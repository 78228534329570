import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, PageHeader } from "antd";
import DHSTable from "../../../../../common/DHSControl/DHSTable/DHSTable";
import DHSDataGrid from "../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import * as actions from "../../../../../redux/actions/Sales/reports/reports";
import DHSHanleFilter from "../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { FormatColumnDHSTable, result } from "../../../../controller/Format";
import * as actionsLogin from "../../../../../redux/actions/Login/index";
import Filter from "./Filter/Filter";
import "./index.css";
const isColumns = [
  {
    caption: "Số Phiếu",
    dataField: "SO_CT",
    type: 0,
  },
  {
    caption: "Ngày lập phiếu",
    dataField: "NGAY_CT",
    type: 0,
    format: "date",
  },
  {
    caption: "Mã hàng",
    dataField: "MA_VT",
    type: 0,
  },
  {
    caption: "Mã hàng theo dõi",
    dataField: "MA_VT_REF",
    type: 0,
  },
  {
    caption: "Mã khách",
    dataField: "MA_KH",
    type: 0,
  },
  {
    caption: "Số PO",
    dataField: "SO_PO",
    type: 0,
  },
  {
    caption: "Dòng",
    dataField: "LINE_PO",
    type: 0,
  },
  {
    caption: "SL đặt hàng",
    dataField: "SO_LUONG_QD",
    type: 0,
  },
  {
    caption: "ĐVT",
    dataField: "DVT",
    type: 0,
  },
  {
    caption: "Giá bán",
    dataField: "GIA_NT2",
    format: "Money",
    type: 0,
  },
  {
    caption: "Giá bán VNĐ",
    dataField: "GIA2",
    format: "Money",
    type: 0,
  },
  {
    caption: "Thành tiền",
    dataField: "TT_NT",
    format: "Money",
    type: 0,
  },
  {
    caption: "Thành tiền VNĐ",
    dataField: "TT",
    format: "Money",
    type: 0,
  },
  {
    caption: "Hình thức thanh toán",
    dataField: "TERMOFPAYMENT_CODE",
    type: 0,
  },
  {
    caption: "Hình thức giao hàng",
    dataField: "INCOTERM_CODE",
    type: 0,
  },
  {
    caption: "Hình thức vận chuyển",
    dataField: "TRANSPORTATION_METHOD_CODE",
    type: 0,
  },
  {
    caption: "Hình thức vận chuyển",
    dataField: "TRANSPORTATION_METHOD_CODE",
    type: 0,
  },
  {
    caption: "Trạng thái",
    dataField: "STATUS_NAME",
    type: 0,
  },
];

export default function SalesTransaction(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listDm = useSelector((state) => state.SOReportsReducer.listDm);
  const RptSODH0301 = useSelector(
    (state) => state.SOReportsReducer.RptSODH0301
  );
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState();
  const [OpenDrawerFilter, setOpenDrawerFilter] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.GetListsDm({ ma_cty }));
    dispatch(actionsLogin.getListCompany());
    // loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    setData(RptSODH0301);
    setFullData(RptSODH0301);
    setLoading(false);
    return () => {
      RptSODH0301.length = 0;
    };
  }, [RptSODH0301]);

  const getDataFilter = (params) => {
    setData(params);
  };
  const checkVisible = (params) => {
    setOpenDrawerFilter(params);
  };
  const selectionChanged = () => {};
  const hanleRowChange = () => {};
  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Báo cáo bán hàng"
          onBack={() => history.push("/Sale")}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSDataGrid
          column={isColumns}
          data={data}
          dataKey={"SO_CT"}
          selectionChanged={selectionChanged}
          hanleRowChange={hanleRowChange}
          exportExcel={true}
          disabled={false}
        />
        {OpenDrawerFilter ? (
          <Filter isVisible={OpenDrawerFilter} checkVisible={checkVisible} />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
