import * as types from "../../../constants/ActionTypes";

var initialState = {
  listSellPrice: [],
  checkInsert: false,
  checkEdit: false,
  checkDelete: false,
  lists: {},
  lookupSelectedItem: [],
  lookupSelectedItem2: [],
};

var SellPriceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SELL_PRICE: {
      return {
        ...state,
        listSellPrice: action.payload,
      };
    }
    case types.GET_LISTS_SELL_PRICE: {
      return {
        ...state,
        lists: action.payload,
      };
    }
    case types.ADD_NEW_SELL_PRICE: {
      return {
        ...state,
        checkInsert: action.payload,
      };
    }
    case types.UPDATE_SELL_PRICE: {
      return {
        ...state,
        checkEdit: action.payload,
      };
    }
    case types.DELETE_SELL_PRICE: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_SELL_PRICE: {
      return {
        ...state,
        checkDelete: false,
        checkEdit: null,
        checkInsert: null,
      };
    }
    case types.GET_LOOKUP_SELECTED_ITEM: {
      return {
        ...state,
        lookupSelectedItem: action.payload,
      };
    }
    case types.GET_LOOKUP_SELECTED_ITEM2: {
      return {
        ...state,
        lookupSelectedItem2: action.payload,
      };
    }
    default:
      return state;
  }
};

export default SellPriceReducer;
