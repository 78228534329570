import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListPurchaseReceipt = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch02/ListPurchaseReceipt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHPurchaseReceipt = (detail) => (dispatch, getState) => {
  callApi("api/poVch02/GetPHPurchaseReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTPurchaseReceipt = (detail) => (dispatch, getState) => {
  callApi("api/poVch02/GetCTPurchaseReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCustomer = (customer) => (dispatch, getState) => {
  callApi("api/poVch02/GetCustomer", "POST", customer)
    .then((res) =>
      dispatch({
        type: types.GET_CUSTOMER_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListCustomer = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch02/ListCustomer", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CUSTOMER_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch02/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListStatus = () => (dispatch, getState) => {
  callApi("api/poVch02/ListStatus", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_STATUS_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListIncoterms = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch02/ListIncoterms", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_INCOTERMS_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListAccount = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch02/GetSettingAccount", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_ACCOUNT_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListCurrency = () => (dispatch, getState) => {
  callApi("api/poVch02/ListCurrency", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CURRENCY_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/poVch02/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewPurchaseReceipt = (quotation) => (dispatch, getState) => {
  callApi("api/poVch02/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteAPurchaseReceipt = (master) => (dispatch, getState) => {
  callApi("api/poVch02/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updatePurchaseReceipt = (so1) => (dispatch, getState) => {
  callApi("api/poVch02/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getTypeReceipt = () => (dispatch, getState) => {
  callApi("api/poVch02/GetTypeReceipt", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_TYPE_RECEIPT,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_PO2,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_PO2,
  };
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/poVch02/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_PO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const LookupPO1 = (query) => (dispatch, getState) => {
  callApi("api/poVch02/LookupPO1", "POST", query)
    .then((res) =>
      dispatch({
        type: types.LOOKUP_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
