import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const GetListUserInfo = (ma_cty) => (dispatch, getState) => {
  callApi("api/SiUserInfo/GetListUserInfo", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_USER_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewUserInfo = (query) => (dispatch, getState) => {
  callApi("api/SiUserInfo/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_USER_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteUserInfo = (query) => (dispatch, getState) => {
  callApi("api/SiUserInfo/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_USER_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateUserInfo = (query) => (dispatch, getState) => {
  callApi("api/SiUserInfo/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_USER_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_CACHE_USER_INFO,
  };
};
