import React, { useState, useEffect } from "react";
import { Collapse, Row, Col, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  DHSInput,
  DHSText,
  DHSSelectOpt,
  DHSCheckBox,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { FormatDate } from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/CashAndBank/BankPayment/BankPayment";
import moment from "moment";
const { Panel } = Collapse;

export default function General(props) {
  const { Master, isEdit, getTyGia } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isRender, setRender] = useState(false);
  const CA4State = useSelector((state) => state.CA4Reducer);

  useEffect(() => {
    dispatch(actions.getListsDm({ ma_cty }));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(CA4State.listCustomer)) {
      handleKHChange(Master.IDCUSTOMER);
    }
  }, [CA4State.listCustomer]);

  useEffect(() => {
    if (!_.isEqual(CA4State.exchangeRate, "")) {
      Master["TY_GIA"] = CA4State.exchangeRate == 0 ? 1 : CA4State.exchangeRate;
    }
    getTyGia(Master.TY_GIA);
    setRender(!isRender);
  }, [CA4State.exchangeRate]);

  const handleKHChange = (params) => {
    let customer = CA4State.listCustomer.find((x) => x.IDCUSTOMER === params);
    if (!_.isEmpty(customer)) {
      Master.DIA_CHI = customer.DIA_CHI;
      Master.IDCUSTOMER = params;
      Master.EMAIL = customer.EMAIL;
      Master.TEL = customer.TEL;
      Master.MA_KH = customer.MA_KH;
    }
    setRender(!isRender);
  };

  const onChangeText = (params) => {
    Master[params.name] = params.value;
    if (params.name === "TY_GIA") {
      getTyGia(Master.TY_GIA);
    }
    setRender(!isRender);
  };

  const handleChangeCurrency = (params) => {
    Master["MA_NT"] = params;
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: moment(Master.NGAY_LCT),
      })
    );
    setRender(!isRender);
  };
  return (
    <div className="CA4-general">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chung" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Tên nhà cung cấp"
                  array={CA4State.listCustomer}
                  valueOpt={"IDCUSTOMER"}
                  valueRender={"TEN_KH"}
                  defaultValue={Master.IDCUSTOMER}
                  onChange={handleKHChange}
                  disable={isEdit}
                />
                <DHSText label="Số điện thoại" value={Master.TEL} />
                <DHSText label="Email" value={Master.EMAIL} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSInput
                  label="Người nhận"
                  name="NGUOI_GD"
                  value={Master.NGUOI_GD}
                  disable={isEdit}
                  getValueChange={onChangeText}
                />
                <DHSText label="Địa chỉ" value={Master.DIA_CHI} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Loại chi"
                  array={CA4State.sysExchange}
                  valueOpt={"MA_GD"}
                  valueRender={"MO_TA"}
                  defaultValue={Master.MA_GD}
                  onChange={(value) => onChangeText({ name: "MA_GD", value })}
                />
                <DHSText
                  label="Ngày chứng từ"
                  value={FormatDate(Master.NGAY_CT)}
                />
                <DHSText label="Ngày lập" value={FormatDate(Master.NGAY_LCT)} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Loại tiền tệ"
                  array={CA4State.listCurrency}
                  valueOpt={"MA_NT"}
                  valueRender={"MA_NT"}
                  defaultValue={Master.MA_NT}
                  onChange={handleChangeCurrency}
                  disable={isEdit}
                />
                <DHSInput
                  label="Tỷ giá"
                  name="TY_GIA"
                  value={Master.TY_GIA}
                  disable={isEdit}
                  disable={Master.MA_NT === "VND" ? true : false}
                  getValueChange={onChangeText}
                />
                <DHSInput
                  label="Diễn giải"
                  name="DIEN_GIAI"
                  value={Master.DIEN_GIAI}
                  disable={isEdit}
                  getValueChange={onChangeText}
                />
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
}
