import React, { PureComponent } from "react";
import { Modal, Button, Row, Col } from "antd";
import { Template } from "../../../../../common/DHSControl/DHSTemplateRP/Template";
import DHSPrint from "../../../../../common/DHSControl/DHSPrint/DHSPrint";
import { DHSSelectOpt } from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
const contentPrint =
  "Cảm ơn Quý khách hàng đã quan tâm và sử dụng dịch vụ của chúng tôi. Công ty xin gửi tới Quý khách hàng đơn đặt hàng. Chi tiết đơn đặt hàng của quý khách gồm";

export default class ModalSelectRp extends PureComponent {
  state = {
    lstTemplate: [
      {
        id: "Rp01",
        name: "Đơn đặt hàng",
      },
    ],
    template: {
      id: "Rp01",
      name: "Đơn đặt hàng",
    },
  };
  handleChange = (value) => {
    this.state.lstTemplate.map((item) => {
      if (item.id === value) {
        this.setState({ template: item });
      }
    });
  };
  shouldComponentUpdate = () => {
    if (this.props.isModalVisible === true) {
      return true;
    }
    return false;
  };
  render() {
    const { isModalVisible, ctSalesOrder, Master } = this.props;
    const { template, lstTemplate } = this.state;
    return (
      <div>
        <Modal
          title="Chọn mẫu in"
          visible={isModalVisible}
          footer={
            <Row>
              <Col span={3} offset={17}>
                <DHSPrint
                  PagePrint={Template[template.id]}
                  objPrint={Master}
                  isSample={Master.TRANG_THAI === "1" ? true : false}
                  ctObjPrint={ctSalesOrder}
                  title={template.name}
                  contentPrint={contentPrint}
                  onAfterPrint={this.onAfterPrint}
                  onBeforeGetContent={this.onBeforeGetContent}
                />
              </Col>
              <Col span={3} offset={1}>
                <Button onClick={this.handleCancel}>Hủy</Button>
              </Col>
            </Row>
          }
        >
          <DHSSelectOpt
            label="Mẫu in"
            array={lstTemplate}
            valueOpt={"id"}
            valueRender={"name"}
            defaultValue={template.id}
            onChange={this.handleChange}
          />
        </Modal>
      </div>
    );
  }
  handleCancel = () => {
    this.props.ctSalesOrder.length = 0;
    this.props.checkVisible(false);
  };
  onBeforeGetContent = () => {};
  onAfterPrint = () => {};
}
