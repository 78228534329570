import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import * as actions from "../../../../../redux/actions/Category/driver/driver";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
import { widthDrawer } from "../../index";
const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};
class DriverForm extends Component {
  state = {
    loading: false,
    DRIVER_CODE: "",
    DRIVER_NAME: "",
    DRIVER_EMAIL: "",
    DRIVER_PHONE: "",
    PT_ID: "",
    KSD: false,
    cuser: this.props.userName,
    luser: this.props.userName,
    ma_cty: this.props.ma_cty,
    noneEdit: false,
  };

  isEditAction = () => {
    const { Master } = this.props;
    this.setState({
      DRIVER_CODE: Master.DRIVER_CODE,
      DRIVER_NAME: Master.DRIVER_NAME,
      DRIVER_EMAIL: Master.DRIVER_EMAIL,
      DRIVER_PHONE: Master.DRIVER_PHONE,
      PT_ID: Master.PT_ID,
    });
  };

  componentDidMount = () => {
    if (!_.isEmpty(this.props.Master)) {
      this.isEditAction();
      this.props.noneEdit ? this.setState({ noneEdit: true }) : <></>;
    }
  };

  handleOk = async () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      await this.props.getItemActions(this.state);
    }
  };

  render() {
    const { isVisible, title, listVehicle } = this.props;
    const {
      loading,
      noneEdit,
      DRIVER_CODE,
      DRIVER_NAME,
      DRIVER_EMAIL,
      DRIVER_PHONE,
      PT_ID,
    } = this.state;
    return (
      <div>
        <Drawer
          title={title}
          width={widthDrawer}
          onClose={this.handleCancel}
          visible={isVisible}
          maskClosable={false}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                form="submit-form"
                key="submit"
                htmlType="submit"
                style={{
                  display: noneEdit ? "none" : "inline",
                  marginRight: 8,
                }}
              >
                Lưu
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <Collapse
              defaultActiveKey={["1", "2"]}
              ghost
              expandIconPosition="right"
              className="vehicle-form"
            >
              <Panel header="Thông tin chung" key="1">
                <Form
                  id="submit-form"
                  onFinish={this.handleOk}
                  layout="vertical"
                  validateMessages={validateMessages}
                >
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <DHSInput
                        label="Mã tài xế"
                        name="DRIVER_CODE"
                        value={DRIVER_CODE}
                        getValueChange={this.handleChangeText}
                        disable={noneEdit}
                      />
                    </Col>
                    <Col span={12}>
                      <DHSInput
                        label="Tên tài xế"
                        name="DRIVER_NAME"
                        value={DRIVER_NAME}
                        getValueChange={this.handleChangeText}
                        disable={noneEdit}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <DHSInput
                        label="Số điện thoại"
                        name="DRIVER_PHONE"
                        value={DRIVER_PHONE}
                        getValueChange={this.handleChangeText}
                        disable={noneEdit}
                      />
                    </Col>
                    <Col span={12}>
                      <DHSInput
                        label="Email"
                        name="DRIVER_EMAIL"
                        value={DRIVER_EMAIL}
                        getValueChange={this.handleChangeText}
                        disable={noneEdit}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <DHSSelectOpt
                        label="Phương tiện"
                        array={listVehicle}
                        defaultValue={PT_ID}
                        valueOpt={"PT_ID"}
                        valueRender={"TEN_PT"}
                        onChange={(value) => this.setState({ PT_ID: value })}
                        disable={noneEdit}
                      />
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </Spin>
        </Drawer>
      </div>
    );
  }
  handleChangeText = (params) => {
    this.setState({
      [params.name]: params.value,
    });
  };
  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false, editSuccess: false });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listDriver: state.DriverReducer.listDriver,
    checkInsert: state.DriverReducer.checkInsert,
    listVehicle: state.VehicleReducer.listVehicle,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverForm);
