import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/CashAndBank/CashReceipt/CashReceipt";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import _ from "lodash";
import * as actionItem from "../../../../../redux/actions/Category/Item/index";
import "./index.css";
const { TabPane } = Tabs;
export default function CA1Edit(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isClickSave, setClickSave] = useState(false);
  const [ty_gia, setTy_gia] = useState(
    history.location.state ? history.location.state.Master.TY_GIA : 1
  );
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const newEdit = useSelector((state) => state.CA1Reducer.newEdit);
  const Master = history.location.state ? history.location.state.Master : {};
  useEffect(() => {
    dispatch(
      actionItem.getListItem({
        ma_cty,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      window.onload = () => {
        history.push("/CashReceipt");
      };
    };
  });

  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(newEdit)) {
      openNotificationWithIcon("success", "Sửa thành công !");
      setEdit(true);
    }
  }, [newEdit]);

  const handleSave = () => {
    setClickSave(true);
  };

  const handleBack = () => {
    dispatch(actions.clearCache());
    history.push("/CashReceipt");
  };

  const handleEdit = () => {
    setEdit(false);
  };

  const getLine = (params) => {
    const { data, total } = params;
    Master.T_TIEN = data.reduce((a, b) => a + (b["PS_CO"] || 0), 0);
    Master.T_TIEN_NT = data.reduce((a, b) => a + (b["PS_CO"] || 0) / ty_gia, 0);
    if (deleteItemEmpty(data)) {
      if (!isLoading) {
        setLoading(true);
        dispatch(
          actions.updateCashReceipt({
            modelM: Master,
            arrayCt: data,
          })
        );
      }
    }
  };

  const deleteItemEmpty = (array) => {
    var check = true;
    setClickSave(false);
    if (array.length > 0) {
      array.map((item, index) => {
        if (_.isNil(item.IDCUSTOMER)) {
          array.splice(index, 1);
        }
      });
      return check;
    }
  };
  return (
    <div className="ca1-edit">
      <Spin spinning={isLoading}>
        <div>
          <ToolBar
            setSave={!isEdit ? handleSave : undefined}
            setReEdit={isEdit ? handleEdit : undefined}
            setBack={handleBack}
          />
          <div style={{ paddingLeft: 15 }}>
            <h4 style={{ margin: 0, color: "#1890FF" }}>Phiếu thu</h4>
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
                {Master.SO_CT} : {Master.TEN_KH}
              </h2>
            </div>
          </div>
          <General
            Master={Master}
            isEdit={isEdit}
            getTyGia={(ty_gia) => setTy_gia(ty_gia)}
          />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Chi tiết phiếu thu" key="1">
              <ListItem
                Master={Master}
                isEdit={isEdit}
                ty_gia={ty_gia}
                isClickSave={isClickSave}
                getLine={getLine}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  );
}
