import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { GetRptRevenueByInvoice } from "../../../../../../redux/actions/Sales/reports/reports";
import { periodData } from "../../../../../controller/DataSample";
import { onPeriodChange } from "../../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

Filter.defaultProps = {
  //Object filter chưa các property cần thiết để thực hiện action call api
  _objectFilter: {
    MA_CTY: "",
    IDCUSTOMER: "",
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    FROMDATE: moment().startOf("month"),
    TODATE: moment().endOf("month"),
  },
};
export default function Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [objFilter, setObjFilter] = useState(_objectFilter);
  const listCustomer = useSelector(
    (state) => state.SICustomerReducer.listCustomer
  );
  // Function thoát modal hoặc drawer
  const handleCancel = (e) => {
    checkVisible({
      visibleChange: false,
      FROMDATE: objFilter.FROMDATE,
      TODATE: objFilter.TODATE,
    });
  };
  // Function submit
  const handleOk = () => {
    //Dispatch action với các filter mà user đã nhập hoặc chọn.
    dispatch(
      GetRptRevenueByInvoice({
        MA_CTY: ma_cty,
        NGAY_CT1: moment(objFilter.FROMDATE).add(7, "hours"),
        NGAY_CT2: moment(objFilter.TODATE).add(7, "hours"),
        IDCUSTOMER: objFilter.IDCUSTOMER,
      })
    );
    //Thoát modal
    handleCancel();
  };
  //Function set lại dự liệu filter khi có sự thay đổi trên form (trường hợp custom)
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FROMDATE: result.FROMDATE,
      TODATE: result.toDate,
    });
  };
  //Function set lại dự liệu filter khi có sự thay đổi trên form
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Điều kiện lọc"
        width={600}
        onClose={handleCancel}
        visible={isVisible}
        maskClosable={false}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Thực hiện
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="rpBk01-Filter"
        >
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FROMDATE}
                nameState="FROMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={(e) => handleChange(e.value, e.name)}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={(e) => handleChange(e.value, e.name)}
              />
            </Col>
            <Col span={24}>
              <DHSSelectOpt
                label="Khách hàng"
                name="IDCUSTOMER"
                array={listCustomer}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                onChange={(value) => handleChange(value, "IDCUSTOMER")}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
