import * as types from "../../../constants/ActionTypes";

var initialState = {
  listItemGroup: [],
  dm_tk: [],
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
};

var ItemGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_ITEMGROUP: {
      return {
        ...state,
        listItemGroup: action.payload,
      };
    }
    case types.GET_LIST_DM_ITEMGROUP:
      return {
        ...state,
        dm_tk: action.payload.dm_tk,
      };
    case types.INSERT_ITEMGROUP:
      return {
        ...state,
        checkInsert: action.payload,
      };
    case types.DELETE_ITEMGROUP:
      return {
        ...state,
        checkDelete: action.payload,
      };
    case types.UPDATE_ITEMGROUP:
      return {
        ...state,
        checkUpdate: action.payload,
      };
    case types.CLEAR_ITEMGROUP: {
      return {
        ...state,
        checkUpdate: false,
        checkDelete: false,
        checkInsert: false,
        listItemGroup: [],
      };
    }
    default:
      return state;
  }
};

export default ItemGroupReducer;
