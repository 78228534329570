import React, { Component } from "react";
import { Drawer, Button, Divider, Form, Row, Col, Spin } from "antd";
import DHSTable from "../../../../../../common/DHSControl/DHSTable/DHSTable";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyName,
} from "../../../../../controller/Format";
import "./index.css";
import * as actions from "../../../../../../redux/actions/Sales/packingSlip/index";
import { widthDrawer } from "../../../index";
const objectColumnPhQU = {
  so_ct: { name: "Số đơn hàng", width: 100 },
  ngay_ct: { name: "Ngày tạo", width: 50, formatDate: true },
  ma_kh: { name: "Mã khách hàng", width: 100 },
  ten_kh: { name: "Tên khách hàng", width: 150 },
  ma_nt: { name: "Mã ngoại tệ", width: 50 },
  t_tt: { name: "Tiền hàng", width: "10%", formatMoney: true },
};
const objectColumnCtQU = {
  TEN_VT: { name: "Sản phẩm", width: 300 },
  DVT: { name: "Đơn vị tính", width: 100 },
  SO_LUONG: { name: "Số lượng", width: 100 },
  GIA_NT2: { name: "Đơn giá", width: 100, formatMoney: true },
  TT_NT: { name: "Thành tiền", width: 100, formatMoney: true },
};
class ListSO0 extends Component {
  state = {
    customColumnsPh: [],
    customColumnsCt: [],
    phChoose: {},
    dsCTSOBySoSO: [],
    dsCTSelection: [],
    dsAddressSelection: [],
    loading: false,
  };
  componentDidMount = async () => {
    const { dsCtSO, dsPhSO, dsAddressSO } = this.props;
    _.map(dsCtSO, (item) => {
      return toUpperPropertyName(item);
    });
    _.map(dsAddressSO, (item) => {
      return toUpperPropertyName(item);
    });
    await FormatColumnDHSTable(objectColumnPhQU);
    this.setState({ customColumnsPh: result });
    await FormatColumnDHSTable(objectColumnCtQU);
    this.setState({ customColumnsCt: result });
  };

  confirm = () => {
    this.props.checkVisible({
      isVisible: false,
      phChoose: this.state.phChoose,
    });
    this.props.listCtChooseFromSO({
      dsCT: this.state.dsCTSelection,
      dsAddress: this.state.dsAddressSelection,
    });
  };
  render() {
    const { isVisible, dsPhSO } = this.props;
    const {
      loading,
      customColumnsPh,
      customColumnsCt,
      dsCTSOBySoSO,
    } = this.state;
    return (
      <div style={{ height: "100%" }}>
        <Drawer
          title="Chọn đơn hàng"
          width={widthDrawer}
          onClose={this.handleCancel}
          visible={isVisible}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{ marginRight: 8 }}
                onClick={this.confirm}
              >
                Xác nhận
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <div className="listSO0">
              <DHSTable
                data={dsPhSO}
                customColumns={customColumnsPh}
                rowKey="so_ct"
                getDataRow={this.getDataRow}
                multiSelection={this.multiSelection}
                scroll={{ y: 500, x: 700 }}
              />
              <DHSTable
                data={dsCTSOBySoSO}
                customColumns={customColumnsCt}
                rowKey="STT_REC0"
                scroll={{ y: 500, x: 700 }}
              />
            </div>
          </Spin>
        </Drawer>
      </div>
    );
  }
  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false });
  };
  //Select row trên table và show chi tiết SO đã select
  getDataRow = (params) => {
    const { dsCtSO } = this.props;
    if (!_.isEmpty(params)) {
      const data = _.filter(dsCtSO, (item) => {
        return item.SO_DH === params.so_ct;
      });
      this.setState({ dsCTSOBySoSO: data });
    } else {
      this.setState({ dsCTSOBySoSO: [] });
    }
  };
  //Select SO lấy ctSO đã select
  multiSelection = (params) => {
    const { dsCtSO, dsAddressSO } = this.props;
    //kiểm tra params truyền từ chooseSO0
    !_.isEmpty(params)
      ? this.setState({ phChoose: params[0] })
      : this.setState({ phChoose: {} });
    //lọc CtSO mà user đã chọn thành new array
    const data = _.filter(dsCtSO, (item, index) => {
      return _.some(params, (itemFilter) => {
        item.ID = index + 1;
        item.SELL_PRICE = item.GIA2;
        return itemFilter.stt_rec === item.STT_REC;
      });
    });
    const dataAddress = _.filter(dsAddressSO, (item, index) => {
      return _.some(params, (itemFilter) => {
        return itemFilter.stt_rec === item.STT_REC;
      });
    });
    this.setState({ dsCTSelection: data, dsAddressSelection: dataAddress });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listCustomer: state.SO2Reducer.listCustomer,
    dsPhSO: state.SO2Reducer.dsPhSO,
    dsCtSO: state.SO2Reducer.dsCtSO,
    dsAddressSO: state.SO2Reducer.dsAddressSO,
    checkDispatch: state.SO2Reducer.checkDispatch,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    lookupSoSalesOrder: (query) => {
      dispatch(actions.lookupSoSalesOrder(query));
    },
    listCtChooseFromSO: (dsCtSO) => {
      dispatch(actions.listCtChooseFromSO(dsCtSO));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSO0);
