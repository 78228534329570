import React, { Component } from "react";
import { Drawer, Row, Col, Form, Button } from "antd";
import { DHSInput } from "../../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { widthDrawer } from "../../../../index";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
export default class AddAddress extends Component {
  state = {
    CONTACT_CODE: "",
    CONTACT_NAME: "",
    TEL: "",
    EMAIL: "",
    DELIVERY_ADDRESS: "",
    NOTES: "",
    dataSource: [],
  };
  handleCancel = () => {
    this.props.checkVisible({ isVisible: false });
  };
  onChangeText = (params) => {
    this.setState({
      [params.name]: params.value,
    });
  };
  handleOk = (e) => {
    this.props.checkVisible({ isVisible: false, ObjAddress: this.state });
  };
  render() {
    const { isVisible } = this.props;
    const {
      CONTACT_NAME,
      CONTACT_CODE,
      TEL,
      EMAIL,
      DELIVERY_ADDRESS,
      NOTES,
    } = this.state;
    return (
      <div>
        <Drawer
          title="Thêm địa chỉ"
          placement="right"
          onClose={this.handleCancel}
          visible={isVisible}
          width={widthDrawer}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                form="submit-form"
                key="submit"
                htmlType="submit"
                style={{ marginRight: 8 }}
              >
                Lưu
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Form
            className="addAddressSO1"
            id="submit-form"
            onFinish={this.handleOk}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <DHSInput
              label="Mã địa chỉ"
              name="CONTACT_CODE"
              value={CONTACT_CODE}
              getValueChange={this.onChangeText}
            />
            <DHSInput
              label="Người liên hệ"
              name="CONTACT_NAME"
              value={CONTACT_NAME}
              getValueChange={this.onChangeText}
            />
            <DHSInput
              label="Địa chỉ"
              name="DELIVERY_ADDRESS"
              value={DELIVERY_ADDRESS}
              getValueChange={this.onChangeText}
            />
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSInput
                  label="Email"
                  name="EMAIL"
                  value={EMAIL}
                  getValueChange={this.onChangeText}
                />
              </Col>
              <Col span={12}>
                <DHSInput
                  label="Số điện thoại"
                  name="TEL"
                  value={TEL}
                  getValueChange={this.onChangeText}
                />
              </Col>
            </Row>
            <DHSInput
              label="Ghi chú"
              name="NOTES"
              value={NOTES}
              getValueChange={this.onChangeText}
            />
          </Form>
        </Drawer>
      </div>
    );
  }
}
