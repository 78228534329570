import React, { Component } from "react";
import {
  Button,
  Space,
  Divider,
  Collapse,
  Select,
  Row,
  Col,
  List,
  Affix,
  Checkbox,
} from "antd";
import AddAddress from "./addAddress/addAddress";
import Format from "../../../../../controller/Format";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";
import { connect } from "react-redux";
import _ from "lodash";
import ProductInfor from "./productInfor/productInfor";
import { ArrowRightOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { DHSSelectOpt } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
const { Option } = Select;
const { Panel } = Collapse;

class ListAddress extends Component {
  state = {
    loading: false,
    openDrawerAddAddress: false,
    listItemSelected: [],
    listAddress: [],
    address: {},
    isTransfer: false,
  };

  componentDidMount = () => {
    this.getAddressFromContactSO1();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { isTransfer, listAddress } = this.state;
    const { listDelectedItemID } = this.props;
    if (isTransfer !== prevState.isTransfer && isTransfer) {
      this.setState({ isTransfer: false });
    }
    if (!_.isEqual(this.props.lineChange, prevProps.lineChange)) {
      _.filter(listDelectedItemID, (x) => {
        _.filter(listAddress, (item) => {
          _.remove(item.dataSource, (i) => i.OLDID === x);
        });
      });
      this.setState({ isTransfer: true });
    }
  };

  handleSelectAddress = async (value) => {
    const { listAddress } = this.state;
    await this.setState({
      address: _.find(listAddress, (item) => item.CONTACT_CODE === value),
    });
    // await this.setState({ listItemSelected: this.state.address.dataSource });
  };

  onGetItemSelected = (params) => {
    const { listItemSelected } = this.state;
    if (params.checked) {
      this.setState({
        listItemSelected: [...listItemSelected, params.item],
      });
    } else {
      _.remove(listItemSelected, (x) => x.STT_REC0 === params.item.STT_REC0);
      this.setState({ listItemSelected: [...listItemSelected] });
    }
  };

  onGetItemDelete = (params) => {
    const { address, listAddress } = this.state;
    _.filter(listAddress, async (item) => {
      if (item.CONTACT_CODE === address.CONTACT_CODE) {
        await _.filter(this.props.dataSource, (x) => {
          if (x.STT_REC0 === params.OLDID) {
            x.SO_LUONG_DELIVERY += params.SO_LUONG;
            x.SL_DLV_PLAN = x.SO_LUONG - x.SO_LUONG_DELIVERY;
          }
        });
        await _.remove(item.dataSource, (i) => i.OLDID === params.OLDID);
        address.dataSource = item.dataSource;
        this.setState({ address, isTransfer: true });
        this.getArrayAddressItem();
      }
    });
  };

  onTransfer = () => {
    const { address, listAddress, listItemSelected } = this.state;
    _.filter(listAddress, async (item) => {
      if (item.CONTACT_CODE === address.CONTACT_CODE) {
        await this.cloneObject(item.dataSource);
        item.dataSource = item.dataSource.concat(
          listItemSelected.filter((x) => !x.REMOVE)
        );
        address.dataSource = item.dataSource;
        this.setState({
          address,
          listItemSelected: [],
          isTransfer: true,
        });
        this.getArrayAddressItem();
      }
    });
  };

  render() {
    const {
      openDrawerAddAddress,
      listAddress,
      address,
      isTransfer,
      listItemSelected,
    } = this.state;
    const { dataSource } = this.props;
    return (
      <div className="listItem">
        <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
          <Panel header="Thông tin giao hàng" key="1">
            <Space style={{ marginBottom: 16, marginTop: 10 }}>
              <Button
                disabled={this.props.editDone}
                onClick={() => this.setState({ openDrawerAddAddress: true })}
              >
                Thêm địa chỉ
              </Button>
            </Space>
            <Divider />
            <Row gutter={[16, 0]} style={{ marginTop: 8 }}>
              <Col span={10} offset={14}>
                <DHSSelectOpt
                  label="Danh sách địa chỉ giao hàng"
                  array={listAddress}
                  valueOpt={"CONTACT_CODE"}
                  valueRender={"DELIVERY_ADDRESS"}
                  onChange={this.handleSelectAddress}
                />
              </Col>
              <Col span={11} xl={11} xs={24}>
                <List
                  header={<HeaderLeft />}
                  dataSource={dataSource}
                  size="small"
                  bordered
                  renderItem={(item) => (
                    <ProductInfor
                      item={item}
                      onGetItemSelected={this.onGetItemSelected}
                      disabled={_.isEmpty(address)}
                      direction={"left"}
                      isTransfer={isTransfer}
                    />
                  )}
                />
              </Col>
              <Col span={2} xl={2} xs={24} style={{ textAlign: "center" }}>
                <Affix offsetTop={10}>
                  <Button
                    style={{ width: 60, margin: "10px 0px" }}
                    className="listAddressBtn"
                    type="primary"
                    icon={
                      window.screen.width < 1200 ? (
                        <ArrowDownOutlined />
                      ) : (
                        <ArrowRightOutlined />
                      )
                    }
                    onClick={this.onTransfer}
                    disabled={_.isEmpty(listItemSelected)}
                  />
                </Affix>
              </Col>
              <Col span={11} xl={11} xs={24}>
                <List
                  header={<HeaderRight />}
                  dataSource={address.dataSource}
                  size="small"
                  bordered
                  renderItem={(item) => (
                    <ProductInfor
                      item={item}
                      direction={"right"}
                      onGetItemDelete={this.onGetItemDelete}
                    />
                  )}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
        {openDrawerAddAddress ? (
          <AddAddress
            isVisible={openDrawerAddAddress}
            checkVisible={this.checkVisible}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }

  checkVisible = (params) => {
    this.setState({ openDrawerAddAddress: params.isVisible });
    if (!_.isNil(params.ObjAddress)) {
      this.setState({
        listAddress: [...this.state.listAddress, params.ObjAddress],
      });
    }
  };

  cloneObject = (array) => {
    const { listItemSelected, address } = this.state;
    _.filter(listItemSelected, async (item, index) => {
      let result = 0;
      await _.filter(array, (item2) => {
        if (_.isEqual(item2.OLDID, item.STT_REC0)) {
          item2.SO_LUONG += item.SL_DLV_PLAN;
          item.REMOVE = true;
          result = 1;
        }
      });
      if (result === 0) {
        let object = {};
        object.OLDID = item.STT_REC0;
        object.STT_REC0 = Math.random().toString(36).substr(2, 9).toUpperCase();
        object.SO_LUONG = item.SL_DLV_PLAN;
        object.TEN_VT = item.TEN_VT;
        object.MA_VT = item.MA_VT;
        object.DVT = item.DVT;
        object.IDITEM = item.IDITEM;
        object.CONTACT_CODE = address.CONTACT_CODE;
        object.CONTACT_NAME = address.CONTACT_NAME;
        object.TEL = address.TEL;
        object.EMAIL = address.EMAIL;
        object.DELIVERY_ADDRESS = address.DELIVERY_ADDRESS;
        object.NOTES = address.NOTES;
        listItemSelected[index] = object;
      }
      item.SL_DLV_PLAN = item.SO_LUONG - item.SO_LUONG_DELIVERY;
    });
  };

  getArrayAddressItem = async () => {
    const { listAddress } = this.state;
    let array = [];
    await listAddress.filter((item) => {
      array = array.concat(item.dataSource);
    });
    this.props.getAddressForItem(array);
  };

  getAddressFromContactSO1 = async () => {
    const { contactSalesOrder, listItem, dataSource } = this.props;
    const { listAddress } = this.state;
    await contactSalesOrder.map((item, index) => {
      const objectItem = _.filter(listItem, (x) => x.IDITEM === item.IDITEM);
      const objectItem2 = _.filter(dataSource, (x) => x.IDITEM === item.IDITEM);
      if (!_.isEmpty(objectItem2)) {
        if (_.some(listAddress, (x) => x.CONTACT_CODE === item.CONTACT_CODE)) {
          _.filter(listAddress, (x) => {
            if (x.CONTACT_CODE === item.CONTACT_CODE) {
              x.dataSource.push({
                ...objectItem[0],
                ...item,
                OLDID: objectItem2[0].STT_REC0,
              });
            }
          });
        } else {
          listAddress.push({
            ...item,
            dataSource: [
              { ...objectItem[0], ...item, OLDID: objectItem2[0].STT_REC0 },
            ],
          });
          this.setState({ listAddress: listAddress });
        }
      }
    });
    this.getArrayAddressItem();
  };
}

function HeaderLeft() {
  return (
    <div>
      <Row style={{ color: "gray", fontSize: 12 }}>
        <Col span={1}>
          <Checkbox />
        </Col>
        <Col span={3}>Mã vật tư</Col>
        <Col span={8}>Tên vật tư</Col>
        <Col span={3}>Số lượng</Col>
        <Col span={4}>SL giao</Col>
        <Col span={3}>SL còn lại</Col>
        <Col span={2} style={{ textAlign: "center" }}>
          ĐVT
        </Col>
      </Row>
    </div>
  );
}
function HeaderRight() {
  return (
    <div>
      <Row style={{ color: "gray", fontSize: 12 }}>
        <Col span={1}></Col>
        <Col span={3}>Mã vật tư</Col>
        <Col span={11}>Tên vật tư</Col>
        <Col span={3}>SL giao</Col>
        <Col span={2} style={{ textAlign: "center" }}>
          ĐVT
        </Col>
      </Row>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    contactSalesOrder: state.SO1Reducer.contactSalesOrder,
    listItem: state.SellPriceReducer.lookupSelectedItem,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ListAddress);
