import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const GetListRoleInfo = (ma_cty) => (dispatch, getState) => {
  callApi("api/SiRoleInfo/GetListRoleInfo", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_ROLE_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewRoleInfo = (query) => (dispatch, getState) => {
  callApi("api/SiRoleInfo/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_ROLE_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteRoleInfo = (query) => (dispatch, getState) => {
  callApi("api/SiRoleInfo/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_ROLE_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateRoleInfo = (query) => (dispatch, getState) => {
  callApi("api/SiRoleInfo/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_ROLE_INFO,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_CACHE_ROLE_INFO,
  };
};
