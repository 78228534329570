import React, { Component } from "react";
import _ from "lodash";
export default class DHSIcons extends Component {
  render() {
    const { name } = this.props;
    return (
      <div>
        {!_.isEmpty(name) ? (
          <img
            src={process.env.PUBLIC_URL + `./icons/${name}.png`}
            alt=""
            style={{ opacity: 0.8 }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
