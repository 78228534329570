import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Category/Item/index";
import {
  Collapse,
  Form,
  Input,
  Select,
  Spin,
  Drawer,
  Button,
  Row,
  Col,
  Upload,
  InputNumber,
} from "antd";
import RenderOptionSelect from "../../../../controller/RenderOptionsSelect";
import _ from "lodash";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { widthDrawer } from "../../index";
import { FormatMoney } from "../../../../controller/Format";
const { Panel } = Collapse;
const { Option } = Select;
const validateMessages = {
  required: "Trường này là bắt buộc",
};
function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    openNotificationWithIcon("error", "Bạn chỉ có thể tải lên file .JPG/PNG !");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    openNotificationWithIcon("error", "Kích thước hình ảnh phải nhỏ hơn 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
class ItemForm extends Component {
  state = {
    loading: false,
    ma_vt: "",
    ten_vt: "",
    sell_price: "",
    purchase_price: "",
    dvt_id: "",
    IDGroup: "",
    loai: "",
    barcode: "",
    sl_min: 0,
    sl_max: 0,
    cuser: this.props.userName,
    luser: this.props.userName,
    ma_cty: this.props.ma_cty,
    noneEdit: false,
    image: "",
    imageUrl: "",
    gia_si: "",
    gia_le: "",
  };

  isEditAction = () => {
    const { Master } = this.props;
    this.setState({
      ma_vt: Master.ma_vt,
      ten_vt: Master.ten_vt,
      sell_price: Master.sell_price,
      purchase_price: Master.purchase_price,
      dvt_id: Master.dvt_id,
      IDGroup: Master.IDGroup,
      loai: Master.loai,
      barcode: Master.barcode,
      sl_min: Master.sl_min,
      sl_max: Master.sl_max,
      image: Master.anh.length > 5 ? "data:image/png;base64," + Master.anh : "",
      imageUrl: Master.anh,
      gia_si: Master.gia_si,
      gia_le: Master.gia_le,
    });
  };

  componentDidMount = () => {
    this.props.getListDM({ ma_cty: this.props.ma_cty });
    if (!_.isEmpty(this.props.Master)) {
      this.isEditAction();
      this.props.noneEdit ? this.setState({ noneEdit: true }) : <></>;
    }
  };

  handleOk = async () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      await this.props.getItemActions({ ...this.props.Master, ...this.state });
    }
  };
  dummyRequest = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        image: reader.result,
        imageUrl: reader.result.split(",")[1],
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  render() {
    const {
      isVisible,
      lstUnit2,
      dm_nhomvt,
      tblItemType,
      title,
      Master,
    } = this.props;
    const {
      loading,
      ma_vt,
      ten_vt,
      purchase_price,
      sell_price,
      barcode,
      sl_min,
      sl_max,
      dvt_id,
      IDGroup,
      loai,
      noneEdit,
      image,
      gia_si,
      gia_le,
    } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        <Drawer
          title={title}
          width={widthDrawer}
          onClose={this.handleCancel}
          visible={isVisible}
          maskClosable={false}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                form="submit-form"
                key="submit"
                htmlType="submit"
                style={{
                  display: noneEdit ? "none" : "inline",
                  marginRight: 8,
                }}
              >
                Lưu
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <Collapse
              defaultActiveKey={["1", "2"]}
              ghost
              expandIconPosition="right"
              className="item-create"
            >
              <Panel header="Thông tin chung" key="1">
                <Form
                  id="submit-form"
                  onFinish={this.handleOk}
                  layout="vertical"
                  validateMessages={validateMessages}
                >
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Hình ảnh">
                        <Upload
                          listType="picture-card"
                          customRequest={this.dummyRequest}
                          beforeUpload={beforeUpload}
                          showUploadList={false}
                          disabled={noneEdit}
                        >
                          {image ? (
                            <img
                              src={image}
                              alt="avatar"
                              style={{ width: "100%", height: 100 }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item
                        name="ma_vt"
                        rules={[{ required: true }]}
                        label="Mã vật tư"
                        initialValue={_.isEmpty(Master) ? "" : Master.ma_vt}
                      >
                        <Input
                          name="ma_vt"
                          placeholder="Mã vật tư"
                          value={ma_vt}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Tên vật tư"
                        name="ten_vt"
                        rules={[{ required: true }]}
                        initialValue={_.isEmpty(Master) ? "" : Master.ten_vt}
                      >
                        <Input
                          name="ten_vt"
                          placeholder="Tên vật tư"
                          value={ten_vt}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Đơn vị tính",
                        "Chọn đơn vị tính",
                        lstUnit2,
                        "dvt_id",
                        "dvt_name",
                        "dvt_id",
                        dvt_id
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Nhóm vật tư",
                        "Chọn nhóm vật tư",
                        dm_nhomvt,
                        "idgroup",
                        "ten_nhvt",
                        "IDGroup",
                        IDGroup
                      )}
                    </Col>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Loại",
                        "Chọn loại",
                        tblItemType,
                        "id",
                        "mo_ta",
                        "loai",
                        loai
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Giá bán">
                        <InputNumber
                          placeholder="Nhập giá bán"
                          value={sell_price}
                          onChange={(value) =>
                            this.handleChangeText({
                              target: { name: "sell_price", value },
                            })
                          }
                          disabled={noneEdit}
                          min={0}
                          formatter={(value) => `${FormatMoney(value || 0)}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Giá mua">
                        <InputNumber
                          placeholder="Nhập giá mua"
                          value={purchase_price}
                          onChange={(value) =>
                            this.handleChangeText({
                              target: { name: "purchase_price", value },
                            })
                          }
                          disabled={noneEdit}
                          min={0}
                          formatter={(value) => `${FormatMoney(value || 0)}`}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Giá sỉ">
                        <InputNumber
                          placeholder="Nhập giá sỉ"
                          value={gia_si}
                          onChange={(value) =>
                            this.handleChangeText({
                              target: { name: "gia_si", value },
                            })
                          }
                          disabled={noneEdit}
                          min={0}
                          formatter={(value) => `${FormatMoney(value || 0)}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Giá lẻ">
                        <InputNumber
                          placeholder="Nhập giá lẻ"
                          value={gia_le}
                          onChange={(value) =>
                            this.handleChangeText({
                              target: { name: "gia_le", value },
                            })
                          }
                          disabled={noneEdit}
                          min={0}
                          formatter={(value) => `${FormatMoney(value || 0)}`}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Số lượng tối thiểu">
                        <Input
                          name="sl_min"
                          placeholder="Số lượng tối thiểu"
                          value={sl_min}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Số lượng tối đa">
                        <Input
                          name="sl_max"
                          placeholder="Số lượng tối đa"
                          value={sl_max}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </Spin>
        </Drawer>
      </div>
    );
  }
  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false, editSuccess: false });
  };

  handleChangeText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onCheckboxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  renderFormItemSelectOpt = (
    label,
    placeholder,
    array,
    valueOpt,
    valueRender,
    nameState,
    initialValue
  ) => {
    return (
      <Form.Item label={label}>
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={(value) => this.handleChange(value, nameState)}
          value={initialValue}
          disabled={this.state.noneEdit}
        >
          <Option value="" key="empty">
            <div style={{ color: "#C9C9C9" }}>{placeholder}</div>
          </Option>
          {RenderOptionSelect(array, valueOpt, valueRender)}
        </Select>
      </Form.Item>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    tblItemType: state.ItemReducer.tblItemType,
    dm_nhomvt: state.ItemReducer.dm_nhomvt,
    lstUnit2: state.ItemReducer.lstUnit2,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getListDM: (ma_cty) => {
      dispatch(actions.getListDM(ma_cty));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
