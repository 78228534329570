import React, { Component } from "react";
import { Drawer, Button, Spin, Row, Col } from "antd";
import Format from "../../../../../../controller/Format";
import DHSListItem from "../../../../../../../common/DHSControl/DHSListItem/DHSListItem";
import { widthDrawer } from "../../../../index";
function Header() {
  return (
    <div
      style={{
        color: "gray",
        fontSize: 12,
        display: "flex",
        padding: "0px 10px",
        flex: "1 1",
      }}
    >
      <p style={{ width: 50, margin: 0 }}>Ảnh</p>
      <p style={{ width: "35vw", margin: 0, flex: "1 0" }}>Tên vật tư</p>
      <p style={{ width: 90, margin: 0 }}>Đơn giá</p>
      <p style={{ width: 90, margin: 0 }}>Số lượng</p>
    </div>
  );
}
export default class HandleMultiAdd extends Component {
  state = { loading: false, listItemSelected: [] };

  handleCancel = () => {
    this.props.checkVisible({ isVisible: false });
  };
  getItemSelected = (params) => {
    this.setState({ listItemSelected: params });
  };
  confirmSelected = () => {
    this.props.checkVisible({
      isVisible: false,
      listItemSelected: this.state.listItemSelected,
    });
  };
  render() {
    const { isVisible, listItem } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Drawer
          title="Chọn vật tư"
          width={widthDrawer}
          maskClosable={false}
          onClose={this.handleCancel}
          visible={isVisible}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{ marginRight: 8 }}
                onClick={this.confirmSelected}
              >
                Xác nhận
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <DHSListItem
              header={<Header />}
              listItem={listItem}
              keyItem={"IDITEM"}
              title={"TEN_VT"}
              description={"MA_VT"}
              inputName1={"GIA_NT2"}
              avatar={"ANH"}
              inputDisplay={true}
              getItemSelected={this.getItemSelected}
            />
          </Spin>
        </Drawer>
      </div>
    );
  }
}
