import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const fetchSalesByDay = (data) => (dispatch, getState) => {
  callApi("api/Sales/GetSOSalesbyDays", "POST", data).then((res) =>
    dispatch({
      type: types.FETCH_SALES_BY_DAY,
      payload: res.data,
    })
  );
};

export const fetchSalesByMonth = (data) => (dispatch, getState) => {
  callApi("api/Sales/GetSOSalesbyMonth", "POST", data).then((res) =>
    dispatch({
      type: types.FETCH_SALES_BY_MONTH,
      payload: res.data,
    })
  );
};

export const soGetSalesToday = (data) => (dispatch, getState) => {
  callApi("api/possoreports/SORptGetSalesToday", "POST", data).then((res) =>
    dispatch({
      type: types.SO_GET_SALES_TODAY,
      payload: res.data,
    })
  );
};
