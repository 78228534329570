import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toUpperPropertyNameByArray } from "../../../../controller/Format";
import { GetListsDm } from "../../../../../redux/actions/Inventory/Reports/INReports";
import Filter from "./Filter/Filter";
import _ from "lodash";
import DHSReport from "../../../../../common/layout/DHSReport/DHSReport";
import PropTypes from "prop-types";
import "./index.css";
POSRptINMovement.propTypes = {
  columns: PropTypes.object,
};
POSRptINMovement.defaultProps = {
  columns: [
    {
      caption: "Mã sản phẩm",
      dataField: "MA_VT",
      type: 0,
    },
    {
      caption: "Tên sản phẩm",
      dataField: "TEN_VT",
      type: 0,
    },
    {
      caption: "Đơn vị tính",
      dataField: "DVT",
      type: 0,
    },
    {
      caption: "Tồn đầu",
      dataField: "TON_DAU",
      format: "Money",
      type: 0,
    },
    {
      caption: "Dư Đầu",
      dataField: "DU_DAU",
      format: "Money",
      type: 0,
    },
    {
      caption: "Số lượng nhập",
      dataField: "SL_NHAP",
      format: "Money",
      type: 0,
    },
    {
      caption: "Số lượng xuất",
      dataField: "SL_XUAT",
      format: "Money",
      type: 0,
    },
    {
      caption: "Tiền nhập",
      dataField: "TIEN_NHAP",
      format: "Money",
      type: 0,
    },
    {
      caption: "Tiền xuất",
      dataField: "TIEN_XUAT",
      format: "Money",
      type: 0,
    },
    {
      caption: "Tồn cuối",
      dataField: "TON_CUOI",
      format: "Money",
      type: 0,
    },
    {
      caption: "Dư cuối",
      dataField: "DU_CUOI",
      format: "Money",
      type: 0,
    },
  ],
};
export default function POSRptINMovement(props) {
  const { columns } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const RptInventoryMovement = useSelector((state) =>
    toUpperPropertyNameByArray(state.INReportsReducer.RptInventoryMovement)
  );
  useEffect(() => {
    dispatch(GetListsDm({ ma_cty }));
  }, []);
  return (
    <div className="POSRptINMovement">
      <DHSReport
        columns={columns}
        data={RptInventoryMovement}
        dataKey="IDITEM"
        title="Báo cáo nhập xuất tồn"
        onBack="Inventory"
        FilterComponent={Filter}
      />
    </div>
  );
}
