import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import * as actions from "../../../../../redux/actions/Category/site/site";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSCheckBox,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
import { widthDrawer } from "../../index";

const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};
class SiteForm extends Component {
  state = {
    loading: false,
    SITE_NAME: "",
    ADDRESS: "",
    SITE_PARENT: "",
    LEVEL: 0,
    KSD: false,
    cuser: this.props.userName,
    luser: this.props.userName,
    ma_cty: this.props.ma_cty,
    noneEdit: false,
  };

  isEditAction = () => {
    const { Master } = this.props;
    this.setState({
      SITE_NAME: Master.SITE_NAME,
      ADDRESS: Master.ADDRESS,
      SITE_PARENT: Master.SITE_PARENT,
      LEVEL: Master.LEVEL,
      KSD: Master.KSD,
    });
  };

  componentDidMount = () => {
    if (!_.isEmpty(this.props.Master)) {
      this.isEditAction();
      this.props.noneEdit ? this.setState({ noneEdit: true }) : <></>;
    }
  };
  onCheckboxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };
  handleOk = async () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      await this.props.getItemActions(this.state);
    }
  };
  handleChangeCN = async (value, name) => {
    await this.setState({ [name]: value });
    if (name === "SITE_PARENT") {
      this.state[name] !== ""
        ? this.setState({ LEVEL: 1 })
        : this.setState({ LEVEL: 0 });
    }
  };
  render() {
    const { isVisible, title, Master, listSite } = this.props;
    const { loading, noneEdit, SITE_NAME, ADDRESS, KSD } = this.state;
    return (
      <div>
        <Drawer
          title={title}
          width={widthDrawer}
          onClose={this.handleCancel}
          visible={isVisible}
          maskClosable={false}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                form="submit-form"
                key="submit"
                htmlType="submit"
                style={{
                  display: noneEdit ? "none" : "inline",
                  marginRight: 8,
                }}
              >
                Lưu
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <Collapse
              defaultActiveKey={["1", "2"]}
              ghost
              expandIconPosition="right"
              className="site-form"
            >
              <Panel header="Thông tin chung" key="1">
                <Form
                  id="submit-form"
                  onFinish={this.handleOk}
                  layout="vertical"
                  validateMessages={validateMessages}
                >
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <DHSInput
                        label="Tên chi nhánh"
                        name="SITE_NAME"
                        value={SITE_NAME}
                        getValueChange={this.handleChangeText}
                        disable={noneEdit}
                      />
                    </Col>
                    <Col span={12}>
                      <DHSInput
                        label="Địa chỉ"
                        name="ADDRESS"
                        value={ADDRESS}
                        getValueChange={this.handleChangeText}
                        disable={noneEdit}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <DHSSelectOpt
                        label="Chi nhánh cha"
                        array={listSite}
                        valueEmpty={true}
                        valueOpt={"SITE_ID"}
                        valueRender={"SITE_NAME"}
                        onChange={(value) =>
                          this.handleChangeCN(value, "SITE_PARENT")
                        }
                        disable={noneEdit}
                      />
                    </Col>
                    <Col span={12}>
                      <DHSCheckBox
                        label="Không sử dụng"
                        defaultChecked={KSD}
                        name="KSD"
                        onChange={this.onCheckboxChange}
                        disable={noneEdit}
                      />
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </Spin>
        </Drawer>
      </div>
    );
  }
  handleChangeText = (params) => {
    this.setState({
      [params.name]: params.value,
    });
  };
  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false, editSuccess: false });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listSite: state.SiteReducer.listSite,
    checkInsert: state.SiteReducer.checkInsert,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteForm);
