import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Input,
  AutoComplete,
  Descriptions,
  InputNumber,
  Divider,
  Button,
  Spin,
  Dropdown,
  Menu,
} from "antd";
import { SearchOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import _ from "lodash";
import DHSDataGrid from "../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import "./index.css";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import * as actionSellPrice from "../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import * as actionsCus from "../../../../redux/actions/Category/customer/index";
import * as actions from "../../../../redux/actions/Sales/packingSlip/index";
import { getListItemGroup } from "../../../../redux/actions/Category/ItemGroup/index";
import { FormatMoney } from "../../../controller/Format";
import moment from "moment";
import ReactToPrint from "react-to-print";
import Rp04 from "../../../../common/DHSControl/DHSTemplateRP/rp04/rp04";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import ModalAddCustomer from "./Modal/AddCustomers/AddCustomers";
import ModalAddProd from "./Modal/AddProducts/AddProducts";
import ModalFilterInvoices from "./Modal/FilterInvoices/FilterInvoices";
import ModalChangePrices from "./Modal/ChangePrices/ChangePrices";
POS.propTypes = {
  _objectCreate: PropTypes.object,
  columns: PropTypes.array,
  total: PropTypes.object,
};

POS.defaultProps = {
  _objectCreate: {
    HD: 1,
    IDCUSTOMER: "",
    TEN_KH: "",
    MA_CTY: "",
    LUSER: "",
    CUSER: "",
    DIA_CHI: "",
    EMAIL: "",
    TEL: "",
    MA_KH: "",
    GHI_CHU: "",
  },
  columns: [
    {
      caption: "STT",
      dataField: "STT",
      type: 0,
      width: 50,
    },
    {
      caption: "Mã sản phẩm",
      dataField: "MA_VT",
      type: 0,
    },
    {
      caption: "Tên sản phẩm",
      dataField: "TEN_VT",
      type: 0,
    },
    {
      caption: "Đơn vị tính",
      dataField: "DVT",
      type: 0,
      dataSource: {
        valueExpr: "DVT",
        displayExpr: "DVT",
      },
      width: 80,
    },
    {
      caption: "Số lượng",
      dataField: "SO_LUONG",
      type: 1,
      width: 100,
    },
    {
      caption: "Giá",
      dataField: "GIA_LE",
      format: "Money",
      type: 1,
      width: 100,
    },
    {
      caption: "Thành tiền",
      dataField: "TT",
      format: "Money",
      type: 0,
    },
  ],
  total: {
    SO_CT: "",
    HD: 1,
    T_TIEN2: 0,
    TL_CK: 0,
    TS_GTGT: 0,
    T_TT: 0,
    TIEN_CK_NT: 0,
    T_THUE: 0,
    KHACH_TRA: 0,
    CON_LAI: 0,
    THU_KHAC: 0,
  },
};

const renderTitle = (title) => <span>{title}</span>;

const renderItem = (arr) =>
  arr.map((item, index) => {
    return {
      value: item.IDITEM,
      value2: item.TEN_VT,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          key={index}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {item.TEN_VT}
            <span>{item.DVT}</span>
          </div>
          <span>{FormatMoney(item.GIA_LE)} VNĐ</span>
        </div>
      ),
    };
  });
function POS(props) {
  const { _objectCreate, columns, total } = props;
  const componentRef = useRef();
  const so_ct = useSelector((state) => state.SO2Reducer.soCt);
  const voucherNumberUpdate = useSelector(
    (state) => state.SO2Reducer.voucherNumberUpdate
  );
  const [isTotal, setTotal] = useState({ ...total });
  const [isListTabTotal, setListTabTotal] = useState([{ ...total }]);
  const [customer, setCustomer] = useState(_objectCreate);
  const [lstTabsCustomer, setTabLstCustomer] = useState([{ ..._objectCreate }]);
  const [openModalAddCus, setOpenModalAddCus] = useState(false);
  const [openModalAddProd, setOpenModalAddProd] = useState(false);
  const [openModalFilterInvoices, setOpenModalFilterInvoices] = useState(false);
  const [openModalChangePrices, setOpenModalChangePrices] = useState(false);
  const [isFocusNewRow, setFocusNewRow] = useState("");
  const [isPage, setPage] = useState([1]);
  const [isLoading, setLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState(1);
  const [listProduct, setListProduct] = useState([]);
  const [options, setOptions] = useState([]);
  const [isValueSearch, setValueSearch] = useState("");
  const [isTextBC, setTextBC] = useState("");
  const [filterListCustomer, setFilterListCustomer] = useState([]);
  const [lstPaymentSuccess, setPaymentSuccess] = useState([]);
  const [filterListItem, setFilterListItem] = useState([]);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const newItem = useSelector((state) => state.SO2Reducer.newItem);
  const listCustomer = useSelector(
    (state) => state.SICustomerReducer.listCustomer
  );
  const f3Ref = useRef(null);
  const f4Ref = useRef(null);
  const f7Ref = useRef(null);
  const f9Ref = useRef(null);

  const dispatch = useDispatch();
  const FullListItem = useSelector(
    (state) => state.SellPriceReducer.lookupSelectedItem
  );
  const listItemGroup = useSelector((state) => [
    ...state.ItemGroupReducer.listItemGroup,
    { IDGROUP: "", TEN_NHVT: "Chưa phân loại sản phẩm" },
  ]);
  useEffect(() => {
    dispatch(actions.getSoCt({ ma_cty }));
  }, [voucherNumberUpdate]);

  useEffect(() => {
    if (!_.isEmpty(so_ct)) {
      let result = isListTabTotal.map((item) => {
        if (item.HD === tabSelected) {
          item.SO_CT = so_ct;
          return item;
        }
        return item;
      });
      setListTabTotal(result);
    }
  }, [so_ct]);

  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(newItem)) {
      setPaymentSuccess([...lstPaymentSuccess, newItem.SO_CT]);
      openNotificationWithIcon("success", "Thanh toán thành công !");
      dispatch(actions.clearCache());
    }
  }, [newItem]);

  useEffect(() => {
    dispatch(
      actionSellPrice.getLookupSelectedItem({
        ma_cty,
        idcustomer: "",
        ma_nt: "VND",
        ngay_ct: moment().add(7, "hours"),
      })
    );
    dispatch(getListItemGroup({ ma_cty }));
    dispatch(
      actionsCus.getListCustomerAR({
        modelM: { ma_cty },
      })
    );
    window.onkeydown = function (e) {
      let code = e.keyCode ? e.keyCode : e.which;
      if (code === 114) {
        e.preventDefault();
        f3Ref.current.focus();
      }
      if (code === 115) {
        e.preventDefault();
        f4Ref.current.focus();
      }
      if (code === 118) {
        e.preventDefault();
        f7Ref.current.click();
      }
      if (code === 120) {
        e.preventDefault();
        f9Ref.current.click();
      }
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (!_.isEmpty(isValueSearch)) {
      timeoutId = setTimeout(() => {
        setTextBC(isValueSearch);
      }, 1500);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isValueSearch]);

  useEffect(() => {
    if (isTextBC !== "") {
      FullListItem.map((item) => {
        if (item.SKU === isTextBC) {
          const obj = _.filter(listProduct, (x) => x.IDITEM === item.IDITEM)[0];
          if (!_.isUndefined(obj)) {
            const result = listProduct.map((item, index) => {
              if (item.STT_REC0 === obj.STT_REC0) {
                item.SO_LUONG++;
              }
              return item;
            });
            setListProduct(result);
            setValueSearch("");
          } else {
            onSelectedAutoComplete(item.IDITEM);
          }
        }
      });
      setTextBC("");
    }
  }, [isTextBC]);

  useEffect(() => {
    if (!_.isEmpty(FullListItem)) {
      if (!_.isEmpty(listProduct)) {
        _.filter(listProduct, (x) => x.HD === tabSelected).map(
          async (item, index) => {
            item.STT = index + 1;
            if (_.isUndefined(item.STT_REC)) {
              await calc(item);
            }
          }
        );
      }
      setFilterListItem(FullListItem);
    }
  }, [FullListItem]);

  useEffect(() => {
    if (!_.isEmpty(filterListItem)) {
      let options = [];
      _.map(listItemGroup, (item, index) => {
        options.push({
          label: renderTitle(item.TEN_NHVT),
          options: renderItem(
            _.filter(filterListItem, (x) => x.IDGROUP === item.IDGROUP)
          ),
        });
      });
      setOptions(options);
    }
  }, [filterListItem]);

  useEffect(() => {
    console.log(listProduct);
    _.filter(listProduct, (x) => x.HD === tabSelected).map(
      async (item, index) => {
        item.STT = index + 1;
        if (_.isUndefined(item.STT_REC)) {
          await calc(item);
        }
      }
    );
  }, [listProduct]);

  const deleteTabPage = (item) => {
    let filter = _.filter(isPage, (x) => x !== item);
    setTabSelected("");
    setListProduct(_.filter(listProduct, (x) => x.HD !== item));
    setTabLstCustomer(_.filter(lstTabsCustomer, (x) => x.HD !== item));
    setListTabTotal(_.filter(isListTabTotal, (x) => x.HD !== item));
    setPage(filter);
  };

  const onSelectedAutoComplete = (value) => {
    let obj = _.filter(FullListItem, (x) => x.IDITEM === value)[0];
    obj.STT_REC0 = Math.random().toString(36).substr(2, 9).toUpperCase();
    obj.TT = obj.SO_LUONG * obj.GIA_LE;
    setListProduct([...listProduct, { ...obj, HD: tabSelected }]);
    setValueSearch("");
  };

  const hanleRowChange = async (params) => {
    if (params[0].type === "remove") {
      let arr = listProduct.filter((x) => x.STT_REC0 !== params[0].key);
      setListProduct(arr);
    } else {
      let itemChange = listProduct.find((x) => x.STT_REC0 === params[0].key);
      await calc(itemChange);
    }
  };

  const getCustomer = (value) => {
    if (!_.isEmpty(value.TEN_KH)) {
      let result = lstTabsCustomer.map((item) => {
        if (item.HD === tabSelected) {
          return { ...item, ...value };
        }
        return item;
      });
      setTabLstCustomer(result);
    }
  };

  useEffect(() => {
    let success = false;
    _.map(lstTabsCustomer, (item) => {
      if (item.HD === tabSelected) {
        success = true;
        setCustomer(item);
      }
    });
    if (!success) {
      setCustomer(_objectCreate);
    }
  }, [lstTabsCustomer]);

  const calc = (data) => {
    setTimeout(() => {
      data.GIA_NT2 = data.GIA_LE;
      data.TIEN_NT2 = data.SO_LUONG * data.GIA_LE || 0;
      data.TIEN_CK_NT = (data.TIEN_NT2 * data.TL_CK) / 100 || 0;
      data.TT = data.TIEN_NT2 - data.TIEN_CK_NT;
      data.TT_NT = data.TT;
      let T_TIEN2 = _.filter(listProduct, (x) => x.HD === tabSelected).reduce(
        (a, b) => a + (b["TT"] || 0),
        0
      );
      let T_THUE = (T_TIEN2 * isTotal.TS_GTGT) / 100;
      getTotalFromList({
        ...isTotal,
        T_TIEN2,
        T_THUE: T_THUE,
        T_TT: T_TIEN2,
        CON_LAI: T_TIEN2 - isTotal.KHACH_TRA,
      });
    }, 100);
  };

  const paymentChange = (value, name, type) => {
    let TT = isTotal.T_TIEN2;
    let TongTra = isTotal.KHACH_TRA + isTotal.TIEN_CK_NT;
    let cashBack =
      type === 1
        ? TT + isTotal.THU_KHAC - isTotal.KHACH_TRA
        : type === 3
        ? TT + isTotal.THU_KHAC - isTotal.TIEN_CK_NT
        : TT;
    getTotalFromList({
      ...isTotal,
      [name]: value,
      CON_LAI: type === 2 ? cashBack + value - TongTra : cashBack - value,
    });
  };

  const getTotalFromList = (total) => {
    let result = isListTabTotal.map((item) => {
      if (item.HD === tabSelected) {
        return total;
      }
      return item;
    });
    setListTabTotal(result);
  };

  const selectedTab = async (item) => {
    await setTotal(_.filter(isListTabTotal, (x) => x.HD === item)[0] || total);
    await setCustomer(
      _.filter(lstTabsCustomer, (x) => x.HD === item)[0] || _objectCreate
    );
    await setTabSelected(item);
    await setFilterListCustomer(listCustomer);
  };

  const onPayment = () => {
    if (_.isEmpty(customer.TEN_KH) || _.isEmpty(listProduct)) {
      openNotificationWithIcon(
        "error",
        "Vui lòng nhập đầy đủ thông tin hóa đơn !"
      );
    } else if (isTotal.KHACH_TRA === 0) {
      openNotificationWithIcon("error", "Vui lòng nhập số tiền khách đã trả !");
    } else {
      setLoading(true);
      const PackingSlip = {
        modelM: {
          ...isTotal,
          ...customer,
          ...{
            ma_cty,
            ty_gia: 1,
            ngay_ct: moment(),
            trang_thai: "1",
            ma_nt: "VND",
            cuser: userName,
            luser: userName,
          },
        },
        arrayCt: listProduct,
        arrayAddress: [],
      };
      dispatch(actions.addNewPackingSlip(PackingSlip));
    }
  };

  const plusTabs = () => {
    const newTab = isPage[isPage.length - 1] + 1 || 1;
    setPage([...isPage, newTab]);
    setTabSelected(newTab);
    setListTabTotal([...isListTabTotal, { ...total, HD: newTab }]);
    setTabLstCustomer([...lstTabsCustomer, { ..._objectCreate, HD: newTab }]);
    dispatch(
      actions.voucherNumberUpdate({
        ma_cty,
        YEAR: moment().year(),
        MONTH: moment().month() + 1,
        SEQUENCE: so_ct.split("-")[1],
      })
    );
  };

  useEffect(() => {
    let success = false;
    _.map(isListTabTotal, (item) => {
      if (item.HD === tabSelected) {
        success = true;
        setTotal(item);
      }
    });
    !success ? setTotal(total) : "";
  }, [isListTabTotal]);

  const menu = (arr) => {
    return (
      <Menu className="_posMenuDropDown">
        {arr.map((item, index) => {
          return (
            <Menu.Item
              key={index}
              onClick={() => getCustomer(item)}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>{item.TEN_KH}</span>
              <span>{item.TEL}</span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  useEffect(() => {
    if (!_.isEmpty(listCustomer)) {
      setFilterListCustomer(listCustomer);
    }
  }, [listCustomer]);

  const searchCustomer = (e) => {
    const data = _.filter(listCustomer, (item) => {
      if (
        item.TEN_KH.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    setFilterListCustomer(data);
    setCustomer({ ...customer, TEN_KH: e.target.value });
  };

  const getOldInvoices = async (oldTotal, oldCustomer, oldLstProduct) => {
    setPaymentSuccess([...lstPaymentSuccess, oldTotal.SO_CT]);
    const newTab = isPage[isPage.length - 1] + 1 || 1;
    setPage([...isPage, newTab]);
    setTabSelected(newTab);
    setListTabTotal([...isListTabTotal, { ...total, ...oldTotal, HD: newTab }]);
    setTabLstCustomer([
      ...lstTabsCustomer,
      { ..._objectCreate, ...oldCustomer, HD: newTab },
    ]);
    await oldLstProduct.map(async (item, index) => {
      item.GIA_LE = item.GIA_NT2;
      item.HD = newTab;
      item.STT = index + 1;
    });
    await setListProduct([...listProduct, ...oldLstProduct]);
  };

  const handleSearchAutocomplete = async (value) => {
    const data = await _.filter(FullListItem, (item) => {
      if (item.TEN_VT.toString().toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
      return false;
    });
    setFilterListItem(data);
  };
  return (
    <div className="POS">
      <Spin spinning={isLoading}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div className="_posHeader">
              <AutoComplete
                dropdownMatchSelectWidth={600}
                style={{ width: "30%", marginRight: 5 }}
                options={_.isEmpty(isValueSearch) ? [] : options}
                onSelect={(value, option) => onSelectedAutoComplete(value)}
                onSearch={handleSearchAutocomplete}
                onChange={(value) => setValueSearch(value)}
                className="_inputCusPos"
                value={isValueSearch}
                disabled={
                  _.isString(tabSelected) ||
                  lstPaymentSuccess.includes(isTotal.SO_CT)
                }
                onKeyDown={(e) =>
                  e.keyCode === 13
                    ? onSelectedAutoComplete(filterListItem[0].IDITEM)
                    : ""
                }
                allowClear={true}
              >
                <Input
                  ref={f3Ref}
                  prefix={<SearchOutlined />}
                  suffix={
                    <PlusOutlined onClick={() => setOpenModalAddProd(true)} />
                  }
                  placeholder="Tìm kiếm sản phẩm (F3)"
                  value={isValueSearch}
                  style={{ border: "none" }}
                />
              </AutoComplete>
              <i
                className="fa fa-barcode"
                style={{
                  fontSize: 24,
                  marginRight: 5,
                  color: "white",
                  cursor: "pointer",
                }}
              ></i>
              <div className="_posPages" style={{ width: "60%" }}>
                {isPage.map((item, index) => {
                  return (
                    <div
                      className={
                        tabSelected === item
                          ? "_posTabPages active"
                          : "_posTabPages"
                      }
                    >
                      <span key={index} onClick={() => selectedTab(item)}>
                        Hóa đơn {item}{" "}
                      </span>
                      <CloseOutlined
                        onClick={() => deleteTabPage(item)}
                        style={{ color: "gray" }}
                      />
                    </div>
                  );
                })}
                <PlusOutlined
                  onClick={plusTabs}
                  size={24}
                  style={{ margin: 0, marginLeft: 10 }}
                />
              </div>
            </div>
          </Col>
          <Col span={16}>
            <div className="_posTable">
              <div className="_posDatagrid">
                <DHSDataGrid
                  column={columns}
                  data={_.filter(listProduct, (x) => x.HD === tabSelected)}
                  dataKey={"STT_REC0"}
                  showColumnLines={false}
                  selectionChanged={(value) => {
                    setFocusNewRow(value[0]);
                  }}
                  hanleRowChange={hanleRowChange}
                  allowDeleting={true}
                  focusNewRow={isFocusNewRow}
                  showColumnHeaders={true}
                  disabled={
                    _.isString(tabSelected) ||
                    lstPaymentSuccess.includes(isTotal.SO_CT)
                  }
                />
              </div>
              <div className="_posButton">
                <Button
                  className="__posButton"
                  onClick={() => setOpenModalFilterInvoices(true)}
                >
                  Tra cứu hóa đơn
                </Button>
                <Button
                  className="__posButton"
                  onClick={() => setOpenModalChangePrices(true)}
                >
                  Đổi giá bán hàng
                </Button>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="_posPayment">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xl={24} span={24} xs={24} className="_colAddCustomer">
                  <div>
                    <b>Thông tin khách hàng</b>
                    <Dropdown
                      overlay={menu(filterListCustomer)}
                      trigger={["click"]}
                      disabled={
                        _.isString(tabSelected) ||
                        lstPaymentSuccess.includes(isTotal.SO_CT)
                      }
                    >
                      <Input
                        className="_inputCusPos"
                        prefix={<SearchOutlined />}
                        suffix={
                          <PlusOutlined
                            onClick={() => setOpenModalAddCus(true)}
                          />
                        }
                        placeholder="Thêm khách hàng vào đơn (F4)"
                        onChange={searchCustomer}
                        value={customer.TEN_KH}
                        ref={f4Ref}
                      />
                    </Dropdown>
                    <Descriptions size="default">
                      <Descriptions.Item label="Địa chỉ" span={3}>
                        {customer.DIA_CHI}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Số điện thoại"
                        span={3}
                        style={{ width: 130 }}
                      >
                        {customer.TEL}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </Col>
                <Col xl={24} span={24} xs={24} className="_colPayment">
                  <div>
                    <h2>
                      <b>Thanh toán</b>
                    </h2>
                    <Descriptions size="default">
                      <Descriptions.Item
                        label={`Tổng số lượng sản phẩm`}
                        span={3}
                      >
                        {
                          _.filter(listProduct, (x) => x.HD === tabSelected)
                            .length
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label={`Tổng tiền`} span={3}>
                        {FormatMoney(isTotal.T_TIEN2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Giảm giá" span={3}>
                        <InputNumber
                          className="_inputCusPos"
                          min={0}
                          name="TIEN_CK_NT"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          onChange={(value) =>
                            paymentChange(value, "TIEN_CK_NT", 1)
                          }
                          value={isTotal.TIEN_CK_NT}
                          style={{ width: "100%", textAlign: "right" }}
                          disabled={
                            _.isString(tabSelected) ||
                            lstPaymentSuccess.includes(isTotal.SO_CT)
                          }
                        />
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<b>Khách phải trả</b>}
                        span={3}
                        style={{ fontWeight: "bold", fontSize: 16 }}
                      >
                        {FormatMoney(isTotal.T_TIEN2 - isTotal.TIEN_CK_NT)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Tiền khách đưa" span={3}>
                        <InputNumber
                          className="_inputCusPos"
                          min={0}
                          name="KHACH_TRA"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          onChange={(value) =>
                            paymentChange(value, "KHACH_TRA", 3)
                          }
                          value={isTotal.KHACH_TRA}
                          style={{ width: "100%" }}
                          disabled={
                            _.isString(tabSelected) ||
                            lstPaymentSuccess.includes(isTotal.SO_CT)
                          }
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Tiền thừa" span={3}>
                        {FormatMoney(
                          isTotal.KHACH_TRA +
                            isTotal.TIEN_CK_NT -
                            isTotal.T_TIEN2
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    <p style={{ color: "gray", fontSize: 12 }}>
                      Giá đã bao gồm 10% thuế VAT
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  <Divider style={{ margin: 10 }} />
                  <div className="_posBtn">
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          style={{
                            width: "30%",
                            height: 50,
                            fontWeight: "bold",
                            background: "lightgray",
                          }}
                          type="ghost"
                          disabled={_.isString(tabSelected)}
                          ref={f7Ref}
                        >
                          In ( F7 )
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                    <Button
                      className="_posBtnPayment"
                      type="primary"
                      onClick={onPayment}
                      disabled={
                        _.isString(tabSelected) ||
                        lstPaymentSuccess.includes(isTotal.SO_CT)
                      }
                      ref={f9Ref}
                    >
                      Thanh toán ( F9 )
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
      {openModalAddCus ? (
        <ModalAddCustomer
          isVisible={openModalAddCus}
          checkVisible={(params) => setOpenModalAddCus(params)}
          getCustomer={getCustomer}
        />
      ) : (
        <></>
      )}
      {openModalAddProd ? (
        <ModalAddProd
          isVisible={openModalAddProd}
          checkVisible={(params) => setOpenModalAddProd(params)}
        />
      ) : (
        <></>
      )}
      {openModalFilterInvoices ? (
        <ModalFilterInvoices
          isVisible={openModalFilterInvoices}
          checkVisible={(params) => setOpenModalFilterInvoices(params)}
          getOldInvoices={getOldInvoices}
        />
      ) : (
        <></>
      )}
      {openModalChangePrices ? (
        <ModalChangePrices
          isVisible={openModalChangePrices}
          checkVisible={(params) => setOpenModalChangePrices(params)}
          listProduct={listProduct}
        />
      ) : (
        <></>
      )}
      <div style={{ display: "none" }}>
        <Rp04
          ref={componentRef}
          isTotal={isTotal}
          customer={customer}
          listProduct={_.filter(listProduct, (x) => x.HD === tabSelected)}
          soCt={so_ct}
        />
      </div>
    </div>
  );
}

export default POS;
