import * as types from "../../../constants/ActionTypes";

var initialState = {
  lstInventory: [],
};

var InventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_INVENTORY: {
      return {
        ...state,
        lstInventory: action.payload,
      };
    }
    default:
      return state;
  }
};

export default InventoryReducer;
