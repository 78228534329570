import React, { useState, useEffect } from "react";
import SellPriceForm from "../sellPriceForm/sellPriceForm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";

export default function SellPriceCreate(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, history } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);

  const checkInsert = useSelector(
    (state) => state.SellPriceReducer.checkInsert
  );

  useEffect(() => {
    if (checkInsert) {
      openNotificationWithIcon("success", "Thêm giá bán thành công !");
      dispatch(actions.clearCache());
      dispatch(actions.getListSellPrice({ MA_CTY }));
      checkVisible(false);
    }
  }, [checkInsert]);

  const handleActions = (params) => {
    dispatch(actions.addNewSellPrice(params));
  };

  return (
    <SellPriceForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      handleActions={handleActions}
      title="Thêm mới giá bán"
    />
  );
}
