import * as types from "../../../constants/ActionTypes";

var initialState = {
  listPackingSlip: [],
  ctPackingSlip: [],
  phPackingSlip: {},
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  listDm: {},
  checkAddNewCustomer: false,
  listNewAddress: [],
  dsPhSO: [],
  dsCtSO: [],
  dsAddressSO: [],
  lstCtChooseFromSO: [],
  lstAddressChooseFromSO: [],
  checkDispatch: false,
  contactPackingSlip: [],
  dsCtDriver: [],
  lstSPType: [],
  voucherNumberUpdate: false,
};

var PackingSlipReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SO2: {
      return {
        ...state,
        listPackingSlip: action.payload,
      };
    }
    case types.LOOKUP_SO_SO1: {
      return {
        ...state,
        dsPhSO: action.payload.dsPh,
        dsCtSO: action.payload.dsCt,
        dsAddressSO: action.payload.dsAddress,
        checkDispatch: !state.checkDispatch,
      };
    }
    case types.FILT_SO2: {
      return {
        ...state,
        listPackingSlip: action.payload,
      };
    }
    case types.GET_PH_SO2: {
      return {
        ...state,
        phPackingSlip: action.payload,
      };
    }
    case types.GET_CT_SO2: {
      return {
        ...state,
        ctPackingSlip: action.payload,
      };
    }
    case types.GET_CUSTOMER_SO2: {
      return {
        ...state,
        customer: action.payload[0],
      };
    }
    case types.GET_LIST_CUSTOMER_SO2: {
      return {
        ...state,
        listCustomer: action.payload,
      };
    }
    case types.GET_SO_CT_SO2: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_SO2: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_SO2: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LIST_DM_SO2: {
      return {
        ...state,
        listStatus: action.payload.dm_trangthai_SO2,
        lstSPType: action.payload.dm_sp_type,
      };
    }
    case types.GET_LIST_CURRENCY_SO2: {
      return {
        ...state,
        listCurrency: action.payload,
      };
    }
    case types.GET_EXCHANGE_RATE_SO2: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_SO2: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_SO2: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctPackingSlip: [],
        phPackingSlip: {},
        newItem: null,
        lstCtChooseFromSO: [],
        lstAddressChooseFromSO: [],
        dsCtDriver: [],
        contactPackingSlip: [],
      };
    }
    case types.CLEAR_HANDLE_STT_SO2: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }
    case types.LIST_CT_CHOOSE_FROM_SO: {
      return {
        ...state,
        lstCtChooseFromSO: action.payload.dsCT,
        lstAddressChooseFromSO: action.payload.dsAddress,
      };
    }
    case types.GET_CONTACT_SO2: {
      return {
        ...state,
        contactPackingSlip: action.payload,
      };
    }
    case types.GET_DRIVER_SO2: {
      return {
        ...state,
        dsCtDriver: action.payload,
      };
    }
    case types.UPDATE_VOUCHER_NUMBER: {
      return {
        ...state,
        voucherNumberUpdate: !state.voucherNumberUpdate,
      };
    }
    default:
      return state;
  }
};

export default PackingSlipReducer;
