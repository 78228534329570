import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import _ from "lodash";
export default class DHSPrint extends Component {
  trigger = () => {
    return (
      <Button icon={<PrinterOutlined />} type="primary">
        In
      </Button>
    );
  };
  render() {
    const { PagePrint, onBeforeGetContent, onAfterPrint } = this.props;
    return (
      <div>
        <ReactToPrint
          trigger={this.trigger}
          content={() => this.componentRef}
          onAfterPrint={() => onAfterPrint()}
          onBeforeGetContent={() => onBeforeGetContent()}
        />
        <div style={{ display: "none" }}>
          <PagePrint
            ref={(el) => {
              this.componentRef = el;
            }}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}
