import * as types from "../../../constants/ActionTypes";

var initialState = {
  listItemSize: [],
};

var LstSizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_ITEM_SIZE: {
      return {
        ...state,
        listItemSize: action.payload,
      };
    }
    default:
      return state;
  }
};

export default LstSizeReducer;
