import React, { Component } from "react";
import { Row, Col, Form } from "antd";
import { DHSText } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
export default class Driver extends Component {
  render() {
    const { driver } = this.props;
    const driverInfo = _.isEmpty(driver) ? {} : driver[0];
    console.log(driverInfo);
    return (
      <div className="so2-detail-general" style={{ paddingLeft: 15 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12} xs={12} xl={12}>
            <Form layout="vertical">
              <DHSText label="Tên tài xế" value={driverInfo.DRIVER_NAME} />
              <DHSText label="Số điện thoại" value={driverInfo.PHONE} />
              <DHSText label="Email" value={driverInfo.EMAIL} />
            </Form>
          </Col>
          <Col className="gutter-row" span={12} xs={12} xl={12}>
            <Form layout="vertical">
              <DHSText label="Biển số" value={driverInfo.BIEN_SO} />
              <DHSText label="Ghi chú" value={driverInfo.GHI_CHU} />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
