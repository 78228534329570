import * as types from "../../constants/ActionTypes";

var initialState = {
  listMachine: [],
  listMachineActionState: [],
};

var ManufacturingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_MACHINE: {
      return {
        ...state,
        listMachine: action.payload,
      };
    }
    case types.GET_MACHINE_ACTION_STATE: {
      return {
        ...state,
        listMachineActionState: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ManufacturingReducer;
