import React, { Component } from "react";
import PieChart, {
  Series,
  Label,
  Connector,
  Legend,
  Title,
  CommonSeriesSettings,
  SeriesTemplate,
} from "devextreme-react/pie-chart";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Manufacturing/Manufacturing";
class Pie extends Component {
  formatLabel = (arg) => {
    const value = (parseInt(arg.value) / this.props.totalDuration) * 100;
    return `${_.round(value, 2)} %`;
  };
  customizePoint = (valueFromNameField) => {
    const { listMachineActionState } = this.props;
    var color = "";
    if (listMachineActionState.length > 0) {
      listMachineActionState.map((item, index) => {
        if (item.DESCRIPTION === valueFromNameField.argument) {
          color = item.BACKCOLOR;
        }
      });
    }
    return color !== "" ? { color: color } : {};
  };
  render() {
    const { legendVisible, title, dataSource } = this.props;
    return (
      <div>
        <PieChart
          id="pie"
          type="doughnut"
          dataSource={dataSource}
          customizePoint={this.customizePoint}
          resolveLabelOverlapping="shift"
        >
          <Title text={title} font={{ size: 18, color: "#1890ff" }} />
          <CommonSeriesSettings
            argumentField="state_description"
            valueField="duration"
          >
            <Label
              visible={true}
              customizeText={this.formatLabel}
              format="fixedPoint"
            >
              <Connector visible={true} width={0.5} />
            </Label>
          </CommonSeriesSettings>
          <SeriesTemplate nameField="machine_code" />
          {legendVisible ? (
            <></>
          ) : (
            <Legend
              horizontalAlignment="center"
              verticalAlignment="bottom"
              border={{ visible: true }}
            />
          )}
        </PieChart>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listMachineActionState: state.ManufacturingReducer.listMachineActionState,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    GetMachinesActionState: (query) => {
      dispatch(actions.GetMachinesActionState(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pie);
