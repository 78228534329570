import React, { Component } from "react";
import { Form, Select, Spin, Drawer, Button, Row, Col } from "antd";
import "./index.css";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../../../../../redux/actions/Sales/salesOrder/salesOrder";
import * as actionsInventory from "../../../../../redux/actions/Category/Inventory/index";
import * as actionsItem from "../../../../../redux/actions/Category/Item/index";
import * as actionsApproval from "../../../../../redux/actions/Sales/approvalSalesOrder/approvalSalesOrder";

import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
import { periodData } from "../../../../controller/DataSample";
import { widthDrawer } from "../../index";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
class SO1Filter extends Component {
  state = {
    visible: false,
    loading: false,
    period: ("0" + (moment().month() + 1)).slice(-2),
    IDcustomer: "",
    formDate: moment().startOf("month").add(7, "hours"),
    toDate: moment().endOf("month").add(7, "hours"),
    so_ct1: "",
    so_ct2: "",
    trang_thai: "",
    ma_kho: "",
    iditem: "",
  };

  componentDidMount = () => {
    this.props.getListNeed({ ma_cty: this.props.ma_cty });
  };

  handleOk = (e) => {
    const { approvalSrc } = this.props;
    approvalSrc
      ? this.props.getListApprovalSalesOrder({
          ma_cty: this.props.ma_cty,
          idcustomer: this.state.IDcustomer,
          cdate: this.state.formDate,
          ldate: this.state.toDate,
          trang_thai: this.state.trang_thai,
          so_ct1: this.state.so_ct1,
          so_ct2: this.state.so_ct2,
          ma_kho: this.state.ma_kho,
          iditem: this.state.iditem,
        })
      : this.props.filt({
          modelM: {
            ma_cty: this.props.ma_cty,
            idcustomer: this.state.IDcustomer,
            cdate: this.state.formDate,
            ldate: this.state.toDate,
            trang_thai: this.state.trang_thai,
          },
          so_ct1: this.state.so_ct1,
          so_ct2: this.state.so_ct2,
          ma_kho: this.state.ma_kho,
          iditem: this.state.iditem,
        });
    this.props.checkVisible(false);
  };
  handleChange = (value, name) => {
    this.setState({ [name]: value });
  };
  handleCancel = (e) => {
    this.props.checkVisible(false);
  };
  onPeriodChange = (value) => {
    switch (value) {
      case "q1": {
        this.setStateForPeriod(value, "01", "03");
        break;
      }
      case "q2": {
        this.setStateForPeriod(value, "04", "06");
        break;
      }
      case "q3": {
        this.setStateForPeriod(value, "07", "09");
        break;
      }
      case "q4": {
        this.setStateForPeriod(value, "10", "12");
        break;
      }
      case "d6t": {
        this.setStateForPeriod(value, "01", "06");
        break;
      }
      case "c6t": {
        this.setStateForPeriod(value, "07", "12");
        break;
      }
      case "cn": {
        this.setStateForPeriod(value, "01", "12");
        break;
      }
      case "formTo": {
        this.setStateForPeriod(value, "01", "12");
        break;
      }
      default: {
        this.setStateForPeriod(value, value, value);
        break;
      }
    }
  };
  handleChangeText = (e) => {
    this.setState({
      [e.name]: e.value,
    });
  };
  onChangeTime = (params) => {
    this.setState({ [params.name]: params.value });
  };
  render() {
    const {
      isVisible,
      listCustomer,
      listStatus,
      lstInventory,
      listItem,
    } = this.props;
    const { loading, so_ct1, so_ct2, formDate, toDate, period } = this.state;
    return (
      <Drawer
        title="Tìm kiếm"
        width={widthDrawer}
        onClose={this.handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Tìm kiếm
            </Button>
          </div>
        }
      >
        <Spin spinning={loading}>
          <Form
            id="submit-form"
            onFinish={this.handleOk}
            layout="vertical"
            validateMessages={validateMessages}
            className="so1-filter"
          >
            <DHSSelectOpt
              label="Kì báo cáo"
              name="period"
              array={periodData}
              valueOpt={"value"}
              valueRender={"name"}
              defaultValue={period.toString()}
              onChange={this.onPeriodChange}
            />
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <DHSDatePicker
                  label="Từ ngày"
                  value={formDate}
                  nameState="formDate"
                  disable={period === "formTo" ? false : true}
                  getValueChange={this.onChangeTime}
                />
              </Col>
              <Col span={12}>
                <DHSDatePicker
                  label="Đến ngày"
                  value={toDate}
                  nameState="toDate"
                  disable={period === "formTo" ? false : true}
                  getValueChange={this.onChangeTime}
                />
              </Col>
            </Row>
            <DHSSelectOpt
              label="Khách hàng"
              name="IDcustomer"
              array={listCustomer}
              valueOpt={"idcustomer"}
              valueRender={"ten_kh"}
              onChange={(value) => this.handleChange(value, "IDcustomer")}
            />
            <DHSSelectOpt
              label="Trạng thái"
              name="trang_thai"
              array={listStatus}
              valueOpt={"trang_thai"}
              valueRender={"mo_ta"}
              onChange={(value) => this.handleChange(value, "trang_thai")}
            />
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <DHSInput
                  label="Số phiếu từ"
                  name="so_ct1"
                  value={so_ct1}
                  getValueChange={this.handleChangeText}
                />
              </Col>
              <Col span={12}>
                <DHSInput
                  label="Số phiếu đến"
                  name="so_ct2"
                  value={so_ct2}
                  getValueChange={this.handleChangeText}
                />
              </Col>
            </Row>
            <DHSSelectOpt
              label="Kho"
              name="ma_kho"
              array={lstInventory}
              valueOpt={"MA_KHO"}
              valueRender={"TEN_KHO"}
              onChange={(value) => this.handleChange(value, "ma_kho")}
            />
            <DHSSelectOpt
              label="Vật tư"
              name="iditem"
              array={listItem}
              valueOpt={"iditem"}
              valueRender={"ten_vt"}
              onChange={(value) => this.handleChange(value, "iditem")}
            />
          </Form>
        </Spin>
      </Drawer>
    );
  }
  setStateForPeriod = (periodName, value1, value2) => {
    this.setState({
      period: periodName,
      formDate: moment(`${moment().year()}-${value1}-01`).startOf("month"),
      toDate: moment(`${moment().year()}-${value2}-01`).endOf("month"),
    });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listCustomer: state.SO1Reducer.listCustomer,
    listStatus: state.SO1Reducer.listStatus,
    lstInventory: state.InventoryReducer.lstInventory,
    listItem: state.ItemReducer.listItem,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getListNeed: (ma_cty) => {
      dispatch(actionsItem.getListItem(ma_cty));
      dispatch(actionsInventory.getListInventory(ma_cty));
    },
    getListApprovalSalesOrder: (query) => {
      dispatch(actionsApproval.getListApprovalSalesOrder(query));
    },
    filt: (query) => {
      dispatch(actions.filt(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SO1Filter);
