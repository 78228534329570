import React, { Component } from "react";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { Button, Space, Spin, Popconfirm, PageHeader } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Category/site/site";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import "./index.css";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import SiteCreate from "./SiteCreate/siteCreate";
import SiteDetail from "./SiteDetail/SiteDetail";
import SiteEdit from "./SiteEdit/SiteEdit";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const objectColumn = {
  SITE_NAME: { name: "Tên chi nhánh", width: 200 },
  ADDRESS: { name: "Địa chỉ", width: 300 },
  LEVEL: { name: "Cấp", width: 100 },
  SITE_PARENT: { name: "Chi nhánh cha", width: 200 },
};
class Site extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    SITE_ID: "",
    openDrawerCreate: false,
    openDrawerEdit: false,
    openDrawerDetail: false,
    Master: {},
  };
  componentDidMount = () => {
    this.loadListSite();
  };
  componentDidUpdate = (prevProps) => {
    const { listSite, checkDelete, clearCache } = this.props;
    if (!_.isEqual(listSite, prevProps.listSite)) {
      this.setState({
        data: listSite,
        fullData: listSite,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ loading: false, SITE_ID: "", Master: {} });
      this.loadListSite();
      clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };
  confirmDelete(SITE_ID) {
    this.setState({ loading: true });
    this.props.deleteSite({
      ma_cty: this.props.ma_cty,
      SITE_ID: SITE_ID,
    });
  }
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.setState({ openDrawerEdit: true });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn chi nhánh cần sửa !");
    }
  };

  render() {
    const {
      loading,
      data,
      customColumns,
      SITE_ID,
      openDrawerCreate,
      openDrawerEdit,
      Master,
      openDrawerDetail,
      fullData,
    } = this.state;
    return (
      <div className="Site" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Chi nhánh / phân xưởng"
            onBack={() => this.props.history.push("/Category")}
          />
          <Space style={{ marginBottom: 16, marginTop: 10 }}>
            <Button
              onClick={() => this.setState({ openDrawerCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn muốn xóa chi nhánh ?"
              onConfirm={() => this.confirmDelete(SITE_ID)}
              okText="Đồng ý"
              cancelText="Hủy"
              disabled={SITE_ID === "" ? true : false}
            >
              <Button
                onClick={() => {
                  if (SITE_ID === "") {
                    openNotificationWithIcon(
                      "error",
                      "Vui lòng chọn chi nhánh cần xóa !"
                    );
                  }
                }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            </Popconfirm>
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListSite();
            }}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="SITE_ID"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openDrawerCreate ? (
            <SiteCreate
              isVisible={openDrawerCreate}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
          {openDrawerEdit ? (
            <SiteEdit
              isVisible={openDrawerEdit}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <SiteDetail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.setState({
      openDrawerCreate: params.isVisible,
      loading: params.isVisible,
      openDrawerDetail: params.isVisible,
      openDrawerEdit: params.isVisible,
      openDrawerFilter: params.isVisible,
    });
    params.editSuccess ? this.loadListSite(this.state.moduleID) : <></>;
    params.editSuccess ? this.setState({ SITE_ID: "", Master: {} }) : <></>;
  };
  getDataRow = (params) => {
    this.setState({ Master: params, SITE_ID: params.SITE_ID });
  };
  loadListSite = () => {
    const { listSite } = this.props;
    this.setState({ loading: true });
    if (this.state.customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      this.setState({ customColumns: result });
    }
    listSite.length = 0;
    this.props.getListSite({ ma_cty: this.props.ma_cty });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listSite: state.SiteReducer.listSite,
    checkDelete: state.SiteReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListSite: (ma_cty) => {
      dispatch(actions.getListSite(ma_cty));
    },
    deleteSite: (query) => {
      dispatch(actions.deleteSite(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Site);
