import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../redux/actions/Purchases/purchaseReceipt/purchaseReceipt";
import * as actionsInventory from "../../../../../redux/actions/Category/Inventory/index";
import * as actionsItem from "../../../../../redux/actions/Category/Item/index";
import { periodData } from "../../../../controller/DataSample";
import {
  onPeriodChange,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
Po2Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

Po2Filter.defaultProps = {
  _objectFilter: {
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    IDCUSTOMER: "",
    FORMDATE: moment().startOf("month").add(7, "hours"),
    TODATE: moment().endOf("month").add(7, "hours"),
    SO_CT1: "",
    SO_CT2: "",
    MA_KHO: "",
    IDITEM: "",
    MA_GD: "",
    INCOTERM_ID: "",
    TK_PT: "",
  },
};
export default function Po2Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const lstInventory = useSelector(
    (state) => state.InventoryReducer.lstInventory
  );
  const PO2State = useSelector((state) => state.PO2Reducer);
  const listItem = useSelector((state) => state.ItemReducer.listItem);
  const [objFilter, setObjFilter] = useState(_objectFilter);

  useEffect(() => {
    dispatch(actionsItem.getListItem({ ma_cty }));
    dispatch(actionsInventory.getListInventory({ ma_cty }));
  }, []);

  const handleCancel = (e) => {
    checkVisible(false);
  };
  const handleOk = () => {
    dispatch(
      actions.filt({
        modelM: {
          ma_cty,
          idcustomer: objFilter.IDCUSTOMER,
          cdate: objFilter.FORMDATE,
          ldate: objFilter.TODATE,
          INCOTERM_ID: objFilter.INCOTERM_ID,
          TK_PT: objFilter.TK_PT,
        },
        ...objFilter,
      })
    );
    checkVisible(false);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate.add(7, "hours"),
      TODATE: result.toDate.add(7, "hours"),
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Tìm kiếm"
        width={"30%"}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Tìm kiếm
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="po2-filter"
        >
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD.toString()}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FORMDATE}
                nameState="FORMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Nhà cung cấp"
            name="IDCUSTOMER"
            array={PO2State.listCustomer}
            valueOpt={"idcustomer"}
            valueRender={"ten_kh"}
            onChange={(value) => handleChange(value, "IDCUSTOMER")}
            valueEmpty={true}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSInput
                label="Số phiếu từ"
                name="SO_CT1"
                value={objFilter.SO_CT1}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Số phiếu đến"
                name="SO_CT2"
                value={objFilter.SO_CT2}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Tài khoản"
                array={PO2State.listAccount}
                valueOpt={"TK"}
                valueRender={"TEN_TK"}
                onChange={(value) => {
                  handleChangeText({ value, name: "TK_PT" });
                }}
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Điều kiện giao hàng"
                name="INCOTERM_ID"
                array={PO2State.listIncoterms}
                valueOpt={"INCOTERM_ID"}
                valueRender={"INCOTERM_NAME"}
                onChange={(value) =>
                  handleChangeText({ value, name: "INCOTERM_ID" })
                }
                valueEmpty={true}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Kho"
            name="MA_KHO"
            array={lstInventory}
            valueOpt={"MA_KHO"}
            valueRender={"TEN_KHO"}
            onChange={(value) => handleChange(value, "MA_KHO")}
            valueEmpty={true}
          />
          <DHSSelectOpt
            label="Vật tư"
            name="IDITEM"
            array={listItem}
            valueOpt={"iditem"}
            valueRender={"ten_vt"}
            onChange={(value) => handleChange(value, "IDITEM")}
            valueEmpty={true}
          />
        </Form>
      </Drawer>
    </div>
  );
}
