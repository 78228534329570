import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Row, Col, Form } from "antd";
import { FormatDate } from "../../../../../controller/Format";
import {
  DHSSelectOpt,
  DHSCheckBox,
  DHSText,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
import * as actions from "../../../../../../redux/actions/Inventory/MovementIssue/movementIssue";
const { Panel } = Collapse;
export default function General(props) {
  const dispatch = useDispatch();
  const { Master } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listCustomer = useSelector((state) => state.IN2Reducer.listCustomer);
  const sysExchange = useSelector((state) => state.IN2Reducer.sysExchange);
  useEffect(() => {
    dispatch(actions.getListsDm({ ma_cty }));
  }, []);

  useEffect(() => {
    let customer = listCustomer.find((x) => x.IDCUSTOMER === Master.IDCUSTOMER);
    if (!_.isUndefined(customer)) {
      Master.EMAIL = customer.EMAIL;
      Master.TEL = customer.TEL;
      Master.DIA_CHI = customer.DIA_CHI;
    }
  }, [listCustomer]);

  return (
    <div className="IN1-detail-general">
      <Collapse defaultActiveKey={["1"]} ghost expandIconPosition="right">
        <Panel header="Thông tin chung" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Tên khách hàng" value={Master.TEN_KH} />
                <DHSText label="Email" value={Master.EMAIL} />
                <DHSText label="Số điện thoại" value={Master.TEL} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Người liên hệ" value={Master.NGUOI_GD} />
                <DHSText label="Địa chỉ" value={Master.DIA_CHI} />
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <DHSCheckBox
                      label="Giá đích danh"
                      defaultChecked={Master.PX_GDD}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText
                  label="Ngày chứng từ"
                  value={FormatDate(Master.NGAY_CT)}
                />
                <DHSText label="Ngày lập" value={FormatDate(Master.NGAY_LCT)} />
                <DHSSelectOpt
                  label="Loại phiếu"
                  array={sysExchange}
                  valueOpt={"MA_GD"}
                  valueRender={"MO_TA"}
                  defaultValue={Master.MA_GD}
                  disable={true}
                />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Loại tiền tệ" value={Master.MA_NT} />
                <DHSText label="Tỷ giá" value={Master.TY_GIA} />
                <DHSText label="Diễn giải" value={Master.DIEN_GIAI} />
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
}
