import React, { Component } from "react";
import { Button, Space, Spin, PageHeader } from "antd";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Sales/packingSlip/index";
import * as actionsItem from "../../../../redux/actions/Category/Item/index";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import SO2Create from "./so2Create/so2Create";
import SO2Filter from "./so2Filter/so2Filter";
import SO2Detail from "./so2Detail/so2Detail";
import "./index.css";
import _ from "lodash";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import ButtonDelete from "../../../../common/DHSControl/DHSButton/btnDelete";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import ModalSelectRp from "./modalSelectRp/modalSelectRp";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import { PlusOutlined, EditOutlined, PrinterOutlined } from "@ant-design/icons";

const objectColumn = {
  SO_CT: { name: "Số phiếu xuất", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
};
class PackingSlip extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    openModalCreate: false,
    openDrawerFilter: false,
    openDrawerDetail: false,
    isModalVisible: false,
    rowId: "",
    Master: {},
  };
  loadListSO2 = () => {
    const { listPackingSlip } = this.props;
    this.setState({ loading: true });
    listPackingSlip.length = 0;
    if (this.state.customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      this.setState({ customColumns: result });
    }
    this.props.getListPackingSlip({ ma_cty: this.props.ma_cty });
  };
  componentDidMount = () => {
    this.loadListSO2();
    this.props.getListDm({ ma_cty: this.props.ma_cty });
    this.props.getListCurrency();
    this.props.getListCustomer({ ma_cty: this.props.ma_cty });
    this.props.getListItem({ ma_cty: this.props.ma_cty });
  };
  componentDidUpdate = async (prevProps) => {
    const { listPackingSlip, checkDelete } = this.props;
    if (!_.isEqual(listPackingSlip, prevProps.listPackingSlip)) {
      this.setState({
        data: this.props.listPackingSlip,
        fullData: this.props.listPackingSlip,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ Master: {} });
      this.loadListSO2();
      this.props.clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };

  confirmDelete = (params) => {
    this.setState({ loading: true });
    this.props.deleteAPackingSlip({ modelM: params });
  };

  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.props.getPHPackingSlip({
        stt_rec: Master.STT_REC,
        ma_cty: this.props.ma_cty,
      });
      this.props.history.push("/soVch02Edit", {
        Master: Master,
      });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn phiếu suất cần sửa !");
    }
  };

  render() {
    const {
      data,
      customColumns,
      openModalCreate,
      Master,
      openDrawerDetail,
      loading,
      openDrawerFilter,
      fullData,
      isModalVisible,
    } = this.state;
    return (
      <div className="packingSlip" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Phiếu xuất bán"
            onBack={() => this.props.history.push("/Sale")}
          />
          <Space className="topMenu">
            <Button
              onClick={() => this.setState({ openModalCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <ButtonDelete
              objDelele={Master}
              confirmDelete={this.confirmDelete}
            />
            <Button
              onClick={this.isPrint}
              icon={<PrinterOutlined />}
              disabled={_.isEmpty(Master)}
              style={{ background: "white" }}
            >
              In
            </Button>
            <ModalSelectRp
              isModalVisible={isModalVisible}
              checkVisible={this.checkVisible}
              Master={Master}
              {...this.props}
            />
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListSO2();
            }}
            openFiler={() => {
              this.setState({ openDrawerFilter: true });
            }}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="SO_CT"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openModalCreate ? (
            <SO2Create
              isVisible={openModalCreate}
              checkVisible={this.checkVisible}
              history={this.props.history}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <SO2Detail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerFilter ? (
            <SO2Filter
              isVisible={openDrawerFilter}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  isPrint = () => {
    this.props.getListNeedForPrint({
      stt_rec: this.state.Master.STT_REC,
      ma_cty: this.props.ma_cty,
    });
    this.setState({ loading: true, isModalVisible: true });
  };
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  getDataRow = (params) => {
    this.setState({ Master: params });
  };
  checkVisible = (params) => {
    this.setState({
      openModalCreate: params,
      loading: params,
      openDrawerDetail: params,
      openDrawerFilter: params,
      isModalVisible: params,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listPackingSlip: state.SO2Reducer.listPackingSlip,
    listCustomer: state.SO2Reducer.listCustomer,
    customer: state.SO2Reducer.customer,
    ctPackingSlip: state.SO2Reducer.ctPackingSlip,
    checkDelete: state.SO2Reducer.checkDelete,
    listFiltPackingSlip: state.SO2Reducer.listFiltPackingSlip,
    contactPackingSlip: state.SO2Reducer.contactPackingSlip,
    dsCtDriver: state.SO2Reducer.dsCtDriver,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    clearCache: () => {
      dispatch(actions.clearCache());
    },
    getListNeedForPrint: (query) => {
      dispatch(actions.getCTPackingSlip(query));
      dispatch(actions.getContactPackingSlip(query));
      dispatch(actions.getCtDriver(query));
    },
    getListPackingSlip: (ma_cty) => {
      dispatch(actions.getListPackingSlip(ma_cty));
    },
    getPHPackingSlip: (detail) => {
      dispatch(actions.getPHPackingSlip(detail));
    },
    deleteAPackingSlip: (master) => {
      dispatch(actions.deleteAPackingSlip(master));
    },
    getListDm: (ma_cty) => {
      dispatch(actions.getListDm(ma_cty));
    },
    getListCustomer: (ma_cty) => {
      dispatch(actions.getListCustomer(ma_cty));
    },
    getListCurrency: () => {
      dispatch(actions.getListCurrency());
    },
    getListItem: (ma_cty) => {
      dispatch(actionsItem.getListItem(ma_cty));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackingSlip);
