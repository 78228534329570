import React, { Component } from "react";
import * as actions from "../../../../../redux/actions/Manufacturing/MachineRpt/index";
import * as actionsManufacturing from "../../../../../redux/actions/Manufacturing/Manufacturing";
import DateBox from "devextreme-react/date-box";
import { Button } from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import "./index.css";
import { Row, Col, Divider, Collapse, InputNumber } from "antd";
import TimeLineChart from "../../ChartType/timeLineChart";
import Reports from "./Reports/reports";
import { DHSSelectOpt } from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { periodData, shiftData } from "../../../../controller/DataSample";
import { onPeriodChange } from "../../../../controller/Format";
const { Panel } = Collapse;
class MachineTimeLine extends Component {
  state = {
    dataSource: [],
    period: "formTo",
    periodReport: "formTo",
    time_from: moment()
      .subtract("1", "days")
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_to: moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_from_display: moment()
      .subtract("1", "days")
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_to_display: moment().set({
      hour: 6,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    machineID: "",
    machineName: "",
    displayName: "",
    shift: 0,
  };

  componentDidMount = () => {
    const {
      time_from,
      time_to,
      machine_name,
      machineID,
    } = this.props.containDataRptMachine;
    this.props.GetListMachines({ ma_cty: this.props.ma_cty });
    if (machineID !== "") {
      this.setState({
        time_from: time_from,
        time_to: time_to,
        time_from_display: time_from,
        time_to_display: time_to,
        displayName: machine_name,
        machineID: machineID,
      });
      this.getRptMachineTimeLine(machineID, time_from, time_to);
      this.getRptMachineWorkShift(machineID, time_from, time_to);
    } else {
      this.getRptMachineWorkShift();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { machineTimeLine } = this.props;
    if (!_.isEqual(machineTimeLine, prevProps.machineTimeLine)) {
      this.setState({ dataSource: machineTimeLine });
    }
  };

  componentWillUnmount = () => {
    if (this.props.machineTimeLine !== null) {
      this.props.machineTimeLine.length = 0;
      this.props.machineWorkShift.length = 0;
    }
    this.setState({ dataSource: [] });
  };

  onValueChanged = (name) => (e) => {
    this.setState({ [name]: e.value });
  };

  onSelectionChanged = (e) => {
    this.setState({
      machineID: e.selectedItem.machine_id,
      machineName: e.selectedItem.machine_name,
    });
  };
  selectShift = (e) => {
    this.setState({
      shift: e.selectedItem.value,
    });
  };
  onClickSearch = (type) => {
    const { machineID, time_from, time_to, machineName, shift } = this.state;
    this.setState({ time_from_display: time_from, time_to_display: time_to });
    if (type === 0) {
      this.setState({ displayName: machineName });
      this.getRptMachineTimeLine(machineID, time_from, time_to);
    }
    this.getRptMachineWorkShift(machineID, time_from, time_to, shift);
  };

  render() {
    const {
      dataSource,
      time_from,
      time_to,
      time_from_display,
      time_to_display,
      displayName,
      machineID,
      shift,
      period,
    } = this.state;
    const { containDataRptMachine, listMachine } = this.props;
    return (
      <div className="machineTimeLine-container">
        <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
          <Panel
            header={<Divider orientation="left">Machine Timeline</Divider>}
            key="1"
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24} xs={24} xl={6}>
                <div className="textTime">Mã máy</div>
                <div>
                  <SelectBox
                    searchEnabled={true}
                    searchExpr="machine_code"
                    items={listMachine}
                    onSelectionChanged={this.onSelectionChanged}
                    valueExpr="machine_id"
                    displayExpr="machine_code"
                    defaultValue={containDataRptMachine.machineID}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={4}>
                <div className="textTime">Lọc</div>
                <div>
                  <DHSSelectOpt
                    placeHolder="Chọn điều kiện lọc"
                    array={periodData}
                    defaultValue={period}
                    valueOpt="value"
                    valueRender="name"
                    onChange={(value) => this.onHandleFilter(value, 1)}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={2}>
                <div className="textTime">Số tuần</div>
                <div>
                  <InputNumber
                    min={1}
                    max={52}
                    defaultValue={moment().weeks()}
                    onChange={(value) => this.onChangeWeek(value, 1)}
                    disabled={period === "week" ? false : true}
                    maxLength={2}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={4}>
                <div className="textTime">Thời gian từ</div>
                <div>
                  <DateBox
                    value={time_from}
                    defaultValue={time_from}
                    type="datetime"
                    onValueChanged={this.onValueChanged("time_from")}
                    displayFormat={"dd/MM/yyyy HH:mm"}
                    disabled={period === "formTo" ? false : true}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={4}>
                <div className="textTime">Thời gian đến</div>
                <div>
                  <DateBox
                    value={time_to}
                    defaultValue={time_to}
                    type="datetime"
                    min={time_from}
                    onValueChanged={this.onValueChanged("time_to")}
                    displayFormat={"dd/MM/yyyy HH:mm"}
                    disabled={period === "formTo" ? false : true}
                  />
                </div>
              </Col>
              <Col span={8} xs={24} xl={4}>
                <Button
                  style={{ marginTop: 22 }}
                  width={120}
                  text="Tìm kiếm"
                  type="normal"
                  stylingMode="contained"
                  disabled={machineID === "" ? true : false}
                  onClick={() => this.onClickSearch(0)}
                />
              </Col>
            </Row>
            <div className="machineTimeLineChart">
              <TimeLineChart
                dataSource={dataSource}
                machineName={displayName}
                time_to={time_to_display}
                time_from={time_from_display}
                customizeTooltip={this.customizeTooltip}
              />
            </div>
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["2"]} expandIconPosition="right" ghost>
          <Panel header={<Divider orientation="left">Reports</Divider>} key="2">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24} xs={24} xl={6}>
                <div className="textTime">Ca làm việc</div>
                <div>
                  <SelectBox
                    searchEnabled={true}
                    searchExpr="name"
                    items={shiftData}
                    onSelectionChanged={this.selectShift}
                    valueExpr="value"
                    displayExpr="name"
                    defaultValue={shift}
                  />
                </div>
              </Col>
              <Col span={8} xs={24} xl={4}>
                <Button
                  style={{ marginTop: 22 }}
                  width={120}
                  text="Tìm kiếm"
                  type="normal"
                  stylingMode="contained"
                  onClick={() => this.onClickSearch(1)}
                />
              </Col>
            </Row>
            <Reports machineName={displayName} machineID={machineID} />
          </Panel>
        </Collapse>
      </div>
    );
  }

  customizeTooltip(arg) {
    let value = moment(arg.originalValue).diff(
      moment(arg.originalMinValue),
      "seconds"
    );
    let convert = (value) => {
      return value >= 10 ? value : "0" + value;
    };
    let hours = Math.floor(value / 60 / 60);
    let minutes = Math.floor((value / 60) % 60);
    let seconds = value % 60;
    return {
      text: `Time ${arg.seriesName} :${
        convert(hours) + ":" + convert(minutes) + ":" + convert(seconds)
      }
      Start time: ${moment(arg.originalMinValue).format(
        "DD/MM/yyyy HH:mm:ss"
      )}`,
    };
  }
  onHandleFilter = (params, type) => {
    const result = onPeriodChange(params, moment().weeks());
    this.setState({
      period: result.period,
      time_to: result.toDate,
      time_from: result.formDate,
    });
  };
  onChangeWeek = (value, type) => {
    const result = onPeriodChange("week", value);
    this.setState({
      period: result.period,
      time_to: result.toDate,
      time_from: result.formDate,
    });
  };
  getRptMachineTimeLine = (
    machine_id = "",
    time_from = moment()
      .subtract("1", "days")
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_to = moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    ma_cty = this.props.ma_cty
  ) => {
    const query = {
      ma_cty,
      time_from: moment(time_from).add(7, "hours"),
      time_to: moment(time_to).add(7, "hours"),
      machine_id,
    };
    this.props.GetRptMachineTimeline(query);
  };
  getRptMachineWorkShift = (
    machine_id = "",
    time_from = moment(),
    time_to = moment(),
    shift = 0,
    ma_cty = this.props.ma_cty
  ) => {
    const query = {
      ma_cty,
      time_from: moment(time_from).add(7, "hours"),
      time_to: moment(time_to).add(7, "hours"),
      shift,
      machine_id,
    };
    this.props.GetRptMachineWorkShift(query);
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    machineTimeLine: state.MachineRptReducer.machineTimeLine,
    containDataRptMachine: state.MachineRptReducer.containDataRptMachine,
    listMachine: state.ManufacturingReducer.listMachine,
    machineWorkShift: state.MachineRptReducer.machineWorkShift,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    GetRptMachineTimeline: (query) => {
      dispatch(actions.GetRptMachineTimeline(query));
    },
    GetListMachines: (query) => {
      dispatch(actionsManufacturing.GetListMachines(query));
    },
    GetRptMachineWorkShift: (query) => {
      dispatch(actions.GetRptMachineWorkShift(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineTimeLine);
