import React, { Component } from "react";
import moment from "moment";
import { Bar } from "react-chartjs-2";
export default class Bars extends Component {
  render() {
    const { width, height, value, label } = this.props;
    const dataBar = {
      labels: label,
      datasets: [
        {
          label: this.props.name,
          backgroundColor: this.props.color,
          borderColor: this.props.color,
          borderWidth: 1,
          hoverBackgroundColor: "lavender",
          hoverBorderColor: "lavender",
          data: value,
        },
      ],
    };
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              callback(value) {
                return Number(value).toLocaleString("en");
              },
            },
          },
        ],
      },
      maintainAspectRatio: false,
    };
    return (
      <div>
        <Bar
          data={dataBar}
          width={width}
          height={height}
          options={options}
          onElementsClick={(e) => {
            console.log(e);
          }}
        />
      </div>
    );
  }
}
