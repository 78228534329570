import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const GetListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/INReports/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_IN_REPORT,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetDataFilterINRpt = (obj) => (dispatch, getState) => {
  callApi("api/INReports/GetDataFilterINRpt", "POST", obj)
    .then((res) =>
      dispatch({
        type: types.GET_RPT_IN,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetRptInventoryMovement = (query) => (dispatch, getState) => {
  callApi("api/POSSOReports/INRptInventoryMovement", "POST", query)
    .then((res) =>
      dispatch({
        type: types.POS_RPT_IN_MOVEMENT,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
