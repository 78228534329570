import React, { useState, useEffect } from "react";
import { Drawer, Button, Spin, Row, Col } from "antd";
import { useSelector } from "react-redux";
import Format from "../../../../../../controller/Format";
import DHSListItem from "../../../../../../../common/DHSControl/DHSListItem/DHSListItem";
import {widthDrawer} from "../../../../index"
function Header() {
  return (
    <div>
      <Row style={{ color: "gray", fontSize: 12, marginLeft: 10 }}>
        <Col span={2}>Ảnh</Col>
        <Col span={13}>Tên vật tư</Col>
        <Col span={4}>Đơn giá</Col>
        <Col span={4}>Số lượng</Col>
      </Row>
    </div>
  );
}
export default function HandleMultiAdd(props) {
  const { isVisible, checkVisible } = props;
  // const lstItem = useSelector((state) => state.ItemReducer.listItem);
  const lookupSelectedItem = useSelector(
    (state) => state.SellPriceReducer.lookupSelectedItem
  );
  const [isSelected, setSelected] = useState([]);
  const handleCancel = () => {
    checkVisible({ isVisible: false, isSelected: [] });
  };
  const confirmSelected = () => {
    checkVisible({
      isVisible: false,
      isSelected,
    });
  };
  const getItemSelected = (params) => {
    setSelected(params);
  };
  return (
    <Drawer
      title="Chọn vật tư"
      width={600}
      maskClosable={false}
      onClose={handleCancel}
      visible={isVisible}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={confirmSelected}
          >
            Xác nhận
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <DHSListItem
        header={<Header />}
        listItem={lookupSelectedItem}
        keyItem={"IDITEM"}
        title={"TEN_VT"}
        description={"MA_VT"}
        inputName1={"GIA_NT2"}
        avatar={"ANH"}
        inputDisplay={true}
        getItemSelected={getItemSelected}
      />
    </Drawer>
  );
}
