import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Spin, Row, Col, Avatar, Card, Divider } from "antd";
import _ from "lodash";
import { FormatMoney } from "../../../../../controller/Format";
import DHSHanleFilter from "../../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { lstColor, lstSize } from "../../../../../controller/DataSample";
const styleColor = {
  height: 20,
  width: 20,
  borderRadius: 40,
  margin: "0px 3px",
};
const styleSize = {
  height: 30,
  width: 30,
  padding: "3px",
  textAlign: "center",
  border: "1px solid #E6E8EC",
  color: "#8595AA",
  margin: "0px 2px",
};
export default function AddProducts(props) {
  const { isVisible, checkVisible, getProduct } = props;
  const [isRender, setRender] = useState(false);
  const [isSelected, setSelected] = useState([]);
  const [data, setData] = useState(
    useSelector((state) => state.SellPriceReducer.lookupSelectedItem)
  );
  const FullListItem = useSelector(
    (state) => state.SellPriceReducer.lookupSelectedItem
  );

  const handleCancel = () => {
    checkVisible(false);
  };
  const checkItem = (item) => {
    if (!_.isEmpty(item.COLOR_ID) && !_.isEmpty(item.SIZE_ID)) {
      item.CHON = !item.CHON;
      if (item.CHON) {
        item.INDEX = Math.random().toString(36).substr(2, 9).toUpperCase();
        let STT_REC0 = Math.random().toString(36).substr(2, 9).toUpperCase();
        item.SO_LUONG = 1;
        setSelected([...isSelected, Object.assign({ STT_REC0 }, item)]);
      } else {
        let newArray = _.filter(isSelected, (x) => {
          return x.INDEX !== item.INDEX;
        });
        setSelected(newArray);
      }
    }
  };
  const handleOk = async () => {
    await data.map((item) => {
      item.CHON = false;
      item.SIZE_ID = "";
      item.COLOR_ID = "";
    });
    getProduct(isSelected);
    checkVisible(false);
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const chooseColor = (item, iColor) => {
    if (item.COLOR_ID === iColor.COLOR_ID) {
      item.COLOR_ID = "";
    } else {
      item.COLOR_ID = iColor.COLOR_ID;
    }
    checkItem(item);
    setRender(!isRender);
  };
  const chooseSize = (item, iSize) => {
    if (item.SIZE_ID === iSize.SIZE_ID) {
      item.SIZE_ID = "";
    } else {
      item.SIZE_ID = iSize.SIZE_ID;
    }
    checkItem(item);
    setRender(!isRender);
  };
  const Product = (item) => {
    return (
      <Col sm={12} xs={24} xl={6} lg={8}>
        <Card
          hoverable
          style={{
            width: 320,
            height: 380,
            marginBottom: 25,
          }}
          cover={
            <div style={{ height: 230 }}>
              <Avatar
                shape="square"
                src={"data:image/png;base64," + item.ANH}
                size={150}
                className="avatarCard"
              />
              .
              <p
                style={{ fontSize: 14, fontWeight: "bold", margin: "0px 10px" }}
              >
                {item.TEN_VT}
              </p>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 20,
                  margin: 0,
                  color: "#37A4FF",
                  margin: "0px 10px",
                }}
              >
                {FormatMoney(item.GIA_NT2)} VNĐ
              </p>
            </div>
          }
        >
          <Divider style={{ margin: 0 }} />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24} xs={24} xl={24}>
              Kích thước :
            </Col>
            <Col
              span={24}
              xs={24}
              xl={24}
              style={{ display: "flex", paddingLeft: 0, marginBottom: 12 }}
            >
              {lstSize.map((iSize, index) => {
                return (
                  <div
                    style={{
                      ...styleSize,
                      background:
                        iSize.SIZE_ID === item.SIZE_ID ? "#71C5FF" : "#EEF0F5",
                    }}
                    onClick={() => chooseSize(item, iSize)}
                  >
                    {iSize.SIZE_NAME}
                  </div>
                );
              })}
            </Col>
            <Col span={6} xs={24} xl={6}>
              Màu :
            </Col>
            <Col
              span={18}
              xs={24}
              xl={18}
              style={{ display: "flex", paddingLeft: 0 }}
            >
              {lstColor.map((iColor, index) => {
                return (
                  <div
                    style={{
                      ...styleColor,
                      background: iColor.COLOR_NAME_EN,
                      border:
                        iColor.COLOR_ID === item.COLOR_ID
                          ? "3px solid #71C5FF"
                          : "1px solid lightgray",
                    }}
                    onClick={() => chooseColor(item, iColor)}
                  />
                );
              })}
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };
  return (
    <div>
      <Modal
        title="Add Product"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1500}
        className="_modalProduct"
      >
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={FullListItem}
        />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {data.map((item, index) => {
            return Product(item);
          })}
        </Row>
      </Modal>
    </div>
  );
}
