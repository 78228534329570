import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import DHSTable from "../../../../../common/DHSControl/DHSTable/DHSTable";
import So3Detail from "../so3Detail/so3Detail";
// import ModalSelectRp from "../modalSelectRp/modalSelectRp";
import DHSHanleFilter from "../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actions from "../../../../../redux/actions/Sales/salesInvoice/salesInvoice";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";

const objectColumn = {
  SO_CT: { name: "Số chứng từ", width: 150 },
  NGAY_CT: { name: "Ngày chứng từ", width: 150, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 150 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  T_TT: { name: "Tiền hàng", width: 150, formatMoney: true },
  T_CK: { name: "Chiết khấu", width: 150, formatMoney: true },
  T_THUE: { name: "VAT", width: 150, formatMoney: true },
  MA_NT: { name: "Mã NT", width: 50 },
  TK_PT: { name: "TK PT", witdh: 50 },
  TEN_HTTT: { name: "HTTT", witdh: 150 },
  DIEN_GIAI: { name: "Diễn giải", width: 300 },
};
const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function SO3Bank(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const SO3State = useSelector((state) => state.SO3Reducer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isLoading, setLoading] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      actions.filt({
        modelM: {
          ma_cty,
          ma_httt: "CK",
        },
      })
    );
    loadColumnDHSTable(customColumns, setCustomColumns);
    return () => {
      dispatch(actions.clearCache());
    };
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(SO3State.listSalesInvoice);
    setData(SO3State.listSalesInvoice);
    setFullData(SO3State.listSalesInvoice);
    setLoading(false);
  }, [SO3State.listSalesInvoice]);

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const checkVisible = (params) => {
    setOpenDrawerDetail(params);
    setOpenModalPrint(params);
  };

  //   const handlePrint = (params) => {
  //     dispatch(actions.getCTSalesInvoice(params));
  //     setOpenModalPrint(true);
  //   };
  return (
    <div className="salesInvoice" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Chuyển khoản"
          onBack={() => history.push("/Sale")}
        />
        {/* <ToolBar setPrint={handlePrint} Master={isMaster} /> */}
        {/* <ModalSelectRp
          isModalVisible={openModalPrint}
          checkVisible={checkVisible}
          Master={isMaster}
          ctSalesInvoice={toUpperPropertyNameByArray(
            useSelector((state) => state.SO3Reducer.ctSalesInvoice)
          )}
        /> */}
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(
              actions.filt({
                modelM: {
                  ma_cty,
                  ma_httt: "CK",
                },
              })
            );
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
        />
        {openDrawerDetail ? (
          <So3Detail
            isVisible={openDrawerDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
