import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListInventory = (ma_cty) => (dispatch, getState) => {
  callApi("api/LstInventory/GetLstInventory", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_INVENTORY,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
