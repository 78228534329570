import React, { useState, useEffect } from "react";
import { Collapse, Row, Col, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  DHSInput,
  DHSText,
  DHSSelectOpt,
  DHSCheckBox,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { FormatDate } from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/Inventory/MovementIssue/movementIssue";
import moment from "moment";
const { Panel } = Collapse;

export default function General(props) {
  const { Master, isEdit, getTyGia } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isRender, setRender] = useState(false);
  const IN2State = useSelector((state) => state.IN2Reducer);

  useEffect(() => {
    dispatch(actions.getListsDm({ ma_cty }));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(IN2State.listCustomer)) {
      handleKHChange(Master.IDCUSTOMER);
    }
  }, [IN2State.listCustomer]);

  useEffect(() => {
    if (!_.isEqual(IN2State.exchangeRate, "")) {
      Master["TY_GIA"] = IN2State.exchangeRate == 0 ? 1 : IN2State.exchangeRate;
    }
    getTyGia(Master.TY_GIA);
    setRender(!isRender);
  }, [IN2State.exchangeRate]);

  const handleKHChange = (params) => {
    let customer = IN2State.listCustomer.find((x) => x.IDCUSTOMER === params);
    if (!_.isEmpty(customer)) {
      Master.DIA_CHI = customer.DIA_CHI;
      Master.IDCUSTOMER = params;
      Master.EMAIL = customer.EMAIL;
      Master.TEL = customer.TEL;
      Master.MA_KH = customer.MA_KH;
    }
    setRender(!isRender);
  };

  const onChangeText = (params) => {
    Master[params.name] = params.value;
    if (params.name === "TY_GIA") {
      getTyGia(Master.TY_GIA);
    }
    setRender(!isRender);
  };

  const handleChangeCurrency = (params) => {
    Master["MA_NT"] = params;
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: moment(Master.NGAY_LCT),
      })
    );
    setRender(!isRender);
  };
  return (
    <div className="IN2-general">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chung" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Tên khách hàng"
                  array={IN2State.listCustomer}
                  valueOpt={"IDCUSTOMER"}
                  valueRender={"TEN_KH"}
                  defaultValue={Master.IDCUSTOMER}
                  onChange={handleKHChange}
                  disable={isEdit}
                />
                <DHSText label="Số điện thoại" value={Master.TEL} />
                <DHSText label="Email" value={Master.EMAIL} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSInput
                  label="Người liên hệ"
                  name="NGUOI_GD"
                  value={Master.NGUOI_GD}
                  getValueChange={onChangeText}
                  disable={isEdit}
                />
                <DHSText label="Địa chỉ" value={Master.DIA_CHI} />
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <DHSCheckBox
                      label="Giá đích danh"
                      defaultChecked={Master.PX_GDD}
                      name="PX_GDD"
                      onChange={(e) =>
                        onChangeText({
                          name: "PX_GDD",
                          value: e.target.checked,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Loại phiếu"
                  array={IN2State.sysExchange}
                  valueOpt={"MA_GD"}
                  valueRender={"MO_TA"}
                  defaultValue={Master.MA_GD}
                  onChange={(value) => onChangeText({ name: "MA_GD", value })}
                />
                <DHSText
                  label="Ngày chứng từ"
                  value={FormatDate(Master.NGAY_CT)}
                />
                <DHSText label="Ngày lập" value={FormatDate(Master.NGAY_LCT)} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Loại tiền tệ"
                  array={IN2State.listCurrency}
                  valueOpt={"MA_NT"}
                  valueRender={"MA_NT"}
                  defaultValue={Master.MA_NT}
                  onChange={handleChangeCurrency}
                  disable={isEdit}
                />
                <DHSInput
                  label="Tỷ giá"
                  name="TY_GIA"
                  value={Master.TY_GIA}
                  disable={isEdit}
                  disable={Master.MA_NT === "VND" ? true : false}
                  getValueChange={onChangeText}
                />
                <DHSInput
                  label="Diễn giải"
                  name="DIEN_GIAI"
                  value={Master.DIEN_GIAI}
                  disable={isEdit}
                  getValueChange={onChangeText}
                />
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
}
