import React, { useEffect, useState } from "react";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import PropTypes from "prop-types";
import moment from "moment";
import { FormatMoney } from "../../../../controller/Format";
import {
  DHSInput,
  DHSCheckBox,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
RoleInfoForm.propTypes = {
  objectCreate: PropTypes.object,
};

RoleInfoForm.defaultProps = {
  objectCreate: {
    ROLENAME: "",
    FULLNAME: "",
    CUSER: "",
    LUSER: "",
    KSD: false,
  },
};
const validateMessages = {
  required: "Trường này là bắt buộc",
};
export default function RoleInfoForm(props) {
  const {
    isVisible,
    checkVisible,
    title,
    handleActions,
    objectCreate,
    isEdit,
    Master,
  } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [objCreate, setObjCreate] = useState(
    _.isNil(Master) ? objectCreate : Master
  );
  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = () => {
    isEdit ? (objCreate.CUSER = userName) : (objCreate.LUSER = userName);
    handleActions({
      ...objCreate,
      MA_CTY,
    });
  };

  const handleChangeText = async (params) => {
    await setObjCreate({ ...objCreate, [params.name]: params.value });
  };
  const onCheckboxChange = (e) => {
    setObjCreate({ ...objCreate, KSD: e.target.checked });
  };
  return (
    <Drawer
      title={title}
      width={500}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8, display: !isEdit ? "none" : "unset" }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Form
        id="submit-form"
        onFinish={handleOk}
        layout="vertical"
        validateMessages={validateMessages}
        style={{ padding: 20 }}
      >
        <DHSInput
          label="Mã vai trò"
          name="ROLENAME"
          value={objCreate.ROLENAME}
          getValueChange={handleChangeText}
          disable={_.isNil(Master) ? !isEdit : true}
        />
        <DHSInput
          label="Tên vai trò"
          name="FULLNAME"
          value={objCreate.FULLNAME}
          getValueChange={handleChangeText}
          disable={!isEdit}
        />
        <DHSCheckBox
          label="KSD"
          defaultChecked={objCreate.KSD}
          name="KSD"
          onChange={onCheckboxChange}
          disabled={!isEdit}
        />
      </Form>
    </Drawer>
  );
}
