import React, { Component } from "react";
import { Row, Col, Spin } from "antd";
import "./index.css";
import { connect } from "react-redux";
import _ from "lodash";
import DHSIcons from "../DHSIcons/DHSIcons";
import FontAwesome from "react-fontawesome";
import ScrollMenu from "react-horizontal-scrolling-menu";
const heightScreenYourComputer = window.screen.height - 168;
class DHSMetroMenu extends Component {
  state = { loading: false };

  render() {
    const { image, listCategoryLv2 } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Spin spinning={loading}>
          <Row className="rowParentMetroMenu">
            <Col {...colImage}>
              <img src={image} className="_metroMenuImage" />
            </Col>
            {this.renderMetroMenuLv2(listCategoryLv2)}
          </Row>
        </Spin>
      </div>
    );
  }
  renderMetroMenuLv2 = (listCategoryLv2) => {
    var result = null;
    if (listCategoryLv2.length > 0) {
      result = listCategoryLv2.map((item, index) => {
        let _str = item.menuid.substring(3, 8);
        if (_str !== "00.00" && item.menuid.includes(".00")) {
          if (item.moduleid === this.props.moduleid) {
            return (
              <Col {...col} key={item.menuid} className="metroMenuCol">
                <div className="titleMenuLv2">{item.bar}</div>
                {this.renderMetroMenuLv3(
                  this.props.listCategoryLv3,
                  item.menuid
                )}
              </Col>
            );
          }
        }
      });
    }
    return result;
  };
  renderMetroMenuLv3 = (listCategoryLv3, menuid) => {
    var result = null;
    if (!_.isEmpty(listCategoryLv3)) {
      result = listCategoryLv3.map((item) => {
        let _str1 = item.menuid.substring(0, 5);
        let _str2 = menuid.substring(0, 5);
        if (_str1.includes(_str2)) {
          return (
            <li
              className={"item r" + item.rowspan + "c" + item.columnspan}
              style={{
                padding: item.rowspan === 1 ? 0 : 10,
                background:
                  item.color_code !== "" ? item.color_code : "#2291C4",
                textAlign:
                  item.position_icon === 0
                    ? "left"
                    : item.position_icon === 1
                    ? "center"
                    : item.position_icon === 2
                    ? "right"
                    : "bottom",
              }}
              key={item.menuid}
              onClick={() => this.onRedirectPage(item)}
            >
              <DHSIcons name={item.iconweb} />
              {/* <FontAwesome name={item.iconweb} className="icon" /> */}
              <span style={{ fontWeight: "bold" }}>{item.short_name}</span>
            </li>
          );
        }
      });
    }
    return result;
  };
  onRedirectPage = (item) => {
    this.props.history.push("/" + item.linkmobile);
  };
}
const col = {
  md: 12,
  className: "gutter-row",
  xs: 24,
  xl: 6,
  style: {
    background: "white",
    padding: 10,
    borderLeft: "10px solid #F2F5F9",
    borderBottom: "10px solid #F2F5F9",
  },
};
const colImage = {
  md: 12,
  className: "gutter-row",
  xs: 0,
  xl: 6,
  style: {
    // padding: 10,
    borderBottom: "10px solid #F2F5F9",
  },
};

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listCategoryLv2: state.LoginReducer.listCategoryLv2,
    listCategoryLv3: state.LoginReducer.listCategoryLv3,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DHSMetroMenu);
