import { Button, Space, Popconfirm } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  PrinterOutlined,
  DeleteOutlined,
  CheckOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import BtnConfirm from "./btnConfirm";
import PropTypes from "prop-types";
import { openNotificationWithIcon } from "../../notification/notification";
import "./index.css";

ToolBar.propTypes = {
  setStateOpen: PropTypes.func,
  setDelete: PropTypes.func,
  setEdit: PropTypes.func,
  setBack: PropTypes.func,
  setSave: PropTypes.func,
  Master: PropTypes.object,
};

ToolBar.defaultProps = {
  Master: {},
};

const renderBtn = (onClick, title, icon, active, disabled = false) => {
  return (
    <>
      {!_.isNil(active) ? (
        <Button
          onClick={onClick}
          icon={icon}
          disabled={disabled}
          style={{ background: "white" }}
        >
          {title}
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

const renderBtnConfirm = (objConfirm, label, onClick, title, icon, active) => {
  return (
    <>
      {!_.isNil(active) ? (
        <Popconfirm
          title={title}
          onConfirm={onClick}
          okText="Đồng ý"
          cancelText="Hủy"
          disabled={_.isEmpty(objConfirm)}
        >
          <Button
            icon={icon}
            onClick={() => {
              if (_.isEmpty(objConfirm)) {
                openNotificationWithIcon("error", "Vui lòng chọn đối tượng !");
              }
            }}
          >
            {label}
          </Button>
        </Popconfirm>
      ) : (
        <></>
      )}
    </>
  );
};

export default function ToolBar(props) {
  const {
    Master,
    setStateOpen,
    setDelete,
    setEdit,
    setBack,
    setSave,
    setAddItem,
    setDeleteLine,
    setReEdit,
    setApproval,
    setReject,
    setCreateQU,
    setPrint,
    disabled,
  } = props;
  function clickCreate() {
    setStateOpen();
  }
  function clickEdit() {
    if (!_.isEmpty(Master)) {
      setEdit(Master);
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn đối tượng cần sửa !");
    }
  }
  function clickDelete() {
    setDelete(Master);
  }
  const clickSave = () => {
    setSave();
  };
  const clickBack = () => {
    setBack();
  };
  const clickAddItem = () => {
    setAddItem();
  };
  const clickReEdit = () => {
    setReEdit();
  };
  const clickDeleteLine = () => {
    setDeleteLine();
  };
  const clickApproval = () => {
    setApproval(Master);
  };
  const clickReject = () => {
    setReject(Master);
  };
  const clickCreateQU = () => {
    setCreateQU();
  };
  const clickPrint = () => {
    if (!_.isEmpty(Master)) {
      setPrint(Master);
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn đối tượng cần in !");
    }
  };
  return (
    <div className="toolBar">
      <Space>
        {renderBtnConfirm(
          Master,
          "Lập báo giá",
          clickCreateQU,
          "Bạn chắc chắn lập báo giá ?",
          <CheckOutlined />,
          setCreateQU
        )}
        {renderBtn(clickSave, "Lưu", <CheckOutlined />, setSave, disabled)}
        {renderBtn(
          clickCreate,
          "Thêm",
          <PlusOutlined />,
          setStateOpen,
          disabled
        )}
        {renderBtn(clickEdit, "Sửa", <EditOutlined />, setEdit, disabled)}
        {renderBtn(clickReEdit, "Sửa", <EditOutlined />, setReEdit, disabled)}
        {renderBtn(
          clickAddItem,
          "Thêm từ danh mục",
          <></>,
          setAddItem,
          disabled
        )}
        {renderBtn(clickDeleteLine, "Xóa dòng", <></>, setDeleteLine, disabled)}
        {renderBtn(
          clickBack,
          "Trở về",
          <RollbackOutlined />,
          setBack,
          disabled
        )}
        {renderBtn(clickPrint, "In", <PrinterOutlined />, setPrint, disabled)}
        {renderBtnConfirm(
          Master,
          "Duyệt",
          clickApproval,
          "Bạn chắc chắn duyệt ?",
          <CheckOutlined />,
          setApproval
        )}
        {renderBtnConfirm(
          Master,
          "Từ chối",
          clickReject,
          "Bạn chắc chắn từ chối ?",
          <DeleteOutlined />,
          setReject
        )}
        {renderBtnConfirm(
          Master,
          "Xóa",
          clickDelete,
          "Bạn chắc chắn muốn xóa ?",
          <DeleteOutlined />,
          setDelete
        )}
      </Space>
    </div>
  );
}
