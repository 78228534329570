import React, { Component } from "react";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { Button, Space, Spin, Popconfirm, PageHeader } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Category/driver/driver";
import * as actionsVehicle from "../../../../redux/actions/Category/vehicle/vehicle";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import "./index.css";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import DriverCreate from "./DriverCreate/DriverCreate";
import DriverDetail from "./DriverDetail/DriverDetail";
import DriverEdit from "./DriverEdit/DriverEdit";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const objectColumn = {
  DRIVER_CODE: { name: "Mã tài xế", width: 100 },
  DRIVER_NAME: { name: "Tên tài xế", width: 300 },
  DRIVER_EMAIL: { name: "Email", width: 100 },
  DRIVER_PHONE: { name: "Số điện thoại", width: 100 },
};
class Driver extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    DRIVER_ID: "",
    openDrawerCreate: false,
    openDrawerEdit: false,
    openDrawerDetail: false,
    Master: {},
  };
  componentDidMount = () => {
    this.loadListDriver();
  };
  componentDidUpdate = (prevProps) => {
    const { listDriver, checkDelete, clearCache } = this.props;
    if (!_.isEqual(listDriver, prevProps.listDriver)) {
      this.setState({
        data: listDriver,
        fullData: listDriver,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ loading: false, DRIVER_ID: "", Master: {} });
      this.loadListDriver();
      clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };
  confirmDelete(DRIVER_ID) {
    this.setState({ loading: true });
    this.props.deleteDriver({
      ma_cty: this.props.ma_cty,
      driver_id: DRIVER_ID,
    });
  }
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.setState({ openDrawerEdit: true });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn tài xế cần sửa !");
    }
  };

  render() {
    const {
      loading,
      data,
      customColumns,
      DRIVER_ID,
      openDrawerCreate,
      openDrawerEdit,
      Master,
      openDrawerDetail,
      fullData,
    } = this.state;
    return (
      <div className="Driver" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Tài xế"
            onBack={() => this.props.history.push("/Category")}
          />
          <Space style={{ marginBottom: 16, marginTop: 10 }}>
            <Button
              onClick={() => this.setState({ openDrawerCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn muốn xóa tài xế ?"
              onConfirm={() => this.confirmDelete(DRIVER_ID)}
              okText="Đồng ý"
              cancelText="Hủy"
              disabled={DRIVER_ID === "" ? true : false}
            >
              <Button
                onClick={() => {
                  if (DRIVER_ID === "") {
                    openNotificationWithIcon(
                      "error",
                      "Vui lòng chọn tài xế cần xóa !"
                    );
                  }
                }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            </Popconfirm>
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListDriver();
            }}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="DRIVER_ID"
            idItemClick={this.state.DRIVER_ID}
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openDrawerCreate ? (
            <DriverCreate
              isVisible={openDrawerCreate}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
          {openDrawerEdit ? (
            <DriverEdit
              isVisible={openDrawerEdit}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <DriverDetail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.setState({
      openDrawerCreate: params.isVisible,
      loading: params.isVisible,
      openDrawerDetail: params.isVisible,
      openDrawerEdit: params.isVisible,
      openDrawerFilter: params.isVisible,
    });
    if (params.editSuccess) {
      this.loadListDriver(this.state.moduleID);
      this.setState({ DRIVER_ID: "", Master: {} });
    }
  };
  getDataRow = (params) => {
    this.setState({ Master: params, DRIVER_ID: params.DRIVER_ID });
  };
  loadListDriver = () => {
    const { listDriver } = this.props;
    this.setState({ loading: true });
    if (this.state.customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      this.setState({ customColumns: result });
    }
    listDriver.length = 0;
    this.props.getListVehicle({ ma_cty: this.props.ma_cty });
    this.props.getListDriver({ ma_cty: this.props.ma_cty });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listDriver: state.DriverReducer.listDriver,
    checkDelete: state.DriverReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListDriver: (ma_cty) => {
      dispatch(actions.getListDriver(ma_cty));
    },
    getListVehicle: (ma_cty) => {
      dispatch(actionsVehicle.getListVehicle(ma_cty));
    },
    deleteDriver: (query) => {
      dispatch(actions.deleteDriver(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Driver);
