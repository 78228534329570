import * as types from "../../../constants/ActionTypes";
var initialState = {
  objCustomer: {},
  listCustomer: [],
  listDm: {},
  checkAddNewCustomer: false,
  listNewAddress: [],
  checkDelete: false,
  checkUpdate: false,
};

var SICustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_CUSTOMER_SI:
      return {
        ...state,
        listCustomer: action.payload,
      };
    case types.GET_LISTS: {
      return {
        ...state,
        listDm: action.payload,
      };
    }
    case types.GET_CUSTOMER: {
      return {
        ...state,
        objCustomer: action.payload,
      };
    }
    case types.ADD_NEW_CUSTOMER: {
      return {
        ...state,
        checkAddNewCustomer: action.payload,
      };
    }
    case types.ADD_NEW_ADDRESS: {
      if (typeof action.item === "object") {
        state.listNewAddress.push(action.item);
      } else if (typeof action.item === "number") {
        state.listNewAddress.splice(action.item, 1);
      }
      return { ...state };
    }
    case types.DELETE_CUSTOMER: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.UPDATE_CUSTOMER: {
      return {
        ...state,
        checkUpdate: action.payload,
      };
    }
    case types.CLEAR_CUSTOMER: {
      return {
        ...state,
        checkDelete: false,
        checkAddNewCustomer: false,
        checkUpdate: false,
        listCustomer: [],
      };
    }
    default:
      return state;
  }
};

export default SICustomerReducer;
