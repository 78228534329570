import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListBankPayment = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch04/ListBankPayment", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHBankPayment = (detail) => (dispatch, getState) => {
  callApi("api/caVch04/GetPHBankPayment", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTBankPayment = (detail) => (dispatch, getState) => {
  callApi("api/caVch04/GetCTBankPayment", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch04/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/caVch04/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewBankPayment = (query) => (dispatch, getState) => {
  callApi("api/caVch04/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteABankPayment = (master) => (dispatch, getState) => {
  callApi("api/caVch04/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_CA4,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updateBankPayment = (query) => (dispatch, getState) => {
  callApi("api/caVch04/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/caVch04/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch04/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_CA4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_CA4,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_CA4,
  };
};
