import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListApprovalPurchaseOrder = (ma_cty) => (
  dispatch,
  getState
) => {
  callApi("api/ApprovalPO1/ListApprovalPurchaseOrder", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_APPROVAL_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const approvalPurchaseOrder = (query) => (dispatch, getState) => {
  callApi("api/ApprovalPO1/Approval", "POST", query)
    .then((res) =>
      dispatch({
        type: types.APPROVAL_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const rejectPurchaseOrder = (query) => (dispatch, getState) => {
  callApi("api/ApprovalPO1/Reject", "POST", query)
    .then((res) =>
      dispatch({
        type: types.REJECT_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_APPROVAL_PO1,
  };
};
