import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const GetListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/SOReports/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_SO_REPORT,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetRptSOBK01 = (obj) => (dispatch, getState) => {
  callApi("api/SOReports/GetRptSOBK01", "POST", obj)
    .then((res) =>
      dispatch({
        type: types.GET_RPT_SO_BK_01,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetRptSODH0301 = (obj) => (dispatch, getState) => {
  callApi("api/SOReports/GetRptSODH0301", "POST", obj)
    .then((res) =>
      dispatch({
        type: types.GET_RPT_SODH0301,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const GetRptRevenueByInvoice = (query) => (dispatch, getState) => {
  //Cấu trúc call api
  callApi("api/POSSOReports/SORptRevenuebyInvoice", "POST", query)
    .then((res) =>
      dispatch({
        type: types.POS_RPT_REVENUE_BY_INVOICE, //Action Type đã khai báo để bên reducer nhận được dữ liệu
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const GetRptRevenueByItem = (query) => (dispatch, getState) => {
  callApi("api/POSSOReports/SORptRevenuebyItem", "POST", query)
    .then((res) =>
      dispatch({
        type: types.POS_RPT_REVENUE_BY_ITEM,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
