import * as types from "../../../constants/ActionTypes";

var initialState = {
  listDm: {},
  RptBK01: [],
  RptSODH0301: [],
  POSRptRevenuebyInvoice: [],
  POSRptRevenuebyItem: [],
};

var SOReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LISTS_DM_SO_REPORT: {
      return {
        ...state,
        listDm: action.payload,
      };
    }
    case types.GET_RPT_SO_BK_01: {
      return {
        ...state,
        RptBK01: action.payload,
      };
    }
    case types.GET_RPT_SODH0301: {
      return {
        ...state,
        RptSODH0301: action.payload,
      };
    }
    case types.POS_RPT_REVENUE_BY_INVOICE: {
      return {
        ...state,
        POSRptRevenuebyInvoice: action.payload.dsResult,
      };
    }
    case types.POS_RPT_REVENUE_BY_ITEM: {
      return {
        ...state,
        POSRptRevenuebyItem: action.payload.dsResult,
      };
    }
    default:
      return state;
  }
};

export default SOReportsReducer;
