import React, { Component } from "react";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { PageHeader, Button, Space, Spin, Popconfirm, Select } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Category/Item/index";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import "./index.css";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import ItemCreate from "./itemCreate/itemCreate";
import ItemEdit from "./itemEdit/itemEdit";
import ItemDetail from "./itemDetail/itemDetail";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
const { Option } = Select;

const objectColumn = {
  anh: { name: "Ảnh", width: 50, formatImage: true },
  ma_vt: { name: "Mã vật tư", width: 100 },
  ten_vt: { name: "Tên vật tư", width: 300 },
  dvt: { name: "Đơn vị tính", width: 100 },
  sku: { name: "SKU", width: 300 },
  gia_si: { name: "Giá sỉ", width: 100, formatMoney: true },
  gia_le: { name: "Giá lẻ", width: 100, formatMoney: true },
  sell_price: { name: "Giá bán", width: 100, formatMoney: true },
  purchase_price: { name: "Giá mua", width: 100, formatMoney: true },
};
class Item extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    rowId: "",
    iditem: "",
    openDrawerCreate: false,
    openDrawerEdit: false,
    openDrawerDetail: false,
    Master: {},
    moduleID: "",
    ma_vt: "",
  };
  componentDidMount = () => {
    this.loadListItem();
  };
  componentDidUpdate = (prevProps) => {
    const { listItem, checkDelete, clearCache } = this.props;
    if (!_.isEqual(listItem, prevProps.listItem)) {
      this.setState({
        data: listItem,
        fullData: listItem,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ loading: false, rowId: "", iditem: "", Master: {} });
      this.loadListItem();
      clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };
  confirmDelete(iditem) {
    this.setState({ loading: true });
    this.props.deleteItem({
      ma_cty: this.props.ma_cty,
      iditem: iditem,
      ma_vt: this.state.rowId,
    });
  }
  handleFilter = (e) => {
    const formattedQuery = e.target.value;
    const data = _.filter(this.state.fullData, (item) => {
      if (
        item.ma_vt.includes(formattedQuery) ||
        item.ten_vt.includes(formattedQuery) ||
        item.dvt.includes(formattedQuery)
      ) {
        return true;
      }
      return false;
    });
    this.setState({ data });
  };
  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.setState({ openDrawerEdit: true });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn vật tư cần sửa !");
    }
  };

  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  render() {
    const {
      loading,
      data,
      customColumns,
      iditem,
      openDrawerCreate,
      openDrawerEdit,
      Master,
      fullData,
      openDrawerDetail,
    } = this.state;
    return (
      <div className="Item" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Hàng hóa / vật tư"
            onBack={() => this.props.history.push("/Category")}
          />
          <Space style={{ marginBottom: 16, marginTop: 10 }}>
            <Button
              onClick={() => this.setState({ openDrawerCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn muốn xóa vật tư ?"
              onConfirm={() => this.confirmDelete(iditem)}
              okText="Đồng ý"
              cancelText="Hủy"
              disabled={iditem === "" ? true : false}
            >
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  if (iditem === "") {
                    openNotificationWithIcon(
                      "error",
                      "Vui lòng chọn vật tư cần xóa !"
                    );
                  }
                }}
              >
                Xóa
              </Button>
            </Popconfirm>
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => this.loadListItem()}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="ma_vt"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openDrawerCreate ? (
            <ItemCreate
              isVisible={openDrawerCreate}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
          {openDrawerEdit ? (
            <ItemEdit
              isVisible={openDrawerEdit}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <ItemDetail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.setState({
      openDrawerCreate: params.isVisible,
      loading: params.isVisible,
      openDrawerDetail: params.isVisible,
      openDrawerEdit: params.isVisible,
      openDrawerFilter: params.isVisible,
    });
    if (params.editSuccess) {
      this.setState({ rowId: "", iditem: "", Master: {} });
      this.loadListItem();
    }
  };
  getDataRow = (params) => {
    this.setState({ Master: params, iditem: params.iditem });
  };
  loadListItem = () => {
    const { listItem } = this.props;
    this.setState({ loading: true });
    if (this.state.customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      // console.log(result);
      this.setState({ customColumns: result });
    }
    listItem.length = 0;
    this.props.getListItem({ ma_cty: this.props.ma_cty });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listItem: state.ItemReducer.listItem,
    checkDelete: state.ItemReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListItem: (ma_cty) => {
      dispatch(actions.getListItem(ma_cty));
    },
    deleteItem: (query) => {
      dispatch(actions.deleteItem(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
