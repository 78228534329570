import React, { Component } from "react";
import {
  Input,
  Button,
  Space,
  Spin,
  Divider,
  Collapse,
  Descriptions,
  Select,
  Row,
  Col,
} from "antd";
import HandleMultiAdd from "./handleMultiAdd/handleMultiAdd";
import "react-data-grid/dist/react-data-grid.css";
import {
  FormatMoney,
  toUpperPropertyNameByArray,
} from "../../../../../controller/Format";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";
import { connect } from "react-redux";
import _ from "lodash";
import DHSDataGrid from "../../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import EmployeeDropDownBoxComponent from "../../../../../../common/DHSControl/DHSDropDownBox/DHSDropDownBox";
import ProductInfo from "../../../../../controller/ProductInfo";

const { Option } = Select;
const { Panel } = Collapse;

class ListItem extends Component {
  state = {
    column: [
      {
        caption: "Mã VT/HH",
        dataField: "IDITEM",
        type: 2,
        dropDown: EmployeeDropDownBoxComponent,
        dataSource: {
          data: this.props.listItem,
          valueExpr: "IDITEM",
          displayExpr: "MA_VT",
          render: ProductInfo,
        },
      },
      {
        caption: "Tên VT/HH",
        dataField: "TEN_VT",
        type: 0,
        width: 250,
      },
      {
        caption: "ĐVT",
        dataField: "DVT",
        type: 0,
      },
      {
        caption: "Kho",
        dataField: "MA_KHO",
        type: 2,
        dataSource: {
          data: this.props.lstInventory,
          valueExpr: "MA_KHO",
          displayExpr: "TEN_KHO",
        },
      },
      {
        caption: "Color",
        dataField: "COLOR_ID",
        type: 2,
        dataSource: {
          data: [],
          valueExpr: "COLOR_ID",
          displayExpr: "COLOR_NAME",
        },
        visible: false,
      },
      {
        caption: "Size",
        dataField: "SIZE_ID",
        type: 2,
        dataSource: {
          data: [],
          valueExpr: "SIZE_ID",
          displayExpr: "SIZE_NAME",
        },
        visible: false,
      },
      {
        caption: "Loại giá",
        dataField: "CODE_TYPE",
        type: 2,
        dataSource: {
          data: this.props.lstSPType,
          valueExpr: "CODE_TYPE",
          displayExpr: "DESCRIPTION",
        },
      },
      {
        caption: "Số lượng",
        dataField: "SO_LUONG",
        type: 1,
      },
      {
        caption: "Đơn giá",
        dataField: "GIA_NT2",
        format: "Money",
        type: 1,
      },
      {
        caption: "Tiền ngoại tệ",
        dataField: "TIEN_NT2",
        format: "Money",
        type: 0,
      },
      {
        caption: "% CK",
        dataField: "TL_CK",
        type: 1,
        visible: true,
      },
      {
        caption: "Tiền CK",
        dataField: "TIEN_CK_NT",
        type: 0,
        format: "Money",
        visible: true,
      },
      {
        caption: "% VAT",
        dataField: "TS_GTGT",
        type: 1,
        visible: false,
      },
      {
        caption: "Tiền thuế",
        dataField: "THUE_GTGT_NT",
        type: 1,
        format: "Money",
        visible: false,
      },
      {
        caption: "Tổng tiền",
        dataField: "TT",
        format: "Money",
        type: 0,
      },
    ],
    dataSource: [],
    idRowSelected: "",
    ty_gia: this.props.ty_gia,
    amount: 0,
    tl_ck_item: 0,
    tien_ck_nt_item: 0,
    tl_ck: 0,
    ts_gtgt: 0,
    tt: 0,
    tien_ck_nt: 0,
    thue_gtgt_nt: 0,
    ma_nt: this.props.Master.MA_NT,
    loading: false,
    openDrawerMultiAdd: false,
    isStatusMultiSelected: false,
  };

  loadColumn = () => {
    const { listItem } = this.props;
    _.filter(this.state.column, (x) => {
      switch (x.dataField) {
        case "IDITEM": {
          x.dataSource.data = toUpperPropertyNameByArray(listItem);
          break;
        }
        default: {
          break;
        }
      }
    });
  };

  componentDidMount = async () => {
    const { lstCtChooseFromSO, listItem } = this.props;
    await toUpperPropertyNameByArray(listItem);
    listItem.map((item) => {
      item.CODE_TYPE = this.props.Master.CODE_TYPE;
      item.GIA_NT2 = this.selectedSPType(this.props.Master.CODE_TYPE, item.IDITEM);
    });
    this.loadColumn();
    this.checkStatusEdit(lstCtChooseFromSO);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { listItem } = this.props;
    if (this.props.ty_gia !== prevProps.ty_gia) {
      await this.setState({ ty_gia: this.props.ty_gia, amount: 0 });
      Promise.all(
        this.state.dataSource.map((item) => this.calc(item))
      ).then(() => this.calcTT());
    }
    if (!_.isEqual(this.state, prevState)) {
      this.props.getLine(this.state);
    }
    if (!_.isEqual(this.props.dsCT, prevProps.dsCT)) {
      if (this.props.ty_gia === prevProps.ty_gia) {
        this.checkStatusEdit(this.props.dsCT);
      }
    }
    if (!_.isEqual(listItem, prevProps.listItem)) {
      this.loadColumn();
    }
  };

  handleDelete = async () => {
    const { dataSource, idRowSelected } = this.state;
    this.props.getDeletectedItemID(idRowSelected);
    var amount = 0;
    const data = _.filter(dataSource, (x) => {
      if (x.ID === idRowSelected) {
        amount = x.TT;
        return false;
      }
      return true;
    });
    await this.setState({
      dataSource: data,
      idRowSelected: "",
      amount: amount ? this.state.amount - amount : this.state.amount,
    });
    await this.calcTT();
  };
  selectedSPType = (CODE_TYPE, IDITEM) => {
    let SPType = 0;
    this.props.listItem.map((item) => {
      if (_.isEqual(item.IDITEM, IDITEM)) {
        SPType =
          CODE_TYPE === "SI"
            ? item.GIA_SI
            : CODE_TYPE === "LE"
            ? item.GIA_LE
            : item.SELL_PRICE;
      }
    });
    return SPType;
  };

  hanleRowChange = async (params) => {
    const { listItem, Master } = this.props;
    let itemChange = await Object.assign(
      this.state.dataSource.find((x) => x.ID === params[0].key),
      params[0].data
    );
    const dataFilter = listItem.find((x) => x.IDITEM === params[0].data.IDITEM);
    if (!_.isUndefined(dataFilter)) {
      var data = await Object.assign(itemChange, {
        ...dataFilter,
        CODE_TYPE: Master.CODE_TYPE,
      });
      data.SO_LUONG = 1;
      data.GIA_NT2 = this.selectedSPType(data.CODE_TYPE, data.IDITEM);
      data.TL_CK = 0;
      await this.calc(data);
    } else {
      itemChange.GIA_NT2 = await this.selectedSPType(
        itemChange.CODE_TYPE,
        itemChange.IDITEM
      );
      await this.calc(itemChange);
    }
  };

  handleAdd = async () => {
    const { dataSource } = this.state;
    var hasItemEmpty = false;
    Promise.all(
      dataSource.map((item) => {
        if (Object.keys(item).length <= 1) {
          hasItemEmpty = true;
        }
      })
    ).then(async () => {
      hasItemEmpty
        ? openNotificationWithIcon(
            "error",
            "Vui lòng chọn sản phẩm vào dòng rỗng !"
          )
        : await this.setState({
            dataSource: [
              ...dataSource,
              { ID: Math.random().toString(36).substr(2, 9).toUpperCase() },
            ],
          });
    });
  };

  componentWillUnmount = () => {
    this.setState({ dataSource: [], isStatusMultiSelected: false });
  };
  render() {
    const {
      dataSource,
      loading,
      column,
      tl_ck,
      ts_gtgt,
      amount,
      tt,
      tien_ck_nt,
      thue_gtgt_nt,
      openDrawerMultiAdd,
    } = this.state;
    return (
      <div className="listItem">
        <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
          <Panel header="Thông tin hàng hóa" key="1">
            <Space style={{ marginBottom: 16, marginTop: 10 }}>
              <Button disabled={this.props.editDone} onClick={this.handleAdd}>
                Thêm dòng
              </Button>
              <Button
                disabled={this.props.editDone}
                onClick={() => this.setState({ openDrawerMultiAdd: true })}
              >
                Thêm từ danh mục
              </Button>
              <Button
                disabled={this.props.editDone}
                onClick={this.handleDelete}
              >
                Xóa dòng
              </Button>
            </Space>
            <Divider />
            {loading ? (
              <Spin spinning={loading} />
            ) : (
              <DHSDataGrid
                column={column}
                data={dataSource}
                dataKey={"ID"}
                selectionChanged={this.selectionChanged}
                hanleRowChange={this.hanleRowChange}
                disabled={this.props.editDone}
              />
            )}
            <Row style={{ marginTop: 15 }}>
              <Col
                span={8}
                xs={24}
                xl={8}
                offset={window.screen.width > 1200 ? 16 : 0}
              >
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Tổng tiền :" span={3}>
                    {FormatMoney(amount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="% CK :" span={1.5}>
                    <Input
                      name="tl_ck"
                      onChange={this.onChangeTextTotal}
                      value={tl_ck}
                      disabled={this.props.editDone}
                      style={{ width: 100 }}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Tiền CK :" span={1.5}>
                    {FormatMoney(tien_ck_nt)}
                  </Descriptions.Item>
                  <Descriptions.Item label="% VAT :" span={1.5}>
                    <Input
                      name="ts_gtgt"
                      onChange={this.onChangeTextTotal}
                      value={ts_gtgt}
                      disabled={this.props.editDone}
                      style={{ width: 100 }}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Thuế :" span={1.5}>
                    {FormatMoney(thue_gtgt_nt)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Thành tiền :" span={3}>
                    {FormatMoney(tt)}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        {openDrawerMultiAdd ? (
          <HandleMultiAdd
            isVisible={openDrawerMultiAdd}
            checkVisible={this.checkVisible}
            listItem={this.props.listItem}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
  checkStatusEdit = (dsCT) => {
    const { Master } = this.props;
    const { dataSource, isStatusMultiSelected } = this.state;
    var amount = 0;
    this.setState({ loading: true });
    if (!_.isEmpty(dsCT)) {
      Promise.all(
        dsCT.map(async (item, index) => {
          item.ID = Math.random().toString(36).substr(2, 9).toUpperCase();
          amount += item.TT;
        })
      ).then(async () => {
        if (!_.isEmpty(Master)) {
          await this.setState({
            loading: false,
            dataSource: isStatusMultiSelected
              ? _.concat(dataSource, dsCT)
              : dsCT,
            tl_ck: Master.TL_CK,
            ts_gtgt: Master.TS_GTGT,
            thue_gtgt_nt: Master.T_THUE_NT,
            tien_ck_nt: Master.T_CK,
            amount,
            tt: Master.T_TT,
            isStatusMultiSelected: false,
          });
        } else {
          await this.setState({
            loading: false,
            dataSource: dsCT,
            amount,
            tt: amount,
          });
        }
      });
    } else {
      this.setState({ loading: false });
    }
  };
  checkVisible = async (params) => {
    this.setState({ openDrawerMultiAdd: params.isVisible });
    if (!_.isNil(params.listItemSelected)) {
      await this.setState({ isStatusMultiSelected: true });
      await this.checkStatusEdit(params.listItemSelected);
      params.listItemSelected.map((item) => {
        this.calc(item);
      });
    }
  };
  selectionChanged = (params) => {
    this.setState({ idRowSelected: params[0] });
  };
  onChangeTextTotal = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    await this.calcTT();
  };
  calc = (object) => {
    const { ty_gia } = this.state;
    var calcAmount = 0;
    setTimeout(async () => {
      object.TIEN_NT2 = object.SO_LUONG * object.GIA_NT2 * ty_gia || 0;
      object.TIEN_CK_NT = (object.TIEN_NT2 * object.TL_CK) / 100 || 0;
      object.TT = object.TIEN_NT2 - object.TIEN_CK_NT;
      await this.state.dataSource.map((item) => {
        calcAmount += item.TT;
      });
      await this.setState({
        dataSource: this.state.dataSource,
        amount: calcAmount,
      });

      await this.calcTT();
    }, 100);
  };

  calcTT = async () => {
    const { amount, tl_ck, ts_gtgt } = this.state;
    const tien_ck_nt = (await (amount * tl_ck)) / 100;
    const thue_gtgt_nt = ((amount - tien_ck_nt) * ts_gtgt) / 100;
    await this.setState({
      tien_ck_nt,
      thue_gtgt_nt,
      tt: amount - tien_ck_nt + thue_gtgt_nt,
    });
    await this.props.getLine(this.state);
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    ctPackingSlip: state.SO2Reducer.ctPackingSlip,
    lstInventory: state.InventoryReducer.lstInventory,
    listItem: state.ItemReducer.listItem,
    listItemColor: state.LstColorReducer.listItemColor,
    listItemSize: state.LstSizeReducer.listItemSize,
    lstCtChooseFromSO: state.SO2Reducer.lstCtChooseFromSO,
    lookupSelectedItem: state.SellPriceReducer.lookupSelectedItem,
    // lookupSelectedItem2: state.SellPriceReducer.lookupSelectedItem2,
    lstSPType: state.SO2Reducer.lstSPType,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
