import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListCrmCustomer = (ma_cty) => (dispatch, getState) => {
  callApi("api/CrmCustomer/LstCrmCustomer", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CRMCUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getLists = (ma_cty) => (dispatch, getState) => {
  callApi("api/CrmCustomer/GetLists", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_CRMCUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
// export const getCrmCustomer = (query) => (dispatch, getState) => {
//   callApi("api/CrmCustomer/GetCrmCustomer", "POST", query)
//     .then((res) =>
//       dispatch({
//         type: types.GET_CRMCUSTOMER,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };

export const deleteCrmCustomer = (query) => (dispatch, getState) => {
  callApi("api/CrmCustomer/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_CRMCUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewCrmCustomer = (model) => (dispatch, getState) => {
  callApi("api/CrmCustomer/InsertCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_CRMCUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const updateCrmCustomer = (model) => (dispatch, getState) => {
  callApi("api/CrmCustomer/UpdateCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CRMCUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_CRMCUSTOMER,
  };
};
