import React, { Component } from "react";
import { Form, Input, Select, DatePicker, Checkbox, Radio } from "antd";
import RenderOptionSelect from "../../../components/controller/RenderOptionsSelect";
import moment from "moment";
import _ from "lodash";
const { Option } = Select;
//Input
class DHSInput extends Component {
  handleChangeText = (e) => {
    this.props.getValueChange({ name: e.target.name, value: e.target.value });
  };
  render() {
    //khi rules bằng true thì mới truyền nameRules
    const {
      label,
      name,
      disable,
      value,
      rules,
      nameRules,
      addonAfter,
      passWord,
    } = this.props;
    return (
      <div>
        <Form.Item
          name={rules ? nameRules : undefined}
          label={label}
          rules={[{ required: rules }]}
        >
          <Input
            name={name}
            placeholder={"Nhập " + label}
            value={value}
            onChange={this.handleChangeText}
            disabled={disable}
            addonAfter={addonAfter}
            type={passWord ? "password" : "text"}
          />
        </Form.Item>
      </div>
    );
  }
}
//TextArea
class DHSInputTextArea extends Component {
  handleChangeText = (e) => {
    this.props.getValueChange({ name: e.target.name, value: e.target.value });
  };
  render() {
    const { label, name, disable, value, rules, nameRules } = this.props;
    return (
      <div>
        <Form.Item
          label={label}
          name={rules ? nameRules : undefined}
          rules={[{ required: rules }]}
        >
          <Input.TextArea
            name={name}
            autoSize={{ minRows: 2, maxRows: 6 }}
            placeholder={"Nhập " + label}
            value={value}
            disabled={disable}
            onChange={this.handleChangeText}
          />
        </Form.Item>
      </div>
    );
  }
}

//Select Option
class DHSSelectOpt extends Component {
  handleChange = (value) => {
    this.props.onChange(value);
  };
  render() {
    const {
      label,
      name,
      array,
      valueOpt,
      valueRender,
      rules,
      disable,
      defaultValue,
      valueEmpty,
      placeHolder,
    } = this.props;
    return (
      <div>
        <Form.Item
          name={_.isNil(defaultValue) ? name : undefined}
          label={label}
          rules={[{ required: rules }]}
          initialValue={defaultValue}
          style={{ marginBottom: 10 }}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={_.isUndefined(label) ? placeHolder : "Chọn " + label}
            optionFilterProp="children"
            onChange={this.handleChange}
            value={defaultValue}
            disabled={disable}
          >
            {valueEmpty ? <Option value={""} key={"empty"}></Option> : <></>}
            {RenderOptionSelect(array, valueOpt, valueRender)}
          </Select>
        </Form.Item>
      </div>
    );
  }
}

//DatePicker
class DHSDatePicker extends Component {
  onChangeCreateTime = (date, dateString, name) => {
    console.log(date);
    this.props.getValueChange({
      name: name,
      value: _.isNull(date) ? moment() : date.add(7, "hours"),
    });
  };
  render() {
    const { label, nameState, value, disable } = this.props;
    return (
      <div>
        <Form.Item label={label}>
          <DatePicker
            onChange={(date, dateString, name) =>
              this.onChangeCreateTime(date, dateString, nameState)
            }
            defaultValue={value}
            format={"DD/MM/YYYY"}
            disabled={disable}
            // disabledDate={this.disabledDate}
          />
        </Form.Item>
      </div>
    );
  }
}

class DHSText extends Component {
  render() {
    const { label, value } = this.props;
    return (
      <div>
        <Form.Item
          label={label}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "lightgray",
            borderBottomStyle: "solid",
          }}
        >
          <h4>{value}</h4>
        </Form.Item>
      </div>
    );
  }
}

class DHSCheckBox extends Component {
  onCheckboxChange = (e) => {
    this.props.onChange(e);
  };
  render() {
    const { label, defaultChecked, name, disabled } = this.props;
    return (
      <div>
        <div className="label">{label}</div>
        <Checkbox
          defaultChecked={defaultChecked}
          name={name}
          onChange={this.onCheckboxChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

function formatNumber(value) {
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
}

class DHSNumericInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.props.onChange(value);
    }
  };

  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, "$1"));
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">
        {value !== "-" ? formatNumber(value) : "-"}
      </span>
    ) : (
      "Input a number"
    );
    return (
      <Input
        {...this.props}
        onChange={this.onChange}
        onBlur={this.onBlur}
        maxLength={25}
      />
    );
  }
}

export {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSInputTextArea,
  DHSText,
  DHSCheckBox,
  DHSNumericInput,
};
