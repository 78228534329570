import * as types from "../../../constants/ActionTypes";

var initialState = {
  listCustomerKind: [],
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
};

var CustomerKindReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_CUS_KIND: {
      return {
        ...state,
        listCustomerKind: action.payload,
      };
    }
    case types.INSERT_CUS_KIND:
      return {
        ...state,
        checkInsert: action.payload,
      };
    case types.DELETE_CUS_KIND:
      return {
        ...state,
        checkDelete: action.payload,
      };
    case types.UPDATE_CUS_KIND:
      return {
        ...state,
        checkUpdate: action.payload,
      };
    case types.CLEAR_CUS_KIND: {
      return {
        ...state,
        checkUpdate: false,
        checkDelete: false,
        checkInsert: false,
      };
    }
    default:
      return state;
  }
};

export default CustomerKindReducer;
