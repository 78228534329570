import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import So1Detail from "../salesOrder/so1Detail/so1Detail";
import * as actions from "../../../../redux/actions/Sales/approvalSalesOrder/approvalSalesOrder";
import _ from "lodash";
import So1Filter from "../salesOrder/so1Filter/so1Filter";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";

const objectColumn = {
  SO_CT: { name: "Số đơn hàng", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  TEN_TRANG_THAI: { name: "Trạng thái", width: 100 },
  T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

function ApprovalSalesOrder(props) {
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [isLoading, setLoading] = useState(false);
  const [customColumns, setCustomColumns] = useState([]);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [listSttRec, setListSttRec] = useState([]);
  const [isMaster, setMaster] = useState({});
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const APPROVALSO1 = useSelector((state) => state.ApprovalSalesOrdersReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListApprovalSalesOrder({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(APPROVALSO1.listApprovalSalesOrder);
    setData(APPROVALSO1.listApprovalSalesOrder);
    setFullData(APPROVALSO1.listApprovalSalesOrder);
    setLoading(false);
  }, [APPROVALSO1.listApprovalSalesOrder]);

  useEffect(() => {
    if (APPROVALSO1.approvalSalesOrders || APPROVALSO1.rejectSalesOrders) {
      dispatch(actions.getListApprovalSalesOrder({ ma_cty }));
      dispatch(actions.clearCache());
      setLoading(false);
    }
  }, [APPROVALSO1.approvalSalesOrders, APPROVALSO1.rejectSalesOrders]);

  const handleApproval = (params) => {
    setLoading(true);
    const query = {
      ma_cty: ma_cty,
      luser: userName,
      note3: "",
      arrayStt_rec: params,
    };
    dispatch(actions.approvalSalesOrder(query));
  };

  const handleReject = (params) => {
    setLoading(true);
    const query = {
      ma_cty: ma_cty,
      luser: userName,
      arrayStt_rec: params,
    };
    dispatch(actions.rejectSalesOrder(query));
  };

  const getDataFilter = (params) => {
    setData(params);
  };
  const multiSelection = async (params) => {
    if (!_.isEmpty(params)) {
      var listStt_rec = await params.map((item) => item.STT_REC);
      setListSttRec(listStt_rec);
    } else {
      setListSttRec([]);
    }
  };
  const getDataRow = (params) => {
    setMaster(params);
  };
  const checkVisible = (params) => {
    setOpenDrawerFilter(params);
    setOpenDrawerDetail(params);
  };
  return (
    <div className="approvalSalesOrder" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Duyệt đơn hàng"
          onBack={() => props.history.push("/Sale")}
        />
        <ToolBar
          setApproval={handleApproval}
          setReject={handleReject}
          Master={listSttRec}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListApprovalSalesOrder({ ma_cty }));
          }}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
          multiSelection={multiSelection}
        />
        {openDrawerDetail ? (
          <So1Detail
            isVisible={openDrawerDetail}
            checkVisible={checkVisible}
            Master={isMaster}
            approval={true}
          />
        ) : (
          <></>
        )}
        {openDrawerFilter ? (
          <So1Filter
            isVisible={openDrawerFilter}
            checkVisible={checkVisible}
            approvalSrc={true}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}

ApprovalSalesOrder.propTypes = {};

export default ApprovalSalesOrder;
