import * as types from "../../constants/ActionTypes";

var initialState = {
  userInfo: null,
  listCompany: [],
  userName: "",
  ma_cty: "",
  companyName: "",
  isLogin: null,
  listCategory: [],
  listCategoryLv1: [],
  listCategoryLv2: [],
  listCategoryLv3: [],
};

var LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN: {
      return {
        ...state,
        userInfo: action.payload,
        isLogin: action.payload !== null ? true : false,
      };
    }
    case types.LOGIN_POS: {
      return {
        ...state,
        userInfo: action.payload,
        isLogin: action.payload !== null ? true : false,
      };
    }
    case types.GET_LIST_COMPANY: {
      return {
        ...state,
        listCompany: action.payload,
      };
    }
    case types.SESSION_LOGIN: {
      return {
        ...state,
        userName: action.userName,
        ma_cty: action.ma_cty,
        companyName: action.companyName,
      };
    }
    case types.CLEAR_LOGIN: {
      return {
        ...state,
        isLogin: null,
      };
    }
    case types.GET_CATEGORY: {
      if (action.payload !== null) {
        var listCategoryLv1 = [];
        var listCategoryLv2 = [];
        var listCategoryLv3 = [];
        action.payload.filter((item) => {
          let _str = item.menuid.substring(3, 8);
          let _str2 = item.menuid.substring(0, 5);
          if (item.menuid.includes(".00.00")) {
            listCategoryLv1.push(item);
          } else if (_str !== "00.00" && item.menuid.includes(".00")) {
            listCategoryLv2.push(item);
          } else if (_str2 % 2 !== 0) {
            listCategoryLv3.push(item);
          }
        });
      }
      return {
        ...state,
        listCategory: action.payload,
        listCategoryLv1: listCategoryLv1,
        listCategoryLv2: listCategoryLv2,
        listCategoryLv3: listCategoryLv3,
      };
    }
    default:
      return state;
  }
};

export default LoginReducer;
