import React, { useState, useEffect } from "react";
import RoleInfoForm from "../roleInfoForm/roleInfoForm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/System/RoleInfo/roleInfo";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";

export default function RoleInfoCreate(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, Master } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);

  const checkEdit = useSelector((state) => state.RoleInfoReducer.checkEdit);

  useEffect(() => {
    if (checkEdit) {
      openNotificationWithIcon("success", "Sửa vai trò thành công !");
      dispatch(actions.clearCache());
      dispatch(actions.GetListRoleInfo({ MA_CTY }));
      checkVisible(false);
    }
  }, [checkEdit]);

  const handleActions = (params) => {
    dispatch(actions.updateRoleInfo({ roleInfo: params, MA_CTY }));
  };

  return (
    <RoleInfoForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      Master={Master}
      handleActions={handleActions}
      title="Sửa vai trò"
    />
  );
}
