import * as types from "../../../constants/ActionTypes";

var initialState = {
  listUserInfo: [],
  lstRole: [],
  lstTitleUser: [],
  checkInsert: false,
  checkEdit: false,
  checkDelete: false,
};

var UserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_USER_INFO: {
      return {
        ...state,
        listUserInfo: action.payload.lstPH,
        lstRole: action.payload.lstRole,
        lstTitleUser: action.payload.lstTitleUser,
      };
    }
    case types.ADD_NEW_USER_INFO: {
      return {
        ...state,
        checkInsert: action.payload,
      };
    }
    case types.UPDATE_USER_INFO: {
      return {
        ...state,
        checkEdit: action.payload,
      };
    }
    case types.DELETE_USER_INFO: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_CACHE_USER_INFO: {
      return {
        ...state,
        checkDelete: false,
        checkEdit: null,
        checkInsert: null,
      };
    }
    default:
      return state;
  }
};

export default UserInfoReducer;
