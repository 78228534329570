import * as types from "../../../constants/ActionTypes";

var initialState = {
  listPlanning: [],
  listPlanningWorkOrder: [],
};

var MasterPlanningReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PLANNING_DEADLINE_BY_MACHINE: {
      return {
        ...state,
        listPlanning: action.payload,
      };
    }
    case types.GET_PLANNING_DEADLINE_BY_WORKORDER: {
      return {
        ...state,
        listPlanningWorkOrder: action.payload,
      };
    }
    default:
      return state;
  }
};

export default MasterPlanningReducer;
