import React from "react";
import CompareBars from "./Types/barCompare";
export default function DHSCompareBars(props) {
  const { title, data, name, nameCompare } = props;
  return (
    <div className="DHSCompareBars">
      <div className="h4">{title}</div>
      <div className="salesByDays">
        <CompareBars
          height={250}
          width={150}
          data={data.value}
          data2={data.value2}
          label={data.label}
          name={name}
          name2={nameCompare}
        />
      </div>
    </div>
  );
}
