import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSInputTextArea,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { ReloadOutlined } from "@ant-design/icons";
import * as actions from "../../../../../redux/actions/Purchases/purchaseOrder/purchaseOrder";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import PropTypes from "prop-types";
import moment from "moment";
import {
  toUpperPropertyName,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};

Po1Create.propTypes = {
  _objectCreate: PropTypes.object,
};

Po1Create.defaultProps = {
  _objectCreate: {
    IDCUSTOMER: "",
    MA_CTY: "",
    LUSER: "",
    CUSER: "",
    DIA_CHI: "",
    EMAIL: "",
    TEL: "",
    MA_KH: "",
    NGUOI_GD: "",
    SO_CT: "",
    NGAY_CT: moment(),
    MA_NT: "VND",
    TY_GIA: 1,
    IDEMPLOYEE: "",
    DIEN_GIAI: "",
    MA_GD: "1",
    INCOTERM_ID: "",
    MA_HD: "",
    MA_BP: "",
    STATUSDOCUMENT: "1",
    TRANG_THAI: "2",
  },
};

export default function Po1Create(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, _objectCreate, history } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.ma_cty);
  const PO1State = useSelector((state) => state.PO1Reducer);
  const [isLoading, setLoading] = useState(false);
  const [objCreate, setObjCreate] = useState(_objectCreate);

  useEffect(() => {
    dispatch(actions.getSoCt({ ma_cty }));
  }, []);

  useEffect(() => {
    setObjCreate({
      ...objCreate,
      TY_GIA: PO1State.exchangeRate == 0 ? 1 : PO1State.exchangeRate,
    });
  }, [PO1State.exchangeRate]);

  useEffect(() => {
    setLoading(false);
    if (PO1State.newItem === "-1") {
      openNotificationWithIcon("error", "Trùng số chứng từ !");
      dispatch(actions.clearSTT());
    } else if (_.isObject(PO1State.newItem)) {
      openNotificationWithIcon("success", "Thêm đơn hàng mua thành công !");
      checkVisible(false);
      toUpperPropertyName(PO1State.newItem);
      history.push("/PO1Edit", {
        Master: { ...PO1State.newItem, TEN_KH: objCreate.TEN_KH },
      });
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [PO1State.newItem]);

  const handleCancel = () => {
    dispatch(actions.clearCache());
    checkVisible(false);
  };

  const handleOk = () => {
    const PurchaseOrder = {
      modelM: {
        ...objCreate,
        SO_CT: PO1State.soCt,
        MA_CTY: ma_cty,
        LUSER: userName,
        CUSER: userName,
      },
      arrayCt: [],
    };
    setLoading(true);
    dispatch(actions.addNewPurchaseOrder(PurchaseOrder));
  };

  const handleChangeText = (params) => {
    setObjCreate({ ...objCreate, [params.name]: params.value });
  };

  const handleKHChange = (params) => {
    let customer = PO1State.listCustomer.find((x) => x.IDCUSTOMER === params);
    setObjCreate({
      ...objCreate,
      DIA_CHI: customer.DIA_CHI,
      IDCUSTOMER: params,
      EMAIL: customer.EMAIL,
      TEL: customer.TEL,
      NGUOI_GD: customer.NGUOI_GD,
      MA_KH: customer.MA_KH,
      TEN_KH: customer.TEN_KH,
      MA_SO_THUE: customer.MA_SO_THUE,
    });
  };

  const handleChangeCurrency = (params) => {
    setObjCreate({ ...objCreate, MA_NT: params });
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: objCreate.NGAY_LCT,
      })
    );
  };
  const reloadSoCt = () => {
    dispatch(actions.getSoCt({ ma_cty }));
  };
  return (
    <Drawer
      title="Thêm mới đơn hàng mua"
      width={700}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8 }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Collapse
          defaultActiveKey={["1", "2"]}
          ghost
          className="po1-create"
          expandIconPosition="right"
        >
          <Panel header="Thông tin nhà cung cấp" key="1">
            <Form
              id="submit-form"
              onFinish={handleOk}
              layout="vertical"
              validateMessages={validateMessages}
            >
              <DHSSelectOpt
                label="Nhà cung cấp"
                name="IDCUSTOMER"
                array={PO1State.listCustomer}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                rules={true}
                defaultValue={
                  objCreate.IDCUSTOMER === "" ? undefined : objCreate.IDCUSTOMER
                }
                onChange={handleKHChange}
              />
              <DHSInput
                label="Địa chỉ"
                name="DIA_CHI"
                value={objCreate.DIA_CHI}
                getValueChange={handleChangeText}
              />
              <DHSInput
                label="Email"
                name="EMAIL"
                value={objCreate.EMAIL}
                getValueChange={handleChangeText}
              />
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSInput
                    label="Người liên hệ"
                    name="NGUOI_GD"
                    value={objCreate.NGUOI_GD}
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số điện thoại"
                    name="TEL"
                    value={objCreate.TEL}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
            </Form>
          </Panel>
          <Panel header="Thông tin đơn hàng mua" key="2">
            <Form layout="vertical">
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Kiểu phiếu"
                    name="MA_GD"
                    array={PO1State.sysExchange}
                    valueOpt={"MA_GD"}
                    valueRender={"MO_TA"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "MA_GD" })
                    }
                    defaultValue={"1"}
                  />
                </Col>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Điều kiện giao hàng"
                    name="INCOTERM_ID"
                    array={PO1State.lstIncoterms}
                    valueOpt={"INCOTERM_ID"}
                    valueRender={"INCOTERM_NAME"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "INCOTERM_ID" })
                    }
                    valueEmpty={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Hợp đồng"
                    name="MA_HD"
                    array={PO1State.dm_hopdong_mua}
                    valueOpt={"MA_HD"}
                    valueRender={"TEN_HD"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "MA_HD" })
                    }
                    valueEmpty={true}
                  />
                </Col>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Bộ phận"
                    name="MA_BP"
                    array={PO1State.dm_bp}
                    valueOpt={"MA_BP"}
                    valueRender={"TEN_BP"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "MA_BP" })
                    }
                    valueEmpty={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Trạng thái"
                    array={PO1State.listStatus}
                    valueOpt={"trang_thai"}
                    valueRender={"mo_ta"}
                    defaultValue={"2"}
                    disable={true}
                  />
                </Col>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Trạng thái GD"
                    array={PO1State.dm_trangthaiDoc_PO1}
                    valueOpt={"TRANG_THAI"}
                    valueRender={"MO_TA"}
                    defaultValue={"1"}
                    disable={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày chứng từ"
                    value={objCreate.NGAY_CT}
                    nameState="NGAY_CT"
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số phiếu"
                    name="SO_CT"
                    value={PO1State.soCt}
                    getValueChange={handleChangeText}
                    addonAfter={<ReloadOutlined onClick={reloadSoCt} />}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Loại tiền tệ"
                    array={PO1State.listCurrency}
                    valueOpt={"MA_NT"}
                    valueRender={"MA_NT"}
                    defaultValue={objCreate.MA_NT}
                    onChange={handleChangeCurrency}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Tỷ giá"
                    name="TY_GIA"
                    disable={objCreate.MA_NT === "VND" ? true : false}
                    value={objCreate.TY_GIA}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <DHSInputTextArea
                label="Ghi Chú"
                name="DIEN_GIAI"
                value={objCreate.DIEN_GIAI}
                getValueChange={handleChangeText}
              />
            </Form>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
}
