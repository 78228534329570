import React, { useState, useEffect } from "react";
import { Drawer, Button, Row, Col, Input } from "antd";
import { useSelector } from "react-redux";
import { toUpperPropertyNameByArray } from "../../../../../../controller/Format";
import DHSListItem from "../../../../../../../common/DHSControl/DHSListItem/DHSListItem";
import { SearchOutlined } from "@ant-design/icons";
import "../../../index.css";
function Header() {
  return (
    <div>
      <Row style={{ color: "gray", fontSize: 12, marginLeft: 10 }}>
        <Col span={2}>Ảnh</Col>
        <Col span={13}>Tên vật tư</Col>
        <Col span={4}>Đơn giá</Col>
        <Col span={4}>Số lượng</Col>
      </Row>
    </div>
  );
}
export default function HandleMultiAdd(props) {
  const { isVisible, checkVisible } = props;
  // const lstItem = useSelector((state) => state.ItemReducer.listItem);
  const [data, setData] = useState([]);
  const lookupSelectedItem = useSelector((state) =>
    toUpperPropertyNameByArray(state.ItemReducer.listItem)
  );
  const [isSelected, setSelected] = useState([]);
  const handleCancel = () => {
    checkVisible({ isVisible: false, isSelected: [] });
  };
  const confirmSelected = () => {
    checkVisible({
      isVisible: false,
      isSelected,
    });
  };
  const getItemSelected = (params) => {
    setSelected(params);
  };
  useEffect(() => {
    lookupSelectedItem.map((x) => {
      x.GIA = 0;
      x.SO_LUONG = 1;
    });
    setData(lookupSelectedItem);
  }, [lookupSelectedItem]);

  const getDataFilter = (e) => {
    const data = _.filter(lookupSelectedItem, (item) => {
      if (
        item.TEN_VT.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    setData(data);
  };

  return (
    <Drawer
      className="handleMultiAdd"
      title="Chọn vật tư"
      width={600}
      maskClosable={false}
      onClose={handleCancel}
      visible={isVisible}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={confirmSelected}
          >
            Xác nhận
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={12} style={{ margin: "10px 0px 0px 10px" }}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Tìm kiếm sản phẩm"
            onChange={getDataFilter}
            // value={customer.TEN_KH}
            // ref={f4Ref}
          />
        </Col>
      </Row>
      <DHSListItem
        header={<Header />}
        listItem={data}
        keyItem={"IDITEM"}
        title={"TEN_VT"}
        description={"MA_VT"}
        inputName1={"GIA_LE"}
        avatar={"ANH"}
        inputDisplay={true}
        getItemSelected={getItemSelected}
      />
    </Drawer>
  );
}
