import React, { useState, useEffect } from "react";
import { Descriptions } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import DHSTable from "../../../../../../common/DHSControl/DHSTable/DHSTable";
import {
  FormatMoney,
  toUpperPropertyNameByArray,
  FormatColumnDHSTable,
  result,
} from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/CashAndBank/BankPayment/BankPayment";
const objectColumn = {
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 200 },
  TK_NO: { name: "Tài khoản nợ", width: 100 },
  TK: { name: "Tài khoản có", width: 100 },
  PS_NO: { name: "Tiền NT", width: 100 },
  DIEN_GIAI: { name: "Diễn giải", width: 100 },
};
const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};
export default function ListItem(props) {
  const { Master } = props;
  const dispatch = useDispatch();
  const [customColumns, setCustomColumns] = useState([]);
  const ctBankPayment = toUpperPropertyNameByArray(
    useSelector((state) => state.CA4Reducer.ctBankPayment)
  );

  useEffect(() => {
    dispatch(actions.getCTBankPayment(Master));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);
  return (
    <div className="CA4-detail-listitem">
      <DHSTable
        data={ctBankPayment}
        customColumns={customColumns}
        scroll={{ y: 200, x: 800 }}
        rowKey="MA_KH"
      />
      {/* <div className="detailTotal">
        <Descriptions bordered size="small">
          <Descriptions.Item label="Tổng tiền :" span={3}>
            {FormatMoney(Master.T_TIEN)}
          </Descriptions.Item>
          <Descriptions.Item label="Thành tiền :" span={3}>
            {FormatMoney(Master.T_TIEN)}
          </Descriptions.Item>
        </Descriptions>
      </div> */}
    </div>
  );
}
