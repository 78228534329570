import React, { Component } from "react";
import VendorsForm from "../vendorsForm/vendorsForm";
export default class VendorsDetail extends Component {
  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <VendorsForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          title="Thông tin nhà cung cấp"
          Master={Master}
          noneEdit={true}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
