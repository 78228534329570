import React, { Component } from "react";
import DriverForm from "../DriverForm/DriverForm";
export default class DriverDetail extends Component {
  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <DriverForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          title="Thông tin tài xế"
          Master={Master}
          noneEdit={true}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
