import React from "react";
import Bar from "../ChartType/bar";
import Pie from "../ChartType/pieChart";
import DateBox from "devextreme-react/date-box";
import "./index.css";
import { Button } from "devextreme-react/button";
import * as actions from "../../../../redux/actions/Manufacturing/MachineRpt/index";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, InputNumber, Spin, Collapse, Divider } from "antd";
import { DHSSelectOpt } from "../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
import { periodData } from "../../../controller/DataSample";
import {
  onPeriodChange,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
const { Panel } = Collapse;

class MachineReport extends React.Component {
  state = {
    totalRptmachine: [],
    listRptMachine: [],
    totalRptMachinePie: [],
    totalDuration: 1,
    period: "formTo",
    time_to: moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_from: moment()
      .subtract("1", "days")
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    machineGroupId: "",
    loading: false,
  };
  componentDidMount = () => {
    const { containDataRptMachine, ma_cty } = this.props;
    this.props.GetLstMachinesGroup({ ma_cty: ma_cty });
    if (containDataRptMachine.machineID !== "") {
      this.setState(
        {
          time_to: containDataRptMachine.time_to,
          time_from: containDataRptMachine.time_from,
        },
        () => {
          this.getRptMachine(this.state.time_from, this.state.time_to);
        }
      );
    } else {
      this.getRptMachine();
    }
  };
  componentDidUpdate = (prevProps) => {
    if (
      !_.isEqual(this.props.TotalRptMachine, prevProps.TotalRptMachine) ||
      !_.isEqual(this.props.listRptMachine, prevProps.listRptMachine)
    ) {
      this.getFullPie(this.props.TotalRptMachine);
      this.setState({
        totalRptmachine: this.props.TotalRptMachine,
        listRptMachine: this.props.listRptMachine,
        loading: false,
      });
    }
  };
  componentWillUnmount = () => {
    this.props.listRptMachine.length = 0;
    this.setState({
      totalRptmachine: [],
      listRptMachine: [],
    });
  };
  getFullPie = (array) => {
    const object = _.groupBy(array, "state_description");
    var total = 0;
    var array = [];
    for (var key in object) {
      const value = _.sumBy(object[key], "duration");
      total += value;
      array.push({
        state_description: key,
        machine_code: object[key][0].ma_cty,
        duration: value,
      });
    }
    this.setState({ totalDuration: total, totalRptMachinePie: array });
  };
  render() {
    const {
      time_to,
      time_from,
      totalRptmachine,
      listRptMachine,
      period,
      totalRptMachinePie,
      totalDuration,
      loading,
    } = this.state;
    const { groupMachine } = this.props;
    // console.log(listRptMachine);
    // console.log(moment().weeks());
    // console.log(moment(moment().weeks(1)).startOf("weeks").add(1, "days"));
    // console.log(moment(moment().weeks(1)).endOf("weeks").add(1, "days"));
    return (
      <div className="machineReport-container">
        <Spin spinning={loading}>
          <div className="title">Báo cáo máy sản xuất</div>
          <div className="pickDateTime">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24} xs={24} xl={4}>
                <div className="textTime">Khu vực máy</div>
                <div>
                  <DHSSelectOpt
                    placeHolder="Chọn khu vực máy"
                    array={groupMachine}
                    valueOpt="MACHINE_GROUP_ID"
                    valueRender="MACHINE_GROUP_NAME"
                    onChange={this.onHandleMachineGroupFilter}
                    valueEmpty={true}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={4}>
                <div className="textTime">Lọc</div>
                <div>
                  <DHSSelectOpt
                    placeHolder="Chọn điều kiện lọc"
                    array={periodData}
                    defaultValue={period}
                    valueOpt="value"
                    valueRender="name"
                    onChange={this.onHandleFilter}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={2}>
                <div className="textTime">Số tuần</div>
                <div>
                  <InputNumber
                    min={1}
                    max={52}
                    defaultValue={moment().weeks()}
                    onChange={this.onChangeWeek}
                    disabled={period === "week" ? false : true}
                    maxLength={2}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={4}>
                <div className="textTime">Thời gian từ</div>
                <div>
                  <DateBox
                    value={time_from}
                    defaultValue={time_from}
                    type="datetime"
                    onValueChanged={this.onValueChanged("time_from")}
                    displayFormat={"dd/MM/yyyy HH:mm:ss"}
                    disabled={period === "formTo" ? false : true}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} xl={4}>
                <div className="textTime">Thời gian đến</div>
                <div>
                  <DateBox
                    value={time_to}
                    defaultValue={time_to}
                    type="datetime"
                    min={time_from}
                    onValueChanged={this.onValueChanged("time_to")}
                    displayFormat={"dd/MM/yyyy HH:mm:ss"}
                    disabled={period === "formTo" ? false : true}
                  />
                </div>
              </Col>
              <Col span={8} xs={24} xl={6}>
                <Button
                  style={{ marginTop: 22 }}
                  width={120}
                  text="Tìm kiếm"
                  type="normal"
                  stylingMode="contained"
                  onClick={this.onClickSearch}
                />
              </Col>
            </Row>
          </div>
          <div className="contentMachineRp">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24} xs={24} xl={6}>
                <Bar
                  dataSource={totalRptmachine}
                  type="stackedBar"
                  title="Total machines daily report"
                  descriptionField="state_description"
                  argumentField="ma_cty"
                  valueField="duration"
                  scroll={false}
                  customizeTooltip={this.customizeTooltip}
                  customerText={this.customerText}
                />
              </Col>
              <Col className="gutter-row" span={24} xs={24} xl={18}>
                <Bar
                  dataSource={listRptMachine}
                  type="stackedBar"
                  title="Machines daily report"
                  descriptionField="state_description"
                  argumentField="machine_code"
                  valueField="duration"
                  onClickCol={this.onClickCol}
                  displayLabelMode="rotate"
                  customizeTooltip={this.customizeTooltip}
                  customerText={this.customerText}
                />
              </Col>
              <Col className="gutter-row" span={24} xs={24} xl={24}>
                <Pie
                  legendVisible={false}
                  title="Full Day"
                  dataSource={
                    totalRptMachinePie.length > 0 ? totalRptMachinePie : []
                  }
                  totalDuration={totalDuration}
                />
              </Col>
              <Col
                className="gutter-row"
                span={24}
                xs={24}
                xl={24}
                style={{ marginBottom: 20 }}
              >
                <Collapse expandIconPosition="right" ghost>
                  {this.renderPieByGroup(groupMachine)}
                </Collapse>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
  renderPieByGroup = (array) => {
    var result = [];
    if (!_.isEmpty(array)) {
      result = array.map((x, index) => {
        return (
          <Panel
            header={
              <Divider orientation="left" style={{ fontWeight: "bold" }}>
                {"Khu vực máy " + x.MACHINE_GROUP_CODE}
              </Divider>
            }
            key={index}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {this.renderPieDetail(
                this.state.listRptMachine,
                x.MACHINE_GROUP_ID
              )}
            </Row>
          </Panel>
        );
      });
    }
    return result;
  };
  renderPieDetail = (array, machineGroupId) => {
    const calcTotalDuration = (array) => {
      var value = 0;
      array.map((item) => {
        value += item.duration;
      });
      return value;
    };
    var result = [];
    if (!_.isEmpty(array)) {
      const arrayObject = _.groupBy(array, "machine_code");
      result = Object.keys(arrayObject).map(function (key, index) {
        if (_.isEqual(arrayObject[key][0].machine_group_id, machineGroupId)) {
          return (
            <Col className="gutter-row" xs={24} md={12} xl={6} key={key}>
              <Pie
                title={key}
                legendVisible={false}
                dataSource={arrayObject[key]}
                totalDuration={calcTotalDuration(arrayObject[key])}
                legendVisible={true}
              />
            </Col>
          );
        }
      });
    }
    return result;
  };
  onValueChanged = (name) => (e) => {
    this.setState({ [name]: e.value });
  };
  onClickSearch = () => {
    this.getRptMachine(
      this.state.time_from,
      this.state.time_to,
      this.state.machineGroupId
    );
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 500);
  };
  onClickCol = (params) => {
    this.props.containRptMachine({
      time_to: moment(this.state.time_to),
      time_from: moment(this.state.time_from),
      machineID: params.machine_id,
      machine_name: params.machine_name,
    });
    this.props.history.push("/machinereporttimeline");
  };
  onHandleFilter = (params) => {
    const result = onPeriodChange(params, moment().weeks());
    this.setState({
      period: result.period,
      time_to: result.toDate,
      time_from: result.formDate,
    });
  };
  onChangeWeek = (value) => {
    const result = onPeriodChange("week", value);
    this.setState({
      period: result.period,
      time_to: result.toDate,
      time_from: result.formDate,
    });
  };
  onHandleMachineGroupFilter = (value) => {
    this.setState({ machineGroupId: value });
  };
  tranferToHours = (value) => {
    let convert = (value) => {
      return value >= 10 ? value : "0" + value;
    };
    let hours = Math.floor(value / 60);
    let minutes = value % 60;
    return convert(hours) + ":" + convert(minutes);
  };
  customerText = (item) => {
    return this.tranferToHours(item.value);
  };
  roundNumber(num, scale) {
    if (!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = "";
      if (+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(
        Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
        "e-" +
        scale
      );
    }
  }
  customizeTooltip = (arg) => {
    const value = (parseInt(arg.value) / arg.total) * 100;
    return {
      text: `${arg.argument}
      ${arg.seriesName} Time: ${this.tranferToHours(arg.originalValue)}
      ${arg.seriesName}:${_.round(value, 2)} %
      `,
    };
  };
  getRptMachine = (
    time_from = moment()
      .subtract("1", "days")
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    time_to = moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
    machine_group_id = ""
  ) => {
    const query = {
      ma_cty: this.props.ma_cty,
      time_from: moment(time_from).add(7, "hours"),
      time_to: moment(time_to).add(7, "hours"),
      machine_group_id: machine_group_id,
    };
    this.props.GetRptMachineUptimeTotalAll(query);
    this.props.GetRptMachineUptime(query);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listRptMachine: state.MachineRptReducer.listRptMachine,
    TotalRptMachine: state.MachineRptReducer.TotalRptMachine,
    containDataRptMachine: state.MachineRptReducer.containDataRptMachine,
    groupMachine: state.MachineRptReducer.groupMachine,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    GetRptMachineUptimeTotalAll: (query) => {
      dispatch(actions.GetRptMachineUptimeTotalAll(query));
    },
    GetRptMachineUptime: (query) => {
      dispatch(actions.GetRptMachineUptime(query));
    },
    GetLstMachinesGroup: (ma_cty) => {
      dispatch(actions.GetLstMachinesGroup(ma_cty));
    },
    containRptMachine: (data) => {
      dispatch(actions.containRptMachine(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineReport);
