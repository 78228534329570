import "./index.css";
import React, { Component } from "react";
import { Divider, Spin, Space, Button, Popconfirm, Tabs } from "antd";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import { connect } from "react-redux";
import * as actions from "../../../../../redux/actions/Sales/packingSlip/index";
import * as actionsDriver from "../../../../../redux/actions/Category/driver/driver";
import * as actionsVehicle from "../../../../../redux/actions/Category/vehicle/vehicle";
import Driver from "./Driver/driver";
import ListAddress from "./listAddress/listAddress";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actionsSellPrice from "../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import {
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
const { TabPane } = Tabs;

class SO2Edit extends Component {
  state = {
    loading: false,
    Master: this.props.history.location.state
      ? this.props.history.location.state.Master
      : {},
    Line: {},
    ty_gia: this.props.history.location.state
      ? this.props.history.location.state.Master.TY_GIA
      : 1,
    editDone: false,
    Address: this.props.lstAddressChooseFromSO,
    Driver: [],
    isAddAddressToLine: [],
    listDelectedItemID: [],
    lineChange: false,
  };

  componentDidMount = () => {
    if (this.props.history.location.state) {
      const { Master } = this.props.history.location.state;
      this.props.getListNeed({
        ma_cty: this.props.ma_cty,
        stt_rec: Master.STT_REC,
      });
      this.props.getCustomer({
        ma_cty: this.props.ma_cty,
        idCustomer: Master.IDCUSTOMER,
      });
      // this.props.lookupSelectedItem({
      //   ma_cty: this.props.ma_cty,
      //   idcustomer: "",
      //   ma_nt: "VND",
      //   ngay_ct: moment(Master.NGAY_CT).add(7, "hours"),
      // });
      // this.props.lookupSelectedItem2({
      //   ma_cty: this.props.ma_cty,
      //   idcustomer: "",
      //   ma_nt: "VND",
      //   ngay_ct: moment(Master.NGAY_CT).add(7, "hours"),
      // });
    } else {
      this.props.history.push("/PackingSlip");
    }
  };

  componentWillUnmount = () => {
    this.props.clearCache();
  };

  componentDidUpdate = (prevProps) => {
    const {
      newEdit,
      checkDelete,
      dsCtDriver,
      phPackingSlip,
      contactPackingSlip,
      lstAddressChooseFromSO,
    } = this.props;
    if (!_.isEqual(newEdit, prevProps.newEdit)) {
      if (newEdit !== null) {
        const query = {
          stt_rec: this.state.Master.STT_REC,
          ma_cty: this.props.ma_cty,
        };
        this.props.getCTPackingSlip(query);
        this.props.getPHPackingSlip(query);
        this.setState({ loading: false, editDone: true });
        this.props.clearSTT();
        openNotificationWithIcon("success", "Lưu thành công !");
      }
    }
    if (!_.isEqual(phPackingSlip, prevProps.phPackingSlip)) {
      const { Master } = this.props.history.location.state;
      this.props.getCTPackingSlip({
        stt_rec: Master.STT_REC,
        ma_cty: this.props.ma_cty,
      });
      this.setState({ loading: false });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.props.history.push("/PackingSlip");
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    if (
      !_.isEqual(dsCtDriver, prevProps.dsCtDriver) ||
      !_.isEqual(contactPackingSlip, prevProps.contactPackingSlip)
    ) {
      this.setState({
        Driver: dsCtDriver,
        Address: contactPackingSlip,
      });
    }
  };

  confirm = async () => {
    if (this.deleteItemEmpty(this.state.Line)) {
      if (!this.state.loading) {
        this.setState({ loading: true });
        this.props.updatePackingSlip({
          modelM: this.state.Master,
          arrayCt: this.state.Line,
          arrayAddress: this.state.Address,
          arrayDriver: this.state.Driver,
        });
      }
    }
  };

  confirmDelete(Master) {
    this.setState({ loading: true });
    this.props.deleteAPackingSlip({ modelM: Master });
  }

  render() {
    const {
      loading,
      ty_gia,
      editDone,
      isAddAddressToLine,
      listDelectedItemID,
    } = this.state;
    const { customer, ctPackingSlip, phPackingSlip } = this.props;
    const { Master } = this.props.history.location.state
      ? this.props.history.location.state
      : "";
    return (
      <div className="so2-edit">
        {Master ? (
          <div>
            <Spin spinning={loading}>
              <Space className="topMenu">
                <Button
                  onClick={this.confirm}
                  style={{ display: editDone ? "none" : "block" }}
                  icon={<CheckOutlined />}
                >
                  Lưu
                </Button>
                <Button
                  style={{ display: editDone ? "block" : "none" }}
                  onClick={() => {
                    this.setState({ editDone: false });
                  }}
                  icon={<EditOutlined />}
                >
                  Sửa
                </Button>
                <Popconfirm
                  title="Bạn muốn xóa phiếu ?"
                  onConfirm={() => this.confirmDelete(Master)}
                  okText="Đồng ý"
                  cancelText="Hủy"
                  disabled={Object.keys(Master).length == 0 ? true : false}
                >
                  <Button icon={<DeleteOutlined />}>Xóa</Button>
                </Popconfirm>
                <Button
                  onClick={() => {
                    this.props.history.push("/PackingSlip");
                  }}
                  icon={<RollbackOutlined />}
                >
                  Trở về
                </Button>
              </Space>
              <div style={{ paddingLeft: 15 }}>
                <h4 style={{ margin: 0, color: "#1890FF" }}>
                  PHIẾU XUẤT BÁN HÀNG
                </h4>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
                    {Master.SO_CT} : {customer.ten_kh}
                  </h2>
                </div>
              </div>
              <Divider />
              <General
                Master={Master}
                customer={customer}
                getMaster={this.getMaster}
                editDone={editDone}
              />
              <Tabs onTabClick={this.clickTabs}>
                <TabPane tab="Thông tin hàng hóa" key="1">
                  <ListItem
                    Master={_.isEmpty(phPackingSlip) ? Master : phPackingSlip}
                    getLine={this.getLine}
                    dsCT={ctPackingSlip}
                    ty_gia={ty_gia}
                    editDone={editDone}
                    getDeletectedItemID={this.getDeletectedItemID}
                  />
                </TabPane>
                <TabPane tab="Thông tin tài xế" key="2">
                  <Driver getDriver={this.getDriver} />
                </TabPane>
                <TabPane tab="Thông tin giao hàng" key="3">
                  <ListAddress
                    dataSource={isAddAddressToLine}
                    getAddressForItem={this.getAddressForItem}
                    lineChange={this.state.lineChange}
                    listDelectedItemID={listDelectedItemID}
                  />
                </TabPane>
              </Tabs>
            </Spin>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  getMaster = (params) => {
    const { Master } = this.state;
    Master.IDCUSTOMER = params.IDcustomer;
    Master.MA_KH = params.ma_kh;
    Master.NGUOI_GD = params.contact;
    Master.TY_GIA = params.ty_gia;
    Master.MA_NT = params.ma_nt;
    Master.DIEN_GIAI = params.ghi_chu;
    this.setState({ ty_gia: params.ty_gia });
  };

  getLine = (params) => {
    const { Master } = this.state;
    Master.T_SO_LUONG = 0;
    Master.T_TIEN2 = 0;
    Master.T_TIEN_NT2 = 0;
    // Master.T_TT = 0;
    // Master.T_TT_NT = 0;
    if (params.dataSource.length > 0) {
      params.dataSource.filter(async (x) => {
        if (Object.keys(x).length > 1) {
          Master.T_SO_LUONG += parseInt(x.SO_LUONG);
          x.SO_LUONG_DELIVERY = x.SO_LUONG - (x.SL_DLV_PLAN || 0);
          x.TT_NT = x.TT / this.state.ty_gia;
          Master.T_TIEN2 += x.TIEN_NT2;
          Master.T_TIEN_NT2 += x.TIEN_NT2 / this.state.ty_gia;
        }
      });
      Master.TL_CK = parseInt(params.tl_ck);
      Master.T_CK_NT = params.tien_ck_nt / this.state.ty_gia;
      Master.T_CK = params.tien_ck_nt;
      Master.TS_GTGT = parseInt(params.ts_gtgt);
      Master.T_THUE_NT = params.thue_gtgt_nt / this.state.ty_gia;
      Master.T_THUE = params.thue_gtgt_nt;
      Master.T_TT = params.tt;
      Master.T_TT_NT = params.tt / this.state.ty_gia;
    }
    this.setState({
      Line: params.dataSource,
      isAddAddressToLine: _.filter(params.dataSource, (item) => {
        return Object.keys(item).length > 3;
      }),
    });
  };

  getAddressForItem = (params) => {
    this.setState({ Address: params, listDelectedItemID: [] });
  };

  getDriver = (params) => {
    this.setState({ Driver: params });
  };

  clickTabs = (value) => {
    if (value === "3") {
      this.setState({ lineChange: !this.state.lineChange });
    }
  };

  getDeletectedItemID = async (params) => {
    this.setState({
      listDelectedItemID: [...this.state.listDelectedItemID, params],
    });
  };

  deleteItemEmpty = (array) => {
    var check = true;
    if (array.length > 0) {
      array.map((item, index) => {
        if (_.isNil(item.IDITEM)) {
          array.splice(index, 1);
        } else if (item.SO_LUONG <= 0 || item.GIA_NT2 <= 0) {
          openNotificationWithIcon(
            "error",
            `Vui lòng nhập số lượng hoặc đơn giá vật tư ${item.TEN_VT}`
          );
          check = false;
        }
      });
      return check;
    }
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    newEdit: state.SO2Reducer.newEdit,
    customer: state.SO2Reducer.customer,
    listItem: state.ItemReducer.listItem,
    ctPackingSlip: state.SO2Reducer.ctPackingSlip,
    phPackingSlip: state.SO2Reducer.phPackingSlip,
    checkDelete: state.SO2Reducer.checkDelete,
    lstAddressChooseFromSO: state.SO2Reducer.lstAddressChooseFromSO,
    contactPackingSlip: state.SO2Reducer.contactPackingSlip,
    dsCtDriver: state.SO2Reducer.dsCtDriver,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    updatePackingSlip: (query) => {
      dispatch(actions.updatePackingSlip(query));
    },

    getCustomer: (query) => {
      dispatch(actions.getCustomer(query));
    },
    getCTPackingSlip: (detail) => {
      dispatch(actions.getCTPackingSlip(detail));
    },
    getListNeed: (query) => {
      dispatch(actionsDriver.getListDriver({ ma_cty: query.ma_cty }));
      dispatch(actionsVehicle.getListVehicle({ ma_cty: query.ma_cty }));
      dispatch(actions.getContactPackingSlip(query));
      dispatch(actions.getCtDriver(query));
    },
    deleteAPackingSlip: (master) => {
      dispatch(actions.deleteAPackingSlip(master));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
    clearSTT: () => {
      dispatch(actions.clearSTT());
    },
    getPHPackingSlip: (detail) => {
      dispatch(actions.getPHPackingSlip(detail));
    },
    lookupSelectedItem: (query) => {
      dispatch(actionsSellPrice.getLookupSelectedItem(query));
    },
    lookupSelectedItem2: (query) => {
      dispatch(actionsSellPrice.getLookupSelectedItem2(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SO2Edit);
