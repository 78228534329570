import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form } from "antd";
import { DHSInput, DHSSelectOpt } from "../DHSForm/DHSFormItem";
import { toUpperPropertyNameByArray } from "../../../components/controller/Format";
import "./index.css";
import PropTypes from "prop-types";

export default function InfoReportsBar(props) {
  const { listRp, objFilter, setObjFilter } = props;
  const listCompany = useSelector((state) => state.LoginReducer.listCompany);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  useEffect(() => {
    if (!_.isUndefined(listRp)) {
      setObjFilter({
        ...objFilter,
        LOAI_BC: listRp[0].MA_MAU,
        TITLE: listRp[0].TITLE,
        SPNAME: listRp[0].SPNAME,
      });
    }
  }, [listRp]);
  const handleChangeRptType = (value) => {
    let obj = listRp.find((x) => x.MA_MAU === value);
    setObjFilter({
      ...objFilter,
      LOAI_BC: value,
      TITLE: !_.isUndefined(obj) ? obj.TITLE : "",
      SPNAME: !_.isUndefined(obj) ? obj.SPNAME : "",
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  return (
    <div className="infoReportsBar">
      <Form layout="vertical" className="Filter">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8} xs={24} xl={24} xxl={8}>
            <DHSSelectOpt
              label="Công ty"
              name="MA_CTY"
              array={toUpperPropertyNameByArray(listCompany)}
              valueOpt={"MA_CTY"}
              valueRender={"TEN_CTY"}
              onChange={(value) => handleChangeText({ value, name: "MA_CTY" })}
              defaultValue={ma_cty}
            />
          </Col>
          <Col span={8} xs={24} xl={24} xxl={8}>
            <DHSSelectOpt
              label="Mẫu báo cáo"
              name="LOAI_BC"
              array={toUpperPropertyNameByArray(listRp)}
              valueOpt={"MA_MAU"}
              valueRender={"TEN_MAU"}
              onChange={(value) => handleChangeRptType(value)}
              defaultValue={objFilter.LOAI_BC}
            />
          </Col>
          <Col span={8} xs={24} xl={24} xxl={8}>
            <DHSInput
              label="Tiêu đề"
              name="TITLE"
              value={objFilter.TITLE}
              getValueChange={handleChangeText}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}
