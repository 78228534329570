import General from "./general/general";
import ListItem from "./listItem/listItem";
import { useSelector, useDispatch } from "react-redux";
// import * as actions from "../../../../../redux/actions/Sales/quotation/quotation";
import { Drawer, Divider, Tabs } from "antd";
import "./index.css";
const { TabPane } = Tabs;

export default function po1Detail(props) {
  const { isVisible, checkVisible, Master } = props;
  const onClose = () => {
    checkVisible(false);
  };
  return (
    <Drawer
      title={`Chi tiết đơn hàng mua - ${Master.SO_CT}`}
      visible={isVisible}
      width={"50%"}
      onClose={onClose}
    >
      <div className="po1-detail">
        <General Master={Master} />
        <Divider style={{ margin: 0 }} />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Thông tin hàng hóa" key="1">
            <ListItem Master={Master} />
          </TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
}
