import * as types from "../../../constants/ActionTypes";

var initialState = {
  listRptMachine: [],
  TotalRptMachine: [],
  machineTimeLine: [],
  machineWorkShift: [],
  containDataRptMachine: {
    time_to: "",
    time_from: "",
    machineID: "",
    machine_name: "",
  },
  groupMachine: [],
};

var MachineRptReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RPT_MACHINE_UPTIME: {
      return {
        ...state,
        listRptMachine: action.payload,
      };
    }
    case types.GET_RPT_MACHINE_UPTIME_TOTALALL: {
      return {
        ...state,
        TotalRptMachine: action.payload,
      };
    }
    case types.GET_RPT_MACHINE_TIMELINE: {
      return {
        ...state,
        machineTimeLine: action.payload,
      };
    }
    case types.GET_RPT_MACHINE_WORK_SHIFT: {
      return {
        ...state,
        machineWorkShift: action.payload,
      };
    }
    case types.CONTAIN_RPT_MACHINE: {
      return {
        ...state,
        containDataRptMachine: {
          time_to: action.payload.time_to,
          time_from: action.payload.time_from,
          machineID: action.payload.machineID,
          machine_name: action.payload.machine_name,
        },
      };
    }
    case types.GET_GROUP_MACHINE: {
      return {
        ...state,
        groupMachine: action.payload,
      };
    }
    default:
      return state;
  }
};

export default MachineRptReducer;
