import * as types from "../../constants/ActionTypes";
import callApi from "../../../routes/config/configApi";

export const getListCompany = () => (dispatch, getState) => {
  callApi("api/Login/ListCompany", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_COMPANY,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const Login = (user) => (dispatch, getState) => {
  callApi("api/Login/Login", "POST", user).then((res) =>
    dispatch({
      type: types.LOGIN,
      payload: res.data,
    })
  );
};
export const LoginPOS = (user) => (dispatch, getState) => {
  callApi("api/LoginPOS/Login", "POST", user).then((res) =>
    dispatch({
      type: types.LOGIN_POS,
      payload: res.data,
    })
  );
};
export const sessionLogin = (userName, ma_cty, companyName) => {
  return {
    type: types.SESSION_LOGIN,
    userName,
    ma_cty,
    companyName,
  };
};
export const clearCache = () => {
  return {
    type: types.CLEAR_LOGIN,
  };
};
//LOGOUT
export const destroySession = () => {
  return {
    type: types.DESTROY_SESSION,
  };
};

//HOME
export const getCategory = (userName) => (dispatch, getState) => {
  callApi("api/Home/DanhMucWeb", "POST", userName)
    .then((res) =>
      dispatch({
        type: types.GET_CATEGORY,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
