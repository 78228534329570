import React, { Component } from "react";
import { Drawer } from "antd";
import { Divider, Spin, Row, Col, Tabs } from "antd";
import General from "./General/general";
import ListItem from "./ListItem/listItem";
import Delivery from "./Delivery/delivery";
import Driver from "./Driver/driver";
import "./index.css";
import * as actions from "../../../../../redux/actions/Sales/packingSlip/index";
import { connect } from "react-redux";
import Format from "../../../../controller/Format";
import { widthDrawer } from "../../index";
const { TabPane } = Tabs;
class SO2Detail extends Component {
  state = {
    loading: false,
  };
  componentDidMount = () => {
    const { Master } = this.props;
    this.props.getCustomer({
      ma_cty: this.props.ma_cty,
      idCustomer: Master.IDCUSTOMER,
    });
    this.props.getListNeed({
      stt_rec: Master.STT_REC,
      ma_cty: this.props.ma_cty,
    });
  };
  onClose = () => {
    this.props.checkVisible(false);
  };
  componentWillUnmount = () => {
    this.props.clearCache();
  };
  render() {
    const { loading } = this.state;
    const {
      isVisible,
      Master,
      customer,
      ctPackingSlip,
      contactPackingSlip,
      dsCtDriver,
    } = this.props;
    return (
      <Drawer
        title={`Chi tiết phiếu xuất - ${Master.SO_CT}`}
        visible={isVisible}
        width={widthDrawer}
        onClose={this.onClose}
      >
        <div className="so2-detail">
          <Spin spinning={loading}>
            <General Master={Master} customer={customer} />
            <Divider style={{ margin: 0 }} />{" "}
            <Tabs defaultActiveKey="1">
              <TabPane tab="Thông tin hàng hóa" key="1">
                <ListItem
                  dsCT={ctPackingSlip}
                  amount={Master.T_TT}
                  ma_nt={Master.MA_NT}
                  Master={Master}
                />
              </TabPane>
              <TabPane tab="Thông tin tài xế" key="2">
                <Driver driver={dsCtDriver} />
              </TabPane>
              <TabPane tab="Thông tin giao hàng" key="3">
                <Delivery
                  delivery={contactPackingSlip}
                  dataSource={ctPackingSlip}
                />
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      </Drawer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    newEdit: state.SO2Reducer.newEdit,
    customer: state.SO2Reducer.customer,
    ctPackingSlip: state.SO2Reducer.ctPackingSlip,
    contactPackingSlip: state.SO2Reducer.contactPackingSlip,
    dsCtDriver: state.SO2Reducer.dsCtDriver,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getCustomer: (query) => {
      dispatch(actions.getCustomer(query));
    },
    getListNeed: (query) => {
      dispatch(actions.getContactPackingSlip(query));
      dispatch(actions.getCTPackingSlip(query));
      dispatch(actions.getCtDriver(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SO2Detail);
