import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSInputTextArea,
  DHSCheckBox,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { ReloadOutlined } from "@ant-design/icons";
import * as actions from "../../../../../redux/actions/CashAndBank/BankReceipt/BankReceipt";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import PropTypes from "prop-types";
import moment from "moment";
import { toUpperPropertyName } from "../../../../controller/Format";
import { widthDrawer } from "../../index";
const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};

CA3Create.propTypes = {
  _objectCreate: PropTypes.object,
};

CA3Create.defaultProps = {
  _objectCreate: {
    IDCUSTOMER: "",
    MA_CTY: "",
    LUSER: "",
    CUSER: "",
    DIA_CHI: "",
    EMAIL: "",
    TEL: "",
    MA_KH: "",
    NGUOI_GD: "",
    SO_CT: "",
    NGAY_LCT: moment(),
    NGAY_CT: moment(),
    MA_NT: "VND",
    TY_GIA: 1,
    DIEN_GIAI: "",
    POST2IN: false,
    POST2GL: false,
    PN_GTB: false,
    MA_GD: "1",
    MA_NV: "",
    SO_CT_KEM: "",
  },
};

export default function CA3Create(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, _objectCreate, history } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const CA3State = useSelector((state) => state.CA3Reducer);
  const [isLoading, setLoading] = useState(false);
  const [objCreate, setObjCreate] = useState(_objectCreate);

  useEffect(() => {
    dispatch(actions.getListsDm({ ma_cty }));
    dispatch(actions.getSoCt({ ma_cty }));
  }, []);

  useEffect(() => {
    setObjCreate({
      ...objCreate,
      TY_GIA: CA3State.exchangeRate == 0 ? 1 : CA3State.exchangeRate,
    });
  }, [CA3State.exchangeRate]);

  useEffect(() => {
    setLoading(false);
    if (CA3State.newItem === "-1") {
      openNotificationWithIcon("error", "Trùng số chứng từ !");
      dispatch(actions.clearSTT());
    } else if (_.isObject(CA3State.newItem)) {
      openNotificationWithIcon("success", "Thêm báo có thành công !");
      checkVisible(false);
      toUpperPropertyName(CA3State.newItem);
      history.push("/CA3Edit", {
        Master: { ...CA3State.newItem, TEN_KH: objCreate.TEN_KH },
      });
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [CA3State.newItem]);

  const handleCancel = () => {
    dispatch(actions.clearCache());
    checkVisible(false);
  };

  const handleOk = () => {
    const BankReceipt = {
      modelM: {
        ...objCreate,
        SO_CT: CA3State.soCt,
        MA_CTY: ma_cty,
        LUSER: userName,
        CUSER: userName,
      },
      arrayCt: [],
    };
    setLoading(true);
    dispatch(actions.addNewBankReceipt(BankReceipt));
  };

  const handleChangeText = (params) => {
    setObjCreate({ ...objCreate, [params.name]: params.value });
  };

  const handleKHChange = (params) => {
    let customer = CA3State.listCustomer.find((x) => x.IDCUSTOMER === params);
    setObjCreate({
      ...objCreate,
      DIA_CHI: customer.DIA_CHI,
      IDCUSTOMER: params,
      EMAIL: customer.EMAIL,
      TEL: customer.TEL,
      MA_KH: customer.MA_KH,
      TEN_KH: customer.TEN_KH,
    });
  };

  const handleChangeCurrency = (params) => {
    setObjCreate({ ...objCreate, MA_NT: params });
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: objCreate.NGAY_LCT,
      })
    );
  };
  const reloadSoCt = () => {
    dispatch(actions.getSoCt({ ma_cty }));
  };
  return (
    <Drawer
      title="Thêm mới báo có"
      width={widthDrawer}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8 }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Collapse
          defaultActiveKey={["1", "2"]}
          ghost
          className="ca3-create"
          expandIconPosition="right"
        >
          <Panel header="Thông tin khách hàng" key="1">
            <Form
              id="submit-form"
              onFinish={handleOk}
              layout="vertical"
              validateMessages={validateMessages}
            >
              <DHSSelectOpt
                label="Loại thu"
                array={CA3State.sysExchange}
                valueOpt={"MA_GD"}
                valueRender={"MO_TA"}
                defaultValue={objCreate.MA_GD}
                onChange={(value) => handleChangeText({ name: "MA_GD", value })}
              />
              <DHSSelectOpt
                label="Khách hàng"
                name="IDCUSTOMER"
                array={CA3State.listCustomer}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                rules={true}
                defaultValue={
                  objCreate.IDCUSTOMER === "" ? undefined : objCreate.IDCUSTOMER
                }
                onChange={handleKHChange}
              />
              <DHSInput
                label="Địa chỉ"
                name="DIA_CHI"
                value={objCreate.DIA_CHI}
                getValueChange={handleChangeText}
              />
              <DHSInput
                label="Email"
                name="EMAIL"
                value={objCreate.EMAIL}
                getValueChange={handleChangeText}
              />
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSInput
                    label="Người nộp tiền"
                    name="NGUOI_GD"
                    value={objCreate.NGUOI_GD}
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số điện thoại"
                    name="TEL"
                    value={objCreate.TEL}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Nhân viên BH"
                    array={CA3State.listNV}
                    valueOpt={"IDCUSTOMER"}
                    valueRender={"TEN_KH"}
                    defaultValue={objCreate.MA_NV}
                    onChange={(value) =>
                      handleChangeText({ name: "MA_NV", value })
                    }
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số chứng từ kèm theo"
                    name="SO_CT_KEM"
                    value={objCreate.SO_CT_KEM}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
            </Form>
          </Panel>
          <Panel header="Thông tin báo có" key="2">
            <Form layout="vertical">
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSInput
                    label="Số báo có"
                    name="SO_CT"
                    disable={true}
                    value={CA3State.soCt}
                    addonAfter={<ReloadOutlined onClick={reloadSoCt} />}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày chứng từ"
                    value={objCreate.NGAY_CT}
                    nameState="NGAY_CT"
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày lập"
                    value={objCreate.NGAY_LCT}
                    nameState="NGAY_LCT"
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Loại tiền tệ"
                    array={CA3State.listCurrency}
                    valueOpt={"MA_NT"}
                    valueRender={"MA_NT"}
                    defaultValue={objCreate.MA_NT}
                    onChange={handleChangeCurrency}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Tỷ giá"
                    name="TY_GIA"
                    disable={objCreate.MA_NT === "VND" ? true : false}
                    value={objCreate.TY_GIA}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <DHSInputTextArea
                label="Diễn giải"
                name="DIEN_GIAI"
                value={objCreate.DIEN_GIAI}
                getValueChange={handleChangeText}
              />
            </Form>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
}
