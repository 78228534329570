import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListSellPrice = (ma_cty) => (dispatch, getState) => {
  callApi("api/SellPriceBase/ListSellPrice", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_SELL_PRICE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewSellPrice = (quotation) => (dispatch, getState) => {
  callApi("api/SellPriceBase/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_SELL_PRICE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getLists = (ma_cty) => (dispatch, getState) => {
  callApi("api/SellPriceBase/Lists", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_SELL_PRICE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const deleteSellPrice = (master) => (dispatch, getState) => {
  callApi("api/SellPriceBase/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_SELL_PRICE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateSellPrice = (so1) => (dispatch, getState) => {
  callApi("api/SellPriceBase/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_SELL_PRICE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getLookupSelectedItem = (query) => (dispatch, getState) => {
  callApi("api/SellPriceBase/LookupSelectedItem", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_LOOKUP_SELECTED_ITEM,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getLookupSelectedItem2 = (query) => (dispatch, getState) => {
  callApi("api/SellPriceBase/LookupSelectedItem2", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_LOOKUP_SELECTED_ITEM2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_SELL_PRICE,
  };
};
