import React, { Component } from "react";
import { Collapse, Row, Col, Form } from "antd";
import { FormatDate } from "../../../../../controller/Format";
import { connect } from "react-redux";
import {
  DHSSelectOpt,
  DHSText,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";

const { Panel } = Collapse;
class General extends Component {
  render() {
    const { Master, customer, listStatus } = this.props;
    return (
      <div className="so1-detail-general">
        <Collapse defaultActiveKey={["1"]} ghost expandIconPosition="right">
          <Panel header="Thông tin chung" key="1">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSText label="Tên khách hàng" value={customer.ten_kh} />
                  <DHSText label="Email" value={customer.email} />
                  <DHSText label="Số điện thoại" value={customer.tel} />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSText label="Người liên hệ" value={Master.NGUOI_GD} />
                  <DHSText label="Địa chỉ" value={customer.dia_chi} />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSText
                    label="Ngày lập"
                    value={FormatDate(Master.NGAY_CT)}
                  />
                  <DHSSelectOpt
                    label="Trạng thái"
                    array={listStatus}
                    valueOpt={"trang_thai"}
                    valueRender={"mo_ta"}
                    defaultValue={Master.TRANG_THAI}
                    disable={true}
                  />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSText label="Loại tiền tệ" value={Master.MA_NT} />
                  <DHSText label="Tỷ giá" value={Master.TY_GIA} />
                  <DHSText label="Ghi chú" value={Master.DIEN_GIAI} />
                </Form>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listStatus: state.SO1Reducer.listStatus,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
