import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import INReports from "../INReports";
import Filter from "./Filter/Filter";

export default function MovementIssueStatement(props) {
  const { history } = props;
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [objFilter, setObjFilter] = useState({
    TITLE: "",
    SPNAME: "",
  });
  const checkVisible = () => {
    setOpenDrawerFilter(false);
  };
  return (
    <div>
      <INReports
        rpName="mau_rp_mis"
        title="Tổng hợp hàng xuất"
        history={history}
        setOpenDrawerFilter={setOpenDrawerFilter}
        setObjFilter={setObjFilter}
        objFilter={objFilter}
        dataKey="ma_vt"
      />
      {openDrawerFilter ? (
        <Filter
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
          dataRpt={objFilter}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
