import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { GetRptRevenueByItem } from "../../../../../../redux/actions/Sales/reports/reports";
import { periodData } from "../../../../../controller/DataSample";
import { onPeriodChange } from "../../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

Filter.defaultProps = {
  _objectFilter: {
    MA_CTY: "",
    SITE_ID: "",
    IDITEM: "",
    MA_KHO: "",
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    FROMDATE: moment().startOf("month"),
    TODATE: moment().endOf("month"),
  },
};
export default function Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter, approvalScr } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listDm = useSelector((state) => state.SOReportsReducer.listDm);
  const [objFilter, setObjFilter] = useState(_objectFilter);
  const listCompany = useSelector((state) => state.LoginReducer.listCompany);

  const handleCancel = (e) => {
    checkVisible({
      visibleChange: false,
      FROMDATE: objFilter.FROMDATE,
      TODATE: objFilter.TODATE,
    });
  };
  const handleOk = () => {
    dispatch(
      GetRptRevenueByItem({
        MA_CTY: ma_cty,
        NGAY_CT1: moment(objFilter.FROMDATE).add(7, "hours"),
        NGAY_CT2: moment(objFilter.TODATE).add(7, "hours"),
        IDITEM: objFilter.IDITEM,
        SITE_ID: objFilter.SITE_ID,
        MA_KHO: objFilter.MA_KHO,
      })
    );
    handleCancel();
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FROMDATE: result.FROMDATE,
      TODATE: result.toDate,
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Điều kiện lọc"
        width={600}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Thực hiện
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="rpBk01-Filter"
        >
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FROMDATE}
                nameState="FROMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <DHSSelectOpt
                label="Vật tư"
                name="IDITEM"
                array={listDm.dm_vattu}
                valueOpt={"IDITEM"}
                valueRender={"TEN_VT"}
                onChange={(value) => handleChange(value, "IDITEM")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Chi nhánh"
                name="SITE_ID"
                array={listDm.dm_site}
                valueOpt={"SITE_ID"}
                valueRender={"SITE_NAME"}
                onChange={(value) => handleChange(value, "SITE_ID")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Mã kho"
                name="MA_KHO"
                array={listDm.dm_kho}
                valueOpt={"MA_KHO"}
                valueRender={"TEN_KHO"}
                onChange={(value) => handleChange(value, "MA_KHO")}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
