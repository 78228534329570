import React, { useState, useEffect } from "react";
import { Collapse, Row, Col, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  DHSInput,
  DHSText,
  DHSSelectOpt,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { FormatDate } from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/Sales/salesOrder/salesOrder";
import moment from "moment";
const { Panel } = Collapse;

export default function General(props) {
  const { Master, isEdit, getTyGia } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isRender, setRender] = useState(false);
  const SO1State = useSelector((state) => state.SO1Reducer);

  useEffect(() => {
    if (!_.isEmpty(SO1State.listCustomer)) {
      handleKHChange(Master.IDCUSTOMER);
    }
  }, [SO1State.listCustomer]);

  useEffect(() => {
    if (!_.isEqual(SO1State.exchangeRate, "")) {
      Master["TY_GIA"] = SO1State.exchangeRate == 0 ? 1 : SO1State.exchangeRate;
    }
    getTyGia(Master.TY_GIA);
    setRender(!isRender);
  }, [SO1State.exchangeRate]);

  const handleKHChange = (params) => {
    let customer = SO1State.listCustomer.find((x) => x.idcustomer === params);
    if (!_.isEmpty(customer)) {
      Master.DIA_CHI = customer.dia_chi;
      Master.IDCUSTOMER = params;
      Master.EMAIL = customer.email;
      Master.TEL = customer.tel;
      Master.MA_KH = customer.ma_kh;
    }
    setRender(!isRender);
  };

  const onChangeText = (params) => {
    Master[params.name] = params.value;
    if (params.name === "TY_GIA") {
      getTyGia(Master.TY_GIA);
    }
    setRender(!isRender);
  };

  const handleChangeCurrency = (params) => {
    Master["MA_NT"] = params;
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: moment(Master.NGAY_LCT),
      })
    );
    setRender(!isRender);
  };

  return (
    <div className="so1-general">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chung" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Tên khách hàng"
                  array={SO1State.listCustomer}
                  valueOpt={"idcustomer"}
                  valueRender={"ten_kh"}
                  defaultValue={Master.IDCUSTOMER}
                  onChange={handleKHChange}
                  disable={isEdit}
                />
                <DHSText label="Số điện thoại" value={Master.TEL} />
                <DHSText label="Email" value={Master.EMAIL} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSInput
                  label="Người liên hệ"
                  name="NGUOI_GD"
                  value={Master.NGUOI_GD}
                  getValueChange={onChangeText}
                  disable={isEdit}
                />
                <DHSText label="Địa chỉ" value={Master.DIA_CHI} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Ngày lập" value={FormatDate(Master.NGAY_CT)} />
                <DHSSelectOpt
                  label="Trạng thái"
                  array={SO1State.listStatus}
                  valueOpt={"trang_thai"}
                  valueRender={"mo_ta"}
                  defaultValue={Master.TRANG_THAI}
                  disable={true}
                />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Loại tiền tệ"
                  array={SO1State.listCurrency}
                  valueOpt={"ma_nt"}
                  valueRender={"ma_nt"}
                  defaultValue={Master.MA_NT}
                  onChange={handleChangeCurrency}
                  disable={isEdit}
                />
                <DHSInput
                  label="Tỷ giá"
                  name="TY_GIA"
                  value={Master.TY_GIA}
                  disable={isEdit}
                  disable={Master.MA_NT === "VND" ? true : false}
                  getValueChange={onChangeText}
                />
                <DHSInput
                  label="Ghi chú"
                  name="GHI_CHU"
                  value={Master.GHI_CHU}
                  disable={isEdit}
                  getValueChange={onChangeText}
                />
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
}

// import React, { Component } from "react";
// import { Collapse, Row, Col, Form, Spin } from "antd";
// import { FormatDate } from "../../../../../controller/Format";
// import { connect } from "react-redux";
// import {
//   DHSInput,
//   DHSSelectOpt,
//   DHSText,
// } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
// import * as actions from "../../../../../../redux/actions/Sales/salesOrder/salesOrder";
// import _ from "lodash";
// const { Panel } = Collapse;

// class General extends Component {
//   state = {
//     IDcustomer: "",
//     dia_chi: "",
//     email: "",
//     tel: "",
//     ma_kh: "",
//     contact: "",
//     ty_gia: "",
//     ma_nt: "",
//     ghi_chu: "",
//     loadingExchange: false,
//   };
//   refreshState = () => {
//     this.setState({
//       IDcustomer: this.props.customer.IDCustomer,
//       dia_chi: this.props.customer.dia_chi,
//       email: this.props.customer.email,
//       tel: this.props.customer.tel,
//       ma_kh: this.props.customer.ma_kh,
//       contact: this.props.Master.NGUOI_GD,
//       ty_gia: this.props.Master.TY_GIA,
//       ma_nt: this.props.Master.MA_NT,
//       ghi_chu: this.props.Master.DIEN_GIAI,
//     });
//   };
//   componentDidMount = () => {
//     this.props.getListCustomer({ ma_cty: this.props.ma_cty });
//     this.props.getListCurrency();
//   };
//   componentDidUpdate = (prevProps, prevState) => {
//     if (!_.isEqual(this.state, prevState)) {
//       this.props.getMaster(this.state);
//     }
//     if (!_.isEqual(this.props.customer, prevProps.customer)) {
//       this.refreshState();
//     }
//   };
//   componentWillUnmount = () => {
//     this.props.clearCache();
//   };

//   render() {
//     const {
//       Master,
//       listCustomer,
//       listCurrency,
//       customer,
//       editDone,
//       listStatus,
//     } = this.props;
//     const {
//       IDcustomer,
//       dia_chi,
//       tel,
//       contact,
//       loadingExchange,
//       ty_gia,
//       ma_nt,
//       ghi_chu,
//       email,
//     } = this.state;
//     return (
//       <div className="so1-general">
//         <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
//           <Panel header="Thông tin chung" key="1">
//             <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
//               <Col className="gutter-row" span={6} xs={12} xl={6}>
//                 <Form layout="vertical">
//                   <DHSSelectOpt
//                     label="Tên khách hàng"
//                     array={listCustomer}
//                     valueOpt={"idcustomer"}
//                     valueRender={"ten_kh"}
//                     defaultValue={IDcustomer}
//                     onChange={this.handleChange}
//                     disable={editDone}
//                   />
//                   <DHSText label="Số điện thoại" value={tel} />
//                   <DHSText label="Email" value={email} />
//                 </Form>
//               </Col>
//               <Col className="gutter-row" span={6} xs={12} xl={6}>
//                 <Form layout="vertical">
//                   <DHSInput
//                     label="Người liên hệ"
//                     name="contact"
//                     value={contact}
//                     getValueChange={this.onChangeText}
//                     disable={editDone}
//                   />
//                   <DHSText label="Địa chỉ" value={dia_chi} />
//                 </Form>
//               </Col>
//               <Col className="gutter-row" span={6} xs={12} xl={6}>
//                 <Form layout="vertical">
//                   <DHSText
//                     label="Ngày lập"
//                     value={FormatDate(Master.NGAY_CT)}
//                   />
//                   <DHSSelectOpt
//                     label="Trạng thái"
//                     array={listStatus}
//                     valueOpt={"trang_thai"}
//                     valueRender={"mo_ta"}
//                     defaultValue={Master.TRANG_THAI}
//                     disable={true}
//                   />
//                 </Form>
//               </Col>
//               <Col className="gutter-row" span={6} xs={12} xl={6}>
//                 <Form layout="vertical">
//                   <DHSSelectOpt
//                     label="Loại tiền tệ"
//                     array={listCurrency}
//                     valueOpt={"ma_nt"}
//                     valueRender={"ma_nt"}
//                     defaultValue={ma_nt}
//                     onChange={this.handleChangeCurrency}
//                     disable={editDone}
//                   />
//                   <Spin spinning={loadingExchange}>
//                     <DHSInput
//                       label="Tỷ giá"
//                       name="ty_gia"
//                       value={ty_gia}
//                       disable={editDone}
//                       disable={ma_nt === "VND" ? true : false}
//                       getValueChange={this.onChangeText}
//                     />
//                   </Spin>
//                   <DHSInput
//                     label="Ghi chú"
//                     name="ghi_chu"
//                     value={ghi_chu}
//                     disable={editDone}
//                     getValueChange={this.onChangeText}
//                   />
//                 </Form>
//               </Col>
//             </Row>
//           </Panel>
//         </Collapse>
//       </div>
//     );
//   }
//   handleChangeCurrency = async (value) => {
//     this.setState({ ma_nt: value, loadingExchange: true });
//     this.props.getExchangeRate({
//       ma_cty: this.props.ma_cty,
//       ma_nt: value,
//       ngay_ct: this.props.Master.NGAY_CT,
//     });
//     setTimeout(async () => {
//       value === "VND"
//         ? await this.setState({ ty_gia: 1, loadingExchange: false })
//         : await this.setState({
//             ty_gia: this.props.exchangeRate == 0 ? 1 : this.props.exchangeRate,
//             loadingExchange: false,
//           });
//     }, 500);
//   };
//   onChangeText = async (params) => {
//     await this.setState({ [params.name]: params.value });
//   };
//   handleChange = (value) => {
//     this.props.listCustomer.filter(async (item) => {
//       if (item.idcustomer === value) {
//         await this.setState({
//           IDcustomer: value,
//           dia_chi: item.dia_chi,
//           email: item.email,
//           tel: item.tel,
//           ma_kh: item.ma_kh,
//         });
//       }
//     });
//   };
// }
// const mapStateToProps = (state) => {
//   return {
//     ma_cty: state.LoginReducer.ma_cty,
//     userName: state.LoginReducer.userName,
//     listCustomer: state.SO1Reducer.listCustomer,
//     customer: state.SO1Reducer.customer,
//     listCurrency: state.SO1Reducer.listCurrency,
//     exchangeRate: state.SO1Reducer.exchangeRate,
//     listStatus: state.SO1Reducer.listStatus,
//   };
// };

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     getCustomer: (query) => {
//       dispatch(actions.getCustomer(query));
//     },
//     getListCustomer: (ma_cty) => {
//       dispatch(actions.getListCustomer(ma_cty));
//     },
//     getListCurrency: () => {
//       dispatch(actions.getListCurrency());
//     },
//     getExchangeRate: (ty_gia) => {
//       dispatch(actions.getExchangeRate(ty_gia));
//     },
//     clearCache: () => {
//       dispatch(actions.clearCache());
//     },
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(General);
