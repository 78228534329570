import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, PageHeader } from "antd";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import Ds1Create from "./ds1Create/ds1Create";
import Ds1Detail from "./ds1Detail/ds1Detail";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import * as actions from "../../../../redux/actions/Sales/soDiscount/soDiscount";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import _ from "lodash";
const objectColumn = {
  SO_CT: { name: "Số chiết khấu", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  TEN_CTCK: { name: "Tên chương trình", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  NGAY_HL: { name: "Ngày hiệu lực", width: 100, formatDate: true },
  NGAY_HH: { name: "Ngày hết hạn", width: 100, formatDate: true },
  DIEN_GIAI: { name: "Diễn giải", width: "10%" },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function SoDiscount(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const [isLoading, setLoading] = useState(false);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const DS1State = useSelector((state) => state.DS1Reducer);
  const [customColumns, setCustomColumns] = useState();
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListDiscount({ ma_cty }));
    dispatch(actions.getLists({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    if (DS1State.checkDelete) {
      dispatch(actions.getListDiscount({ ma_cty }));
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [DS1State.checkDelete]);

  useEffect(() => {
    setData(DS1State.listDiscount);
    setFullData(DS1State.listDiscount);
    setLoading(false);
  }, [DS1State.listDiscount]);

  const handleOpenDrawer = () => {
    dispatch(actions.clearCache());
    setIsOpenCreate(true);
  };

  const handleDelete = (params) => {
    dispatch(actions.deleteDiscount(params));
    setLoading(true);
  };

  const checkVisible = (params) => {
    setIsOpenCreate(params);
    setIsOpenDetail(params);
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const handleEdit = (params) => {
    dispatch(actions.getCTDiscount(params));
    history.push("/DiscountSoEdit", {
      Master: params,
    });
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Chiết khấu"
          onBack={() => history.push("/Sale")}
        />
        <ToolBar
          {...props}
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          Master={isMaster}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListDiscount({ ma_cty }));
          }}
          // openFiler={() => {
          //   this.setState({ openDrawerFilter: true });
          // }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setIsOpenDetail(true);
          }}
        />
        {isOpenCreate ? (
          <Ds1Create
            isVisible={isOpenCreate}
            checkVisible={checkVisible}
            history={history}
          />
        ) : (
          <></>
        )}
        {isOpenDetail ? (
          <Ds1Detail
            isVisible={isOpenDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
