import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import IN1Create from "./IN1Create/IN1Create";
import IN1Detail from "./IN1Detail/IN1Detail";
import IN1Filter from "./IN1Filter/IN1Filter";
import ModalSelectRp from "./modalSelectRp/modalSelectRp";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actions from "../../../../redux/actions/Inventory/MovementReceipt/movementReceipt";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import "./index.css";

const objectColumn = {
  TEN_GD: { name: "Loại nhập kho", width: 100 },
  NGAY_CT: { name: "Ngày chứng từ", width: 100, formatDate: true },
  SO_CT: { name: "Số chứng từ", width: 100 },
  TEN_NGUOI_GD: { name: "Người GD", width: 100 },
  DIEN_GIAI: { name: "Diễn giải", width: 100 },
  DIA_CHI: { name: "Địa chỉ", width: 150 },
  MA_KH: { name: "Mã khách hàng", width: 70 },
  TEN_KH: { name: "Tên khách hàng", width: 120 },
  T_TIEN: { name: "Thành tiền", width: 80, formatMoney: true },
  MA_NT: { name: "Mã ngoại tệ", width: 50 },
  GIA_THANH: { name: "Tính giá thành", width: 50, checkBox: true },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function MovementReceipt(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const IN1State = useSelector((state) => state.IN1Reducer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isLoading, setLoading] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false);
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListMovementReceipt({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(IN1State.listMovementReceipt);
    setData(IN1State.listMovementReceipt);
    setFullData(IN1State.listMovementReceipt);
    setLoading(false);
  }, [IN1State.listMovementReceipt]);

  useEffect(() => {
    if (IN1State.checkDelete) {
      dispatch(actions.getListMovementReceipt({ ma_cty }));
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [IN1State.checkDelete]);

  const handleOpenDrawer = () => {
    setOpenDrawerCreate(true);
  };

  const handleDelete = (params) => {
    dispatch(actions.deleteAMovementReceipt({ modelM: params }));
    setLoading(true);
  };

  const handleEdit = () => {
    if (!_.isEmpty(isMaster)) {
      dispatch(actions.getCTMovementReceipt(isMaster));
      history.push("/IN1Edit", {
        Master: isMaster,
      });
    }
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const checkVisible = (params) => {
    setOpenDrawerCreate(params);
    setOpenDrawerDetail(params);
    setOpenDrawerFilter(params);
    setOpenModalPrint(params);
  };

  const handlePrint = (params) => {
    dispatch(actions.getCTMovementReceipt(params));
    setOpenModalPrint(true);
  };
  return (
    <div className="movementReceipt" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Phiếu nhập kho"
          onBack={() => history.push("/Inventory")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          setPrint={handlePrint}
          Master={isMaster}
        />
        <ModalSelectRp
          isModalVisible={openModalPrint}
          checkVisible={checkVisible}
          Master={isMaster}
          ctData={toUpperPropertyNameByArray(
            useSelector((state) => state.IN1Reducer.ctMovementReceipt)
          )}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListMovementReceipt({ ma_cty }));
          }}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
        />
      </Spin>
      {openDrawerCreate ? (
        <IN1Create
          isVisible={openDrawerCreate}
          checkVisible={checkVisible}
          history={history}
        />
      ) : (
        <></>
      )}
      {openDrawerDetail ? (
        <IN1Detail
          isVisible={openDrawerDetail}
          checkVisible={checkVisible}
          Master={isMaster}
        />
      ) : (
        <></>
      )}
      {openDrawerFilter ? (
        <IN1Filter
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
          approvalScr={false}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
