import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
import So0Detail from "../so0Detail/so0Detail";
import * as actions from "../../../../../redux/actions/Sales/approvalQuotation/approvalQuotation";
import _ from "lodash";
import DHSHanleFilter from "../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import DHSTable from "../../../../../common/DHSControl/DHSTable/DHSTable";
import So0Filter from "../so0Filter/so0Filter";

const objectColumn = {
  SO_CT: { name: "Số báo giá", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  TEN_TRANG_THAI: { name: "Trạng thái", width: 100 },
  T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

function ApprovalQuotation(props) {
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [isLoading, setLoading] = useState(false);
  const [customColumns, setCustomColumns] = useState([]);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [listSttRec, setListSttRec] = useState([]);
  const [isMaster, setMaster] = useState({});
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const APPROVALSO0 = useSelector((state) => state.ApprovalQuotationsReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListApprovalQuotations({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(APPROVALSO0.listApprovalQuotations);
    setData(APPROVALSO0.listApprovalQuotations);
    setFullData(APPROVALSO0.listApprovalQuotations);
    setLoading(false);
  }, [APPROVALSO0.listApprovalQuotations]);

  useEffect(() => {
    if (APPROVALSO0.approvalQuotations || APPROVALSO0.rejectQuotations) {
      dispatch(actions.getListApprovalQuotations({ ma_cty }));
      setListSttRec([]);
      dispatch(actions.clearCache());
      setLoading(false);
    }
  }, [APPROVALSO0.approvalQuotations, APPROVALSO0.rejectQuotations]);

  const handleApproval = (params) => {
    setLoading(true);
    const query = {
      ma_cty: ma_cty,
      luser: userName,
      note3: "",
      arrayStt_rec: params,
    };
    dispatch(actions.approvalQuotations(query));
  };

  const handleReject = (params) => {
    setLoading(true);
    const query = {
      ma_cty: ma_cty,
      luser: userName,
      arrayStt_rec: params,
    };
    dispatch(actions.rejectQuotations(query));
  };

  const getDataFilter = (params) => {
    setData(params);
  };
  const multiSelection = async (params) => {
    if (!_.isEmpty(params)) {
      var listStt_rec = await params.map((item) =>
        !_.isUndefined(item) ? item.STT_REC : false
      );
      setListSttRec(listStt_rec);
    } else {
      setListSttRec([]);
    }
  };
  const getDataRow = (params) => {
    setMaster(params);
  };
  const checkVisible = (params) => {
    setOpenDrawerDetail(params);
    setOpenDrawerFilter(params);
  };
  return (
    <div className="approvalQuotation" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Duyệt báo giá"
          onBack={() => props.history.push("/Sale")}
        />
        <ToolBar
          setApproval={handleApproval}
          setReject={handleReject}
          Master={listSttRec}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListApprovalQuotations({ ma_cty }));
          }}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
          multiSelection={multiSelection}
        />
        {openDrawerDetail ? (
          <So0Detail
            approval={true}
            isVisible={openDrawerDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
        {openDrawerFilter ? (
          <So0Filter
            isVisible={openDrawerFilter}
            checkVisible={checkVisible}
            approvalScr={true}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}

ApprovalQuotation.propTypes = {};

export default ApprovalQuotation;
