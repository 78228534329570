// const Rp01 = require("./rp01/rp01");
// const Rp02 = require("./rp02/rp02");
import Rp01 from "./rp01/rp01";
import Rp02 from "./rp02/rp02";
import Rp03 from "./rp03/rp03";
const Template = {
  Rp01,
  Rp02,
  Rp03,
};
export { Template };
