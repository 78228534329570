import * as types from "../../../constants/ActionTypes";

var initialState = {
  listApprovalSalesOrder: [],
  approvalSalesOrders: false,
  rejectSalesOrders: false,
};

var ApprovalSalesOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_APPROVAL_SO1: {
      return {
        ...state,
        listApprovalSalesOrder: action.payload,
      };
    }
    case types.APPROVAL_SALESORDER: {
      return {
        ...state,
        approvalSalesOrders: action.payload,
      };
    }
    case types.REJECT_SALESORDER: {
      return {
        ...state,
        rejectSalesOrders: action.payload,
      };
    }
    case types.CLEAR_APPROVAL_SO1: {
      return {
        ...state,
        approvalSalesOrders: false,
        rejectSalesOrders: false,
      };
    }
    default:
      return state;
  }
};

export default ApprovalSalesOrdersReducer;
