import React, { useState, useEffect } from "react";
import { Input, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { DHSNumericInput } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
import { EnterOutlined } from "@ant-design/icons";

const styleNumber = {
  style: {
    background: "lightgray",
    textAlign: "center",
    fontSize: 35,
  },
};
const styleCalc = {
  style: {
    background: "#BFBFBF",
    textAlign: "center",
    fontSize: 35,
  },
};
const styleEqual = {
  style: {
    background: "#1890FF",
    textAlign: "center",
    fontSize: 35,
    color: "white",
  },
};

export default function Calculator(props) {
  const { setCountItem } = props;
  const [textCalc, setTextCalc] = useState("");
  const [calc, setCalc] = useState("");
  const [operation, setOperation] = useState("");
  // const CalcOperation = (value) => {
  //   switch (value) {
  //     case "-": {
  //       setTextCalc(parseInt(textCalc) - parseInt(calc));
  //       break;
  //     }
  //     case "+": {
  //       setTextCalc(parseInt(textCalc) + parseInt(calc));
  //       break;
  //     }
  //     case "*": {
  //       setTextCalc(parseInt(textCalc) * parseInt(calc));
  //       break;
  //     }
  //     case "/": {
  //       setTextCalc(parseInt(textCalc) / parseInt(calc));
  //       break;
  //     }
  //     default: {
  //       break;
  //     }
  //   }
  // };
  const changeCalc = (value) => {
    setCalc(value);
  };
  const clickCalc = (number) => {
    if (_.isEmpty(operation)) {
      setTextCalc("");
    }
    setCalc(calc + number);
  };
  // const clickOperation = (value) => {
  //   if (!_.isEmpty(operation)) {
  //     CalcOperation(operation);
  //   } else {
  //     if (!_.isEmpty(calc)) {
  //       setTextCalc(calc);
  //     }
  //   }
  //   setCalc("");
  //   setOperation(value);
  // };
  const Calc = () => {
    if (operation === "") {
      setCountItem(parseInt(calc));
    }
    // CalcOperation(operation);
    setOperation("");
    setCalc("");
  };

  return (
    <div>
      <Row>
        <Col span={24} style={{ textAlign: "right", marginBottom: 5 }}>
          <DHSNumericInput
            placeholder="Nhập số lượng"
            bordered={false}
            size="large"
            value={textCalc + operation + calc}
            onChange={changeCalc}
            style={{ textAlign: "right", fontSize: 30 }}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("7")}
              >
                7
              </li>
            </Col>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("8")}
              >
                8
              </li>
            </Col>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("9")}
              >
                9
              </li>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("4")}
              >
                4
              </li>
            </Col>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("5")}
              >
                5
              </li>
            </Col>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("6")}
              >
                6
              </li>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("1")}
              >
                1
              </li>
            </Col>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("2")}
              >
                2
              </li>
            </Col>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("3")}
              >
                3
              </li>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => {
                  setCalc("");
                  setOperation("");
                  setTextCalc("");
                }}
              >
                C
              </li>
            </Col>
            <Col span={8}>
              <li
                className="item r1c4"
                {...styleNumber}
                onClick={() => clickCalc("0")}
              >
                0
              </li>
            </Col>
            <Col span={8}>
              <li className="item r1c4" {...styleEqual} onClick={Calc}>
                <EnterOutlined
                  style={{ textAlign: "center", fontSize: 48, width: "100%" }}
                />
              </li>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
