import * as types from "../../../constants/ActionTypes";

var initialState = {
  listDriver: [],
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
};

var DriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_DRIVER: {
      return {
        ...state,
        listDriver: action.payload,
      };
    }
    case types.INSERT_DRIVER:
      return {
        ...state,
        checkInsert: action.payload,
      };
    case types.DELETE_DRIVER:
      return {
        ...state,
        checkDelete: action.payload,
      };
    case types.UPDATE_DRIVER:
      return {
        ...state,
        checkUpdate: action.payload,
      };
    case types.CLEAR_DRIVER: {
      return {
        ...state,
        checkUpdate: false,
        checkDelete: false,
        checkInsert: false,
        listDriver: [],
      };
    }
    default:
      return state;
  }
};

export default DriverReducer;
