import React, { PureComponent } from "react";
import _ from "lodash";
import {
  SearchOutlined,
  FilterFilled,
  ReloadOutlined,
} from "@ant-design/icons";
import { Input, Row, Col, Button } from "antd";
import "./index.css";
const styleBtn = {
  border: "none",
  position: "relative",
  top: -3,
  padding: 5,
  color: "#1890FF",
};
export default class DHSHanleFilter extends PureComponent {
  handleFilter = (e) => {
    const { dataFilter } = this.props;
    const formattedQuery = e.target.value;
    const data = _.filter(dataFilter, (item) => {
      for (var x in item) {
        if (!_.isNil(item[x]) && typeof item[x] !== "boolean") {
          if (
            item[x]
              .toString()
              .toLowerCase()
              .includes(formattedQuery.toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    });
    this.props.getDataFilter(data);
  };
  shouldComponentUpdate = (nextProps) => {
    return !_.isEqual(this.props.dataFilter, nextProps.dataFilter);
  };
  render() {
    const { reload, openFiler } = this.props;
    return (
      <div className="DHSHandleFilter">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={5} xs={24} md={12} xl={7}>
            <div style={{ width: "100%", marginBottom: 10 }}>
              <Input
                placeholder="Nhập thông tin cần tìm"
                style={{ height: 25 }}
                suffix={<SearchOutlined style={{ color: "#1890FF" }} />}
                onChange={this.handleFilter}
              />
            </div>
          </Col>
          <Col xs={24} md={12} xl={17} style={{ padding: 0 }}>
            {!_.isNil(openFiler) ? (
              <Button
                icon={<FilterFilled />}
                onClick={openFiler}
                style={styleBtn}
              >
                Lọc
              </Button>
            ) : (
              <></>
            )}
            {!_.isNil(reload) ? (
              <Button
                icon={<ReloadOutlined />}
                onClick={reload}
                style={styleBtn}
              >
                Tải lại trang
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
