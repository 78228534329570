import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Space, Tabs, Button } from "antd";
import * as actions from "../../../../../redux/actions/CustomerServices/crmCustomer/crmCustomer";
import * as actionsCsmRequest from "../../../../../redux/actions/CustomerServices/crmCustomerRequest/crmCustomerRequest";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import _ from "lodash";
import General from "./General/general";
import Contact from "./Contact/contact";
import Request from "./Request/request";
import {
  CheckOutlined,
  EditOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import "./index.css";
const { TabPane } = Tabs;
class CrmCustomerEdit extends Component {
  state = { loading: false, editDone: false };
  componentDidMount = () => {
    if (this.props.history.location.state) {
      const { Master } = this.props.history.location.state;
      this.props.getListRequestByIdcustomer({
        ma_cty: this.props.ma_cty,
        idcustomer: Master.IDCUSTOMER,
      });
    } else {
      this.props.history.push("/CrmCustomer");
    }
  };

  componentDidUpdate = (prevProps) => {
    const { checkUpdate } = this.props;
    if (checkUpdate !== prevProps.checkUpdate && checkUpdate) {
      openNotificationWithIcon("success", "Sửa khách hàng thành công !");
      this.setState({ loading: false, editDone: true });
      this.props.clearCache();
    }
  };
  componentWillUnmount = () => {
    if (!_.isNil(this.props.listRequestById)) {
      this.props.listRequestById.length = 0;
    }
  };
  Save = () => {
    const { Master } = this.props.history.location.state;
    this.setState({ loading: true });
    Master.MA_CTY = this.props.ma_cty;
    this.props.updateCrmCustomer(Master);
  };
  render() {
    const { loading, editDone } = this.state;
    const { userName, listRequestById, listDm } = this.props;
    const { Master } = this.props.history.location.state
      ? this.props.history.location.state
      : "";
    return (
      <div className="csr-edit">
        {Master ? (
          <div>
            <Spin spinning={loading}>
              <Space className="topMenu">
                <Button
                  icon={<CheckOutlined />}
                  onClick={this.Save}
                  style={{ display: editDone ? "none" : "block" }}
                >
                  Lưu
                </Button>
                <Button
                  style={{ display: editDone ? "block" : "none" }}
                  onClick={() => {
                    this.setState({ editDone: false });
                  }}
                  icon={<EditOutlined />}
                >
                  Sửa
                </Button>
                <Button
                  onClick={() => {
                    this.props.history.push("/CrmCustomer");
                  }}
                  icon={<RollbackOutlined />}
                >
                  Trở về
                </Button>
              </Space>
              <div style={{ paddingLeft: 15 }}>
                <h4 style={{ margin: 0, color: "#1890FF" }}>
                  THÔNG TIN KHÁCH HÀNG
                </h4>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
                    {Master.MA_KH} : {Master.TEN_KH}
                  </h2>
                </div>
              </div>
              <General
                Master={Master}
                userName={userName}
                listDm={listDm}
                editDone={editDone}
              />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Liên hệ" key="1">
                  <Contact
                    Master={Master}
                    userName={userName}
                    editDone={editDone}
                  />
                </TabPane>
                <TabPane tab="Yêu cầu" key="2">
                  <Request Master={Master} listRequestById={listRequestById} />
                </TabPane>
              </Tabs>
            </Spin>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listDm: state.CrmCustomerReducer.listDm,
    checkUpdate: state.CrmCustomerReducer.checkUpdate,
    listRequestById: state.CrmCustomerRequestReducer.listRequestById,
    listDm: state.CrmCustomerReducer.listDm,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListRequestByIdcustomer: (model) => {
      dispatch(actionsCsmRequest.getListRequestByIdcustomer(model));
    },
    updateCrmCustomer: (model) => {
      dispatch(actions.updateCrmCustomer(model));
    },
    getLists: (ma_cty) => {
      dispatch(actions.getLists(ma_cty));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CrmCustomerEdit);
