import React, { Component } from "react";
import moment from "moment";
import { Bar } from "react-chartjs-2";
export default class CompareBars extends Component {
  render() {
    const { width, height } = this.props;

    const dataBar = {
      labels: this.props.label,
      datasets: [
        {
          label: this.props.name,
          backgroundColor: "#2291C4",
          borderColor: "#2291C4",
          borderWidth: 1,
          hoverBackgroundColor: "lavender",
          hoverBorderColor: "lavender",
          data: this.props.data,
        },
        {
          label: this.props.name2,
          backgroundColor: "rgba(255,99,132,0.4)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(155,231,91,0.2)",
          hoverBorderColor: "rgba(155,231,91,0.2)",
          data: this.props.data2,
        },
      ],
    };
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              callback(value) {
                return Number(value).toLocaleString("en");
              },
            },
          },
        ],
      },
      maintainAspectRatio: false,
    };
    return (
      <div>
        <Bar data={dataBar} width={width} height={height} options={options} />
      </div>
    );
  }
}
