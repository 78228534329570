import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListDriver = (ma_cty) => (dispatch, getState) => {
  callApi("api/LstDriver/LstDriver", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_DRIVER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const insertDriver = (query) => (dispatch, getState) => {
  callApi("api/LstDriver/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.INSERT_DRIVER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const deleteDriver = (query) => (dispatch, getState) => {
  callApi("api/LstDriver/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_DRIVER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateDriver = (query) => (dispatch, getState) => {
  callApi("api/LstDriver/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_DRIVER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_DRIVER,
  };
};
