import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListVehicle = (ma_cty) => (dispatch, getState) => {
  callApi("api/Vehicle/ListVehicle", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_VEHICLE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const insertVehicle = (query) => (dispatch, getState) => {
  callApi("api/Vehicle/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.INSERT_VEHICLE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const deleteVehicle = (query) => (dispatch, getState) => {
  callApi("api/Vehicle/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_VEHICLE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateVehicle = (query) => (dispatch, getState) => {
  callApi("api/Vehicle/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_VEHICLE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_VEHICLE,
  };
};
