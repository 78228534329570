import * as types from "../../../constants/ActionTypes";
var initialState = {
  objCrmCustomer: {},
  listCrmCustomer: [],
  listDm: {},
  listRequestById: [],
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
  so_ct: "",
};

var CrmCustomerRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_NEW_CRMCUSTOMER_REQUEST: {
      return {
        ...state,
        checkInsert: action.payload,
      };
    }
    case types.GET_SO_CT_CSR: {
      return {
        ...state,
        so_ct: action.payload,
      };
    }
    case types.GET_LIST_REQUEST_BY_IDCUSTOMER: {
      return {
        ...state,
        listRequestById: action.payload,
      };
    }
    case types.DELETE_CUSTOMER_REQUEST: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.UPDATE_CRMCUSTOMER_REQUEST: {
      return {
        ...state,
        checkUpdate: action.payload,
      };
    }
    case types.CLEAR_CUSTOMER_REQUEST: {
      return {
        ...state,
        checkDelete: false,
        checkInsert: false,
        checkUpdate: false,
      };
    }
    default:
      return state;
  }
};

export default CrmCustomerRequestReducer;
