import React, { useEffect, useState } from "react";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import PropTypes from "prop-types";
import moment from "moment";
import Format from "../../../../../controller/Format";
import { DHSInput } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { widthDrawer } from "../../../index";
SellPriceTypeForm.propTypes = {
  objectCreate: PropTypes.object,
};

SellPriceTypeForm.defaultProps = {
  objectCreate: {
    CODE_TYPE: "",
    DESCRIPTION: "",
    CUSER: "",
    LUSER: "",
  },
};

const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};
export default function SellPriceTypeForm(props) {
  const {
    isVisible,
    checkVisible,
    title,
    handleActions,
    objectCreate,
    isEdit,
    Master,
  } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [objCreate, setObjCreate] = useState(
    _.isNil(Master) ? objectCreate : Master
  );
  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = () => {
    isEdit ? (objCreate.CUSER = userName) : (objCreate.LUSER = userName);
    handleActions({
      ...objCreate,
      MA_CTY,
    });
  };

  const handleChangeText = async (params) => {
    await setObjCreate({ ...objCreate, [params.name]: params.value });
  };
  return (
    <Drawer
      title={title}
      width={widthDrawer}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8, display: !isEdit ? "none" : "unset" }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Collapse
        defaultActiveKey={["0", "1", "2"]}
        ghost
        className="sellPrice-create"
        expandIconPosition="right"
      >
        <Panel header="Thông tin loại giá bán" key="1">
          <Form
            id="submit-form"
            onFinish={handleOk}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <DHSInput
                  label="Loại giá bán"
                  name="CODE_TYPE"
                  value={objCreate.CODE_TYPE}
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
              <Col span={24}>
                <DHSInput
                  label="Mô tả"
                  name="DESCRIPTION"
                  value={objCreate.DESCRIPTION}
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Drawer>
  );
}
