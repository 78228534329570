import * as types from "../../../constants/ActionTypes";

var initialState = {
  listApprovalPurchaseOrder: [],
  approvalPurchaseOrder: false,
  rejectPurchaseOrder: false,
};

var ApprovalPurchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_APPROVAL_PO1: {
      return {
        ...state,
        listApprovalPurchaseOrder: action.payload,
      };
    }
    case types.APPROVAL_PO1: {
      return {
        ...state,
        approvalPurchaseOrder: action.payload,
      };
    }
    case types.REJECT_PO1: {
      return {
        ...state,
        rejectPurchaseOrder: action.payload,
      };
    }
    case types.CLEAR_APPROVAL_PO1: {
      return {
        ...state,
        approvalPurchaseOrder: false,
        rejectPurchaseOrder: false,
      };
    }
    default:
      return state;
  }
};

export default ApprovalPurchaseOrderReducer;
