import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../redux/actions/CashAndBank/BankPayment/BankPayment";
import { periodData } from "../../../../controller/DataSample";
import { onPeriodChange } from "../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { widthDrawer } from "../../index";
import "./index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
CA4Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

CA4Filter.defaultProps = {
  _objectFilter: {
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    IDCUSTOMER: "",
    FORMDATE: moment().startOf("month").add(7, "hours"),
    TODATE: moment().endOf("month").add(7, "hours"),
    SO_CT1: "",
    SO_CT2: "",
    MA_GD: "",
    MA_KHO: "",
    IDITEM: "",
  },
};
export default function CA4Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listCustomer = useSelector((state) => state.CA4Reducer.listCustomer);
  const sysExchange = useSelector((state) => state.CA4Reducer.sysExchange);
  const [objFilter, setObjFilter] = useState(_objectFilter);
  useEffect(() => {
    dispatch(actions.getListsDm({ ma_cty }));
  }, []);

  const handleCancel = (e) => {
    checkVisible(false);
  };
  const handleOk = () => {
    dispatch(
      actions.filt({
        modelM: {
          ma_cty,
          idcustomer: objFilter.IDCUSTOMER,
          cdate: objFilter.FORMDATE,
          ldate: objFilter.TODATE,
          ma_gd: objFilter.MA_GD,
        },
        ...objFilter,
      })
    );
    checkVisible(false);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate,
      TODATE: result.toDate,
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Tìm kiếm"
        width={widthDrawer}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Tìm kiếm
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="CA4-filter"
        >
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD.toString()}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FORMDATE}
                nameState="FORMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Khách hàng"
            name="IDCUSTOMER"
            array={listCustomer}
            valueOpt={"IDCUSTOMER"}
            valueRender={"TEN_KH"}
            valueEmpty={true}
            onChange={(value) => handleChange(value, "IDCUSTOMER")}
          />
          <DHSSelectOpt
            label="Loại phiếu"
            array={sysExchange}
            valueOpt={"MA_GD"}
            valueRender={"MO_TA"}
            valueEmpty={true}
            onChange={(value) => handleChangeText({ name: "MA_GD", value })}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSInput
                label="Số phiếu từ"
                name="SO_CT1"
                value={objFilter.SO_CT1}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Số phiếu đến"
                name="SO_CT2"
                value={objFilter.SO_CT2}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
