import CashAndBank from "../../../components/layout/CashAndBank/CashAndBank";
import CashReceipt from "../../../components/layout/CashAndBank/CashReceipt/CashReceipt";
import CA1Edit from "../../../components/layout/CashAndBank/CashReceipt/CA1Edit/CA1Edit";
import CashPayment from "../../../components/layout/CashAndBank/CashPayment/CashPayment";
import CA2Edit from "../../../components/layout/CashAndBank/CashPayment/CA2Edit/CA2Edit";
import BankReceipt from "../../../components/layout/CashAndBank/BankReceipt/BankReceipt";
import CA3Edit from "../../../components/layout/CashAndBank/BankReceipt/CA3Edit/CA3Edit";
import BankPayment from "../../../components/layout/CashAndBank/BankPayment/BankPayment";
import CA4Edit from "../../../components/layout/CashAndBank/BankPayment/CA4Edit/CA4Edit";
const cashAndBankRoutes = [
  {
    path: "/CashAndBank",
    exact: false,
    main: ({ history }) => <CashAndBank history={history} />,
  },
  {
    path: "/CashReceipt",
    exact: false,
    main: ({ history }) => <CashReceipt history={history} />,
  },
  {
    path: "/CA1Edit",
    exact: false,
    main: ({ history }) => <CA1Edit history={history} />,
  },
  {
    path: "/CashPayment",
    exact: false,
    main: ({ history }) => <CashPayment history={history} />,
  },
  {
    path: "/CA2Edit",
    exact: false,
    main: ({ history }) => <CA2Edit history={history} />,
  },
  {
    path: "/BankReceipt",
    exact: false,
    main: ({ history }) => <BankReceipt history={history} />,
  },
  {
    path: "/CA3Edit",
    exact: false,
    main: ({ history }) => <CA3Edit history={history} />,
  },
  {
    path: "/BankPayment",
    exact: false,
    main: ({ history }) => <BankPayment history={history} />,
  },
  {
    path: "/CA4Edit",
    exact: false,
    main: ({ history }) => <CA4Edit history={history} />,
  },
];

export default cashAndBankRoutes;
