import * as types from "../../../constants/ActionTypes";

var initialState = {
  listDiscount: [],
  ctDiscount: [],
  phDiscount: {},
  soCt: "",
  newItem: {},
  newEdit: {},
  checkDelete: false,
  lists: {},
  lstCtChooseFromQU: [],
  checkDispatch: false,
};

var DiscountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_DS1: {
      return {
        ...state,
        listDiscount: action.payload,
      };
    }
    case types.GET_CT_DS1: {
      return {
        ...state,
        ctDiscount: action.payload,
      };
    }
    case types.GET_PH_DS1: {
      return {
        ...state,
        phDiscount: action.payload,
      };
    }
    case types.GET_SO_CT_DS1: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_DS1: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_DS1: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LISTS_DS1: {
      return {
        ...state,
        lists: action.payload,
      };
    }
    case types.DELETE_DS1: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_DS1: {
      return {
        ...state,
        checkDelete: false,
        newEdit: null,
        ctDiscount: [],
        phDiscount: {},
        newItem: null,
      };
    }
    case types.CLEAR_HANDLE_STT_DS1: {
      return {
        ...state,
        checkDelete: false,
        newEdit: null,
        newItem: null,
      };
    }
    default:
      return state;
  }
};

export default DiscountReducer;
