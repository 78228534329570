import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ToolBar from "../../../../../../common/DHSControl/DHSButton/toolBar";
import DHSDataGrid from "../../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import EmployeeDropDownBoxComponent from "../../../../../../common/DHSControl/DHSDropDownBox/DHSDropDownBox";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";
import PropTypes from "prop-types";
import HandleMultiAdd from "./handleMultiAdd/handleMultiAdd";
import { Collapse, Divider } from "antd";
const { Panel } = Collapse;
function ProductInfo(item, count = 0) {
  return (
    <div>
      <div
        style={{
          display: count === 0 ? "flex" : "none",
          overflow: "hidden",
          backgroundColor: "white",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          padding: 10,
          paddingBottom: 5,
        }}
      >
        <h5 style={{ width: "20%", margin: 0 }}>Mã vật tư</h5>
        <h5 style={{ width: "69%", margin: 0 }}>Tên vật tư</h5>
        <h5 style={{ width: "11%", margin: 0 }}>ĐVT</h5>
      </div>
      <div
        className="product"
        style={{
          display: "flex",
          flexWrap: "nowrap",
          borderBottom: "1px double lightgray",
          padding: 5,
          fontSize: 13,
          marginTop: count === 0 ? 20 : 0,
        }}
      >
        <div style={{ width: "20%" }}>{item.MA_VT}</div>
        <div style={{ width: "70%", marginBottom: 5 }}>{item.TEN_VT}</div>
        <div style={{ width: "10%" }}>{item.DVT}</div>
      </div>
    </div>
  );
}

ListItem.propTypes = {
  columns: PropTypes.array,
};

ListItem.defaultProps = {
  columns: [
    {
      caption: "Mã VT/HH",
      dataField: "IDITEM",
      type: 2,
      dropDown: EmployeeDropDownBoxComponent,
      dataSource: {
        data: [],
        valueExpr: "IDITEM",
        displayExpr: "MA_VT",
        render: ProductInfo,
      },
    },
    {
      caption: "Tên VT/HH",
      dataField: "TEN_VT",
      type: 0,
      width: 250,
    },
    {
      caption: "Loại chiết khấu",
      dataField: "DISCOUNT_CODE",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "DISCOUNT_CODE",
        displayExpr: "DISCOUNT_NAME",
      },
    },
    {
      caption: "Số lượng từ",
      dataField: "SO_LUONG_TU",
      type: 1,
    },
    {
      caption: "Số lượng đến",
      dataField: "SO_LUONG_DEN",
      type: 1,
    },
    {
      caption: "Số lượng",
      dataField: "SO_LUONG",
      type: 1,
    },
  ],
};

export default function ListItem(props) {
  const { columns, setIsArrayCt, isEdit } = props;
  const ctPromotion = useSelector((state) => state.DS2Reducer.ctPromotion);
  const [dataSource, setDataSource] = useState([]);
  const [isColumns, setColumns] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [idRowSelected, setIdRowSelected] = useState("");
  const Lists = useSelector((state) => state.DS2Reducer.lists);

  useEffect(() => {
    _.filter(columns, (x) => {
      switch (x.dataField) {
        case "IDITEM": {
          x.dataSource.data = Lists.dm_vattu;
          break;
        }
        case "DISCOUNT_CODE": {
          x.dataSource.data = Lists.dm_ds_type;
          break;
        }
        default: {
          break;
        }
      }
    });
    setColumns(columns);
  });

  useEffect(() => {
    ctPromotion.map((item) => {
      item.TEN_VT = Lists.dm_vattu.find((x) => x.IDITEM === item.IDITEM).TEN_VT;
    });
    setDataSource(ctPromotion);
    setIsArrayCt(ctPromotion);
  }, [ctPromotion]);

  const handleAddFromList = () => {
    setVisible(true);
  };

  const handleAddLine = () => {
    let hasItemEmpty = false;
    Promise.all(
      dataSource.map((item) => {
        if (Object.keys(item).length <= 1) {
          hasItemEmpty = true;
        }
      })
    ).then(async () => {
      hasItemEmpty
        ? openNotificationWithIcon(
            "error",
            "Vui lòng chọn sản phẩm vào dòng rỗng !"
          )
        : await setDataSource([
            ...dataSource,
            { STT_REC0: Math.random().toString(36).substr(2, 9).toUpperCase() },
          ]);
    });
  };

  const handleDeleteLine = () => {
    const data = _.filter(dataSource, (x) => {
      if (x.STT_REC0 === idRowSelected) {
        return false;
      }
      return true;
    });
    setDataSource(data);
    setIdRowSelected("");
    setIsArrayCt(data);
  };

  const hanleRowChange = async (params) => {
    _.map(dataSource, (item) => {
      if (item.STT_REC0 === params[0].key) {
        item = Object.assign(item, params[0].data);
        if (!_.isUndefined(item.IDITEM)) {
          item.TEN_VT = Lists.dm_vattu.find(
            (x) => x.IDITEM === item.IDITEM
          ).TEN_VT;
        }
      }
    });
    setIsArrayCt(dataSource);
  };

  const selectionChanged = (params) => {
    setIdRowSelected(params[0]);
  };

  const checkVisible = (params) => {
    setVisible(params.isVisible);
    if (!_.isEmpty(params.isSelected)) {
      setDataSource(dataSource.concat(params.isSelected));
    }
  };
  return (
    <div className="listItem">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chi tiết" key="1">
          <ToolBar
            setAddItem={handleAddFromList}
            setStateOpen={handleAddLine}
            setDeleteLine={handleDeleteLine}
            disabled={isEdit}
          />
          <Divider />
          <DHSDataGrid
            column={isColumns}
            data={dataSource}
            dataKey={"STT_REC0"}
            selectionChanged={selectionChanged}
            hanleRowChange={hanleRowChange}
            disabled={isEdit}
          />
        </Panel>
      </Collapse>
      {isVisible ? (
        <HandleMultiAdd isVisible={isVisible} checkVisible={checkVisible} />
      ) : (
        <></>
      )}
    </div>
  );
}
