import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import * as actions from "../../../../redux/actions/System/UserInfo/userInfo";
import { PageHeader, Spin } from "antd";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import UserInfoCreate from "./userInfoCreate/userInforCreate";
// import UserInfoEdit from "./roleInfoEdit/roleInfoEdit";
// import UserInfoDetail from "./roleInfoDetail/roleInforDetail";
import { openNotificationWithIcon } from "../../../../common/notification/notification";

const objectColumn = {
  USERNAME: { name: "Tên đăng nhập", width: 100 },
  FULLNAME: { name: "Tên đầy đủ", width: 100 },
  TITLE_NAME: { name: "Chức vụ", width: 100 },
  EMAIL: { name: "Email", width: 100 },
  ISADMIN: { name: "Quản trị", width: 100, checkBox: true },
  KSD: { name: "Không sử dụng", width: 100, checkBox: true },
};
const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};
export default function UserInfo(props) {
  const dispatch = useDispatch();
  const [customColumns, setCustomColumns] = useState();
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [isLoading, setLoading] = useState(false);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const UserInfoState = useSelector((state) => state.UserInfoReducer);
  useEffect(() => {
    dispatch(actions.GetListUserInfo({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    setLoading(false);
    setData(UserInfoState.listUserInfo);
    setFullData(UserInfoState.listUserInfo);
  }, [UserInfoState.listUserInfo]);

  useEffect(() => {
    if (UserInfoState.checkDelete) {
      dispatch(actions.GetListUserInfo({ ma_cty }));
      dispatch(actions.clearCache());
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [UserInfoState.checkDelete]);

  const getDataFilter = (params) => {
    setData(params);
  };

  const handleOpenDrawer = () => {
    dispatch(actions.clearCache());
    setIsOpenCreate(true);
  };
  const checkVisible = (params) => {
    setIsOpenCreate(params);
    setIsOpenDetail(params);
    setIsOpenEdit(params);
  };
  const getDataRow = (params) => {
    setMaster(params);
  };
  const handleEdit = (params) => {
    setIsOpenEdit(true);
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Người sử dụng"
          onBack={() => props.history.push("/System")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setEdit={handleEdit}
          Master={isMaster}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.GetListUserInfo({ ma_cty }));
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="USERNAME"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setIsOpenDetail(true);
          }}
        />
        {/* {isOpenCreate ? (
          <UserInfoCreate
            isVisible={isOpenCreate}
            checkVisible={checkVisible}
          />
        ) : (
          <></>
        )} */}
        {/* {isOpenDetail ? (
          <UserInfoDetail
            isVisible={isOpenDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
        {isOpenEdit ? (
          <UserInfoEdit
            isVisible={isOpenEdit}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )} */}
      </Spin>
    </div>
  );
}
