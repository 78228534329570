import React, { Component } from "react";
import { PageHeader, Button, Space, Spin } from "antd";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Purchases/purchaseReceipt/purchaseReceipt";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import PO2Create from "./po2Create/po2Create";
import PO2Detail from "./po2Detail/po2Detail";
import PO2Filter from "./po2Filter/po2Filter";
import "./index.css";
import _ from "lodash";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import ButtonDelete from "../../../../common/DHSControl/DHSButton/btnDelete";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
const objectColumn = {
  SO_CT: { name: "Số phiếu nhập", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
};
class PurchaseReceipt extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    openModalCreate: false,
    openDrawerFilter: false,
    openDrawerDetail: false,
    rowId: "",
    Master: {},
  };
  componentDidMount = () => {
    this.props.getListNeed({ ma_cty: this.props.ma_cty });
    this.loadListPO2();
  };
  componentDidUpdate = async (prevProps) => {
    const { listPurchaseReceipt, checkDelete } = this.props;
    if (!_.isEqual(listPurchaseReceipt, prevProps.listPurchaseReceipt)) {
      this.setState({
        data: this.props.listPurchaseReceipt,
        fullData: this.props.listPurchaseReceipt,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ Master: {} });
      this.loadListPO2();
      this.props.clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };

  confirmDelete = (params) => {
    // this.setState({ loading: true });
    this.props.deleteAPurchaseReceipt({ modelM: params });
  };

  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.props.getCTPurchaseReceipt({
        stt_rec: Master.STT_REC,
        ma_cty: this.props.ma_cty,
      });
      this.props.history.push("/poVch02Edit", {
        Master: Master,
      });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn phiếu nhập cần sửa !");
    }
  };

  render() {
    const {
      data,
      customColumns,
      openModalCreate,
      Master,
      openDrawerDetail,
      loading,
      openDrawerFilter,
      fullData,
    } = this.state;
    return (
      <div className="purchaseReceipt" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Phiếu nhập mua"
            onBack={() => this.props.history.push("/Purchase")}
          />
          <Space className="topMenu">
            <Button
              onClick={() => this.setState({ openModalCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <ButtonDelete
              objDelele={Master}
              confirmDelete={this.confirmDelete}
            />
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListPO2();
            }}
            openFiler={() => {
              this.setState({ openDrawerFilter: true });
            }}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="SO_CT"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openModalCreate ? (
            <PO2Create
              isVisible={openModalCreate}
              checkVisible={this.checkVisible}
              history={this.props.history}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <PO2Detail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerFilter ? (
            <PO2Filter
              isVisible={openDrawerFilter}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  loadListPO2 = () => {
    const { listPurchaseReceipt } = this.props;
    // this.setState({ loading: true });
    if (_.isEmpty(this.state.customColumns)) {
      FormatColumnDHSTable(objectColumn);
      this.setState({ customColumns: result });
    }
    listPurchaseReceipt.length = 0;
    this.props.getListPurchaseReceipt({ ma_cty: this.props.ma_cty });
  };
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  getDataRow = (params) => {
    this.setState({ Master: params });
  };
  checkVisible = (params) => {
    this.setState({
      openModalCreate: params,
      loading: params,
      openDrawerDetail: params,
      openDrawerFilter: params,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listPurchaseReceipt: state.PO2Reducer.listPurchaseReceipt,
    listCustomer: state.PO2Reducer.listCustomer,
    customer: state.PO2Reducer.customer,
    ctPurchaseReceipt: state.PO2Reducer.ctPurchaseReceipt,
    checkDelete: state.PO2Reducer.checkDelete,
    // listFiltPackingSlip: state.PO2Reducer.listFiltPackingSlip,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    clearCache: () => {
      dispatch(actions.clearCache());
    },
    getListPurchaseReceipt: (ma_cty) => {
      dispatch(actions.getListPurchaseReceipt(ma_cty));
    },
    getPHPurchaseReceipt: (detail) => {
      dispatch(actions.getPHPurchaseReceipt(detail));
    },
    getCTPurchaseReceipt: (detail) => {
      dispatch(actions.getCTPurchaseReceipt(detail));
    },
    deleteAPurchaseReceipt: (master) => {
      dispatch(actions.deleteAPurchaseReceipt(master));
    },
    getListNeed: (ma_cty) => {
      dispatch(actions.getListCustomer(ma_cty));
      dispatch(actions.getListStatus());
      dispatch(actions.getListCurrency());
      dispatch(actions.getListIncoterms(ma_cty));
      dispatch(actions.getListAccount(ma_cty));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseReceipt);
