import * as types from "../../../../constants/ActionTypes";
import callApi from "../../../../../routes/config/configApi";

export const getListSellPriceType = (ma_cty) => (dispatch, getState) => {
  callApi("api/SellPriceTypes/ListSellPriceTypes", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_SELL_PRICE_TYPE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewSellPriceType = (quotation) => (dispatch, getState) => {
  callApi("api/SellPriceTypes/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_SELL_PRICE_TYPE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteSellPriceType = (master) => (dispatch, getState) => {
  callApi("api/SellPriceTypes/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_SELL_PRICE_TYPE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateSellPriceType = (so1) => (dispatch, getState) => {
  callApi("api/SellPriceTypes/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_SELL_PRICE_TYPE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_SELL_PRICE_TYPE,
  };
};
