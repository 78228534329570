import * as types from "../../../constants/ActionTypes";

var initialState = {
  listPurchaseReceipt: [],
  ctPurchaseReceipt: [],
  phPurchaseReceipt: [],
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: {},
  newEdit: {},
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  listDm: {},
  checkAddNewCustomer: false,
  listNewAddress: [],
  typeReceipt: [],
  listIncoterms: [],
  listAccount: [],
  dsPhPO1: [],
  dsCtPO1: [],
};

var PurchaseReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_PO2: {
      return {
        ...state,
        listPurchaseReceipt: action.payload,
      };
    }
    case types.FILT_PO2: {
      return {
        ...state,
        listPurchaseReceipt: action.payload,
      };
    }
    case types.GET_PH_PO2: {
      return {
        ...state,
        phPurchaseReceipt: action.payload,
      };
    }
    case types.GET_CT_PO2: {
      return {
        ...state,
        ctPurchaseReceipt: action.payload,
      };
    }
    case types.GET_CUSTOMER_PO2: {
      return {
        ...state,
        customer: action.payload[0],
      };
    }
    case types.GET_LIST_INCOTERMS_PO2: {
      return {
        ...state,
        listIncoterms: action.payload,
      };
    }
    case types.GET_LIST_ACCOUNT_PO2: {
      return {
        ...state,
        listAccount: action.payload,
      };
    }
    case types.GET_LIST_CUSTOMER_PO2: {
      return {
        ...state,
        listCustomer: action.payload,
      };
    }
    case types.GET_SO_CT_PO2: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_PO2: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_PO2: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LIST_STATUS_PO2: {
      return {
        ...state,
        listStatus: action.payload,
      };
    }
    case types.GET_LIST_CURRENCY_PO2: {
      return {
        ...state,
        listCurrency: action.payload,
      };
    }
    case types.GET_EXCHANGE_RATE_PO2: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_PO2: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.GET_TYPE_RECEIPT: {
      return {
        ...state,
        typeReceipt: action.payload,
      };
    }
    case types.CLEAR_PO2: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctPurchaseReceipt: [],
        phPurchaseReceipt: {},
        newItem: null,
      };
    }
    case types.CLEAR_HANDLE_STT_PO2: {
      return {
        ...state,
        checkDelete: false,
        newEdit: null,
        newItem: null,
      };
    }
    case types.LOOKUP_PO1: {
      return {
        ...state,
        dsCtPO1: action.payload.dsCt,
        dsPhPO1: action.payload.dsPh,
      };
    }
    default:
      return state;
  }
};

export default PurchaseReceiptReducer;
