import React, { Component } from "react";
import SiteForm from "../SiteForm/SiteForm";
export default class SiteDetail extends Component {
  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <SiteForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          title="Thông tin chi nhánh"
          Master={Master}
          noneEdit={true}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
