import React, { Component } from "react";
import { Modal, Button, Row, Col } from "antd";
import { Template } from "../../../../../common/DHSControl/DHSTemplateRP/Template";
import DHSPrint from "../../../../../common/DHSControl/DHSPrint/DHSPrint";
import { DHSSelectOpt } from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
const contentPrint =
  "Cảm ơn Quý khách hàng đã quan tâm và sử dụng dịch vụ của chúng tôi. Công ty xin gửi tới Quý khách hàng phiếu xuất  . Chi tiết phiếu xuất";

export default class ModalSelectRp extends Component {
  state = {
    lstTemplate: [
      {
        id: "Rp01",
        name: "Phiếu xuất bán",
      },
      {
        id: "Rp02",
        name: "Phiếu giao hàng",
      },
    ],
    template: {
      id: "Rp01",
      name: "Phiếu xuất bán",
    },
  };
  handleChange = (value) => {
    this.state.lstTemplate.map((item) => {
      if (item.id === value) {
        this.setState({ template: item });
      }
    });
  };
  render() {
    const {
      isModalVisible,
      ctPackingSlip,
      dsCtDriver,
      contactPackingSlip,
      Master,
    } = this.props;
    const { template, lstTemplate } = this.state;
    return (
      <div>
        <Modal
          title="Chọn mẫu in"
          visible={isModalVisible}
          footer={
            <Row>
              <Col span={3} offset={17}>
                <DHSPrint
                  PagePrint={Template[template.id]}
                  objPrint={Master}
                  ctObjPrint={ctPackingSlip}
                  ctAddressPrint={contactPackingSlip}
                  driverPrint={dsCtDriver}
                  title={template.name}
                  contentPrint={contentPrint}
                  onAfterPrint={this.onAfterPrint}
                  onBeforeGetContent={this.onBeforeGetContent}
                />
              </Col>
              <Col span={3} offset={1}>
                <Button onClick={this.handleCancel}>Hủy</Button>
              </Col>
            </Row>
          }
        >
          <DHSSelectOpt
            label="Mẫu in"
            array={lstTemplate}
            valueOpt={"id"}
            valueRender={"name"}
            defaultValue={template.id}
            onChange={this.handleChange}
          />
        </Modal>
      </div>
    );
  }
  handleCancel = () => {
    this.props.ctPackingSlip.length = 0;
    this.props.contactPackingSlip.length = 0;
    this.props.checkVisible(false);
  };
  onBeforeGetContent = () => {};
  onAfterPrint = () => {};
}
