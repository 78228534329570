import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";
import LoginReducer from "./Login/index";
import ManufacturingReducer from "./Manufacturing/manufacturing";
import MachineStatusReducer from "./Manufacturing/MachineStatus/index";
import SO2Reducer from "./SalesReducer/packingSlip/index";
import ItemReducer from "./Category/Item/item";
import ItemGroupReducer from "./Category/ItemGroup/itemGroup";
import InventoryReducer from "./Category/Inventory/inventory";
import SalesDBReducer from "./DashboardReducer/SalesDB/SalesDB";
import MachineRptReducer from "./Manufacturing/MachineRpt/index";
import LstColorReducer from "./Category/Color/color";
import LstSizeReducer from "./Category/Size/size";
import MasterPlanningReducer from "./Manufacturing/MasterPlanning/index";
import SICustomerReducer from "./Category/Customer/customer";
import SO1Reducer from "./SalesReducer/salesOrder/salesOrder";
import PO2Reducer from "./PurchasesReducer/purchaseReceipt/purchaseReceipt";
import SiteReducer from "./Category/Site/Site";
import VehicleReducer from "./Category/Vehicle/Vehicle";
import ApprovalSalesOrdersReducer from "./SalesReducer/approvalSalesOrder/approvalSalesOrder";
import ApprovalQuotationsReducer from "./SalesReducer/approvalQuotations/approvalQuotations";
import DriverReducer from "./Category/Driver/driver";
import CrmCustomerReducer from "./CustomerServices/CrmCustomer/crmCustomer";
import CrmCustomerRequestReducer from "./CustomerServices/crmCustomerRequest/crmCustomerRequest";
import DS1Reducer from "./SalesReducer/soDiscount/soDiscount";
import SellPriceReducer from "./SalesReducer/sellPriceBase/sellPriceBase";
import SO0Reducer from "./SalesReducer/quotation/quotation";
import DS2Reducer from "./SalesReducer/soPromotion/soPromotion";
import SellPriceTypesReducer from "./SalesReducer/sellPriceBase/sellPriceTypes/sellPriceTypes";
import SO4Reducer from "./SalesReducer/salesReturn/salesReturn";
import SO3Reducer from "./SalesReducer/salesInvoice/salesInvoice";
import CustomerGroupReducer from "./Category/CustomerGroup/customerGroup";
import CustomerKindReducer from "./Category/CustomerKind/customerKind";
import PO1Reducer from "./PurchasesReducer/purchaseOrder/purchaseOrder";
import ApprovalPO1Reducer from "./PurchasesReducer/approvalPurchaseOrder/approvalPurchaseOrder";
import SOReportsReducer from "./SalesReducer/reports/reports";
import ChangePasswordReducer from "./System/ChangePasswork/changePasswork";
import RoleInfoReducer from "./System/RoleInfo/roleInfo";
import UserInfoReducer from "./System/UserInfo/userInfo";
import IN1Reducer from "./Inventory/MovementReceipt/movementReceipt";
import IN2Reducer from "./Inventory/MovementIssue/movementIssue";
import IN3Reducer from "./Inventory/MovementTransfer/movementTransfer";
import INReportsReducer from "./Inventory/Reports/INReports";
import CA1Reducer from "./CashAndBank/CashReceipt/CashReceipt";
import CA2Reducer from "./CashAndBank/CashPayment/CashPayment";
import CA3Reducer from "./CashAndBank/BankReceipt/BankReceipt";
import CA4Reducer from "./CashAndBank/BankPayment/BankPayment";
const appReducer = combineReducers({
  LoginReducer,
  MachineStatusReducer,
  SO2Reducer,
  ItemReducer,
  InventoryReducer,
  SalesDBReducer,
  MachineRptReducer,
  LstColorReducer,
  LstSizeReducer,
  ManufacturingReducer,
  MasterPlanningReducer,
  SICustomerReducer,
  SO1Reducer,
  PO2Reducer,
  ItemGroupReducer,
  SiteReducer,
  VehicleReducer,
  ApprovalSalesOrdersReducer,
  DriverReducer,
  CrmCustomerReducer,
  CrmCustomerRequestReducer,
  DS1Reducer,
  SellPriceReducer,
  SO0Reducer,
  DS2Reducer,
  ApprovalQuotationsReducer,
  SellPriceTypesReducer,
  SO4Reducer,
  SO3Reducer,
  CustomerGroupReducer,
  CustomerKindReducer,
  PO1Reducer,
  ApprovalPO1Reducer,
  SOReportsReducer,
  ChangePasswordReducer,
  RoleInfoReducer,
  UserInfoReducer,
  IN1Reducer,
  IN2Reducer,
  IN3Reducer,
  INReportsReducer,
  CA1Reducer,
  CA2Reducer,
  CA3Reducer,
  CA4Reducer,
});

const rootReducer = (state, action) => {
  //Clear all data in redux store to initial.
  if (action.type === types.DESTROY_SESSION) state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
