import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListMovementReceipt = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch01/ListMovementReceipt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHMovementReceipt = (detail) => (dispatch, getState) => {
  callApi("api/inVch01/GetPHMovementReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTMovementReceipt = (detail) => (dispatch, getState) => {
  callApi("api/inVch01/GetCTMovementReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch01/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/inVch01/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewMovementReceipt = (query) => (dispatch, getState) => {
  callApi("api/inVch01/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteAMovementReceipt = (master) => (dispatch, getState) => {
  callApi("api/inVch01/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_IN1,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updateMovementReceipt = (so1) => (dispatch, getState) => {
  callApi("api/inVch01/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/inVch01/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch01/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_IN1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_IN1,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_IN1,
  };
};
