import Customer from "../../../components/layout/Category/Customer/customer";
import Item from "../../../components/layout/Category/Item/item";
import Vendors from "../../../components/layout/Category/Vendors/vendors";
import Employee from "../../../components/layout/Category/Employee/employee";
import Category from "../../../components/layout/Category/category";
import ItemGroup from "../../../components/layout/Category/ItemGroup/itemGroup";
import Site from "../../../components/layout/Category/Site/Site";
import Vehicle from "../../../components/layout/Category/Vehicle/Vehicle";
import Driver from "../../../components/layout/Category/Driver/Driver";
import CustomerGroup from "../../../components/layout/Category/CustomerGroup/customerGroup";
import CustomerKind from "../../../components/layout/Category/CustomerKind/customerKind";
const categoryRoute = [
  {
    path: "/Category",
    exact: false,
    main: ({ history }) => <Category history={history} />,
  },
  {
    path: "/Customer",
    exact: false,
    main: ({ history }) => <Customer history={history} />,
  },

  {
    path: "/ItemDetail",
    exact: false,
    main: ({ history }) => <Item history={history} />,
  },
  {
    path: "/Vendors",
    exact: false,
    main: ({ history }) => <Vendors history={history} />,
  },
  {
    path: "/Employee",
    exact: false,
    main: ({ history }) => <Employee history={history} />,
  },
  {
    path: "/ItemGroup",
    exact: false,
    main: ({ history }) => <ItemGroup history={history} />,
  },
  {
    path: "/Site",
    exact: false,
    main: ({ history }) => <Site history={history} />,
  },
  {
    path: "/Vehicle",
    exact: false,
    main: ({ history }) => <Vehicle history={history} />,
  },
  {
    path: "/Driver",
    exact: false,
    main: ({ history }) => <Driver history={history} />,
  },
  {
    path: "/CustomerGroup",
    exact: false,
    main: ({ history }) => <CustomerGroup history={history} />,
  },
  {
    path: "/CustomerKind",
    exact: false,
    main: ({ history }) => <CustomerKind history={history} />,
  },
];

export default categoryRoute;
