import RoleInfoForm from "../roleInfoForm/roleInfoForm";

export default function RoleInfoDetail(props) {
  const { isVisible, checkVisible, Master } = props;
  return (
    <RoleInfoForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      Master={Master}
      isEdit={false}
      title="Chi tiết vai trò"
    />
  );
}
