import React from "react";
import LoginPage from "./components/layout/Login/index";
import Main from "./components/layout/Main/Main";
const routes = [
  {
    path: "/login",
    exact: true,
    main: ({ history }) => <LoginPage history={history} />,
  },
  {
    path: "/",
    exact: false,
    main: ({ history }) => <Main history={history} />,
  },
];
export default routes;
