import * as types from "../../../constants/ActionTypes";

var initialState = {
  listVehicle: [],
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
};

var VehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_VEHICLE: {
      return {
        ...state,
        listVehicle: action.payload,
      };
    }

    case types.INSERT_VEHICLE:
      return {
        ...state,
        checkInsert: action.payload,
      };
    case types.DELETE_VEHICLE:
      return {
        ...state,
        checkDelete: action.payload,
      };
    case types.UPDATE_VEHICLE:
      return {
        ...state,
        checkUpdate: action.payload,
      };
    case types.CLEAR_VEHICLE: {
      return {
        ...state,
        checkUpdate: false,
        checkDelete: false,
        checkInsert: false,
        listVehicle: [],
      };
    }
    default:
      return state;
  }
};

export default VehicleReducer;
