import React, { useState, useEffect } from "react";
import CustomerGroupForm from "../customerGroupForm/customerGroupForm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/Category/customerGroup/customerGroup";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";

export default function CustomerGroupCreate(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, history } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);

  const checkInsert = useSelector(
    (state) => state.CustomerGroupReducer.checkInsert
  );

  useEffect(() => {
    if (checkInsert) {
      openNotificationWithIcon("success", "Thêm nhóm khách hàng thành công !");
      dispatch(actions.clearCache());
      dispatch(actions.getListCustomerGroup({ MA_CTY }));
      checkVisible(false);
    }
  }, [checkInsert]);

  const handleActions = (params) => {
    dispatch(actions.addNewCustomerGroup(params));
  };

  return (
    <CustomerGroupForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      handleActions={handleActions}
      title="Thêm mới nhóm khách hàng"
    />
  );
}
