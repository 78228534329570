import React, { useState, useEffect } from "react";
import Ds2Form from "../ds2Form/ds2Form";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/Sales/soPromotion/soPromotion";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";

export default function Ds2Create(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, history } = props;
  const newItem = useSelector((state) => state.DS2Reducer.newItem);

  useEffect(() => {
    if (newItem === "-1") {
      openNotificationWithIcon("error", "Trùng số khuyến mãi !");
      dispatch(actions.clearSTT());
    } else if (_.isObject(newItem)) {
      openNotificationWithIcon(
        "success",
        "Thêm chương trình khuyến mãi thành công !"
      );
      history.push("/PromotionSoEdit", {
        Master: newItem,
      });
    }
  }, [newItem]);

  const handleActions = (params) => {
    dispatch(actions.addNewPromotion(params));
  };

  return (
    <Ds2Form
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      handleActions={handleActions}
      title="Thêm mới chương trình khuyến mãi"
    />
  );
}
