import SalesDB from "../../../components/layout/Dashboard/SalesDB/SalesDB";

const dashboardRoutes = [
  {
    path: "/DashBoard",
    exact: false,
    main: ({ history }) => <SalesDB history={history} />,
  },
];

export default dashboardRoutes;
