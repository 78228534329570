import React, { Component } from "react";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { Button, Space, Spin, Popconfirm, PageHeader } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Category/vehicle/vehicle";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import "./index.css";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import VehicleCreate from "./VehicleCreate/VehicleCreate";
import VehicleDetail from "./VehicleDetail/VehicleDetail";
import VehicleEdit from "./VehicleEdit/VehicleEdit";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const objectColumn = {
  MA_PT: { name: "Mã phương tiện", width: 100 },
  TEN_PT: { name: "Tên phương tiện", width: 300 },
  BIEN_SO: { name: "Biển số", width: 100 },
  NGUOI_DK: { name: "Người điều khiển", width: 300 },
};
class Vehicle extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    MA_PT: "",
    openDrawerCreate: false,
    openDrawerEdit: false,
    openDrawerDetail: false,
    Master: {},
  };
  componentDidMount = () => {
    this.loadListVehicle();
  };
  componentDidUpdate = (prevProps) => {
    const { listVehicle, checkDelete, clearCache } = this.props;
    if (!_.isEqual(listVehicle, prevProps.listVehicle)) {
      this.setState({
        data: listVehicle,
        fullData: listVehicle,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ loading: false, MA_PT: "", Master: {} });
      this.loadListVehicle();
      clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };
  confirmDelete(MA_PT) {
    this.setState({ loading: true });
    this.props.deleteVehicle({
      ma_cty: this.props.ma_cty,
      ma_pt: MA_PT,
    });
  }
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.setState({ openDrawerEdit: true });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn phương tiện cần sửa !");
    }
  };

  render() {
    const {
      loading,
      data,
      customColumns,
      MA_PT,
      openDrawerCreate,
      openDrawerEdit,
      Master,
      openDrawerDetail,
      fullData,
    } = this.state;
    return (
      <div className="Vehicle" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Phương tiện vận tải"
            onBack={() => this.props.history.push("/Category")}
          />
          <Space style={{ marginBottom: 16, marginTop: 10 }}>
            <Button
              onClick={() => this.setState({ openDrawerCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn muốn xóa phương tiện ?"
              onConfirm={() => this.confirmDelete(MA_PT)}
              okText="Đồng ý"
              cancelText="Hủy"
              disabled={MA_PT === "" ? true : false}
            >
              <Button
                onClick={() => {
                  if (MA_PT === "") {
                    openNotificationWithIcon(
                      "error",
                      "Vui lòng chọn phương tiện cần xóa !"
                    );
                  }
                }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            </Popconfirm>
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListVehicle();
            }}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="MA_PT"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openDrawerCreate ? (
            <VehicleCreate
              isVisible={openDrawerCreate}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
          {openDrawerEdit ? (
            <VehicleEdit
              isVisible={openDrawerEdit}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <VehicleDetail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.setState({
      openDrawerCreate: params.isVisible,
      loading: params.isVisible,
      openDrawerDetail: params.isVisible,
      openDrawerEdit: params.isVisible,
      openDrawerFilter: params.isVisible,
    });
    params.editSuccess ? this.loadListVehicle(this.state.moduleID) : <></>;
    params.editSuccess ? this.setState({ MA_PT: "", Master: {} }) : <></>;
  };
  getDataRow = (params) => {
    this.setState({ Master: params, MA_PT: params.MA_PT });
  };
  loadListVehicle = () => {
    const { listVehicle } = this.props;
    this.setState({ loading: true });
    if (this.state.customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      this.setState({ customColumns: result });
    }
    listVehicle.length = 0;
    this.props.getListVehicle({ ma_cty: this.props.ma_cty });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listVehicle: state.VehicleReducer.listVehicle,
    checkDelete: state.VehicleReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListVehicle: (ma_cty) => {
      dispatch(actions.getListVehicle(ma_cty));
    },
    deleteVehicle: (query) => {
      dispatch(actions.deleteVehicle(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
