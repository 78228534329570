import Ds1Form from "../ds1Form/ds1Form";

export default function Ds1Detail(props) {
  const { isVisible, checkVisible, Master } = props;
  return (
    <Ds1Form
      isVisible={isVisible}
      checkVisible={checkVisible}
      Master={Master}
      isEdit={false}
      title="Chi tiết chương trình chiết khấu"
    />
  );
}
