import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../redux/actions/Sales/quotation/quotation";
import * as actionsApproval from "../../../../../redux/actions/Sales/approvalQuotation/approvalQuotation";
import * as actionsInventory from "../../../../../redux/actions/Category/Inventory/index";
import * as actionsItem from "../../../../../redux/actions/Category/Item/index";
import { periodData } from "../../../../controller/DataSample";
import { onPeriodChange } from "../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { widthDrawer } from "../../index";
import "./index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
So0Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

So0Filter.defaultProps = {
  _objectFilter: {
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    IDCUSTOMER: "",
    FORMDATE: moment().startOf("month").add(7, "hours"),
    TODATE: moment().endOf("month").add(7, "hours"),
    SO_CT1: "",
    SO_CT2: "",
    TRANG_THAI: "",
    MA_KHO: "",
    IDITEM: "",
  },
};
export default function So0Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter, approvalScr } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listCustomer = useSelector((state) => state.SO0Reducer.listCustomer);
  const listStatus = useSelector((state) => state.SO0Reducer.listStatus);
  const lstInventory = useSelector(
    (state) => state.InventoryReducer.lstInventory
  );
  const listItem = useSelector((state) => state.ItemReducer.listItem);
  const [objFilter, setObjFilter] = useState(_objectFilter);
  useEffect(() => {
    dispatch(actionsItem.getListItem({ ma_cty }));
    dispatch(actionsInventory.getListInventory({ ma_cty }));
  }, []);

  const handleCancel = (e) => {
    checkVisible(false);
  };
  const handleOk = () => {
    approvalScr
      ? dispatch(
          actionsApproval.getListApprovalQuotations({
            ...objFilter,
            cdate: objFilter.FORMDATE,
            ldate: objFilter.TODATE,
            ma_cty,
          })
        )
      : dispatch(
          actions.filt({
            modelM: {
              ma_cty,
              idcustomer: objFilter.IDCUSTOMER,
              cdate: objFilter.FORMDATE,
              ldate: objFilter.TODATE,
              trang_thai: objFilter.TRANG_THAI,
            },
            ...objFilter,
          })
        );
    checkVisible(false);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate,
      TODATE: result.toDate,
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Tìm kiếm"
        width={widthDrawer}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Tìm kiếm
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="so0-filter"
        >
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD.toString()}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FORMDATE}
                nameState="FORMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Khách hàng"
            name="IDCUSTOMER"
            array={listCustomer}
            valueOpt={"idcustomer"}
            valueRender={"ten_kh"}
            onChange={(value) => handleChange(value, "IDCUSTOMER")}
          />
          <DHSSelectOpt
            label="Trạng thái"
            name="TRANG_THAI"
            array={listStatus}
            valueOpt={"trang_thai"}
            valueRender={"mo_ta"}
            onChange={(value) => handleChange(value, "TRANG_THAI")}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSInput
                label="Số phiếu từ"
                name="SO_CT1"
                value={objFilter.SO_CT1}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Số phiếu đến"
                name="SO_CT2"
                value={objFilter.SO_CT2}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Kho"
            name="MA_KHO"
            array={lstInventory}
            valueOpt={"MA_KHO"}
            valueRender={"TEN_KHO"}
            onChange={(value) => handleChange(value, "MA_KHO")}
          />
          <DHSSelectOpt
            label="Vật tư"
            name="IDITEM"
            array={listItem}
            valueOpt={"iditem"}
            valueRender={"ten_vt"}
            onChange={(value) => handleChange(value, "IDITEM")}
          />
        </Form>
      </Drawer>
    </div>
  );
}
