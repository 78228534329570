import React, { useEffect, useState } from "react";
import { Collapse, Divider, Row, Col, Input, Descriptions } from "antd";
import { useSelector } from "react-redux";
import ToolBar from "../../../../../../common/DHSControl/DHSButton/toolBar";
import DHSDataGrid from "../../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import EmployeeDropDownBoxComponent from "../../../../../../common/DHSControl/DHSDropDownBox/DHSDropDownBox";
import PropTypes from "prop-types";
import ProductInfo from "../../../../../controller/ProductInfo";
import HandleMultiAdd from "./handleMultiAdd/handleMultiAdd";
import {
  toUpperPropertyNameByArray,
  FormatMoney,
} from "../../../../../controller/Format";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";

ListItem.propTypes = {
  columns: PropTypes.array,
  total: PropTypes.object,
};

ListItem.defaultProps = {
  columns: [
    {
      caption: "Mã VT/HH",
      dataField: "IDITEM",
      type: 2,
      dropDown: EmployeeDropDownBoxComponent,
      dataSource: {
        data: [],
        valueExpr: "IDITEM",
        displayExpr: "MA_VT",
        render: ProductInfo,
      },
    },
    {
      caption: "Tên VT/HH",
      dataField: "TEN_VT",
      type: 0,
      width: 250,
    },
    {
      caption: "ĐVT",
      dataField: "DVT",
      type: 0,
    },
    {
      caption: "Kho",
      dataField: "MA_KHO",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "MA_KHO",
        displayExpr: "TEN_KHO",
      },
    },
    {
      caption: "Color",
      dataField: "COLOR_ID",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "COLOR_ID",
        displayExpr: "COLOR_NAME",
      },
      visible: false,
    },
    {
      caption: "Size",
      dataField: "SIZE_ID",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "SIZE_ID",
        displayExpr: "SIZE_NAME",
      },
      visible: false,
    },
    {
      caption: "Số lượng",
      dataField: "SO_LUONG",
      type: 1,
    },
    {
      caption: "Đơn giá",
      dataField: "GIA_NT2",
      format: "Money",
      type: 1,
    },
    {
      caption: "Tiền ngoại tệ",
      dataField: "TIEN_NT2",
      format: "Money",
      type: 0,
    },
    {
      caption: "% CK",
      dataField: "TL_CK",
      type: 1,
      visible: true,
    },
    {
      caption: "Tiền CK",
      dataField: "TIEN_CK_NT",
      type: 0,
      format: "Money",
      visible: true,
    },
    {
      caption: "% VAT",
      dataField: "TS_GTGT",
      type: 1,
      visible: false,
    },
    {
      caption: "Tiền thuế",
      dataField: "THUE_GTGT_NT",
      type: 1,
      format: "Money",
      visible: false,
    },
    {
      caption: "Tổng tiền",
      dataField: "TT",
      format: "Money",
      type: 0,
    },
  ],
  total: {
    amount: 0,
    tl_ck: 0,
    ts_gtgt: 0,
    tt: 0,
    tien_ck_nt: 0,
    thue_gtgt_nt: 0,
  },
};

const { Panel } = Collapse;
export default function ListItem(props) {
  const {
    isEdit,
    columns,
    ty_gia,
    isClickSave,
    getLine,
    total,
    Master,
    lstItemSelectedFromSearch,
  } = props;
  const ctSalesInvoice = useSelector(
    (state) => state.SO3Reducer.ctSalesInvoice
  );
  const [isColumns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [idRowSelected, setIdRowSelected] = useState("");
  const [openDrawerMultiAdd, setOpenDrawerMultiAdd] = useState(false);
  const [isTotal, setTotal] = useState(total);
  const lstInventory = useSelector(
    (state) => state.InventoryReducer.lstInventory
  );
  const lookupSelectedItem = useSelector(
    (state) => state.SellPriceReducer.lookupSelectedItem
  );

  useEffect(() => {
    if (!_.isEmpty(lookupSelectedItem)) {
      _.filter(columns, (x) => {
        switch (x.dataField) {
          case "IDITEM": {
            x.dataSource.data = lookupSelectedItem;
            break;
          }
          case "MA_KHO": {
            x.dataSource.data = toUpperPropertyNameByArray(lstInventory);
            break;
          }
          default: {
            break;
          }
        }
      });
      setColumns(columns);
    }
  }, [lookupSelectedItem, lstInventory]);

  useEffect(() => {
    if (!_.isEmpty(ctSalesInvoice)) {
      toUpperPropertyNameByArray(ctSalesInvoice);
      setDataSource(ctSalesInvoice);
      setTotal({
        tl_ck: Master.TL_CK,
        ts_gtgt: Master.TS_GTGT,
        thue_gtgt_nt: Master.T_THUE_NT,
        tien_ck_nt: Master.T_CK,
        tt: Master.T_TT,
        amount: ctSalesInvoice.reduce((a, b) => a + (b["TT"] || 0), 0),
      });
    } else if (!_.isEmpty(lstItemSelectedFromSearch)) {
      setDataSource(dataSource.concat(lstItemSelectedFromSearch));
    }
  }, [ctSalesInvoice]);

  useEffect(() => {
    const { amount, tl_ck, ts_gtgt } = isTotal;
    const tien_ck_nt = (amount * tl_ck) / 100;
    const thue_gtgt_nt = ((amount - tien_ck_nt) * ts_gtgt) / 100;
    setTotal({
      ...isTotal,
      tien_ck_nt,
      thue_gtgt_nt,
      tt: amount - tien_ck_nt + thue_gtgt_nt,
    });
  }, [isTotal.amount, isTotal.ts_gtgt, isTotal.tl_ck]);

  useEffect(() => {
    dataSource.map((item) => calc(item));
  }, [ty_gia]);

  useEffect(() => {
    if (isClickSave) {
      getLine({ data: dataSource, total: isTotal });
    }
  }, [isClickSave]);

  useEffect(() => {
    if (!_.isEmpty(dataSource)) {
      dataSource.map((item) => calc(item));
    } else {
      setTotal(total);
    }
  }, [dataSource]);

  const handleAddFromList = () => {
    setOpenDrawerMultiAdd(true);
  };

  const handleAddLine = () => {
    let hasItemEmpty = false;
    Promise.all(
      dataSource.map((item) => {
        if (Object.keys(item).length <= 5) {
          hasItemEmpty = true;
        }
      })
    ).then(async () => {
      hasItemEmpty
        ? openNotificationWithIcon(
            "error",
            "Vui lòng chọn sản phẩm vào dòng rỗng !"
          )
        : await setDataSource([
            ...dataSource,
            { STT_REC0: Math.random().toString(36).substr(2, 9).toUpperCase() },
          ]);
    });
  };

  const handleDeleteLine = () => {
    const data = _.filter(dataSource, (x) => {
      if (x.STT_REC0 === idRowSelected) {
        return false;
      }
      return true;
    });
    setIdRowSelected("");
    setDataSource(data);
  };

  const hanleRowChange = async (params) => {
    let itemChange = dataSource.find((x) => x.STT_REC0 === params[0].key);
    const dataFilter = lookupSelectedItem.find(
      (x) => x.IDITEM === params[0].data.IDITEM
    );
    var data = await Object.assign(itemChange, dataFilter);
    if (!_.isUndefined(dataFilter)) {
      data.SO_LUONG = 1;
      data.TL_CK = 0;
      await calc(data);
    } else {
      await calc(itemChange);
    }
  };

  const selectionChanged = (params) => {
    setIdRowSelected(params[0]);
  };

  const onChangeTextTotal = async (e) => {
    setTotal({
      ...isTotal,
      [e.target.name]: e.target.value,
    });
  };

  const calc = (data) => {
    setTimeout(async () => {
      data.TIEN_NT2 = data.SO_LUONG * data.GIA_NT2 * ty_gia || 0;
      data.TIEN_CK_NT = (data.TIEN_NT2 * data.TL_CK) / 100 || 0;
      data.TT = data.TIEN_NT2 - data.TIEN_CK_NT;
      data.TT_NT = data.TT / ty_gia;
      setTotal({
        ...isTotal,
        amount: dataSource.reduce((a, b) => a + (b["TT"] || 0), 0),
      });
    }, 300);
  };

  const checkVisible = async (params) => {
    setOpenDrawerMultiAdd(params.isVisible);
    if (!_.isEmpty(params.isSelected)) {
      setDataSource(dataSource.concat(params.isSelected));
    }
  };

  return (
    <div className="listItem">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chi tiết" key="1">
          <ToolBar
            setAddItem={handleAddFromList}
            setStateOpen={handleAddLine}
            setDeleteLine={handleDeleteLine}
            disabled={isEdit}
          />
          <Divider />
          <DHSDataGrid
            column={isColumns}
            data={dataSource}
            dataKey={"STT_REC0"}
            selectionChanged={selectionChanged}
            hanleRowChange={hanleRowChange}
            disabled={isEdit}
          />
          <Row style={{ marginTop: 15 }}>
            <Col
              span={8}
              xs={24}
              xl={8}
              offset={window.screen.width > 1200 ? 16 : 0}
            >
              <Descriptions bordered size="small">
                <Descriptions.Item label="Tổng tiền :" span={3}>
                  {FormatMoney(isTotal.amount)}
                </Descriptions.Item>
                <Descriptions.Item label="% CK :" span={1.5}>
                  <Input
                    name="tl_ck"
                    onChange={onChangeTextTotal}
                    value={isTotal.tl_ck}
                    disabled={isEdit}
                    style={{ width: 100 }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Tiền CK :" span={1.5}>
                  {FormatMoney(isTotal.tien_ck_nt)}
                </Descriptions.Item>
                <Descriptions.Item label="% VAT :" span={1.5}>
                  <Input
                    name="ts_gtgt"
                    onChange={onChangeTextTotal}
                    value={isTotal.ts_gtgt}
                    disabled={isEdit}
                    style={{ width: 100 }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Thuế :" span={1.5}>
                  {FormatMoney(isTotal.thue_gtgt_nt)}
                </Descriptions.Item>
                <Descriptions.Item label="Thành tiền :" span={3}>
                  {FormatMoney(isTotal.tt)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      {openDrawerMultiAdd ? (
        <HandleMultiAdd
          isVisible={openDrawerMultiAdd}
          checkVisible={checkVisible}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
