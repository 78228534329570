import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import So1Create from "./so1Create/so1Create";
import So1Detail from "./so1Detail/so1Detail";
import So1Filter from "./so1Filter/so1Filter";
import ModalSelectRp from "./modalSelectRp/modalSelectRp";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actions from "../../../../redux/actions/Sales/salesOrder/salesOrder";
import * as actionsItem from "../../../../redux/actions/Category/Item/index";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import "./index.css";

const objectColumn = {
  SO_CT: { name: "Số đơn hàng", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  TEN_TRANG_THAI: { name: "Trạng thái", width: 100 },
  T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function SalesOrder(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const SO1State = useSelector((state) => state.SO1Reducer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isLoading, setLoading] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false);
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListSalesOrder({ ma_cty }));
    dispatch(actions.getListCustomer({ ma_cty }));
    dispatch(actions.getListDm({ ma_cty }));
    dispatch(actions.getListCurrency());
    dispatch(actionsItem.getListItem({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(SO1State.listSalesOrder);
    setData(SO1State.listSalesOrder);
    setFullData(SO1State.listSalesOrder);
    setLoading(false);
  }, [SO1State.listSalesOrder]);

  useEffect(() => {
    if (SO1State.checkDelete) {
      dispatch(actions.getListSalesOrder({ ma_cty }));
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [SO1State.checkDelete]);

  const handleOpenDrawer = () => {
    setOpenDrawerCreate(true);
  };

  const handleDelete = (params) => {
    if (params.TRANG_THAI === "1") {
      dispatch(actions.deleteASalesOrder({ modelM: params }));
      setLoading(true);
    } else {
      openNotificationWithIcon(
        "error",
        "Chỉ được xóa đơn đặt hàng chờ duyệt !"
      );
    }
  };

  const handleEdit = () => {
    if (!_.isEmpty(isMaster)) {
      if (isMaster.TRANG_THAI === "1") {
        dispatch(actions.getCTSalesOrder(isMaster));
        history.push("/soVch01Edit", {
          Master: isMaster,
        });
      } else {
        openNotificationWithIcon(
          "error",
          "Đơn đặt hàng này đã duyệt hoặc hủy !"
        );
      }
    }
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const checkVisible = (params) => {
    setOpenDrawerCreate(params);
    setOpenDrawerDetail(params);
    setOpenDrawerFilter(params);
    setOpenModalPrint(params);
  };

  const handlePrint = (params) => {
    dispatch(actions.getCTSalesOrder(params));
    setOpenModalPrint(true);
  };
  return (
    <div className="quotation" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Đơn đặt hàng"
          onBack={() => history.push("/Sale")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          setPrint={handlePrint}
          Master={isMaster}
        />
        <ModalSelectRp
          isModalVisible={openModalPrint}
          checkVisible={checkVisible}
          Master={isMaster}
          ctSalesOrder={toUpperPropertyNameByArray(
            useSelector((state) => state.SO1Reducer.ctSalesOrder)
          )}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListSalesOrder({ ma_cty }));
          }}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
        />
      </Spin>
      {openDrawerCreate ? (
        <So1Create
          isVisible={openDrawerCreate}
          checkVisible={checkVisible}
          history={history}
        />
      ) : (
        <></>
      )}
      {openDrawerDetail ? (
        <So1Detail
          isVisible={openDrawerDetail}
          checkVisible={checkVisible}
          Master={isMaster}
        />
      ) : (
        <></>
      )}
      {openDrawerFilter ? (
        <So1Filter
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
          approvalScr={false}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

// import React, { Component } from "react";
// import { Table, Button, Space, Spin } from "antd";
// import { connect } from "react-redux";
// import * as actions from "../../../../redux/actions/Sales/salesOrder/salesOrder";
// import { FormatColumnDHSTable, result } from "../../../controller/Format";
// import SO1Create from "./so1Create/so1Create";
// import SO1Filter from "./so1Filter/so1Filter";
// import SO1Detail from "./so1Detail/so1Detail";
// import "./index.css";
// import _ from "lodash";
// import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
// import ButtonDelete from "../../../../common/DHSControl/DHSButton/btnDelete";
// import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
// import { openNotificationWithIcon } from "../../../../common/notification/notification";
// import { PlusOutlined, EditOutlined, PrinterOutlined } from "@ant-design/icons";
// import ModalSelectRp from "./modalSelectRp/modalSelectRp";
// const objectColumn = {
//   SO_CT: { name: "Số đơn hàng", width: 100 },
//   NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
//   MA_KH: { name: "Mã khách hàng", width: 100 },
//   TEN_KH: { name: "Tên khách hàng", width: 300 },
//   MA_NT: { name: "Mã ngoại tệ", width: 100 },
//   TEN_TRANG_THAI: { name: "Trạng thái", width: 100 },
//   T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
// };

// class SalesOrder extends Component {
//   state = {
//     searchText: "",
//     searchedColumn: "",
//     loading: false,
//     data: [],
//     fullData: [],
//     customColumns: [],
//     openModalCreate: false,
//     openDrawerFilter: false,
//     openDrawerDetail: false,
//     isModalVisible: false,
//     rowId: "",
//     Master: {},
//   };
//   loadListSO1 = () => {
//     const { listSalesOrder } = this.props;
//     this.setState({ loading: true });
//     listSalesOrder.length = 0;
//     if (this.state.customColumns.length === 0) {
//       FormatColumnDHSTable(objectColumn);
//       this.setState({ customColumns: result });
//     }
//     this.props.getListSalesOrder({ ma_cty: this.props.ma_cty });
//   };
//   componentDidMount = () => {
//     this.loadListSO1();
//   };
//   componentDidUpdate = async (prevProps) => {
//     const { listSalesOrder, checkDelete } = this.props;
//     if (!_.isEqual(listSalesOrder, prevProps.listSalesOrder)) {
//       this.setState({
//         data: this.props.listSalesOrder,
//         fullData: this.props.listSalesOrder,
//         loading: false,
//       });
//     }
//     if (checkDelete !== prevProps.checkDelete && checkDelete) {
//       this.setState({ Master: {} });
//       this.loadListSO1();
//       this.props.clearCache();
//       openNotificationWithIcon("success", "Xóa thành công !");
//     }
//   };

//   confirmDelete = (params) => {
//     if (params.TRANG_THAI === "1") {
//       this.setState({ loading: true });
//       this.props.deleteASalesOrder({ modelM: params });
//     } else {
//       openNotificationWithIcon("error", "Chỉ được xóa đơn giá chờ duyệt !");
//     }
//   };

//   editRow = () => {
//     const { Master } = this.state;
//     if (!_.isEmpty(Master)) {
//       if (Master.TRANG_THAI === "1") {
//         this.props.getPHSalesOrder({
//           stt_rec: Master.STT_REC,
//           ma_cty: this.props.ma_cty,
//         });
//         this.props.history.push("/soVch01Edit", {
//           Master: Master,
//         });
//       } else {
//         openNotificationWithIcon(
//           "error",
//           "Đơn đặt hàng này đã duyệt hoặc hủy !"
//         );
//       }
//     } else {
//       openNotificationWithIcon("error", "Vui lòng chọn đơn hàng cần sửa !");
//     }
//   };

//   render() {
//     const {
//       data,
//       customColumns,
//       openModalCreate,
//       Master,
//       openDrawerDetail,
//       loading,
//       openDrawerFilter,
//       fullData,
//       isModalVisible,
//     } = this.state;
//     return (
//       <div className="salesOrder" style={{ height: "100%" }}>
//         <Spin spinning={loading}>
//           <Space className="topMenu">
//             <Button
//               onClick={() => this.setState({ openModalCreate: true })}
//               icon={<PlusOutlined />}
//             >
//               Thêm
//             </Button>
//             <Button onClick={this.editRow} icon={<EditOutlined />}>
//               Sửa
//             </Button>
//             <ButtonDelete
//               objDelele={Master}
//               confirmDelete={this.confirmDelete}
//             />
//             <Button
//               onClick={this.isPrint}
//               icon={<PrinterOutlined />}
//               disabled={_.isEmpty(Master)}
//               style={{ background: "white" }}
//             >
//               In
//             </Button>
//             <ModalSelectRp
//               isModalVisible={isModalVisible}
//               checkVisible={this.checkVisible}
//               Master={Master}
//               {...this.props}
//             />
//           </Space>
//           <DHSHanleFilter
//             getDataFilter={this.getDataFilter}
//             dataFilter={fullData}
//             reload={() => {
//               this.loadListSO1();
//             }}
//             openFiler={() => {
//               this.setState({ openDrawerFilter: true });
//             }}
//           />
//           <DHSTable
//             data={data}
//             customColumns={customColumns}
//             rowKey="SO_CT"
//             getDataRow={this.getDataRow}
//             doubleClickRow={() => {
//               this.setState({ openDrawerDetail: true });
//             }}
//           />
//           {openModalCreate ? (
//             <SO1Create
//               isVisible={openModalCreate}
//               checkVisible={this.checkVisible}
//               history={this.props.history}
//             />
//           ) : (
//             <></>
//           )}
//           {openDrawerDetail ? (
//             <SO1Detail
//               isVisible={openDrawerDetail}
//               checkVisible={this.checkVisible}
//               Master={Master}
//             />
//           ) : (
//             <></>
//           )}
//           {openDrawerFilter ? (
//             <SO1Filter
//               isVisible={openDrawerFilter}
//               checkVisible={this.checkVisible}
//               approvalSrc={false}
//             />
//           ) : (
//             <></>
//           )}
//         </Spin>
//       </div>
//     );
//   }
//   isPrint = () => {
//     this.props.getCTSalesOrder({
//       stt_rec: this.state.Master.STT_REC,
//       ma_cty: this.props.ma_cty,
//     });
//     this.setState({ loading: true, isModalVisible: true });
//   };
//   getDataFilter = (params) => {
//     this.setState({ data: params });
//   };

//   getDataRow = (params) => {
//     if (!_.isEmpty(params)) {
//       this.props.getCTSalesOrder({
//         stt_rec: params.STT_REC,
//         ma_cty: this.props.ma_cty,
//       });
//     }
//     this.setState({ Master: params });
//   };
//   checkVisible = (params) => {
//     this.setState({
//       openModalCreate: params,
//       loading: params,
//       openDrawerDetail: params,
//       openDrawerFilter: params,
//       isModalVisible: params,
//     });
//   };
// }

// const mapStateToProps = (state) => {
//   return {
//     ma_cty: state.LoginReducer.ma_cty,
//     listSalesOrder: state.SO1Reducer.listSalesOrder,
//     listCustomer: state.SO1Reducer.listCustomer,
//     customer: state.SO1Reducer.customer,
//     ctSalesOrder: state.SO1Reducer.ctSalesOrder,
//     checkDelete: state.SO1Reducer.checkDelete,
//     // listFiltPackingSlip: state.SO1Reducer.listFiltPackingSlip,
//   };
// };
// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     clearCache: () => {
//       dispatch(actions.clearCache());
//     },
//     getListSalesOrder: (ma_cty) => {
//       dispatch(actions.getListSalesOrder(ma_cty));
//     },
//     getPHSalesOrder: (detail) => {
//       dispatch(actions.getPHSalesOrder(detail));
//     },
//     getCTSalesOrder: (query) => {
//       dispatch(actions.getCTSalesOrder(query));
//     },
//     deleteASalesOrder: (master) => {
//       dispatch(actions.deleteASalesOrder(master));
//     },
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(SalesOrder);
