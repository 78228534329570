import React, { Component } from "react";
import _ from "lodash";
import { Spin } from "antd";
import { connect } from "react-redux";
import MetroMenu from "../../../common/DHSControl/DHSMetroMenu/DHSMetroMenu";
import imgLink from "../../../common/images/purchase.png";

const moduleid = "CS";
class CustomerServices extends Component {
  state = {
    loading: false,
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 500);
  };
  render() {
    const { loading } = this.state;
    const { listCategoryLv2, history } = this.props;
    return (
      <div>
        <Spin spinning={loading}>
          {!_.isEmpty(listCategoryLv2) ? (
            <MetroMenu image={imgLink} moduleid={moduleid} history={history} />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listCategoryLv2: state.LoginReducer.listCategoryLv2,
    listCategoryLv3: state.LoginReducer.listCategoryLv3,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerServices);
