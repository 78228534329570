import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Sales/salesOrder/salesOrder";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import ListAddress from "./listAddress/listAddress";
import _ from "lodash";
import * as actionSellPrice from "../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import "./index.css";
import moment from "moment";
const { TabPane } = Tabs;
export default function So1Edit(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isClickSave, setClickSave] = useState(false);
  const [isAddAddressToLine, setAddAddressToLine] = useState([]);
  const [lineChange, setLineChange] = useState(false);
  const [Address, setAddress] = useState([]);
  const [listDelectedItemID, setListDelectedItemID] = useState([]);
  const [ty_gia, setTy_gia] = useState(
    history.location.state ? history.location.state.Master.TY_GIA : 1
  );
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const newEdit = useSelector((state) => state.SO1Reducer.newEdit);
  const Master = history.location.state ? history.location.state.Master : {};
  const contactSalesOrder = useSelector(
    (state) => state.SO1Reducer.contactSalesOrder
  );
  useEffect(() => {
    // dispatch(
    //   actionSellPrice.getLookupSelectedItem({
    //     ma_cty,
    //     idcustomer: "",
    //     ma_nt: "VND",
    //     ngay_ct: moment(Master.NGAY_CT).add(7, "hours"),
    //   })
    // );
    // dispatch(
    //   actionSellPrice.getLookupSelectedItem2({
    //     ma_cty,
    //     idcustomer: "",
    //     ma_nt: "VND",
    //     ngay_ct: moment(Master.NGAY_CT).add(7, "hours"),
    //   })
    // );
    dispatch(
      actions.getContactSalesOrder({
        stt_rec: Master.STT_REC,
        ma_cty,
      })
    );
  }, []);

  useEffect(() => {
    if (!_.isEmpty(contactSalesOrder)) {
      setAddress(contactSalesOrder);
    }
  }, [contactSalesOrder]);

  useEffect(() => {
    return () => {
      window.onload = () => {
        history.push("/SalesOrder");
      };
    };
  });

  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(newEdit)) {
      openNotificationWithIcon("success", "Sửa thành công !");
      setEdit(true);
    }
  }, [newEdit]);

  const handleSave = () => {
    setClickSave(true);
  };

  const handleBack = () => {
    dispatch(actions.clearCache());
    history.push("/SalesOrder");
  };

  const handleEdit = () => {
    setEdit(false);
  };

  const getLine = (params) => {
    const { data, total } = params;
    Master.T_SO_LUONG = data.reduce((a, b) => a + (b["SO_LUONG"] || 0), 0);
    Master.T_TIEN2 = data.reduce((a, b) => a + (b["TIEN_NT2"] || 0), 0);
    Master.T_TIEN_NT2 = data.reduce(
      (a, b) => a + (b["TIEN_NT2"] || 0) / ty_gia,
      0
    );
    Master.TL_CK = parseInt(total.tl_ck);
    Master.T_CK_NT = total.tien_ck_nt / ty_gia;
    Master.T_CK = total.tien_ck_nt;
    Master.TS_GTGT = parseInt(total.ts_gtgt);
    Master.T_THUE_NT = total.thue_gtgt_nt / ty_gia;
    Master.T_THUE = total.thue_gtgt_nt;
    Master.T_TT = total.tt;
    Master.T_TT_NT = total.tt / ty_gia;
    if (deleteItemEmpty(data)) {
      if (!isLoading) {
        setLoading(true);
        dispatch(
          actions.updateSalesOrder({
            modelM: Master,
            arrayCt: data,
            arrayAddress: Address,
          })
        );
      }
    }
  };

  const deleteItemEmpty = (array) => {
    var check = true;
    setClickSave(false);
    if (array.length > 0) {
      array.map((item, index) => {
        if (_.isNil(item.IDITEM)) {
          array.splice(index, 1);
        } else if (item.SO_LUONG <= 0 || item.GIA_NT2 <= 0) {
          openNotificationWithIcon(
            "error",
            `Vui lòng nhập số lượng hoặc đơn giá vật tư ${item.TEN_VT}`
          );
          check = false;
        }
      });
      return check;
    }
  };
  const getAddressForItem = (params) => {
    setAddress(params);
    setListDelectedItemID([]);
  };
  const clickTabs = (value) => {
    if (value === "2") {
      setLineChange(!lineChange);
    }
  };

  const getDeletectedItemID = async (params) => {
    setListDelectedItemID([...listDelectedItemID, params]);
  };
  const getItemAddress = (params) => {
    setAddAddressToLine(
      _.filter(params, (item) => {
        if (Object.keys(item).length > 5) {
          item.SO_LUONG_DELIVERY = item.SO_LUONG - (item.SL_DLV_PLAN || 0);
          return true;
        }
        return false;
      })
    );
  };
  return (
    <div className="so1-edit">
      <Spin spinning={isLoading}>
        <div>
          <ToolBar
            setSave={!isEdit ? handleSave : undefined}
            setReEdit={isEdit ? handleEdit : undefined}
            setBack={handleBack}
          />
          <div style={{ paddingLeft: 15 }}>
            <h4 style={{ margin: 0, color: "#1890FF" }}>ĐƠN ĐẶT HÀNG</h4>
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
                {Master.SO_CT} : {Master.TEN_KH}
              </h2>
            </div>
          </div>
          <General
            Master={Master}
            isEdit={isEdit}
            getTyGia={(ty_gia) => setTy_gia(ty_gia)}
          />
          <Tabs defaultActiveKey="1" onTabClick={clickTabs}>
            <TabPane tab="Thông tin đơn đặt hàng" key="1">
              <ListItem
                Master={Master}
                isEdit={isEdit}
                ty_gia={ty_gia}
                isClickSave={isClickSave}
                getLine={getLine}
                getItemAddress={getItemAddress}
                getDeletectedItemID={getDeletectedItemID}
              />
            </TabPane>
            <TabPane tab="Thông tin giao hàng" key="2">
              <ListAddress
                dataSource={isAddAddressToLine}
                getAddressForItem={getAddressForItem}
                lineChange={lineChange}
                listDelectedItemID={listDelectedItemID}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  );
}

// import React, { Component } from "react";
// import { Tabs, Spin, Space, Button, Popconfirm } from "antd";
// import General from "./general/general";
// import ListItem from "./listItem/listItem";
// import "./index.css";
// import ListAddress from "./listAddress/listAddress";
// import { connect } from "react-redux";
// import * as actions from "../../../../../redux/actions/Sales/salesOrder/salesOrder";
// import * as actionsSellPrice from "../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
// import { openNotificationWithIcon } from "../../../../../common/notification/notification";
// import moment from "moment";
// import {
//   CheckOutlined,
//   EditOutlined,
//   DeleteOutlined,
//   RollbackOutlined,
// } from "@ant-design/icons";
// import _ from "lodash";
// const { TabPane } = Tabs;
// class SO1Edit extends Component {
//   state = {
//     loading: false,
//     Master: this.props.history.location.state
//       ? this.props.history.location.state.Master
//       : {},
//     Line: {},
//     Address: [],
//     isAddAddressToLine: [],
//     listDelectedItemID: [],
//     ty_gia: this.props.history.location.state
//       ? this.props.history.location.state.Master.TY_GIA
//       : 1,
//     editDone: false,
//     lineChange: false,
//   };

//   componentDidMount = () => {
//     if (this.props.history.location.state) {
//       const { Master } = this.props.history.location.state;
//       this.props.getListNeed({ ma_cty: this.props.ma_cty });
//       this.props.getCustomer({
//         ma_cty: this.props.ma_cty,
//         idCustomer: Master.IDCUSTOMER,
//       });
//       this.props.getCTSalesOrder({
//         stt_rec: Master.STT_REC,
//         ma_cty: this.props.ma_cty,
//       });
//       this.props.getContactSalesOrder({
//         stt_rec: Master.STT_REC,
//         ma_cty: this.props.ma_cty,
//       });
//       this.props.lookupSelectedItem({
//         ma_cty: this.props.ma_cty,
//         idcustomer: "",
//         ma_nt: "VND",
//         ngay_ct: moment(Master.NGAY_CT).add(7, "hours"),
//       });
//     } else {
//       this.props.history.push("/SalesOrder");
//     }
//   };

//   componentDidUpdate = (prevProps) => {
//     const {
//       newEdit,
//       checkDelete,
//       contactSalesOrder,
//       phSalesOrder,
//     } = this.props;
//     if (!_.isEqual(newEdit, prevProps.newEdit)) {
//       if (newEdit !== null) {
//         this.props.getCTSalesOrder({
//           stt_rec: this.state.Master.STT_REC,
//           ma_cty: this.props.ma_cty,
//         });
//         this.props.getPHSalesOrder({
//           stt_rec: this.state.Master.STT_REC,
//           ma_cty: this.props.ma_cty,
//         });
//         this.setState({ loading: false, editDone: true });
//         this.props.clearSTT();
//         openNotificationWithIcon("success", "Sửa thành công !");
//       }
//     }
//     if (!_.isEqual(phSalesOrder, prevProps.phSalesOrder)) {
//       this.setState({ loading: false });
//     }
//     if (checkDelete !== prevProps.checkDelete && checkDelete) {
//       this.props.history.push("/SalesOrder");
//       openNotificationWithIcon("success", "Xóa thành công !");
//     }
//     if (!_.isEqual(contactSalesOrder, prevProps.contactSalesOrder)) {
//       this.setState({
//         Address: contactSalesOrder,
//       });
//     }
//   };

//   deleteItemEmpty = (array) => {
//     var check = true;
//     if (array.length > 0) {
//       array.map((item, index) => {
//         if (_.isNil(item.IDITEM)) {
//           array.splice(index, 1);
//         } else if (item.SO_LUONG <= 0 || item.GIA_NT2 <= 0) {
//           openNotificationWithIcon(
//             "error",
//             `Vui lòng nhập số lượng hoặc đơn giá vật tư ${item.TEN_VT}`
//           );
//           check = false;
//         }
//       });
//       return check;
//     }
//   };

//   confirm = async () => {
//     if (this.deleteItemEmpty(this.state.Line)) {
//       if (!this.state.loading) {
//         this.setState({ loading: true });
//         this.props.updateSalesOrder({
//           modelM: this.state.Master,
//           arrayCt: this.state.Line,
//           arrayAddress: this.state.Address,
//         });
//       }
//     }
//   };

//   confirmDelete(Master) {
//     this.setState({ loading: true });
//     this.props.deleteASalesOrder({ modelM: Master });
//   }

//   componentWillUnmount = () => {
//     this.setState({ loading: false });
//     this.props.clearCache();
//   };

//   render() {
//     const {
//       loading,
//       ty_gia,
//       editDone,
//       isAddAddressToLine,
//       listDelectedItemID,
//     } = this.state;
//     const { customer, ctSalesOrder, phSalesOrder } = this.props;
//     const { Master } = this.props.history.location.state
//       ? this.props.history.location.state
//       : "";
//     return (
//       <div className="so1-edit">
//         {Master ? (
//           <div>
//             <Spin spinning={loading}>
//               <Space className="topMenu">
//                 <Button
//                   onClick={this.confirm}
//                   style={{ display: editDone ? "none" : "block" }}
//                   icon={<CheckOutlined />}
//                 >
//                   Lưu
//                 </Button>
//                 <Button
//                   style={{ display: editDone ? "block" : "none" }}
//                   onClick={() => {
//                     this.setState({ editDone: false });
//                   }}
//                   icon={<EditOutlined />}
//                 >
//                   Sửa
//                 </Button>
//                 <Popconfirm
//                   title="Bạn muốn xóa đơn hàng ?"
//                   onConfirm={() => this.confirmDelete(Master)}
//                   okText="Đồng ý"
//                   cancelText="Hủy"
//                   disabled={Object.keys(Master).length == 0 ? true : false}
//                 >
//                   <Button icon={<DeleteOutlined />}>Xóa</Button>
//                 </Popconfirm>
//                 <Button
//                   onClick={() => {
//                     this.props.history.push("/SalesOrder");
//                   }}
//                   icon={<RollbackOutlined />}
//                 >
//                   Trở về
//                 </Button>
//               </Space>
//               <div style={{ paddingLeft: 15 }}>
//                 <h4 style={{ margin: 0, color: "#1890FF" }}>ĐƠN ĐẶT HÀNG</h4>
//                 <div style={{ display: "flex", flexWrap: "nowrap" }}>
//                   <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
//                     {Master.SO_CT} : {customer.ten_kh}
//                   </h2>
//                 </div>
//               </div>
//               <General
//                 Master={Master}
//                 customer={customer}
//                 getMaster={this.getMaster}
//                 editDone={editDone}
//               />
//               <Tabs defaultActiveKey="1" onTabClick={this.clickTabs}>
//                 <TabPane tab="Thông tin hàng hóa" key="1">
//                   <ListItem
//                     Master={_.isEmpty(phSalesOrder) ? Master : phSalesOrder}
//                     getLine={this.getLine}
//                     dsCT={ctSalesOrder}
//                     ty_gia={ty_gia}
//                     editDone={editDone}
//                     getDeletectedItemID={this.getDeletectedItemID}
//                   />
//                 </TabPane>
//                 <TabPane tab="Thông tin giao hàng" key="2">
//                   <ListAddress
//                     dataSource={isAddAddressToLine}
//                     getAddressForItem={this.getAddressForItem}
//                     lineChange={this.state.lineChange}
//                     listDelectedItemID={listDelectedItemID}
//                   />
//                 </TabPane>
//               </Tabs>
//             </Spin>
//           </div>
//         ) : (
//           <></>
//         )}
//       </div>
//     );
//   }

//   getMaster = (params) => {
//     const { Master } = this.state;
//     Master.IDCUSTOMER = params.IDcustomer;
//     Master.MA_KH = params.ma_kh;
//     Master.NGUOI_GD = params.contact;
//     Master.TY_GIA = params.ty_gia;
//     Master.MA_NT = params.ma_nt;
//     Master.DIEN_GIAI = params.ghi_chu;
//     this.setState({ ty_gia: params.ty_gia });
//   };

//   getLine = (params) => {
//     const { Master } = this.state;
//     Master.T_SO_LUONG = 0;
//     Master.T_TIEN2 = 0;
//     Master.T_TIEN_NT2 = 0;
//     Master.T_TT = 0;
//     Master.T_TT_NT = 0;
//     if (params.dataSource.length > 0) {
//       params.dataSource.filter(async (x) => {
//         if (Object.keys(x).length > 1) {
//           Master.T_SO_LUONG += parseInt(x.SO_LUONG);
//           x.SO_LUONG_DELIVERY = x.SO_LUONG - (x.SL_DLV_PLAN || 0);
//           x.TT_NT = x.TT / this.state.ty_gia;
//           Master.T_TIEN2 += x.TIEN_NT2;
//           Master.T_TIEN_NT2 += x.TIEN_NT2 / this.state.ty_gia;
//         }
//       });
//       Master.TL_CK = parseInt(params.tl_ck);
//       Master.T_CK_NT = params.tien_ck_nt / this.state.ty_gia;
//       Master.T_CK = params.tien_ck_nt;
//       Master.TS_GTGT = parseInt(params.ts_gtgt);
//       Master.T_THUE_NT = params.thue_gtgt_nt / this.state.ty_gia;
//       Master.T_THUE = params.thue_gtgt_nt;
//       Master.T_TT = params.tt;
//       Master.T_TT_NT = params.tt / this.state.ty_gia;
//     }
//     this.setState({
//       Line: params.dataSource,
//       isAddAddressToLine: _.filter(params.dataSource, (item) => {
//         return Object.keys(item).length > 3;
//       }),
//     });
//   };

//   getAddressForItem = (params) => {
//     this.setState({ Address: params, listDelectedItemID: [] });
//   };

//   clickTabs = (value) => {
//     if (value === "2") {
//       this.setState({ lineChange: !this.state.lineChange });
//     }
//   };

//   getDeletectedItemID = async (params) => {
//     this.setState({
//       listDelectedItemID: [...this.state.listDelectedItemID, params],
//     });
//   };
// }

// const mapStateToProps = (state) => {
//   return {
//     ma_cty: state.LoginReducer.ma_cty,
//     newEdit: state.SO1Reducer.newEdit,
//     customer: state.SO1Reducer.customer,
//     listItem: state.ItemReducer.listItem,
//     ctSalesOrder: state.SO1Reducer.ctSalesOrder,
//     phSalesOrder: state.SO1Reducer.phSalesOrder,
//     checkDelete: state.SO1Reducer.checkDelete,
//     lstCtChooseFromQU: state.SO1Reducer.lstCtChooseFromQU,
//     contactSalesOrder: state.SO1Reducer.contactSalesOrder,
//   };
// };
// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     updateSalesOrder: (query) => {
//       dispatch(actions.updateSalesOrder(query));
//     },
//     getCustomer: (query) => {
//       dispatch(actions.getCustomer(query));
//     },
//     getCTSalesOrder: (detail) => {
//       dispatch(actions.getCTSalesOrder(detail));
//     },
//     getContactSalesOrder: (query) => {
//       dispatch(actions.getContactSalesOrder(query));
//     },
//     getPHSalesOrder: (detail) => {
//       dispatch(actions.getPHSalesOrder(detail));
//     },
//     getListNeed: (ma_cty) => {
//       dispatch(actions.getListStatus());
//       // dispatch(actionsLstColor.getListItemColor(ma_cty));
//       // dispatch(actionsLstSize.getListItemSize(ma_cty));
//     },
//     deleteASalesOrder: (master) => {
//       dispatch(actions.deleteASalesOrder(master));
//     },
//     clearCache: () => {
//       dispatch(actions.clearCache());
//     },
//     clearSTT: () => {
//       dispatch(actions.clearSTT());
//     },
//     lookupSelectedItem: (query) => {
//       dispatch(actionsSellPrice.getLookupSelectedItem(query));
//     },
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(SO1Edit);
