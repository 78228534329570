import React from "react";
import { Avatar } from "antd";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Selection,
  Scrolling,
  Lookup,
  RowDragging,
  KeyboardNavigation,
  MasterDetail,
  Export,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import _ from "lodash";
import { Template } from "devextreme-react/core/template";

import "./index.css";
// import { jsPDF } from "jspdf";
// import "jspdf-autotable";
// import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
const cellRender = (data) => {
  return (
    <Avatar
      shape="square"
      src={"data:image/png;base64," + data.value}
      size={56}
    />
  );
};
const loadCol = (lstColumn) => {
  var result = null;
  if (lstColumn.length > 0) {
    result = lstColumn.map((item, index) => {
      return (
        <Column
          dataField={item.dataField}
          key={index}
          caption={item.caption}
          allowEditing={
            item.type == 0 ||
            (!_.isUndefined(item.allowEditing) && !item.allowEditing)
              ? false
              : true
          }
          visible={item.visible}
          editCellComponent={item.dropDown}
          format={
            item.format === "Money" ? { minimumSignificantDigits: 3 } : ""
          }
          dataType={item.format === "date" ? "date" : ""}
          width={item.width}
          cellRender={item.format === "image" ? cellRender : ""}
          onChangesChange={(e) => console.log(e)}
        >
          <Scrolling mode="infinite" />
          {item.type == 2 ? (
            <Lookup
              dataSource={item.dataSource.data}
              valueExpr={item.dataSource.valueExpr}
              displayExpr={item.dataSource.displayExpr}
              render={item.dataSource.render}
            ></Lookup>
          ) : (
            ""
          )}
        </Column>
      );
    });
  }
  return result;
};
class DHSDataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.dataGrid = null;
  }
  state = {
    selectedItemKeys: [],
  };
  selectionChanged = (data) => {
    this.props.selectionChanged(data.selectedRowKeys);
  };
  valueChange = (data) => {
    if (data.length > 0 && !_.isEmpty(data[0].data)) {
      this.props.hanleRowChange(data);
    }
    if (data.length > 0 && data[0].type == "remove") {
      this.props.hanleRowChange(data);
    }
  };
  // onCellClick = (data) => {
  //   console.log(data);
  // };
  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.data, prevProps.data)) {
      return true;
    }
  };
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "print",
        onClick: this.printDataGrid.bind(this),
        visible: !_.isUndefined(this.props.onPrintChange),
      },
    });
  }
  printDataGrid() {
    this.props.onPrintChange(true);
  }
  render() {
    const {
      column,
      data,
      dataKey,
      disabled,
      enableMD,
      componentMD,
      exportExcel,
      showColumnLines = true,
      allowDeleting = false,
      focusNewRow,
      showColumnHeaders = true,
      onPrintChange,
    } = this.props;
    return (
      <div id="data-grid-demo">
        <DataGrid
          id="gridContainer"
          dataSource={data}
          keyExpr={dataKey}
          selection={{ mode: "single" }}
          hoverStateEnabled={true}
          showRowLines={false}
          onSelectionChanged={this.selectionChanged}
          disabled={disabled}
          columnAutoWidth={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          showColumnLines={showColumnLines}
          selectedRowKeys={focusNewRow}
          showColumnHeaders={showColumnHeaders}
          onToolbarPreparing={this.onToolbarPreparing}
        >
          <KeyboardNavigation
            editOnKeyPress={true}
            enterKeyAction={"moveFocus"}
            enterKeyDirection={"row"}
          />
          <Scrolling mode="infinite" />
          <Paging enabled={false} />
          <Editing
            mode="cell"
            allowUpdating={true}
            onChangesChange={this.valueChange}
            allowDeleting={allowDeleting}
            useIcons={true}
            texts={{
              confirmDeleteMessage: "Bạn có chắc chắn muốn xóa ?",
            }}
          />
          <MasterDetail enabled={enableMD} component={componentMD} />
          <Export enabled={exportExcel} fileName="Report" />
          <Column type="buttons" width={30}>
            <Button name="delete" />
          </Column>
          {loadCol(column)}
        </DataGrid>
      </div>
    );
  }
}

export default DHSDataGrid;
