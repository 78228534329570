import React, { useEffect, useState } from "react";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import { Drawer, Divider, Tabs, Spin } from "antd";
import { widthDrawer } from "../../index";
import "./index.css";

const { TabPane } = Tabs;

export default function IN1Detail(props) {
  const { isVisible, checkVisible, Master } = props;

  const onClose = () => {
    checkVisible(false);
  };
  return (
    <Drawer
      title={`Chi tiết phiếu xuất kho - ${Master.SO_CT}`}
      visible={isVisible}
      width={widthDrawer}
      onClose={onClose}
    >
      <div className="IN2-detail">
        <General Master={Master} />
        <Divider style={{ margin: 0 }} />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Thông tin hàng hóa" key="1">
            <ListItem Master={Master} />
          </TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
}
