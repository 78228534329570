import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListApprovalSalesOrder = (ma_cty) => (dispatch, getState) => {
  callApi("api/SoApprovalSalesOrder/ListApprovalSalesOrder", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_APPROVAL_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const approvalSalesOrder = (query) => (dispatch, getState) => {
  callApi("api/SoApprovalSalesOrder/Approval", "POST", query)
    .then((res) =>
      dispatch({
        type: types.APPROVAL_SALESORDER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const rejectSalesOrder = (query) => (dispatch, getState) => {
  callApi("api/SoApprovalSalesOrder/Reject", "POST", query)
    .then((res) =>
      dispatch({
        type: types.REJECT_SALESORDER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_APPROVAL_SO1,
  };
};
