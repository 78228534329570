import React, { Component } from "react";
import { Collapse, Row, Col, Form, Spin } from "antd";
import { DHSInput } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
const { Panel } = Collapse;

export default class Contact extends Component {
  state = {
    loading: false,
    noneEdit: false,
  };

  handleChange = (e) => {
    this.setState({});
    this.props.Master[e.name] = e.value;
  };
  render() {
    const {
      NGUOI_GD,
      TEL,
      EXTENSION,
      MOBILE,
      FAX,
      EMAIL,
      HOME_PAGE,
    } = this.props.Master;
    const { editDone } = this.props;
    return (
      <div className="csr-general">
        <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
          <Panel header="Liên hệ" key="1">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12} xs={12} xl={12}>
                <Form layout="vertical">
                  <DHSInput
                    label="Người liên hệ"
                    name="NGUOI_GD"
                    value={NGUOI_GD}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Điện thoại"
                    name="TEL"
                    value={TEL}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Số nội bộ"
                    name="EXTENSION"
                    value={EXTENSION}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                </Form>
              </Col>
              <Col className="gutter-row" span={12} xs={12} xl={12}>
                <Form layout="vertical">
                  <DHSInput
                    label="Di động"
                    value={MOBILE}
                    name="MOBILE"
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Fax"
                    value={FAX}
                    name="FAX"
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Email"
                    value={EMAIL}
                    name="EMAIL"
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Website"
                    value={HOME_PAGE}
                    name="HOME_PAGE"
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                </Form>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
