import React, { useState, useEffect } from "react";
import "./index.css";
import Grantt from "../../ChartType/ganttChart";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/Manufacturing/MasterPlanning/index";
import * as actionsManufacturing from "../../../../../redux/actions/Manufacturing/Manufacturing";
import { granttScaleType } from "../../../../controller/DataSample";
import moment from "moment";
import { Row, Col } from "antd";
import DateBox from "devextreme-react/date-box";
import { Button } from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import { FormatDateTime } from "../../../../controller/Format";
import _ from "lodash";
import { periodData } from "../../../../controller/DataSample";
import { onPeriodChange } from "../../../../controller/Format";
import { DHSSelectOpt } from "../../../../../common/DHSControl/DHSForm/DHSFormItem";

export default function MachineScheduleGeneral() {
  const dispatch = useDispatch();
  const [objFilter, setObjFilter] = useState({
    timeFrom: moment().startOf("years").utcOffset(7),
    timeTo: moment().endOf("years").utcOffset(7),
    period: "cn",
  });
  const [scaleType, setScaleType] = useState("days");
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listPlanningWorkOrder = useSelector(
    (state) => state.MasterPlanningReducer.listPlanningWorkOrder
  );
  useEffect(() => {
    dispatch(
      actions.getPLGanttDeadlinebyWorkOrder({
        ma_cty,
        ngay_ct1: moment(objFilter.timeFrom).add(7, "hours"),
        ngay_ct2: moment(objFilter.timeTo).add(7, "hours"),
      })
    );
    dispatch(actionsManufacturing.GetListMachines({ ma_cty }));
  }, []);
  useEffect(() => {
    if (!_.isEmpty(listPlanningWorkOrder)) {
      listPlanningWorkOrder.map((item, index) => {
        item.STARTDATE = FormatDateTime(item.STARTDATE);
        item.FINISHDATE = FormatDateTime(item.FINISHDATE);
        if (item.PLANLEVEL === -2) {
          item.TASKNAME = this.props.companyName;
        }
        if (_.isEqual(item.TASKID, item.PARENTID)) {
          item.PARENTID = 0;
        }
      });
    }
  }, [listPlanningWorkOrder]);

  const onClickSearch = () => {
    console.log(objFilter);
    // dispatch(
    //   actions.getPLGanttDeadlinebyWorkOrder({
    //     ma_cty,
    //     ngay_ct1: moment(objFilter.timeFrom).add(7, "hours"),
    //     ngay_ct2: moment(objFilter.timeTo).add(7, "hours"),
    //   })
    // );
  };
  const onValueChanged = (name) => (e) => {
    setObjFilter({ ...objFilter, [name]: e.value });
  };
  const onSelectionScaleChanged = (e) => {
    console.log(e);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params, moment().weeks());
    setObjFilter({
      period: result.period,
      timeTo: result.toDate,
      timeFrom: result.formDate,
    });
  };
  return (
    <div className="machineSchedule">
      <div
        style={{
          fontSize: 24,
          padding: 10,
          color: "#1890ff",
          background: "white",
        }}
      >
        Kế hoạch sản xuất tổng
      </div>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ margin: "10px 0px", background: "white" }}
      >
        <Col span={24} xs={24} xl={4}>
          <div className="textTime">Scale type</div>
          <div>
            <SelectBox
              items={granttScaleType}
              onSelectionChanged={onSelectionScaleChanged}
              valueExpr="type"
              displayExpr="name"
              defaultValue={"days"}
            />
          </div>
        </Col>
        <Col span={24} xs={24} xl={4}>
          <div className="textTime">Lọc</div>
          <div>
            <DHSSelectOpt
              placeHolder="Chọn điều kiện lọc"
              array={periodData}
              defaultValue={objFilter.period}
              valueOpt="value"
              valueRender="name"
              onChange={onHandleFilter}
            />
          </div>
        </Col>
        <Col span={24} xs={24} xl={4}>
          <div className="textTime">Thời gian từ</div>
          <div>
            <DateBox
              value={objFilter.timeFrom}
              defaultValue={objFilter.timeFrom}
              type="datetime"
              onValueChanged={onValueChanged("timeFrom")}
              displayFormat={"dd/MM/yyyy HH:mm"}
            />
          </div>
        </Col>
        <Col span={24} xs={24} xl={4}>
          <div className="textTime">đến</div>
          <div>
            <DateBox
              value={objFilter.timeTo}
              defaultValue={objFilter.timeTo}
              type="datetime"
              min={objFilter.timeFrom}
              onValueChanged={onValueChanged("timeTo")}
              displayFormat={"dd/MM/yyyy HH:mm"}
            />
          </div>
        </Col>
        <Col span={8} xs={24} xl={4}>
          <Button
            style={{ marginTop: 22 }}
            width={120}
            text="Tìm kiếm"
            type="normal"
            stylingMode="contained"
            onClick={onClickSearch}
          />
        </Col>
      </Row>
      <Grantt dataSource={listPlanningWorkOrder} scaleType={scaleType} />
    </div>
  );
}
