import "./index.css";
import React, { Component } from "react";
import { Layout, Menu } from "antd";
import image from "../../../common/images/logodhsoft.png";
import imgUser from "../../../common/images/user.png";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  BulbOutlined,
  BellOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import routes from "../../../common/routes/index";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../common/notification/notification";
import * as actions from "../../../redux/actions/Login/index";
import FontAwesome from "react-fontawesome";
const { SubMenu } = Menu;
const { Header, Sider, Content, Footer } = Layout;

class Main extends Component {
  state = {
    collapsed: true,
    listCategoryLv1: [],
  };

  componentDidMount = async () => {
    this.props.getCategory({ USERNAME: this.props.userName });
    if (window.location.pathname === "/") {
      this.props.history.push("/DashBoard");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!_.isEqual(this.props.listCategoryLv1, prevProps.listCategoryLv1)) {
      this.setState({ listCategoryLv1: this.props.listCategoryLv1 });
    }
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onLogout = () => {
    this.props.destroySession();
    localStorage.removeItem("SessionLogin");
    openNotificationWithIcon(
      "success",
      "Thông báo đăng xuất",
      "Đăng xuất thành công"
    );
    this.props.history.push("/login");
  };
  render() {
    const { listCategoryLv1 } = this.state;
    let objCompany = JSON.parse(localStorage.getItem("SessionLogin"));
    return (
      <Route>
        <Layout>
          <Header
            className="site-layout-background header"
            style={{ padding: 0 }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}
              <h3 style={{ color: "white", margin: 0 }}>
                {objCompany.companyName}
              </h3>
            </div>
            <div
              style={{ marginRight: 20, display: "flex", alignItems: "center" }}
            >
              <h3 style={{ color: "white", margin: 0 }}>
                Hotline: 0948 473 967
              </h3>
              <BulbOutlined
                style={{ fontSize: 18, marginRight: 10, marginLeft: 10 }}
              />
              <BellOutlined style={{ fontSize: 18, marginRight: 10 }} />
              <img
                src={imgUser}
                style={{
                  borderRadius: 50,
                  height: 30,
                  width: 30,
                  marginBottom: 5,
                }}
              />
            </div>
          </Header>
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              breakpoint="lg"
              collapsedWidth="0"
            >
              <Menu
                mode="inline"
                style={{ height: window.innerHeight }}
                theme="dark"
              >
                {this.renderMenu(listCategoryLv1)}
                <Menu.Item
                  className="logout"
                  onClick={this.onLogout}
                  key="3"
                  icon={<LogoutOutlined />}
                >
                  Đăng xuất
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout className="site-layout">
              <Content
                className="site-layout-background"
                style={{
                  margin: "24px 16px",
                  padding: 24,
                  minHeight: 1500,
                }}
              >
                <Switch>
                  {routes.length > 0
                    ? routes.map((route, index) => {
                        return (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.main}
                          />
                        );
                      })
                    : null}
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </Route>
    );
  }
  renderMenu = (listCategory) => {
    var result = null;
    if (listCategory.length > 0) {
      result = listCategory.map((item) => {
        return (
          <Menu.Item
            id="subMenu1"
            key={item.menuid}
            icon={
              <FontAwesome
                name={item.iconweb}
                style={{ fontSize: 16, marginRight: 10 }}
              />
            }
            title={item.bar}
          >
            <Link to={"/" + item.linkmobile}>
              {this.state.collapsed ? "" : item.bar}
            </Link>
          </Menu.Item>
        );
      });
    }
    return result;
  };
}

const mapStateToProps = (state) => {
  return {
    userName: state.LoginReducer.userName,
    listCategoryLv1: state.LoginReducer.listCategoryLv1,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    destroySession: () => {
      dispatch(actions.destroySession());
    },
    getCategory: (userName) => {
      dispatch(actions.getCategory(userName));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
