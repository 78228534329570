import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Sales/salesInvoice/salesInvoice";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import _ from "lodash";
import * as actionSellPrice from "../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import PropTypes from "prop-types";
import "./index.css";
import moment from "moment";
const { TabPane } = Tabs;
export default function So4Edit(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isClickSave, setClickSave] = useState(false);
  const [ty_gia, setTy_gia] = useState(
    history.location.state ? history.location.state.Master.TY_GIA : 1
  );
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const newEdit = useSelector((state) => state.SO3Reducer.newEdit);
  const Master = history.location.state ? history.location.state.Master : {};
  useEffect(() => {
    dispatch(
      actionSellPrice.getLookupSelectedItem({
        ma_cty,
        idcustomer: "",
        ma_nt: "VND",
        ngay_ct: moment(Master.NGAY_CT).add(7, "hours"),
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      window.onload = () => {
        history.push("/SalesInvoice");
      };
    };
  });

  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(newEdit)) {
      openNotificationWithIcon("success", "Sửa thành công !");
      setEdit(true);
    }
  }, [newEdit]);

  const handleSave = () => {
    setClickSave(true);
  };

  const handleBack = () => {
    dispatch(actions.clearCache());
    history.push("/SalesInvoice");
  };

  const handleEdit = () => {
    setEdit(false);
  };

  const getLine = (params) => {
    const { data, total } = params;
    Master.T_SO_LUONG = data.reduce((a, b) => a + (b["SO_LUONG"] || 0), 0);
    Master.T_TIEN2 = data.reduce((a, b) => a + (b["TIEN_NT2"] || 0), 0);
    Master.T_TIEN_NT2 = data.reduce(
      (a, b) => a + (b["TIEN_NT2"] || 0) / ty_gia,
      0
    );
    Master.TL_CK = parseInt(total.tl_ck);
    Master.T_CK_NT = total.tien_ck_nt / ty_gia;
    Master.T_CK = total.tien_ck_nt;
    Master.TS_GTGT = parseInt(total.ts_gtgt);
    Master.T_THUE_NT = total.thue_gtgt_nt / ty_gia;
    Master.T_THUE = total.thue_gtgt_nt;
    Master.T_TT = total.tt;
    Master.T_TT_NT = total.tt / ty_gia;
    if (deleteItemEmpty(data)) {
      if (!isLoading) {
        setLoading(true);
        dispatch(
          actions.updateSalesInvoice({
            modelM: Master,
            arrayCt: data,
          })
        );
      }
    }
  };

  const deleteItemEmpty = (array) => {
    var check = true;
    setClickSave(false);
    if (array.length > 0) {
      array.map((item, index) => {
        if (_.isNil(item.IDITEM)) {
          array.splice(index, 1);
        } else if (item.SO_LUONG <= 0 || item.GIA_NT2 <= 0) {
          openNotificationWithIcon(
            "error",
            `Vui lòng nhập số lượng hoặc đơn giá vật tư ${item.TEN_VT}`
          );
          check = false;
        }
      });
      return check;
    }
  };
  return (
    <div className="so4-edit">
      <Spin spinning={isLoading}>
        <div>
          <ToolBar
            setSave={!isEdit ? handleSave : undefined}
            setReEdit={isEdit ? handleEdit : undefined}
            setBack={handleBack}
          />
          <div style={{ paddingLeft: 15 }}>
            <h4 style={{ margin: 0, color: "#1890FF" }}>Hóa đơn bán hàng</h4>
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
                {Master.SO_CT} : {Master.TEN_KH}
              </h2>
            </div>
          </div>
          <General
            Master={Master}
            isEdit={isEdit}
            getTyGia={(ty_gia) => setTy_gia(ty_gia)}
          />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Chi tiết hóa đơn bán" key="1">
              <ListItem
                Master={Master}
                isEdit={isEdit}
                ty_gia={ty_gia}
                isClickSave={isClickSave}
                getLine={getLine}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  );
}
