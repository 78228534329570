import React, { Component } from "react";
import EmployeeForm from "../employeeForm/employeeForm";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Category/customer/index";
class EmployeeCreate extends Component {
  getItemActions = async (params) => {
    var model = {
      modelM: params,
      arraylstCustomerAddress: [],
    };
    model.modelM.SKU = params.ma_kh;
    model.modelM.MA_CTY = this.props.ma_cty;
    this.props.addNewCustomer(model);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.checkAddNewCustomer !== prevProps.checkAddNewCustomer) {
      openNotificationWithIcon("success", "Thêm nhân viên thành công !");
      this.props.clearCache();
      this.props.checkVisible({ isVisible: false, editSuccess: true });
    }
  };
  render() {
    const { isVisible } = this.props;
    return (
      <div>
        <EmployeeForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          getItemActions={this.getItemActions}
          title="Thêm mới nhân viên"
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listDm: state.SICustomerReducer.listDm,
    checkAddNewCustomer: state.SICustomerReducer.checkAddNewCustomer,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    addNewCustomer: (model) => {
      dispatch(actions.addNewCustomer(model));
    },
    getLists: (ma_cty) => {
      dispatch(actions.getLists(ma_cty));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCreate);
