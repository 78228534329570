import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListApprovalQuotations = (ma_cty) => (dispatch, getState) => {
  callApi("api/SoApprovalQuotations/ListApprovalQuotations", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_APPROVAL_SO0,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const approvalQuotations = (query) => (dispatch, getState) => {
  callApi("api/SoApprovalQuotations/ApprovalQuotations", "POST", query)
    .then((res) =>
      dispatch({
        type: types.APPROVAL_QUOTATION,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const rejectQuotations = (query) => (dispatch, getState) => {
  callApi("api/SoApprovalQuotations/RejectQuotations", "POST", query)
    .then((res) =>
      dispatch({
        type: types.REJECT_QUOTATION,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_APPROVAL_SO0,
  };
};
