import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Collapse, Button } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormatColumnDHSTable, result } from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/Category/Item/index";
import * as actionSellPrice from "../../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import DHSDataGrid from "../../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import "../../index.css";
import _ from "lodash";
import moment from "moment";

ChangePrices.propTypes = {
  _objectCreate: PropTypes.object,
};

ChangePrices.defaultProps = {
  listProduct: [],
  columns: [
    {
      caption: "STT",
      dataField: "STT",
      type: 0,
      width: 30,
    },
    {
      caption: "Mã sản phẩm",
      dataField: "MA_VT",
      type: 0,
    },
    {
      caption: "Tên sản phẩm",
      dataField: "TEN_VT",
      type: 0,
    },
    {
      caption: "Đơn vị tính",
      dataField: "DVT",
      type: 0,
      dataSource: {
        valueExpr: "DVT",
        displayExpr: "DVT",
      },
      // visible: false,
    },

    {
      caption: "Giá",
      dataField: "GIA_LE",
      format: "Money",
      type: 1,
    },
  ],
};
export default function ChangePrices(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, listProduct, columns } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const lstItem = useSelector((state) => state.ItemReducer.listItem);
  const [lstItemEdit, setLstItemEdit] = useState([]);
  const [isFocusNewRow, setFocusNewRow] = useState("");

  useEffect(() => {
    dispatch(actions.getListItem({ ma_cty }));
  }, []);

  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = async () => {
    await lstItemEdit.map((item) => {
      dispatch(
        actions.updateItem({
          ...item,
          ma_cty,
          CUSER: userName,
          LUSER: userName,
          imageUrl: item.anh,
        })
      );
    });
    await dispatch(
      actionSellPrice.getLookupSelectedItem({
        ma_cty,
        idcustomer: "",
        ma_nt: "VND",
        ngay_ct: moment().add(7, "hours"),
      })
    );
    checkVisible(false);
  };

  const hanleRowChange = async (params) => {
    let itemEdit = await listProduct.find((x) => x.STT_REC0 === params[0].key);
    await calc(itemEdit);
  };

  const calc = (data) => {
    setTimeout(() => {
      let item = {
        ...lstItem.find((x) => x.iditem === data.IDITEM),
        gia_le: data.GIA_LE,
      };
      let arr = lstItemEdit.filter((x) => x.iditem !== item.iditem);
      setLstItemEdit([...arr, { ...item }]);
    }, 100);
  };
  useEffect(() => {
    console.log(lstItemEdit);
  }, [lstItemEdit]);
  return (
    <div>
      <Modal
        id="submit-form"
        width={900}
        title="Đổi giá bán hàng"
        visible={isVisible}
        onCancel={handleCancel}
        footer={
          <Row gutter={[16, 16]}>
            <Col span={3} offset={19}>
              <Button type="primary" onClick={handleOk}>
                Đồng ý
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={handleCancel}>Hủy</Button>
            </Col>
          </Row>
        }
        maskClosable={false}
      >
        <Row>
          <Col span={24} className="_posChangePrices">
            <DHSDataGrid
              column={columns}
              data={listProduct}
              dataKey={"STT_REC0"}
              showColumnLines={false}
              selectionChanged={(value) => {
                setFocusNewRow(value[0]);
              }}
              hanleRowChange={hanleRowChange}
              focusNewRow={isFocusNewRow}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
