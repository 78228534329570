import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import INReports from "../INReports";
import Filter from "./Filter/Filter";
export default function StockReport(props) {
  const { history } = props;
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [objFilter, setObjFilter] = useState({
    TITLE: "",
    SPNAME: "",
  });
  const checkVisible = () => {
    setOpenDrawerFilter(false);
  };

  return (
    <div>
      <INReports
        rpName="mau_rp"
        title="Báo cáo tồn kho"
        history={history}
        setOpenDrawerFilter={setOpenDrawerFilter}
        setObjFilter={setObjFilter}
        objFilter={objFilter}
        dataKey="IDItem"
      />
      {openDrawerFilter ? (
        <Filter
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
          dataRpt={objFilter}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
