import React, { Component } from "react";
import { DHSSelectOpt } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import ProductInfor from "../../so2Edit/listAddress/productInfor/productInfor";
import { List, Row, Col } from "antd";
export default class Delivery extends Component {
  state = { listAddress: [], address: {} };
  componentDidMount = () => {
    const { delivery } = this.props;
    if (!_.isEmpty(delivery)) {
      this.getAddressFromContact();
    }
  };

  handleSelectAddress = async (value) => {
    const { listAddress } = this.state;
    await this.setState({
      address: _.find(listAddress, (item) => item.CONTACT_CODE === value),
    });
  };
  render() {
    const { listAddress, address } = this.state;
    return (
      <div style={{ padding: "0px 10px 10px 10px", marginBottom: 10 }}>
        <div style={{ width: "50%" }}>
          <DHSSelectOpt
            label="Danh sách địa chỉ giao hàng"
            array={listAddress}
            valueOpt={"CONTACT_CODE"}
            valueRender={"DELIVERY_ADDRESS"}
            onChange={this.handleSelectAddress}
          />
        </div>
        <List
          header={<HeaderRight />}
          dataSource={address.dataSource}
          size="small"
          bordered
          renderItem={(item) => (
            <ProductInfor
              item={item}
              direction={""}
              onGetItemDelete={() => {}}
            />
          )}
        />
      </div>
    );
  }
  getAddressFromContact = async () => {
    const { delivery, dataSource } = this.props;
    const { listAddress } = this.state;
    await delivery.map((item) => {
      const objectItem2 = _.filter(dataSource, (x) => x.IDITEM === item.IDITEM);
      if (!_.isEmpty(objectItem2)) {
        if (_.some(listAddress, (x) => x.CONTACT_CODE === item.CONTACT_CODE)) {
          _.filter(listAddress, (x) => {
            if (x.CONTACT_CODE === item.CONTACT_CODE) {
              x.dataSource.push({
                ...objectItem2[0],
                ...item,
              });
            }
          });
        } else {
          listAddress.push({
            ...item,
            dataSource: [{ ...objectItem2[0], ...item }],
          });
          this.setState({ listAddress: listAddress });
        }
      }
    });
  };
}
function HeaderRight() {
  return (
    <div>
      <Row style={{ color: "gray", fontSize: 12 }}>
        <Col span={1}></Col>
        <Col span={3}>Mã vật tư</Col>
        <Col span={12}>Tên vật tư</Col>
        <Col span={3}>SL giao</Col>
        <Col span={2}>ĐVT</Col>
      </Row>
    </div>
  );
}
