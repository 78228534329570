import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListPromotion = (ma_cty) => (dispatch, getState) => {
  callApi("api/soPromotion/ListPromotion", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHPromotion = (detail) => (dispatch, getState) => {
  callApi("api/soPromotion/GetPHPromotion", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTPromotion = (detail) => (dispatch, getState) => {
  callApi("api/soPromotion/GetCTPromotion", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/soPromotion/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewPromotion = (quotation) => (dispatch, getState) => {
  callApi("api/soPromotion/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getLists = (ma_cty) => (dispatch, getState) => {
  callApi("api/soPromotion/Lists", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deletePromotion = (master) => (dispatch, getState) => {
  callApi("api/soPromotion/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updatePromotion = (ds2) => (dispatch, getState) => {
  callApi("api/soPromotion/UpdateCommand", "POST", ds2)
    .then((res) =>
      dispatch({
        type: types.UPDATE_DS2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_DS2,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_DS2,
  };
};
