import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListCashPayment = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch02/ListCashPayment", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHCashPayment = (detail) => (dispatch, getState) => {
  callApi("api/caVch02/GetPHCashPayment", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTCashPayment = (detail) => (dispatch, getState) => {
  callApi("api/caVch02/GetCTCashPayment", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch02/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/caVch02/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewCashPayment = (query) => (dispatch, getState) => {
  callApi("api/caVch02/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteACashPayment = (master) => (dispatch, getState) => {
  callApi("api/caVch02/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_CA2,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updateCashPayment = (query) => (dispatch, getState) => {
  callApi("api/caVch02/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/caVch02/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch02/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_CA2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_CA2,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_CA2,
  };
};
