import React, { Component } from "react";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import "./index.css";
import { connect } from "react-redux";
import moment from "moment";
import { ReloadOutlined } from "@ant-design/icons";
import * as actions from "../../../../../redux/actions/Sales/salesOrder/salesOrder";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import _ from "lodash";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSInputTextArea,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import ChooseSO0 from "./chooseSO0/chooseSO0";
import ListSO0 from "./listSO0/listSO0";
import { widthDrawer } from "../../index";
const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};

class SO1Create extends Component {
  state = {
    resize: widthDrawer,
    IDcustomer: "",
    ma_cty: this.props.ma_cty,
    luser: this.props.userName,
    cuser: this.props.userName,
    dia_chi: "",
    email: "",
    tel: "",
    ma_kh: "",
    nguoi_gd: "",
    so_ct: this.props.soCt,
    ngay_lct: moment(),
    ngay_ct: moment(),
    ngay_hh: moment(),
    trang_thai: 1,
    ma_nt: "VND",
    ty_gia: 1,
    ghi_chu: "",
    ten_kh: "",
    loadingExchange: false,
    Master: {},
    loading: false,
    chooseSO0: false,
    listSO0: false,
    code_type: "",
  };

  componentDidMount = () => {
    this.props.getSoCt({ ma_cty: this.props.ma_cty });
  };

  componentDidUpdate = (prevProps) => {
    const { newItem, soCt } = this.props;
    if (!_.isEqual(newItem, prevProps.newItem)) {
      if (newItem === "-1") {
        openNotificationWithIcon("error", "Trùng số chứng từ !");
        this.setState({ loading: false });
        this.props.clearSTT();
      } else if (_.isObject(newItem)) {
        openNotificationWithIcon("success", "Thêm đơn hàng thành công !");
        this.props.checkVisible(false);
        this.props.history.push("/soVch01Edit", {
          Master: {
            ...newItem,
            TEN_KH: this.state.ten_kh,
            CODE_TYPE: this.state.code_type,
          },
        });
      }
    }
    if (!_.isEqual(soCt, prevProps.soCt)) {
      this.setState({ so_ct: soCt });
    }
  };
  componentWillUnmount = () => {
    this.props.clearCache();
  };
  handleOk = (e) => {
    if (!this.state.loading) {
      const SalesOrder = {
        modelM: this.state,
        arrayCt: [],
      };
      this.setState({ loading: true });
      this.props.addNewSalesOrder(SalesOrder);
    }
  };

  handleKHChange = (params) => {
    this.props.listCustomer.filter(async (item) => {
      if (item.idcustomer === params) {
        await this.setState({
          IDcustomer: params,
          dia_chi: item.dia_chi,
          email: item.email,
          tel: item.tel,
          ma_kh: item.ma_kh,
          nguoi_gd: item.nguoi_gd,
          ten_kh: item.ten_kh,
          so_ct: this.props.soCt,
          code_type: item.code_type,
        });
      }
    });
  };
  reloadSoCt = () => {
    this.props.getSoCt({ ma_cty: this.props.ma_cty });
  };
  render() {
    const {
      isVisible,
      listCustomer,
      soCt,
      listStatus,
      listCurrency,
    } = this.props;
    const {
      dia_chi,
      email,
      tel,
      nguoi_gd,
      ty_gia,
      loadingExchange,
      ma_nt,
      ghi_chu,
      loading,
      ngay_lct,
      ngay_ct,
      ngay_hh,
      chooseSO0,
      resize,
      listSO0,
      IDcustomer,
    } = this.state;
    return (
      <Drawer
        title="Thêm mới đơn hàng"
        width={resize}
        onClose={this.handleCancel}
        visible={isVisible}
        maskClosable={false}
        footer={
          <div
            style={{
              textAlign: "right",
              marginRight: 10,
            }}
          >
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
              style={{ marginRight: 8 }}
            >
              Lưu
            </Button>
            <Button onClick={this.handleCancel}>Hủy</Button>
          </div>
        }
      >
        <Spin spinning={loading}>
          <Collapse
            defaultActiveKey={["0", "1", "2"]}
            ghost
            className="so2-create"
            expandIconPosition="right"
          >
            <Panel header="Thông tin tham chiếu" key="0">
              <Button
                type="primary"
                onClick={() => this.setState({ chooseSO0: true })}
              >
                Chọn báo giá
              </Button>
              {chooseSO0 ? (
                <ChooseSO0
                  isVisible={chooseSO0}
                  checkVisible={this.checkVisible}
                />
              ) : (
                <></>
              )}
              {listSO0 ? (
                <ListSO0
                  isVisible={listSO0}
                  checkVisible={this.checkVisibleListSO0}
                />
              ) : (
                <></>
              )}
            </Panel>
            <Panel header="Thông tin khách hàng" key="1">
              <Form
                id="submit-form"
                onFinish={this.handleOk}
                layout="vertical"
                validateMessages={validateMessages}
              >
                <DHSSelectOpt
                  label="Khách hàng"
                  name="IDcustomer"
                  array={listCustomer}
                  valueOpt={"idcustomer"}
                  valueRender={"ten_kh"}
                  rules={true}
                  defaultValue={IDcustomer === "" ? undefined : IDcustomer}
                  onChange={this.handleKHChange}
                />
                <DHSInput
                  label="Địa chỉ"
                  name="dia_chi"
                  value={dia_chi}
                  getValueChange={this.handleChangeText}
                />
                <DHSInput
                  label="Email"
                  name="email"
                  value={email}
                  getValueChange={this.handleChangeText}
                />
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSInput
                      label="Người liên hệ"
                      name="nguoi_gd"
                      value={nguoi_gd}
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                  <Col span={12}>
                    <DHSInput
                      label="Số điện thoại"
                      name="tel"
                      value={tel}
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                </Row>
              </Form>
            </Panel>
            <Panel header="Thông tin đơn hàng" key="2">
              <Form layout="vertical">
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSInput
                      label="Số đơn hàng"
                      name="so_ct"
                      disable={true}
                      value={soCt}
                      addonAfter={<ReloadOutlined onClick={this.reloadSoCt} />}
                    />
                  </Col>
                  <Col span={12}>
                    <DHSDatePicker
                      label="Ngày chứng từ"
                      value={ngay_ct}
                      nameState="ngay_ct"
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSSelectOpt
                      label="Trạng thái"
                      array={listStatus}
                      valueOpt={"trang_thai"}
                      valueRender={"mo_ta"}
                      defaultValue={"Chờ duyệt"}
                      disable={true}
                    />
                  </Col>
                  <Col span={12}>
                    <DHSDatePicker
                      label="Ngày hết hạn"
                      value={ngay_hh}
                      nameState="ngay_hh"
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                  <Col span={12} offset={12}>
                    <DHSDatePicker
                      label="Ngày lập"
                      value={ngay_lct}
                      nameState="ngay_lct"
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSSelectOpt
                      label="Loại tiền tệ"
                      array={listCurrency}
                      valueOpt={"ma_nt"}
                      valueRender={"ma_nt"}
                      defaultValue={ma_nt}
                      onChange={this.handleChangeCurrency}
                    />
                  </Col>
                  <Col span={12}>
                    <Spin spinning={loadingExchange}>
                      <DHSInput
                        label="Tỷ giá"
                        name="ty_gia"
                        disable={ma_nt === "VND" ? true : false}
                        value={ty_gia}
                        getValueChange={this.handleChangeText}
                      />
                    </Spin>
                  </Col>
                </Row>
                <DHSInputTextArea
                  label="Ghi Chú"
                  name="ghi_chu"
                  value={ghi_chu}
                  getValueChange={this.handleChangeText}
                />
              </Form>
            </Panel>
          </Collapse>
        </Spin>
      </Drawer>
    );
  }
  handleCancel = (e) => {
    this.props.clearCache();
    this.props.checkVisible(false);
  };
  checkVisible = (params) => {
    this.setState({ chooseSO0: params.isVisible });
    params.dsPhQU
      ? this.setState({ listSO0: true, resize: widthDrawer + 400 })
      : this.setState({ listSO0: false, resize: widthDrawer });
  };
  checkVisibleListSO0 = (params) => {
    this.setState({ listSO0: params.isVisible, resize: widthDrawer });
    !_.isNil(params.phChoose) ? (
      this.handleKHChange(params.phChoose.IDCustomer)
    ) : (
      <></>
    );
  };
  handleChangeText = (params) => {
    this.setState({
      [params.name]: params.value,
    });
  };
  handleChangeCurrency = (params) => {
    this.setState({ ma_nt: params, loadingExchange: true });
    this.props.getExchangeRate({
      ma_cty: this.props.ma_cty,
      ma_nt: params,
      ngay_ct: this.state.ngay_lct,
    });
    setTimeout(() => {
      params === "VND"
        ? this.setState({ ty_gia: 1, loadingExchange: false })
        : this.setState({
            ty_gia: this.props.exchangeRate == 0 ? 1 : this.props.exchangeRate,
            loadingExchange: false,
          });
    }, 500);
  };
  //   disabledDate(current) {
  //     return current - 1 && current - 1 < moment().startOf("day");
  //   }
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listCustomer: state.SO1Reducer.listCustomer,
    listStatus: state.SO1Reducer.listStatus,
    listCurrency: state.SO1Reducer.listCurrency,
    soCt: state.SO1Reducer.soCt,
    exchangeRate: state.SO1Reducer.exchangeRate,
    newItem: state.SO1Reducer.newItem,
    customer: state.SO1Reducer.customer,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getExchangeRate: (ty_gia) => {
      dispatch(actions.getExchangeRate(ty_gia));
    },
    addNewSalesOrder: (query) => {
      dispatch(actions.addNewSalesOrder(query));
    },
    getCustomer: (query) => {
      dispatch(actions.getCustomer(query));
    },
    getSoCt: (ma_cty) => {
      dispatch(actions.getSoCt(ma_cty));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
    clearSTT: () => {
      dispatch(actions.clearSTT());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SO1Create);
