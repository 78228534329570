import React, { useState, useEffect } from "react";
import CustomerKindForm from "../customerKindForm/customerKindForm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/Category/customerKind/customerKind";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";

export default function CustomerKindCreate(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, history } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);

  const checkInsert = useSelector(
    (state) => state.CustomerKindReducer.checkInsert
  );

  useEffect(() => {
    if (checkInsert) {
      openNotificationWithIcon(
        "success",
        "Thêm phân loại khách hàng thành công !"
      );
      dispatch(actions.clearCache());
      dispatch(actions.getListCustomerKind({ MA_CTY }));
      checkVisible(false);
    }
  }, [checkInsert]);

  const handleActions = (params) => {
    dispatch(actions.addNewCustomerKind(params));
  };

  return (
    <CustomerKindForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      handleActions={handleActions}
      title="Thêm mới phân loại khách hàng"
    />
  );
}
