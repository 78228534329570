import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../redux/actions/Sales/salesInvoice/salesInvoice";
import * as actionsInventory from "../../../../../redux/actions/Category/Inventory/index";
import * as actionsItem from "../../../../../redux/actions/Category/Item/index";
import { periodData } from "../../../../controller/DataSample";
import {
  onPeriodChange,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.css";
import { widthDrawer } from "../../index";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
So3Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

So3Filter.defaultProps = {
  _objectFilter: {
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    IDCUSTOMER: "",
    FORMDATE: moment().startOf("month").add(7, "hours"),
    TODATE: moment().endOf("month").add(7, "hours"),
    SO_CT1: "",
    SO_CT2: "",
    TK_PT: "",
    TK_THUE: "",
    TK_CK_DS: "",
    MA_GD: "",
    MA_KHO: "",
    IDITEM: "",
    SO_SERI: "",
    MA_HTTT: "",
  },
};
export default function So3Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listCustomer = useSelector((state) => state.SO3Reducer.listCustomer);
  const lstInventory = useSelector(
    (state) => state.InventoryReducer.lstInventory
  );
  const tk_ck = useSelector((state) => state.SO3Reducer.tk_ck);
  const tk_no = useSelector((state) => state.SO3Reducer.tk_no);
  const tk_ts_gtgt = useSelector((state) => state.SO3Reducer.tk_ts_gtgt);
  const paymentTerm = useSelector((state) => state.SO3Reducer.paymentTerm);

  const sysExchange = useSelector((state) => state.SO3Reducer.sysExchange);
  const listItem = useSelector((state) => state.ItemReducer.listItem);
  const [objFilter, setObjFilter] = useState(_objectFilter);

  useEffect(() => {
    dispatch(actionsItem.getListItem({ ma_cty }));
    dispatch(actionsInventory.getListInventory({ ma_cty }));
  }, []);

  const handleCancel = (e) => {
    checkVisible(false);
  };
  const handleOk = () => {
    dispatch(
      actions.filt({
        modelM: {
          ma_cty,
          idcustomer: objFilter.IDCUSTOMER,
          cdate: objFilter.FORMDATE,
          ldate: objFilter.TODATE,
          tk_pt: objFilter.TK_PT,
          tk_thue: objFilter.TK_THUE,
          tk_ck_ds: objFilter.TK_CK_DS,
          so_seri: objFilter.SO_SERI,
          ma_httt: objFilter.MA_HTTT,
          ma_gd: objFilter.MA_GD,
        },
        ...objFilter,
      })
    );
    checkVisible(false);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate.add(7, "hours"),
      TODATE: result.toDate.add(7, "hours"),
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Tìm kiếm"
        width={widthDrawer}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Tìm kiếm
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="so3-filter"
        >
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD.toString()}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FORMDATE}
                nameState="FORMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Khách hàng"
            name="IDCUSTOMER"
            array={listCustomer}
            valueOpt={"IDCUSTOMER"}
            valueRender={"TEN_KH"}
            onChange={(value) => handleChange(value, "IDCUSTOMER")}
            valueEmpty={true}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSInput
                label="Số phiếu từ"
                name="SO_CT1"
                value={objFilter.SO_CT1}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Số phiếu đến"
                name="SO_CT2"
                value={objFilter.SO_CT2}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Điều khoản thanh toán"
                name="MA_HTTT"
                array={paymentTerm}
                valueOpt={"MA_HTTT"}
                valueRender={"TEN_HTTT"}
                onChange={(value) =>
                  handleChangeText({ value, name: "MA_HTTT" })
                }
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Số seri"
                name="SO_SERI"
                value={objFilter.SO_SERI}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Loại phiếu"
                name="MA_GD"
                array={sysExchange}
                valueOpt={"MA_GD"}
                valueRender={"MO_TA"}
                onChange={(value) => handleChange(value, "MA_GD")}
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Tài khoản nợ"
                name="TK_PT"
                array={tk_no}
                valueOpt={"TK"}
                valueRender={"TK"}
                onChange={(value) => handleChange(value, "TK_PT")}
                valueEmpty={true}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Tài khoản chiết khấu"
                name="TK_CK_DS"
                array={tk_ck}
                valueOpt={"TK"}
                valueRender={"TK"}
                onChange={(value) => handleChange(value, "TK_CK_DS")}
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Tài khoản thuế"
                name="TK_THUE"
                array={tk_ts_gtgt}
                valueOpt={"TK"}
                valueRender={"TK"}
                onChange={(value) => handleChange(value, "TK_THUE")}
                valueEmpty={true}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Kho"
            name="MA_KHO"
            array={lstInventory}
            valueOpt={"MA_KHO"}
            valueRender={"TEN_KHO"}
            onChange={(value) => handleChange(value, "MA_KHO")}
            valueEmpty={true}
          />
          <DHSSelectOpt
            label="Vật tư"
            name="IDITEM"
            array={listItem}
            valueOpt={"iditem"}
            valueRender={"ten_vt"}
            onChange={(value) => handleChange(value, "IDITEM")}
            valueEmpty={true}
          />
        </Form>
      </Drawer>
    </div>
  );
}
