import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Row, Col, Form } from "antd";
import {
  FormatDate,
  toUpperPropertyNameByArray,
} from "../../../../../controller/Format";
import {
  DHSSelectOpt,
  DHSText,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
import * as actions from "../../../../../../redux/actions/Sales/salesReturn/salesReturn";
const { Panel } = Collapse;
export default function General(props) {
  const dispatch = useDispatch();
  const { Master } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listCustomer = useSelector((state) => state.SO4Reducer.listCustomer);
  const tk_pt = useSelector((state) => state.SO4Reducer.tk_pt);
  const sysExchange = useSelector((state) => state.SO4Reducer.sysExchange);
  useEffect(() => {
    let customer = listCustomer.find((x) => x.idcustomer === Master.IDCUSTOMER);
    if (!_.isUndefined(customer)) {
      Master.EMAIL = customer.email;
      Master.TEL = customer.tel;
      Master.DIA_CHI = customer.dia_chi;
    }
  }, [listCustomer]);

  return (
    <div className="so4-detail-general">
      <Collapse defaultActiveKey={["1"]} ghost expandIconPosition="right">
        <Panel header="Thông tin chung" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Tên khách hàng" value={Master.TEN_KH} />
                <DHSText label="Email" value={Master.EMAIL} />
                <DHSText label="Số điện thoại" value={Master.TEL} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Người liên hệ" value={Master.NGUOI_GD} />
                <DHSText label="Địa chỉ" value={Master.DIA_CHI} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Ngày lập" value={FormatDate(Master.NGAY_CT)} />
                <DHSSelectOpt
                  label="Loại phiếu"
                  name="MA_GD"
                  array={toUpperPropertyNameByArray(sysExchange)}
                  valueOpt={"MA_GD"}
                  valueRender={"MO_TA"}
                  onChange={(value) =>
                    handleChangeText({ value, name: "MA_GD" })
                  }
                  defaultValue={Master.MA_GD}
                  disable={true}
                />
                <DHSSelectOpt
                  label="Tài khoản có"
                  name="TK"
                  array={toUpperPropertyNameByArray(tk_pt)}
                  valueOpt={"TK"}
                  valueRender={"TK"}
                  onChange={(value) =>
                    handleChangeText({ value, name: "TK_PT" })
                  }
                  defaultValue={Master.TK_PT}
                  disable={true}
                />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Loại tiền tệ" value={Master.MA_NT} />
                <DHSText label="Tỷ giá" value={Master.TY_GIA} />
                <DHSText label="Ghi chú" value={Master.DIEN_GIAI} />
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
}
