import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../../redux/actions/Inventory/Reports/INReports";
import { periodData, widthDrawer } from "../../../../../controller/DataSample";
import {
  onPeriodChange,
  toUpperPropertyNameByArray,
} from "../../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import "../../index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
const responsiveDrawer = {
  span: 12,
  xs: 24,
  lg: 12,
};
Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

Filter.defaultProps = {
  _objectFilter: {
    MA_CTY: "",
    IDCUSTOMER: "",
    TITLE: "",
    SITE_ID: "",
    MA_NHVT: "",
    MA_BP: "",
    MA_HD: "",
    TK_VT: "",
    MA_KHO: "",
    MA_LO: "",
    MA_NHKHO: "",
    IDITEM: "",
    MA_NT: "",
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    FORMDATE: moment().startOf("month"),
    TODATE: moment().endOf("month"),
    SPNAME: "",
  },
};
export default function Filter(props) {
  const dispatch = useDispatch();
  const {
    checkVisible,
    isVisible,
    _objectFilter,
    approvalScr,
    dataRpt,
  } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listDm = useSelector((state) => state.INReportsReducer.listDm);
  const [objFilter, setObjFilter] = useState({ ..._objectFilter, ...dataRpt });
  const listCompany = useSelector((state) => state.LoginReducer.listCompany);

  const handleCancel = (e) => {
    checkVisible(false);
  };
  const handleOk = () => {
    dispatch(
      actions.GetDataFilterINRpt({
        ...objFilter,
        MA_CTY: ma_cty,
        MA_CT: "IN",
        NGAY1: moment(objFilter.FORMDATE).add(7, "hours"),
        NGAY2: moment(objFilter.TODATE).add(7, "hours"),
      })
    );
    checkVisible(false);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate,
      TODATE: result.toDate,
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Điều kiện lọc"
        width={widthDrawer}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Thực hiện
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="Filter"
        >
          <DHSInput
            label="Tiêu đề"
            name="TITLE"
            value={objFilter.TITLE}
            getValueChange={handleChangeText}
          />
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FORMDATE}
                nameState="FORMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Chi nhánh"
                name="SITE_ID"
                array={listDm.dm_site}
                valueOpt={"SITE_ID"}
                valueRender={"SITE_NAME"}
                onChange={(value) => handleChange(value, "SITE_ID")}
              />
            </Col>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Nhà cung cấp"
                name="MA_BP"
                array={listDm.dm_ncc}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                onChange={(value) => handleChange(value, "IDCUSTOMER")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Kho"
                name="MA_KHO"
                array={listDm.dm_kho}
                valueOpt={"MA_KHO"}
                valueRender={"TEN_KHO"}
                onChange={(value) => handleChange(value, "MA_KHO")}
              />
            </Col>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Bộ phận"
                name="MA_BP"
                array={listDm.dm_bp}
                valueOpt={"MA_BP"}
                valueRender={"TEN_BP"}
                onChange={(value) => handleChange(value, "MA_BP")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Nhóm kho"
                name="MA_NHKHO"
                array={listDm.dm_nh_kho}
                valueOpt={"MA_NHKHO"}
                valueRender={"TEN_NHKHO"}
                onChange={(value) => handleChange(value, "MA_NHKHO")}
              />
            </Col>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Hợp đồng"
                name="MA_HD"
                array={listDm.dm_hopdong_mua}
                valueOpt={"MA_HD"}
                valueRender={"TEN_HD"}
                onChange={(value) => handleChange(value, "MA_HD")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Lô"
                name="MA_LO"
                array={listDm.dm_lo}
                valueOpt={"MA_LO"}
                valueRender={"TEN_LO"}
                onChange={(value) => handleChange(value, "MA_LO")}
              />
            </Col>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Mã ngoại tệ"
                name="MA_NT"
                array={listDm.dm_nt}
                valueOpt={"MA_NT"}
                valueRender={"TEN_NT"}
                onChange={(value) => handleChange(value, "MA_NT")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Vật tư"
                name="IDITEM"
                array={listDm.dm_vattu}
                valueOpt={"IDITEM"}
                valueRender={"TEN_VT"}
                onChange={(value) => handleChange(value, "IDITEM")}
              />
            </Col>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Nhóm vật tư"
                name="MA_NHVT"
                array={listDm.dm_nhomvt}
                valueOpt={"MA_NHVT"}
                valueRender={"TEN_NHVT"}
                onChange={(value) => handleChange(value, "MA_NHVT")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col {...responsiveDrawer}>
              <DHSSelectOpt
                label="Tài khoản kho"
                name="TK_VT"
                array={listDm.dm_tk_kho}
                valueOpt={"TK"}
                valueRender={"TEN_TK"}
                onChange={(value) => handleChange(value, "TK_VT")}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
