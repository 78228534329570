import React, { Component } from "react";
import DriverForm from "../DriverForm/DriverForm";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Category/driver/driver";
class DriverCreate extends Component {
  getItemActions = async (params) => {
    var model = params;
    model.MA_CTY = this.props.ma_cty;
    this.props.insertDriver(model);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.checkInsert !== prevProps.checkInsert) {
      openNotificationWithIcon("success", "Thêm tài xế thành công !");
      this.props.clearCache();
      this.props.checkVisible({ isVisible: false, editSuccess: true });
    }
  };
  render() {
    const { isVisible } = this.props;
    return (
      <div>
        <DriverForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          getItemActions={this.getItemActions}
          title="Thêm mới tài xế"
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    checkInsert: state.DriverReducer.checkInsert,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    insertDriver: (model) => {
      dispatch(actions.insertDriver(model));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverCreate);
