import React, { Component } from "react";
import VehicleForm from "../VehicleForm/VehicleForm";
export default class VehicleDetail extends Component {
  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <VehicleForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          title="Thông tin phương tiện"
          Master={Master}
          noneEdit={true}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
