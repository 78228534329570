import * as types from "../../../constants/ActionTypes";

var initialState = {
  listMovementTransfer: [],
  ctMovementTransfer: [],
  phMovementTransfer: {},
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  sysExchange: [],
  listNV: [],
  listBP: [],
};

var MovementTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_IN3: {
      return {
        ...state,
        listMovementTransfer: action.payload,
      };
    }
    case types.FILT_IN3: {
      return {
        ...state,
        listMovementTransfer: action.payload,
      };
    }
    case types.GET_PH_IN3: {
      return {
        ...state,
        phMovementTransfer: action.payload,
      };
    }
    case types.GET_CT_IN3: {
      return {
        ...state,
        ctMovementTransfer: action.payload,
      };
    }

    case types.GET_SO_CT_IN3: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_IN3: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_IN3: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_EXCHANGE_RATE_IN3: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_IN3: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_IN3: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctMovementTransfer: [],
        phMovementTransfer: {},
        newItem: null,
        exchangeRate: "",
      };
    }
    case types.CLEAR_HANDLE_STT_IN3: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }
    case types.GET_LISTS_DM_IN3: {
      return {
        ...state,
        listCurrency: action.payload.dm_nt,
        listCustomer: action.payload.dm_ncc,
        sysExchange: action.payload.sysExchange,
        listNV: action.payload.dm_nv,
        listBP: action.payload.dm_bp,
      };
    }
    default:
      return state;
  }
};

export default MovementTransferReducer;
