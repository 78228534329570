import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import * as actions from "../../../../redux/actions/System/RoleInfo/roleInfo";
import { PageHeader, Spin } from "antd";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import RoleInfoCreate from "./roleInfoCreate/roleInfoCreate";
import RoleInfoEdit from "./roleInfoEdit/roleInfoEdit";
import RoleInfoDetail from "./roleInfoDetail/roleInforDetail";
import { openNotificationWithIcon } from "../../../../common/notification/notification";

const objectColumn = {
  ROLENAME: { name: "Mã vai trò", width: 100 },
  FULLNAME: { name: "Tên vai trò", width: 100 },
};
const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};
export default function RoleInfo(props) {
  const dispatch = useDispatch();
  const [customColumns, setCustomColumns] = useState();
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [isLoading, setLoading] = useState(false);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const RoleInfoState = useSelector((state) => state.RoleInfoReducer);
  useEffect(() => {
    dispatch(actions.GetListRoleInfo({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    setLoading(false);
    setData(RoleInfoState.listRoleInfo);
    setFullData(RoleInfoState.listRoleInfo);
  }, [RoleInfoState.listRoleInfo]);

  useEffect(() => {
    if (RoleInfoState.checkDelete) {
      dispatch(actions.GetListRoleInfo({ ma_cty }));
      dispatch(actions.clearCache());
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [RoleInfoState.checkDelete]);

  const getDataFilter = (params) => {
    setData(params);
  };

  const handleOpenDrawer = () => {
    dispatch(actions.clearCache());
    setIsOpenCreate(true);
  };
  const checkVisible = (params) => {
    setIsOpenCreate(params);
    setIsOpenDetail(params);
    setIsOpenEdit(params);
  };
  const getDataRow = (params) => {
    setMaster(params);
  };
  const handleDelete = (params) => {
    setLoading(true);
    dispatch(actions.deleteRoleInfo({ roleInfo: params, ma_cty }));
  };
  const handleEdit = (params) => {
    setIsOpenEdit(true);
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Vai trò người sử dụng"
          onBack={() => props.history.push("/System")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          Master={isMaster}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.GetListRoleInfo({ ma_cty }));
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="ROLENAME"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setIsOpenDetail(true);
          }}
        />
        {isOpenCreate ? (
          <RoleInfoCreate
            isVisible={isOpenCreate}
            checkVisible={checkVisible}
          />
        ) : (
          <></>
        )}
        {isOpenDetail ? (
          <RoleInfoDetail
            isVisible={isOpenDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
        {isOpenEdit ? (
          <RoleInfoEdit
            isVisible={isOpenEdit}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
