import React, { useState, useEffect } from "react";
import { Drawer, Button, Row, Col } from "antd";
import { useSelector } from "react-redux";
import Format from "../../../../../../controller/Format";
import DHSListItem from "../../../../../../../common/DHSControl/DHSListItem/DHSListItem";
import { widthDrawer } from "../../../../index";
function Header() {
  return (
    <div
      style={{
        color: "gray",
        fontSize: 12,
        display: "flex",
        padding: "0px 10px",
        flex: "1 1",
      }}
    >
      <p style={{ width: 50, margin: 0 }}>Ảnh</p>
      <p style={{ width: "35vw", margin: 0, flex: "1 0" }}>Tên vật tư</p>
      <p style={{ width: 90, margin: 0 }}>Đơn giá</p>
      <p style={{ width: 90, margin: 0 }}>Số lượng</p>
    </div>
  );
}
export default function HandleMultiAdd(props) {
  const { isVisible, checkVisible } = props;
  const lstItem = useSelector((state) => state.ItemReducer.listItem);
  // const lookupSelectedItem = useSelector(
  //   (state) => state.SellPriceReducer.lookupSelectedItem
  // );
  const [isSelected, setSelected] = useState([]);
  const handleCancel = () => {
    checkVisible({ isVisible: false, isSelected: [] });
  };
  const confirmSelected = () => {
    checkVisible({
      isVisible: false,
      isSelected,
    });
  };
  const getItemSelected = (params) => {
    setSelected(params);
  };
  return (
    <Drawer
      title="Chọn vật tư"
      width={widthDrawer}
      maskClosable={false}
      onClose={handleCancel}
      visible={isVisible}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={confirmSelected}
          >
            Xác nhận
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <DHSListItem
        header={<Header />}
        listItem={lstItem}
        keyItem={"IDITEM"}
        title={"TEN_VT"}
        description={"MA_VT"}
        inputName1={"GIA_NT2"}
        avatar={"ANH"}
        inputDisplay={true}
        getItemSelected={getItemSelected}
      />
    </Drawer>
  );
}
