import Inventory from "../../../components/layout/Inventory/inventory";
import MovementReceipt from "../../../components/layout/Inventory/MovementReceipt/movementReceipt";
import IN1Edit from "../../../components/layout/Inventory/MovementReceipt/IN1Edit/IN1Edit";
import MovementIssue from "../../../components/layout/Inventory/MovementIssue/movementIssue";
import IN2Edit from "../../../components/layout/Inventory/MovementIssue/IN2Edit/IN2Edit";
import MovementTransfer from "../../../components/layout/Inventory/MovementTransfer/movementTransfer";
import IN3Edit from "../../../components/layout/Inventory/MovementTransfer/IN3Edit/IN3Edit";
import MovementStockInventory from "../../../components/layout/Inventory/Reports/MovementStockInventory/MovementStockInventory";
import StockReport from "../../../components/layout/Inventory/Reports/StockReport/StockReport";
import MovementIssueStatement from "../../../components/layout/Inventory/Reports/MovementIssueStatement/MovementIssueStatement";
import MovementIssueTransaction from "../../../components/layout/Inventory/Reports/MovementIssueTransaction/MovementIssueTransaction";
import MovementReceiptStatement from "../../../components/layout/Inventory/Reports/MovementReceiptStatement/MovementReceiptStatement";
import MovementReceiptTransaction from "../../../components/layout/Inventory/Reports/MovementReceiptTransaction/MovementReceiptTransaction";
import POSRptINMovement from "../../../components/layout/Inventory/Reports/POSRptINMovement/POSRptINMovement";
const inventoryRoutes = [
  {
    path: "/Inventory",
    exact: false,
    main: ({ history }) => <Inventory history={history} />,
  },
  {
    path: "/MovementReceipt",
    exact: false,
    main: ({ history }) => <MovementReceipt history={history} />,
  },
  {
    path: "/IN1Edit",
    exact: false,
    main: ({ history }) => <IN1Edit history={history} />,
  },
  {
    path: "/MovementIssue",
    exact: false,
    main: ({ history }) => <MovementIssue history={history} />,
  },
  {
    path: "/IN2Edit",
    exact: false,
    main: ({ history }) => <IN2Edit history={history} />,
  },
  {
    path: "/MovementTransfer",
    exact: false,
    main: ({ history }) => <MovementTransfer history={history} />,
  },
  {
    path: "/IN3Edit",
    exact: false,
    main: ({ history }) => <IN3Edit history={history} />,
  },
  {
    path: "/MovementStockInventory",
    exact: false,
    main: ({ history }) => <MovementStockInventory history={history} />,
  },
  {
    path: "/StockReport",
    exact: false,
    main: ({ history }) => <StockReport history={history} />,
  },
  {
    path: "/MovementIssueStatement",
    exact: false,
    main: ({ history }) => <MovementIssueStatement history={history} />,
  },
  {
    path: "/MovementIssueTransaction",
    exact: false,
    main: ({ history }) => <MovementIssueTransaction history={history} />,
  },
  {
    path: "/MovementReceiptStatement",
    exact: false,
    main: ({ history }) => <MovementReceiptStatement history={history} />,
  },
  {
    path: "/MovementReceiptTransaction",
    exact: false,
    main: ({ history }) => <MovementReceiptTransaction history={history} />,
  },
  {
    path: "/POSRptInventoryMovement",
    exact: false,
    main: ({ history }) => <POSRptINMovement history={history} />,
  },
];

export default inventoryRoutes;
