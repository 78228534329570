import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListSite = (ma_cty) => (dispatch, getState) => {
  callApi("api/Site/ListSite", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_SITE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const insertSite = (query) => (dispatch, getState) => {
  callApi("api/Site/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.INSERT_SITE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const deleteSite = (query) => (dispatch, getState) => {
  callApi("api/Site/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_SITE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateSite = (query) => (dispatch, getState) => {
  callApi("api/Site/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_SITE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_SITE,
  };
};
