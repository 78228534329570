import React, { Component } from "react";
import { Collapse, Row, Col, Form, Input, Select, Spin } from "antd";
import { FormatDate } from "../../../../../controller/Format";
import "./index.css";
import { connect } from "react-redux";
import {
  DHSInput,
  DHSSelectOpt,
  DHSText,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../../redux/actions/Sales/packingSlip/index";
import _ from "lodash";
const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;
class General extends Component {
  state = {
    IDcustomer: "",
    dia_chi: "",
    email: "",
    tel: "",
    ma_kh: "",
    contact: "",
    ty_gia: "",
    ma_nt: "",
    ghi_chu: "",
    loadingExchange: false,
  };
  refreshState = () => {
    this.setState({
      IDcustomer: this.props.customer.IDCustomer,
      dia_chi: this.props.customer.dia_chi,
      email: this.props.customer.email,
      tel: this.props.customer.tel,
      ma_kh: this.props.customer.ma_kh,
      contact: this.props.Master.NGUOI_GD,
      ty_gia: this.props.Master.TY_GIA,
      ma_nt: this.props.Master.MA_NT,
      ghi_chu: this.props.Master.DIEN_GIAI,
    });
  };
  componentDidMount = () => {
    this.props.getListCustomer({ ma_cty: this.props.ma_cty });
    this.props.getListCurrency();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!_.isEqual(this.state, prevState)) {
      this.props.getMaster(this.state);
    }
    if (!_.isEqual(this.props.customer, prevProps.customer)) {
      this.refreshState();
    }
  };
  componentWillUnmount = () => {
    this.props.clearCache();
  };
  onChangeText = async (params) => {
    await this.setState({ [params.name]: params.value });
  };
  handleChange = (value) => {
    this.props.listCustomer.filter(async (item) => {
      if (item.idcustomer === value) {
        await this.setState({
          IDcustomer: value,
          dia_chi: item.dia_chi,
          email: item.email,
          tel: item.tel,
          ma_kh: item.ma_kh,
        });
      }
    });
  };
  handleChangeCurrency = async (value) => {
    this.setState({ ma_nt: value, loadingExchange: true });
    this.props.getExchangeRate({
      ma_cty: this.props.ma_cty,
      ma_nt: value,
      ngay_ct: this.props.Master.NGAY_LCT,
    });
    setTimeout(async () => {
      value === "VND"
        ? await this.setState({ ty_gia: 1, loadingExchange: false })
        : await this.setState({
            ty_gia: this.props.exchangeRate == 0 ? 1 : this.props.exchangeRate,
            loadingExchange: false,
          });
    }, 500);
  };
  render() {
    const {
      Master,
      listCustomer,
      listCurrency,
      customer,
      editDone,
      listStatus,
    } = this.props;
    const {
      IDcustomer,
      dia_chi,
      tel,
      contact,
      loadingExchange,
      ty_gia,
      ma_nt,
      ghi_chu,
      email,
    } = this.state;
    return (
      <div>
        <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
          <Panel header="Thông tin chung" key="1">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSSelectOpt
                    label="Tên khách hàng"
                    array={listCustomer}
                    valueOpt={"idcustomer"}
                    valueRender={"ten_kh"}
                    defaultValue={IDcustomer}
                    onChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSText label="Số điện thoại" value={tel} />
                  <DHSText label="Email" value={email} />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSInput
                    label="Người liên hệ"
                    name="contact"
                    value={contact}
                    getValueChange={this.onChangeText}
                    disable={editDone}
                  />
                  <DHSText label="Địa chỉ" value={dia_chi} />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSText
                    label="Ngày lập"
                    value={FormatDate(Master.NGAY_CT)}
                  />
                  <DHSSelectOpt
                    label="Trạng thái"
                    array={listStatus}
                    valueOpt={"trang_thai"}
                    valueRender={"mo_ta"}
                    defaultValue={Master.TRANG_THAI}
                    disable={true}
                  />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSSelectOpt
                    label="Loại tiền tệ"
                    array={listCurrency}
                    valueOpt={"ma_nt"}
                    valueRender={"ma_nt"}
                    defaultValue={ma_nt}
                    onChange={this.handleChangeCurrency}
                    disable={editDone}
                  />
                  <Spin spinning={loadingExchange}>
                    <DHSInput
                      label="Tỷ giá"
                      name="ty_gia"
                      value={ty_gia}
                      disable={editDone}
                      disable={ma_nt === "VND" ? true : false}
                      getValueChange={this.onChangeText}
                    />
                  </Spin>
                  <DHSInput
                    label="Ghi chú"
                    name="ghi_chu"
                    value={ghi_chu}
                    disable={editDone}
                    getValueChange={this.onChangeText}
                  />
                </Form>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }
  listCustomer = (listCustomer) => {
    var result = null;
    if (listCustomer.length > 0) {
      result = listCustomer.map((item, index) => {
        return (
          <Option value={item.idcustomer} key={index}>
            {item.ma_kh} - {item.ten_kh}
          </Option>
        );
      });
    }
    return result;
  };
  selectCurrency = (listCurrency) => {
    var result = null;
    if (listCurrency.length > 0) {
      result = listCurrency.map((item, index) => {
        return (
          <Option value={item.ma_nt} key={index}>
            {item.ma_nt}
          </Option>
        );
      });
    }
    return result;
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listCustomer: state.SO2Reducer.listCustomer,
    customer: state.SO2Reducer.customer,
    listCurrency: state.SO2Reducer.listCurrency,
    exchangeRate: state.SO2Reducer.exchangeRate,
    listStatus: state.SO2Reducer.listStatus,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getCustomer: (query) => {
      dispatch(actions.getCustomer(query));
    },
    getListCustomer: (ma_cty) => {
      dispatch(actions.getListCustomer(ma_cty));
    },
    getListCurrency: () => {
      dispatch(actions.getListCurrency());
    },
    getExchangeRate: (ty_gia) => {
      dispatch(actions.getExchangeRate(ty_gia));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
