import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Button, Divider, Form, Row, Col, Spin } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { periodData } from "../../../../../controller/DataSample";
import { onPeriodChange } from "../../../../../controller/Format";
import { DHSSelectOpt } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as action from "../../../../../../redux/actions/Purchases/purchaseReceipt/purchaseReceipt";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
ChoosePO1.propTypes = {
  _objectFilter: PropTypes.object,
};

ChoosePO1.defaultProps = {
  _objectFilter: {
    IDCUSTOMER: "",
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    FORMDATE: moment().startOf("month"),
    TODATE: moment().endOf("month"),
  },
};
export default function ChoosePO1(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, _objectFilter } = props;
  const [resize, setResize] = useState(400);
  const [isLoading, setLoading] = useState(false);
  const listCustomer = useSelector((state) => state.PO2Reducer.listCustomer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [objFilter, setObjFilter] = useState(_objectFilter);
  const handleCancel = () => {
    checkVisible({ isVisible: false, dsPhPO: false });
  };
  const handleChange = (value, name) => {
    setObjFilter({ ...objFilter, [name]: value });
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate,
      TODATE: result.toDate,
    });
  };
  const onConfirm = () => {
    const query = {
      ma_cty,
      idcustomer: objFilter.IDCUSTOMER,
      cdate: objFilter.FORMDATE,
      ldate: objFilter.TODATE,
    };
    dispatch(action.LookupPO1(query));
  };
  return (
    <div>
      <Drawer
        title="Lọc đơn hàng mua"
        width={resize}
        onClose={handleCancel}
        visible={isVisible}
      >
        <Spin spinning={isLoading}>
          <Divider orientation="left">Điều kiện lọc</Divider>
          <Form
            layout="vertical"
            validateMessages={validateMessages}
            onFinish={onConfirm}
          >
            <Row>
              <Col span={20} offset={2}>
                <DHSSelectOpt
                  label="Nhà cung cấp"
                  name="IDCUSTOMER"
                  array={listCustomer}
                  valueOpt={"idcustomer"}
                  valueRender={"ten_kh"}
                  rules={true}
                  onChange={(value) => handleChange(value, "IDCUSTOMER")}
                />
              </Col>
              <Col span={20} offset={2}>
                <DHSSelectOpt
                  label="Thời gian"
                  name="PERIOD"
                  array={periodData}
                  valueOpt={"value"}
                  valueRender={"name"}
                  defaultValue={objFilter.PERIOD}
                  onChange={onHandleFilter}
                />
              </Col>
              <Col span={20} offset={2}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Xác nhận
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
}
