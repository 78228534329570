import * as types from "../../../constants/ActionTypes";

var initialState = {
  listItem: [],
  tblItemType: [],
  dm_nhomvt: [],
  lstUnit2: [],
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
};

var ItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_ITEM: {
      return {
        ...state,
        listItem: action.payload,
      };
    }
    case types.GET_LIST_DM:
      return {
        ...state,
        tblItemType: action.payload.tblItemType,
        dm_nhomvt: action.payload.dm_nhomvt,
        lstUnit2: action.payload.lstUnit2,
      };
    case types.INSERT_ITEM:
      return {
        ...state,
        checkInsert: action.payload,
      };
    case types.DELETE_ITEM:
      return {
        ...state,
        checkDelete: action.payload,
      };
    case types.UPDATE_ITEM:
      return {
        ...state,
        checkUpdate: action.payload,
      };
    case types.CLEAR_ITEM: {
      return {
        ...state,
        checkUpdate: false,
        checkDelete: false,
        checkInsert: false,
        listItem: [],
      };
    }
    default:
      return state;
  }
};

export default ItemReducer;
