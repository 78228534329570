import React, { useEffect, useState } from "react";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import PropTypes from "prop-types";
import moment from "moment";
import { FormatMoney } from "../../../../controller/Format";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { widthDrawer } from "../../index";
SellPriceForm.propTypes = {
  objectCreate: PropTypes.object,
};

SellPriceForm.defaultProps = {
  objectCreate: {
    MA_NT: "VND",
    IDITEM: "",
    PRICE_CURRENT: 0,
    DIEN_GIAI: "",
    CODE_TYPE: "",
    EFFECT_DATE: moment(),
    EFFECT_DATE_TO: moment(),
    IDCUSTOMER: "",
    MA_NHKH: "",
    CUSER: "",
    LUSER: "",
  },
};

const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};
export default function SellPriceForm(props) {
  const {
    isVisible,
    checkVisible,
    title,
    handleActions,
    objectCreate,
    isEdit,
    Master,
  } = props;
  const Lists = useSelector((state) => state.SellPriceReducer.lists);
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [objCreate, setObjCreate] = useState(
    _.isNil(Master) ? objectCreate : Master
  );
  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = () => {
    isEdit ? (objCreate.CUSER = userName) : (objCreate.LUSER = userName);
    handleActions({
      ...objCreate,
      MA_CTY,
    });
  };

  const handleChangeText = async (params) => {
    await setObjCreate({ ...objCreate, [params.name]: params.value });
  };
  return (
    <Drawer
      title={title}
      width={widthDrawer}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8, display: !isEdit ? "none" : "unset" }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Collapse
        defaultActiveKey={["0", "1", "2"]}
        ghost
        className="sellPrice-create"
        expandIconPosition="right"
      >
        <Panel header="Thông tin giá bán" key="1">
          <Form
            id="submit-form"
            onFinish={handleOk}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <DHSSelectOpt
              label="Tên vật tư"
              name="IDITEM"
              array={Lists.dm_vattu}
              valueOpt="IDITEM"
              valueRender="TEN_VT"
              rules={true}
              defaultValue={
                objCreate.IDITEM === "" ? undefined : objCreate.IDITEM
              }
              onChange={(value) => handleChangeText({ name: "IDITEM", value })}
              disable={!isEdit}
            />
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSSelectOpt
                  label="Mã ngoại tệ"
                  name="MA_NT"
                  array={Lists.dm_nt}
                  valueOpt="MA_NT"
                  valueRender="TEN_NT"
                  defaultValue={objCreate.MA_NT}
                  onChange={(value) =>
                    handleChangeText({ name: "MA_NT", value })
                  }
                  disable={!isEdit}
                />
              </Col>
              <Col span={12}>
                <DHSSelectOpt
                  label="Loại giá"
                  name="CODE_TYPE"
                  array={Lists.dm_sp_type}
                  valueOpt="CODE_TYPE"
                  valueRender="DESCRIPTION"
                  defaultValue={objCreate.CODE_TYPE}
                  onChange={(value) =>
                    handleChangeText({ name: "CODE_TYPE", value })
                  }
                  disable={!isEdit}
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSSelectOpt
                  label="Nhóm khách hàng"
                  name="MA_NHKH"
                  array={Lists.dm_nhkh}
                  valueOpt="MA_NHKH"
                  valueRender="TEN_NHKH"
                  defaultValue={objCreate.MA_NHKH}
                  onChange={(value) =>
                    handleChangeText({ name: "MA_NHKH", value })
                  }
                  valueEmpty={true}
                  disable={!isEdit}
                />
              </Col>
              <Col span={12}>
                <DHSInput
                  label="Giá bán"
                  name="PRICE_CURRENT"
                  value={
                    isEdit
                      ? objCreate.PRICE_CURRENT
                      : FormatMoney(objCreate.PRICE_CURRENT)
                  }
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
            </Row>
            <DHSSelectOpt
              label="Khách hàng"
              name="IDCUSTOMER"
              array={Lists.dm_kh}
              valueOpt="IDCUSTOMER"
              valueRender="TEN_KH"
              defaultValue={objCreate.IDCUSTOMER}
              onChange={(value) =>
                handleChangeText({ name: "IDCUSTOMER", value })
              }
              valueEmpty={true}
              disable={!isEdit}
            />
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSDatePicker
                  label="Ngày hiệu lực"
                  value={moment(objCreate.EFFECT_DATE)}
                  nameState="EFFECT_DATE"
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
              <Col span={12}>
                <DHSDatePicker
                  label="Ngày hết hạn"
                  value={moment(objCreate.EFFECT_DATE_TO)}
                  nameState="EFFECT_DATE_TO"
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Drawer>
  );
}
