import React, { useState, useEffect } from "react";
import { Row, Col, Descriptions, Button, Spin, InputNumber, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import Calculator from "./Calculator/Calculator";
import AddCustomers from "../Modal/AddCustomers/AddCustomers";
import AddProducts from "../Modal/AddProducts/AddProducts";
import PropTypes from "prop-types";
import DHSDataGrid from "../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import { FormatMoney } from "../../../../controller/Format";
import { lstColor, lstSize } from "../../../../controller/DataSample";
import * as actions from "../../../../../redux/actions/Sales/packingSlip/index";
import moment from "moment";
import _ from "lodash";
// import MessengerCustomerChat from "react-messenger-customer-chat";
Lines.propTypes = {
  _objectCreate: PropTypes.object,
  columns: PropTypes.array,
  total: PropTypes.object,
};

Lines.defaultProps = {
  _objectCreate: {
    IDCUSTOMER: "",
    TEN_KH: "",
    MA_CTY: "",
    LUSER: "",
    CUSER: "",
    DIA_CHI: "",
    EMAIL: "",
    TEL: "",
    MA_KH: "",
    GHI_CHU: "",
  },
  columns: [
    {
      caption: "Ảnh",
      dataField: "ANH",
      type: 0,
      format: "image",
      width: 100,
    },
    {
      caption: "Tên sản phẩm",
      dataField: "TEN_VT",
      type: 0,
    },
    {
      caption: "Màu",
      dataField: "COLOR_ID",
      type: 2,
      allowEditing: false,
      dataSource: {
        data: lstColor,
        valueExpr: "COLOR_ID",
        displayExpr: "COLOR_NAME",
      },
      width: 150,
      // visible: false,
    },
    {
      caption: "Kích thước",
      dataField: "SIZE_ID",
      type: 0,
      dataSource: {
        data: lstSize,
        valueExpr: "SIZE_ID",
        displayExpr: "SIZE_NAME",
      },
      width: 150,
      // visible: false,
    },
    {
      caption: "Số lượng",
      dataField: "SO_LUONG",
      type: 1,
      width: 150,
    },
    {
      caption: "Giá",
      dataField: "GIA_NT2",
      format: "Money",
      type: 0,
      width: 150,
    },
  ],
  total: {
    T_TIEN2: 0,
    TL_CK: 0,
    TS_GTGT: 10,
    T_TT: 0,
    TIEN_CK_NT: 0,
    T_THUE: 0,
    KHACH_TRA: 0,
    CON_LAI: 0,
  },
};
const { Search } = Input;
export default function Lines(props) {
  const dispatch = useDispatch();
  const { _objectCreate, columns, total } = props;
  const [openModalAddCus, setOpenModalAddCus] = useState(false);
  const [openModalAddProduct, setOpenModalAddProduct] = useState(false);
  const [checkCustomer, setCheckCustomer] = useState(false);
  const [customer, setCustomer] = useState(_objectCreate);
  const [listProduct, setListProduct] = useState([]);
  const [isTotal, setTotal] = useState(total);
  const [isLoading, setLoading] = useState(false);
  const [isFocusNewRow, setFocusNewRow] = useState("");
  const [isCountItem, setCountItem] = useState(-1);
  const [textBC, setTextBC] = useState("");
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const so_ct = useSelector((state) => state.SO2Reducer.soCt);
  const newItem = useSelector((state) => state.SO2Reducer.newItem);
  const lookupSelectedItem = useSelector(
    (state) => state.SellPriceReducer.lookupSelectedItem
  );
  var scanner = "";
  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(newItem)) {
      openNotificationWithIcon("success", "Thanh toán thành công !");
      dispatch(actions.getSoCt({ ma_cty }));
      dispatch(actions.clearCache());
      setCustomer(_objectCreate);
      setListProduct([]);
      setTotal(total);
      setCheckCustomer(false);
    }
  }, [newItem]);

  useEffect(() => {
    dispatch(actions.getSoCt({ ma_cty }));
    window.onkeypress = function (e) {
      let barcode = "";
      let code = e.keyCode ? e.keyCode : e.which;
      barcode = barcode + String.fromCharCode(code);
      scanner = scanner + barcode;
      const timeoutId = setTimeout(() => {
        setTextBC(scanner);
        scanner = "";
      }, 1000);
      return () => clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    if (textBC !== "") {
      lookupSelectedItem.map((item) => {
        if (item.MA_VT == textBC) {
          item.INDEX = Math.random().toString(36).substr(2, 9).toUpperCase();
          let STT_REC0 = Math.random().toString(36).substr(2, 9).toUpperCase();
          item.SO_LUONG = 1;
          setFocusNewRow(STT_REC0);
          setListProduct([...listProduct, Object.assign({ STT_REC0 }, item)]);
        }
      });
    }
  }, [textBC]);
  const checkVisible = (params) => {
    setOpenModalAddCus(params);
    setOpenModalAddProduct(params);
  };
  const getCustomer = (value) => {
    if (!_.isEmpty(value.TEN_KH)) {
      setCustomer(value);
      setCheckCustomer(true);
    } else {
      setCheckCustomer(false);
    }
  };

  useEffect(() => {
    listProduct.map((item) => calc(item));
  }, [listProduct]);

  const hanleRowChange = async (params) => {
    let itemChange = listProduct.find((x) => x.STT_REC0 === params[0].key);
    await calc(itemChange);
  };

  const calc = (data) => {
    setTimeout(() => {
      data.TIEN_NT2 = data.SO_LUONG * data.GIA_NT2 || 0;
      data.TIEN_CK_NT = (data.TIEN_NT2 * data.TL_CK) / 100 || 0;
      data.TT = data.TIEN_NT2 - data.TIEN_CK_NT;
      data.TT_NT = data.TT;
      let T_TIEN2 = listProduct.reduce((a, b) => a + (b["TT"] || 0), 0);
      let T_THUE = (T_TIEN2 * isTotal.TS_GTGT) / 100;
      setTotal({
        ...isTotal,
        T_TIEN2,
        T_THUE: T_THUE,
        T_TT: T_TIEN2 + T_THUE,
        CON_LAI: T_TIEN2 + T_THUE - isTotal.KHACH_TRA,
      });
    }, 100);
  };

  const validationProduct = () => {
    let check = true;
    listProduct.map((item) => {
      if (_.isEmpty(item.COLOR_ID) || _.isEmpty(item.SIZE_ID)) {
        check = false;
      }
    });
    return check;
  };

  useEffect(() => {
    setTotal({
      ...isTotal,
      CON_LAI: isTotal.T_TIEN2 + isTotal.T_THUE - isTotal.KHACH_TRA,
    });
  }, [isTotal.KHACH_TRA]);

  const paymentChange = (value) => {
    setTotal({
      ...isTotal,
      KHACH_TRA: value,
    });
  };

  const onPayment = () => {
    if (validationProduct()) {
      if (_.isEmpty(customer.TEN_KH)) {
        openNotificationWithIcon("error", "Vui lòng chọn khách hàng mua !");
      } else {
        setLoading(true);
        const PackingSlip = {
          modelM: {
            ...isTotal,
            ...customer,
            ...{
              ma_cty,
              ty_gia: 1,
              so_ct,
              ngay_ct: moment(),
              trang_thai: "1",
              ma_nt: "VND",
              cuser: userName,
              luser: userName,
            },
          },
          arrayCt: listProduct,
          arrayAddress: [],
        };
        dispatch(actions.addNewPackingSlip(PackingSlip));
      }
    } else {
      openNotificationWithIcon(
        "error",
        "Vui lòng nhập đầy đủ màu và kích thước sản phẩm !"
      );
    }
  };

  useEffect(() => {
    if (isCountItem > -1) {
      let itemChange = listProduct.find((x) => x.STT_REC0 === isFocusNewRow);
      if (!_.isUndefined(itemChange)) {
        itemChange.SO_LUONG = isCountItem;
        calc(itemChange);
      }
    }
  }, [isCountItem]);
  return (
    <div className="_listItem">
      {/* <MessengerCustomerChat
        pageId="1689685854662423"
        appId="1316877102013801"
      /> */}
      <Spin spinning={isLoading}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={18} xs={12} xl={16}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={16} xs={16} xl={21}>
                <Button
                  onClick={() => setOpenModalAddProduct(true)}
                  icon={<PlusOutlined />}
                  style={{ marginBottom: 5 }}
                  type="primary"
                >
                  Thêm sản phẩm
                </Button>
              </Col>
              <Col span={8} xs={8} xl={3}>
                <Button onClick={onPayment} type="primary">
                  Thanh toán
                </Button>
              </Col>
            </Row>
            <DHSDataGrid
              column={columns}
              data={listProduct}
              dataKey={"STT_REC0"}
              showColumnLines={false}
              selectionChanged={(value) => {
                setFocusNewRow(value[0]);
              }}
              hanleRowChange={hanleRowChange}
              allowDeleting={true}
              focusNewRow={isFocusNewRow}
            />
          </Col>
          <Col span={6} xs={12} xl={8}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                xl={24}
                span={24}
                xs={24}
                className="_colAddCustomer"
                onClick={() => setOpenModalAddCus(true)}
              >
                {checkCustomer ? (
                  <div>
                    Thông tin khách hàng
                    <Descriptions bordered>
                      <Descriptions.Item label="Họ và Tên" span={3}>
                        {customer.TEN_KH}
                      </Descriptions.Item>
                      <Descriptions.Item label="Địa chỉ" span={3}>
                        {customer.DIA_CHI}
                      </Descriptions.Item>
                      <Descriptions.Item label="Số điện thoại" span={3}>
                        {customer.TEL}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email" span={3}>
                        {customer.EMAIL}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", marginTop: 75 }}>
                    <PlusOutlined style={{ fontSize: 30 }} />
                    <br />
                    <h1>Chọn khách hàng</h1>
                  </div>
                )}
              </Col>
              <Col xl={24} span={24} xs={24}>
                <Descriptions bordered size="large">
                  <Descriptions.Item label="Dòng" span={1.5}>
                    {listProduct.length}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tổng tiền" span={1.5}>
                    {FormatMoney(isTotal.T_TIEN2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Giảm giá" span={1.5}>
                    0
                  </Descriptions.Item>
                  <Descriptions.Item label="Thuế" span={1.5}>
                    {FormatMoney(isTotal.T_THUE)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Khách trả" span={3}>
                    <InputNumber
                      min={0}
                      name="KHACH_TRA"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={paymentChange}
                      value={isTotal.KHACH_TRA}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Tổng còn lại" span={3}>
                    <p style={{ fontSize: 35, color: "#1890FF" }}>
                      {FormatMoney(isTotal.CON_LAI)}
                    </p>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col xl={24}>
                <Calculator setCountItem={setCountItem} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      {openModalAddCus ? (
        <AddCustomers
          isVisible={openModalAddCus}
          checkVisible={checkVisible}
          getCustomer={getCustomer}
        />
      ) : (
        <></>
      )}
      {openModalAddProduct ? (
        <AddProducts
          isVisible={openModalAddProduct}
          checkVisible={checkVisible}
          getProduct={(selected) =>
            setListProduct([...listProduct, ...selected])
          }
          // listProduct={listProduct}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
