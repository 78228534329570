import React, { Component } from "react";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { PageHeader, Button, Space, Spin, Popconfirm } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Category/ItemGroup/index";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import "./index.css";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import ItemGroupCreate from "./itemGroupCreate/itemGroupCreate";
import ItemGroupDetail from "./itemGroupDetail/itemGroupDetail";
import ItemGroupEdit from "./itemGroupEdit/itemGroupEdit";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const objectColumn = {
  MA_NHVT: { name: "Mã nhóm vật tư", width: 100 },
  TEN_NHVT: { name: "Tên nhóm vật tư", width: 300 },
  CAP: { name: "Cấp", width: 100 },
  TK_VT: { name: "TK VT", width: 100 },
  TK_DT: { name: "TK DT", width: 100 },
  TK_GV: { name: "TK GV", width: 100 },
  TK_TL: { name: "TK TL", width: 100 },
  TK_CK: { name: "TK CK", width: 100 },
};
class ItemGroup extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    IDGROUP: "",
    openDrawerCreate: false,
    openDrawerEdit: false,
    openDrawerDetail: false,
    Master: {},
  };
  componentDidMount = () => {
    this.loadListItemGroup();
  };
  componentDidUpdate = (prevProps) => {
    const { listItemGroup, checkDelete, clearCache } = this.props;
    if (!_.isEqual(listItemGroup, prevProps.listItemGroup)) {
      this.setState({
        data: listItemGroup,
        fullData: listItemGroup,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ loading: false, IDGROUP: "", Master: {} });
      this.loadListItemGroup();
      clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };
  confirmDelete(IDGROUP) {
    this.setState({ loading: true });
    this.props.deleteItemGroup({
      ma_cty: this.props.ma_cty,
      IDGROUP: IDGROUP,
    });
  }
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.setState({ openDrawerEdit: true });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn nhà cung cấp cần sửa !");
    }
  };

  render() {
    const {
      loading,
      data,
      customColumns,
      IDGROUP,
      openDrawerCreate,
      openDrawerEdit,
      Master,
      openDrawerDetail,
      fullData,
    } = this.state;
    return (
      <div className="ItemGroup" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Nhóm Hàng hóa / vật tư"
            onBack={() => this.props.history.push("/Category")}
          />
          <Space style={{ marginBottom: 16, marginTop: 10 }}>
            <Button
              onClick={() => this.setState({ openDrawerCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn muốn xóa nhà cung cấp ?"
              onConfirm={() => this.confirmDelete(IDGROUP)}
              okText="Đồng ý"
              cancelText="Hủy"
              disabled={IDGROUP === "" ? true : false}
            >
              <Button
                onClick={() => {
                  if (IDGROUP === "") {
                    openNotificationWithIcon(
                      "error",
                      "Vui lòng chọn nhà cung cấp cần xóa !"
                    );
                  }
                }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            </Popconfirm>
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListItemGroup();
            }}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="MA_NHVT"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openDrawerCreate ? (
            <ItemGroupCreate
              isVisible={openDrawerCreate}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
          {openDrawerEdit ? (
            <ItemGroupEdit
              isVisible={openDrawerEdit}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <ItemGroupDetail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.setState({
      openDrawerCreate: params.isVisible,
      loading: params.isVisible,
      openDrawerDetail: params.isVisible,
      openDrawerEdit: params.isVisible,
      openDrawerFilter: params.isVisible,
    });
    params.editSuccess ? this.loadListItemGroup(this.state.moduleID) : <></>;
    params.editSuccess ? this.setState({ IDGROUP: "", Master: {} }) : <></>;
  };
  getDataRow = (params) => {
    this.setState({ Master: params, IDGROUP: params.IDGROUP });
  };
  loadListItemGroup = () => {
    const { listItemGroup } = this.props;
    // this.setState({ loading: true });
    if (this.state.customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      this.setState({ customColumns: result });
    }
    listItemGroup.length = 0;
    this.props.getListItemGroup({ ma_cty: this.props.ma_cty });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listItemGroup: state.ItemGroupReducer.listItemGroup,
    checkDelete: state.ItemGroupReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListItemGroup: (ma_cty) => {
      dispatch(actions.getListItemGroup(ma_cty));
    },
    deleteItemGroup: (query) => {
      dispatch(actions.deleteItemGroup(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemGroup);
