import React, { Component } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import {
  FormatMoney,
  FormatDate,
} from "../../../../components/controller/Format";
import "./index.css";
class rp05 extends Component {
  render() {
    let objCompany = JSON.parse(localStorage.getItem("SessionLogin"));
    const { title, dateRp, dataTable, contentTable } = this.props;
    return (
      <div className="Rp05" style={{ padding: 20 }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <p>
              <b>{objCompany.companyName}</b>
            </p>
            <p>
              <b>{objCompany.dia_chi}</b>
            </p>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <h2>{title}</h2>
            <p>
              từ ngày {moment(dateRp.FROMDATE).format("DD/MM/YYYY")} đến ngày{" "}
              {moment(dateRp.TODATE).format("DD/MM/YYYY")}
            </p>
          </Col>
          <Col span={24}>
            <table
              style={{
                width: "100%",
              }}
            >
              <tr>
                {contentTable.map((item, index) => {
                  return <th>{item.caption}</th>;
                })}
              </tr>
              {dataTable.map((item, index) => {
                return (
                  <tr>
                    {contentTable.map((itemTd, index) => {
                      return (
                        <td>
                          {itemTd.format === "date"
                            ? FormatDate(item[itemTd.dataField])
                            : itemTd.format === "Money"
                            ? FormatMoney(item[itemTd.dataField])
                            : item[itemTd.dataField]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </table>
          </Col>
        </Row>
      </div>
    );
  }
}

rp05.propTypes = {};

export default rp05;
