import * as types from "../../../constants/ActionTypes";

var initialState = {
  listMachine: [],
  checkNewUpdate: false,
  dateUpdate: "",
  listMachinesStateColor: [],
};

var MachineStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MACHINE_STATE: {
      return {
        ...state,
        listMachine: action.payload,
      };
    }
    case types.GET_MACHINE_STATE_NEW_UPDATE: {
      return {
        ...state,
        checkNewUpdate: action.payload,
      };
    }
    case types.CHANGE_STATE_UPDATE: {
      return {
        ...state,
        checkNewUpdate: false,
      };
    }
    case types.GET_TIME_UPDATE: {
      return {
        ...state,
        dateUpdate: action.payload,
      };
    }
    case types.GET_MACHINE_STATE_COLOR: {
      return {
        ...state,
        listMachinesStateColor: action.payload,
      };
    }
    default:
      return state;
  }
};

export default MachineStatusReducer;
