import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListBankReceipt = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch03/ListBankReceipt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHBankReceipt = (detail) => (dispatch, getState) => {
  callApi("api/caVch03/GetPHBankReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTBankReceipt = (detail) => (dispatch, getState) => {
  callApi("api/caVch03/GetCTBankReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch03/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/caVch03/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewBankReceipt = (query) => (dispatch, getState) => {
  callApi("api/caVch03/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteABankReceipt = (master) => (dispatch, getState) => {
  callApi("api/caVch03/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_CA3,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updateBankReceipt = (query) => (dispatch, getState) => {
  callApi("api/caVch03/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/caVch03/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch03/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_CA3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_CA3,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_CA3,
  };
};
