import React, { useEffect, useState } from "react";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import { useSelector, useDispatch } from "react-redux";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Sales/approvalQuotation/approvalQuotation";
import { Drawer, Divider, Tabs, Spin } from "antd";
import "./index.css";
import { widthDrawer } from "../../index";

const { TabPane } = Tabs;

export default function So0Detail(props) {
  const { isVisible, checkVisible, Master, approval } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const APPROVALSO0 = useSelector((state) => state.ApprovalQuotationsReducer);

  const onClose = () => {
    checkVisible(false);
  };

  useEffect(() => {
    if (APPROVALSO0.approvalQuotations || APPROVALSO0.rejectQuotations) {
      APPROVALSO0.approvalQuotations
        ? openNotificationWithIcon("success", "Duyệt thành công !")
        : openNotificationWithIcon("success", "Từ chối thành công !");
      checkVisible(false);
    }
  }, [APPROVALSO0.approvalQuotations, APPROVALSO0.rejectQuotations]);

  const handleApproval = (params) => {
    const query = {
      ma_cty: ma_cty,
      luser: userName,
      note3: "",
      arrayStt_rec: [params.STT_REC],
    };
    dispatch(actions.approvalQuotations(query));
    setLoading(true);
  };

  const handleReject = (params) => {
    const query = {
      ma_cty: ma_cty,
      luser: userName,
      arrayStt_rec: [params.STT_REC],
    };
    dispatch(actions.rejectQuotations(query));
    setLoading(true);
  };
  return (
    <Drawer
      title={`Chi tiết báo giá - ${Master.SO_CT}`}
      visible={isVisible}
      width={widthDrawer}
      onClose={onClose}
    >
      <Spin spinning={isLoading}>
        <div className="so0-detail">
          {approval ? (
            <div>
              <ToolBar
                setApproval={handleApproval}
                setReject={handleReject}
                Master={Master}
              />
              <Divider style={{ margin: 0 }} />
            </div>
          ) : (
            <></>
          )}
          <General Master={Master} />
          <Divider style={{ margin: 0 }} />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin hàng hóa" key="1">
              <ListItem Master={Master} />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </Drawer>
  );
}
