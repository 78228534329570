import * as types from "../../../constants/ActionTypes";

var initialState = {
  listSalesReturn: [],
  ctSalesReturn: [],
  phSalesReturn: {},
  contactSalesReturn: [],
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  checkDispatch: false,
  tk_pt: [],
  sysExchange: [],
};

var SalesReturnReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SO4: {
      return {
        ...state,
        listSalesReturn: action.payload,
      };
    }
    case types.FILT_SO4: {
      return {
        ...state,
        listSalesReturn: action.payload,
      };
    }
    case types.GET_CT_SO4: {
      return {
        ...state,
        ctSalesReturn: action.payload,
      };
    }
    case types.GET_PH_SO4: {
      return {
        ...state,
        phSalesReturn: action.payload,
      };
    }
    case types.GET_CUSTOMER_SO4: {
      return {
        ...state,
        customer: action.payload[0],
      };
    }
    case types.GET_SO_CT_SO4: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_SO4: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_SO4: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LIST_STATUS_SO4: {
      return {
        ...state,
        listStatus: action.payload,
      };
    }
    case types.GET_LISTS_DM: {
      return {
        ...state,
        listCurrency: action.payload.dm_nt,
        listCustomer: action.payload.dm_kh,
        sysExchange: action.payload.sysExchange,
        tk_pt: action.payload.tk,
      };
    }
    case types.GET_EXCHANGE_RATE_SO4: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_SO4: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }

    case types.CLEAR_SO4: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctSalesReturn: [],
        phSalesReturn: {},
        newItem: null,
      };
    }
    case types.CLEAR_HANDLE_STT_SO4: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }

    default:
      return state;
  }
};

export default SalesReturnReducer;
