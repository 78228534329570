import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import MetroMenu from "../../../common/DHSControl/DHSMetroMenu/DHSMetroMenu";
import ChangePassword from "./changePassword/changePassword";
import imgLink from "../../../common/images/Retail_System.png";

const moduleid = "AD";

export default function System(props) {
  const { history, isVisible } = props;
  const [openModalCP, setOpenModalCP] = useState(false);
  const listCategoryLv2 = useSelector(
    (state) => state.LoginReducer.listCategoryLv2
  );
  useEffect(() => {
    if (isVisible) {
      setOpenModalCP(isVisible);
    }
  }, []);
  return (
    <div>
      {!_.isEmpty(listCategoryLv2) ? (
        <MetroMenu image={imgLink} moduleid={moduleid} history={history} />
      ) : (
        <></>
      )}
      {openModalCP ? (
        <ChangePassword isVisible={openModalCP} history={history} />
      ) : (
        <></>
      )}
    </div>
  );
}
