import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListSalesOrder = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch01/ListSalesOrder", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHSalesOrder = (detail) => (dispatch, getState) => {
  callApi("api/soVch01/GetPHSalesOrder", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTSalesOrder = (detail) => (dispatch, getState) => {
  callApi("api/soVch01/GetCTSalesOrder", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCustomer = (customer) => (dispatch, getState) => {
  callApi("api/soVch01/GetCustomer", "POST", customer)
    .then((res) =>
      dispatch({
        type: types.GET_CUSTOMER_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListCustomer = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch01/ListCustomer", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CUSTOMER_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch01/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch01/GetListDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_DM_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListCurrency = () => (dispatch, getState) => {
  callApi("api/soVch01/ListCurrency", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CURRENCY_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/soVch01/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewSalesOrder = (quotation) => (dispatch, getState) => {
  callApi("api/soVch01/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteASalesOrder = (master) => (dispatch, getState) => {
  callApi("api/soVch01/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateSalesOrder = (so1) => (dispatch, getState) => {
  callApi("api/soVch01/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const lookupSoQuotation = (query) => (dispatch, getState) => {
  callApi("api/soVch01/LookupSoQuotation", "POST", query)
    .then((res) =>
      dispatch({
        type: types.LOOKUP_SO_QUOTATION,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_SO1,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_SO1,
  };
};
export const listCtChooseFromQU = (dsCtQU) => {
  return {
    type: types.LIST_CT_CHOOSE_FROM_QU,
    payload: dsCtQU,
  };
};
export const getContactSalesOrder = (query) => (dispatch, getState) => {
  callApi("api/soVch01/GetContactSalesOrder", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_CONTACT_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/soVch01/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
