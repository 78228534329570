import React, { Component } from "react";
import { Button, Popconfirm } from "antd";
import { openNotificationWithIcon } from "../../notification/notification";
import { DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";
export default class BtnConfirm extends Component {
  render() {
    const { objConfirm, label, handleConfirm, title, icon } = this.props;
    return (
      <div>
        <Popconfirm
          title={title}
          onConfirm={() => handleConfirm(objConfirm)}
          okText="Đồng ý"
          cancelText="Hủy"
          disabled={_.isEmpty(objConfirm)}
        >
          <Button
            icon={icon}
            onClick={() => {
              if (_.isEmpty(objConfirm)) {
                openNotificationWithIcon("error", "Vui lòng chọn đối tượng !");
              }
            }}
          >
            {label}
          </Button>
        </Popconfirm>
      </div>
    );
  }
}
