import React, { Component } from "react";
import { Row, Col, Divider, Tabs, Collapse, Spin, Affix } from "antd";
import {
  ClusterOutlined,
  ToolOutlined,
  LoadingOutlined,
  SettingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import * as actions from "../../../../redux/actions/Manufacturing/MachineStatus/index";
import * as actionsMachineRpt from "../../../../redux/actions/Manufacturing/MachineRpt/index";
import { FormatMoney } from "../../../controller/Format";
import { connect } from "react-redux";
import "./index.css";
import moment from "moment";
import _ from "lodash";
const { TabPane } = Tabs;

var intervalTime = null;
const timeNow = moment().add("7", "hours").toISOString();
const renderTabBar = (props, DefaultTabBar) => (
  <Affix>
    <DefaultTabBar {...props} className="custom-tab-bar-box-machine" />
  </Affix>
);
const { Panel } = Collapse;

class MachineStatus extends Component {
  state = {
    listMachine: [],
    loading: false,
  };
  componentDidMount = () => {
    this.props.GetLstMachinesGroup({ ma_cty: this.props.ma_cty });
    this.props.getMachineState({ ma_cty: this.props.ma_cty });
    this.props.getLstMachinesStateColor({ ma_cty: this.props.ma_cty });
    this.props.getTimeUpdate(timeNow);
    intervalTime = setInterval(() => {
      this.props.getMachineStateNewUpdate({
        ma_cty: this.props.ma_cty,
        LAST_UPDATE_DATETIME: this.props.dateUpdate,
      });
    }, 30000);
  };
  componentDidUpdate = async (prevProps) => {
    if (this.props.checkNewUpdate !== prevProps.checkNewUpdate) {
      if (this.props.checkNewUpdate) {
        await this.props.getMachineState({ ma_cty: this.props.ma_cty });
        await this.props.getTimeUpdate(timeNow);
        await this.props.changeStateUpdate();
      }
    }
    if (!_.isEqual(this.props.listMachine, prevProps.listMachine)) {
      this.setState({ listMachine: this.props.listMachine });
    }
  };
  componentWillUnmount = () => {
    this.props.listMachine.length = 0;
    clearInterval(intervalTime);
  };

  onRefesh = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      this.props.getMachineStateNewUpdate({
        ma_cty: this.props.ma_cty,
        LAST_UPDATE_DATETIME: this.props.dateUpdate,
      });
    }, 500);
  };
  onClickCol = (item) => {
    this.props.containRptMachine({
      time_to: moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
      time_from: moment()
        .subtract("1", "days")
        .set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
      machineID: item.machine_id,
      machine_name: item.machine_name,
    });
    this.props.history.push("/machinereporttimeline");
  };
  render() {
    const { listMachine, loading } = this.state;
    return (
      <div style={{ height: "100%" }} className="machineStatus">
        <Spin spinning={loading}>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <SyncOutlined
                style={{ fontSize: 22 }}
                onClick={this.onRefesh}
                spin={loading}
              />
            }
            renderTabBar={renderTabBar}
            animated={true}
          >
            <TabPane tab="All" key="1">
              <Collapse
                expandIconPosition="right"
                ghost
                defaultActiveKey={["0", "1", "2"]}
              >
                {this.renderByGroup(this.props.groupMachine, 0)}
              </Collapse>
            </TabPane>
            <TabPane tab={<span>Setup</span>} key="2">
              <Collapse
                expandIconPosition="right"
                ghost
                defaultActiveKey={["0", "1", "2"]}
              >
                {this.renderByGroup(this.props.groupMachine, 1)}
              </Collapse>
            </TabPane>
            <TabPane tab="Run" key="3">
              <Collapse
                expandIconPosition="right"
                ghost
                defaultActiveKey={["0", "1", "2"]}
              >
                {this.renderByGroup(this.props.groupMachine, 3)}
              </Collapse>
            </TabPane>
            <TabPane tab={<span>Stop</span>} key="4">
              <Collapse
                expandIconPosition="right"
                ghost
                defaultActiveKey={["0", "1", "2"]}
              >
                {this.renderByGroup(this.props.groupMachine, 4)}
              </Collapse>
            </TabPane>
            <TabPane tab={<span>Maintenance</span>} key="5">
              <Collapse
                expandIconPosition="right"
                ghost
                defaultActiveKey={["0", "1", "2"]}
              >
                {this.renderByGroup(this.props.groupMachine, 2)}
              </Collapse>
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    );
  }
  renderByGroup = (listGroup, state) => {
    var result = [];
    if (!_.isEmpty(listGroup)) {
      result = listGroup.map((x, index) => {
        return (
          <Panel
            header={
              <Divider orientation="left" style={{ fontWeight: "bold" }}>
                {"Khu vực máy " + x.MACHINE_GROUP_CODE}
              </Divider>
            }
            key={index}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {this.listMachineStatus(
                this.state.listMachine,
                state,
                x.MACHINE_GROUP_CODE
              )}
            </Row>
          </Panel>
        );
      });
    }
    return result;
  };
  listMachineStatus(listMachine, state, idGroup) {
    var result = null;
    if (listMachine.length > 0) {
      result = listMachine.map((item, index) => {
        if (item.machine_zone_code === idGroup) {
          const data = (
            <Col
              key={index}
              className="gutter-row"
              span={6}
              xs={12}
              xl={6}
              onClick={() => this.onClickCol(item)}
            >
              {this.renderCol(item)}
            </Col>
          );
          if (state === 0) {
            return data;
          } else if (item.state === state) {
            return data;
          }
        }
      });
    }
    return result;
  }
  renderCol = (item) => {
    const color = this.colorStateDescription(item.state);
    return (
      <div>
        <div
          className="boxMachine"
          style={{
            background: this.colorState(item.state),
          }}
        >
          <h2 style={{ color }}>{item.machine_code}</h2>
          <h3 style={{ color }}>{item.machine_name}</h3>
          <div className="twoColumnTotal">
            <div className="twoColumnLeft">
              <h3 style={{ color }}>{item.state_description}</h3>
            </div>
            <div className="twoColumnRight">
              <h4 style={{ color }}>
                {moment(item.last_update_datetime).format("DD/MM/YYYY HH:mm")}
              </h4>
            </div>
          </div>
          <div className="twoColumnTotal">
            <div className="twoColumnLeft">
              <div>
                <h5>SẢN PHẨM</h5>
                <h3 style={{ color }}>{item.ten_vt}</h3>
              </div>
              <div>
                <h5>SỐ LỆNH SX</h5>
                <h3 style={{ color }}>{item.so_lsx}</h3>
              </div>
            </div>
            <div className="twoColumnRight">
              <div>
                <h5>SL KẾ HOẠCH</h5>
                <h3 style={{ color }}>{FormatMoney(item.so_luong_kh)}</h3>
              </div>
              <div>
                <h5>SL HOÀN THÀNH</h5>
                <h3 style={{ color }}>{FormatMoney(item.sl_thuc_te)}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  colorState = (value) => {
    const { listMachinesStateColor } = this.props;
    var color = "";
    listMachinesStateColor.map((item, index) => {
      if (item.STATE === value) {
        color = item.BACKCOLOR;
      }
    });
    return color !== "" ? color : {};
  };
  colorStateDescription = (value) => {
    const { listMachinesStateColor } = this.props;
    var color = "";
    listMachinesStateColor.map((item, index) => {
      if (item.STATE === value) {
        color = item.FORECOLOR;
      }
    });
    return color !== "" ? color : {};
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listMachine: state.MachineStatusReducer.listMachine,
    checkNewUpdate: state.MachineStatusReducer.checkNewUpdate,
    dateUpdate: state.MachineStatusReducer.dateUpdate,
    listMachinesStateColor: state.MachineStatusReducer.listMachinesStateColor,
    groupMachine: state.MachineRptReducer.groupMachine,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getMachineState: (ma_cty) => {
      dispatch(actions.getMachineState(ma_cty));
    },
    getMachineStateNewUpdate: (query) => {
      dispatch(actions.getMachineStateNewUpdate(query));
    },
    changeStateUpdate: () => {
      dispatch(actions.changeStateUpdate());
    },
    getTimeUpdate: (date) => {
      dispatch(actions.getTimeUpdate(date));
    },
    containRptMachine: (data) => {
      dispatch(actionsMachineRpt.containRptMachine(data));
    },
    getLstMachinesStateColor: (query) => {
      dispatch(actions.getLstMachinesStateColor(query));
    },
    GetLstMachinesGroup: (ma_cty) => {
      dispatch(actionsMachineRpt.GetLstMachinesGroup(ma_cty));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineStatus);
