import React, { useEffect, useState } from "react";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Divider, Tabs, Spin } from "antd";
import { widthDrawer } from "../../index";
import "./index.css";
const { TabPane } = Tabs;

export default function CA1Detail(props) {
  const { isVisible, checkVisible, Master } = props;
  const [isLoading, setLoading] = useState(false);

  const onClose = () => {
    checkVisible(false);
  };
  return (
    <Drawer
      title={`Chi tiết báo có - ${Master.SO_CT}`}
      visible={isVisible}
      width={widthDrawer}
      onClose={onClose}
    >
      <Spin spinning={isLoading}>
        <div className="CA1-detail">
          <General Master={Master} />
          <Divider style={{ margin: 0 }} />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin chi tiết" key="1">
              <ListItem Master={Master} />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </Drawer>
  );
}
