import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Category/customer/index";
import {
  Collapse,
  Form,
  Input,
  Select,
  Spin,
  Drawer,
  Button,
  Checkbox,
  Row,
  Col,
} from "antd";
import RenderOptionSelect from "../../../../controller/RenderOptionsSelect";
import _ from "lodash";
import { widthDrawer } from "../../index";
const { Panel } = Collapse;
const { Option } = Select;
const validateMessages = {
  required: "Trường này là bắt buộc",
};
class EmployeeForm extends Component {
  state = {
    loading: false,
    invoice_account: "",
    tk: "",
    ma_nhkh: "",
    ncc1: "",
    ncc2: "",
    ncc3: "",
    ma_nt: "",
    client: "",
    iskh: false,
    isncc: false,
    isnv: true,
    ma_kh: "",
    barcode: "",
    ten_kh: "",
    dia_chi: "",
    ma_so_thue: "",
    ghi_chu: "",
    nguoi_gd: "",
    tel: "",
    extension: "",
    mobile: "",
    fax: "",
    email: "",
    home_page: "",
    cuser: this.props.userName,
    luser: this.props.userName,
    noneEdit: false,
  };
  isEditAction = () => {
    const { Master } = this.props;
    this.setState({
      invoice_account: Master.INVOICE_ACCOUNT,
      tk: Master.TK,
      ma_nhkh: Master.MA_NHKH,
      ncc1: Master.MA_PLKH1,
      ncc2: Master.MA_PLKH2,
      ncc3: Master.MA_PLKH3,
      ma_nt: Master.MA_NT,
      client: Master.CLIENT,
      iskh: Master.ISKH,
      isncc: Master.ISNCC,
      isnv: Master.ISNV,
      ma_kh: Master.MA_KH,
      barcode: Master.BARCODE,
      ten_kh: Master.TEN_KH,
      dia_chi: Master.DIA_CHI,
      ma_so_thue: Master.MA_SO_THUE,
      ghi_chu: Master.GHI_CHU,
      nguoi_gd: Master.NGUOI_GD,
      tel: Master.TEL,
      extension: Master.EXTENSION,
      mobile: Master.MOBILE,
      fax: Master.FAX,
      email: Master.EMAIL,
      home_page: Master.HOME_PAGE,
    });
  };
  componentDidMount = () => {
    this.props.getLists({ modelM: { ma_cty: this.props.ma_cty } });
    if (!_.isEmpty(this.props.Master)) {
      this.isEditAction();
      this.props.noneEdit ? this.setState({ noneEdit: true }) : <></>;
    }
  };

  handleOk = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      this.props.getItemActions(this.state);
    }
  };

  render() {
    const { isVisible, listDm, title, Master } = this.props;
    const {
      noneEdit,
      loading,
      ma_kh,
      barcode,
      ten_kh,
      dia_chi,
      ma_so_thue,
      nguoi_gd,
      tel,
      mobile,
      extension,
      fax,
      email,
      home_page,
      iskh,
      isncc,
      ghi_chu,
      invoice_account,
      client,
      ncc1,
      ncc2,
      ncc3,
      ma_nhkh,
      ma_nt,
      tk,
    } = this.state;
    return (
      <div>
        <Drawer
          title={title}
          width={widthDrawer}
          onClose={this.handleCancel}
          visible={isVisible}
          maskClosable={false}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                form="submit-form"
                key="submit"
                htmlType="submit"
                style={{
                  display: noneEdit ? "none" : "inline",
                  marginRight: 8,
                }}
              >
                Lưu
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <Collapse
              defaultActiveKey={["1", "2"]}
              ghost
              expandIconPosition="right"
              className="customer-create"
            >
              <Panel header="Thông tin chung" key="1">
                <Form
                  id="submit-form"
                  onFinish={this.handleOk}
                  layout="vertical"
                  validateMessages={validateMessages}
                >
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Mã nhân viên"
                        name="ma_kh"
                        rules={[{ required: true }]}
                        initialValue={_.isEmpty(Master) ? "" : Master.MA_KH}
                      >
                        <Input
                          name="ma_kh"
                          placeholder="Mã nhân viên"
                          value={ma_kh}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Barcode">
                        <Input
                          name="barcode"
                          placeholder="Barcode"
                          value={barcode}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="ten_kh"
                    label="Tên nhân viên"
                    rules={[{ required: true }]}
                    initialValue={_.isEmpty(Master) ? "" : Master.TEN_KH}
                  >
                    <Input
                      name="ten_kh"
                      placeholder="Tên nhân viên"
                      value={ten_kh}
                      onChange={this.handleChangeText}
                      disabled={noneEdit}
                    />
                  </Form.Item>
                  <Form.Item label="Địa chỉ">
                    <Input
                      name="dia_chi"
                      placeholder="Địa chỉ"
                      value={dia_chi}
                      onChange={this.handleChangeText}
                      disabled={noneEdit}
                    />
                  </Form.Item>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Mã số thuế">
                        <Input
                          name="ma_so_thue"
                          placeholder="Mã số thuế"
                          value={ma_so_thue}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Nhóm khách hàng",
                        "Chọn khách hàng",
                        listDm.dm_nhkh,
                        "ma_nhkh",
                        "ten_nhkh",
                        "ma_nhkh",
                        ma_nhkh
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Mã ngoại tệ",
                        "Chọn mã ngoại tệ",
                        listDm.dm_nt,
                        "ma_nt",
                        "ten_nt",
                        "ma_nt",
                        ma_nt
                      )}
                    </Col>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Tài khoản",
                        "Chọn tài khoản",
                        listDm.siaccount,
                        "tk",
                        "ten_tk",
                        "tk",
                        tk
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Xuất hóa đơn",
                        "Chọn công ty xuất hóa đơn ",
                        listDm.dm_kh,
                        "ma_kh",
                        "ten_kh",
                        "invoice_account",
                        invoice_account
                      )}
                    </Col>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Khách hàng",
                        "Chọn khách hàng",
                        listDm.dm_kh,
                        "ma_kh",
                        "ten_kh",
                        "client",
                        client
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Loại NCC1",
                        "Chọn loại NCC1",
                        listDm.dm_plncc1,
                        "ma_plkh",
                        "ten_plkh",
                        "ncc1",
                        ncc1
                      )}
                    </Col>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Loại NCC2",
                        "Chọn loại NCC2",
                        listDm.dm_plncc2,
                        "ma_plkh",
                        "ten_plkh",
                        "ncc2",
                        ncc2
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      {this.renderFormItemSelectOpt(
                        "Loại NCC3",
                        "Chọn loại NCC3",
                        listDm.dm_plncc3,
                        "ma_plkh",
                        "ten_plkh",
                        "ncc3",
                        ncc3
                      )}
                    </Col>
                  </Row>
                  <Form.Item label="Ghi chú">
                    <Input.TextArea
                      name="ghi_chu"
                      placeholder="Ghi chú"
                      value={ghi_chu}
                      onChange={this.handleChangeText}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>
                </Form>
              </Panel>
              <Panel header="Thông tin liên hệ" key="2">
                <Form layout="vertical">
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Người giao dịch">
                        <Input
                          name="nguoi_gd"
                          placeholder="Người giao dịch"
                          value={nguoi_gd}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Số điện thoại">
                        <Input
                          name="tel"
                          placeholder="Số điện thoại"
                          value={tel}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Số nội bộ">
                        <Input
                          name="extension"
                          placeholder="Số nội bộ"
                          value={extension}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Di động">
                        <Input
                          name="mobile"
                          placeholder="Di động"
                          value={mobile}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Fax">
                        <Input
                          name="fax"
                          placeholder="Fax"
                          value={fax}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Email">
                        <Input
                          name="email"
                          placeholder="Email"
                          value={email}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={12}>
                      <Form.Item label="Website">
                        <Input
                          name="home_page"
                          placeholder="Website"
                          value={home_page}
                          onChange={this.handleChangeText}
                          disabled={noneEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </Spin>
        </Drawer>
      </div>
    );
  }
  handleChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  handleChangeText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false, editSuccess: false });
  };
  renderFormItemSelectOpt = (
    label,
    placeholder,
    array,
    valueOpt,
    valueRender,
    valueState,
    initialValue
  ) => {
    return (
      <Form.Item label={label}>
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={(value) => this.handleChange(value, valueState)}
          disabled={this.state.noneEdit}
          value={initialValue}
        >
          <Option value="" key="empty"></Option>
          {!_.isEmpty(this.props.listDm) ? (
            RenderOptionSelect(array, valueOpt, valueRender)
          ) : (
            <></>
          )}
        </Select>
      </Form.Item>
    );
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listDm: state.SICustomerReducer.listDm,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getLists: (ma_cty) => {
      dispatch(actions.getLists(ma_cty));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeForm);
