import CustomerGroupForm from "../customerGroupForm/customerGroupForm";

export default function CustomerGroupDetail(props) {
  const { isVisible, checkVisible, Master } = props;
  return (
    <CustomerGroupForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      Master={Master}
      isEdit={false}
      title="Chi tiết nhóm khách hàng"
    />
  );
}
