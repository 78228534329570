import React, { useEffect, useState } from "react";
import { Form, Spin, Drawer, Button, Row, Col } from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import PropTypes from "prop-types";
import moment from "moment";
import {
  DHSInput,
  DHSCheckBox,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
UserInfoForm.propTypes = {
  objectCreate: PropTypes.object,
};

UserInfoForm.defaultProps = {
  objectCreate: {
    USERNAME: "",
    FULLNAME: "",
    EMAIL: "",
    TITLE_CODE: "",
    TITLE_NAME: "",
    PASSMAIL: "",
    SERVERMAIL: "",
    EMPLOYEECODE: "",
    PASSWORD: "",
    MAILPORT: "",
    ROLENAME: "",
    ISADMIN: false,
    ISSUPERADMIN: false,
    DISABLED: false,
    ENABLESSL: false,
    CUSER: "",
    LUSER: "",
    LINK_IMG: "",
  },
};
const validateMessages = {
  required: "Trường này là bắt buộc",
};
export default function UserInfoForm(props) {
  const {
    isVisible,
    checkVisible,
    title,
    handleActions,
    objectCreate,
    isEdit,
    Master,
  } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [objCreate, setObjCreate] = useState(
    _.isNil(Master) ? objectCreate : Master
  );
  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = () => {
    isEdit ? (objCreate.CUSER = userName) : (objCreate.LUSER = userName);
    handleActions({
      ...objCreate,
      MA_CTY,
    });
  };

  const handleChangeText = async (params) => {
    await setObjCreate({ ...objCreate, [params.name]: params.value });
  };
  const onCheckboxChange = (e, name) => {
    setObjCreate({ ...objCreate, [name]: e.target.checked });
  };
  return (
    <Drawer
      title={title}
      width={500}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8, display: !isEdit ? "none" : "unset" }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Form
        id="submit-form"
        onFinish={handleOk}
        layout="vertical"
        validateMessages={validateMessages}
        style={{ padding: 20 }}
      >
        <Row gutter={[16, 0]}>
          {/* <Col span={12}>
            <Form.Item label="Hình ảnh">
              <Upload
                listType="picture-card"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                showUploadList={false}
                disabled={noneEdit}
              >
                {image ? (
                  <img
                    src={image}
                    alt="avatar"
                    style={{ width: "100%", height: 100 }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DHSInput
              label="Tên đăng nhập"
              name="USERNAME"
              value={objCreate.USERNAME}
              getValueChange={handleChangeText}
              disable={_.isNil(Master) ? !isEdit : true}
            />
          </Col>
          <Col span={12}>
            <DHSInput
              label="Email"
              name="EMAIL"
              value={objCreate.EMAIL}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DHSInput
              label="Tên đầy đủ"
              name="USERNAME"
              value={objCreate.FULLNAME}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
          <Col span={12}>
            <DHSInput
              label="Mật khẩu email"
              name="PASSMAIL"
              value={objCreate.PASSMAIL}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DHSInput
              label="Vị trí"
              name="TITLE_NAME"
              value={objCreate.TITLE_NAME}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
          <Col span={12}>
            <DHSInput
              label="Server mail"
              name="SERVERMAIL"
              value={objCreate.SERVERMAIL}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DHSInput
              label="Mã nhân viên"
              name="EMPLOYEECODE"
              value={objCreate.EMPLOYEECODE}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
          <Col span={12}>
            <DHSInput
              label="Port"
              name="MAILPORT"
              value={objCreate.MAILPORT}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DHSInput
              label="Mật khẩu"
              name="PASSWORD"
              value={objCreate.PASSWORD}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
          <Col span={12}>
            <DHSInput
              label="Vai trò người dùng"
              name="ROLENAME"
              value={objCreate.ROLENAME}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DHSInput
              label="Nhập lại mật khẩu"
              name="REPASSWORD"
              value={objCreate.REPASSWORD}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Col>
          <Col span={12}>
            <DHSCheckBox
              label="Enable SSL"
              defaultChecked={objCreate.ENABLESSL}
              name="ENABLESSL"
              onChange={(params) => onCheckboxChange(params, "ENABLESSL")}
              disabled={!isEdit}
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={4}>
            <DHSCheckBox
              label="Quản trị"
              defaultChecked={objCreate.ISADMIN}
              name="ISADMIN"
              onChange={(params) => onCheckboxChange(params, "ISADMIN")}
              disabled={!isEdit}
            />
          </Col>
          <Col span={7}>
            <DHSCheckBox
              label="Được cấp quyền"
              defaultChecked={objCreate.ISSUPERADMIN}
              name="ISSUPERADMIN"
              onChange={(params) => onCheckboxChange(params, "ISSUPERADMIN")}
              disabled={!isEdit}
            />
          </Col>
          <Col span={4}>
            <DHSCheckBox
              label="KSD"
              defaultChecked={objCreate.DISABLED}
              name="DISABLED"
              onChange={(params) => onCheckboxChange(params, "DISABLED")}
              disabled={!isEdit}
            />
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
