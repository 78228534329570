import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import So3Create from "./so3Create/so3Create";
import So3Detail from "./so3Detail/so3Detail";
import So3Filter from "./so3Filter/so3Filter";
// import ModalSelectRp from "./modalSelectRp/modalSelectRp";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actions from "../../../../redux/actions/Sales/salesInvoice/salesInvoice";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import "./index.css";

const objectColumn = {
  SO_CT: { name: "Số chứng từ", width: 150 },
  NGAY_CT: { name: "Ngày chứng từ", width: 150, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 150 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  T_TT: { name: "Tiền hàng", width: 150, formatMoney: true },
  T_CK: { name: "Chiết khấu", width: 150, formatMoney: true },
  T_THUE: { name: "VAT", width: 150, formatMoney: true },
  MA_NT: { name: "Mã NT", width: 150 },
  TK_PT: { name: "TK PT", witdh: 100 },
  DIEN_GIAI: { name: "Diễn giải", width: 300 },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function SalesInvoice(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const SO3State = useSelector((state) => state.SO3Reducer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isLoading, setLoading] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false);
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListSalesInvoice({ ma_cty }));
    dispatch(actions.getListsDm({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(SO3State.listSalesInvoice);
    setData(SO3State.listSalesInvoice);
    setFullData(SO3State.listSalesInvoice);
    setLoading(false);
  }, [SO3State.listSalesInvoice]);

  useEffect(() => {
    if (SO3State.checkDelete) {
      dispatch(actions.getListSalesInvoice({ ma_cty }));
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [SO3State.checkDelete]);

  const handleOpenDrawer = () => {
    setOpenDrawerCreate(true);
  };

  const handleDelete = (params) => {
    dispatch(actions.deleteASalesInvoice({ modelM: params }));
    setLoading(true);
  };

  const handleEdit = () => {
    if (!_.isEmpty(isMaster)) {
      dispatch(actions.getCTSalesInvoice(isMaster));
      history.push("/SO3Edit", {
        Master: isMaster,
      });
    }
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const checkVisible = (params) => {
    setOpenDrawerCreate(params);
    setOpenDrawerDetail(params);
    setOpenDrawerFilter(params);
    setOpenModalPrint(params);
  };

  // const handlePrint = (params) => {
  //   dispatch(actions.getCTSalesInvoice(params));
  //   setOpenModalPrint(true);
  // };
  return (
    <div className="so3" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Hóa đơn bán hàng"
          onBack={() => history.push("/Sale")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          // setPrint={handlePrint}
          Master={isMaster}
        />
        {/* <ModalSelectRp
          isModalVisible={openModalPrint}
          checkVisible={checkVisible}
          Master={isMaster}
          ctSalesInvoice={toUpperPropertyNameByArray(
            useSelector((state) => state.SO0Reducer.ctSalesInvoice)
          )}
        /> */}
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListSalesInvoice({ ma_cty }));
          }}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
          scroll={{ y: 500, x: 1800 }}
        />
      </Spin>
      {openDrawerDetail ? (
        <So3Detail
          isVisible={openDrawerDetail}
          checkVisible={checkVisible}
          Master={isMaster}
        />
      ) : (
        <></>
      )}
      {openDrawerCreate ? (
        <So3Create
          isVisible={openDrawerCreate}
          checkVisible={checkVisible}
          history={history}
        />
      ) : (
        <></>
      )}
      {openDrawerFilter ? (
        <So3Filter
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
          approvalScr={false}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
