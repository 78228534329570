import React, { Component } from "react";
import { Row, Col } from "antd";
import { FormatMoney } from "../../../../components/controller/Format";
import Barcode from "react-barcode";
import "./index.css";
export default class Rp01 extends Component {
  render() {
    const { title, contentPrint, ctObjPrint, objPrint, isSample } = this.props;
    return (
      <div className="rp01">
        <Row className="rp01Header">
          <Col span={6}>
            <div className="logoRp01">
              <img
                src="http://www.dhsoft.com.vn/upload/photo/logo_12391829082020.png"
                className="img"
              />
            </div>
          </Col>
          <Col span={18}>
            <div className="inforCompany">
              <h4>Công ty TNHH Giải Pháp Phần Mềm DHSoft</h4>
              <h5>
                672A41 , Phan Văn Trị, KDC Cityland Park Hills, Phường 10, Gò
                Vấp, TP.HCM
              </h5>
              <h5>Tel: 0948.47.39.67 - Fax: - Mobile:</h5>
              <h5>
                <a
                  style={{ textDecoration: "none" }}
                  href="http://www.dhsoft.com.vn"
                >
                  www.dhsoft.com.vn / Email: sales@dhsoft.com.vn
                </a>
              </h5>
            </div>
          </Col>
        </Row>
        <div className="titleRp">
          <Row>
            <Col span={14}>
              <h2>{title}</h2>
            </Col>
            <Col span={6} offset={4}>
              <div style={{ textAlign: "right" }}>
                <Barcode
                  value={objPrint.SO_CT}
                  height={30}
                  width={1}
                  fontSize={12}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="contentSentRp">
          <div style={{ fontWeight: "bold", fontSize: 16 }}>
            <i style={{ borderBottom: "1px solid black" }}>Kính gửi: </i>
            <i>Quý khách hàng</i>
          </div>
          <div>
            <p style={{ fontSize: 14, fontStyle: "italic" }}>{contentPrint}</p>
          </div>
        </div>
        <div className="inforOrder">
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="tableOrder">
                <h5>Người nhận: </h5>
                <h5>Địa chỉ: </h5>
                <h5>Điện thoại: </h5>
                <h5>Email: </h5>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="tableOrder">
                <h5>Người gửi:</h5>
                <h5>Chức vụ:</h5>
                <h5>Điện thoại:</h5>
                <h5>Email:</h5>
              </div>
            </Col>
          </Row>
        </div>
        <div className="tableItem">
          {isSample ? <h1 className="sample">Sample</h1> : <></>}
          <table>
            <tbody>
              <tr className="titleTable">
                <th>
                  <h5>STT</h5>
                </th>
                <th>
                  <h5>TÊN THIẾT BỊ VẬT TƯ DỊCH VỤ</h5>
                </th>
                <th style={{ wclassNameth: "90px" }}>
                  <h5>MÃ HÀNG HÓA</h5>
                </th>
                <th style={{ wclassNameth: "40px" }}>
                  <h5>ĐVT</h5>
                </th>
                <th style={{ wclassNameth: "40px" }}>
                  <h5>SL</h5>
                </th>
                <th>
                  <h5>ĐƠN GIÁ (VND)</h5>
                </th>
                <th>
                  <h5>THÀNH TIỀN (VND)</h5>
                </th>
              </tr>
              {ctObjPrint.map((item, index) => {
                return (
                  <tr className="contentTable page-break">
                    <th>
                      <h5>{index + 1}</h5>
                    </th>
                    <th>
                      <h5>{item.TEN_VT}</h5>
                    </th>
                    <th style={{ wclassNameth: "90px" }}>
                      <h5>{item.MA_VT}</h5>
                    </th>
                    <th style={{ wclassNameth: "40px" }}>
                      <h5>{item.DVT}</h5>
                    </th>
                    <th style={{ wclassNameth: "40px" }}>
                      <h5>{item.SO_LUONG}</h5>
                    </th>
                    <th>
                      <h5>{FormatMoney(item.GIA_NT2)}</h5>
                    </th>
                    <th>
                      <h5>{FormatMoney(item.TT_NT)}</h5>
                    </th>
                  </tr>
                );
              })}
              <tr className="bottomTable">
                <th colSpan={6} style={{ textAlign: "right" }}>
                  Cộng
                </th>
                <th>{FormatMoney(objPrint.T_TIEN_NT2)}</th>
              </tr>
              <tr className="bottomTable">
                <th colSpan={6} style={{ textAlign: "right" }}>
                  CK %
                </th>
                <th>{objPrint.TL_CK}</th>
              </tr>
              <tr className="bottomTable">
                <th colSpan={6} style={{ textAlign: "right" }}>
                  VAT %
                </th>
                <th>{objPrint.TS_GTGT}</th>
              </tr>
              <tr className="bottomTable">
                <th colSpan={6} style={{ textAlign: "right" }}>
                  <h5>Tổng cộng</h5>
                </th>
                <th>
                  <h5>{FormatMoney(objPrint.T_TT_NT)}</h5>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="readMoney">
          <p style={{ fontSize: "14px", fontStyle: "italic" }}>
            Số tiền bằng chữ: <b />
          </p>
        </div>
        <div className="note">
          <p style={{ fontSize: "14px" }}>
            <b>Ghi chú:</b>
          </p>
        </div>
        <div className="signature">
          <div
            style={{
              fontSize: "14px",
              textAlign: "right",
              marginRight: "40px",
              fontStyle: "italic",
            }}
          >
            TP Hồ Chí Minh, ngày ......... tháng ......... năm .........
          </div>
          <div>
            <Row style={{ textAlign: "center" }}>
              <Col span={12}>
                <h4>Trân trọng kính chào !</h4>
              </Col>
              <Col span={12}>
                <h4>Đại diện Công ty DHSoft</h4>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
