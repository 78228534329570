import * as types from "../../../constants/ActionTypes";

var initialState = {
  listPromotion: [],
  ctPromotion: [],
  phPromotion: {},
  contactPromotion: [],
  soCt: "",
  newItem: {},
  newEdit: {},
  checkDelete: false,
  lists: {},
  checkDispatch: false,
};

var PromotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_DS2: {
      return {
        ...state,
        listPromotion: action.payload,
      };
    }

    case types.GET_CT_DS2: {
      return {
        ...state,
        ctPromotion: action.payload,
      };
    }
    case types.GET_PH_DS2: {
      return {
        ...state,
        phPromotion: action.payload,
      };
    }

    case types.GET_SO_CT_DS2: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_DS2: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_DS2: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }

    case types.GET_LISTS_DS2: {
      return {
        ...state,
        lists: action.payload,
      };
    }

    case types.DELETE_DS2: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }

    case types.CLEAR_DS2: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctPromotion: [],
        phPromotion: {},
        newItem: null,
      };
    }
    case types.CLEAR_HANDLE_STT_DS2: {
      return {
        ...state,
        checkDelete: false,
        newEdit: null,
        newItem: null,
      };
    }
    default:
      return state;
  }
};

export default PromotionReducer;
