import React, { useEffect, useState } from "react";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSCheckBox,
  DHSInputTextArea,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../redux/actions/Sales/soDiscount/soDiscount";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import "./index.css";
import { ReloadOutlined } from "@ant-design/icons";
import { widthDrawer } from "../../index";

const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};

Ds1Form.propTypes = {
  objectCreate: PropTypes.object,
  arrayCt: PropTypes.array,
};

Ds1Form.defaultProps = {
  objectCreate: {
    TEN_CTCK: "",
    NGAY_CT: moment(),
    MA_NT: "VND",
    DIEN_GIAI: "",
    NGAY_HL: moment(),
    NGAY_HH: moment(),
    IDCUSTOMER: "",
    MA_NHKH: "",
    BYCUSTOMER: false,
    BYCUSTOMERGROUP: false,
    CUSER: "",
    LUSER: "",
  },
  arrayCt: [],
};

export default function Ds1Form(props) {
  const {
    isVisible,
    checkVisible,
    objectCreate,
    handleActions,
    Master,
    isEdit,
    title,
  } = props;

  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const SO_CT = useSelector((state) => state.DS1Reducer.soCt);
  const Lists = useSelector((state) => state.DS1Reducer.lists);
  const [objCreate, setObjCreate] = useState(
    isEdit ? { ...objectCreate, SO_CT } : Master
  );

  useEffect(() => {
    dispatch(actions.getSoCt({ ma_cty }));
  }, []);

  const handleCancel = () => {
    checkVisible(false);
  };

  const handleOk = () => {
    const Discount = {
      modelM: { ...objCreate, SO_CT, ma_cty, CUSER: userName, LUSER: userName },
      arrayCt: [],
    };
    handleActions(Discount);
  };

  const handleChangeText = (params) => {
    setObjCreate({ ...objCreate, [params.name]: params.value });
  };

  const handleCheckBox = (params) => {
    setObjCreate({ ...objCreate, [params.target.name]: params.target.checked });
  };
  const reloadSoCt = () => {
    dispatch(actions.getSoCt({ ma_cty }));
  };
  return (
    <Drawer
      title={title}
      width={widthDrawer}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8, display: isEdit ? "unset" : "none" }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Collapse
        defaultActiveKey={["0", "1", "2"]}
        ghost
        className="ds1-create"
        expandIconPosition="right"
      >
        <Panel header="Thông tin chương trình" key="1">
          <Form
            id="submit-form"
            onFinish={handleOk}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <Row gutter={[16, 0]}>
              <Col span={22}>
                <DHSInput
                  label="Số chiết khấu"
                  name="SO_CT"
                  value={isEdit ? SO_CT : objCreate.SO_CT}
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
              <Col span={2} style={{ alignSelf: "flex-end" }}>
                <ReloadOutlined
                  onClick={reloadSoCt}
                  style={{ fontSize: 25 }}
                  disable={!isEdit}
                />
              </Col>
            </Row>
            <DHSInput
              label="Tên chương trình"
              name="TEN_CTCK"
              value={objCreate.TEN_CTCK}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSDatePicker
                  label="Ngày lập"
                  value={moment(objCreate.NGAY_CT)}
                  nameState="NGAY_CT"
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
              <Col span={12}>
                <DHSSelectOpt
                  label="Mã ngoại tệ"
                  name="MA_NT"
                  array={Lists.dm_nt}
                  valueOpt="MA_NT"
                  valueRender="TEN_NT"
                  defaultValue={objCreate.MA_NT}
                  onChange={(value) =>
                    handleChangeText({ name: "MA_NT", value })
                  }
                  disable={!isEdit}
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSDatePicker
                  label="Ngày hiệu lực"
                  value={moment(objCreate.NGAY_HL)}
                  nameState="NGAY_HL"
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
              <Col span={12}>
                <DHSDatePicker
                  label="Ngày hết hạn"
                  value={moment(objCreate.NGAY_HH)}
                  nameState="NGAY_HH"
                  getValueChange={handleChangeText}
                  disable={!isEdit}
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSCheckBox
                  label="Khách hàng"
                  defaultChecked={objCreate.BYCUSTOMER}
                  name="BYCUSTOMER"
                  onChange={handleCheckBox}
                  disabled={!isEdit}
                />
              </Col>
              <Col span={12}>
                <DHSSelectOpt
                  label="Khách hàng"
                  name="IDCUSTOMER"
                  array={Lists.dm_kh}
                  valueOpt="IDCUSTOMER"
                  valueRender="TEN_KH"
                  defaultValue={objCreate.IDCUSTOMER}
                  onChange={(value) =>
                    handleChangeText({ name: "IDCUSTOMER", value })
                  }
                  disable={!objCreate.BYCUSTOMER || !isEdit}
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <DHSCheckBox
                  label="Nhóm khách hàng"
                  defaultChecked={objCreate.BYCUSTOMERGROUP}
                  name="BYCUSTOMERGROUP"
                  onChange={handleCheckBox}
                  disabled={!isEdit}
                />
              </Col>
              <Col span={12}>
                <DHSSelectOpt
                  label="Nhóm khách hàng"
                  name="MA_NHKH"
                  array={Lists.dm_nhkh}
                  valueOpt="MA_NHKH"
                  valueRender="TEN_NHKH"
                  defaultValue={objCreate.MA_NHKH}
                  onChange={(value) =>
                    handleChangeText({ name: "MA_NHKH", value })
                  }
                  disable={!objCreate.BYCUSTOMERGROUP || !isEdit}
                />
              </Col>
            </Row>
            <DHSInputTextArea
              label="Diễn giải"
              name="DIEN_GIAI"
              value={objCreate.DIEN_GIAI}
              getValueChange={handleChangeText}
              disable={!isEdit}
            />
          </Form>
        </Panel>
      </Collapse>
    </Drawer>
  );
}
