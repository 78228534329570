import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./App.css";
import routes from "./routes";
import "antd/dist/antd.css";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sessionLogin, getCategory } from "../src/redux/actions/Login/index";
import { getListItem } from "../src/redux/actions/Category/Item/index";
import { getListInventory } from "../src/redux/actions/Category/Inventory/index";
function App() {
  const IsLogin = useSelector((state) => state.LoginReducer.isLogin);
  const SessionLogin = JSON.parse(localStorage.getItem("SessionLogin"));
  const dispatch = useDispatch();
  const dispatchSessionLogin = (userName, ma_cty, companyName) =>
    dispatch(sessionLogin(userName, ma_cty, companyName));
  const dispatchCategory = (userName) =>
    dispatch(getCategory({ USERNAME: userName }));
  const dispatchListsLocal = (ma_cty) => {
    dispatch(getListItem({ ma_cty }));
    dispatch(getListInventory({ ma_cty }));
  };
  const history = useHistory();
  return (
    <>
      <Switch>
        {routes.length > 0
          ? routes.map((route, index) => {
              if (IsLogin || route.path == "/login" || SessionLogin !== null) {
                if (!IsLogin && route.path != "/login") {
                  dispatchSessionLogin(
                    SessionLogin.userName,
                    SessionLogin.ma_cty,
                    SessionLogin.companyName
                  );
                  dispatchCategory(SessionLogin.userName);
                  dispatchListsLocal(SessionLogin.ma_cty);
                  if (window.performance) {
                    if (window.performance.navigation.type == 1) {
                      history.push(window.location.pathname);
                    } else {
                      history.push("/");
                    }
                  }
                }
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.main}
                  />
                );
              } else {
                return <Redirect push from="/" to="/login" key={"logout"} />;
              }
            })
          : null}
      </Switch>
    </>
  );
}

export default App;
