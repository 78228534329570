import React from "react";
import ItemForm from "../../../Category/Item/itemForm/itemForm";

export default function ItemDetail(props) {
  const { isVisible, Master, checkVisible } = props;
  const checkVisibleFunc = (params) => {
    checkVisible(params);
  };
  return (
    <div>
      <ItemForm
        isVisible={isVisible}
        checkVisible={checkVisibleFunc}
        title="Thông tin vật tư"
        Master={Master}
        noneEdit={true}
      />
    </div>
  );
}
