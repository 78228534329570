import * as types from "../../../constants/ActionTypes";
var initialState = {
  objCrmCustomer: {},
  listCrmCustomer: [],
  listDm: {},
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
};

var CrmCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_CRMCUSTOMER:
      return {
        ...state,
        listCrmCustomer: action.payload,
      };
    case types.GET_LISTS_CRMCUSTOMER: {
      return {
        ...state,
        listDm: action.payload,
      };
    }
    // case types.GET_CUSTOMER: {
    //   return {
    //     ...state,
    //     objCrmCustomer: action.payload,
    //   };
    // }
    case types.ADD_NEW_CRMCUSTOMER: {
      return {
        ...state,
        checkInsert: action.payload,
      };
    }
    // case types.ADD_NEW_ADDRESS: {
    //   if (typeof action.item === "object") {
    //     state.listNewAddress.push(action.item);
    //   } else if (typeof action.item === "number") {
    //     state.listNewAddress.splice(action.item, 1);
    //   }
    //   return { ...state };
    // }
    case types.DELETE_CRMCUSTOMER: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.UPDATE_CRMCUSTOMER: {
      return {
        ...state,
        checkUpdate: action.payload,
      };
    }
    case types.CLEAR_CRMCUSTOMER: {
      return {
        ...state,
        checkDelete: false,
        checkInsert: false,
        checkUpdate: false,
        listCrmCustomer: [],
      };
    }
    default:
      return state;
  }
};

export default CrmCustomerReducer;
