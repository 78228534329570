import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, PageHeader } from "antd";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import DHSDataGrid from "../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import * as actions from "../../../../redux/actions/Inventory/Reports/INReports";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import * as actionsLogin from "../../../../redux/actions/Login/index";
import InfoReportsBar from "../../../../common/DHSControl/InfoReportsBar/InfoReportsBar";
import "./index.css";

export default function INReports(props) {
  const {
    history,
    rpName,
    title,
    setOpenDrawerFilter,
    setObjFilter,
    objFilter,
    dataKey,
  } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);

  const [isColumns, setColumns] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const listDm = useSelector((state) => state.INReportsReducer.listDm);
  const listDataFilterINRpt = useSelector(
    (state) => state.INReportsReducer.listDataFilterINRpt
  );

  useEffect(() => {
    setData(listDataFilterINRpt);
    setFullData(listDataFilterINRpt);
    setLoading(false);
    return () => {
      listDataFilterINRpt.length = 0;
    };
  }, [listDataFilterINRpt]);

  useEffect(() => {
    setLoading(true);
    if (_.isEmpty(listDm)) {
      dispatch(actions.GetListsDm({ ma_cty }));
    }
    dispatch(actionsLogin.getListCompany());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(listDm[rpName])) {
      let obj = listDm[rpName][0];
      let lstColumnsString = obj.FORMATED_COL_LIST.split(",");
      const data = [];
      _.map(lstColumnsString, (item, index) => {
        let s1 = item.split(":");
        data.push({
          caption: s1.find((x) => x.includes("H=")).substring(2),
          dataField: s1[0],
          type: 0,
        });
      });
      setColumns(data);
    }
  }, [listDm]);

  useEffect(() => {
    setLoading(false);
  }, [isColumns]);

  const getDataFilter = () => {};
  return (
    <div className="INReports">
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title={title}
          onBack={() => history.push("/Inventory")}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <InfoReportsBar
          listRp={listDm[rpName]}
          setObjFilter={setObjFilter}
          objFilter={objFilter}
        />
        <DHSDataGrid
          column={isColumns}
          data={data}
          dataKey={dataKey}
          selectionChanged={() => {}}
          hanleRowChange={() => {}}
          exportExcel={true}
          disabled={false}
        />
        {/* {OpenDrawerFilter ? (
          <Filter isVisible={OpenDrawerFilter} checkVisible={checkVisible} />
        ) : (
          <></>
        )} */}
      </Spin>
    </div>
  );
}
