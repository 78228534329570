import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../redux/actions/Purchases/purchaseOrder/purchaseOrder";
import * as actionsInventory from "../../../../../redux/actions/Category/Inventory/index";
import * as actionsItem from "../../../../../redux/actions/Category/Item/index";
import { periodData } from "../../../../controller/DataSample";
import {
  onPeriodChange,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
Po1Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

Po1Filter.defaultProps = {
  _objectFilter: {
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    IDCUSTOMER: "",
    FORMDATE: moment().startOf("month").add(7, "hours"),
    TODATE: moment().endOf("month").add(7, "hours"),
    SO_CT1: "",
    SO_CT2: "",
    MA_KHO: "",
    IDITEM: "",
    MA_GD: "",
    INCOTERM_ID: "",
    MA_HD: "",
    MA_BP: "",
    STATUSDOCUMENT: "",
    TRANG_THAI: "",
  },
};
export default function Po1Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const lstInventory = useSelector(
    (state) => state.InventoryReducer.lstInventory
  );
  const PO1State = useSelector((state) => state.PO1Reducer);
  const listItem = useSelector((state) => state.ItemReducer.listItem);
  const [objFilter, setObjFilter] = useState(_objectFilter);

  useEffect(() => {
    dispatch(actionsItem.getListItem({ ma_cty }));
    dispatch(actionsInventory.getListInventory({ ma_cty }));
  }, []);

  const handleCancel = (e) => {
    checkVisible(false);
  };
  const handleOk = () => {
    dispatch(
      actions.filt({
        modelM: {
          ma_cty,
          idcustomer: objFilter.IDCUSTOMER,
          cdate: objFilter.FORMDATE,
          ldate: objFilter.TODATE,
          ma_gd: objFilter.MA_GD,
          INCOTERM_ID: objFilter.INCOTERM_ID,
          MA_HD: objFilter.MA_HD,
          MA_BP: objFilter.MA_BP,
          STATUSDOCUMENT: objFilter.STATUSDOCUMENT,
          TRANG_THAI: objFilter.TRANG_THAI,
        },
        ...objFilter,
      })
    );
    checkVisible(false);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate.add(7, "hours"),
      TODATE: result.toDate.add(7, "hours"),
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Tìm kiếm"
        width={"30%"}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Tìm kiếm
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="po2-filter"
        >
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD.toString()}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FORMDATE}
                nameState="FORMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Nhà cung cấp"
            name="IDCUSTOMER"
            array={PO1State.listCustomer}
            valueOpt={"IDCUSTOMER"}
            valueRender={"TEN_KH"}
            onChange={(value) => handleChange(value, "IDCUSTOMER")}
            valueEmpty={true}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSInput
                label="Số phiếu từ"
                name="SO_CT1"
                value={objFilter.SO_CT1}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Số phiếu đến"
                name="SO_CT2"
                value={objFilter.SO_CT2}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Kiểu phiếu"
                name="MA_GD"
                array={PO1State.sysExchange}
                valueOpt={"MA_GD"}
                valueRender={"MO_TA"}
                onChange={(value) => handleChangeText({ value, name: "MA_GD" })}
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Điều kiện giao hàng"
                name="INCOTERM_ID"
                array={PO1State.lstIncoterms}
                valueOpt={"INCOTERM_ID"}
                valueRender={"INCOTERM_NAME"}
                onChange={(value) =>
                  handleChangeText({ value, name: "INCOTERM_ID" })
                }
                valueEmpty={true}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Hợp đồng"
                name="MA_HD"
                array={PO1State.dm_hopdong_mua}
                valueOpt={"MA_HD"}
                valueRender={"TEN_HD"}
                onChange={(value) => handleChangeText({ value, name: "MA_HD" })}
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Bộ phận"
                name="MA_BP"
                array={PO1State.dm_bp}
                valueOpt={"MA_BP"}
                valueRender={"TEN_BP"}
                onChange={(value) => handleChangeText({ value, name: "MA_BP" })}
                valueEmpty={true}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Trạng thái"
                array={PO1State.listStatus}
                valueOpt={"trang_thai"}
                valueRender={"mo_ta"}
                onChange={(value) =>
                  handleChangeText({ value, name: "TRANG_THAI" })
                }
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Trạng thái GD"
                array={PO1State.dm_trangthaiDoc_PO1}
                valueOpt={"TRANG_THAI"}
                valueRender={"MO_TA"}
                onChange={(value) =>
                  handleChangeText({ value, name: "STATUSDOCUMENT" })
                }
                valueEmpty={true}
              />
            </Col>
          </Row>
          <DHSSelectOpt
            label="Kho"
            name="MA_KHO"
            array={lstInventory}
            valueOpt={"MA_KHO"}
            valueRender={"TEN_KHO"}
            onChange={(value) => handleChange(value, "MA_KHO")}
            valueEmpty={true}
          />
          <DHSSelectOpt
            label="Vật tư"
            name="IDITEM"
            array={listItem}
            valueOpt={"iditem"}
            valueRender={"ten_vt"}
            onChange={(value) => handleChange(value, "IDITEM")}
            valueEmpty={true}
          />
        </Form>
      </Drawer>
    </div>
  );
}
