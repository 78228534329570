import React from "react";
import _ from "lodash";

import SalesDB from "../../components/layout/Dashboard/SalesDB/SalesDB";
import InventoryRoutes from "./inventory/inventoryRoutes";
import CategoryRoutes from "./category/categoryRoutes";
import ManufacturingRoutes from "./manufacturing/manufacturingRoutes";
import SalesRoutes from "./sales/salesRoutes";
import PurchaseRoutes from "./purchases/purchasesRoutes";
import CustomerServicesRoutes from "./customerServices/customerServicesRoutes";
import DashBoardRoutes from "./dashBoard/dashBoardRoutes";
import SystemRoutes from "./system/systemRoutes";
import CashAndBankRoutes from "./cashAndBank/cashAndBankRoutes";
var routes = [
  {
    path: "/salesDB",
    exact: false,
    main: ({ history }) => <SalesDB history={history} />,
  },
];
const exportArrayToObject = (array) => {
  routes = _.concat(routes, array);
};
exportArrayToObject(CategoryRoutes);
exportArrayToObject(ManufacturingRoutes);
exportArrayToObject(InventoryRoutes);
exportArrayToObject(SalesRoutes);
exportArrayToObject(PurchaseRoutes);
exportArrayToObject(CustomerServicesRoutes);
exportArrayToObject(DashBoardRoutes);
exportArrayToObject(SystemRoutes);
exportArrayToObject(CashAndBankRoutes);
export default routes;
