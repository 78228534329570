import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Collapse, Button } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../redux/actions/Category/customer/index";
import PropTypes from "prop-types";
import _ from "lodash";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
const { Panel } = Collapse;

AddCustomers.propTypes = {
  _objectCreate: PropTypes.object,
};

AddCustomers.defaultProps = {
  _objectCreate: {
    IDCUSTOMER: "",
    TEN_KH: "",
    MA_CTY: "",
    LUSER: "",
    CUSER: "",
    DIA_CHI: "",
    EMAIL: "",
    TEL: "",
    MA_KH: "",
    GHI_CHU: "",
  },
  lstItemSelectedFromSearch: [],
};

export default function AddCustomers(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, _objectCreate, getCustomer } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [objCreate, setObjCreate] = useState(_objectCreate);
  const listCustomer = useSelector(
    (state) => state.SICustomerReducer.listCustomer
  );
  const newCustomer = useSelector(
    (state) => state.SICustomerReducer.checkAddNewCustomer
  );
  useEffect(() => {
    dispatch(
      actions.getListCustomerAR({
        modelM: { ma_cty },
      })
    );
  }, []);

  useEffect(() => {
    if (!_.isEmpty(newCustomer)) {
      dispatch(actions.clearCache());
      getCustomer(newCustomer);
      checkVisible(false);
    }
  }, [newCustomer]);

  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = () => {
    if (!_.isEmpty(objCreate.IDCUSTOMER)) {
      getCustomer(objCreate);
      checkVisible(false);
    } else {
      var model = {
        modelM: {
          ...objCreate,
          ...{
            SKU: objCreate.MA_KH,
            ma_cty,
            CUSER: userName,
            LUSER: userName,
            iskh: true,
          },
        },
        arraylstCustomerAddress: [],
      };

      dispatch(actions.addNewCustomer(model));
    }
  };
  const handleChangeText = (params) => {
    setObjCreate({ ...objCreate, [params.name]: params.value });
  };

  return (
    <div>
      <Modal
        id="submit-form"
        width={1000}
        title="Thêm khách hàng"
        visible={isVisible}
        onCancel={handleCancel}
        footer={
          <Row gutter={[16, 16]}>
            <Col span={3} offset={19}>
              <Button type="primary" onClick={handleOk}>
                Đồng ý
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={handleCancel}>Hủy</Button>
            </Col>
          </Row>
        }
      >
        <Form layout="vertical" validateMessages={validateMessages}>
          {/* <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Filter"
                name="IDCUSTOMER"
                array={listCustomer}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                onChange={handleKHChange}
                valueEmpty={true}
              />
            </Col>
          </Row> */}
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSInput
                label="Tên khách hàng"
                name="TEN_KH"
                getValueChange={handleChangeText}
                value={objCreate.TEN_KH}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Mã khách hàng"
                name="MA_KH"
                getValueChange={handleChangeText}
                value={objCreate.MA_KH}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSInput
                label="Số điện thoại"
                name="TEL"
                getValueChange={handleChangeText}
                value={objCreate.TEL}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Địa chỉ"
                name="DIA_CHI"
                getValueChange={handleChangeText}
                value={objCreate.DIA_CHI}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSInput
                label="Email"
                name="EMAIL"
                getValueChange={handleChangeText}
                value={objCreate.EMAIL}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Ghi chú"
                name="GHI_CHU"
                getValueChange={handleChangeText}
                value={objCreate.GHI_CHU}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
