import React, { Component } from "react";
import { Drawer } from "antd";
import { Divider, Spin, Descriptions } from "antd";
import General from "./General/general";
import ListItem from "./ListItem/listItem";
import "./index.css";
import * as actions from "../../../../../redux/actions/Purchases/purchaseReceipt/purchaseReceipt";
import { connect } from "react-redux";
import { FormatMoney } from "../../../../controller/Format";

class PO2Detail extends Component {
  state = {
    loading: false,
  };
  componentDidMount = () => {
    const { Master } = this.props;
    this.props.getCustomer({
      ma_cty: this.props.ma_cty,
      idCustomer: Master.IDCUSTOMER,
    });
    this.props.getCTPurchaseReceipt({
      stt_rec: Master.STT_REC,
      ma_cty: this.props.ma_cty,
    });
    this.props.getListStatus();
  };
  onClose = () => {
    this.props.checkVisible(false);
  };
  componentWillUnmount = () => {
    this.props.clearCache();
  };
  render() {
    const { loading } = this.state;
    const { isVisible, Master, customer, ctPurchaseReceipt } = this.props;
    return (
      <Drawer
        title={`Chi tiết phiếu nhập - ${Master.SO_CT}`}
        visible={isVisible}
        width={"60%"}
        onClose={this.onClose}
      >
        <div className="po2-detail">
          <Spin spinning={loading}>
            <General Master={Master} customer={customer} />
            <Divider style={{ margin: 0 }} />
            <ListItem
              dsCT={ctPurchaseReceipt}
              amount={Master.T_TT}
              ma_nt={Master.MA_NT}
            />
            <div className="detailTotal">
              <Descriptions bordered size="small">
                <Descriptions.Item label="Tổng tiền :" span={3}>
                  {FormatMoney(Master.T_TT)}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Spin>
        </div>
      </Drawer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    newEdit: state.PO2Reducer.newEdit,
    customer: state.PO2Reducer.customer,
    ctPurchaseReceipt: state.PO2Reducer.ctPurchaseReceipt,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getCustomer: (query) => {
      dispatch(actions.getCustomer(query));
    },
    getCTPurchaseReceipt: (detail) => {
      dispatch(actions.getCTPurchaseReceipt(detail));
    },
    getListStatus: () => {
      dispatch(actions.getListStatus());
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PO2Detail);
