//Login
export const GET_LIST_COMPANY = "GET_LIST_COMPANY";
export const SESSION_LOGIN = "SESSION_LOGIN";
export const LOGIN = "LOGIN";
export const LOGIN_POS = "LOGIN_POS";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
//Home
export const GET_CATEGORY = "GET_CATEGORY_LV1";

//Logout
export const DESTROY_SESSION = "DESTROY_SESSION";

//Machine status
export const GET_MACHINE_STATE = "GET_MACHINE_STATE";
export const GET_MACHINE_STATE_NEW_UPDATE = "GET_MACHINE_STATE_NEW_UPDATE";
export const CHANGE_STATE_UPDATE = "CHANGE_STATE_UPDATE";
export const GET_TIME_UPDATE = "GET_TIME_UPDATE";
export const GET_MACHINE_STATE_COLOR = "GET_MACHINE_STATE_COLOR";
//Machine report
export const GET_RPT_MACHINE_UPTIME_TOTALALL =
  "GET_RPT_MACHINE_UPTIME_TOTALALL";
export const GET_RPT_MACHINE_UPTIME = "GET_RPT_MACHINE_UPTIME";
export const GET_RPT_MACHINE_TIMELINE = "GET_RPT_MACHINE_TIMELINE";
export const CONTAIN_RPT_MACHINE = "CONTAIN";
export const GET_LIST_MACHINE = "GET_LIST_MACHINE";
export const GET_RPT_MACHINE_WORK_SHIFT = "GET_RPT_MACHINE_WORK_SHIFT";
export const GET_MACHINE_ACTION_STATE = "GET_MACHINE_ACTION_STATE";
export const GET_GROUP_MACHINE = "GET_GROUP_MACHINE";
//Machine master planning
export const GET_PLANNING_DEADLINE_BY_MACHINE =
  "GET_PLANNING_DEADLINE_BY_MACHINE";
export const GET_PLANNING_DEADLINE_BY_WORKORDER =
  "GET_PLANNING_DEADLINE_BY_WORKORDER";
//Sale
//Quotation
export const GET_LIST_SO0 = "GET_LIST_SO0";
export const GET_CT_SO0 = "GET_CT_SO0";
export const GET_PH_SO0 = "GET_PH_SO0";
export const GET_CUSTOMER_SO0 = "GET_CUSTOMER_SO0";
export const LIST_SELECTED_PRODUCT_SO0 = "LIST_SELECTED_PRODUCT_SO0";
export const GET_LIST_CUSTOMER_SO0 = "GET_LIST_CUSTOMER_SO0";
export const GET_SO_CT_SO0 = "GET_SO_CT_SO0";
export const GET_LIST_DM_SO0 = "GET_LIST_DM_SO0";
export const GET_LIST_CURRENCY_SO0 = "GET_LIST_CURRENCY_SO0";
export const GET_EXCHANGE_RATE_SO0 = "GET_EXCHANGE_RATE_SO0";
export const ADD_NEW_SO0 = "ADD_NEW_SO0";
export const DELETE_SO0 = "DELETE_SO0";
export const UPDATE_SO0 = "UPDATE_SO0";
export const CLEAR_SO0 = "CLEAR_SO0";
export const FILT_SO0 = "FILT_SO0";
export const GET_CONTACT_SO0 = "GET_CONTACT_SO0";
export const CLEAR_HANDLE_STT_SO0 = "CLEAR_HANDLE_STT_SO0";
//ApprovalQuotation
export const GET_LIST_APPROVAL_SO0 = "GET_LIST_APPROVAL_SO0";
export const APPROVAL_QUOTATION = "APPROVAL_QUOTATION";
export const REJECT_QUOTATION = "REJECT_QUOTATION";
export const CLEAR_APPROVAL_SO0 = "CLEAR_APPROVAL_SO0";
//PackingSlip
export const GET_LIST_SO2 = "GET_LIST_SO2";
export const GET_CT_SO2 = "GET_CT_SO2";
export const GET_PH_SO2 = "GET_PH_SO2";
export const GET_CUSTOMER_SO2 = "GET_CUSTOMER_SO2";
export const LIST_SELECTED_PRODUCT_SO2 = "LIST_SELECTED_PRODUCT_SO2";
export const GET_LIST_CUSTOMER_SO2 = "GET_LIST_CUSTOMER_SO2";
export const GET_SO_CT_SO2 = "GET_SO_CT_SO2";
export const GET_LIST_DM_SO2 = "GET_LIST_DM_SO2";
export const GET_LIST_CURRENCY_SO2 = "GET_LIST_CURRENCY_SO2";
export const GET_EXCHANGE_RATE_SO2 = "GET_EXCHANGE_RATE_SO2";
export const ADD_NEW_SO2 = "ADD_NEW_SO2";
export const DELETE_SO2 = "DELETE_SO2";
export const UPDATE_SO2 = "UPDATE_SO2";
export const CLEAR_SO2 = "CLEAR_SO2";
export const CLEAR_HANDLE_STT_SO2 = "CLEAR_HANDLE_STT_SO2";
export const FILT_SO2 = "FILT_SO2";
export const LOOKUP_SO_SO1 = "LOOKUP_SO_SO1";
export const LIST_CT_CHOOSE_FROM_SO = "LIST_CT_CHOOSE_FROM_SO";
export const GET_CONTACT_SO2 = "GET_CONTACT_SO2";
export const GET_DRIVER_SO2 = "GET_DRIVER_SO2";
export const UPDATE_VOUCHER_NUMBER = "UPDATE_VOUCHER_NUMBER";
//SalesOrder
export const GET_LIST_SO1 = "GET_LIST_SO1";
export const GET_CT_SO1 = "GET_CT_SO1";
export const GET_PH_SO1 = "GET_PH_SO1";
export const GET_CUSTOMER_SO1 = "GET_CUSTOMER_SO1";
export const LIST_SELECTED_PRODUCT_SO1 = "LIST_SELECTED_PRODUCT_SO1";
export const GET_LIST_CUSTOMER_SO1 = "GET_LIST_CUSTOMER_SO1";
export const GET_SO_CT_SO1 = "GET_SO_CT_SO1";
export const GET_LIST_DM_SO1 = "GET_LIST_DM_SO1";
export const GET_LIST_CURRENCY_SO1 = "GET_LIST_CURRENCY_SO1";
export const GET_EXCHANGE_RATE_SO1 = "GET_EXCHANGE_RATE_SO1";
export const ADD_NEW_SO1 = "ADD_NEW_SO1";
export const DELETE_SO1 = "DELETE_SO1";
export const UPDATE_SO1 = "UPDATE_SO1";
export const LOOKUP_SO_QUOTATION = "LOOKUP_SO_QUOTATION";
export const CLEAR_SO1 = "CLEAR_SO1";
export const CLEAR_HANDLE_STT_SO1 = "CLEAR_HANDLE_STT_SO1";
export const FILT_SO1 = "FILT_SO1";
export const LIST_CT_CHOOSE_FROM_QU = "LIST_CT_CHOOSE_FROM_QU";
export const GET_CONTACT_SO1 = "GET_CONTACT_SO1";
//Sales Return
export const GET_LIST_SO4 = "GET_LIST_SO4";
export const GET_CT_SO4 = "GET_CT_SO4";
export const GET_PH_SO4 = "GET_PH_SO4";
export const GET_CUSTOMER_SO4 = "GET_CUSTOMER_SO4";
export const LIST_SELECTED_PRODUCT_SO4 = "LIST_SELECTED_PRODUCT_SO4";
export const GET_SO_CT_SO4 = "GET_SO_CT_SO4";
export const GET_LIST_STATUS_SO4 = "GET_LIST_STATUS_SO4";
export const GET_EXCHANGE_RATE_SO4 = "GET_EXCHANGE_RATE_SO4";
export const ADD_NEW_SO4 = "ADD_NEW_SO4";
export const DELETE_SO4 = "DELETE_SO4";
export const UPDATE_SO4 = "UPDATE_SO4";
export const CLEAR_SO4 = "CLEAR_SO4";
export const CLEAR_HANDLE_STT_SO4 = "CLEAR_HANDLE_STT_SO4";
export const FILT_SO4 = "FILT_SO4";
export const GET_LISTS_DM = "GET_LISTS_DM";
//Sales Invoice
export const GET_LIST_SO3 = "GET_LIST_SO3";
export const GET_CT_SO3 = "GET_CT_SO3";
export const GET_PH_SO3 = "GET_PH_SO3";
export const GET_CUSTOMER_SO3 = "GET_CUSTOMER_SO3";
export const LIST_SELECTED_PRODUCT_SO3 = "LIST_SELECTED_PRODUCT_SO3";
export const GET_SO_CT_SO3 = "GET_SO_CT_SO3";
export const GET_LIST_STATUS_SO3 = "GET_LIST_STATUS_SO3";
export const GET_EXCHANGE_RATE_SO3 = "GET_EXCHANGE_RATE_SO3";
export const ADD_NEW_SO3 = "ADD_NEW_SO3";
export const DELETE_SO3 = "DELETE_SO3";
export const UPDATE_SO3 = "UPDATE_SO3";
export const CLEAR_SO3 = "CLEAR_SO3";
export const CLEAR_HANDLE_STT_SO3 = "CLEAR_HANDLE_STT_SO3";
export const FILT_SO3 = "FILT_SO3";
export const GET_LISTS_DM_SO3 = "GET_LISTS_DM_SO3";
//ApprovalSalesOrder
export const GET_LIST_APPROVAL_SO1 = "GET_LIST_APPROVAL_SO1";
export const APPROVAL_SALESORDER = "APPROVAL_SALESORDER";
export const REJECT_SALESORDER = "REJECT_SALESORDER";
export const CLEAR_APPROVAL_SO1 = "CLEAR_APPROVAL_SO1";
//SO Discount
export const GET_LIST_DS1 = "GET_LIST_DS1";
export const GET_CT_DS1 = "GET_CT_DS1";
export const GET_PH_DS1 = "GET_PH_DS1";
export const ADD_NEW_DS1 = "ADD_NEW_DS1";
export const DELETE_DS1 = "DELETE_DS1";
export const UPDATE_DS1 = "UPDATE_DS1";
export const GET_SO_CT_DS1 = "GET_SO_CT_DS1";
export const CLEAR_DS1 = "CLEAR_DS1";
export const GET_LISTS_DS1 = "GET_LISTS_DS1";
export const CLEAR_HANDLE_STT_DS1 = "CLEAR_HANDLE_STT_DS1";

//SO Promotion
export const GET_LIST_DS2 = "GET_LIST_DS2";
export const GET_CT_DS2 = "GET_CT_DS2";
export const GET_PH_DS2 = "GET_PH_DS2";
export const ADD_NEW_DS2 = "ADD_NEW_DS2";
export const DELETE_DS2 = "DELETE_DS2";
export const UPDATE_DS2 = "UPDATE_DS2";
export const GET_SO_CT_DS2 = "GET_SO_CT_DS2";
export const CLEAR_DS2 = "CLEAR_DS2";
export const GET_LISTS_DS2 = "GET_LISTS_DS2";
export const CLEAR_HANDLE_STT_DS2 = "CLEAR_HANDLE_STT_DS2";

//SO SellPriceBase
export const GET_LIST_SELL_PRICE = "GET_LIST_SELL_PRICE";
export const GET_LISTS_SELL_PRICE = "GET_LISTS_SELL_PRICE";
export const ADD_NEW_SELL_PRICE = "ADD_NEW_SELL_PRICE";
export const DELETE_SELL_PRICE = "DELETE_SELL_PRICE";
export const UPDATE_SELL_PRICE = "UPDATE_SELL_PRICE";
export const CLEAR_SELL_PRICE = "CLEAR_SELL_PRICE";
export const GET_LOOKUP_SELECTED_ITEM = "GET_LOOKUP_SELECTED_ITEM";
export const GET_LOOKUP_SELECTED_ITEM2 = "GET_LOOKUP_SELECTED_ITEM2";
//SO SellPriceType
export const GET_LIST_SELL_PRICE_TYPE = "GET_LIST_SELL_PRICE_TYPE";
export const ADD_NEW_SELL_PRICE_TYPE = "ADD_NEW_SELL_PRICE_TYPE";
export const DELETE_SELL_PRICE_TYPE = "DELETE_SELL_PRICE_TYPE";
export const UPDATE_SELL_PRICE_TYPE = "UPDATE_SELL_PRICE_TYPE";
export const CLEAR_SELL_PRICE_TYPE = "CLEAR_SELL_PRICE_TYPE";
//SO Reports
export const GET_LISTS_DM_SO_REPORT = "GET_LISTS_DM_SO_REPORT";
export const GET_RPT_SO_BK_01 = "GET_RPT_SO_BK_01";
export const GET_RPT_SODH0301 = "GET_RPT_SODH0301";
export const POS_RPT_REVENUE_BY_INVOICE = "POS_RPT_REVENUE_BY_INVOICE";
export const POS_RPT_REVENUE_BY_ITEM = "POS_RPT_REVENUE_BY_ITEM";
//Category
//Vật tư
export const GET_LIST_ITEM = "GET_LIST_ITEM";
export const GET_LIST_DM = "GET_LIST_DM";
export const INSERT_ITEM = "INSERT_ITEM";
export const DELETE_ITEM = "DETELE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const CLEAR_ITEM = "CLEAR_ITEM";
//Kho
export const GET_LIST_INVENTORY = "GET_LIST_INVENTORY";
//Color
export const GET_LIST_ITEM_COLOR = "GET_LIST_ITEM_COLOR";
//Size
export const GET_LIST_ITEM_SIZE = "GET_LIST_ITEM_SIZE";
//Category Customer
export const GET_LIST_CUSTOMER_SI = "GET_LIST_CUSTOMER_SI";
export const GET_LISTS = "GET_LISTS";
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
//Nhóm khách hàng
export const GET_LIST_CUS_GROUP = "GET_LIST_CUS_GROUP";
export const INSERT_CUS_GROUP = "INSERT_CUS_GROUP";
export const DELETE_CUS_GROUP = "DETELE_CUS_GROUP";
export const UPDATE_CUS_GROUP = "UPDATE_CUS_GROUP";
export const CLEAR_CUS_GROUP = "CLEAR_CUS_GROUP";
//Phân loại khách hàng
export const GET_LIST_CUS_KIND = "GET_LIST_CUS_KIND";
export const INSERT_CUS_KIND = "INSERT_CUS_KIND";
export const DELETE_CUS_KIND = "DETELE_CUS_KIND";
export const UPDATE_CUS_KIND = "UPDATE_CUS_KIND";
export const CLEAR_CUS_KIND = "CLEAR_CUS_KIND";
//CS CrmCustomer
export const GET_LIST_CRMCUSTOMER = "GET_LIST_CRMCUSTOMER";
export const GET_LISTS_CRMCUSTOMER = "GET_LISTS_CRMCUSTOMER";
export const ADD_NEW_CRMCUSTOMER = "ADD_NEW_CRMCUSTOMER";
export const DELETE_CRMCUSTOMER = "DELETE_CRMCUSTOMER";
export const UPDATE_CRMCUSTOMER = "UPDATE_CRMCUSTOMER";
export const CLEAR_CRMCUSTOMER = "CLEAR_CRMCUSTOMER";
export const GET_CRMCUSTOMER = "GET_CRMCUSTOMER";
//CS CrmCustomerRequest
export const ADD_NEW_CRMCUSTOMER_REQUEST = "ADD_NEW_CRMCUSTOMER_REQUEST";
export const GET_SO_CT_CSR = "GET_SO_CT_CSR";
export const GET_LIST_REQUEST_BY_IDCUSTOMER = "GET_LIST_REQUEST_BY_IDCUSTOMER";
export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const CLEAR_CUSTOMER_REQUEST = "CLEAR_CUSTOMER_REQUEST";
export const UPDATE_CRMCUSTOMER_REQUEST = "UPDATE_NEW_CRMCUSTOMER_REQUEST";
//Nhóm Vật tư
export const GET_LIST_ITEMGROUP = "GET_LIST_ITEMGROUP";
export const GET_LIST_DM_ITEMGROUP = "GET_LIST_DM_ITEMGROUP";
export const INSERT_ITEMGROUP = "INSERT_ITEMGROUP";
export const DELETE_ITEMGROUP = "DETELE_ITEMGROUP";
export const UPDATE_ITEMGROUP = "UPDATE_ITEMGROUP";
export const CLEAR_ITEMGROUP = "CLEAR_ITEMGROUP";
//Chi nhánh
export const GET_LIST_SITE = "GET_LIST_SITE";
export const INSERT_SITE = "INSERT_SITE";
export const DELETE_SITE = "DETELE_SITE";
export const UPDATE_SITE = "UPDATE_SITE";
export const CLEAR_SITE = "CLEAR_SITE";
//Phương tiện vận tải
export const GET_LIST_VEHICLE = "GET_LIST_VEHICLE";
export const INSERT_VEHICLE = "INSERT_VEHICLE";
export const DELETE_VEHICLE = "DETELE_VEHICLE";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const CLEAR_VEHICLE = "CLEAR_VEHICLE";
//Tài xế
export const GET_LIST_DRIVER = "GET_LIST_DRIVER";
export const INSERT_DRIVER = "INSERT_DRIVER";
export const DELETE_DRIVER = "DETELE_DRIVER";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const CLEAR_DRIVER = "CLEAR_DRIVER";
//Dashboard
//SalesDB
export const FETCH_SALES_BY_DAY = "FETCH_SALES_BY_DAY";
export const FETCH_SALES_BY_MONTH = "FETCH_SALES_BY_MONTH";
export const SO_GET_SALES_TODAY = "SO_GET_SALES_TODAY";
//Purchase
//Purchase Receipt
export const GET_LIST_PO2 = "GET_LIST_PO2";
export const GET_CT_PO2 = "GET_CT_PO2";
export const GET_PH_PO2 = "GET_PH_PO2";
export const GET_CUSTOMER_PO2 = "GET_CUSTOMER_PO2";
export const LIST_SELECTED_PRODUCT_PO2 = "LIST_SELECTED_PRODUCT_PO2";
export const GET_LIST_CUSTOMER_PO2 = "GET_LIST_CUSTOMER_PO2";
export const GET_SO_CT_PO2 = "GET_SO_CT_PO2";
export const GET_LIST_STATUS_PO2 = "GET_LIST_STATUS_PO2";
export const GET_LIST_CURRENCY_PO2 = "GET_LIST_CURRENCY_PO2";
export const GET_EXCHANGE_RATE_PO2 = "GET_EXCHANGE_RATE_PO2";
export const ADD_NEW_PO2 = "ADD_NEW_PO2";
export const DELETE_PO2 = "DELETE_PO2";
export const UPDATE_PO2 = "UPDATE_PO2";
export const GET_TYPE_RECEIPT = "GET_TYPE_RECEIPT";
export const CLEAR_PO2 = "CLEAR_PO2";
export const CLEAR_HANDLE_STT_PO2 = "CLEAR_HANDLE_STT_PO2";
export const GET_LIST_INCOTERMS_PO2 = "GET_LIST_INCOTERMS_PO2";
export const GET_LIST_ACCOUNT_PO2 = "GET_LIST_ACCOUNT_PO2";
export const FILT_PO2 = "FILT_PO2";
export const LOOKUP_PO1 = "LOOKUP_PO1";
//Purchase Order
export const GET_LIST_PO1 = "GET_LIST_PO1";
export const GET_CT_PO1 = "GET_CT_PO1";
export const GET_PH_PO1 = "GET_PH_PO1";
export const GET_CUSTOMER_PO1 = "GET_CUSTOMER_PO1";
export const LIST_SELECTED_PRODUCT_PO1 = "LIST_SELECTED_PRODUCT_PO1";
export const GET_SO_CT_PO1 = "GET_SO_CT_PO1";
export const GET_LIST_STATUS_PO1 = "GET_LIST_STATUS_PO1";
export const GET_EXCHANGE_RATE_PO1 = "GET_EXCHANGE_RATE_PO1";
export const ADD_NEW_PO1 = "ADD_NEW_PO1";
export const DELETE_PO1 = "DELETE_PO1";
export const UPDATE_PO1 = "UPDATE_PO1";
export const CLEAR_PO1 = "CLEAR_PO1";
export const CLEAR_HANDLE_STT_PO1 = "CLEAR_HANDLE_STT_PO1";
export const FILT_PO1 = "FILT_PO1";
export const GET_LISTS_DM_PO1 = "GET_LISTS_DM_PO1";
//Approval Purchase Order
export const GET_LIST_APPROVAL_PO1 = "GET_LIST_APPROVAL_PO1";
export const APPROVAL_PO1 = "APPROVAL_PO1";
export const REJECT_PO1 = "REJECT_PO1";
export const CLEAR_APPROVAL_PO1 = "CLEAR_APPROVAL_PO1";

//System
/////Change Password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CLEAR_CACHE_CP = "CLEAR_CACHE_CP";
/////Role Info
export const GET_LIST_ROLE_INFO = "GET_LIST_ROLE_INFO";
export const ADD_NEW_ROLE_INFO = "ADD_NEW_ROLE_INFO";
export const DELETE_ROLE_INFO = "DELETE_ROLE_INFO";
export const UPDATE_ROLE_INFO = "UPDATE_ROLE_INFO";
export const CLEAR_CACHE_ROLE_INFO = "CLEAR_CACHE_ROLE_INFO";
/////User Info
export const GET_LIST_USER_INFO = "GET_LIST_USER_INFO";
export const ADD_NEW_USER_INFO = "ADD_NEW_USER_INFO";
export const DELETE_USER_INFO = "DELETE_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const CLEAR_CACHE_USER_INFO = "CLEAR_CACHE_USER_INFO";

//Inventory
//Movement Receipt
export const GET_LIST_IN1 = "GET_LIST_IN1";
export const GET_CT_IN1 = "GET_CT_IN1";
export const GET_PH_IN1 = "GET_PH_IN1";
export const GET_SO_CT_IN1 = "GET_SO_CT_IN1";
export const GET_EXCHANGE_RATE_IN1 = "GET_EXCHANGE_RATE_IN1";
export const ADD_NEW_IN1 = "ADD_NEW_IN1";
export const DELETE_IN1 = "DELETE_IN1";
export const UPDATE_IN1 = "UPDATE_IN1";
export const CLEAR_IN1 = "CLEAR_IN1";
export const FILT_IN1 = "FILT_IN1";
export const CLEAR_HANDLE_STT_IN1 = "CLEAR_HANDLE_STT_IN1";
export const GET_LISTS_DM_IN1 = "GET_LISTS_DM_IN1";
//Movement Issue
export const GET_LIST_IN2 = "GET_LIST_IN2";
export const GET_CT_IN2 = "GET_CT_IN2";
export const GET_PH_IN2 = "GET_PH_IN2";
export const GET_SO_CT_IN2 = "GET_SO_CT_IN2";
export const GET_EXCHANGE_RATE_IN2 = "GET_EXCHANGE_RATE_IN2";
export const ADD_NEW_IN2 = "ADD_NEW_IN2";
export const DELETE_IN2 = "DELETE_IN2";
export const UPDATE_IN2 = "UPDATE_IN2";
export const CLEAR_IN2 = "CLEAR_IN2";
export const FILT_IN2 = "FILT_IN2";
export const CLEAR_HANDLE_STT_IN2 = "CLEAR_HANDLE_STT_IN2";
export const GET_LISTS_DM_IN2 = "GET_LISTS_DM_IN2";
//Movement Transfer
export const GET_LIST_IN3 = "GET_LIST_IN3";
export const GET_CT_IN3 = "GET_CT_IN3";
export const GET_PH_IN3 = "GET_PH_IN3";
export const GET_SO_CT_IN3 = "GET_SO_CT_IN3";
export const GET_EXCHANGE_RATE_IN3 = "GET_EXCHANGE_RATE_IN3";
export const ADD_NEW_IN3 = "ADD_NEW_IN3";
export const DELETE_IN3 = "DELETE_IN3";
export const UPDATE_IN3 = "UPDATE_IN3";
export const CLEAR_IN3 = "CLEAR_IN3";
export const FILT_IN3 = "FILT_IN3";
export const CLEAR_HANDLE_STT_IN3 = "CLEAR_HANDLE_STT_IN3";
export const GET_LISTS_DM_IN3 = "GET_LISTS_DM_IN3";
//IN Reports
export const GET_LISTS_DM_IN_REPORT = "GET_LISTS_DM_IN_REPORT";
export const GET_RPT_IN = "GET_RPT_IN";
export const POS_RPT_IN_MOVEMENT = "POS_RPT_IN_MOVEMENT";
//Cash and bank
//Cash Receipt
export const GET_LIST_CA1 = "GET_LIST_CA1";
export const GET_CT_CA1 = "GET_CT_CA1";
export const GET_PH_CA1 = "GET_PH_CA1";
export const GET_SO_CT_CA1 = "GET_SO_CT_CA1";
export const GET_EXCHANGE_RATE_CA1 = "GET_EXCHANGE_RATE_CA1";
export const ADD_NEW_CA1 = "ADD_NEW_CA1";
export const DELETE_CA1 = "DELETE_CA1";
export const UPDATE_CA1 = "UPDATE_CA1";
export const CLEAR_CA1 = "CLEAR_CA1";
export const FILT_CA1 = "FILT_CA1";
export const CLEAR_HANDLE_STT_CA1 = "CLEAR_HANDLE_STT_CA1";
export const GET_LISTS_DM_CA1 = "GET_LISTS_DM_CA1";
//Cash Payment
export const GET_LIST_CA2 = "GET_LIST_CA2";
export const GET_CT_CA2 = "GET_CT_CA2";
export const GET_PH_CA2 = "GET_PH_CA2";
export const GET_SO_CT_CA2 = "GET_SO_CT_CA2";
export const GET_EXCHANGE_RATE_CA2 = "GET_EXCHANGE_RATE_CA2";
export const ADD_NEW_CA2 = "ADD_NEW_CA2";
export const DELETE_CA2 = "DELETE_CA2";
export const UPDATE_CA2 = "UPDATE_CA2";
export const CLEAR_CA2 = "CLEAR_CA2";
export const FILT_CA2 = "FILT_CA2";
export const CLEAR_HANDLE_STT_CA2 = "CLEAR_HANDLE_STT_CA2";
export const GET_LISTS_DM_CA2 = "GET_LISTS_DM_CA2";
//Bank receipt
export const GET_LIST_CA3 = "GET_LIST_CA3";
export const GET_CT_CA3 = "GET_CT_CA3";
export const GET_PH_CA3 = "GET_PH_CA3";
export const GET_SO_CT_CA3 = "GET_SO_CT_CA3";
export const GET_EXCHANGE_RATE_CA3 = "GET_EXCHANGE_RATE_CA3";
export const ADD_NEW_CA3 = "ADD_NEW_CA3";
export const DELETE_CA3 = "DELETE_CA3";
export const UPDATE_CA3 = "UPDATE_CA3";
export const CLEAR_CA3 = "CLEAR_CA3";
export const FILT_CA3 = "FILT_CA3";
export const CLEAR_HANDLE_STT_CA3 = "CLEAR_HANDLE_STT_CA3";
export const GET_LISTS_DM_CA3 = "GET_LISTS_DM_CA3";
//Bank payment
export const GET_LIST_CA4 = "GET_LIST_CA4";
export const GET_CT_CA4 = "GET_CT_CA4";
export const GET_PH_CA4 = "GET_PH_CA4";
export const GET_SO_CT_CA4 = "GET_SO_CT_CA4";
export const GET_EXCHANGE_RATE_CA4 = "GET_EXCHANGE_RATE_CA4";
export const ADD_NEW_CA4 = "ADD_NEW_CA4";
export const DELETE_CA4 = "DELETE_CA4";
export const UPDATE_CA4 = "UPDATE_CA4";
export const CLEAR_CA4 = "CLEAR_CA4";
export const FILT_CA4 = "FILT_CA4";
export const CLEAR_HANDLE_STT_CA4 = "CLEAR_HANDLE_STT_CA4";
export const GET_LISTS_DM_CA4 = "GET_LISTS_DM_CA4";
