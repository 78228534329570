import * as types from "../../../constants/ActionTypes";

var initialState = {
  listMovementIssue: [],
  ctMovementIssue: [],
  phMovementIssue: {},
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  sysExchange: [],
  listBP: [],
};

var MovementIssueReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_IN2: {
      return {
        ...state,
        listMovementIssue: action.payload,
      };
    }
    case types.FILT_IN2: {
      return {
        ...state,
        listMovementIssue: action.payload,
      };
    }
    case types.GET_PH_IN2: {
      return {
        ...state,
        phMovementIssue: action.payload,
      };
    }
    case types.GET_CT_IN2: {
      return {
        ...state,
        ctMovementIssue: action.payload,
      };
    }

    case types.GET_SO_CT_IN2: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_IN2: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_IN2: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_EXCHANGE_RATE_IN2: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_IN2: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_IN2: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctMovementIssue: [],
        phMovementIssue: {},
        newItem: null,
        exchangeRate: "",
      };
    }
    case types.CLEAR_HANDLE_STT_IN2: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }
    case types.GET_LISTS_DM_IN2: {
      return {
        ...state,
        listCurrency: action.payload.dm_nt,
        listCustomer: action.payload.dm_ncc,
        sysExchange: action.payload.sysExchange,
        listBP: action.payload.dm_bp,
      };
    }
    default:
      return state;
  }
};

export default MovementIssueReducer;
