import React, { Component } from "react";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { Button, Space, Spin, Popconfirm, PageHeader } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Category/customer/index";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import EmployeeCreate from "./employeeCreate/employeeCreate";
import EmployeeEdit from "./employeeEdit/employeeEdit";
import EmployeeDetail from "./employeeDetail/employeeDetail";

const objectColumn = {
  MA_KH: { name: "Mã nhân viên", width: 100 },
  TEN_KH: { name: "Tên nhân viên", width: 300 },
  DIA_CHI: { name: "Địa chỉ", width: 300 },
  TEL: { name: "SĐT", width: 100 },
  EMAIL: { name: "Email", width: 200 },
};
class Employee extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    rowId: "",
    IDCUSTOMER: "",
    openDrawerCreate: false,
    openDrawerEdit: false,
    openDrawerDetail: false,
    Master: {},
    moduleID: "CA",
  };
  componentDidMount = () => {
    this.loadListCustomer(this.state.moduleID);
  };
  componentDidUpdate = (prevProps) => {
    const { listCustomer, checkDelete, clearCache } = this.props;
    if (!_.isEqual(listCustomer, prevProps.listCustomer)) {
      this.setState({
        data: listCustomer,
        fullData: listCustomer,
        loading: false,
      });
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ loading: false, rowId: "", IDCUSTOMER: "", Master: {} });
      this.loadListCustomer(this.state.moduleID);
      clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };
  confirmDelete(IDCUSTOMER) {
    this.setState({ loading: true });
    this.props.deleteCustomer({
      ma_cty: this.props.ma_cty,
      IDCUSTOMER: IDCUSTOMER,
    });
  }
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.setState({ openDrawerEdit: true });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn nhân viên cần sửa !");
    }
  };
  handleFilterChange = async (value) => {
    await this.setState({ moduleID: value });
    await this.loadListCustomer(this.state.moduleID);
  };
  render() {
    const {
      loading,
      data,
      customColumns,
      IDCUSTOMER,
      openDrawerCreate,
      openDrawerEdit,
      Master,
      openDrawerDetail,
      fullData,
    } = this.state;
    return (
      <div className="Customer" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Nhân viên"
            onBack={() => this.props.history.push("/Category")}
          />
          <Space style={{ marginBottom: 16, marginTop: 10 }}>
            <Button
              onClick={() => this.setState({ openDrawerCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn muốn xóa khách hàng ?"
              onConfirm={() => this.confirmDelete(IDCUSTOMER)}
              okText="Đồng ý"
              cancelText="Hủy"
              disabled={IDCUSTOMER === "" ? true : false}
            >
              <Button
                onClick={() => {
                  if (IDCUSTOMER === "") {
                    openNotificationWithIcon(
                      "error",
                      "Vui lòng chọn nhân viên cần xóa !"
                    );
                  }
                }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            </Popconfirm>
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListCustomer(this.state.moduleID);
            }}
          />
          {/* <Select
                  style={{ width: "100%" }}
                  defaultValue={""}
                  onChange={this.handleFilterChange}
                >
                  <Option value="">---Lọc---</Option>
                  <Option value="SI">Lọc tất cả</Option>
                  <Option value="AR">Khách hàng</Option>
                  <Option value="AP">Nhà cung cấp</Option>
                </Select> */}
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="MA_KH"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openDrawerCreate ? (
            <EmployeeCreate
              isVisible={openDrawerCreate}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
          {openDrawerEdit ? (
            <EmployeeEdit
              isVisible={openDrawerEdit}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <EmployeeDetail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.setState({
      openDrawerCreate: params.isVisible,
      loading: params.isVisible,
      openDrawerDetail: params.isVisible,
      openDrawerEdit: params.isVisible,
      openDrawerFilter: params.isVisible,
    });
    params.editSuccess ? this.loadListCustomer(this.state.moduleID) : <></>;
    params.editSuccess ? (
      this.setState({ rowId: "", IDCUSTOMER: "", Master: {} })
    ) : (
      <></>
    );
  };
  getDataRow = (params) => {
    this.setState({ Master: params, IDCUSTOMER: params.IDCUSTOMER });
  };
  loadListCustomer = (moduleid) => {
    const { listCustomer } = this.props;
    this.setState({ loading: true });
    if (this.state.customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      this.setState({ customColumns: result });
    }
    listCustomer.length = 0;
    this.props.getListCustomerAR({
      modelM: { ma_cty: this.props.ma_cty },
      moduleid,
    });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listCustomer: state.SICustomerReducer.listCustomer,
    checkDelete: state.SICustomerReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListCustomerAR: (ma_cty) => {
      dispatch(actions.getListCustomerAR(ma_cty));
    },
    deleteCustomer: (query) => {
      dispatch(actions.deleteCustomer(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
