import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import DHSTable from "../../../../../common/DHSControl/DHSTable/DHSTable";
import So0Detail from "../so0Detail/so0Detail";
import ModalSelectRp from "../modalSelectRp/modalSelectRp";
import DHSHanleFilter from "../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actions from "../../../../../redux/actions/Sales/quotation/quotation";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";

const objectColumn = {
  SO_CT: { name: "Số báo giá", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  TEN_TRANG_THAI: { name: "Trạng thái", width: 100 },
  T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function OpenQU(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const SO0State = useSelector((state) => state.SO0Reducer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isLoading, setLoading] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      actions.filt({
        modelM: {
          ma_cty,
          trang_thai: "0",
        },
      })
    );
    loadColumnDHSTable(customColumns, setCustomColumns);
    return () => {
      dispatch(actions.clearCache());
    };
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(SO0State.listQuotation);
    setData(SO0State.listQuotation);
    setFullData(SO0State.listQuotation);
    setLoading(false);
  }, [SO0State.listQuotation]);

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const checkVisible = (params) => {
    setOpenDrawerDetail(params);
    setOpenModalPrint(params);
  };

  const handlePrint = (params) => {
    dispatch(actions.getCTQuotation(params));
    setOpenModalPrint(true);
  };
  return (
    <div className="quotation" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Danh sách báo giá chờ duyệt"
          onBack={() => history.push("/Sale")}
        />
        <ToolBar setPrint={handlePrint} Master={isMaster} />
        <ModalSelectRp
          isModalVisible={openModalPrint}
          checkVisible={checkVisible}
          Master={isMaster}
          ctQuotation={toUpperPropertyNameByArray(
            useSelector((state) => state.SO0Reducer.ctQuotation)
          )}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(
              actions.filt({
                modelM: {
                  ma_cty,
                  trang_thai: "0",
                },
              })
            );
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
        />
        {openDrawerDetail ? (
          <So0Detail
            isVisible={openDrawerDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
