import SalesOrder from "../../../components/layout/Sales/salesOrder/salesOrder";
import PackingSlip from "../../../components/layout/Sales/packingSlip/packingSlip";
import SO2Edit from "../../../components/layout/Sales/packingSlip/so2Edit/so2Edit";
import SO1Edit from "../../../components/layout/Sales/salesOrder/so1Edit/so1Edit";
import SO0Edit from "../../../components/layout/Sales/quotation/so0Edit/so0Edit";
import Sales from "../../../components/layout/Sales/sales";
import ApprovalSalesOrder from "../../../components/layout/Sales/ApprovalSalesOrder/approvalSalesOrder";
import DiscountSo from "../../../components/layout/Sales/soDiscount/soDiscount";
import DiscountSoEdit from "../../../components/layout/Sales/soDiscount/ds1Edit/ds1Edit";
import SellPriceBase from "../../../components/layout/Sales/sellPriceBase/sellPriceBase";
import Quotation from "../../../components/layout/Sales/quotation/quotation";
import PromotionSo from "../../../components/layout/Sales/soPromotion/soPromotion";
import PromotionSoEdit from "../../../components/layout/Sales/soPromotion/ds2Edit/ds2Edit";
import ApprovalQuotation from "../../../components/layout/Sales/quotation/approvalQuotation/approvalQuotation";
import SearchItem from "../../../components/layout/Sales/searchItem/searchItem";
import SellPriceTypes from "../../../components/layout/Sales/sellPriceBase/sellPriceTypes/sellPriceTypes";
import ConfirmQU from "../../../components/layout/Sales/quotation/confirmQU/confirmQU";
import OpenQU from "../../../components/layout/Sales/quotation/openQU/openQU";
import ConfirmSO from "../../../components/layout/Sales/salesOrder/confirmSO/confirmSO";
import OpenSO from "../../../components/layout/Sales/salesOrder/openSO/openSO";
import SalesReturn from "../../../components/layout/Sales/salesReturn/salesReturn";
import SO4Edit from "../../../components/layout/Sales/salesReturn/so4Edit/so4Edit";
import SalesInvoice from "../../../components/layout/Sales/salesInvoice/salesInvoice";
import SO3Edit from "../../../components/layout/Sales/salesInvoice/so3Edit/so3Edit";
import SO3Cash from "../../../components/layout/Sales/salesInvoice/so3Cash/so3Cash";
import SO3Bank from "../../../components/layout/Sales/salesInvoice/so3Bank/so3Bank";
import SalesTransaction from "../../../components/layout/Sales/Reports/SalesTransaction/SalesTransaction";
import SalesLineReport from "../../../components/layout/Sales/Reports/SalesLineReport/SalesLineReport";
import Transaction from "../../../components/layout/Sales/Transaction/Transaction";
import TransactionVn from "../../../components/layout/Sales/TransactionVn/TransactionVn";
import POS from "../../../components/layout/Sales/POS/POS";
import POSRevenueByInvoice from "../../../components/layout/Sales/Reports/POSRevenueByInvoice/POSRevenueByInvoice";
import POSRevenueByItem from "../../../components/layout/Sales/Reports/POSRevenueByItem/POSRevenueByItem";
const salesRoutes = [
  {
    path: "/Sale",
    exact: false,
    main: ({ history }) => <Sales history={history} />,
  },
  {
    path: "/SalesOrder",
    exact: false,
    main: ({ history }) => <SalesOrder history={history} />,
  },
  {
    path: "/PackingSlip",
    exact: false,
    main: ({ history }) => <PackingSlip history={history} />,
  },
  {
    path: "/Transaction",
    exact: false,
    main: ({ history }) => <Transaction history={history} />,
  },
  {
    path: "/TransactionVN",
    exact: false,
    main: ({ history }) => <POS history={history} />,
  },
  {
    path: "/soVch02Edit",
    exact: false,
    main: ({ history }) => <SO2Edit history={history} />,
  },
  {
    path: "/soVch01Edit",
    exact: false,
    main: ({ history }) => <SO1Edit history={history} />,
  },
  {
    path: "/SalesOrderApproved",
    exact: false,
    main: ({ history }) => <ApprovalSalesOrder history={history} />,
  },
  {
    path: "/DiscountSO",
    exact: false,
    main: ({ history }) => <DiscountSo history={history} />,
  },
  {
    path: "/PromotionSO",
    exact: false,
    main: ({ history }) => <PromotionSo history={history} />,
  },
  {
    path: "/DiscountSoEdit",
    exact: false,
    main: ({ history }) => <DiscountSoEdit history={history} />,
  },
  {
    path: "/PromotionSoEdit",
    exact: false,
    main: ({ history }) => <PromotionSoEdit history={history} />,
  },
  {
    path: "/SalesPrice",
    exact: false,
    main: ({ history }) => <SellPriceBase history={history} />,
  },
  {
    path: "/Quotation",
    exact: false,
    main: ({ history }) => <Quotation history={history} />,
  },
  {
    path: "/SO0Edit",
    exact: false,
    main: ({ history }) => <SO0Edit history={history} />,
  },
  {
    path: "/QuotationApproved",
    exact: false,
    main: ({ history }) => <ApprovalQuotation history={history} />,
  },
  {
    path: "/SearchItem",
    exact: false,
    main: ({ history }) => <SearchItem history={history} />,
  },
  {
    path: "/SellPriceTypes",
    exact: false,
    main: ({ history }) => <SellPriceTypes history={history} />,
  },
  {
    path: "/ConfirmQU",
    exact: false,
    main: ({ history }) => <ConfirmQU history={history} />,
  },
  {
    path: "/OpenQU",
    exact: false,
    main: ({ history }) => <OpenQU history={history} />,
  },
  {
    path: "/ConfirmSO",
    exact: false,
    main: ({ history }) => <ConfirmSO history={history} />,
  },
  {
    path: "/OpenSO",
    exact: false,
    main: ({ history }) => <OpenSO history={history} />,
  },
  {
    path: "/SalesReturn",
    exact: false,
    main: ({ history }) => <SalesReturn history={history} />,
  },
  {
    path: "/SO4Edit",
    exact: false,
    main: ({ history }) => <SO4Edit history={history} />,
  },
  {
    path: "/SalesInvoice",
    exact: false,
    main: ({ history }) => <SalesInvoice history={history} />,
  },
  {
    path: "/SO3Edit",
    exact: false,
    main: ({ history }) => <SO3Edit history={history} />,
  },
  {
    path: "/SO3Cash",
    exact: false,
    main: ({ history }) => <SO3Cash history={history} />,
  },
  {
    path: "/SO3Bank",
    exact: false,
    main: ({ history }) => <SO3Bank history={history} />,
  },
  {
    path: "/SalesTransaction",
    exact: false,
    main: ({ history }) => <SalesTransaction history={history} />,
  },
  {
    path: "/SalesLineReport",
    exact: false,
    main: ({ history }) => <SalesLineReport history={history} />,
  },
  {
    path: "/POSRptRevenuebyInvoice",
    exact: false,
    main: ({ history }) => <POSRevenueByInvoice history={history} />,
  },
  {
    path: "/POSRptRevenuebyItem", // path url này được lấy từ dưới SysMenu linkweb
    exact: false,
    main: ({ history }) => <POSRevenueByItem history={history} />, //Component muốn render khi được link đến
  },
];

export default salesRoutes;
