import React, { useState, useEffect } from "react";
import { Collapse, Row, Col, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  DHSInput,
  DHSText,
  DHSSelectOpt,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import {
  FormatDate,
  toUpperPropertyNameByArray,
} from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/Sales/salesReturn/salesReturn";
import moment from "moment";
const { Panel } = Collapse;

export default function General(props) {
  const { Master, isEdit, getTyGia } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isRender, setRender] = useState(false);
  const SO4State = useSelector((state) => state.SO4Reducer);

  useEffect(() => {
    dispatch(actions.getListStatus());
    handleChangeCurrency(Master.MA_NT);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(SO4State.listCustomer)) {
      handleKHChange(Master.IDCUSTOMER);
    }
  }, [SO4State.listCustomer]);

  useEffect(() => {
    Master["TY_GIA"] = SO4State.exchangeRate == 0 ? 1 : SO4State.exchangeRate;
    getTyGia(Master.TY_GIA);
    setRender(!isRender);
  }, [SO4State.exchangeRate]);

  const handleKHChange = (params) => {
    let customer = SO4State.listCustomer.find((x) => x.idcustomer === params);
    if (!_.isEmpty(customer)) {
      Master.DIA_CHI = customer.dia_chi;
      Master.IDCUSTOMER = params;
      Master.EMAIL = customer.email;
      Master.TEL = customer.tel;
      Master.MA_KH = customer.ma_kh;
    }
    setRender(!isRender);
  };

  const onChangeText = (params) => {
    Master[params.name] = params.value;
    if (params.name === "TY_GIA") {
      getTyGia(Master.TY_GIA);
    }
    setRender(!isRender);
  };

  const handleChangeCurrency = (params) => {
    Master["MA_NT"] = params;
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: moment(Master.NGAY_LCT),
      })
    );
    setRender(!isRender);
  };

  return (
    <div className="so4-general">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chung" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Tên khách hàng"
                  array={SO4State.listCustomer}
                  valueOpt={"idcustomer"}
                  valueRender={"ten_kh"}
                  defaultValue={Master.IDCUSTOMER}
                  onChange={handleKHChange}
                  disable={isEdit}
                />
                <DHSText label="Số điện thoại" value={Master.TEL} />
                <DHSText label="Email" value={Master.EMAIL} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSInput
                  label="Người liên hệ"
                  name="NGUOI_GD"
                  value={Master.NGUOI_GD}
                  getValueChange={onChangeText}
                  disable={isEdit}
                />
                <DHSText label="Địa chỉ" value={Master.DIA_CHI} />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSText label="Ngày lập" value={FormatDate(Master.NGAY_CT)} />
                <DHSSelectOpt
                  label="Loại phiếu"
                  array={toUpperPropertyNameByArray(SO4State.sysExchange)}
                  valueOpt={"MA_GD"}
                  valueRender={"MO_TA"}
                  defaultValue={Master.MA_GD}
                  onChange={(value) => onChangeText({ value, name: "TK_PT" })}
                />
                <DHSSelectOpt
                  label="Tài khoản có"
                  array={toUpperPropertyNameByArray(SO4State.tk_pt)}
                  valueOpt={"TK"}
                  valueRender={"TK"}
                  defaultValue={Master.TK_PT}
                  onChange={(value) => onChangeText({ value, name: "TK_PT" })}
                />
              </Form>
            </Col>
            <Col className="gutter-row" span={6} xs={12} xl={6}>
              <Form layout="vertical">
                <DHSSelectOpt
                  label="Loại tiền tệ"
                  array={SO4State.listCurrency}
                  valueOpt={"ma_nt"}
                  valueRender={"ma_nt"}
                  defaultValue={Master.MA_NT}
                  onChange={handleChangeCurrency}
                  disable={isEdit}
                />
                <DHSInput
                  label="Tỷ giá"
                  name="TY_GIA"
                  value={Master.TY_GIA}
                  disable={isEdit}
                  disable={Master.MA_NT === "VND" ? true : false}
                  getValueChange={onChangeText}
                />
                <DHSInput
                  label="Ghi chú"
                  name="DIEN_GIAI"
                  value={Master.DIEN_GIAI}
                  disable={isEdit}
                  getValueChange={onChangeText}
                />
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
}
