import React, { PureComponent } from "react";
import { Modal, Button, Row, Col } from "antd";
import { Template } from "../../../../../common/DHSControl/DHSTemplateRP/Template";
import DHSPrint from "../../../../../common/DHSControl/DHSPrint/DHSPrint";
import { DHSSelectOpt } from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
const contentPrint = "Chi tiết phiếu nhập kho gồm";

export default class ModalSelectRp extends PureComponent {
  state = {
    lstTemplate: [
      {
        id: "Rp03",
        name: "Phiếu nhập kho",
      },
    ],
    template: {
      id: "Rp03",
      name: "Phiếu nhập kho",
    },
  };
  handleChange = (value) => {
    this.state.lstTemplate.map((item) => {
      if (item.id === value) {
        this.setState({ template: item });
      }
    });
  };
  shouldComponentUpdate = () => {
    if (this.props.isModalVisible === true) {
      return true;
    }
    return false;
  };
  render() {
    const { isModalVisible, ctData, Master } = this.props;
    const { template, lstTemplate } = this.state;
    return (
      <div>
        <Modal
          title="Chọn mẫu in"
          visible={isModalVisible}
          footer={
            <Row>
              <Col span={3} offset={17}>
                <DHSPrint
                  PagePrint={Template[template.id]}
                  objPrint={Master}
                  isSample={false}
                  ctObjPrint={ctData}
                  title={template.name}
                  contentPrint={contentPrint}
                  onAfterPrint={this.onAfterPrint}
                  onBeforeGetContent={this.onBeforeGetContent}
                />
              </Col>
              <Col span={3} offset={1}>
                <Button onClick={this.handleCancel}>Hủy</Button>
              </Col>
            </Row>
          }
        >
          <DHSSelectOpt
            label="Mẫu in"
            array={lstTemplate}
            valueOpt={"id"}
            valueRender={"name"}
            defaultValue={template.id}
            onChange={this.handleChange}
          />
        </Modal>
      </div>
    );
  }
  handleCancel = () => {
    this.props.checkVisible(false);
  };
  onBeforeGetContent = () => {};
  onAfterPrint = () => {};
}
