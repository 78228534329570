import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionsCus from "../../../../../redux/actions/Category/customer/index";
import { toUpperPropertyNameByArray } from "../../../../controller/Format";
import Filter from "./Filter/Filter";
import _ from "lodash";
import PropTypes from "prop-types";
import "./index.css";
import DHSReport from "../../../../../common/layout/DHSReport/DHSReport";
POSRevenueByInvoice.propTypes = {
  columns: PropTypes.object,
};
POSRevenueByInvoice.defaultProps = {
  //Columns các property muốn hiển thị lên datagrid
  // caption(tên trên header), dataField(property name),type(0:không edit, 1:edit),format(date hoặc Money)
  columns: [
    {
      caption: "Số HĐ",
      dataField: "SO_CT",
      type: 0,
    },
    {
      caption: "Ngày lập HĐ",
      dataField: "NGAY_CT",
      type: 0,
      format: "date",
    },
    {
      caption: "Mã khách hàng",
      dataField: "MA_KH",
      type: 0,
    },
    {
      caption: "Tên khách hàng",
      dataField: "TEN_KH",
      type: 0,
    },
    {
      caption: "Số lượng",
      dataField: "T_SO_LUONG",
      format: "Money",
      type: 0,
    },
    {
      caption: "Thành tiền",
      dataField: "T_TIEN2",
      format: "Money",
      type: 0,
    },
    {
      caption: "Chiết khấu",
      dataField: "T_CK",
      format: "Money",
      type: 0,
    },
    {
      caption: "Tổng tiền",
      dataField: "T_TT",
      format: "Money",
      type: 0,
    },
    {
      caption: "Khách trả",
      dataField: "KHACH_TRA",
      format: "Money",
      type: 0,
    },
  ],
};
export default function POSRevenueByInvoice(props) {
  // khai báo các props có thể lấy được
  const { columns } = props;
  const dispatch = useDispatch();
  //Get ma_cty khi user đăng nhập
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  //Get dữ liệu báo cáo từ reducer để hiện thị lên datagrid
  const POSRptRevenuebyInvoice = useSelector((state) =>
    toUpperPropertyNameByArray(state.SOReportsReducer.POSRptRevenuebyInvoice)
  );
  useEffect(() => {
    //dispatch để lấy những dữ liệu cần thiết khi filter
    dispatch(
      actionsCus.getListCustomerAR({
        modelM: { ma_cty },
      })
    );
  }, []);
  return (
    <div className="POSRevenueByInvoice">
      <DHSReport // Các props đang được truyền vào đã giải thích bên component DHSReport
        columns={columns}
        data={POSRptRevenuebyInvoice}
        dataKey="SO_CT"
        title="Báo cáo doanh thu theo hóa đơn"
        onBack="Sale"
        FilterComponent={Filter}
      />
    </div>
  );
}
