import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import So4Create from "./so4Create/so4Create";
import So4Detail from "./so4Detail/so4Detail";
import So4Filter from "./so4Filter/so4Filter";
// import ModalSelectRp from "./modalSelectRp/modalSelectRp";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actions from "../../../../redux/actions/Sales/salesReturn/salesReturn";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import "./index.css";

const objectColumn = {
  SO_CT: { name: "Số chứng từ", width: 150 },
  NGAY_CT: { name: "Ngày chứng từ", width: 150, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 150 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  T_TT: { name: "Tiền hàng", width: 150, formatMoney: true },
  T_CK: { name: "Chiết khấu", width: 150, formatMoney: true },
  T_THUE: { name: "VAT", width: 150, formatMoney: true },
  MA_NT: { name: "Mã NT", width: 150 },
  TK_PT: { name: "TK PT", witdh: 100 },
  DIEN_GIAI: { name: "Diễn giải", width: 300 },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function SalesReturn(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const SO4State = useSelector((state) => state.SO4Reducer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isLoading, setLoading] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false);
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListSalesReturn({ ma_cty }));
    dispatch(actions.getListsDm({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(SO4State.listSalesReturn);
    setData(SO4State.listSalesReturn);
    setFullData(SO4State.listSalesReturn);
    setLoading(false);
  }, [SO4State.listSalesReturn]);

  useEffect(() => {
    if (SO4State.checkDelete) {
      dispatch(actions.getListSalesReturn({ ma_cty }));
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [SO4State.checkDelete]);

  const handleOpenDrawer = () => {
    setOpenDrawerCreate(true);
  };

  const handleDelete = (params) => {
    dispatch(actions.deleteASalesReturn({ modelM: params }));
    setLoading(true);
  };

  const handleEdit = () => {
    if (!_.isEmpty(isMaster)) {
      dispatch(actions.getCTSalesReturn(isMaster));
      history.push("/SO4Edit", {
        Master: isMaster,
      });
    }
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const checkVisible = (params) => {
    setOpenDrawerCreate(params);
    setOpenDrawerDetail(params);
    setOpenDrawerFilter(params);
    setOpenModalPrint(params);
  };

  // const handlePrint = (params) => {
  //   dispatch(actions.getCTSalesReturn(params));
  //   setOpenModalPrint(true);
  // };
  return (
    <div className="so4" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Đơn hàng trả"
          onBack={() => history.push("/SalesReturn")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          // setPrint={handlePrint}
          Master={isMaster}
        />
        {/* <ModalSelectRp
          isModalVisible={openModalPrint}
          checkVisible={checkVisible}
          Master={isMaster}
          ctSalesReturn={toUpperPropertyNameByArray(
            useSelector((state) => state.SO0Reducer.ctSalesReturn)
          )}
        /> */}
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListSalesReturn({ ma_cty }));
          }}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
          scroll={{ y: 500, x: 1800 }}
        />
      </Spin>
      {openDrawerCreate ? (
        <So4Create
          isVisible={openDrawerCreate}
          checkVisible={checkVisible}
          history={history}
        />
      ) : (
        <></>
      )}
      {openDrawerDetail ? (
        <So4Detail
          isVisible={openDrawerDetail}
          checkVisible={checkVisible}
          Master={isMaster}
        />
      ) : (
        <></>
      )}
      {openDrawerFilter ? (
        <So4Filter
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
          approvalScr={false}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
