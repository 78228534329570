import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import * as actions from "../../../../../../redux/actions/CustomerServices/crmCustomerRequest/crmCustomerRequest";
import CrmCustomerForm from "../crmCustomerRequest";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";

class CreateCSR extends Component {
  state = {
    loading: false,
  };
  getItemActions = async (params) => {
    this.setState({ loading: true });
    this.props.addNewCrmCustomerRequest(params);
  };
  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.checkInsert, prevProps.checkInsert)) {
      openNotificationWithIcon("success", "Thêm yêu cầu thành công !");
      this.setState({ loading: false });
      this.props.clearCache();
      this.props.checkVisible({ isVisible: false, createSuccess: true });
    }
  };
  render() {
    const { isVisible, IDCUSTOMER } = this.props;
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <CrmCustomerForm
            isVisible={isVisible}
            IDCUSTOMER={IDCUSTOMER}
            checkVisible={this.checkVisible}
            getItemActions={this.getItemActions}
            rules={true}
          />
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    checkInsert: state.CrmCustomerRequestReducer.checkInsert,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    addNewCrmCustomerRequest: (model) => {
      dispatch(actions.addNewCrmCustomerRequest(model));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCSR);
