import React, { Component } from "react";
import { Row, Col } from "antd";
import Pie from "../../../ChartType/pieChart";
import Bar from "../../../ChartType/bar";
import { toLowerPropertyNameByArray } from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/Manufacturing/MachineRpt/index";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

class Reports extends Component {
  state = {
    fullDayPie: [],
    totalDuration: 1,
    arrayShift1: [],
    arrayShift2: [],
    arrayShift3: [],
  };
  calcTotalDuration = (array) => {
    var value = 0;
    array.map((item) => {
      value += item.duration;
    });
    return value;
  };
  getFullDayPie = (array) => {
    const object = _.groupBy(array, "state_description");
    var total = 0;
    var array = [];
    for (var key in object) {
      const value = _.sumBy(object[key], "duration");
      total += value;
      array.push({
        state_description: key,
        machine_code: object[key][0].machine_code,
        duration: value,
      });
    }
    this.setState({ totalDuration: total, fullDayPie: array });
  };
  getShiftPie = (array) => {
    const filter = (shift) => {
      const value = _.filter(array, (item) => {
        return item.shift === shift;
      });
      return value;
    };
    const arrayShift1 = filter(1);
    const arrayShift2 = filter(2);
    const arrayShift3 = filter(3);
    this.setState({ arrayShift1, arrayShift2, arrayShift3 });
  };
  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.machineWorkShift, prevProps.machineWorkShift)) {
      toLowerPropertyNameByArray(this.props.machineWorkShift);
      this.getFullDayPie(this.props.machineWorkShift);
      this.getShiftPie(this.props.machineWorkShift);
    }
  };
  render() {
    const { machineName, machineWorkShift } = this.props;
    const {
      fullDayPie,
      totalDuration,
      arrayShift1,
      arrayShift2,
      arrayShift3,
    } = this.state;
    return (
      <div className="reportsMachineTimeLine">
        <div>
          <p className="text">Reports</p>
          <p className="textChild">{machineName}</p>
        </div>
        <Row gutter={[16, 16]} justify="space-around">
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Pie
              legendVisible={false}
              title="Full Day"
              dataSource={fullDayPie.length > 0 ? fullDayPie : []}
              totalDuration={totalDuration}
            />
          </Col>
          <Col className="gutter-row" xs={24} md={12} xl={7}>
            <Bar
              dataSource={fullDayPie}
              type="stackedBar"
              title="Run Time vs Stop Time"
              descriptionField="state_description"
              argumentField="machine_code"
              valueField="duration"
              scroll={false}
              legendVisible={true}
              customerText={this.customerText}
              customizeTooltip={this.customizeTooltip}
            />
          </Col>
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Bar
              dataSource={machineWorkShift}
              type="stackedBar"
              title="Run Time vs Stop Time"
              descriptionField="STATE_DESCRIPTION"
              argumentField="SHIFT"
              valueField="DURATION"
              scroll={false}
              legendVisible={true}
              customerText={this.customerText}
              customizeTooltip={this.customizeTooltip}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="space-around">
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Pie
              title="Shift 1"
              legendVisible={false}
              dataSource={arrayShift1}
              totalDuration={this.calcTotalDuration(arrayShift1)}
            />
          </Col>
          <Col className="gutter-row" xs={24} md={12} xl={7}>
            <Pie
              title="Shift 2"
              legendVisible={false}
              dataSource={arrayShift2}
              totalDuration={this.calcTotalDuration(arrayShift2)}
            />
          </Col>
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Pie
              title="Shift 3"
              legendVisible={false}
              dataSource={arrayShift3}
              totalDuration={this.calcTotalDuration(arrayShift3)}
            />
          </Col>
        </Row>
      </div>
    );
  }
  tranferToHours = (value) => {
    let convert = (value) => {
      return value >= 10 ? value : "0" + value;
    };
    let hours = Math.floor(value / 60 / 60);
    let minutes = Math.floor((value / 60) % 60);
    let seconds = value % 60;
    return convert(hours) + ":" + convert(minutes) + ":" + convert(seconds);
  };
  customerText = (item) => {
    return this.tranferToHours(item.value);
  };
  customizeTooltip = (arg) => {
    return {
      text: `${arg.argument}
      ${arg.seriesName} Time: ${this.tranferToHours(arg.originalValue)}
      ${arg.seriesName}:${arg.percentText}
      `,
    };
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    machineWorkShift: state.MachineRptReducer.machineWorkShift,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
