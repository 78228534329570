import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Sales/soDiscount/soDiscount";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import _ from "lodash";
import PropTypes from "prop-types";
import "./index.css";

Ds1Edit.propTypes = {};

Ds1Edit.defaultProps = {};
const { TabPane } = Tabs;

export default function Ds1Edit(props) {
  const dispatch = useDispatch();
  const newEdit = useSelector((state) => state.DS1Reducer.newEdit);
  const { history } = props;
  const [isLoading, setLoading] = useState(false);
  const [isArrayCt, setIsArrayCt] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const Master = history.location.state ? history.location.state.Master : {};

  useEffect(() => {
    return () => {
      window.onload = () => {
        history.push("/DiscountSO");
      };
    };
  });

  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(newEdit)) {
      openNotificationWithIcon("success", "Sửa thành công !");
      setEdit(true);
      // dispatch(actions.clearCache());
    }
  }, [newEdit]);

  const handleSave = () => {
    const Discount = {
      modelM: Master,
      arrayCt: isArrayCt,
    };
    setLoading(true);
    dispatch(actions.updateDiscount(Discount));
  };

  const handleBack = () => {
    dispatch(actions.clearCache());
    history.push("/DiscountSO");
  };

  const handleEdit = () => {
    setEdit(false);
  };

  const getArrayCt = (params) => {
    setIsArrayCt(params);
  };
  return (
    <div className="ds1-edit">
      <Spin spinning={isLoading}>
        <div>
          <ToolBar
            setSave={!isEdit ? handleSave : undefined}
            setReEdit={isEdit ? handleEdit : undefined}
            setBack={handleBack}
          />
          <div style={{ paddingLeft: 15 }}>
            <h4 style={{ margin: 0, color: "#1890FF" }}>CHIẾT KHẤU</h4>
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
                {Master.SO_CT} : {Master.TEN_CTCK}
              </h2>
            </div>
          </div>
          <General Master={Master} isEdit={isEdit} />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Chi tiết chiết khấu" key="1">
              <ListItem setIsArrayCt={getArrayCt} isEdit={isEdit} />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  );
}
