import React, { useEffect } from "react";
import CustomerGroupForm from "../customerGroupForm/customerGroupForm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/Category/customerGroup/customerGroup";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";

export default function CustomerGroupEdit(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, Master } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);

  const checkUpdate = useSelector(
    (state) => state.CustomerGroupReducer.checkUpdate
  );

  useEffect(() => {
    if (checkUpdate) {
      openNotificationWithIcon("success", "Sửa nhóm khách hàng thành công !");
      dispatch(actions.clearCache());
      dispatch(actions.getListCustomerGroup({ MA_CTY }));
      checkVisible(false);
    }
  }, [checkUpdate]);

  const handleActions = (params) => {
    dispatch(actions.updateCustomerGroup(params));
  };

  return (
    <CustomerGroupForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      Master={Master}
      handleActions={handleActions}
      title="Sửa nhóm khách hàng"
    />
  );
}
