import React, { useState, useEffect } from "react";
import { Row, Col, DatePicker } from "antd";
import moment from "moment";
import "./index.css";
export default function DHSDatePicker(props) {
  const monthFormat = "MM";
  const { getDatePickerChange } = props;
  const [date, setDate] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  });
  const onChangeDate = async (dateString, name) => {
    if (dateString !== "") {
      await setDate({
        ...date,
        [name]: parseInt(dateString),
      });
    }
  };
  useEffect(() => {
    getDatePickerChange(date);
  }, [date]);
  return (
    <div className="DHSDatePicker">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24} xs={12} xl={3}>
          <h1>Chọn năm</h1>
          <DatePicker
            placeholder="Chọn năm"
            defaultValue={moment()}
            onChange={(date, dateString) => onChangeDate(dateString, "year")}
            picker="year"
          />
        </Col>
        <Col span={24} xs={12} xl={3}>
          <h1>Chọn tháng</h1>
          <DatePicker
            placeholder="Chọn tháng"
            defaultValue={moment()}
            onChange={(date, dateString) => onChangeDate(dateString, "month")}
            format={monthFormat}
            picker="month"
          />
        </Col>
      </Row>
    </div>
  );
}
