import React, { Component } from "react";
import ItemForm from "../itemForm/itemForm";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Category/Item/index";
import _ from "lodash";
class ItemEdit extends Component {
  getItemActions = async (params) => {
    const item = _.clone(params);
    item.sku = params.ma_vt;
    item.ten_vt_in = params.ten_vt;
    item.iditem = this.props.Master.iditem;
    await _.filter(this.props.lstUnit2, (itemFilter) => {
      if (itemFilter.dvt_id === item.dvt_id) {
        item.dvt = itemFilter.dvt_name;
      }
    });
    await this.props.updateItem(item);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.checkUpdate !== prevProps.checkUpdate) {
      openNotificationWithIcon("success", "Sửa vật tư thành công !");
      this.setState({ loading: false });
      this.props.clearCache();
      this.props.checkVisible({ isVisible: false, editSuccess: true });
    }
  };

  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <ItemForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          getItemActions={this.getItemActions}
          title="Sửa thông tin vật tư"
          Master={Master}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    checkUpdate: state.ItemReducer.checkUpdate,
    lstUnit2: state.ItemReducer.lstUnit2,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateItem: (query) => {
      dispatch(actions.updateItem(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemEdit);
