import React, { Component } from "react";
import { Drawer, Button, Divider, Form, Row, Col, Spin } from "antd";
import DHSTable from "../../../../../../common/DHSControl/DHSTable/DHSTable";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { FormatColumnDHSTable, result } from "../../../../../controller/Format";
import { toUpperPropertyName } from "../../../../../controller/Format";
import "./index.css";
import * as actions from "../../../../../../redux/actions/Sales/salesOrder/salesOrder";
import { widthDrawer } from "../../../index";
const objectColumnPhQU = {
  so_ct: { name: "Số đơn hàng", width: 100 },
  ngay_ct: { name: "Ngày tạo", width: 100, formatDate: true },
  ma_kh: { name: "Mã khách hàng", width: 100 },
  ten_kh: { name: "Tên khách hàng", width: 300 },
  ma_nt: { name: "Mã ngoại tệ", width: 100 },
  t_tt: { name: "Tiền hàng", width: "10%", formatMoney: true },
};
const objectColumnCtQU = {
  TEN_VT: { name: "Sản phẩm", width: 300 },
  DVT: { name: "Đơn vị tính", width: 100 },
  SO_LUONG: { name: "Số lượng", width: 100 },
  GIA_NT2: { name: "Đơn giá", width: 100, formatMoney: true },
  TIEN_NT2: { name: "Thành tiền", width: 100, formatMoney: true },
};
class ListSO0 extends Component {
  state = {
    customColumnsPh: [],
    customColumnsCt: [],
    phChoose: {},
    dsCTQuBySoQu: [],
    dsCTSelection: [],
    loading: false,
  };
  componentDidMount = async () => {
    const { dsCtQU, dsPhQU } = this.props;
    _.map(dsCtQU, (item) => {
      return toUpperPropertyName(item);
    });
    await FormatColumnDHSTable(objectColumnPhQU);
    this.setState({ customColumnsPh: result });
    await FormatColumnDHSTable(objectColumnCtQU);
    this.setState({ customColumnsCt: result });
  };

  confirm = () => {
    this.props.checkVisible({
      isVisible: false,
      phChoose: this.state.phChoose,
    });
    this.props.listCtChooseFromQU(this.state.dsCTSelection);
  };
  render() {
    const { isVisible, dsPhQU } = this.props;
    const {
      loading,
      customColumnsPh,
      customColumnsCt,
      dsCTQuBySoQu,
    } = this.state;
    return (
      <div style={{ height: "100%" }}>
        <Drawer
          title="Chọn báo giá"
          width={widthDrawer}
          onClose={this.handleCancel}
          visible={isVisible}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{ marginRight: 8 }}
                onClick={this.confirm}
              >
                Xác nhận
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Spin spinning={loading}>
            <div className="listSO0">
              <DHSTable
                data={dsPhQU}
                customColumns={customColumnsPh}
                rowKey="so_ct"
                getDataRow={this.getDataRow}
                multiSelection={this.multiSelection}
              />
              <DHSTable
                data={dsCTQuBySoQu}
                customColumns={customColumnsCt}
                rowKey="STT_REC0"
                scroll={{ y: 500, x: 700 }}
              />
            </div>
          </Spin>
        </Drawer>
      </div>
    );
  }
  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false });
  };
  //Select row trên table và show chi tiết QU đã select
  getDataRow = (params) => {
    const { dsCtQU } = this.props;
    if (!_.isEmpty(params)) {
      const data = _.filter(dsCtQU, (item) => {
        return item.SO_QU === params.so_ct;
      });
      this.setState({ dsCTQuBySoQu: data });
    } else {
      this.setState({ dsCTQuBySoQu: [] });
    }
  };
  //Select QU lấy ctQu đã select
  multiSelection = (params) => {
    const { dsCtQU } = this.props;
    //kiểm tra params truyền từ chooseSO0
    !_.isEmpty(params)
      ? this.setState({ phChoose: params[0] })
      : this.setState({ phChoose: {} });
    //lọc CtQU mà user đã chọn thành new array
    const data = _.filter(dsCtQU, (item, index) => {
      return _.some(params, (itemFilter) => {
        item.ID = index + 1;
        item.SELL_PRICE = item.GIA2;
        return itemFilter.stt_rec === item.STT_REC;
      });
    });
    this.setState({ dsCTSelection: data });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listCustomer: state.SO1Reducer.listCustomer,
    dsPhQU: state.SO1Reducer.dsPhQU,
    dsCtQU: state.SO1Reducer.dsCtQU,
    checkDispatch: state.SO1Reducer.checkDispatch,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    lookupSoQuotation: (query) => {
      dispatch(actions.lookupSoQuotation(query));
    },
    listCtChooseFromQU: (dsCtQU) => {
      dispatch(actions.listCtChooseFromQU(dsCtQU));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSO0);
