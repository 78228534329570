import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import { Button } from "devextreme-react/button";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const _columns = [
  { name: "TASKNAME", title: "Mô tả", width: { wch: 80 } },
  { name: "SO_LUONG_KH", title: "Số lượng KH" },
  { name: "SO_LUONG_HT", title: "Số lượng HT" },
  { name: "TG_SETMAY", title: "Thời gian set máy (phút)" },
  { name: "TG_GA", title: "Thời gian Gá (phút)" },
  { name: "TG_GC", title: "Thời gian GC (phút)" },
  { name: "STARTDATE", title: "Bắt đầu" },
  { name: "FINISHDATE", title: "Kết thúc" },
  { name: "NGAY_GIAOHANG", title: "Ngày giao hàng" },
  { name: "SO_NGAYGC", title: "Số ngày GC" },
  { name: "SO_NGAYGC_PS", title: "Số ngày phát sinh" },
  {
    name: "FINISHDATE_ACTUAL",
    title: "Ngày kết thúc thực tế",
    width: { wpx: 90 },
  },
  { name: "PROGRESS", title: "% Hoàn thành", width: { wpx: 90 } },
  { name: "PRIORITY_NAME", title: "Ưu tiên", width: { wpx: 90 } },
  { name: "TG_SETMAY_ACTUAL", title: "TG Set máy thực tế", width: { wpx: 90 } },
  { name: "TG_GA_ACTUAL", title: "TG GA thực tế", width: { wpx: 90 } },
  { name: "TG_GC_ACTUAL", title: "TG GC thực tế", width: { wpx: 90 } },
  {
    name: "TG_RUN_ACTUAL",
    title: "Thời gian GC 1 pcs thực tế",
    width: { wpx: 90 },
  },
];

export default function exportExcel(props) {
  const { button, listPlanning, time_from, time_to } = props;
  const [multiDataSet, setDataSet] = useState([]);
  const [dataSort, setDataSort] = useState([]);

  const dateRange = (startDate, endDate, steps = 1) => {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push({
        value: moment(currentDate).format("DD/MM/yyyy HH:mm"),
        title: moment(currentDate).format("MMM DD"),
      });
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
  };

  useEffect(() => {
    const getDaysArray = dateRange(
      moment(time_from).toString(),
      moment(time_to).toString()
    );
    setColumns(_columns.concat(getDaysArray));
  }, [time_from, time_to]);

  const [columns, setColumns] = useState(_columns);

  useEffect(() => {
    if (!_.isEmpty(listPlanning)) {
      const sortedArray = listPlanning.reduce((accumulator, currentValue) => {
        let item = accumulator.find((x) => x.TASKID === currentValue.PARENTID);
        let index = accumulator.indexOf(item);
        index = index !== -1 ? index + 1 : accumulator.length;
        accumulator.splice(index, 0, currentValue);
        return accumulator;
      }, []);
      setDataSort(sortedArray);
    }
  }, [listPlanning]);
  const dateProgress = (startDate, finishDate, columnDate) => {
    let sDate = moment(startDate, "DD/MM/YYYY").toISOString();
    let cDate = moment(columnDate || "01/01/1900", "DD/MM/YYYY").toISOString();
    let fDate = moment(finishDate, "DD/MM/YYYY").toISOString();
    let date = false;
    if (sDate <= cDate && cDate <= fDate) {
      date = true;
    }
    return date;
  };
  const columnProgress = (progress, so_ngaygc) => {
    return Math.ceil((Math.ceil(so_ngaygc) * progress) / 100);
  };
  const setFgColor = (date, level, progress) => {
    if (level === -2) {
      return "D3D3D3";
    } else if (level === -1) {
      return "E07C39";
    } else if (level === 0) {
      return "4794BA";
    } else if (date && progress > 0) {
      return "323B44";
    } else if (date && progress < 0) {
      return "8BCFA9";
    } else {
      return "FFFFFF";
    }
  };
  useEffect(() => {
    if (!_.isEmpty(dataSort)) {
      let data = [];
      Promise.all(
        _.map(dataSort, (item1) => {
          let data1 = [];
          var progress = columnProgress(item1.PROGRESS, item1.SO_NGAYGC);
          _.map(columns, (item2, index) => {
            const level = item1.PLANLEVEL;
            const date = dateProgress(
              item1.STARTDATE,
              item1.FINISHDATE,
              item2.value
            );
            data1.push({
              value: item1[item2.name] || "",
              style: {
                fill: {
                  fgColor: {
                    rgb: setFgColor(date, level, progress),
                  },
                },
              },
            });
            progress = date ? progress - 1 : progress;
            if (index === columns.length - 1) {
              data.push(data1);
            }
          });
        })
      );
      setDataSet([{ data, columns }]);
    }
  }, [dataSort, columns]);
  return (
    <div>
      <ExcelFile element={button} filename="BaoCaoKeHoachMay">
        <ExcelSheet dataSet={multiDataSet} name="Organization" />
      </ExcelFile>
    </div>
  );
}
