import * as types from "../../../constants/ActionTypes";
var initialState = {
  checkStatus: null,
};

var ChangePassword = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD:
      return {
        ...state,
        checkStatus: action.payload,
      };
    case types.CLEAR_CACHE_CP:
      return {
        ...state,
        checkStatus: null,
      };
    default:
      return state;
  }
};

export default ChangePassword;
