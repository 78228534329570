import Manufacturing from "../../../components/layout/Manufacturing/manufacturing";
import MachineStatus from "../../../components/layout/Manufacturing/MachineStatus/index";
import MachineReport from "../../../components/layout/Manufacturing/MachineReports/machineReports";
import MachineReportTimeLine from "../../../components/layout/Manufacturing/MachineReports/MachineTimeLine/machineTimeLine";
import MachineSchedule from "../../../components/layout/Manufacturing/MasterPlanning/MachineSchedule/machineSchedule";
import MachineScheduleGeneral from "../../../components/layout/Manufacturing/MasterPlanning/MachineScheduleGeneral/machineScheduleGeneral";
const manufacturingRoutes = [
  {
    path: "/Manufacturing",
    exact: true,
    main: ({ history }) => <Manufacturing history={history} />,
  },
  {
    path: "/machinestatus",
    exact: true,
    main: ({ history }) => <MachineStatus history={history} />,
  },
  {
    path: "/machinereport",
    exact: false,
    main: ({ history }) => <MachineReport history={history} />,
  },
  {
    path: "/machinereporttimeline",
    exact: false,
    main: ({ history }) => <MachineReportTimeLine history={history} />,
  },
  {
    path: "/machineschedule",
    exact: false,
    main: ({ history }) => <MachineSchedule history={history} />,
  },
  {
    path: "/machineschedulegeneral",
    exact: false,
    main: ({ history }) => <MachineScheduleGeneral history={history} />,
  },
];

export default manufacturingRoutes;
