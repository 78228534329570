import React, { useState, useEffect } from "react";
import SellPriceTypesForm from "../sellPriceTypesForm/sellPriceTypesForm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../redux/actions/Sales/sellPriceBase/sellPriceTypes/sellPriceTypes";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";

export default function SellPriceTypeCreate(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, Master } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);

  const checkEdit = useSelector(
    (state) => state.SellPriceTypesReducer.checkEdit
  );

  useEffect(() => {
    if (checkEdit) {
      openNotificationWithIcon("success", "Sửa loại giá bán thành công !");
      dispatch(actions.clearCache());
      dispatch(actions.getListSellPriceType({ MA_CTY }));
      checkVisible(false);
    }
  }, [checkEdit]);

  const handleActions = (params) => {
    dispatch(actions.updateSellPriceType(params));
  };

  return (
    <SellPriceTypesForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      Master={Master}
      handleActions={handleActions}
      title="Sửa loại giá bán"
    />
  );
}
