import React, { Component } from "react";
import { Drawer, Button, Row, Col, Form, Spin } from "antd";
import {
  DHSInput,
  DHSInputTextArea,
  DHSDatePicker,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import moment from "moment";
import "./index.css";
import { connect } from "react-redux";
import * as actions from "../../../../../redux/actions/CustomerServices/crmCustomerRequest/crmCustomerRequest";
import _ from "lodash";
const validateMessages = {
  required: "Trường này là bắt buộc",
};

class CrmCustomerRequest extends Component {
  state = {
    loading: false,
    MA_CTY: this.props.ma_cty,
    IDCUSTOMER: this.props.IDCUSTOMER || "",
    SUBJECT: "",
    DESCRIPTION: "",
    REQUEST_BY: "",
    MOBILE: "",
    DEPARTMENT: "",
    SO_CT: this.props.so_ct,
    COMPLETED_REQUEST_DATE: moment(),
    NGAY_CT: moment(),
    CUSER: this.props.userName,
    LUSER: this.props.userName,
    disabledEdit: false,
  };

  isEditAction = () => {
    const { Master } = this.props;
    this.setState(Master);
  };

  componentDidMount = () => {
    this.props.getSoCtCSR({ ma_cty: this.props.ma_cty });
    if (!_.isEmpty(this.props.Master)) {
      this.isEditAction();
      this.setState({ disabledEdit: this.props.disabled });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.so_ct, prevProps.so_ct)) {
      this.setState({ SO_CT: this.props.so_ct });
    }
  };

  handleOk = () => {
    this.props.getItemActions(this.state);
  };

  handleChangeValue = (e) => {
    this.setState({ [e.name]: e.value });
  };

  render() {
    const { isVisible, rules } = this.props;
    const {
      SUBJECT,
      DESCRIPTION,
      REQUEST_BY,
      MOBILE,
      DEPARTMENT,
      SO_CT,
      NGAY_CT,
      COMPLETED_REQUEST_DATE,
      loading,
      disabledEdit,
    } = this.state;
    return (
      <div>
        <Drawer
          title="Thêm mới yêu cầu"
          placement="right"
          width={500}
          closable={false}
          visible={isVisible}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                form="submit-form"
                key="submit"
                htmlType="submit"
                style={{
                  marginRight: 8,
                }}
              >
                Lưu
              </Button>
              <Button onClick={this.handleCancel}>Hủy</Button>
            </div>
          }
        >
          <Form
            id="submit-form"
            onFinish={this.handleOk}
            layout="vertical"
            validateMessages={validateMessages}
            className="customer-request"
          >
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <DHSInput
                  label="Số yêu cầu"
                  name="SO_CT"
                  value={SO_CT}
                  getValueChange={this.handleChangeValue}
                  disable={true}
                />
              </Col>
            </Row>
            <DHSInput
              label="Tiêu đề"
              name="SUBJECT"
              value={SUBJECT}
              rules={rules}
              nameRules="SUBJECT"
              getValueChange={this.handleChangeValue}
              disable={disabledEdit}
            />
            <DHSInputTextArea
              label="Nội dung"
              name="DESCRIPTION"
              value={DESCRIPTION}
              rules={rules}
              nameRules="DESCRIPTION"
              getValueChange={this.handleChangeValue}
              disable={disabledEdit}
            />
            <DHSInput
              label="Người yêu cầu"
              name="REQUEST_BY"
              value={REQUEST_BY}
              getValueChange={this.handleChangeValue}
              disable={disabledEdit}
            />
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <DHSInput
                  label="Điện thoại"
                  name="MOBILE"
                  value={MOBILE}
                  getValueChange={this.handleChangeValue}
                  disable={disabledEdit}
                />
              </Col>
              <Col span={12}>
                <DHSInput
                  label="Phòng ban"
                  name="DEPARTMENT"
                  value={DEPARTMENT}
                  getValueChange={this.handleChangeValue}
                  disable={disabledEdit}
                />
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <DHSDatePicker
                  label="Ngày yêu cầu"
                  nameState="NGAY_CT"
                  value={NGAY_CT}
                  getValueChange={this.handleChangeValue}
                  disable={disabledEdit}
                />
              </Col>
              <Col span={12}>
                <DHSDatePicker
                  label="Ngày yêu cầu hoàn thành"
                  nameState="COMPLETED_REQUEST_DATE"
                  value={COMPLETED_REQUEST_DATE}
                  getValueChange={this.handleChangeValue}
                  disable={disabledEdit}
                />
              </Col>
            </Row>
          </Form>
        </Drawer>
      </div>
    );
  }
  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false, createSuccess: false });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    so_ct: state.CrmCustomerRequestReducer.so_ct,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getSoCtCSR: (ma_cty) => {
      dispatch(actions.getSoCtCSR(ma_cty));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CrmCustomerRequest);
