import React, { Component } from "react";
import { Drawer, Button, Divider, Form, Row, Col, Spin } from "antd";
import { DHSSelectOpt } from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { periodData } from "../../../../../controller/DataSample";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";
import { widthDrawer } from "../../../index";
// import { DHSInput } from ;
import * as actions from "../../../../../../redux/actions/Sales/salesOrder/salesOrder";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
class ChooseSO0 extends Component {
  state = {
    resize: widthDrawer,
    idcustomer: "",
    idtime: ("0" + (moment().month() + 1)).slice(-2),
    formDate: moment().startOf("month").format("L"),
    toDate: moment().endOf("month").format("L"),
    loading: false,
  };
  handleChangeTime = (value) => {
    switch (value) {
      case "q1": {
        this.setStateForIDTime(value, "01", "03");
        break;
      }
      case "q2": {
        this.setStateForIDTime(value, "04", "06");
        break;
      }
      case "q3": {
        this.setStateForIDTime(value, "07", "09");
        break;
      }
      case "q4": {
        this.setStateForIDTime(value, "10", "12");
        break;
      }
      case "d6t": {
        this.setStateForIDTime(value, "01", "06");
        break;
      }
      case "c6t": {
        this.setStateForIDTime(value, "07", "12");
        break;
      }
      case "cn": {
        this.setStateForIDTime(value, "01", "12");
        break;
      }
      case "formTo": {
        this.setStateForIDTime(value, "01", "12");
        break;
      }
      default: {
        this.setStateForIDTime(value, value, value);
        break;
      }
    }
  };
  setStateForIDTime = (IDTime, value1, value2) => {
    this.setState({
      idtime: IDTime,
      formDate: moment(`${moment().year()}-${value1}-01`)
        .startOf("month")
        .format("L"),
      toDate: moment(`${moment().year()}-${value2}-01`)
        .endOf("month")
        .format("L"),
    });
  };
  onConfirm = () => {
    const query = {
      ma_cty: this.props.ma_cty,
      idcustomer: this.state.idcustomer,
      cdate: this.state.formDate,
      ldate: this.state.toDate,
    };
    if (!this.state.loading) {
      this.setState({ loading: true });
      this.props.lookupSoQuotation(query);
    }
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.checkDispatch !== prevProps.checkDispatch) {
      this.setState({ loading: false });
      if (_.isEmpty(this.props.dsPhQU)) {
        openNotificationWithIcon("success", "Không có sẳn báo giá");
      } else {
        this.props.checkVisible({ isVisible: false, dsPhQU: true });
      }
    }
  };
  render() {
    const { isVisible, listCustomer } = this.props;
    const { loading, idtime } = this.state;
    return (
      <div>
        <Drawer
          title="Lọc báo giá"
          width={this.state.resize}
          onClose={this.handleCancel}
          visible={isVisible}
        >
          <Spin spinning={loading}>
            <Divider orientation="left">Điều kiện lọc</Divider>
            <Form
              layout="vertical"
              validateMessages={validateMessages}
              onFinish={this.onConfirm}
              // onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col span={20} offset={2}>
                  <DHSSelectOpt
                    label="Khách hàng"
                    name="IDcustomer"
                    array={listCustomer}
                    valueOpt={"idcustomer"}
                    valueRender={"ten_kh"}
                    rules={true}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col span={20} offset={2}>
                  <DHSSelectOpt
                    label="Thời gian"
                    name="idtime"
                    array={periodData}
                    valueOpt={"value"}
                    valueRender={"name"}
                    rules={true}
                    defaultValue={idtime.toString()}
                    onChange={this.handleChangeTime}
                  />
                </Col>
                <Col span={20} offset={2}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Xác nhận
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </div>
    );
  }
  handleCancel = (e) => {
    this.props.checkVisible({ isVisible: false, dsPhQU: false });
  };
  handleChange = (value) => {
    this.setState({ idcustomer: value });
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listCustomer: state.SO1Reducer.listCustomer,
    dsPhQU: state.SO1Reducer.dsPhQU,
    dsCtQU: state.SO1Reducer.dsCtQU,
    checkDispatch: state.SO1Reducer.checkDispatch,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    lookupSoQuotation: (query) => {
      dispatch(actions.lookupSoQuotation(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseSO0);
