import * as types from "../../../constants/ActionTypes";

var initialState = {
  listDm: {},
  listDataFilterINRpt: [],
  RptInventoryMovement: [],
};

var INReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LISTS_DM_IN_REPORT: {
      return {
        ...state,
        listDm: action.payload,
      };
    }
    case types.GET_RPT_IN: {
      return {
        ...state,
        listDataFilterINRpt: action.payload,
      };
    }
    case types.POS_RPT_IN_MOVEMENT: {
      return {
        ...state,
        RptInventoryMovement: action.payload.dsResult,
      };
    }
    default:
      return state;
  }
};

export default INReportsReducer;
