import React, { PureComponent } from "react";
import { Spin } from "antd";
import { InfinityTable as Table } from "antd-table-infinity";
import "./index.css";
import _ from "lodash";
export default class DHSTable extends PureComponent {
  state = {
    rowId: "",
    selections: [],
  };
  onClickRow = (record) => {
    const { rowKey } = this.props;
    return {
      onClick: () => {
        if (!_.isNil(this.props.getDataRow)) {
          if (record[rowKey] === this.state.rowId) {
            this.setState({
              rowId: "",
            });
            this.props.getDataRow({});
          } else {
            this.props.getDataRow(record);
            this.setState({ rowId: record[rowKey] });
          }
        } else {
          this.setState({ rowId: "" });
        }
      },
      onDoubleClick: () => {
        if (!_.isNil(this.props.doubleClickRow)) {
          this.setState({
            rowId: record[rowKey],
          });
          if (!_.isNil(this.props.getDataRow)) {
            this.props.getDataRow(record);
          }
          this.props.doubleClickRow();
        }
      },
    };
  };

  setRowClassName = (record) => {
    let id = _.isUndefined(this.props.idItemClick)
      ? this.state.rowId
      : this.props.idItemClick;
    return record[this.props.rowKey] === id ? "clickRowStyl" : "";
  };
  onSelectChange = (record, selected, selectedRows, nativeEvent) => {
    if (record) {
      this.props.multiSelection(selectedRows);
    } else {
      this.props.multiSelection([]);
    }
  };

  render() {
    const { data, customColumns, rowKey, scroll, multiSelection } = this.props;
    const { selections } = this.state;
    return (
      <div className="DHSTable">
        <Table
          rowSelection={
            !_.isNil(multiSelection)
              ? {
                  selections,
                  onSelect: this.onSelectChange,
                  onSelectAll: this.onSelectChange,
                }
              : undefined
          }
          pageSize={30}
          dataSource={data}
          columns={customColumns}
          scroll={_.isNil(scroll) ? { y: 500, x: 1000 } : scroll}
          pagination={false}
          rowKey={rowKey}
          size="small"
          onRow={this.onClickRow}
          rowClassName={this.setRowClassName}
        />
      </div>
    );
  }
}
