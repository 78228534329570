import React, { Component } from "react";
import ItemForm from "../itemForm/itemForm";
export default class ItemDetail extends Component {
  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <ItemForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          title="Thông tin vật tư"
          Master={Master}
          noneEdit={true}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
