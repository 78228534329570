import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const changePassword = (userInfo) => (dispatch, getState) => {
  callApi("api/SIChangePassword/ChangePassword", "POST", userInfo)
    .then((res) =>
      dispatch({
        type: types.CHANGE_PASSWORD,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_CACHE_CP,
  };
};
