import * as types from "../../../constants/ActionTypes";

var initialState = {
  listSalesOrder: [],
  ctSalesOrder: [],
  phSalesOrder: {},
  contactSalesOrder: [],
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: {},
  newEdit: {},
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  listDm: {},
  checkAddNewCustomer: false,
  listNewAddress: [],
  dsPhQU: [],
  dsCtQU: [],
  lstCtChooseFromQU: [],
  checkDispatch: false,
  lstSPType: [],
};

var SalesOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SO1: {
      return {
        ...state,
        listSalesOrder: action.payload,
      };
    }
    case types.FILT_SO1: {
      return {
        ...state,
        listSalesOrder: action.payload,
      };
    }
    case types.GET_CT_SO1: {
      return {
        ...state,
        ctSalesOrder: action.payload,
      };
    }
    case types.GET_PH_SO1: {
      return {
        ...state,
        phSalesOrder: action.payload,
      };
    }
    case types.GET_CONTACT_SO1: {
      return {
        ...state,
        contactSalesOrder: action.payload,
      };
    }
    case types.GET_CUSTOMER_SO1: {
      return {
        ...state,
        customer: action.payload[0],
      };
    }
    case types.GET_LIST_CUSTOMER_SO1: {
      return {
        ...state,
        listCustomer: action.payload,
      };
    }
    case types.GET_SO_CT_SO1: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_SO1: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_SO1: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LIST_DM_SO1: {
      return {
        ...state,
        listStatus: action.payload.dm_trangthai_SO1,
        lstSPType:action.payload.dm_sp_type
      };
    }
    case types.GET_LIST_CURRENCY_SO1: {
      return {
        ...state,
        listCurrency: action.payload,
      };
    }
    case types.GET_EXCHANGE_RATE_SO1: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_SO1: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.LOOKUP_SO_QUOTATION: {
      return {
        ...state,
        dsPhQU: action.payload.dsPh,
        dsCtQU: action.payload.dsCt,
        checkDispatch: !state.checkDispatch,
      };
    }
    case types.CLEAR_SO1: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctSalesOrder: [],
        phSalesOrder: {},
        newItem: null,
        lstCtChooseFromQU: [],
        contactSalesOrder: [],
        exchangeRate: "",
      };
    }
    case types.CLEAR_HANDLE_STT_SO1: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }
    case types.LIST_CT_CHOOSE_FROM_QU: {
      return { ...state, lstCtChooseFromQU: action.payload };
    }
    default:
      return state;
  }
};

export default SalesOrderReducer;
