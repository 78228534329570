import React, { Component } from "react";
import { Button, Popconfirm } from "antd";
import { openNotificationWithIcon } from "../../notification/notification";
import { DeleteOutlined } from "@ant-design/icons";

export default class BtnDelete extends Component {
  render() {
    const { objDelele } = this.props;
    return (
      <div>
        <Popconfirm
          title="Bạn chắc chắn muốn xóa ?"
          onConfirm={() => this.props.confirmDelete(objDelele)}
          okText="Đồng ý"
          cancelText="Hủy"
          disabled={Object.keys(objDelele).length == 0 ? true : false}
        >
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              if (Object.keys(objDelele).length == 0) {
                openNotificationWithIcon(
                  "error",
                  "Vui lòng chọn dữ liệu cần xóa !"
                );
              }
            }}
          >
            Xóa
          </Button>
        </Popconfirm>
      </div>
    );
  }
}
