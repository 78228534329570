import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toUpperPropertyNameByArray } from "../../../components/controller/Format";
import DHSDataGrid from "../../DHSControl/DHSDataGrid/DHSDataGrid";
import DHSHanleFilter from "../../DHSControl/DHSHandleFilter/DHSHanleFilter";
import ReactToPrint from "react-to-print";
import Rp05 from "../../DHSControl/DHSTemplateRP/rp05/rp05";
import { PageHeader } from "antd";
import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";
import "./index.css";
DHSReport.propTypes = {
  columns: PropTypes.array, // các property muốn render lên lưới của datagrid
  data: PropTypes.array, //danh sách dữ liệu render
  dataKey: PropTypes.string, //Key của dữ liệu render không trùng nhau
  title: PropTypes.string, //Tiêu đề report
  onBack: PropTypes.string, //url muốn back về sau khi sử dụng
  FilterComponent: PropTypes.elementType, //Component filter của báo cáo (do mỗi báo cáo các property tìm kiếm riêng nên phải tách)
};
DHSReport.defaultProps = {};
export default function DHSReport(props) {
  //Các props cần thiết để sử dụng DHSReport
  const { columns, data, dataKey, title, onBack, FilterComponent } = props;
  const componentRef = useRef();
  const btnPrintRef = useRef();
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [_data, setData] = useState(data);
  const [onPrint, setPrint] = useState(false);
  const [onDateRp, setDateRp] = useState({
    FROMDATE: moment().startOf("month"),
    TODATE: moment().endOf("month"),
  });

  //Nếu data thay đổi thì sẽ chạy useEffect này
  useEffect(() => {
    setData(data);
  }, [data]);

  //Lấy dữ liệu tìm kiếm được
  const getDataFilter = (params) => {
    setData(params);
  };

  //On/Off visible các modal hoặc drawer
  const checkVisible = (params) => {
    setOpenDrawerFilter(params.visibleChange);
    setDateRp(params); //lấy Date đã filter report để hỗ trợ Print
  };

  //Nếu state onPrint thay đổi và giá trị bằng true thì print sẽ được thực hiện
  useEffect(() => {
    if (onPrint) {
      btnPrintRef.current.click();
      setPrint(false);
    }
  }, [onPrint]);
  return (
    <div className="DHSReport" style={{ height: "100%" }}>
      <PageHeader
        className="site-page-header"
        title={title}
        onBack={() => history.push(`/${onBack}`)}
      />
      <DHSHanleFilter
        getDataFilter={getDataFilter}
        dataFilter={data}
        openFiler={() => {
          setOpenDrawerFilter(true);
        }}
      />
      <DHSDataGrid
        column={columns}
        data={_data}
        dataKey={dataKey}
        selectionChanged={() => {}}
        hanleRowChange={() => {}}
        exportExcel={true}
        disabled={false}
        showColumnLines={false}
        onPrintChange={setPrint}
      />
      <ReactToPrint
        trigger={() => (
          <button
            style={{
              display: "none",
            }}
            ref={btnPrintRef}
          >
            In
          </button>
        )}
        content={() => componentRef.current}
      />
      {openDrawerFilter ? (
        <FilterComponent
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
        />
      ) : (
        <></>
      )}
      <div style={{ display: "none" }}>
        <Rp05
          ref={componentRef}
          title={title}
          dateRp={onDateRp}
          dataTable={data}
          contentTable={columns}
        />
      </div>
    </div>
  );
}
