import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListMovementIssue = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch02/ListMovementIssue", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHMovementIssue = (detail) => (dispatch, getState) => {
  callApi("api/inVch02/GetPHMovementIssue", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTMovementIssue = (detail) => (dispatch, getState) => {
  callApi("api/inVch02/GetCTMovementIssue", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch02/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/inVch02/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewMovementIssue = (query) => (dispatch, getState) => {
  callApi("api/inVch02/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteAMovementIssue = (master) => (dispatch, getState) => {
  callApi("api/inVch02/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_IN2,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updateMovementIssue = (so1) => (dispatch, getState) => {
  callApi("api/inVch02/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/inVch02/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch02/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_IN2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_IN2,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_IN2,
  };
};
