import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListCustomerKind = (ma_cty) => (dispatch, getState) => {
  callApi("api/CustomerKind/lstCustomerKind", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CUS_KIND,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteCustomerKind = (query) => (dispatch, getState) => {
  callApi("api/CustomerKind/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_CUS_KIND,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewCustomerKind = (model) => (dispatch, getState) => {
  callApi("api/CustomerKind/InsertCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.INSERT_CUS_KIND,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const updateCustomerKind = (model) => (dispatch, getState) => {
  callApi("api/CustomerKind/UpdateCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CUS_KIND,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_CUS_KIND,
  };
};
