import * as types from "../../../constants/ActionTypes";

var initialState = {
  listPurchaseOrder: [],
  ctPurchaseOrder: [],
  phPurchaseOrder: {},
  contactPurchaseOrder: [],
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  checkDispatch: false,
  sysExchange: [],
  paymentTerm: [],
  lstIncoterms: [],
  dm_bp: [],
  dm_hopdong_mua: [],
  dm_trangthaiDoc_PO1: [],
  lstUnit2: [],
};

var PurchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_PO1: {
      return {
        ...state,
        listPurchaseOrder: action.payload,
      };
    }
    case types.FILT_PO1: {
      return {
        ...state,
        listPurchaseOrder: action.payload,
      };
    }
    case types.GET_CT_PO1: {
      return {
        ...state,
        ctPurchaseOrder: action.payload,
      };
    }
    case types.GET_PH_PO1: {
      return {
        ...state,
        phPurchaseOrder: action.payload,
      };
    }
    case types.GET_CUSTOMER_PO1: {
      return {
        ...state,
        customer: action.payload[0],
      };
    }
    case types.GET_SO_CT_PO1: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_PO1: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_PO1: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LIST_STATUS_PO1: {
      return {
        ...state,
        listStatus: action.payload,
      };
    }
    case types.GET_LISTS_DM_PO1: {
      return {
        ...state,
        listCurrency: action.payload.dm_nt,
        listCustomer: action.payload.dm_kh,
        sysExchange: action.payload.sysExchange,
        paymentTerm: action.payload.paymentTerm,
        lstIncoterms: action.payload.lstIncoterms,
        dm_bp: action.payload.dm_bp,
        dm_hopdong_mua: action.payload.dm_hopdong_mua,
        dm_trangthaiDoc_PO1: action.payload.dm_trangthaiDoc_PO1,
        lstUnit2: action.payload.lstUnit2,
      };
    }
    case types.GET_EXCHANGE_RATE_PO1: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_PO1: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }

    case types.CLEAR_PO1: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctPurchaseOrder: [],
        phPurchaseOrder: {},
        newItem: null,
      };
    }
    case types.CLEAR_HANDLE_STT_PO1: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }

    default:
      return state;
  }
};

export default PurchaseOrderReducer;
