const antd = require("antd");
const { Option } = antd.Select;
import _ from "lodash";
const RenderOptionSelect = (array, valueOpt, valueRender) => {
  var result = null;
  if (!_.isEmpty(array)) {
    result = array.map((item, index) => {
      return (
        <Option value={item[valueOpt]} key={index}>
          {item[valueRender]}
        </Option>
      );
    });
  }
  return result;
};

export default RenderOptionSelect;
