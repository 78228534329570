import CustomerServices from "../../../components/layout/CustomerServices/customerServices";
import CrmCustomer from "../../../components/layout/CustomerServices/crmCustomer/crmCustomer";
import CrmCustomerEdit from "../../../components/layout/CustomerServices/crmCustomer/crmCustomerEdit/crmCustomerEdit";

const customerServicesRoutes = [
  {
    path: "/CustomerService",
    exact: false,
    main: ({ history }) => <CustomerServices history={history} />,
  },
  {
    path: "/CrmCustomer",
    exact: false,
    main: ({ history }) => <CrmCustomer history={history} />,
  },
  {
    path: "/CrmCustomerEdit",
    exact: false,
    main: ({ history }) => <CrmCustomerEdit history={history} />,
  },
];

export default customerServicesRoutes;
