import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListSalesReturn = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch04/ListSalesReturn", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHSalesReturn = (detail) => (dispatch, getState) => {
  callApi("api/soVch04/GetPHSalesReturn", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTSalesReturn = (detail) => (dispatch, getState) => {
  callApi("api/soVch04/GetCTSalesReturn", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch04/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getCustomer = (customer) => (dispatch, getState) => {
  callApi("api/soVch04/GetCustomer", "POST", customer)
    .then((res) =>
      dispatch({
        type: types.GET_CUSTOMER_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch04/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListStatus = () => (dispatch, getState) => {
  callApi("api/soVch04/ListStatus", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_STATUS_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/soVch04/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewSalesReturn = (quotation) => (dispatch, getState) => {
  callApi("api/soVch04/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteASalesReturn = (master) => (dispatch, getState) => {
  callApi("api/soVch04/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateSalesReturn = (so1) => (dispatch, getState) => {
  callApi("api/soVch04/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_SO4,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_SO4,
  };
};

export const filt = (query) => (dispatch, getState) => {
  callApi("api/soVch04/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_SO4,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
