import React, { Component } from "react";
import { Form, Input, Button, Spin, Divider, Select } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import logo from "../../../common/images/logodhsoft.png";
import * as actions from "../../../redux/actions/Login/index";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../common/notification/notification";
import "./index.css";
import _ from "lodash";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 18 },
};
const validateMessages = {
  required: "Trường này là bắt buộc",
};
const { Option } = Select;
class LoginPage extends Component {
  state = {
    loading: false,
    userName: "",
    passWord: "",
    ma_cty: "",
  };

  componentDidMount = () => {
    const { getListCompany } = this.props;
    getListCompany();
    this.setState({ loading: true });
  };
  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.listCompany, prevProps.listCompany)) {
      this.setState({ loading: false });
    }
    if (
      !_.isEqual(this.props.isLogin, prevProps.isLogin) &&
      !_.isNull(this.props.isLogin)
    ) {
      if (this.props.isLogin) {
        // this.props.sessionLogin(this.state.userName, this.state.ma_cty);
        this.props.sessionLogin(
          this.state.userName,
          this.props.userInfo.ACCESSCOMPANIES
        );
        this.props.history.push("/");
        openNotificationWithIcon("success", "Thông tin đăng nhập thành công");
        // this.getCompanyName(this.state.ma_cty);
        this.getCompanyName(this.props.userInfo.ACCESSCOMPANIES);
      } else {
        openNotificationWithIcon("error", "Thông tin đăng nhập không đúng");
      }
      this.setState({ loading: false });
      this.props.clearCache();
    }
  };
  handleChangeCompany = (value) => {
    this.setState({ ma_cty: value });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getCompanyName = (ma_cty) => {
    this.props.listCompany.map((item) => {
      if (item.ma_cty === ma_cty) {
        localStorage.setItem(
          "SessionLogin",
          JSON.stringify({
            userName: this.state.userName,
            ma_cty: ma_cty,
            companyName: item.ten_cty,
            dia_chi: item.dia_chi,
            tel: item.Tel,
            name: item.UserName,
          })
        );
      }
    });
  };

  handleSubmit = async () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      await this.props.LoginPOS(this.state);
    }
  };

  render() {
    const { listCompany } = this.props;
    return (
      <div className="login">
        <Row>
          <Col className="divLogo" span={24}>
            <img src={logo} className="logoDHS" alt="" />
            <br></br>
          </Col>
          <Col span={24}>
            <Spin spinning={this.state.loading}>
              <div>
                <h1 style={{ color: "white" }}>Đăng nhập</h1>
              </div>
              <Form
                {...layout}
                name="basic"
                onFinish={this.handleSubmit}
                validateMessages={validateMessages}
              >
                {/* <Form.Item
                  {...tailLayout}
                  name="Company"
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={this.handleChangeCompany}
                    allowClear
                    placeholder="Chọn công ty"
                  >
                    {this.listCompany(listCompany)}
                  </Select>
                </Form.Item> */}
                <Form.Item
                  {...tailLayout}
                  name="userName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    name="userName"
                    onChange={this.handleChange}
                    placeholder="Tài khoản"
                  />
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  name="passWord"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    name="passWord"
                    type="password"
                    onChange={this.handleChange}
                    placeholder="Mật khẩu"
                  />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button className="btnLogin" type="primary" htmlType="submit">
                    Đăng nhập
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
  listCompany = (list) => {
    var result = null;
    if (list.length > 0) {
      result = list.map((item, index) => {
        return (
          <Option value={item.ma_cty} key={index}>
            {item.ten_cty}
          </Option>
        );
      });
    }
    return result;
  };
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.LoginReducer.userInfo,
    listCompany: state.LoginReducer.listCompany,
    isLogin: state.LoginReducer.isLogin,
    listCategoryLv1: state.LoginReducer.listCategoryLv1,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    Login: (user) => {
      dispatch(actions.Login(user));
    },
    LoginPOS: (user) => {
      dispatch(actions.LoginPOS(user));
    },
    getListCompany: () => {
      dispatch(actions.getListCompany());
    },
    sessionLogin: (userName, ma_cty) => {
      dispatch(actions.sessionLogin(userName, ma_cty));
    },
    getCategory: (userName) => {
      dispatch(actions.getCategory(userName));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
