import React, { useEffect, useState } from "react";
import { Collapse, Divider, Row, Col, Input, Descriptions } from "antd";
import { useSelector } from "react-redux";
import ToolBar from "../../../../../../common/DHSControl/DHSButton/toolBar";
import DHSDataGrid from "../../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import PropTypes from "prop-types";
import { toUpperPropertyNameByArray } from "../../../../../controller/Format";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";

ListItem.propTypes = {
  columns: PropTypes.array,
  total: PropTypes.object,
};

ListItem.defaultProps = {
  columns: [
    {
      caption: "Mã nhà cung cấp",
      dataField: "IDCUSTOMER",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "IDCUSTOMER",
        displayExpr: "MA_KH",
      },
    },
    {
      caption: "Tên nhà cung cấp",
      dataField: "TEN_KH",
      type: 0,
    },
    {
      caption: "Tài khoản nợ",
      dataField: "TK_CO",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "TK",
        displayExpr: "TK",
      },
    },
    {
      caption: "Tài khoản có",
      dataField: "TK",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "TK",
        displayExpr: "TK",
      },
    },
    {
      caption: "Tiền NT",
      dataField: "PS_NO",
      format: "Money",
      type: 1,
    },
    {
      caption: "Diễn giải",
      dataField: "DIEN_GIAI",
      type: 1,
    },
    {
      caption: "Chi nhánh",
      dataField: "SITE_ID",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "SITE_ID",
        displayExpr: "SITE_NAME",
      },
    },
    {
      caption: "Bộ phận",
      dataField: "MA_BP",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "MA_BP",
        displayExpr: "TEN_BP",
      },
    },
    {
      caption: "Phí",
      dataField: "MA_PHI",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "MA_PHI",
        displayExpr: "TEN_PHI",
      },
    },
    {
      caption: "Hợp đồng",
      dataField: "MA_HD",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "MA_HD",
        displayExpr: "TEN_HD",
      },
    },
  ],
  total: {
    amount: 0,
    tt: 0,
  },
};

const { Panel } = Collapse;
export default function ListItem(props) {
  const {
    isEdit,
    columns,
    ty_gia,
    isClickSave,
    getLine,
    total,
    Master,
    lstItemSelectedFromSearch,
  } = props;
  const ctBankPayment = useSelector((state) => state.CA4Reducer.ctBankPayment);
  const [isColumns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [idRowSelected, setIdRowSelected] = useState("");
  const [isTotal, setTotal] = useState(total);
  const listCustomer = useSelector((state) => state.CA4Reducer.listCustomer);
  const dm_tk = useSelector((state) => state.CA4Reducer.dm_tk);
  const lstSite = useSelector((state) => state.CA4Reducer.lstSite);
  const dm_bp = useSelector((state) => state.CA4Reducer.dm_bp);
  const dm_phi = useSelector((state) => state.CA4Reducer.dm_phi);
  const dm_hopdong = useSelector((state) => state.CA4Reducer.dm_hopdong);
  useEffect(() => {
    if (!_.isEmpty(listCustomer)) {
      _.filter(columns, (x) => {
        switch (x.dataField) {
          case "IDCUSTOMER": {
            x.dataSource.data = toUpperPropertyNameByArray(listCustomer);
            break;
          }
          case "TK_CO": {
            x.dataSource.data = toUpperPropertyNameByArray(dm_tk);
            break;
          }
          case "TK": {
            x.dataSource.data = toUpperPropertyNameByArray(dm_tk);
            break;
          }
          case "SITE_ID": {
            x.dataSource.data = toUpperPropertyNameByArray(lstSite);
            break;
          }
          case "MA_BP": {
            x.dataSource.data = toUpperPropertyNameByArray(dm_bp);
            break;
          }
          case "MA_PHI": {
            x.dataSource.data = toUpperPropertyNameByArray(dm_phi);
            break;
          }
          case "MA_HD": {
            x.dataSource.data = toUpperPropertyNameByArray(dm_hopdong);
            break;
          }
          default: {
            break;
          }
        }
      });
      setColumns(columns);
    }
  }, [listCustomer]);

  useEffect(() => {
    if (!_.isEmpty(ctBankPayment)) {
      toUpperPropertyNameByArray(ctBankPayment);
      setDataSource(ctBankPayment);
      setTotal({
        tt: Master.T_TIEN,
        amount: ctBankPayment.reduce((a, b) => a + (b["PS_NO"] || 0), 0),
      });
    } else if (!_.isEmpty(lstItemSelectedFromSearch)) {
      setDataSource(dataSource.concat(lstItemSelectedFromSearch));
    }
  }, [ctBankPayment]);

  useEffect(() => {
    const { amount } = isTotal;
    setTotal({
      ...isTotal,
      tt: amount,
    });
  }, [isTotal.amount]);

  useEffect(() => {
    dataSource.map((item) => calc(item));
  }, [ty_gia]);

  useEffect(() => {
    if (isClickSave) {
      getLine({ data: dataSource, total: isTotal });
    }
  }, [isClickSave]);

  useEffect(() => {
    if (!_.isEmpty(dataSource)) {
      dataSource.map((item) => calc(item));
    } else {
      setTotal(total);
    }
  }, [dataSource]);

  const handleAddLine = () => {
    let hasItemEmpty = false;
    Promise.all(
      dataSource.map((item) => {
        if (Object.keys(item).length <= 5) {
          hasItemEmpty = true;
        }
      })
    ).then(async () => {
      hasItemEmpty
        ? openNotificationWithIcon(
            "error",
            "Vui lòng chọn sản phẩm vào dòng rỗng !"
          )
        : await setDataSource([
            ...dataSource,
            { STT_REC0: Math.random().toString(36).substr(2, 9).toUpperCase() },
          ]);
    });
  };

  const handleDeleteLine = () => {
    const data = _.filter(dataSource, (x) => {
      if (x.STT_REC0 === idRowSelected) {
        return false;
      }
      return true;
    });
    setIdRowSelected("");
    setDataSource(data);
  };

  const hanleRowChange = async (params) => {
    let itemChange = dataSource.find((x) => x.STT_REC0 === params[0].key);
    const dataFilter = listCustomer.find(
      (x) => x.IDCUSTOMER === params[0].data.IDCUSTOMER
    );
    var data = await Object.assign(itemChange, dataFilter);
    if (!_.isUndefined(dataFilter)) {
      await calc(data);
    } else {
      await calc(itemChange);
    }
  };

  const selectionChanged = (params) => {
    setIdRowSelected(params[0]);
  };

  const calc = (data) => {
    setTimeout(async () => {
      setTotal({
        ...isTotal,
        amount: dataSource.reduce((a, b) => a + (b["PS_CO"] || 0), 0),
      });
    }, 500);
  };

  return (
    <div className="listItem">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chi tiết" key="1">
          <ToolBar
            setStateOpen={handleAddLine}
            setDeleteLine={handleDeleteLine}
            disabled={isEdit}
          />
          <Divider />
          <DHSDataGrid
            column={isColumns}
            data={dataSource}
            dataKey={"STT_REC0"}
            selectionChanged={selectionChanged}
            hanleRowChange={hanleRowChange}
            disabled={isEdit}
          />
          {/* <Row style={{ marginTop: 15 }}>
              <Col
                span={8}
                xs={24}
                xl={8}
                offset={window.screen.width > 1200 ? 16 : 0}
              >
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Tổng tiền :" span={3}>
                    {FormatMoney(isTotal.amount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Thành tiền :" span={3}>
                    {FormatMoney(isTotal.tt)}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row> */}
        </Panel>
      </Collapse>
    </div>
  );
}
