import * as types from "../../../constants/ActionTypes";

var initialState = {
  listSite: [],
  checkInsert: false,
  checkDelete: false,
  checkUpdate: false,
};

var SiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SITE: {
      return {
        ...state,
        listSite: action.payload,
      };
    }

    case types.INSERT_SITE:
      return {
        ...state,
        checkInsert: action.payload,
      };
    case types.DELETE_SITE:
      return {
        ...state,
        checkDelete: action.payload,
      };
    case types.UPDATE_SITE:
      return {
        ...state,
        checkUpdate: action.payload,
      };
    case types.CLEAR_SITE: {
      return {
        ...state,
        checkUpdate: false,
        checkDelete: false,
        checkInsert: false,
        listSite: [],
      };
    }
    default:
      return state;
  }
};

export default SiteReducer;
