import React, { Component } from "react";
import { Collapse, Table, Descriptions } from "antd";
import _ from "lodash";
import {
  FormatColumnDHSTable,
  result,
  FormatMoney,
} from "../../../../../controller/Format";
const { Panel } = Collapse;

const objectColumn = {
  MA_VT: { name: "Mã VT/HH", width: 100 },
  TEN_VT: { name: "Tên vật tư", width: 200 },
  DVT: { name: "Đơn vị tính", width: 100 },
  MA_KHO: { name: "Kho", width: 100 },
  SO_LUONG: { name: "Số lượng", width: 100 },
  GIA_NT2: { name: "Đơn giá", width: "20%", formatMoney: true },
  TT: { name: "Tổng tiền", width: "25%", formatMoney: true },
};
export default class ListItem extends Component {
  state = {
    data: [],
    loading: false,
    customColumns: [],
  };
  componentDidUpdate = (prevProps) => {
    const { dsCT } = this.props;
    if (!_.isEqual(dsCT, prevProps.dsCT)) {
      if (this.state.customColumns.length === 0) {
        FormatColumnDHSTable(objectColumn);
        this.setState({ customColumns: result });
      }
      this.setState({
        data: dsCT,
        loading: false,
      });
    }
  };
  render() {
    const { amount, ma_nt, Master } = this.props;
    const { data, customColumns } = this.state;
    return (
      <div className="so1-detail-listitem">
        <Table
          // bordered
          dataSource={data}
          columns={customColumns}
          scroll={{ y: 200, x: 800 }}
          pagination={false}
          rowKey="MA_VT"
          size="small"
        />
        <div className="detailTotal">
          <Descriptions bordered size="small">
            <Descriptions.Item label="Tổng tiền :" span={3}>
              {FormatMoney(Master.T_TIEN2)}
            </Descriptions.Item>
            <Descriptions.Item label="% CK :" span={1.5}>
              {Master.TL_CK} %
            </Descriptions.Item>
            <Descriptions.Item label="Tiền CK :" span={1.5}>
              {FormatMoney(Master.T_CK)}
            </Descriptions.Item>
            <Descriptions.Item label="% VAT :" span={1.5}>
              {Master.TS_GTGT} %
            </Descriptions.Item>
            <Descriptions.Item label="Thuế :" span={1.5}>
              {FormatMoney(Master.T_THUE)}
            </Descriptions.Item>
            <Descriptions.Item label="Thành tiền :" span={3}>
              {FormatMoney(Master.T_TT)}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    );
  }
}
