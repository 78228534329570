import React, { useEffect, useState } from "react";
import { Collapse, Divider, Row, Col, Input, Descriptions } from "antd";
import { useSelector } from "react-redux";
import ToolBar from "../../../../../../common/DHSControl/DHSButton/toolBar";
import DHSDataGrid from "../../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import EmployeeDropDownBoxComponent from "../../../../../../common/DHSControl/DHSDropDownBox/DHSDropDownBox";
import PropTypes from "prop-types";
import ProductInfo from "../../../../../controller/ProductInfo";
import HandleMultiAdd from "./handleMultiAdd/handleMultiAdd";
import {
  toUpperPropertyNameByArray,
  FormatMoney,
} from "../../../../../controller/Format";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";

ListItem.propTypes = {
  columns: PropTypes.array,
  total: PropTypes.object,
};

ListItem.defaultProps = {
  columns: [
    {
      caption: "Mã VT/HH",
      dataField: "IDITEM",
      type: 2,
      dropDown: EmployeeDropDownBoxComponent,
      dataSource: {
        data: [],
        valueExpr: "IDITEM",
        displayExpr: "MA_VT",
        render: ProductInfo,
      },
    },
    {
      caption: "Tên VT/HH",
      dataField: "TEN_VT",
      type: 0,
      width: 250,
    },
    {
      caption: "ĐVT",
      dataField: "DVT",
      type: 0,
    },
    {
      caption: "Kho",
      dataField: "MA_KHO",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "MA_KHO",
        displayExpr: "TEN_KHO",
      },
    },
    {
      caption: "Color",
      dataField: "COLOR_ID",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "COLOR_ID",
        displayExpr: "COLOR_NAME",
      },
      visible: false,
    },
    {
      caption: "Size",
      dataField: "SIZE_ID",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "SIZE_ID",
        displayExpr: "SIZE_NAME",
      },
      visible: false,
    },
    {
      caption: "Số lượng",
      dataField: "SO_LUONG",
      type: 1,
    },
    {
      caption: "Đơn giá",
      dataField: "GIA_NT",
      format: "Money",
      type: 1,
    },
    {
      caption: "Tiền ngoại tệ",
      dataField: "TIEN_NT",
      format: "Money",
      type: 0,
    },
  ],
  total: {
    amount: 0,
    tt: 0,
  },
};

const { Panel } = Collapse;
export default function ListItem(props) {
  const {
    isEdit,
    columns,
    ty_gia,
    isClickSave,
    getLine,
    total,
    Master,
    lstItemSelectedFromSearch,
  } = props;
  const ctMovementReceipt = useSelector(
    (state) => state.IN1Reducer.ctMovementReceipt
  );
  const [isColumns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [idRowSelected, setIdRowSelected] = useState("");
  const [openDrawerMultiAdd, setOpenDrawerMultiAdd] = useState(false);
  const [isTotal, setTotal] = useState(total);
  const lstInventory = useSelector(
    (state) => state.InventoryReducer.lstInventory
  );
  const listItem = useSelector((state) => state.ItemReducer.listItem);

  useEffect(() => {
    if (!_.isEmpty(listItem)) {
      _.filter(columns, (x) => {
        switch (x.dataField) {
          case "IDITEM": {
            x.dataSource.data = toUpperPropertyNameByArray(listItem);
            break;
          }
          case "MA_KHO": {
            x.dataSource.data = toUpperPropertyNameByArray(lstInventory);
            break;
          }
          default: {
            break;
          }
        }
      });
      setColumns(columns);
    }
  }, [listItem, lstInventory]);

  useEffect(() => {
    if (!_.isEmpty(ctMovementReceipt)) {
      toUpperPropertyNameByArray(ctMovementReceipt);
      setDataSource(ctMovementReceipt);
      setTotal({
        tt: Master.T_TIEN,
        amount: ctMovementReceipt.reduce((a, b) => a + (b["TIEN_NT"] || 0), 0),
      });
    } else if (!_.isEmpty(lstItemSelectedFromSearch)) {
      setDataSource(dataSource.concat(lstItemSelectedFromSearch));
    }
  }, [ctMovementReceipt]);

  useEffect(() => {
    const { amount } = isTotal;
    setTotal({
      ...isTotal,
      tt: amount,
    });
  }, [isTotal.amount]);

  useEffect(() => {
    dataSource.map((item) => calc(item));
  }, [ty_gia]);

  useEffect(() => {
    if (isClickSave) {
      getLine({ data: dataSource, total: isTotal });
    }
  }, [isClickSave]);

  useEffect(() => {
    if (!_.isEmpty(dataSource)) {
      dataSource.map((item) => calc(item));
    } else {
      setTotal(total);
    }
  }, [dataSource]);

  const handleAddFromList = () => {
    setOpenDrawerMultiAdd(true);
  };

  const handleAddLine = () => {
    let hasItemEmpty = false;
    Promise.all(
      dataSource.map((item) => {
        if (Object.keys(item).length <= 5) {
          hasItemEmpty = true;
        }
      })
    ).then(async () => {
      hasItemEmpty
        ? openNotificationWithIcon(
            "error",
            "Vui lòng chọn sản phẩm vào dòng rỗng !"
          )
        : await setDataSource([
            ...dataSource,
            { STT_REC0: Math.random().toString(36).substr(2, 9).toUpperCase() },
          ]);
    });
  };

  const handleDeleteLine = () => {
    const data = _.filter(dataSource, (x) => {
      if (x.STT_REC0 === idRowSelected) {
        return false;
      }
      return true;
    });
    setIdRowSelected("");
    setDataSource(data);
  };

  const hanleRowChange = async (params) => {
    let itemChange = dataSource.find((x) => x.STT_REC0 === params[0].key);
    const dataFilter = listItem.find((x) => x.IDITEM === params[0].data.IDITEM);
    var data = await Object.assign(itemChange, dataFilter);
    if (!_.isUndefined(dataFilter)) {
      data.SO_LUONG = 1;
      await calc(data);
    } else {
      await calc(itemChange);
    }
  };

  const selectionChanged = (params) => {
    setIdRowSelected(params[0]);
  };

  const calc = (data) => {
    setTimeout(async () => {
      data.TIEN_NT = data.SO_LUONG * data.GIA_NT * ty_gia || 0;
      setTotal({
        ...isTotal,
        amount: dataSource.reduce((a, b) => a + (b["TIEN_NT"] || 0), 0),
      });
    }, 500);
  };

  const checkVisible = async (params) => {
    setOpenDrawerMultiAdd(params.isVisible);
    if (!_.isEmpty(params.isSelected)) {
      setDataSource(dataSource.concat(params.isSelected));
    }
  };

  return (
    <div className="listItem">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chi tiết" key="1">
          <ToolBar
            setAddItem={handleAddFromList}
            setStateOpen={handleAddLine}
            setDeleteLine={handleDeleteLine}
            disabled={isEdit}
          />
          <Divider />
          <DHSDataGrid
            column={isColumns}
            data={dataSource}
            dataKey={"STT_REC0"}
            selectionChanged={selectionChanged}
            hanleRowChange={hanleRowChange}
            disabled={isEdit}
          />
          <Row style={{ marginTop: 15 }}>
            <Col
              span={8}
              xs={24}
              xl={8}
              offset={window.screen.width > 1200 ? 16 : 0}
            >
              <Descriptions bordered size="small">
                <Descriptions.Item label="Tổng tiền :" span={3}>
                  {FormatMoney(isTotal.amount)}
                </Descriptions.Item>
                <Descriptions.Item label="Thành tiền :" span={3}>
                  {FormatMoney(isTotal.tt)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      {openDrawerMultiAdd ? (
        <HandleMultiAdd
          isVisible={openDrawerMultiAdd}
          checkVisible={checkVisible}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
