import React from "react";
import { Row, Col } from "antd";
import CountUp from "react-countup";
export default function DHSCardsCountUp(props) {
  const {
    icon,
    color,
    data1,
    data2 = "",
    content1 = "",
    content2 = "",
  } = props;
  return (
    <div className="DHSCardsCountUp">
      <Row>
        <Col span={6} style={{ alignSelf: "center", textAlign: "center" }}>
          {icon}
        </Col>
        <Col span={18}>
          <p style={{ marginBottom: 0 }}>
            {data1} {content1}
          </p>
          <CountUp
            end={data2}
            duration={2}
            delay={1}
            style={{ fontSize: 32, color: color }}
          />
          <p style={{ fontSize: 12, color: "lightgray", marginBottom: 0 }}>
            {content2}
          </p>
        </Col>
      </Row>
    </div>
  );
}
