import React, { Component } from "react";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Category/customer/index";
import _ from "lodash";
import CustomerForm from "../customerForm/customerForm";
class CustomerEdit extends Component {
  componentDidUpdate = (prevProps) => {
    if (this.props.checkUpdate !== prevProps.checkUpdate) {
      openNotificationWithIcon("success", "Sửa khách hàng thành công !");
      this.props.clearCache();
      this.props.checkVisible({ isVisible: false, editSuccess: true });
    }
  };

  getItemActions = (params) => {
    var model = {
      modelM: params,
      arraylstCustomerAddress: [],
    };
    model.modelM.SKU = params.ma_kh;
    model.modelM.MA_CTY = this.props.ma_cty;
    model.modelM.IDCUSTOMER = this.props.Master.IDCUSTOMER;
    this.props.updateCustomer(model);
  };

  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <CustomerForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          getItemActions={this.getItemActions}
          title="Sửa thông tin khách hàng"
          Master={Master}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listDm: state.SICustomerReducer.listDm,
    checkUpdate: state.SICustomerReducer.checkUpdate,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    updateCustomer: (model) => {
      dispatch(actions.updateCustomer(model));
    },
    getLists: (ma_cty) => {
      dispatch(actions.getLists(ma_cty));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
