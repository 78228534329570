import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getPLGanttDeadlinebyMachine = (query) => (dispatch, getState) => {
  callApi("api/PDMasterPlanning/GetPLGanttDeadlinebyMachine", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_PLANNING_DEADLINE_BY_MACHINE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getPLGanttDeadlinebyWorkOrder = (query) => (
  dispatch,
  getState
) => {
  callApi("api/PDMasterPlanning/GetPLGanttDeadlinebyWorkOrder", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_PLANNING_DEADLINE_BY_WORKORDER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
