import React, { Component } from "react";
import { Drawer } from "antd";
import { Divider, Spin, Tabs } from "antd";
import General from "./General/general";
import ListItem from "./ListItem/listItem";
import Delivery from "./Delivery/delivery";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import "./index.css";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Sales/salesOrder/salesOrder";
import * as actionsApproval from "../../../../../redux/actions/Sales/approvalSalesOrder/approvalSalesOrder";
import { connect } from "react-redux";
import _ from "lodash";
import { widthDrawer } from "../../index";
const { TabPane } = Tabs;
class SO1Detail extends Component {
  state = {
    loading: false,
  };
  componentDidMount = () => {
    const { Master } = this.props;
    this.props.getCustomer({
      ma_cty: this.props.ma_cty,
      idCustomer: Master.IDCUSTOMER,
    });
    this.props.getListNeed({
      stt_rec: Master.STT_REC,
      ma_cty: this.props.ma_cty,
    });
  };
  onClose = () => {
    this.props.checkVisible(false);
  };
  componentWillUnmount = () => {
    this.props.clearCache();
  };

  handleApproval = (params) => {
    const query = {
      ma_cty: this.props.ma_cty,
      luser: this.props.userName,
      note3: "",
      arrayStt_rec: [params.STT_REC],
    };
    this.props.approval(query);
    this.setState({ loading: true });
  };

  handleReject = (params) => {
    const query = {
      ma_cty: this.props.ma_cty,
      luser: this.props.userName,
      arrayStt_rec: [params.STT_REC],
    };
    this.props.reject(query);
    this.setState({ loading: true });
  };

  componentDidUpdate = (prevProps) => {
    const { rejectSalesOrders, approvalSalesOrders } = this.props;
    if (
      !_.isEqual(prevProps.rejectSalesOrders, rejectSalesOrders) ||
      !_.isEqual(prevProps.approvalSalesOrders, approvalSalesOrders)
    ) {
      if (rejectSalesOrders || approvalSalesOrders) {
        approvalSalesOrders
          ? openNotificationWithIcon("success", "Duyệt thành công !")
          : openNotificationWithIcon("success", "Từ chối thành công !");
        this.props.checkVisible(false);
      }
    }
  };
  render() {
    const { loading } = this.state;
    const {
      isVisible,
      Master,
      customer,
      ctSalesOrder,
      contactSalesOrder,
      approval,
    } = this.props;
    return (
      <Drawer
        title={`Chi tiết đơn hàng - ${Master.SO_CT}`}
        visible={isVisible}
        width={widthDrawer}
        onClose={this.onClose}
      >
        <div className="so1-detail">
          <Spin spinning={loading}>
            {approval ? (
              <div>
                <ToolBar
                  setApproval={this.handleApproval}
                  setReject={this.handleReject}
                  Master={Master}
                />
                <Divider style={{ margin: 0 }} />
              </div>
            ) : (
              <></>
            )}
            <General Master={Master} customer={customer} />
            <Divider style={{ margin: 0 }} />
            <Tabs defaultActiveKey="1">
              <TabPane tab="Thông tin hàng hóa" key="1">
                <ListItem
                  dsCT={ctSalesOrder}
                  amount={Master.T_TT}
                  ma_nt={Master.MA_NT}
                  Master={Master}
                />
              </TabPane>
              <TabPane tab="Thông tin giao hàng" key="3">
                <Delivery
                  delivery={contactSalesOrder}
                  dataSource={ctSalesOrder}
                />
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      </Drawer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    newEdit: state.SO1Reducer.newEdit,
    customer: state.SO1Reducer.customer,
    ctSalesOrder: state.SO1Reducer.ctSalesOrder,
    contactSalesOrder: state.SO1Reducer.contactSalesOrder,
    approvalSalesOrders: state.ApprovalSalesOrdersReducer.approvalSalesOrders,
    rejectSalesOrders: state.ApprovalSalesOrdersReducer.rejectSalesOrders,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getCustomer: (query) => {
      dispatch(actions.getCustomer(query));
    },
    getListNeed: (query) => {
      dispatch(actions.getCTSalesOrder(query));
      dispatch(actions.getContactSalesOrder(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
    approval: (query) => {
      dispatch(actionsApproval.approvalSalesOrder(query));
    },
    reject: (query) => {
      dispatch(actionsApproval.rejectSalesOrder(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SO1Detail);
