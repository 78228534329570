import React, { Component } from "react";
import DHSTable from "../../../../../../common/DHSControl/DHSTable/DHSTable";
import CreateCSR from "../../crmCustomerRequest/createCSR/createCSR";
import DetailCSR from "../../crmCustomerRequest/detailCSR/detailCSR";
import EditCSR from "../../crmCustomerRequest/editCSR/editCSR";
import { FormatColumnDHSTable, result } from "../../../../../controller/Format";
import { Collapse, Space, Button, Divider, Spin } from "antd";
import { connect } from "react-redux";
import * as actions from "../../../../../../redux/actions/CustomerServices/crmCustomerRequest/crmCustomerRequest";
import _ from "lodash";
import BtnDelete from "../../../../../../common/DHSControl/DHSButton/btnDelete";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const objectColumn = {
  SO_CT: { name: "Số yêu cầu", width: 100 },
  NGAY_CT: { name: "Ngày yêu cầu", width: 100, formatDate: true },
  SUBJECT: { name: "Tiêu đề", width: 300 },
  REQUEST_BY: { name: "Người yêu cầu", width: 100 },
  MOBILE: { name: "SĐT", width: 100 },
  DEPARTMENT: { name: "Phòng ban", width: 100 },
  COMPLETED_REQUEST_DATE: {
    name: "Ngày hoàn thành",
    width: 100,
    formatDate: true,
  },
};
class Request extends Component {
  state = {
    openDrawerRequest: false,
    openDrawerDetailRequest: false,
    openDrawerEditRequest: false,
    customColumns: [],
    data: [],
    fullData: [],
    objChoose: {},
    loading: false,
  };

  componentDidMount = () => {
    this.loadListRequest();
  };
  componentDidUpdate = (prevProps) => {
    const {
      listRequestById,
      checkDelete,
      clearCache,
      ma_cty,
      Master,
    } = this.props;
    if (!_.isEqual(listRequestById, prevProps.listRequestById)) {
      this.loadListRequest();
    }
    if (!_.isEqual(checkDelete, prevProps.checkDelete) && checkDelete) {
      openNotificationWithIcon("success", "Xóa yêu cầu thành công !");
      this.props.getListRequestByIdcustomer({
        ma_cty,
        idcustomer: Master.IDCUSTOMER,
      });
      clearCache();
    }
  };

  render() {
    const {
      openDrawerRequest,
      data,
      customColumns,
      objChoose,
      loading,
      openDrawerDetailRequest,
      openDrawerEditRequest,
    } = this.state;
    const { Master } = this.props;
    return (
      <div className="listItem" style={{ height: "100%" }}>
        <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
          <Panel header="Yêu cầu" key="1">
            <Spin spinning={loading}>
              <Space style={{ marginBottom: 16, marginTop: 10 }}>
                <Button
                  onClick={() => {
                    this.setState({ openDrawerRequest: true });
                  }}
                  icon={<PlusOutlined />}
                >
                  Thêm
                </Button>
                <Button onClick={this.isEdit} icon={<EditOutlined />}>
                  Sửa
                </Button>
                <BtnDelete
                  objDelele={objChoose}
                  confirmDelete={this.confirmDelete}
                />
              </Space>
              <Divider />
              <DHSTable
                data={data}
                customColumns={customColumns}
                rowKey="STT_REC"
                getDataRow={this.getDataRow}
                doubleClickRow={() => {
                  this.setState({ openDrawerDetailRequest: true });
                }}
              />
            </Spin>
          </Panel>
        </Collapse>
        {openDrawerRequest ? (
          <CreateCSR
            isVisible={openDrawerRequest}
            checkVisible={this.checkVisible}
            IDCUSTOMER={Master.IDCUSTOMER}
          />
        ) : (
          <></>
        )}
        {openDrawerDetailRequest ? (
          <DetailCSR
            isVisible={openDrawerDetailRequest}
            checkVisible={this.checkVisible}
            Master={objChoose}
          />
        ) : (
          <></>
        )}
        {openDrawerEditRequest ? (
          <EditCSR
            isVisible={openDrawerEditRequest}
            checkVisible={this.checkVisible}
            Master={objChoose}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
  isEdit = () => {
    const { objChoose } = this.state;
    if (!_.isEmpty(objChoose)) {
      this.setState({ openDrawerEditRequest: true });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn yêu cầu cần sửa !");
    }
  };
  getDataRow = (record) => {
    this.setState({ objChoose: record });
  };

  loadListRequest = () => {
    const { listRequestById } = this.props;
    FormatColumnDHSTable(objectColumn);
    this.setState({ customColumns: result });
    if (!_.isNil(listRequestById)) {
      this.setState({
        data: listRequestById,
        fullData: listRequestById,
        loading: false,
      });
    } else {
      this.setState({
        data: [],
        fullData: [],
        loading: false,
      });
    }
  };

  checkVisible = (params) => {
    const { ma_cty, Master } = this.props;
    this.setState({
      openDrawerRequest: params.isVisible,
      openDrawerDetailRequest: params.isVisible,
      openDrawerEditRequest: params.isVisible,
    });
    if (params.createSuccess) {
      this.setState({ loading: true, objChoose: {} });
      this.props.getListRequestByIdcustomer({
        ma_cty,
        idcustomer: Master.IDCUSTOMER,
      });
    }
  };

  confirmDelete = (params) => {
    this.setState({ loading: true });
    this.props.deleteCrmCustomerRequest(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listRequestById: state.CrmCustomerRequestReducer.listRequestById,
    checkDelete: state.CrmCustomerRequestReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getListRequestByIdcustomer: (model) => {
      dispatch(actions.getListRequestByIdcustomer(model));
    },
    deleteCrmCustomerRequest: (model) => {
      dispatch(actions.deleteCrmCustomerRequest(model));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Request);
