import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListSalesInvoice = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch03/ListSalesInvoice", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHSalesInvoice = (detail) => (dispatch, getState) => {
  callApi("api/soVch03/GetPHSalesInvoice", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTSalesInvoice = (detail) => (dispatch, getState) => {
  callApi("api/soVch03/GetCTSalesInvoice", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch03/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getCustomer = (customer) => (dispatch, getState) => {
  callApi("api/soVch03/GetCustomer", "POST", customer)
    .then((res) =>
      dispatch({
        type: types.GET_CUSTOMER_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch03/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListStatus = () => (dispatch, getState) => {
  callApi("api/soVch03/ListStatus", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_STATUS_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/soVch03/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewSalesInvoice = (quotation) => (dispatch, getState) => {
  callApi("api/soVch03/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteASalesInvoice = (master) => (dispatch, getState) => {
  callApi("api/soVch03/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateSalesInvoice = (so1) => (dispatch, getState) => {
  callApi("api/soVch03/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_SO3,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_SO3,
  };
};

export const filt = (query) => (dispatch, getState) => {
  callApi("api/soVch03/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_SO3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
