import React, { useState, useEffect } from "react";
import { Drawer, Button, Spin } from "antd";
import { useSelector } from "react-redux";
import Format from "../../../../../../controller/Format";
import DHSListItem from "../../../../../../../common/DHSControl/DHSListItem/DHSListItem";
export default function HandleMultiAdd(props) {
  const { isVisible, checkVisible } = props;
  const Lists = useSelector((state) => state.DS1Reducer.lists);
  const [isSelected, setSelected] = useState([]);
  const handleCancel = () => {
    checkVisible({ isVisible: false, isSelected: [] });
  };
  const confirmSelected = () => {
    checkVisible({
      isVisible: false,
      isSelected,
    });
  };
  const getItemSelected = (params) => {
    setSelected(params);
  };
  return (
    <Drawer
      title="Chọn vật tư"
      width={400}
      maskClosable={false}
      onClose={handleCancel}
      visible={isVisible}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={confirmSelected}
          >
            Xác nhận
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <DHSListItem
        listItem={Lists.dm_vattu}
        keyItem={"IDITEM"}
        title={"TEN_VT"}
        description={"MA_VT"}
        inputName1={"GIA_NT2"}
        inputDisplay={false}
        getItemSelected={getItemSelected}
      />
    </Drawer>
  );
}
