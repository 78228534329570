import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSInputTextArea,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { ReloadOutlined } from "@ant-design/icons";
import * as actions from "../../../../../redux/actions/Sales/salesInvoice/salesInvoice";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import PropTypes from "prop-types";
import moment from "moment";
import {
  toUpperPropertyName,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
import { widthDrawer } from "../../index";
const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};

So4Create.propTypes = {
  _objectCreate: PropTypes.object,
};

So4Create.defaultProps = {
  _objectCreate: {
    IDCUSTOMER: "",
    MA_CTY: "",
    LUSER: "",
    CUSER: "",
    DIA_CHI: "",
    EMAIL: "",
    MA_SO_THUE: "",
    TEL: "",
    MA_KH: "",
    NGUOI_GD: "",
    SO_CT: "",
    SO_SERI: "",
    NGAY_LCT: moment(),
    NGAY_CT: moment(),
    NGAY_TO_KHAI: moment(),
    SO_TO_KHAI: "",
    SO_PX: "",
    MA_NT: "VND",
    TY_GIA: 1,
    MA_GD: "",
    MA_HTTT: "",
    IDEMPLOYEE: "",
    TK_PT: "",
    TK_THUE: "",
    TK_CK_DS: "",
    DIEN_GIAI: "",
  },
};

export default function So4Create(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, _objectCreate, history } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.ma_cty);
  const SO3State = useSelector((state) => state.SO3Reducer);
  const [isLoading, setLoading] = useState(false);
  const [objCreate, setObjCreate] = useState({
    ..._objectCreate,
    SO_SERI: _.isUndefined(SO3State.EInvoicePattern.SERIAL)
      ? ""
      : SO3State.EInvoicePattern.SERIAL,
  });

  useEffect(() => {
    dispatch(actions.getSoCt({ ma_cty }));
    dispatch(actions.getListStatus());
  }, []);

  useEffect(() => {
    setObjCreate({
      ...objCreate,
      TY_GIA: SO3State.exchangeRate == 0 ? 1 : SO3State.exchangeRate,
    });
  }, [SO3State.exchangeRate]);

  useEffect(() => {
    setLoading(false);
    if (SO3State.newItem === "-1") {
      openNotificationWithIcon("error", "Trùng số chứng từ !");
      dispatch(actions.clearSTT());
    } else if (_.isObject(SO3State.newItem)) {
      openNotificationWithIcon("success", "Thêm hóa đơn thành công !");
      checkVisible(false);
      toUpperPropertyName(SO3State.newItem);
      history.push("/SO3Edit", {
        Master: { ...SO3State.newItem, TEN_KH: objCreate.TEN_KH },
      });
    }
  }, [SO3State.newItem]);

  const handleCancel = () => {
    dispatch(actions.clearCache());
    checkVisible(false);
  };

  const handleOk = () => {
    const SalesInvoice = {
      modelM: {
        ...objCreate,
        SO_CT: SO3State.soCt,
        MA_CTY: ma_cty,
        LUSER: userName,
        CUSER: userName,
      },
      arrayCt: [],
    };
    setLoading(true);
    dispatch(actions.addNewSalesInvoice(SalesInvoice));
  };

  const handleChangeText = (params) => {
    setObjCreate({ ...objCreate, [params.name]: params.value });
  };

  const handleKHChange = (params) => {
    let customer = SO3State.listCustomer.find((x) => x.IDCUSTOMER === params);
    setObjCreate({
      ...objCreate,
      DIA_CHI: customer.DIA_CHI,
      IDCUSTOMER: params,
      EMAIL: customer.EMAIL,
      TEL: customer.TEL,
      NGUOI_GD: customer.NGUOI_GD,
      MA_KH: customer.MA_KH,
      TEN_KH: customer.TEN_KH,
      MA_SO_THUE: customer.MA_SO_THUE,
    });
  };

  const handleChangeCurrency = (params) => {
    setObjCreate({ ...objCreate, MA_NT: params });
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: objCreate.NGAY_LCT,
      })
    );
  };
  const reloadSoCt = () => {
    dispatch(actions.getSoCt({ ma_cty }));
  };
  return (
    <Drawer
      title="Thêm mới hóa đơn bán hàng"
      width={widthDrawer}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8 }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Collapse
          defaultActiveKey={["1", "2"]}
          ghost
          className="so3-create"
          expandIconPosition="right"
        >
          <Panel header="Thông tin khách hàng" key="1">
            <Form
              id="submit-form"
              onFinish={handleOk}
              layout="vertical"
              validateMessages={validateMessages}
            >
              <DHSSelectOpt
                label="Khách hàng"
                name="IDCUSTOMER"
                array={SO3State.listCustomer}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                rules={true}
                defaultValue={
                  objCreate.IDCUSTOMER === "" ? undefined : objCreate.IDCUSTOMER
                }
                onChange={handleKHChange}
              />
              <DHSInput
                label="Địa chỉ"
                name="DIA_CHI"
                value={objCreate.DIA_CHI}
                getValueChange={handleChangeText}
              />
              <DHSInput
                label="Email"
                name="EMAIL"
                value={objCreate.EMAIL}
                getValueChange={handleChangeText}
              />
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSInput
                    label="Người liên hệ"
                    name="NGUOI_GD"
                    value={objCreate.NGUOI_GD}
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số điện thoại"
                    name="TEL"
                    value={objCreate.TEL}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
            </Form>
          </Panel>
          <Panel header="Thông tin hóa đơn bán" key="2">
            <Form layout="vertical">
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Loại phiếu"
                    name="MA_GD"
                    array={toUpperPropertyNameByArray(SO3State.sysExchange)}
                    valueOpt={"MA_GD"}
                    valueRender={"MO_TA"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "MA_GD" })
                    }
                    valueEmpty={true}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số seri"
                    name="SO_SERI"
                    value={objCreate.SO_SERI}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Điều khoản thanh toán"
                    name="MA_HTTT"
                    array={toUpperPropertyNameByArray(SO3State.paymentTerm)}
                    valueOpt={"MA_HTTT"}
                    valueRender={"TEN_HTTT"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "MA_HTTT" })
                    }
                    valueEmpty={true}
                  />
                </Col>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Nhân viên"
                    name="IDEMPLOYEE"
                    array={toUpperPropertyNameByArray(SO3State.dm_nv)}
                    valueOpt={"IDCUSTOMER"}
                    valueRender={"TEN_KH"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "IDEMPLOYEE" })
                    }
                    valueEmpty={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={8}>
                  <DHSSelectOpt
                    label="TK nợ"
                    name="TK_PT"
                    array={toUpperPropertyNameByArray(SO3State.tk_no)}
                    valueOpt={"TK"}
                    valueRender={"TK"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "TK_PT" })
                    }
                    valueEmpty={true}
                  />
                </Col>
                <Col span={8}>
                  <DHSSelectOpt
                    label="TK thuế"
                    name="TK_THUE"
                    array={toUpperPropertyNameByArray(SO3State.tk_ts_gtgt)}
                    valueOpt={"TK"}
                    valueRender={"TK"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "TK_THUE" })
                    }
                    valueEmpty={true}
                  />
                </Col>
                <Col span={8}>
                  <DHSSelectOpt
                    label="TK chiết khấu"
                    name="TK_CK_DS"
                    array={toUpperPropertyNameByArray(SO3State.tk_ck)}
                    valueOpt={"TK"}
                    valueRender={"TK"}
                    onChange={(value) =>
                      handleChangeText({ value, name: "TK_CK_DS" })
                    }
                    valueEmpty={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày chứng từ"
                    value={objCreate.NGAY_CT}
                    nameState="NGAY_CT"
                    getValueChange={handleChangeText}
                    addonAfter={<ReloadOutlined onClick={reloadSoCt} />}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số phiếu"
                    name="SO_CT"
                    value={SO3State.soCt}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày tờ khai"
                    nameState="NGAY_TO_KHAI"
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số tờ khai"
                    name="SO_TO_KHAI"
                    value={objCreate.SO_TO_KHAI}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày lập"
                    value={objCreate.NGAY_LCT}
                    nameState="NGAY_LCT"
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số phiếu xuất"
                    name="SO_PX"
                    value={objCreate.SO_PX}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Loại tiền tệ"
                    array={SO3State.listCurrency}
                    valueOpt={"MA_NT"}
                    valueRender={"MA_NT"}
                    defaultValue={objCreate.MA_NT}
                    onChange={handleChangeCurrency}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Tỷ giá"
                    name="TY_GIA"
                    disable={objCreate.MA_NT === "VND" ? true : false}
                    value={objCreate.TY_GIA}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <DHSInputTextArea
                label="Ghi Chú"
                name="DIEN_GIAI"
                value={objCreate.DIEN_GIAI}
                getValueChange={handleChangeText}
              />
            </Form>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
}
