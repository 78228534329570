import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Purchases/purchaseReceipt/purchaseReceipt";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import General from "./general/general";
import ListItem from "./listItem/listItem";
import _ from "lodash";
import * as actionItem from "../../../../../redux/actions/Category/Item/index";
import "./index.css";
import moment from "moment";
const { TabPane } = Tabs;
export default function Po2Edit(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isClickSave, setClickSave] = useState(false);
  const [ty_gia, setTy_gia] = useState(
    history.location.state ? history.location.state.Master.TY_GIA : 1
  );
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const newEdit = useSelector((state) => state.PO2Reducer.newEdit);
  const Master = history.location.state ? history.location.state.Master : {};
  useEffect(() => {
    dispatch(actionItem.getListItem({ ma_cty }));
  }, []);

  useEffect(() => {
    return () => {
      window.onload = () => {
        history.push("/PurchaseReceipt");
      };
    };
  });

  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(newEdit)) {
      openNotificationWithIcon("success", "Sửa thành công !");
      setEdit(true);
    }
  }, [newEdit]);

  const handleSave = () => {
    setClickSave(true);
  };

  const handleBack = () => {
    dispatch(actions.clearCache());
    history.push("/PurchaseReceipt");
  };

  const handleEdit = () => {
    setEdit(false);
  };

  const getLine = (params) => {
    const { data, total } = params;
    Master.T_SO_LUONG = data.reduce((a, b) => a + (b["SO_LUONG"] || 0), 0);
    Master.T_TIEN0 = total.tt;
    Master.T_TIEN = total.tt;
    Master.T_TT = total.tt;
    if (deleteItemEmpty(data)) {
      if (!isLoading) {
        setLoading(true);
        dispatch(
          actions.updatePurchaseReceipt({
            modelM: Master,
            arrayCt: data,
          })
        );
      }
    }
  };

  const deleteItemEmpty = (array) => {
    var check = true;
    setClickSave(false);
    if (array.length > 0) {
      array.map((item, index) => {
        if (_.isNil(item.IDITEM)) {
          array.splice(index, 1);
        } else if (item.SO_LUONG <= 0 || item.GIA_LE <= 0) {
          openNotificationWithIcon(
            "error",
            `Vui lòng nhập số lượng hoặc đơn giá vật tư ${item.TEN_VT}`
          );
          check = false;
        }
      });
      return check;
    }
  };
  return (
    <div className="so0-edit">
      <Spin spinning={isLoading}>
        <div>
          <ToolBar
            setSave={!isEdit ? handleSave : undefined}
            setReEdit={isEdit ? handleEdit : undefined}
            setBack={handleBack}
          />
          <div style={{ paddingLeft: 15 }}>
            <h4 style={{ margin: 0, color: "#1890FF" }}>Phiếu nhập mua</h4>
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
                {Master.SO_CT} : {Master.TEN_KH}
              </h2>
            </div>
          </div>
          <General
            Master={Master}
            isEdit={isEdit}
            getTyGia={(ty_gia) => setTy_gia(ty_gia)}
          />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Chi tiết phiếu nhập mua" key="1">
              <ListItem
                Master={Master}
                isEdit={isEdit}
                ty_gia={ty_gia}
                isClickSave={isClickSave}
                getLine={getLine}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  );
}

// import "./index.css";
// import React, { Component } from "react";
// import { Divider, Spin, Space, Button, Popconfirm } from "antd";
// import General from "./general/general";
// import ListItem from "./listItem/listItem";
// import { connect } from "react-redux";
// import * as actions from "../../../../../redux/actions/Purchases/purchaseReceipt/purchaseReceipt";

// import * as actionsLstColor from "../../../../../redux/actions/Category/color/index";
// import * as actionsLstSize from "../../../../../redux/actions/Category/size/index";
// import { openNotificationWithIcon } from "../../../../../common/notification/notification";
// import {
//   CheckOutlined,
//   EditOutlined,
//   DeleteOutlined,
//   RollbackOutlined,
// } from "@ant-design/icons";
// import _ from "lodash";
// class PO2Edit extends Component {
//   state = {
//     loading: false,
//     Master: this.props.history.location.state
//       ? this.props.history.location.state.Master
//       : {},
//     Line: {},
//     ty_gia: this.props.history.location.state
//       ? this.props.history.location.state.Master.TY_GIA
//       : 1,
//     editDone: false,
//     validationConfirm: false,
//   };
//   componentDidMount = () => {
//     if (this.props.history.location.state) {
//       const { Master } = this.props.history.location.state;
//       this.props.getListNeed({ ma_cty: this.props.ma_cty });
//       this.props.getCustomer({
//         ma_cty: this.props.ma_cty,
//         idCustomer: Master.IDCUSTOMER,
//       });
//       this.props.getCTPurchaseReceipt({
//         stt_rec: Master.STT_REC,
//         ma_cty: this.props.ma_cty,
//       });
//     } else {
//       this.props.history.push("/PurchaseReceipt");
//     }
//   };
//   componentDidUpdate = (prevProps) => {
//     const { newEdit, checkDelete } = this.props;
//     if (!_.isEqual(newEdit, prevProps.newEdit)) {
//       if (newEdit !== null) {
//         this.props.getCTPurchaseReceipt({
//           stt_rec: this.state.Master.STT_REC,
//           ma_cty: this.props.ma_cty,
//         });
//         this.props.getPHPurchaseReceipt({
//           stt_rec: this.state.Master.STT_REC,
//           ma_cty: this.props.ma_cty,
//         });
//         this.setState({ loading: false, editDone: true });
//         // this.props.clearSTTEdit();
//         openNotificationWithIcon("success", "Lưu thành công !");
//       }
//     }
//     if (!_.isEqual(this.props.phPurchaseReceipt, prevProps.phPurchaseReceipt)) {
//       this.setState({ loading: false });
//     }
//     if (checkDelete !== prevProps.checkDelete && checkDelete) {
//       this.props.history.push("/PurchaseReceipt");
//       openNotificationWithIcon("success", "Xóa thành công !");
//     }
//   };
//   deleteItemEmpty = (array) => {
//     var check = true;
//     if (array.length > 0) {
//       array.map((item, index) => {
//         if (_.isNil(item.IDITEM)) {
//           array.splice(index, 1);
//         } else if (item.SO_LUONG <= 0 || item.GIA <= 0) {
//           openNotificationWithIcon(
//             "error",
//             `Vui lòng nhập số lượng hoặc đơn giá vật tư ${item.TEN_VT}`
//           );
//           check = false;
//         }
//       });
//       return check;
//     }
//   };
//   confirm = async () => {
//     if (this.deleteItemEmpty(this.state.Line)) {
//       if (!this.state.loading) {
//         this.setState({ loading: true });
//         this.props.updatePurchaseReceipt({
//           modelM: this.state.Master,
//           arrayCt: this.state.Line,
//         });
//       }
//     }
//   };
//   confirmDelete(Master) {
//     this.setState({ loading: true });
//     this.props.deleteAPurchaseReceipt({ modelM: Master });
//   }

//   componentWillUnmount = () => {
//     this.setState({ loading: false });
//     this.props.clearCache();
//   };

//   render() {
//     const { loading, ty_gia, editDone } = this.state;
//     const { customer, ctPurchaseReceipt, phPurchaseReceipt } = this.props;
//     const { Master } = this.props.history.location.state
//       ? this.props.history.location.state
//       : "";
//     return (
//       <div className="so1-edit">
//         {Master ? (
//           <div>
//             <Spin spinning={loading}>
//               <Space className="topMenu">
//                 <Button
//                   onClick={this.confirm}
//                   style={{ display: editDone ? "none" : "block" }}
//                   icon={<CheckOutlined />}
//                 >
//                   Lưu
//                 </Button>
//                 <Button
//                   style={{ display: editDone ? "block" : "none" }}
//                   onClick={() => {
//                     this.setState({ editDone: false });
//                   }}
//                   icon={<EditOutlined />}
//                 >
//                   Sửa
//                 </Button>
//                 <Popconfirm
//                   title="Bạn muốn xóa đơn hàng ?"
//                   onConfirm={() => this.confirmDelete(Master)}
//                   okText="Đồng ý"
//                   cancelText="Hủy"
//                   disabled={Object.keys(Master).length == 0 ? true : false}
//                 >
//                   <Button icon={<DeleteOutlined />}>Xóa</Button>
//                 </Popconfirm>
//                 <Button
//                   onClick={() => {
//                     this.props.history.push("/PurchaseReceipt");
//                   }}
//                   icon={<RollbackOutlined />}
//                 >
//                   Trở về
//                 </Button>
//               </Space>
//               <div style={{ paddingLeft: 15 }}>
//                 <h4 style={{ margin: 0, color: "#1890FF" }}>
//                   PHIẾU NHẬP MUA HÀNG
//                 </h4>
//                 <div style={{ display: "flex", flexWrap: "nowrap" }}>
//                   <h2 style={{ width: "98%", fontSize: 12, color: "#1890FF" }}>
//                     {Master.SO_CT} : {customer.ten_kh}
//                   </h2>
//                 </div>
//               </div>
//               <General
//                 Master={Master}
//                 customer={customer}
//                 getMaster={this.getMaster}
//                 editDone={editDone}
//               />
//               <ListItem
//                 Master={phPurchaseReceipt}
//                 getLine={this.getLine}
//                 dsCT={ctPurchaseReceipt}
//                 ty_gia={ty_gia}
//                 editDone={editDone}
//               />
//             </Spin>
//           </div>
//         ) : (
//           <></>
//         )}
//       </div>
//     );
//   }
//   getMaster = (params) => {
//     const { Master } = this.state;
//     Master.IDCUSTOMER = params.IDcustomer;
//     Master.MA_KH = params.ma_kh;
//     Master.NGUOI_GD = params.contact;
//     Master.TY_GIA = params.ty_gia;
//     Master.MA_NT = params.ma_nt;
//     Master.DIEN_GIAI = params.ghi_chu;
//     this.setState({ ty_gia: params.ty_gia });
//   };
//   getLine = (params) => {
//     const { Master } = this.state;
//     Master.T_SO_LUONG = 0;
//     Master.T_TIEN2 = 0;
//     Master.T_TIEN_NT2 = 0;
//     Master.T_TT = 0;
//     Master.T_TT_NT = 0;
//     if (params.dataSource.length > 0) {
//       params.dataSource.filter(async (x) => {
//         if (Object.keys(x).length > 1) {
//           Master.T_SO_LUONG += parseInt(x.SO_LUONG);
//           x.TT_NT = x.TT / this.state.ty_gia;
//           Master.T_TIEN2 += x.TIEN_NT2;
//           Master.T_TIEN_NT2 += x.TIEN_NT2 / this.state.ty_gia;
//         }
//       });
//       Master.T_TT = params.tt;
//       Master.T_TT_NT = params.tt / this.state.ty_gia;
//     }
//     this.setState({ Line: params.dataSource });
//   };
// }
// const mapStateToProps = (state) => {
//   return {
//     ma_cty: state.LoginReducer.ma_cty,
//     newEdit: state.PO2Reducer.newEdit,
//     customer: state.PO2Reducer.customer,
//     listItem: state.ItemReducer.listItem,
//     ctPurchaseReceipt: state.PO2Reducer.ctPurchaseReceipt,
//     phPurchaseReceipt: state.PO2Reducer.phPurchaseReceipt,
//     checkDelete: state.PO2Reducer.checkDelete,
//     lstCtChooseFromQU: state.PO2Reducer.lstCtChooseFromQU,
//   };
// };
// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     updatePurchaseReceipt: (query) => {
//       dispatch(actions.updatePurchaseReceipt(query));
//     },
//     getCustomer: (query) => {
//       dispatch(actions.getCustomer(query));
//     },
//     getCTPurchaseReceipt: (detail) => {
//       dispatch(actions.getCTPurchaseReceipt(detail));
//     },
//     getListNeed: (ma_cty) => {
//       dispatch(actions.getListStatus());
//       // dispatch(actionsLstColor.getListItemColor(ma_cty));
//       // dispatch(actionsLstSize.getListItemSize(ma_cty));
//     },
//     deleteAPurchaseReceipt: (master) => {
//       dispatch(actions.deleteAPurchaseReceipt(master));
//     },
//     clearCache: () => {
//       dispatch(actions.clearCache());
//     },
//     // clearSTTEdit: () => {
//     //   dispatch(actions.clearSTTEdit());
//     // },
//     getPHPurchaseReceipt: (detail) => {
//       dispatch(actions.getPHPurchaseReceipt(detail));
//     },
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(PO2Edit);
