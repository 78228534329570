import * as types from "../../../constants/ActionTypes";

var initialState = {
  listQuotation: [],
  ctQuotation: [],
  phQuotation: {},
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  listDm: {},
  checkAddNewCustomer: false,
  listNewAddress: [],
  dsPhSO: [],
  dsCtSO: [],
  dsAddressSO: [],
  lstCtChooseFromSO: [],
  lstAddressChooseFromSO: [],
  checkDispatch: false,
  contactQuotation: [],
  dsCtDriver: [],
  lstSPType: [],
};

var QuotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SO0: {
      return {
        ...state,
        listQuotation: action.payload,
      };
    }
    case types.LOOKUP_SO_SO1: {
      return {
        ...state,
        dsPhSO: action.payload.dsPh,
        dsCtSO: action.payload.dsCt,
        dsAddressSO: action.payload.dsAddress,
        checkDispatch: !state.checkDispatch,
      };
    }
    case types.FILT_SO0: {
      return {
        ...state,
        listQuotation: action.payload,
      };
    }
    case types.GET_PH_SO0: {
      return {
        ...state,
        phQuotation: action.payload,
      };
    }
    case types.GET_CT_SO0: {
      return {
        ...state,
        ctQuotation: action.payload,
      };
    }
    case types.GET_CUSTOMER_SO0: {
      return {
        ...state,
        customer: action.payload[0],
      };
    }
    case types.GET_LIST_CUSTOMER_SO0: {
      return {
        ...state,
        listCustomer: action.payload,
      };
    }
    case types.GET_SO_CT_SO0: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_SO0: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_SO0: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LIST_DM_SO0: {
      return {
        ...state,
        listStatus: action.payload.dm_trangthai_SO0,
        lstSPType: action.payload.dm_sp_type,
      };
    }
    case types.GET_LIST_CURRENCY_SO0: {
      return {
        ...state,
        listCurrency: action.payload,
      };
    }
    case types.GET_EXCHANGE_RATE_SO0: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_SO0: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_SO0: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctQuotation: [],
        phQuotation: {},
        newItem: null,
        lstCtChooseFromSO: [],
        lstAddressChooseFromSO: [],
        dsCtDriver: [],
        contactQuotation: [],
        exchangeRate: "",
      };
    }
    case types.CLEAR_HANDLE_STT_SO0: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }
    default:
      return state;
  }
};

export default QuotationReducer;
