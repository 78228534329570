import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Collapse, InputNumber, Button } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSCheckBox,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../redux/actions/Category/Item/index";
import { openNotificationWithIcon } from "../../../index";
import * as actionSellPrice from "../../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
const { Panel } = Collapse;

AddProducts.propTypes = {
  _objectCreate: PropTypes.object,
};

AddProducts.defaultProps = {
  _objectCreate: {
    MA_VT: "",
    TEN_VT: "",
    SELL_PRICE: 0,
    PURCHASE_PRICE: 0,
    SKU: "",
    DVT_ID: "",
    IDGROUP: "",
    LOAI: "",
    BARCODE: "",
    SL_MIN: 0,
    SL_MAX: 0,
    CUSER: "",
    LUSER: "",
    MA_CTY: "",
    IMAGE: "",
    IMAGEURL: "",
    GIA_SI: 0,
    GIA_LE: 0,
    ISBARCODE: false,
  },
  lstItemSelectedFromSearch: [],
};

export default function AddProducts(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, _objectCreate, getCustomer } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [objCreate, setObjCreate] = useState(_objectCreate);
  const dm_nhomvt = useSelector((state) => state.ItemReducer.dm_nhomvt);
  const tblItemType = useSelector((state) => state.ItemReducer.tblItemType);
  const lstUnit2 = useSelector((state) => state.ItemReducer.lstUnit2);
  const newProd = useSelector((state) => state.ItemReducer.checkInsert);
  useEffect(() => {
    dispatch(actions.getListDM({ ma_cty }));
  }, []);

  useEffect(() => {
    if (newProd) {
      openNotificationWithIcon("success", "Thêm sản phẩm thành công");
      dispatch(actions.clearCache());
      dispatch(
        actionSellPrice.getLookupSelectedItem({
          ma_cty,
          idcustomer: "",
          ma_nt: "VND",
          ngay_ct: moment().add(7, "hours"),
        })
      );
      checkVisible(false);
    }
  }, [newProd]);

  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = () => {
    if (!_.isEmpty(objCreate.IDCUSTOMER)) {
      openNotificationWithIcon(
        "success",
        "Vui lòng nhập đầy đủ thông tin sản phẩm !"
      );
    } else {
      const item = _.clone(objCreate);
      item.ten_vt_in = item.TEN_VT;
      _.filter(lstUnit2, (itemFilter) => {
        if (itemFilter.dvt_id === item.DVT_ID) {
          item.DVT = itemFilter.dvt_name;
        }
      });
      dispatch(
        actions.insertItem({
          ...item,
          ma_cty,
          CUSER: userName,
          LUSER: userName,
        })
      );
    }
  };
  const handleChangeText = (params) => {
    setObjCreate({ ...objCreate, [params.name]: params.value });
  };

  return (
    <div>
      <Modal
        id="submit-form"
        width={1000}
        title="Thêm sản phẩm"
        visible={isVisible}
        onCancel={handleCancel}
        style={{ top: 20 }}
        footer={
          <Row gutter={[16, 16]}>
            <Col span={3} offset={19}>
              <Button type="primary" onClick={handleOk}>
                Đồng ý
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={handleCancel}>Hủy</Button>
            </Col>
          </Row>
        }
      >
        <Form layout="vertical" validateMessages={validateMessages}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <DHSInput
                label="Tên vật tư"
                name="TEN_VT"
                getValueChange={handleChangeText}
                value={objCreate.TEN_VT}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Mã vật tư"
                name="MA_VT"
                getValueChange={handleChangeText}
                value={objCreate.MA_VT}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Đơn vị tính"
                name="DVT_ID"
                array={lstUnit2}
                valueOpt={"dvt_id"}
                valueRender={"dvt_name"}
                onChange={(params) =>
                  handleChangeText({ value: params, name: "DVT_ID" })
                }
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSInput
                label="Barcode"
                name="SKU"
                getValueChange={handleChangeText}
                value={objCreate.SKU}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Loại"
                name="LOAI"
                array={tblItemType}
                valueOpt={"id"}
                valueRender={"mo_ta"}
                onChange={(params) =>
                  handleChangeText({ value: params, name: "LOAI" })
                }
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Nhóm vật tư"
                name="IDGROUP"
                array={dm_nhomvt}
                valueOpt={"idgroup"}
                valueRender={"ten_nhvt"}
                onChange={(params) =>
                  handleChangeText({ value: params, name: "IDGROUP" })
                }
                valueEmpty={true}
              />
            </Col>
            <Col span={12}>
              <Form.Item label="Giá bán">
                <InputNumber
                  className="_inputCusPos"
                  min={0}
                  name="SELL_PRICE"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) =>
                    handleChangeText({ value, name: "SELL_PRICE" })
                  }
                  value={objCreate.SELL_PRICE}
                  style={{ width: "100%", textAlign: "right" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Giá mua">
                <InputNumber
                  className="_inputCusPos"
                  min={0}
                  name="PURCHASE_PRICE"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) =>
                    handleChangeText({ value, name: "PURCHASE_PRICE" })
                  }
                  value={objCreate.PURCHASE_PRICE}
                  style={{ width: "100%", textAlign: "right" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Giá sỉ">
                <InputNumber
                  className="_inputCusPos"
                  min={0}
                  name="GIA_SI"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) =>
                    handleChangeText({ value, name: "GIA_SI" })
                  }
                  value={objCreate.GIA_SI}
                  style={{ width: "100%", textAlign: "right" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Giá lẻ">
                <InputNumber
                  className="_inputCusPos"
                  min={0}
                  name="GIA_LE"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) =>
                    handleChangeText({ value, name: "GIA_LE" })
                  }
                  value={objCreate.GIA_LE}
                  style={{ width: "100%", textAlign: "right" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số lượng Min">
                <InputNumber
                  className="_inputCusPos"
                  min={0}
                  name="SL_MIN"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) =>
                    handleChangeText({ value, name: "SL_MIN" })
                  }
                  value={objCreate.SL_MIN}
                  style={{ width: "100%", textAlign: "right" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số lượng Max">
                <InputNumber
                  className="_inputCusPos"
                  min={0}
                  name="SL_MAX"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) =>
                    handleChangeText({ value, name: "SL_MAX" })
                  }
                  value={objCreate.SL_MAX}
                  style={{ width: "100%", textAlign: "right" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <DHSCheckBox
                label="Sử dụng Barcode"
                defaultChecked={true}
                name="isBarcode"
                onChange={(e) =>
                  handleChangeText({
                    value: e.target.checked,
                    name: "ISBARCODE",
                  })
                }
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
