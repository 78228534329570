import React, { Component } from "react";
import { Button, Space, Divider, Collapse, Form, Row, Col } from "antd";
import {
  DHSInputTextArea,
  DHSSelectOpt,
  DHSText,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { connect } from "react-redux";

import _ from "lodash";
const { Panel } = Collapse;

class Delivery extends Component {
  state = {
    DRIVER_ID: "",
    DRIVER_CODE: "",
    PHONE: "",
    EMAIL: "",
    DRIVER_NAME: "",
    PT_ID: "",
    BIEN_SO: "",
    GHI_CHU: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!_.isEqual(this.state, prevState)) {
      this.props.getDriver([...[], this.state]);
    }
  };
  componentDidMount = async () => {
    const { dsCtDriver } = this.props;
    if (!_.isEmpty(dsCtDriver)) {
      this.setState({ GHI_CHU: dsCtDriver[0].GHI_CHU });
      this.handleDriverChange(dsCtDriver[0].DRIVER_ID);
    }
  };

  handleDriverChange = (value) => {
    const { listDriver } = this.props;
    listDriver.map(async (item) => {
      if (item.DRIVER_ID === value) {
        await this.setState({
          DRIVER_ID: value,
          DRIVER_CODE: item.DRIVER_CODE,
          DRIVER_NAME: item.DRIVER_NAME,
          EMAIL: item.DRIVER_EMAIL,
          PHONE: item.DRIVER_PHONE,
        });
        this.handleVehicleChange(item.PT_ID);
      }
    });
  };

  handleVehicleChange = (value) => {
    const { listVehicle } = this.props;
    listVehicle.map((item) => {
      if (item.PT_ID === value) {
        this.setState({ PT_ID: value, BIEN_SO: item.BIEN_SO });
      }
    });
  };

  getValueChange = (params) => {
    this.setState({ [params.name]: params.value });
  };

  render() {
    const { listDriver, listVehicle } = this.props;
    const {
      DRIVER_CODE,
      PHONE,
      EMAIL,
      PT_ID,
      BIEN_SO,
      GHI_CHU,
      DRIVER_ID,
    } = this.state;
    return (
      <div className="listItem">
        <Collapse
          defaultActiveKey={["1", "2"]}
          expandIconPosition={"right"}
          ghost
        >
          <Panel header="Thông tin tài xế" key="1">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12} xs={12} xl={12}>
                <Form layout="vertical">
                  <DHSSelectOpt
                    label="Tài xế"
                    array={listDriver}
                    defaultValue={DRIVER_ID}
                    valueOpt={"DRIVER_ID"}
                    valueRender={"DRIVER_NAME"}
                    onChange={this.handleDriverChange}
                  />
                  <DHSText label="Mã tài xế" value={DRIVER_CODE} />
                  <DHSText label="Số điện thoại" value={PHONE} />
                  <DHSText label="Email" value={EMAIL} />
                </Form>
              </Col>
              <Col className="gutter-row" span={12} xs={12} xl={12}>
                <Form layout="vertical">
                  <Form layout="vertical">
                    <DHSSelectOpt
                      label="Phương tiện"
                      array={listVehicle}
                      valueOpt={"PT_ID"}
                      valueRender={"TEN_PT"}
                      defaultValue={PT_ID}
                      onChange={this.handleVehicleChange}
                    />
                    <DHSText label="Biển số xe" value={BIEN_SO} />
                    <DHSInputTextArea
                      label="Ghi chú"
                      name="GHI_CHU"
                      value={GHI_CHU}
                      getValueChange={this.getValueChange}
                    />
                  </Form>
                </Form>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listDriver: state.DriverReducer.listDriver,
    listVehicle: state.VehicleReducer.listVehicle,
    dsCtDriver: state.SO2Reducer.dsCtDriver,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
