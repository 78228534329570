import React, { Component } from "react";
import { Row, Col } from "antd";
import "./index.css";
import moment from "moment";
import Barcode from "react-barcode";
import { FormatMoney } from "../../../../components/controller/Format";
export default class rp04 extends Component {
  render() {
    let objCompany = JSON.parse(localStorage.getItem("SessionLogin"));
    const { isTotal, listProduct, customer, soCt } = this.props;
    return (
      <div className="rp04" style={{ padding: 20 }}>
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <b>{objCompany.companyName}</b>
            <p>Điện thoại : {objCompany.tel}</p>
            <p>{objCompany.dia_chi}</p>
            <h2 style={{ fontSize: 13 }}>
              <b>HÓA ĐƠN BÁN HÀNG</b>
            </h2>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <p>Khách hàng: {`${customer.TEN_KH}`}</p>
          </Col>
          <Col span={24}>
            <p>Thu ngân: {objCompany.name}</p>
          </Col>
          <Col span={24}>
            <p>Thời gian: {moment().format("HH:mm, DD/MM/YYYY")}</p>
          </Col>
          <Col span={24}>
            <table style={{ width: "100%" }}>
              <tr style={{ borderBottom: "1px dotted black" }}>
                <th style={{ width: "5%" }}>STT</th>
                <th style={{ width: "50%" }}>ĐƠN GIÁ</th>
                <th style={{ width: "15%" }}>SL</th>
                <th style={{ width: "15%" }}>ĐVT</th>
                <th style={{ width: "15%" }}>T.TIỀN</th>
              </tr>
              {listProduct.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td>{item.STT}</td>
                      <td colSpan="4">{item.TEN_VT}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>{FormatMoney(item.GIA_LE)}</td>
                      <td>{item.SO_LUONG}</td>
                      <td>{item.DVT}</td>
                      <td>{FormatMoney(item.TT_NT)}</td>
                    </tr>
                  </>
                );
              })}
              <tr style={{ borderTop: "1px dotted black" }}>
                <td colSpan="4">Thành tiền</td>
                <td>{FormatMoney(isTotal.T_TIEN2)}</td>
              </tr>
              <tr>
                <td colSpan="4">Tổng SL sản phẩm</td>
                <td>{listProduct.length}</td>
              </tr>
              <tr>
                <td colSpan="4">
                  <b>Tổng tiền</b>
                </td>
                <td>
                  <b>{FormatMoney(isTotal.T_TIEN2)}</b>
                </td>
              </tr>
              <tr>
                <td colSpan="4">Khách hàng đưa</td>
                <td>{FormatMoney(isTotal.KHACH_TRA)}</td>
              </tr>
            </table>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <i>Xin cảm ơn quý khách. Hẹn gặp lại!</i>
            <p>Mã Hóa Đơn:{soCt}</p>
            <Barcode value={soCt} height={30} width={1} fontSize={0} />
            <p>&#169; 2021 - DHSOFT.POS</p>
          </Col>
        </Row>
      </div>
    );
  }
}
