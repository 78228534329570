import React, { useEffect, useState, useRef } from "react";
import { Collapse, Divider, Row, Col, Descriptions, Input, Form } from "antd";
import { useSelector } from "react-redux";
import ToolBar from "../../../../../../common/DHSControl/DHSButton/toolBar";
import DHSDataGrid from "../../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import EmployeeDropDownBoxComponent from "../../../../../../common/DHSControl/DHSDropDownBox/DHSDropDownBox";
import PropTypes from "prop-types";
import ProductInfo from "../../../../../controller/ProductInfo";
import HandleMultiAdd from "./handleMultiAdd/handleMultiAdd";
import {
  toUpperPropertyNameByArray,
  FormatMoney,
} from "../../../../../controller/Format";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";
import { SearchOutlined, BarcodeOutlined } from "@ant-design/icons";
ListItem.propTypes = {
  columns: PropTypes.array,
  total: PropTypes.object,
};

ListItem.defaultProps = {
  columns: [
    {
      caption: "Mã VT/HH",
      dataField: "IDITEM",
      type: 2,
      dropDown: EmployeeDropDownBoxComponent,
      dataSource: {
        data: [],
        valueExpr: "IDITEM",
        displayExpr: "MA_VT",
        render: ProductInfo,
      },
    },
    {
      caption: "Tên VT/HH",
      dataField: "TEN_VT",
      type: 0,
      width: 250,
    },
    {
      caption: "ĐVT",
      dataField: "DVT",
      type: 0,
    },
    {
      caption: "Kho",
      dataField: "MA_KHO",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "MA_KHO",
        displayExpr: "TEN_KHO",
      },
    },
    {
      caption: "Color",
      dataField: "COLOR_ID",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "COLOR_ID",
        displayExpr: "COLOR_NAME",
      },
      visible: false,
    },
    {
      caption: "Size",
      dataField: "SIZE_ID",
      type: 2,
      dataSource: {
        data: [],
        valueExpr: "SIZE_ID",
        displayExpr: "SIZE_NAME",
      },
      visible: false,
    },
    {
      caption: "Số lượng",
      dataField: "SO_LUONG",
      type: 1,
    },
    {
      caption: "Đơn giá",
      dataField: "GIA_LE",
      format: "Money",
      type: 1,
    },
    {
      caption: "Tiền ngoại tệ",
      dataField: "TIEN_NT0",
      format: "Money",
      type: 0,
    },
    {
      caption: "% CK",
      dataField: "TL_CK",
      type: 1,
      visible: false,
    },
    {
      caption: "Tiền CK",
      dataField: "TIEN_CK_NT",
      type: 0,
      format: "Money",
      visible: false,
    },
    {
      caption: "% VAT",
      dataField: "TS_GTGT",
      type: 1,
      visible: false,
    },
    {
      caption: "Tiền thuế",
      dataField: "THUE_GTGT_NT",
      type: 1,
      format: "Money",
      visible: false,
    },
    {
      caption: "Tổng tiền",
      dataField: "TT",
      format: "Money",
      type: 0,
    },
  ],
  total: {
    amount: 0,
    tt: 0,
    count: 0,
  },
};

const { Panel } = Collapse;
export default function ListItem(props) {
  const { isEdit, columns, ty_gia, isClickSave, getLine, total, Master } =
    props;
  const ctPurchaseReceipt = useSelector(
    (state) => state.PO2Reducer.ctPurchaseReceipt
  );
  const [isColumns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [idRowSelected, setIdRowSelected] = useState("");
  const [openDrawerMultiAdd, setOpenDrawerMultiAdd] = useState(false);
  const [isTotal, setTotal] = useState(total);
  const lstInventory = useSelector(
    (state) => state.InventoryReducer.lstInventory
  );
  const lookupSelectedItem = useSelector((state) => state.ItemReducer.listItem);

  useEffect(() => {
    if (!_.isEmpty(lookupSelectedItem)) {
      _.filter(columns, (x) => {
        switch (x.dataField) {
          case "IDITEM": {
            x.dataSource.data = toUpperPropertyNameByArray(lookupSelectedItem);
            break;
          }
          case "MA_KHO": {
            x.dataSource.data = toUpperPropertyNameByArray(lstInventory);
            break;
          }
          default: {
            break;
          }
        }
      });
      setColumns(columns);
    }
  }, [lookupSelectedItem, lstInventory]);

  useEffect(() => {
    if (!_.isEmpty(ctPurchaseReceipt)) {
      toUpperPropertyNameByArray(ctPurchaseReceipt);
      setDataSource(ctPurchaseReceipt);
      setTotal({
        tt: Master.T_TT,
        amount: ctPurchaseReceipt.reduce((a, b) => a + (b["TT"] || 0), 0),
      });
    }
  }, [ctPurchaseReceipt]);

  useEffect(() => {
    const { amount } = isTotal;
    setTotal({
      ...isTotal,
      tt: amount,
    });
  }, [isTotal.amount]);

  useEffect(() => {
    dataSource.map((item) => calc(item));
  }, [ty_gia]);

  useEffect(() => {
    if (isClickSave) {
      getLine({ data: dataSource, total: isTotal });
    }
  }, [isClickSave]);

  useEffect(() => {
    if (!_.isEmpty(dataSource)) {
      dataSource.map((item) => calc(item));
    } else {
      setTotal(total);
    }
  }, [dataSource]);

  const handleAddFromList = () => {
    setOpenDrawerMultiAdd(true);
  };

  const handleAddLine = () => {
    let hasItemEmpty = false;
    Promise.all(
      dataSource.map((item) => {
        if (Object.keys(item).length <= 5) {
          hasItemEmpty = true;
        }
      })
    ).then(async () => {
      hasItemEmpty
        ? openNotificationWithIcon(
            "error",
            "Vui lòng chọn sản phẩm vào dòng rỗng !"
          )
        : await setDataSource([
            ...dataSource,
            { STT_REC0: Math.random().toString(36).substr(2, 9).toUpperCase() },
          ]);
    });
  };

  const handleDeleteLine = () => {
    const data = _.filter(dataSource, (x) => {
      if (x.STT_REC0 === idRowSelected) {
        return false;
      }
      return true;
    });
    setIdRowSelected("");
    setDataSource(data);
  };

  const hanleRowChange = async (params) => {
    let itemChange = dataSource.find((x) => x.STT_REC0 === params[0].key);
    const dataFilter = lookupSelectedItem.find(
      (x) => x.IDITEM === params[0].data.IDITEM
    );
    var data = await Object.assign(itemChange, dataFilter);
    if (!_.isUndefined(dataFilter)) {
      data.SO_LUONG = 1;
      data.TL_CK = 0;
      await calc(data);
    } else {
      await calc(itemChange);
    }
  };

  const selectionChanged = (params) => {
    setIdRowSelected(params[0]);
  };

  const calc = (data) => {
    setTimeout(async () => {
      data.GIA_LE = data.GIA_NT > 0 ? data.GIA_NT : data.GIA_LE;
      data.TIEN_NT0 = data.SO_LUONG * parseInt(data.GIA_LE) * ty_gia || 0;
      data.TT = data.TIEN_NT0;
      data.TT_NT = data.TT / ty_gia;
      data.GIA = data.GIA_LE;
      await setTotal({
        ...isTotal,
        amount: dataSource.reduce((a, b) => a + (b["TT"] || 0), 0),
      });
    }, 300);
  };

  const checkVisible = async (params) => {
    setOpenDrawerMultiAdd(params.isVisible);
    if (!_.isEmpty(params.isSelected)) {
      setDataSource([...dataSource, ...params.isSelected]);
    }
  };

  const formRef = useRef();
  const scanBarcode = (e) => {
    console.log(e.target.value);
    let SKU = e.target.value;
    setTimeout(async () => {
      const itemScan = lookupSelectedItem.find((x) => x.SKU === SKU);
      if (!_.isUndefined(itemScan)) {
        itemScan.SO_LUONG = 1;
        checkVisible({
          isVisible: false,
          isSelected: [
            {
              ...itemScan,
              STT_REC0: Math.random().toString(36).substr(2, 9).toUpperCase(),
            },
          ],
        });
        setTimeout(() => {
          formRef.current.resetFields();
        }, 1000);
      }
    }, 1000);
  };

  return (
    <div className="listItem">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chi tiết" key="1">
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <ToolBar
                setAddItem={handleAddFromList}
                setStateOpen={handleAddLine}
                setDeleteLine={handleDeleteLine}
                disabled={isEdit}
              />
            </Col>
            <Col span={6}>
              <Form ref={formRef}>
                <Form.Item name="textBC">
                  <Input
                    prefix={<SearchOutlined />}
                    suffix={<BarcodeOutlined />}
                    onChange={scanBarcode}
                    style={{
                      border: "none",
                      borderBottom: "1px solid lightgray",
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Divider />
          <DHSDataGrid
            column={isColumns}
            data={dataSource}
            dataKey={"STT_REC0"}
            selectionChanged={selectionChanged}
            hanleRowChange={hanleRowChange}
            disabled={isEdit}
          />
          <Row style={{ marginTop: 15 }}>
            <Col span={8} offset={16}>
              <Descriptions bordered size="small">
                <Descriptions.Item label="Tổng tiền :" span={3}>
                  {FormatMoney(isTotal.amount)}
                </Descriptions.Item>
                <Descriptions.Item label="Tổng số lượng :" span={3}>
                  {dataSource.reduce((a, b) => a + (b["SO_LUONG"] || 0), 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Thành tiền :" span={3}>
                  {FormatMoney(isTotal.tt)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      {openDrawerMultiAdd ? (
        <HandleMultiAdd
          isVisible={openDrawerMultiAdd}
          checkVisible={checkVisible}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
