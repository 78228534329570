import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

// export const getListCrmCustomer = (ma_cty) => (dispatch, getState) => {
//   callApi("api/CrmCustomer/LstCrmCustomer", "POST", ma_cty)
//     .then((res) =>
//       dispatch({
//         type: types.GET_LIST_CRMCUSTOMER,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };

// export const getLists = (ma_cty) => (dispatch, getState) => {
//   callApi("api/CrmCustomer/GetLists", "POST", ma_cty)
//     .then((res) =>
//       dispatch({
//         type: types.GET_LISTS_CRMCUSTOMER,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };
// export const getCrmCustomer = (query) => (dispatch, getState) => {
//   callApi("api/CrmCustomer/GetCrmCustomer", "POST", query)
//     .then((res) =>
//       dispatch({
//         type: types.GET_CRMCUSTOMER,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };

export const deleteCrmCustomerRequest = (query) => (dispatch, getState) => {
  callApi("api/CrmCustomerRequest/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_CUSTOMER_REQUEST,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListRequestByIdcustomer = (model) => (dispatch, getState) => {
  callApi("api/CrmCustomerRequest/GetListRequestByIdcustomer", "POST", model)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_REQUEST_BY_IDCUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewCrmCustomerRequest = (model) => (dispatch, getState) => {
  callApi("api/CrmCustomerRequest/InsertCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_CRMCUSTOMER_REQUEST,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getSoCtCSR = (model) => (dispatch, getState) => {
  callApi("api/CrmCustomerRequest/GetSoCt", "POST", model)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_CSR,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateCrmCustomerRequest = (model) => (dispatch, getState) => {
  callApi("api/CrmCustomerRequest/UpdateCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CRMCUSTOMER_REQUEST,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_CUSTOMER_REQUEST,
  };
};
