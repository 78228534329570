import React, { Component } from "react";
import Gantt, {
  Tasks,
  Column,
  Validation,
} from "devextreme-react/gantt";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devexpress-gantt/dist/dx-gantt.css";
import _ from "lodash";
export default class GanttChart extends Component {
  render() {
    const { dataSource, scaleType } = this.props;
    return (
      <div>
        <Gantt
          taskListWidth={500}
          scaleType="weeks"
          height={800}
          taskTitlePosition="outside"
          scaleType={scaleType}
        >
          <Validation autoUpdateParentTasks={true} />
          <Tasks
            dataSource={dataSource}
            keyExpr="TASKID"
            parentIdExpr="PARENTID"
            titleExpr="TASKNAME"
            startExpr="STARTDATE"
            endExpr="FINISHDATE"
            progressExpr="PROGRESS"
          />
          <Column dataField="TASKNAME" caption="Mô tả" width={300} />
          <Column
            dataField="SO_LUONG_KH"
            caption="Số lượng KH"
            width={100}
            type="Numberic"
          />
          <Column
            dataField="SO_LUONG_HT"
            caption="Số lượng HT"
            width={100}
            type="Numberic"
          />
          <Column
            dataField="TG_SETMAY"
            caption="Thời gian set máy (phút)"
            width={100}
          />
          <Column dataField="TG_GA" caption="Thời gian Gá (phút)" width={100} />
          <Column dataField="TG_GC" caption="Thời gian GC (phút)" width={100} />
          <Column
            dataField="TG_RUN"
            caption="Thời gian GC 1pcs (phút)"
            width={100}
          />
          <Column dataField="STARTDATE" caption="Bắt đầu" width={150} />
          <Column dataField="FINISHDATE" caption="Kết thúc" width={150} />
          <Column
            dataField="NGAY_GIAOHANG"
            caption="Ngày giao hàng"
            width={150}
          />
          <Column dataField="SO_NGAYGC" caption="Số ngày GC" width={100} />
          <Column
            dataField="SO_NGAYGC_PS"
            caption="Số ngày phát sinh"
            width={100}
          />
          <Column
            dataField="FINISHDATE_ACTUAL"
            caption="Ngày kết thúc thực tế"
            width={150}
          />
          <Column dataField="PROGRESS" caption="% Hoàn thành" width={100} />
          <Column dataField="PRIORITY_NAME" caption="Ưu tiên" width={100} />
          <Column
            dataField="TG_SETMAY_ACTUAL"
            caption="TG Set máy thực tế"
            width={100}
          />
          <Column
            dataField="TG_GA_ACTUAL"
            caption="TG GA thực tế"
            width={100}
          />
          <Column
            dataField="TG_GC_ACTUAL"
            caption="TG GC thực tế"
            width={100}
          />
          <Column
            dataField="TG_RUN_ACTUAL"
            caption="Thời gian GC 1 pcs thực tế"
            width={100}
          />
        </Gantt>
      </div>
    );
  }
}
