import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetListsDm } from "../../../../../redux/actions/Sales/reports/reports";
import { toUpperPropertyNameByArray } from "../../../../controller/Format";
import Filter from "./Filter/Filter";
import _ from "lodash";
import DHSReport from "../../../../../common/layout/DHSReport/DHSReport";
import PropTypes from "prop-types";
import "./index.css";
POSRevenueByItem.propTypes = {
  columns: PropTypes.object,
};
POSRevenueByItem.defaultProps = {
  columns: [
    {
      caption: "Mã sản phẩm",
      dataField: "MA_VT",
      type: 0,
    },
    {
      caption: "Tên sản phẩm",
      dataField: "TEN_VT",
      type: 0,
    },
    {
      caption: "Đơn vị tính",
      dataField: "DVT",
      type: 0,
    },
    {
      caption: "Số lượng",
      dataField: "SO_LUONG",
      format: "Money",
      type: 0,
    },
    {
      caption: "Thành tiền",
      dataField: "TIEN2",
      format: "Money",
      type: 0,
    },
    {
      caption: "Chiết khấu",
      dataField: "TIEN_CK",
      format: "Money",
      type: 0,
    },
    {
      caption: "Tổng tiền",
      dataField: "TT",
      format: "Money",
      type: 0,
    },
  ],
};
export default function POSRevenueByItem(props) {
  const { columns } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const POSRptRevenuebyItem = useSelector((state) =>
    toUpperPropertyNameByArray(state.SOReportsReducer.POSRptRevenuebyItem)
  );
  useEffect(() => {
    dispatch(GetListsDm({ ma_cty }));
  }, []);

  return (
    <div className="POSRevenueByItem">
      <DHSReport
        columns={columns}
        data={POSRptRevenuebyItem}
        dataKey="IDITEM"
        title="Báo cáo doanh thu theo sản phẩm"
        onBack="Sale"
        FilterComponent={Filter}
      />
    </div>
  );
}
