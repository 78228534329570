import React, { Component } from "react";
import { Collapse, Row, Col, Form, Spin } from "antd";
import { connect } from "react-redux";
import {
  DHSInput,
  DHSSelectOpt,
  DHSText,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import _ from "lodash";
const { Panel } = Collapse;

export default class General extends Component {
  state = {
    loading: false,
    CUSER: this.props.userName,
    LUSER: this.props.userName,
    noneEdit: false,
  };

  render() {
    const {
      TK,
      MA_NHKH,
      NCC1,
      NCC2,
      NCC3,
      MA_NT,
      MA_KH,
      BARCODE,
      TEN_KH,
      DIA_CHI,
      MA_SO_THUE,
      GHI_CHU,
    } = this.props.Master;
    const { listDm, editDone } = this.props;
    return (
      <div className="csr-general">
        <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
          <Panel header="Thông tin chung" key="1">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSInput
                    label="Mã khách hàng"
                    name="MA_KH"
                    value={MA_KH}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Tên khách hàng"
                    name="TEN_KH"
                    value={TEN_KH}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Barcode"
                    name="BARCODE"
                    value={BARCODE}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSInput
                    label="Địa chỉ"
                    name="DIA_CHI"
                    value={DIA_CHI}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Mã số thuế"
                    name="MA_SO_THUE"
                    value={MA_SO_THUE}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                  <DHSSelectOpt
                    label="Nhóm khách hàng"
                    array={listDm.dm_nhkh}
                    valueOpt="ma_nhkh"
                    valueRender="ten_nhkh"
                    valueEmpty={true}
                    defaultValue={MA_NHKH}
                    onChange={(value) => this.handleSelectOpt(value, "MA_NHKH")}
                    disable={editDone}
                  />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSSelectOpt
                    label="Mã ngoại tệ"
                    array={listDm.dm_nt}
                    valueOpt="ma_nt"
                    valueRender="ten_nt"
                    valueEmpty={true}
                    defaultValue={MA_NT}
                    onChange={(value) => this.handleSelectOpt(value, "MA_NT")}
                    disable={editDone}
                  />
                  <DHSSelectOpt
                    label="Tài khoản"
                    array={listDm.siaccount}
                    valueOpt="tk"
                    valueRender="ten_tk"
                    valueEmpty={true}
                    defaultValue={TK}
                    onChange={(value) => this.handleSelectOpt(value, "TK")}
                    disable={editDone}
                  />
                  <DHSInput
                    label="Ghi chú"
                    name="GHI_CHU"
                    value={GHI_CHU}
                    getValueChange={this.handleChange}
                    disable={editDone}
                  />
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <DHSSelectOpt
                    label="Loại NCC1"
                    array={listDm.dm_plncc1}
                    valueOpt="ma_plkh"
                    valueRender="ten_plkh"
                    valueEmpty={true}
                    defaultValue={NCC1}
                    onChange={(value) => this.handleSelectOpt(value, "NCC1")}
                    disable={editDone}
                  />
                  <DHSSelectOpt
                    label="Loại NCC2"
                    array={listDm.dm_plncc2}
                    valueOpt="ma_plkh"
                    valueRender="ten_plkh"
                    valueEmpty={true}
                    defaultValue={NCC2}
                    onChange={(value) => this.handleSelectOpt(value, "NCC2")}
                    disable={editDone}
                  />
                  <DHSSelectOpt
                    label="Loại NCC3"
                    array={listDm.dm_plncc3}
                    valueOpt="ma_plkh"
                    valueRender="ten_plkh"
                    valueEmpty={true}
                    defaultValue={NCC3}
                    onChange={(value) => this.handleSelectOpt(value, "NCC3")}
                    disable={editDone}
                  />
                </Form>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }

  handleChange = (e) => {
    this.props.Master[e.name] = e.value;
    this.setState({});
  };
  handleSelectOpt = (value, name) => {
    this.props.Master[name] = value;
    this.setState({});
  };
}
