import React, { Component } from "react";
import _ from "lodash";
import { InputNumber, Checkbox, Row, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
export default class ProductInfor extends Component {
  state = { selected: false, SO_LUONG: 0, SL_DLV_PLAN: 0 };

  componentDidMount = () => {
    this.setState({
      SO_LUONG: this.props.item.SO_LUONG_DELIVERY,
      SL_DLV_PLAN: this.props.item.SL_DLV_PLAN,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { isTransfer, item } = this.props;
    if (isTransfer !== prevProps.isTransfer && isTransfer) {
      this.setState({
        SO_LUONG: item.SO_LUONG_DELIVERY,
        SL_DLV_PLAN: 0,
        selected: false,
      });
    }
  };

  selectItem = async (item) => {
    await this.setState({ selected: !this.state.selected });
    this.props.onGetItemSelected({ item: item, checked: this.state.selected });
  };

  onChangeSL_DLV_PLAN = (value, item) => {
    const { SO_LUONG } = this.state;
    item.SL_DLV_PLAN = value;
    item.SO_LUONG_DELIVERY = SO_LUONG - value;
    this.setState({ SL_DLV_PLAN: value });
  };

  onDeleteItem = (item) => {
    this.props.onGetItemDelete(item);
  };

  render() {
    const { item, disabled, direction } = this.props;
    const { selected, SL_DLV_PLAN, SO_LUONG } = this.state;
    return (
      <div className="so1ProductInfor">
        <Row style={{ padding: 5, borderBottom: "1px solid lightgray" }}>
          <Col span={1}>
            <Checkbox
              onChange={() => this.selectItem(item)}
              checked={selected}
              disabled={disabled ? true : SO_LUONG === 0 ? true : false}
              style={{ display: direction === "left" ? "block" : "none" }}
            />
          </Col>
          <Col span={3}>{item.MA_VT}</Col>
          <Col span={direction === "left" ? 9 : 12}>{item.TEN_VT}</Col>
          <Col span={direction === "left" ? 2 : 0}>{item.SO_LUONG}</Col>
          <Col span={direction === "left" ? 5 : 0}>
            <InputNumber
              value={SL_DLV_PLAN}
              min={0}
              max={SO_LUONG}
              disabled={!selected}
              onChange={(value) => this.onChangeSL_DLV_PLAN(value, item)}
              style={{ display: direction === "left" ? "block" : "none" }}
            />
          </Col>
          <Col span={2}>
            {direction === "left" ? item.SO_LUONG_DELIVERY : item.SO_LUONG}
          </Col>
          <Col span={2} style={{ textAlign: "center" }}>
            {item.DVT}
          </Col>
          <Col span={2} style={{ alignSelf: "center" }}>
            <DeleteOutlined
              style={{
                display: direction === "right" ? "block" : "none",
                margin: 0,
                fontSize: 16,
              }}
              onClick={() => this.onDeleteItem(item)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
