import * as types from "../../../constants/ActionTypes";

var initialState = {
  listItemColor: [],
};

var LstColorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_ITEM_COLOR: {
      return {
        ...state,
        listItemColor: action.payload,
      };
    }
    default:
      return state;
  }
};

export default LstColorReducer;
