import React, { useEffect, useState } from "react";
import { Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import CA1Create from "./CA1Create/CA1Create";
import CA1Detail from "./CA1Detail/CA1Detail";
import CA1Filter from "./CA1Filter/CA1Filter";
// import ModalSelectRp from "./modalSelectRp/modalSelectRp";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actions from "../../../../redux/actions/CashAndBank/CashReceipt/CashReceipt";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../controller/Format";
import "./index.css";

const objectColumn = {
  POST2GL: { name: "Đã ghi sổ", width: 50, checkBox: true },
  NGAY_CT: { name: "Ngày chứng từ", width: 100, formatDate: true },
  SO_CT: { name: "Số chứng từ", width: 100 },
  MA_KH: { name: "Mã khách hàng", width: 70 },
  TEN_KH: { name: "Tên khách hàng", width: 120 },
  DIA_CHI: { name: "Địa chỉ", width: 150 },
  T_TIEN: { name: "Thành tiền", width: 80, formatMoney: true },
  MA_NT: { name: "Mã ngoại tệ", width: 50 },
  DIEN_GIAI: { name: "Diễn giải", width: 100 },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function CashReceipt(props) {
  const dispatch = useDispatch();
  const { history } = props;
  const CA1State = useSelector((state) => state.CA1Reducer);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const [isLoading, setLoading] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false);
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListCashReceipt({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(CA1State.listCashReceipt);
    setData(CA1State.listCashReceipt);
    setFullData(CA1State.listCashReceipt);
    setLoading(false);
  }, [CA1State.listCashReceipt]);

  useEffect(() => {
    if (CA1State.checkDelete) {
      dispatch(actions.getListCashReceipt({ ma_cty }));
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [CA1State.checkDelete]);

  const handleOpenDrawer = () => {
    setOpenDrawerCreate(true);
  };

  const handleDelete = (params) => {
    dispatch(actions.deleteACashReceipt({ modelM: params }));
    setLoading(true);
  };

  const handleEdit = () => {
    if (!_.isEmpty(isMaster)) {
      dispatch(actions.getCTCashReceipt(isMaster));
      history.push("/CA1Edit", {
        Master: isMaster,
      });
    }
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const checkVisible = (params) => {
    setOpenDrawerCreate(params);
    setOpenDrawerDetail(params);
    setOpenDrawerFilter(params);
    setOpenModalPrint(params);
  };

  const handlePrint = (params) => {
    dispatch(actions.getCTCashReceipt(params));
    setOpenModalPrint(true);
  };
  return (
    <div className="cashReceipt" style={{ height: "100%" }}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Phiếu thu"
          onBack={() => history.push("/CashAndBank")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          setPrint={handlePrint}
          Master={isMaster}
        />
        {/* <ModalSelectRp
          isModalVisible={openModalPrint}
          checkVisible={checkVisible}
          Master={isMaster}
          ctData={toUpperPropertyNameByArray(
            useSelector((state) => state.CA1Reducer.ctCashReceipt)
          )}
        /> */}
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListCashReceipt({ ma_cty }));
          }}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="SO_CT"
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setOpenDrawerDetail(true);
          }}
        />
      </Spin>
      {openDrawerCreate ? (
        <CA1Create
          isVisible={openDrawerCreate}
          checkVisible={checkVisible}
          history={history}
        />
      ) : (
        <></>
      )}
      {openDrawerDetail ? (
        <CA1Detail
          isVisible={openDrawerDetail}
          checkVisible={checkVisible}
          Master={isMaster}
        />
      ) : (
        <></>
      )}
      {openDrawerFilter ? (
        <CA1Filter
          isVisible={openDrawerFilter}
          checkVisible={checkVisible}
          approvalScr={false}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
