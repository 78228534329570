import * as types from "../../../constants/ActionTypes";

var initialState = {
  listSalesInvoice: [],
  ctSalesInvoice: [],
  phSalesInvoice: {},
  contactSalesInvoice: [],
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  checkDispatch: false,
  sysExchange: [],
  dm_nv: [],
  tk_ck: [],
  tk_no: [],
  tk_ts_gtgt: [],
  paymentTerm: [],
  EInvoicePattern: {},
};

var SalesInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SO3: {
      return {
        ...state,
        listSalesInvoice: action.payload,
      };
    }
    case types.FILT_SO3: {
      return {
        ...state,
        listSalesInvoice: action.payload,
      };
    }
    case types.GET_CT_SO3: {
      return {
        ...state,
        ctSalesInvoice: action.payload,
      };
    }
    case types.GET_PH_SO3: {
      return {
        ...state,
        phSalesInvoice: action.payload,
      };
    }
    case types.GET_CUSTOMER_SO3: {
      return {
        ...state,
        customer: action.payload[0],
      };
    }
    case types.GET_SO_CT_SO3: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_SO3: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_SO3: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_LIST_STATUS_SO3: {
      return {
        ...state,
        listStatus: action.payload,
      };
    }
    case types.GET_LISTS_DM_SO3: {
      return {
        ...state,
        listCurrency: action.payload.dm_nt,
        listCustomer: action.payload.dm_kh,
        sysExchange: action.payload.sysExchange,
        dm_nv: action.payload.dm_nv,
        tk_ck: action.payload.tk_ck,
        tk_no: action.payload.tk_no,
        tk_ts_gtgt: action.payload.tk_ts_gtgt,
        paymentTerm: action.payload.paymentTerm,
        EInvoicePattern: action.payload.EInvoicePattern[0],
      };
    }
    case types.GET_EXCHANGE_RATE_SO3: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_SO3: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }

    case types.CLEAR_SO3: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctSalesInvoice: [],
        phSalesInvoice: {},
        newItem: null,
      };
    }
    case types.CLEAR_HANDLE_STT_SO3: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }

    default:
      return state;
  }
};

export default SalesInvoiceReducer;
