import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getMachineState = (ma_cty) => (dispatch, getState) => {
  callApi("api/PDMachineStatus/GetMachineState", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_MACHINE_STATE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getMachineStateNewUpdate = (query) => (dispatch, getState) => {
  callApi("api/PDMachineStatus/GetMachineStateNewUpdate", "POST", query)
    .then((res) => {
      dispatch({
        type: types.GET_MACHINE_STATE_NEW_UPDATE,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getLstMachinesStateColor = (query) => (dispatch, getState) => {
  callApi("api/PDMachineStatus/GetLstMachinesState", "POST", query)
    .then((res) => {
      dispatch({
        type: types.GET_MACHINE_STATE_COLOR,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

export const changeStateUpdate = () => {
  return {
    type: types.CHANGE_STATE_UPDATE,
  };
};

export const getTimeUpdate = (date) => {
  return {
    type: types.GET_TIME_UPDATE,
    payload: date,
  };
};
