import * as types from "../../../constants/ActionTypes";

var initialState = {
  listCashReceipt: [],
  ctCashReceipt: [],
  phCashReceipt: {},
  listCustomer: [],
  customer: {},
  soCt: "",
  newItem: null,
  newEdit: null,
  checkDelete: false,
  listStatus: [],
  listCurrency: [],
  exchangeRate: "",
  sysExchange: [],
  listNV: [],
  dm_tk: [],
  lstSite: [],
  dm_bp: [],
  dm_phi: [],
  dm_hopdong: [],
};

var CashReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_CA1: {
      return {
        ...state,
        listCashReceipt: action.payload,
      };
    }
    case types.FILT_CA1: {
      return {
        ...state,
        listCashReceipt: action.payload,
      };
    }
    case types.GET_PH_CA1: {
      return {
        ...state,
        phCashReceipt: action.payload,
      };
    }
    case types.GET_CT_CA1: {
      return {
        ...state,
        ctCashReceipt: action.payload,
      };
    }

    case types.GET_SO_CT_CA1: {
      return {
        ...state,
        soCt: action.payload,
      };
    }
    case types.ADD_NEW_CA1: {
      return {
        ...state,
        newItem: action.payload,
      };
    }
    case types.UPDATE_CA1: {
      return {
        ...state,
        newEdit: action.payload,
      };
    }
    case types.GET_EXCHANGE_RATE_CA1: {
      return {
        ...state,
        exchangeRate: action.payload,
      };
    }
    case types.DELETE_CA1: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_CA1: {
      return {
        ...state,
        customer: {},
        checkDelete: false,
        newEdit: null,
        ctCashReceipt: [],
        phCashReceipt: {},
        newItem: null,
        exchangeRate: "",
      };
    }
    case types.CLEAR_HANDLE_STT_CA1: {
      return {
        ...state,
        newEdit: null,
        checkDelete: false,
        newItem: null,
      };
    }
    case types.GET_LISTS_DM_CA1: {
      return {
        ...state,
        listCurrency: action.payload.dm_nt,
        listCustomer: action.payload.dm_kh,
        sysExchange: action.payload.sysExchange,
        listNV: action.payload.dm_nv,
        dm_tk: action.payload.dm_tk,
        lstSite: action.payload.lstSite,
        dm_bp: action.payload.dm_bp,
        dm_phi: action.payload.dm_phi,
        dm_hopdong: action.payload.dm_hopdong,
      };
    }
    default:
      return state;
  }
};

export default CashReceiptReducer;
