import Purchase from "../../../components/layout/Purchases/purchases";
import PurchaseReceipt from "../../../components/layout/Purchases/purchaseReceipt/purchaseReceipt";
import PurchaseReceiptEdit from "../../../components/layout/Purchases/purchaseReceipt/po2Edit/po2Edit";
import PurchaseOrder from "../../../components/layout/Purchases/purchaseOrder/purchaseOrder";
import PO1Edit from "../../../components/layout/Purchases/purchaseOrder/po1Edit/po1Edit";
import PurchaseOrderApproval from "../../../components/layout/Purchases/purchaseOrder/approvalPurchaseOrder/approvalPurchaseOrder";
const purchaseRoutes = [
  {
    path: "/Purchase",
    exact: false,
    main: ({ history }) => <Purchase history={history} />,
  },
  {
    path: "/PurchaseReceipt",
    exact: false,
    main: ({ history }) => <PurchaseReceipt history={history} />,
  },
  {
    path: "/poVch02Edit",
    exact: false,
    main: ({ history }) => <PurchaseReceiptEdit history={history} />,
  },
  {
    path: "/PurchaseOrder",
    exact: false,
    main: ({ history }) => <PurchaseOrder history={history} />,
  },
  {
    path: "/PO1Edit",
    exact: false,
    main: ({ history }) => <PO1Edit history={history} />,
  },
  {
    path: "/PurchaseOrderApproval",
    exact: false,
    main: ({ history }) => <PurchaseOrderApproval history={history} />,
  },
];

export default purchaseRoutes;
