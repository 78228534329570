import SellPriceForm from "../sellPriceForm/sellPriceForm";

export default function SellPriceDetail(props) {
  const { isVisible, checkVisible, Master } = props;
  return (
    <SellPriceForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      Master={Master}
      isEdit={false}
      title="Chi tiết giá bán"
    />
  );
}
