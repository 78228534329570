import React, { Component } from "react";
import { Collapse, Table } from "antd";
import _ from "lodash";
import { FormatColumnDHSTable, result } from "../../../../../controller/Format";
import DHSTable from "../../../../../../common/DHSControl/DHSTable/DHSTable";
const { Panel } = Collapse;

const objectColumn = {
  MA_VT: { name: "Mã VT/HH", width: 100 },
  TEN_VT: { name: "Tên vật tư", width: 200 },
  DVT: { name: "Đơn vị tính", width: 100 },
  MA_KHO: { name: "Kho", width: 100 },
  SO_LUONG: { name: "Số lượng", width: 100 },
  GIA0: { name: "Đơn giá", width: "20%", formatMoney: true },
  TT_NT: { name: "Tổng tiền", width: "25%", formatMoney: true },
};
export default class ListItem extends Component {
  state = {
    data: [],
    loading: false,
    customColumns: [],
  };
  componentDidUpdate = (prevProps) => {
    const { dsCT } = this.props;
    if (!_.isEqual(dsCT, prevProps.dsCT)) {
      FormatColumnDHSTable(objectColumn);
      this.setState({
        data: dsCT,
        loading: false,
        customColumns: result,
      });
    }
  };
  render() {
    const { data, customColumns } = this.state;
    return (
      <div className="so1-detail-listitem">
        <Collapse defaultActiveKey={["1"]} ghost expandIconPosition="right">
          <Panel header="Thông tin hàng hóa" key="1">
            <DHSTable
              data={data}
              customColumns={customColumns}
              rowKey="MA_VT"
            />
          </Panel>
        </Collapse>
      </div>
    );
  }
}
