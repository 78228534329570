import * as types from "../../../../constants/ActionTypes";

var initialState = {
  listSellPriceType: [],
  checkInsert: false,
  checkEdit: false,
  checkDelete: false,
};

var SellPriceTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SELL_PRICE_TYPE: {
      return {
        ...state,
        listSellPriceType: action.payload,
      };
    }
    case types.ADD_NEW_SELL_PRICE_TYPE: {
      return {
        ...state,
        checkInsert: action.payload,
      };
    }
    case types.UPDATE_SELL_PRICE_TYPE: {
      return {
        ...state,
        checkEdit: action.payload,
      };
    }
    case types.DELETE_SELL_PRICE_TYPE: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_SELL_PRICE_TYPE: {
      return {
        ...state,
        checkDelete: false,
        checkEdit: null,
        checkInsert: null,
      };
    }
    default:
      return state;
  }
};

export default SellPriceTypesReducer;
