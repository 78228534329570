import React, { Component } from "react";
import { connect } from "react-redux";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Category/site/site";
import _ from "lodash";
import SiteForm from "../SiteForm/SiteForm";
class SiteEdit extends Component {
  componentDidUpdate = (prevProps) => {
    if (this.props.checkUpdate !== prevProps.checkUpdate) {
      openNotificationWithIcon("success", "Sửa chi nhánh thành công !");
      this.props.clearCache();
      this.props.checkVisible({ isVisible: false, editSuccess: true });
    }
  };

  getItemActions = (params) => {
    var model = params;
    model.MA_CTY = this.props.ma_cty;
    model.SITE_ID = this.props.Master.SITE_ID;
    this.props.updateSite(model);
  };

  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <SiteForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          getItemActions={this.getItemActions}
          title="Sửa thông tin chi nhánh"
          Master={Master}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listDm: state.SiteReducer.listDm,
    checkUpdate: state.SiteReducer.checkUpdate,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    updateSite: (model) => {
      dispatch(actions.updateSite(model));
    },

    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteEdit);
