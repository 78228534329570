import React, { Component } from "react";
import Grantt from "../../ChartType/ganttChart";
import * as actions from "../../../../../redux/actions/Manufacturing/MasterPlanning/index";
import * as actionsManufacturing from "../../../../../redux/actions/Manufacturing/Manufacturing";
import _ from "lodash";
import { FormatDateTime, FormatMoney } from "../../../../controller/Format";
import { granttScaleType } from "../../../../controller/DataSample";
import { Row, Col } from "antd";
import DateBox from "devextreme-react/date-box";
import { Button } from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import { DHSSelectOpt } from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { connect } from "react-redux";
import moment from "moment";
import "./index.css";
import { periodData } from "../../../../controller/DataSample";
import { onPeriodChange } from "../../../../controller/Format";
import ExportExcel from "./ExportExcel/exportExcel";
class MachineSchedule extends Component {
  state = {
    time_from: moment().startOf("years"),
    time_to: moment().endOf("years"),
    machine_id: "",
    scaleType: "days",
    period: "cn",
  };
  componentDidMount = () => {
    const { time_from, time_to, machine_id } = this.state;
    this.props.getPLGanttDeadlinebyMachine({
      ma_cty: this.props.ma_cty,
      machine_id: machine_id,
      ngay_ct1: time_from,
      ngay_ct2: time_to,
    });
    this.props.GetListMachines({ ma_cty: this.props.ma_cty });
  };
  onSelectionChanged = (e) => {
    this.setState({
      machine_id: e,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { listPlanning } = this.props;
    if (!_.isEqual(listPlanning, prevProps.listPlanning)) {
      listPlanning.map((item, index) => {
        item.STARTDATE = FormatDateTime(item.STARTDATE);
        item.FINISHDATE = FormatDateTime(item.FINISHDATE);
        item.NGAY_GIAOHANG = FormatDateTime(item.NGAY_GIAOHANG);
        item.SO_LUONG_KH = FormatMoney(item.SO_LUONG_KH);
        item.SO_LUONG_HT = FormatMoney(item.SO_LUONG_HT);
        item.FINISHDATE_ACTUAL = FormatDateTime(item.FINISHDATE_ACTUAL);
        if (item.PLANLEVEL === -2) {
          item.TASKNAME = this.props.companyName;
        }
      });
    }
  };

  onValueChanged = (name) => (e) => {
    this.setState({ [name]: e.value });
  };

  onClickSearch = () => {
    const { machine_id, time_from, time_to } = this.state;
    this.props.getPLGanttDeadlinebyMachine({
      ma_cty: this.props.ma_cty,
      machine_id: machine_id,
      ngay_ct1: moment(time_from).add(7, "hours"),
      ngay_ct2: moment(time_to).add(7, "hours"),
    });
  };
  onSelectionScaleChanged = (e) => {
    this.setState({ scaleType: e.selectedItem.type });
  };
  onHandleFilter = (params) => {
    const result = onPeriodChange(params, moment().weeks());
    this.setState({
      period: result.period,
      time_to: result.toDate,
      time_from: result.formDate,
    });
  };
  render() {
    const { listPlanning, listMachine } = this.props;
    const { time_from, time_to, scaleType, period } = this.state;
    return (
      <div className="machineSchedule">
        <div
          style={{
            fontSize: 24,
            padding: 10,
            color: "#1890ff",
            background: "white",
          }}
        >
          Kế hoạch sản xuất theo máy
        </div>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ margin: "10px 0px", background: "white" }}
        >
          <Col span={24} xs={24} xl={3}>
            <div className="textTime">Scale type</div>
            <div>
              <SelectBox
                items={granttScaleType}
                onSelectionChanged={this.onSelectionScaleChanged}
                valueExpr="type"
                displayExpr="name"
                defaultValue={"days"}
              />
            </div>
          </Col>
          <Col span={24} xs={24} xl={3}>
            <div className="textTime">Lọc</div>
            <div>
              <DHSSelectOpt
                placeHolder="Chọn điều kiện lọc"
                array={periodData}
                defaultValue={period}
                valueOpt="value"
                valueRender="name"
                onChange={this.onHandleFilter}
              />
            </div>
          </Col>
          <Col span={24} xs={24} xl={4}>
            <div className="textTime">Thời gian từ</div>
            <div>
              <DateBox
                value={time_from}
                defaultValue={time_from}
                type="datetime"
                onValueChanged={this.onValueChanged("time_from")}
                displayFormat={"dd/MM/yyyy HH:mm"}
              />
            </div>
          </Col>
          <Col span={24} xs={24} xl={4}>
            <div className="textTime">đến</div>
            <div>
              <DateBox
                value={time_to}
                defaultValue={time_to}
                type="datetime"
                min={time_from}
                onValueChanged={this.onValueChanged("time_to")}
                displayFormat={"dd/MM/yyyy HH:mm"}
              />
            </div>
          </Col>
          <Col span={24} xs={24} xl={4}>
            <div className="textTime">Mã máy</div>
            <div>
              <DHSSelectOpt
                array={listMachine}
                valueOpt="machine_id"
                valueRender="machine_code"
                onChange={this.onSelectionChanged}
                valueEmpty={true}
              />
            </div>
          </Col>
          <Col span={8} xs={24} xl={3}>
            <Button
              style={{ marginTop: 22 }}
              width={120}
              text="Tìm kiếm"
              type="normal"
              stylingMode="contained"
              onClick={this.onClickSearch}
            />
          </Col>
          <Col span={8} xs={24} xl={3}>
            <ExportExcel
              listPlanning={listPlanning}
              time_from={time_from}
              time_to={time_to}
              button={
                <Button
                  style={{ marginTop: 22 }}
                  width={120}
                  text="Export Excel"
                  type="normal"
                  stylingMode="contained"
                />
              }
            />
          </Col>
        </Row>
        <Grantt dataSource={listPlanning} scaleType={scaleType} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    companyName: state.LoginReducer.companyName,
    listPlanning: state.MasterPlanningReducer.listPlanning,
    listMachine: state.ManufacturingReducer.listMachine,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getPLGanttDeadlinebyMachine: (query) => {
      dispatch(actions.getPLGanttDeadlinebyMachine(query));
    },
    GetListMachines: (query) => {
      dispatch(actionsManufacturing.GetListMachines(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineSchedule);
