import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Manufacturing/Manufacturing";
import {
  Chart,
  CommonSeriesSettings,
  Legend,
  Border,
  Label,
  Format,
  Tooltip,
  ScrollBar,
  ArgumentAxis,
  ZoomAndPan,
  SeriesTemplate,
  Title,
  Series,
  Size,
} from "devextreme-react/chart";
import _ from "lodash";
import moment from "moment";
class Bar extends Component {
  componentDidMount = () => {
    this.props.GetMachinesActionState({ ma_cty: this.props.ma_cty });
  };
  onPointClick = ({ target: point }) => {
    this.props.onClickCol(point.data);
  };
  customizeSeries = (valueFromNameField) => {
    const { listMachineActionState } = this.props;
    var color = "";
    if (listMachineActionState.length > 0) {
      listMachineActionState.map((item, index) => {
        if (item.DESCRIPTION === valueFromNameField) {
          color = item.BACKCOLOR;
        }
      });
    }
    return color !== "" ? { color: color } : {};
  };
  render() {
    const {
      dataSource,
      type,
      title,
      descriptionField,
      argumentField,
      valueField,
      scroll,
      onClickCol,
      legendVisible,
      displayLabelMode,
      customizeTooltip,
      customerText,
    } = this.props;
    return (
      <Chart
        id="chart"
        dataSource={dataSource}
        onPointClick={onClickCol ? this.onPointClick : ""}
        // resolveLabelOverlapping="stack"
      >
        <Title text={title} font={{ size: 18, color: "#1890ff" }} />
        <SeriesTemplate
          nameField={descriptionField}
          customizeSeries={this.customizeSeries}
        />
        <CommonSeriesSettings
          argumentField={argumentField}
          type={type}
          valueField={valueField}
          ResolveOverlappingMode="none"
          type="stackedBar"
        >
          <Label visible={true} customizeText={customerText}>
            <Format type="fixedPoint" precision={0} />
          </Label>
        </CommonSeriesSettings>
        <Legend
          verticalAlignment="center"
          horizontalAlignment="center"
          visible={legendVisible}
        >
          <Border visible={true} />
        </Legend>
        <ArgumentAxis>
          <Label
            staggeringSpacing={1}
            rotationAngle={45}
            displayMode={!_.isNil(displayLabelMode) ? displayLabelMode : ""}
          />
        </ArgumentAxis>
        {scroll ? <ScrollBar visible={true} position="bottom" /> : <></>}
        <ZoomAndPan argumentAxis="both" allowTouchGestures={true} />
        <Tooltip
          enabled={true}
          location="edge"
          customizeTooltip={customizeTooltip}
        />
      </Chart>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listMachineActionState: state.ManufacturingReducer.listMachineActionState,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    GetMachinesActionState: (query) => {
      dispatch(actions.GetMachinesActionState(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bar);
