import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListCustomerAR = (ma_cty) => (dispatch, getState) => {
  callApi("api/Customer/GetList", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CUSTOMER_SI,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCustomer = (query) => (dispatch, getState) => {
  callApi("api/Customer/GetCustomer", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_CUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getLists = (ma_cty) => (dispatch, getState) => {
  callApi("api/Customer/GetLists", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteCustomer = (query) => (dispatch, getState) => {
  callApi("api/Customer/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_CUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewCustomer = (model) => (dispatch, getState) => {
  callApi("api/Customer/InsertCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_CUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const updateCustomer = (model) => (dispatch, getState) => {
  callApi("api/Customer/UpdateCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CUSTOMER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewAddress = (item) => {
  return {
    type: types.ADD_NEW_ADDRESS,
    item,
  };
};
export const clearCache = () => {
  return {
    type: types.CLEAR_CUSTOMER,
  };
};
