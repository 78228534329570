import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListItemGroup = (ma_cty) => (dispatch, getState) => {
  callApi("api/ItemGroup/ListItemGroup", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_ITEMGROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListDM = (ma_cty) => (dispatch, getState) => {
  callApi("api/ItemGroup/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_DM_ITEMGROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const insertItemGroup = (query) => (dispatch, getState) => {
  callApi("api/ItemGroup/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.INSERT_ITEMGROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const deleteItemGroup = (query) => (dispatch, getState) => {
  callApi("api/ItemGroup/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_ITEMGROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateItemGroup = (query) => (dispatch, getState) => {
  callApi("api/ItemGroup/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_ITEMGROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_ITEMGROUP,
  };
};
