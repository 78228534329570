import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, PageHeader } from "antd";
import DHSDataGrid from "../../../../../common/DHSControl/DHSDataGrid/DHSDataGrid";
import * as actions from "../../../../../redux/actions/Sales/reports/reports";
import DHSHanleFilter from "../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import * as actionsLogin from "../../../../../redux/actions/Login/index";
import Filter from "./Filter/Filter";
import "./index.css";
// const objectColumn = {
//   ngay_ct: { name: "Ngày chứng từ", width: 100, formatDate: true },
//   ngay_hh: { name: "Ngày giao", width: 100, formatDate: true },
//   so_ct: { name: "Số chứng từ", width: 100 },
//   so_po_kh: { name: "Số PO KH", width: 100 },
//   so_lsx: { name: "Số LSX", width: 100 },
//   ma_kh: { name: "Mã KH", width: 100 },
//   ten_kh: { name: "Tên KH", width: 150 },
//   dien_giai: { name: "Diễn giải", width: 100 },
//   site_id: { name: "Chi nhánh", width: 100 },
//   ma_vt: { name: "Mã SP", width: 100 },
//   ten_vt: { name: "Tên SP", width: 150 },
//   dvt: { name: "ĐVT", width: 100 },
//   so_luong: { name: "Số lượng", width: 100 },
//   gia_nt2: { name: "Đơn giá", width: 150, formatMoney: true },
//   gia2: { name: "Đơn giá VNĐ", width: 150, formatMoney: true },
//   tien_nt2: { name: "Tiền hàng", width: 150, formatMoney: true },
//   tien2: { name: "Tiền hàng VNĐ", width: 150, formatMoney: true },
//   thue_gtgt_nt: { name: "Tiền thuế", width: 150, formatMoney: true },
//   thue_gtgt: { name: "Tiền thuế VNĐ", width: 150, formatMoney: true },
//   tt_nt: { name: "Tổng thanh toán", width: 150, formatMoney: true },
//   tt: { name: "Tổng thanh toán VNĐ", width: 150, formatMoney: true },
// };
const isColumns = [
  {
    caption: "Ngày chứng từ",
    dataField: "ngay_ct",
    type: 0,
    format: "date",
  },
  {
    caption: "Ngày giao",
    dataField: "ngay_hh",
    type: 0,
    format: "date",
  },
  {
    caption: "Số chứng từ",
    dataField: "so_ct",
    type: 0,
  },
  {
    caption: "Số PO KH",
    dataField: "so_po_kh",
    type: 0,
  },
  {
    caption: "Số LSX",
    dataField: "so_lsx",
    type: 0,
  },
  {
    caption: "Mã KH",
    dataField: "ma_kh",
    type: 0,
  },
  {
    caption: "Tên KH",
    dataField: "ten_kh",
    type: 0,
  },
  {
    caption: "Diễn giải",
    dataField: "dien_giai",
    type: 0,
  },
  {
    caption: "Chi nhánh",
    dataField: "site_id",
    type: 0,
  },
  {
    caption: "Mã SP",
    dataField: "ma_vt",
    type: 0,
  },
  {
    caption: "Tên SP",
    dataField: "ten_vt",
    type: 0,
  },
  {
    caption: "ĐVT",
    dataField: "dvt",
    type: 0,
  },
  {
    caption: "Số lượng",
    dataField: "so_luong",
    type: 0,
  },
  {
    caption: "Đơn giá",
    dataField: "gia_nt2",
    format: "Money",
    type: 0,
  },
  {
    caption: "Đơn giá VNĐ",
    dataField: "gia2",
    format: "Money",
    type: 0,
  },
  {
    caption: "Tiền hàng",
    dataField: "tien_nt2",
    format: "Money",
    type: 0,
  },
  {
    caption: "Tiền hàng VNĐ",
    dataField: "tien2",
    format: "Money",
    type: 0,
  },
  {
    caption: "Tiền thuế",
    dataField: "thue_gtgt_nt",
    type: 0,
    format: "Money",
  },
  {
    caption: "Tiền thuế VNĐ",
    dataField: "thue_gtgt",
    type: 0,
    format: "Money",
  },
  {
    caption: "Tổng thanh toán",
    dataField: "tt_nt",
    format: "Money",
    type: 0,
  },
  {
    caption: "Tổng thanh toán VNĐ",
    dataField: "tt",
    format: "Money",
    type: 0,
  },
];

export default function SalesTransaction(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listDm = useSelector((state) => state.SOReportsReducer.listDm);
  const RptBK01 = useSelector((state) => state.SOReportsReducer.RptBK01);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [OpenDrawerFilter, setOpenDrawerFilter] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.GetListsDm({ ma_cty }));
    dispatch(actionsLogin.getListCompany());
  }, []);

  useEffect(() => {
    setData(RptBK01);
    setFullData(RptBK01);
    setLoading(false);
    return () => {
      RptBK01.length = 0;
    };
  }, [RptBK01]);

  const getDataFilter = (params) => {
    setData(params);
  };
  const checkVisible = (params) => {
    setOpenDrawerFilter(params);
  };

  const selectionChanged = () => {};
  
  const hanleRowChange = () => {};
  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Bảng kê bán hàng"
          onBack={() => history.push("/Sale")}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          openFiler={() => {
            setOpenDrawerFilter(true);
          }}
        />
        <DHSDataGrid
          column={isColumns}
          data={data}
          dataKey={"so_ct"}
          selectionChanged={selectionChanged}
          hanleRowChange={hanleRowChange}
          exportExcel={true}
          disabled={false}
        />
        {OpenDrawerFilter ? (
          <Filter isVisible={OpenDrawerFilter} checkVisible={checkVisible} />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
