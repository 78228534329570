import React, { useState, useEffect } from "react";
import { Row, Col, Descriptions } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import DHSTable from "../../../../../../common/DHSControl/DHSTable/DHSTable";
import {
  FormatMoney,
  toUpperPropertyNameByArray,
  FormatColumnDHSTable,
  result,
} from "../../../../../controller/Format";
import * as actions from "../../../../../../redux/actions/Purchases/purchaseOrder/purchaseOrder";
const objectColumn = {
  MA_VT: { name: "Mã VT/HH", width: 100 },
  TEN_VT: { name: "Tên vật tư", width: 200 },
  DVT: { name: "Đơn vị tính", width: 100 },
  MA_KHO: { name: "Kho", width: 100 },
  SO_LUONG: { name: "Số lượng", width: 100 },
  GIA_NT2: { name: "Đơn giá", width: "20%", formatMoney: true },
  TT: { name: "Tổng tiền", width: "25%", formatMoney: true },
};
const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};
export default function ListItem(props) {
  const { Master } = props;
  const dispatch = useDispatch();
  const [customColumns, setCustomColumns] = useState([]);
  const ctPurchaseOrder = toUpperPropertyNameByArray(
    useSelector((state) => state.PO1Reducer.ctPurchaseOrder)
  );
  useEffect(() => {
    dispatch(actions.getCTPurchaseOrder(Master));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);
  return (
    <div className="po1-detail-listitem">
      <DHSTable
        data={ctPurchaseOrder}
        customColumns={customColumns}
        scroll={{ y: 200, x: 800 }}
        rowKey="MA_VT"
      />
      <div className="detailTotal">
        <Descriptions bordered size="small">
          <Descriptions.Item label="Thuế :" span={3}>
            {FormatMoney(Master.T_THUE)}
          </Descriptions.Item>
          <Descriptions.Item label="Thành tiền :" span={3}>
            {FormatMoney(Master.T_TT)}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
}
