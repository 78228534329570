import React, { Component } from "react";
import { Row, Col } from "antd";
import Format from "../../../../components/controller/Format";
import Barcode from "react-barcode";
import _ from "lodash";
import "./index.css";
export default class Rp02 extends Component {
  render() {
    const {
      title,
      ctObjPrint,
      objPrint,
      ctAddressPrint,
      driverPrint,
    } = this.props;
    const driver = _.isEmpty(driverPrint) ? {} : driverPrint[0];
    return (
      <div>
        {ctAddressPrint.map((item) => {
          return (
            <div className="rp02">
              <Row className="rp02Header">
                <Col span={10}>
                  <div className="logoRp02">
                    <img
                      src="http://www.dhsoft.com.vn/upload/photo/logo_12391829082020.png"
                      className="img"
                    />
                  </div>
                </Col>
                <Col span={14}>
                  <div className="inforCompany">
                    <h4>Công ty TNHH Giải Pháp Phần Mềm DHSoft</h4>
                    <h5>
                      672A41 , Phan Văn Trị, KDC Cityland Park Hills, Phường 10,
                      Gò Vấp, TP.HCM
                    </h5>
                    <h5>Tel: 0948.47.39.67 - Fax: - Mobile:</h5>
                    <h5>
                      <a
                        style={{ textDecoration: "none" }}
                        href="http://www.dhsoft.com.vn"
                      >
                        www.dhsoft.com.vn / Email: sales@dhsoft.com.vn
                      </a>
                    </h5>
                  </div>
                </Col>
              </Row>
              <div className="titleRp">
                <Row>
                  <Col span={15}>
                    <h2>{title}</h2>
                  </Col>
                  <Col span={6} offset={3}>
                    <div style={{ textAlign: "right" }}>
                      <Barcode
                        value={objPrint.SO_CT}
                        height={30}
                        width={1}
                        fontSize={12}
                        format={"CODE128"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="inforOrder">
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <div className="tableOrder">
                      <h5>
                        <b>Khách hàng:</b> {item.CONTACT_NAME}
                      </h5>
                      <h5>
                        <b>Địa chỉ:</b> {item.DELIVERY_ADDRESS}
                      </h5>
                      <h5>
                        <b>Điện thoại:</b> {item.TEL}
                      </h5>
                    </div>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <div className="tableOrder">
                      <h5>
                        <b>Người giao:</b> {driver.DRIVER_NAME}
                      </h5>
                      <h5>
                        <b>Biển số:</b> {driver.BIEN_SO}
                      </h5>
                      <h5>
                        <b>Điện thoại:</b> {driver.PHONE}
                      </h5>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="tableItem">
                <table>
                  <tbody>
                    <tr className="titleTable">
                      <th>
                        <h5>STT</h5>
                      </th>
                      <th>
                        <h5>TÊN VẬT TƯ</h5>
                      </th>
                      <th style={{ wclassNameth: "90px" }}>
                        <h5>MÃ VẬT TƯ</h5>
                      </th>
                      <th style={{ wclassNameth: "40px" }}>
                        <h5>ĐVT</h5>
                      </th>
                      <th style={{ wclassNameth: "40px" }}>
                        <h5>SL</h5>
                      </th>
                      {/* <th>
                        <h5>ĐƠN GIÁ</h5>
                      </th>
                      <th>
                        <h5>THÀNH TIỀN</h5>
                      </th> */}
                    </tr>
                    {ctObjPrint.map((item2, index) => {
                      if (item.IDITEM === item2.IDITEM) {
                        return (
                          <tr className="contentTable">
                            <th>
                              <h5>{index + 1}</h5>
                            </th>
                            <th>
                              <h5>{item2.TEN_VT}</h5>
                            </th>
                            <th style={{ wclassNameth: "90px" }}>
                              <h5>{item2.MA_VT}</h5>
                            </th>
                            <th style={{ wclassNameth: "40px" }}>
                              <h5>{item2.DVT}</h5>
                            </th>
                            <th style={{ wclassNameth: "40px" }}>
                              <h5>{item.SO_LUONG}</h5>
                            </th>
                            {/* <th>
                              <h5>{Format.FormatMoney(item.GIA_NT2)}</h5>
                            </th>
                            <th>
                              <h5>{Format.FormatMoney(item.TT_NT)}</h5>
                            </th> */}
                          </tr>
                        );
                      }
                    })}
                    {/* <tr className="bottomTable">
                      <th colSpan={4} style={{ textAlign: "right" }}>
                        Cộng
                      </th>
                      <th>{Format.FormatMoney(objPrint.T_TIEN_NT2)}</th>
                    </tr>
                    <tr className="bottomTable">
                      <th colSpan={4} style={{ textAlign: "right" }}>
                        CK %
                      </th>
                      <th>{objPrint.TL_CK}</th>
                    </tr>
                    <tr className="bottomTable">
                      <th colSpan={4} style={{ textAlign: "right" }}>
                        VAT %
                      </th>
                      <th>{objPrint.TS_GTGT}</th>
                    </tr>
                    <tr className="bottomTable">
                      <th colSpan={6} style={{ textAlign: "right" }}>
                        <h5>Tổng cộng</h5>
                      </th>
                      <th>
                        <h5>{Format.FormatMoney(objPrint.T_TT_NT)}</h5>
                      </th>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              {/* <div className="readMoney">
                <p style={{ fontSize: "14px", fontStyle: "italic" }}>
                  Số tiền bằng chữ: <b />
                </p>
              </div> */}
              <div className="note" style={{ marginTop: 10 }}>
                <p style={{ fontSize: "14px" }}>
                  <b>Ghi chú:</b>
                </p>
              </div>
              <div className="signature" style={{ pageBreakAfter: "always" }}>
                <div
                  style={{
                    fontSize: "14px",
                    textAlign: "right",
                    marginRight: "40px",
                    fontStyle: "italic",
                  }}
                >
                  Ngày ......... tháng ......... năm .........
                </div>
                <div>
                  <Row style={{ textAlign: "center" }}>
                    <Col span={13}>
                      <h4>Khách hàng</h4>
                      <h5>( Ký, ghi rõ họ tên )</h5>
                    </Col>
                    <Col span={11}>
                      <h4>Bên giao</h4>
                      <h5>( Ký, ghi rõ họ tên )</h5>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
