import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, Collapse, Button } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../../redux/actions/Sales/packingSlip/index";
import { useDispatch, useSelector } from "react-redux";
import DHSHanleFilter from "../../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { FormatColumnDHSTable, result } from "../../../../../controller/Format";
import DHSTable from "../../../../../../common/DHSControl/DHSTable/DHSTable";
import PropTypes from "prop-types";
import _ from "lodash";
import "../../index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
const { Panel } = Collapse;

FilterInvoices.propTypes = {
  _objectCreate: PropTypes.object,
};

FilterInvoices.defaultProps = {};
const objectColumn = {
  SO_CT: { name: "Số phiếu xuất", width: 100 },
  NGAY_CT: { name: "Ngày tạo", width: 100, formatDate: true },
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_NT: { name: "Mã ngoại tệ", width: 100 },
  T_TT: { name: "Tiền hàng", width: "10%", formatMoney: true },
};
export default function FilterInvoices(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, getOldInvoices, isPage } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const [customColumns, setCustomColumns] = useState([]);
  const [PHPackingSlip, setPHPackingSlip] = useState();
  const [data, setData] = useState([]);
  const listPackingSlip = useSelector(
    (state) => state.SO2Reducer.listPackingSlip
  );
  const lstCTPackingSlip = useSelector(
    (state) => state.SO2Reducer.ctPackingSlip
  );
  const listCustomer = useSelector(
    (state) => state.SICustomerReducer.listCustomer
  );
  const loadListSO2 = () => {
    listPackingSlip.length = 0;
    if (customColumns.length === 0) {
      FormatColumnDHSTable(objectColumn);
      setCustomColumns(result);
    }
    dispatch(actions.getListPackingSlip({ ma_cty }));
  };

  useEffect(() => {
    if (!_.listPackingSlip) {
      setData(listPackingSlip);
    }
  }, [listPackingSlip]);

  useEffect(() => {
    loadListSO2();
  }, []);

  const handleCancel = () => {
    checkVisible(false);
  };
  const handleOk = () => {
    let total = {
      SO_CT: PHPackingSlip.SO_CT,
      TIEN_CK_NT: PHPackingSlip.T_CK_NT,
      T_TIEN2: PHPackingSlip.T_TIEN2,
      T_THUE: PHPackingSlip.T_THUE,
      T_TT: PHPackingSlip.T_TIEN2,
      CON_LAI: PHPackingSlip.T_TIEN2 - PHPackingSlip.KHACH_TRA,
      KHACH_TRA: PHPackingSlip.KHACH_TRA,
    };
    let customer = listCustomer.find(
      (x) => x.IDCUSTOMER === PHPackingSlip.IDCUSTOMER
    );
    getOldInvoices(total, customer, lstCTPackingSlip);
    checkVisible(false);
  };

  const getDataRow = (params) => {
    setPHPackingSlip(params);
    dispatch(
      actions.getCTPackingSlip({
        stt_rec: params.STT_REC,
        ma_cty,
      })
    );
  };
  const getDataFilter = (params) => {
    setData(params);
  };
  return (
    <div>
      <Modal
        id="submit-form"
        width={900}
        title="Tra cứu hóa đơn"
        visible={isVisible}
        onCancel={handleCancel}
        footer={
          <Row gutter={[16, 16]}>
            <Col span={3} offset={19}>
              <Button
                type="primary"
                onClick={handleOk}
                disabled={_.isEmpty(PHPackingSlip)}
              >
                Đồng ý
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={handleCancel}>Hủy</Button>
            </Col>
          </Row>
        }
      >
        <Row>
          <Col span={24} className="_posFilterInvoices">
            <DHSHanleFilter
              getDataFilter={getDataFilter}
              dataFilter={listPackingSlip}
            />
            <DHSTable
              data={data}
              customColumns={customColumns}
              rowKey="SO_CT"
              getDataRow={getDataRow}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
