import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { DHSInput } from "../../../../common/DHSControl/DHSForm/DHSFormItem";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import * as actions from "../../../../redux/actions/System/ChangePassword/changePassword";
import PropTypes from "prop-types";
ChangePassword.propTypes = {
  _objCreate: PropTypes.object,
};

ChangePassword.defaultProps = {
  _objCreate: {
    oldPassword: "",
    newPassword: "",
    renewPassword: "",
  },
};
const validateMessages = {
  required: "Trường này là bắt buộc",
};
export default function ChangePassword(props) {
  const dispatch = useDispatch();
  const { isVisible, history, _objCreate } = props;
  const [objCreate, setObjCreate] = useState(_objCreate);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const checkStatus = useSelector(
    (state) => state.ChangePasswordReducer.checkStatus
  );
  const validationText = () => {
    const { oldPassword, newPassword, renewPassword } = objCreate;
    if (
      oldPassword.length < 6 ||
      newPassword.length < 6 ||
      renewPassword.length < 6
    ) {
      openNotificationWithIcon("error", "Mật khẩu phải có trên 6 ký tự !");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!_.isNull(checkStatus)) {
      if (checkStatus) {
        openNotificationWithIcon("success", "Đổi mật khẩu thành công !");
        history.push("/System");
      } else {
        openNotificationWithIcon("error", "Mật khẩu cũ không đúng !");
      }
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [checkStatus]);

  const handleOk = () => {
    const { oldPassword, newPassword, renewPassword } = objCreate;
    if (validationText()) {
      if (_.isEqual(newPassword, renewPassword)) {
        dispatch(
          actions.changePassword({ userName, newPassword, oldPassword })
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Mật khẩu mới và mật khẩu nhập lại không trùng nhau !"
        );
      }
    }
  };
  const handleCancel = () => {
    history.push("/System");
  };
  const handleChangeText = (e) => {
    setObjCreate({ ...objCreate, [e.name]: e.value });
  };
  return (
    <div>
      <Modal
        title="Thay đổi mật khẩu"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          id="submit-form"
          layout="vertical"
          validateMessages={validateMessages}
        >
          <DHSInput
            label="Mật khẩu cũ"
            name="oldPassword"
            value={objCreate.oldPassword}
            getValueChange={handleChangeText}
            passWord={true}
          />
          <DHSInput
            label="Mật khẩu mới"
            name="newPassword"
            value={objCreate.newPassword}
            getValueChange={handleChangeText}
            passWord={true}
          />
          <DHSInput
            label="Nhập lại mật khẩu"
            name="renewPassword"
            value={objCreate.renewPassword}
            getValueChange={handleChangeText}
            passWord={true}
          />
        </Form>
      </Modal>
    </div>
  );
}
