import Ds2Form from "../ds2Form/ds2Form";

export default function Ds2Detail(props) {
  const { isVisible, checkVisible, Master } = props;
  return (
    <Ds2Form
      isVisible={isVisible}
      checkVisible={checkVisible}
      Master={Master}
      isEdit={false}
      title="Chi tiết chương trình khuyến mãi"
    />
  );
}
