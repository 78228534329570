import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, PageHeader } from "antd";
import ToolBar from "../../../../../common/DHSControl/DHSButton/toolBar";
import DHSHanleFilter from "../../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import DHSTable from "../../../../../common/DHSControl/DHSTable/DHSTable";
import SellPriceTypesCreate from "./sellPriceTypesCreate/sellPriceTypesCreate";
import SellPriceTypesDetail from "./sellPriceTypesDetail/sellPriceTypesDetail";
import SellPriceTypesEdit from "./sellPriceTypesEdit/sellPriceTypesEdit";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import * as actions from "../../../../../redux/actions/Sales/sellPriceBase/sellPriceTypes/sellPriceTypes";
import {
  FormatColumnDHSTable,
  result,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
import _ from "lodash";
import moment from "moment";

const objectColumn = {
  MA_CTY: { name: "Mã công ty", width: 100 },
  CODE_TYPE: { name: "Loại giá", width: 200 },
  DESCRIPTION: { name: "Mô tả", width: 300 },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function SellPriceTypes(props) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const SellPriceTypeState = useSelector(
    (state) => state.SellPriceTypesReducer
  );
  const [customColumns, setCustomColumns] = useState();
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListSellPriceType({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    toUpperPropertyNameByArray(SellPriceTypeState.listSellPriceType);
    setTimeout(() => {
      setData(SellPriceTypeState.listSellPriceType);
      setFullData(SellPriceTypeState.listSellPriceType);
      setLoading(false);
    }, 200);
  }, [SellPriceTypeState.listSellPriceType]);

  useEffect(() => {
    if (SellPriceTypeState.checkDelete) {
      dispatch(actions.getListSellPriceType({ ma_cty }));
      dispatch(actions.clearCache());
      openNotificationWithIcon("success", "Xóa thành công !");
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [SellPriceTypeState.checkDelete]);

  const handleOpenDrawer = () => {
    dispatch(actions.clearCache());
    setIsOpenCreate(true);
  };

  const handleDelete = (params) => {
    dispatch(actions.deleteSellPriceType(params));
    setLoading(true);
  };

  const checkVisible = (params) => {
    setIsOpenCreate(params);
    setIsOpenDetail(params);
    setIsOpenEdit(params);
  };

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };

  const handleEdit = (params) => {
    setIsOpenEdit(true);
  };
  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Loại giá bán"
          onBack={() => props.history.push("/Sale")}
        />
        <ToolBar
          setStateOpen={handleOpenDrawer}
          setDelete={handleDelete}
          setEdit={handleEdit}
          Master={isMaster}
        />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListSellPriceType({ ma_cty }));
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="CODE_TYPE"
          scroll={{ y: 500, x: 600 }}
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setIsOpenDetail(true);
          }}
        />
        {isOpenDetail ? (
          <SellPriceTypesDetail
            isVisible={isOpenDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
        {isOpenCreate ? (
          <SellPriceTypesCreate
            isVisible={isOpenCreate}
            checkVisible={checkVisible}
          />
        ) : (
          <></>
        )}
        {isOpenEdit ? (
          <SellPriceTypesEdit
            isVisible={isOpenEdit}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
