import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, PageHeader } from "antd";
import ToolBar from "../../../../common/DHSControl/DHSButton/toolBar";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import ItemDetail from "./itemDetail/itemDetail";
import SO0Create from "../quotation/so0Create/so0Create";
import * as actions from "../../../../redux/actions/Category/Item/index";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import _ from "lodash";
const objectColumn = {
  anh: { name: "Ảnh", width: 50, formatImage: true },
  ma_vt: { name: "Mã vật tư", width: 100 },
  ten_vt: { name: "Tên vật tư", width: 300 },
  dvt: { name: "Đơn vị tính", width: 100 },
  sku: { name: "SKU", width: 300 },
  sell_price: { name: "Giá bán", width: 100 },
  purchase_price: { name: "Giá mua", width: 100 },
};

const loadColumnDHSTable = (customColumns, setCustomColumns) => {
  if (_.isEmpty(customColumns)) {
    FormatColumnDHSTable(objectColumn);
    setCustomColumns(result);
  }
};

export default function SearchItem(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const ItemState = useSelector((state) => state.ItemReducer);
  const [customColumns, setCustomColumns] = useState();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isMaster, setMaster] = useState({});
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [listSttRec, setListSttRec] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.getListItem({ ma_cty }));
    loadColumnDHSTable(customColumns, setCustomColumns);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setData(ItemState.listItem);
      setFullData(ItemState.listItem);
      setLoading(false);
    }, 200);
  }, [ItemState.listItem]);

  const getDataFilter = (params) => {
    setData(params);
  };

  const getDataRow = (params) => {
    setMaster(params);
  };
  const checkVisible = () => {
    setIsOpenDetail(false);
    setIsOpenCreate(false);
  };

  const multiSelection = async (params) => {
    if (!_.isEmpty(params)) {
      params.map((item) => {
        item.STT_REC0 = Math.random().toString(36).substr(2, 9).toUpperCase();
        item.SO_LUONG = 1;
        item.GIA_NT2 = 0;
      });
      setListSttRec(params);
    } else {
      setListSttRec([]);
    }
  };
  const setCreateQU = () => {
    setIsOpenCreate(true);
  };
  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title="Truy vấn"
          onBack={() => history.push("/Sale")}
        />
        <ToolBar setCreateQU={setCreateQU} Master={listSttRec} />
        <DHSHanleFilter
          getDataFilter={getDataFilter}
          dataFilter={fullData}
          reload={() => {
            dispatch(actions.getListItem({ ma_cty }));
          }}
        />
        <DHSTable
          data={data}
          customColumns={customColumns}
          rowKey="ma_vt"
          scroll={{ y: 500, x: 600 }}
          getDataRow={getDataRow}
          doubleClickRow={() => {
            setIsOpenDetail(true);
          }}
          multiSelection={multiSelection}
        />
        {isOpenDetail ? (
          <ItemDetail
            isVisible={isOpenDetail}
            checkVisible={checkVisible}
            Master={isMaster}
          />
        ) : (
          <></>
        )}
        {isOpenCreate ? (
          <SO0Create
            isVisible={isOpenCreate}
            checkVisible={checkVisible}
            history={history}
            lstItemSelectedFromSearch={listSttRec}
          />
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
}
