import * as types from "../../../constants/ActionTypes";

var initialState = {
  dataSalesByDay: [],
  dsSalesbyMonth: [],
  dsSalesbyQuater: [],
  dsSalesbyCn: [],
  dsSalesbyEmployee: [],
  dsTop10: [],
  dsCompare: [],
  dsSalesToday: [],
};

var SalesDBReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SALES_BY_DAY:
      return {
        ...state,
        dataSalesByDay: action.payload,
      };
    case types.FETCH_SALES_BY_MONTH:
      return {
        ...state,
        dsSalesbyMonth: action.payload.dsSalesbyMonth,
        dsSalesbyQuater: action.payload.dsSalesbyQuater,
        dsSalesbyCn: action.payload.dsSalesbyCn,
        dsSalesbyEmployee: action.payload.dsSalesbyEmployee,
        dsTop10: action.payload.dsTop10,
        dsCompare: action.payload.dsCompare,
      };
    case types.SO_GET_SALES_TODAY:
      return {
        ...state,
        dsSalesToday: action.payload.dsSales,
      };
    default:
      return state;
  }
};

export default SalesDBReducer;
