import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListPurchaseOrder = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch01/ListPurchaseOrder", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHPurchaseOrder = (detail) => (dispatch, getState) => {
  callApi("api/poVch01/GetPHPurchaseOrder", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTPurchaseOrder = (detail) => (dispatch, getState) => {
  callApi("api/poVch01/GetCTPurchaseOrder", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch01/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getCustomer = (customer) => (dispatch, getState) => {
  callApi("api/poVch01/GetCustomer", "POST", customer)
    .then((res) =>
      dispatch({
        type: types.GET_CUSTOMER_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/poVch01/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListStatus = () => (dispatch, getState) => {
  callApi("api/poVch01/ListStatus", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_STATUS_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/poVch01/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewPurchaseOrder = (quotation) => (dispatch, getState) => {
  callApi("api/poVch01/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteAPurchaseOrder = (master) => (dispatch, getState) => {
  callApi("api/poVch01/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updatePurchaseOrder = (so1) => (dispatch, getState) => {
  callApi("api/poVch01/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_PO1,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_PO1,
  };
};

export const filt = (query) => (dispatch, getState) => {
  callApi("api/poVch01/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_PO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
