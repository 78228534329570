import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListCashReceipt = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch01/ListCashReceipt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHCashReceipt = (detail) => (dispatch, getState) => {
  callApi("api/caVch01/GetPHCashReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTCashReceipt = (detail) => (dispatch, getState) => {
  callApi("api/caVch01/GetCTCashReceipt", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch01/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/caVch01/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewCashReceipt = (query) => (dispatch, getState) => {
  callApi("api/caVch01/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteACashReceipt = (master) => (dispatch, getState) => {
  callApi("api/caVch01/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_CA1,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updateCashReceipt = (query) => (dispatch, getState) => {
  callApi("api/caVch01/UpdateCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/caVch01/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/caVch01/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_CA1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_CA1,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_CA1,
  };
};
