import React, { useState, useEffect } from "react";
import SellPriceForm from "../sellPriceForm/sellPriceForm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";

export default function SellPriceCreate(props) {
  const dispatch = useDispatch();
  const { isVisible, checkVisible, Master } = props;
  const MA_CTY = useSelector((state) => state.LoginReducer.ma_cty);

  const checkEdit = useSelector((state) => state.SellPriceReducer.checkEdit);

  useEffect(() => {
    if (checkEdit) {
      openNotificationWithIcon("success", "Sửa giá bán thành công !");
      dispatch(actions.clearCache());
      dispatch(actions.getListSellPrice({ MA_CTY }));
      checkVisible(false);
    }
  }, [checkEdit]);

  const handleActions = (params) => {
    dispatch(actions.updateSellPrice(params));
  };

  return (
    <SellPriceForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      isEdit={true}
      Master={Master}
      handleActions={handleActions}
      title="Sửa giá bán"
    />
  );
}
