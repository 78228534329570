import React, { useState } from "react";
import { Collapse, Row, Col, Form } from "antd";
import { useSelector } from "react-redux";
import {
  DHSInput,
  DHSDatePicker,
  DHSSelectOpt,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import moment from "moment";
const { Panel } = Collapse;

export default function General(props) {
  const { Master, isEdit } = props;
  const [isRender, setRender] = useState(false);
  const Lists = useSelector((state) => state.DS1Reducer.lists);

  const hanleValueChange = (params) => {
    Master[params.name] = params.value;
    setRender(!isRender);
  };
  return (
    <div className="ds1-general">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"} ghost>
        <Panel header="Thông tin chung" key="1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12} xs={12} xl={12}>
              <Form layout="vertical">
                <DHSInput
                  name="TEN_CTCK"
                  label="Tên chương trình"
                  value={Master.TEN_CTCK}
                  getValueChange={hanleValueChange}
                  disable={isEdit}
                />
                <DHSDatePicker
                  label="Ngày hiệu lực"
                  nameState="NGAY_HL"
                  value={moment(Master.NGAY_HL).add(7, "hours")}
                  getValueChange={hanleValueChange}
                  disable={isEdit}
                />
                <DHSSelectOpt
                  label="Mã ngoại tệ"
                  name="MA_NT"
                  array={Lists.dm_nt}
                  valueOpt="MA_NT"
                  valueRender="TEN_NT"
                  defaultValue={Master.MA_NT}
                  onChange={(value) =>
                    hanleValueChange({ name: "MA_NT", value })
                  }
                  disable={isEdit}
                />
              </Form>
            </Col>
            <Col className="gutter-row" span={12} xs={12} xl={12}>
              <Form layout="vertical">
                <DHSDatePicker
                  label="Ngày lập"
                  nameState="NGAY_CT"
                  value={moment(Master.NGAY_CT).add(7, "hours")}
                  getValueChange={hanleValueChange}
                  disable={isEdit}
                />
                <DHSDatePicker
                  label="Ngày hết hạn"
                  nameState="NGAY_HH"
                  value={moment(Master.NGAY_HH).add(7, "hours")}
                  getValueChange={hanleValueChange}
                  disable={isEdit}
                />
                <DHSInput
                  name="DIEN_GIAI"
                  label="Diễn giải"
                  value={Master.DIEN_GIAI}
                  getValueChange={hanleValueChange}
                  disable={isEdit}
                />
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
}
