import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../../redux/actions/CustomerServices/crmCustomerRequest/crmCustomerRequest";
import CrmCustomerForm from "../crmCustomerRequest";
import { openNotificationWithIcon } from "../../../../../../common/notification/notification";

export default class DetailCSR extends Component {
  render() {
    const { isVisible, IDCUSTOMER, Master } = this.props;
    return (
      <div>
        <CrmCustomerForm
          isVisible={isVisible}
          IDCUSTOMER={IDCUSTOMER}
          checkVisible={this.checkVisible}
          Master={Master}
          disabled={true}
          rules={false}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
