import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSInputTextArea,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import { ReloadOutlined } from "@ant-design/icons";
import * as actions from "../../../../../redux/actions/Sales/quotation/quotation";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import PropTypes from "prop-types";
import moment from "moment";
import {
  toUpperPropertyName,
  toUpperPropertyNameByArray,
} from "../../../../controller/Format";
import { widthDrawer } from "../../index";
const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};

So0Create.propTypes = {
  _objectCreate: PropTypes.object,
  lstItemSelectedFromSearch: PropTypes.array,
};

So0Create.defaultProps = {
  _objectCreate: {
    IDCUSTOMER: "",
    MA_CTY: "",
    LUSER: "",
    CUSER: "",
    DIA_CHI: "",
    EMAIL: "",
    TEL: "",
    CODE_TYPE: "",
    MA_KH: "",
    NGUOI_GD: "",
    SO_CT: "",
    NGAY_LCT: moment(),
    NGAY_CT: moment(),
    NGAY_HH: moment(),
    TRANG_THAI: 0,
    MA_NT: "VND",
    TY_GIA: 1,
    GHI_CHU: "",
  },
  lstItemSelectedFromSearch: [],
};

export default function So0Create(props) {
  const dispatch = useDispatch();
  const {
    isVisible,
    checkVisible,
    _objectCreate,
    history,
    lstItemSelectedFromSearch,
  } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const userName = useSelector((state) => state.LoginReducer.userName);
  const SO0State = useSelector((state) => state.SO0Reducer);
  const [isLoading, setLoading] = useState(false);
  const [objCreate, setObjCreate] = useState(_objectCreate);
  useEffect(() => {
    dispatch(actions.getSoCt({ ma_cty }));
  }, []);

  useEffect(() => {
    setObjCreate({
      ...objCreate,
      TY_GIA: SO0State.exchangeRate == 0 ? 1 : SO0State.exchangeRate,
    });
  }, [SO0State.exchangeRate]);

  useEffect(() => {
    setLoading(false);
    if (SO0State.newItem === "-1") {
      openNotificationWithIcon("error", "Trùng số chứng từ !");
      dispatch(actions.clearSTT());
    } else if (_.isObject(SO0State.newItem)) {
      openNotificationWithIcon("success", "Thêm báo giá thành công !");
      checkVisible(false);
      toUpperPropertyName(SO0State.newItem);
      history.push("/SO0Edit", {
        Master: {
          ...SO0State.newItem,
          TEN_KH: objCreate.TEN_KH,
          CODE_TYPE: objCreate.CODE_TYPE,
        },
        listItem: toUpperPropertyNameByArray(lstItemSelectedFromSearch),
      });
    }
    return () => {
      dispatch(actions.clearCache());
    };
  }, [SO0State.newItem]);

  const handleCancel = () => {
    dispatch(actions.clearCache());
    checkVisible(false);
  };

  const handleOk = () => {
    const Quotation = {
      modelM: {
        ...objCreate,
        SO_CT: SO0State.soCt,
        MA_CTY: ma_cty,
        LUSER: userName,
        CUSER: userName,
      },
      arrayCt: [],
    };
    setLoading(true);
    dispatch(actions.addNewQuotation(Quotation));
  };

  const handleChangeText = (params) => {
    setObjCreate({ ...objCreate, [params.name]: params.value });
  };

  const handleKHChange = (params) => {
    let customer = SO0State.listCustomer.find((x) => x.idcustomer === params);
    setObjCreate({
      ...objCreate,
      DIA_CHI: customer.dia_chi,
      IDCUSTOMER: params,
      EMAIL: customer.email,
      TEL: customer.tel,
      NGUOI_GD: customer.nguoi_gd,
      MA_KH: customer.ma_kh,
      TEN_KH: customer.ten_kh,
      CODE_TYPE: customer.code_type,
    });
  };

  const handleChangeCurrency = (params) => {
    setObjCreate({ ...objCreate, MA_NT: params });
    dispatch(
      actions.getExchangeRate({
        ma_cty,
        ma_nt: params,
        ngay_ct: objCreate.NGAY_LCT,
      })
    );
  };
  const reloadSoCt = () => {
    dispatch(actions.getSoCt({ ma_cty }));
  };
  return (
    <Drawer
      title="Thêm mới báo giá"
      width={widthDrawer}
      onClose={handleCancel}
      visible={isVisible}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: "right",
            marginRight: 10,
          }}
        >
          <Button
            type="primary"
            form="submit-form"
            key="submit"
            htmlType="submit"
            style={{ marginRight: 8 }}
          >
            Lưu
          </Button>
          <Button onClick={handleCancel}>Hủy</Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Collapse
          defaultActiveKey={["1", "2"]}
          ghost
          className="so0-create"
          expandIconPosition="right"
        >
          <Panel header="Thông tin khách hàng" key="1">
            <Form
              id="submit-form"
              onFinish={handleOk}
              layout="vertical"
              validateMessages={validateMessages}
            >
              <DHSSelectOpt
                label="Khách hàng"
                name="IDCUSTOMER"
                array={SO0State.listCustomer}
                valueOpt={"idcustomer"}
                valueRender={"ten_kh"}
                rules={true}
                defaultValue={
                  objCreate.IDCUSTOMER === "" ? undefined : objCreate.IDCUSTOMER
                }
                onChange={handleKHChange}
              />
              <DHSInput
                label="Địa chỉ"
                name="DIA_CHI"
                value={objCreate.DIA_CHI}
                getValueChange={handleChangeText}
              />
              <DHSInput
                label="Email"
                name="EMAIL"
                value={objCreate.EMAIL}
                getValueChange={handleChangeText}
              />
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSInput
                    label="Người liên hệ"
                    name="NGUOI_GD"
                    value={objCreate.NGUOI_GD}
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Số điện thoại"
                    name="TEL"
                    value={objCreate.TEL}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
            </Form>
          </Panel>
          <Panel header="Thông tin báo giá" key="2">
            <Form layout="vertical">
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSInput
                    label="Số báo giá"
                    name="SO_CT"
                    disable={true}
                    value={SO0State.soCt}
                    addonAfter={<ReloadOutlined onClick={reloadSoCt} />}
                  />
                </Col>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày chứng từ"
                    value={objCreate.NGAY_CT}
                    nameState="NGAY_CT"
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Trạng thái"
                    array={SO0State.listStatus}
                    valueOpt={"trang_thai"}
                    valueRender={"mo_ta"}
                    defaultValue={"Chờ duyệt"}
                    disable={true}
                  />
                </Col>
                <Col span={12}>
                  <DHSDatePicker
                    label="Ngày hết hạn"
                    value={objCreate.NGAY_HH}
                    nameState="NGAY_HH"
                    getValueChange={handleChangeText}
                  />
                </Col>
                <Col span={12} offset={12}>
                  <DHSDatePicker
                    label="Ngày lập"
                    value={objCreate.NGAY_LCT}
                    nameState="NGAY_LCT"
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <DHSSelectOpt
                    label="Loại tiền tệ"
                    array={SO0State.listCurrency}
                    valueOpt={"ma_nt"}
                    valueRender={"ma_nt"}
                    defaultValue={objCreate.MA_NT}
                    onChange={handleChangeCurrency}
                  />
                </Col>
                <Col span={12}>
                  <DHSInput
                    label="Tỷ giá"
                    name="TY_GIA"
                    disable={objCreate.MA_NT === "VND" ? true : false}
                    value={objCreate.TY_GIA}
                    getValueChange={handleChangeText}
                  />
                </Col>
              </Row>
              <DHSInputTextArea
                label="Ghi Chú"
                name="GHI_CHU"
                value={objCreate.GHI_CHU}
                getValueChange={handleChangeText}
              />
            </Form>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
}
