import React, { Component } from "react";
import CustomerForm from "../customerForm/customerForm";
export default class CustomerDetail extends Component {
  render() {
    const { isVisible, Master } = this.props;
    return (
      <div>
        <CustomerForm
          isVisible={isVisible}
          checkVisible={this.checkVisible}
          title="Thông tin khách hàng"
          Master={Master}
          noneEdit={true}
        />
      </div>
    );
  }
  checkVisible = (params) => {
    this.props.checkVisible(params);
  };
}
