import React, { Component } from "react";
import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  Title,
  ZoomAndPan,
  ValueAxis,
  ScrollBar,
  Aggregation,
  TickInterval,
  Label,
  Tooltip,
} from "devextreme-react/chart";
import RangeSelector, {
  Size,
  Margin,
  Scale,
  // Chart as RsChart,
  // SeriesTemplate as RsSeriesTemplate,
  // CommonSeriesSettings as RsCommonSeriesSettings,
  // CommonAxisSettings as RsCommonAxisSettings,
  // ValueAxis as RsValueAxis,
  // TickInterval as RsTickInterval,
  // Label as RsLabel,
} from "devextreme-react/range-selector";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/Manufacturing/Manufacturing";
class TimeLineChart extends Component {
  state = {
    visualRange: {
      startValue: this.props.time_from,
      endValue: this.props.time_to,
    },
  };
  updateVisualRange = (e) => {
    this.setState({ visualRange: e.value });
  };
  handleChange = (e) => {
    if (e.name === "valueAxis") {
      this.setState({ visualRange: e.value });
    }
  };
  customizeSeries = (valueFromNameField) => {
    const { listMachineActionState } = this.props;
    var color = "";
    if (listMachineActionState.length > 0) {
      listMachineActionState.map((item, index) => {
        if (item.DESCRIPTION === valueFromNameField) {
          color = item.BACKCOLOR;
        }
      });
    }
    return color !== "" ? { color: color } : {};
  };
  render() {
    const {
      dataSource,
      machineName,
      time_from,
      time_to,
      customizeTooltip,
    } = this.props;
    return (
      <div>
        <Chart
          id="chart"
          dataSource={dataSource}
          barGroupPadding={0.2}
          rotated={true}
          onOptionChanged={this.handleChange}
        >
          <Title
            text="Machine Timeline"
            subtitle={machineName}
            font={{ color: "#1890ff" }}
          />
          <CommonSeriesSettings
            type="rangeBar"
            argumentField="machine_code"
            rangeValue1Field="time_from"
            rangeValue2Field="time_to"
            barOverlapGroup="machine_code"
            ignoreEmptyPoints={true}
          >
            <Aggregation enabled={true} />
          </CommonSeriesSettings>
          <ArgumentAxis>
            <Label visible={false}></Label>
          </ArgumentAxis>
          <Legend verticalAlignment="bottom" horizontalAlignment="center">
            <Title text="Trạng thái" />
          </Legend>
          <ValueAxis valueType="datetime" visualRange={this.state.visualRange}>
            <TickInterval milliseconds={5} />
          </ValueAxis>
          <SeriesTemplate
            nameField="state_description"
            customizeSeries={this.customizeSeries}
          />
          <ZoomAndPan
            valueAxis="both"
            argumentAxis="none"
            allowTouchGestures={true}
          />
          <ScrollBar visible={false} />
          <Animation enabled={false} />
          <Tooltip
            enabled={true}
            location="edge"
            customizeTooltip={customizeTooltip}
          />
        </Chart>
        <RangeSelector
          id="range-selector"
          dataSource={dataSource}
          onValueChanged={this.updateVisualRange}
          value={this.state.visualRange}
        >
          <Size height={120} />
          <Margin left={10} />
          <Scale
            startValue={time_from}
            endValue={time_to}
            valueType="datetime"
          />
        </RangeSelector>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listMachineActionState: state.ManufacturingReducer.listMachineActionState,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    // GetMachinesActionState: (query) => {
    //   dispatch(actions.GetMachinesActionState(query));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeLineChart);
