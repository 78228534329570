import SellPriceTypesForm from "../sellPriceTypesForm/sellPriceTypesForm";

export default function Ds1Detail(props) {
  const { isVisible, checkVisible, Master } = props;
  return (
    <SellPriceTypesForm
      isVisible={isVisible}
      checkVisible={checkVisible}
      Master={Master}
      isEdit={false}
      title="Chi tiết loại giá bán"
    />
  );
}
