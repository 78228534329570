import React, { Component } from "react";
import DHSTable from "../../../../common/DHSControl/DHSTable/DHSTable";
import DHSHanleFilter from "../../../../common/DHSControl/DHSHandleFilter/DHSHanleFilter";
import { PageHeader, Button, Space, Spin, Popconfirm, Select } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../../../../redux/actions/CustomerServices/crmCustomer/crmCustomer";
import { openNotificationWithIcon } from "../../../../common/notification/notification";
import "./index.css";
import { FormatColumnDHSTable, result } from "../../../controller/Format";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import CrmCustomerCreate from "./crmCustomerCreate/crmCustomerCreate";
import CrmCustomerEdit from "./crmCustomerEdit/crmCustomerEdit";
import CrmCustomerDetail from "./crmCustomerDetail/crmCustomerDetail";
import CrmCustomerRequest from "./crmCustomerRequest/crmCustomerRequest";
const { Option } = Select;

const objectColumn = {
  MA_KH: { name: "Mã khách hàng", width: 100 },
  TEN_KH: { name: "Tên khách hàng", width: 300 },
  MA_SO_THUE: { name: "Mã số thuế", width: 100 },
  DIA_CHI: { name: "Địa chỉ", width: 300 },
  TEL: { name: "SĐT", width: 100 },
  EMAIL: { name: "Email", width: 100 },
};
class CrmCustomer extends Component {
  state = {
    loading: false,
    data: [],
    fullData: [],
    customColumns: [],
    rowId: "",
    IDCUSTOMER: "",
    openDrawerCreate: false,
    openDrawerEdit: false,
    openDrawerDetail: false,
    openDrawerRequest: false,
    Master: {},
  };
  componentDidMount = () => {
    this.loadListCustomer();
  };
  componentDidUpdate = (prevProps) => {
    const { listCrmCustomer, checkDelete, clearCache } = this.props;
    if (!_.isEqual(listCrmCustomer, prevProps.listCrmCustomer)) {
      if (!_.isNull(listCrmCustomer)) {
        this.setState({
          data: listCrmCustomer,
          fullData: listCrmCustomer,
          loading: false,
        });
      }
    }
    if (checkDelete !== prevProps.checkDelete && checkDelete) {
      this.setState({ loading: false, rowId: "", IDCUSTOMER: "", Master: {} });
      this.loadListCustomer();
      clearCache();
      openNotificationWithIcon("success", "Xóa thành công !");
    }
  };
  confirmDelete(IDCUSTOMER) {
    this.setState({ loading: true });
    this.props.deleteCrmCustomer({
      ma_cty: this.props.ma_cty,
      IDCUSTOMER: IDCUSTOMER,
    });
  }
  getDataFilter = (params) => {
    this.setState({ data: params });
  };
  editRow = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.props.history.push("/CrmCustomerEdit", { Master: Master });
    } else {
      openNotificationWithIcon("error", "Vui lòng chọn khách hàng cần sửa !");
    }
  };
  addRequest = () => {
    const { Master } = this.state;
    if (!_.isEmpty(Master)) {
      this.setState({ openDrawerRequest: true });
    } else {
      openNotificationWithIcon(
        "error",
        "Vui lòng chọn khách hàng cần yêu cầu !"
      );
    }
  };
  handleFilterChange = async (value) => {
    await this.setState({ moduleID: value });
    await this.loadListCustomer();
  };
  render() {
    const {
      loading,
      data,
      customColumns,
      IDCUSTOMER,
      openDrawerCreate,
      openDrawerEdit,
      Master,
      openDrawerDetail,
      openDrawerRequest,
      fullData,
    } = this.state;
    return (
      <div className="Customer" style={{ height: "100%" }}>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            title="Khai báo khách hàng"
            onBack={() => this.props.history.push("/CustomerService")}
          />
          <Space style={{ marginBottom: 16, marginTop: 10 }}>
            <Button
              onClick={() => this.setState({ openDrawerCreate: true })}
              icon={<PlusOutlined />}
            >
              Thêm
            </Button>
            <Button onClick={this.editRow} icon={<EditOutlined />}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn muốn xóa khách hàng ?"
              onConfirm={() => this.confirmDelete(IDCUSTOMER)}
              okText="Đồng ý"
              cancelText="Hủy"
              disabled={IDCUSTOMER === "" ? true : false}
            >
              <Button
                onClick={() => {
                  if (IDCUSTOMER === "") {
                    openNotificationWithIcon(
                      "error",
                      "Vui lòng chọn khách hàng cần xóa !"
                    );
                  }
                }}
                icon={<DeleteOutlined />}
              >
                Xóa
              </Button>
            </Popconfirm>
          </Space>
          <DHSHanleFilter
            getDataFilter={this.getDataFilter}
            dataFilter={fullData}
            reload={() => {
              this.loadListCustomer();
            }}
          />
          <DHSTable
            data={data}
            customColumns={customColumns}
            rowKey="MA_KH"
            getDataRow={this.getDataRow}
            doubleClickRow={() => {
              this.setState({ openDrawerDetail: true });
            }}
          />
          {openDrawerCreate ? (
            <CrmCustomerCreate
              isVisible={openDrawerCreate}
              checkVisible={this.checkVisible}
            />
          ) : (
            <></>
          )}
          {openDrawerEdit ? (
            <CrmCustomerEdit
              isVisible={openDrawerEdit}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerDetail ? (
            <CrmCustomerDetail
              isVisible={openDrawerDetail}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
          {openDrawerRequest ? (
            <CrmCustomerRequest
              isVisible={openDrawerRequest}
              checkVisible={this.checkVisible}
              Master={Master}
            />
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
  checkVisible = (params) => {
    this.setState({
      openDrawerCreate: params.isVisible,
      loading: params.isVisible,
      openDrawerDetail: params.isVisible,
      openDrawerEdit: params.isVisible,
      openDrawerFilter: params.isVisible,
      openDrawerRequest: params.isVisible,
    });
    params.editSuccess ? this.loadListCustomer() : <></>;
    params.editSuccess ? (
      this.setState({ rowId: "", IDCUSTOMER: "", Master: {} })
    ) : (
      <></>
    );
  };
  getDataRow = (params) => {
    this.setState({ Master: params, IDCUSTOMER: params.IDCUSTOMER });
  };

  loadListCustomer = () => {
    const { clearCache, getLists, getListCrmCustomer, ma_cty } = this.props;
    FormatColumnDHSTable(objectColumn);
    this.setState({ customColumns: result });
    getLists({ modelM: { ma_cty: this.props.ma_cty } });
    clearCache();
    getListCrmCustomer({ ma_cty });
  };
}
const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    listCrmCustomer: state.CrmCustomerReducer.listCrmCustomer,
    checkDelete: state.CrmCustomerReducer.checkDelete,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getLists: (ma_cty) => {
      dispatch(actions.getLists(ma_cty));
    },
    getListCrmCustomer: (ma_cty) => {
      dispatch(actions.getListCrmCustomer(ma_cty));
    },
    deleteCrmCustomer: (query) => {
      dispatch(actions.deleteCrmCustomer(query));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CrmCustomer);
