import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Drawer, Button, Row, Col } from "antd";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
} from "../../../../../../common/DHSControl/DHSForm/DHSFormItem";
import * as actions from "../../../../../../redux/actions/Sales/reports/reports";
import { periodData } from "../../../../../controller/DataSample";
import {
  onPeriodChange,
  toUpperPropertyNameByArray,
} from "../../../../../controller/Format";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.css";
const validateMessages = {
  required: "Trường này là bắt buộc",
};
Filter.propTypes = {
  _objectFilter: PropTypes.object,
};

Filter.defaultProps = {
  _objectFilter: {
    MA_CTY: "",
    MA_CT: "",
    TITLE: "BẢNG KÊ ĐƠN ĐẶT HÀNG",
    SITE_ID: "",
    IDCUSTOMER: "",
    MA_NHKH: "",
    IDITEM: "",
    MA_PLKH1: "",
    MA_PLKH2: "",
    MA_PLKH3: "",
    MA_PLVT1: "",
    MA_PLVT2: "",
    MA_PLVT3: "",
    IDGROUP: "",
    MA_HD: "",
    MA_NHHD: "",
    MA_KHO: "",
    MA_BP: "",
    MA_VITRI: "",
    MA_LO: "",
    MA_TT: "",
    MA_HTTT: "",
    MA_NT: "",
    PERIOD: ("0" + (moment().month() + 1)).slice(-2),
    FORMDATE: moment().startOf("month"),
    TODATE: moment().endOf("month"),
    MA_NVKD: "",
  },
};
export default function Filter(props) {
  const dispatch = useDispatch();
  const { checkVisible, isVisible, _objectFilter, approvalScr } = props;
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const listDm = useSelector((state) => state.SOReportsReducer.listDm);
  const [objFilter, setObjFilter] = useState(_objectFilter);
  const listCompany = useSelector((state) => state.LoginReducer.listCompany);

  const handleCancel = (e) => {
    checkVisible(false);
  };
  const handleOk = () => {
    dispatch(
      actions.GetRptSOBK01({
        ...objFilter,
        MA_CT: "SO",
        NGAY1: moment(objFilter.FORMDATE).add(7, "hours"),
        NGAY2: moment(objFilter.TODATE).add(7, "hours"),
      })
    );
    checkVisible(false);
  };
  const onHandleFilter = (params) => {
    const result = onPeriodChange(params);
    setObjFilter({
      ...objFilter,
      PERIOD: result.period,
      FORMDATE: result.formDate,
      TODATE: result.toDate,
    });
  };
  const handleChangeText = (e) => {
    setObjFilter({
      ...objFilter,
      [e.name]: e.value,
    });
  };
  const handleChange = (value, name) => {
    setObjFilter({
      ...objFilter,
      [name]: value,
    });
  };
  return (
    <div>
      <Drawer
        title="Điều kiện lọc"
        width={600}
        onClose={handleCancel}
        visible={isVisible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Hủy
            </Button>
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
            >
              Thực hiện
            </Button>
          </div>
        }
      >
        <Form
          id="submit-form"
          onFinish={handleOk}
          layout="vertical"
          validateMessages={validateMessages}
          className="rpBk01-Filter"
        >
          <DHSSelectOpt
            label="Công ty"
            name="MA_CTY"
            array={toUpperPropertyNameByArray(listCompany)}
            valueOpt={"MA_CTY"}
            valueRender={"TEN_CTY"}
            onChange={(value) => handleChange(value, "MA_CTY")}
            defaultOpen={true}
            rules={true}
            defaultValue={
              objFilter.MA_CTY === "" ? undefined : objFilter.MA_CTY
            }
          />
          <DHSSelectOpt
            label="Mẫu báo cáo"
            name="MA_CT"
            array={toUpperPropertyNameByArray(listDm.mau_rp)}
            valueOpt={"MA_MAU"}
            valueRender={"TEN_MAU"}
            onChange={(value) => handleChange(value, "MA_CT")}
          />
          <DHSInput
            label="Tiêu đề"
            name="TITLE"
            value={objFilter.TITLE}
            getValueChange={handleChangeText}
          />
          <DHSSelectOpt
            label="Kì báo cáo"
            name="PERIOD"
            array={periodData}
            valueOpt={"value"}
            valueRender={"name"}
            defaultValue={objFilter.PERIOD}
            onChange={onHandleFilter}
          />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSDatePicker
                label="Từ ngày"
                value={objFilter.FORMDATE}
                nameState="FORMDATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
            <Col span={12}>
              <DHSDatePicker
                label="Đến ngày"
                value={objFilter.TODATE}
                nameState="TODATE"
                disable={objFilter.PERIOD === "formTo" ? false : true}
                getValueChange={handleChangeText}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Chi nhánh"
                name="SITE_ID"
                array={listDm.dm_site}
                valueOpt={"SITE_ID"}
                valueRender={"SITE_NAME"}
                onChange={(value) => handleChange(value, "SITE_ID")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Khách hàng"
                name="IDCUSTOMER"
                array={listDm.dm_kh}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                onChange={(value) => handleChange(value, "IDCUSTOMER")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Nhóm khách hàng"
                name="MA_NHKH"
                array={listDm.dm_nhkh}
                valueOpt={"MA_NHKH"}
                valueRender={"TEN_NHKH"}
                onChange={(value) => handleChange(value, "MA_NHKH")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Vật tư"
                name="IDITEM"
                array={listDm.dm_vattu}
                valueOpt={"IDITEM"}
                valueRender={"TEN_VT"}
                onChange={(value) => handleChange(value, "IDITEM")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Phân loại KH 1"
                name="MA_PLKH1"
                array={listDm.dm_plncc1}
                valueOpt={"MA_PLKH"}
                valueRender={"TEN_PLKH"}
                onChange={(value) => handleChange(value, "MA_PLKH1")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Nhóm vật tư"
                name="IDGROUP"
                array={listDm.dm_nhomvt}
                valueOpt={"IDGROUP"}
                valueRender={"TEN_NHVT"}
                onChange={(value) => handleChange(value, "IDGROUP")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Phân loại KH 2"
                name="MA_PLKH2"
                array={listDm.dm_plncc2}
                valueOpt={"MA_PLKH"}
                valueRender={"TEN_PLKH"}
                onChange={(value) => handleChange(value, "MA_PLKH2")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Phân loại vật tư 1"
                name="MA_PLVT1"
                array={listDm.dm_plvt1}
                valueOpt={"MA_PLVT"}
                valueRender={"TEN_PLVT"}
                onChange={(value) => handleChange(value, "MA_PLVT1")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Phân loại KH 3"
                name="MA_PLKH3"
                array={listDm.dm_plncc3}
                valueOpt={"MA_PLKH"}
                valueRender={"TEN_PLKH"}
                onChange={(value) => handleChange(value, "MA_PLKH3")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Phân loại vật tư 2"
                name="MA_PLVT2"
                array={listDm.dm_plvt2}
                valueOpt={"MA_PLVT"}
                valueRender={"TEN_PLVT"}
                onChange={(value) => handleChange(value, "MA_PLVT2")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Hợp đồng"
                name="MA_HD"
                array={listDm.dm_hopdong_mua}
                valueOpt={"MA_HD"}
                valueRender={"TEN_HD"}
                onChange={(value) => handleChange(value, "MA_HD")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Phân loại vật tư 3"
                name="MA_PLVT3"
                array={listDm.dm_plvt3}
                valueOpt={"MA_PLVT"}
                valueRender={"TEN_PLVT"}
                onChange={(value) => handleChange(value, "MA_PLVT3")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Nhóm hợp đồng"
                name="MA_NHHD"
                array={listDm.dm_nhhd}
                valueOpt={"MA_NHHD"}
                valueRender={"TEN_NHHD"}
                onChange={(value) => handleChange(value, "MA_NHHD")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Kho"
                name="MA_KHO"
                array={listDm.dm_kho}
                valueOpt={"MA_KHO"}
                valueRender={"TEN_KHO"}
                onChange={(value) => handleChange(value, "MA_KHO")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Bộ phận"
                name="MA_BP"
                array={listDm.dm_bp}
                valueOpt={"MA_BP"}
                valueRender={"TEN_BP"}
                onChange={(value) => handleChange(value, "MA_BP")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Vị trí"
                name="MA_VITRI"
                array={listDm.dm_vitri}
                valueOpt={"MA_VITRI"}
                valueRender={"TEN_VITRI"}
                onChange={(value) => handleChange(value, "MA_VITRI")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Nhân viên"
                name="MA_NVKD"
                array={listDm.dm_nv}
                valueOpt={"IDCUSTOMER"}
                valueRender={"TEN_KH"}
                onChange={(value) => handleChange(value, "MA_NVKD")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Lô"
                name="MA_LO"
                array={listDm.dm_lo}
                valueOpt={"MA_LO"}
                valueRender={"TEN_LO"}
                onChange={(value) => handleChange(value, "MA_LO")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Điều khoản TT"
                name="MA_TT"
                array={listDm.dm_sodmtt}
                valueOpt={"MA_TT"}
                valueRender={"MO_TA"}
                onChange={(value) => handleChange(value, "MA_TT")}
              />
            </Col>
            <Col span={12}>
              <DHSSelectOpt
                label="Điều khoản thanh toán"
                name="Điều khoản thanh toán"
                array={listDm.dm_httt_so}
                valueOpt={"MA_HTTT"}
                valueRender={"TEN_HTTT"}
                onChange={(value) => handleChange(value, "MA_HTTT")}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DHSSelectOpt
                label="Mã ngoại tệ"
                name="MA_NT"
                array={listDm.dm_nt}
                valueOpt={"MA_NT"}
                valueRender={"TEN_NT"}
                onChange={(value) => handleChange(value, "MA_NT")}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
