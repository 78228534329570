import React from "react";
import Bar from "./Types/bar";
export default function DHSBar(props) {
  const { title, data } = props;
  return (
    <div className="DHSBar">
      <div className="h4">{title}</div>
      <div className="salesByDays">
        <Bar
          height={250}
          width={150}
          value={data.value}
          label={data.label}
          name="Doanh số"
          color="#2291C4"
        />
      </div>
    </div>
  );
}
