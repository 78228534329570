import React, { Component } from "react";
import { Collapse, Form, Spin, Drawer, Button, Row, Col } from "antd";
import "./index.css";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../../../../../redux/actions/Sales/packingSlip/index";
import { ReloadOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "../../../../../common/notification/notification";
import _ from "lodash";
import {
  DHSInput,
  DHSSelectOpt,
  DHSDatePicker,
  DHSInputTextArea,
} from "../../../../../common/DHSControl/DHSForm/DHSFormItem";
import ChooseSO1 from "./chooseSO1/chooseSO1";
import ListSO1 from "./listSO1/listSO1";
import { widthDrawer } from "../../index";
const { Panel } = Collapse;
const validateMessages = {
  required: "Trường này là bắt buộc",
};

class SO2Create extends Component {
  state = {
    resize: widthDrawer,
    visible: false,
    IDcustomer: "",
    ma_cty: this.props.ma_cty,
    luser: this.props.userName,
    cuser: this.props.userName,
    dia_chi: "",
    email: "",
    tel: "",
    ma_kh: "",
    nguoi_gd: "",
    chooseSO1: false,
    so_ct: this.props.soCt,
    ngay_lct: moment(),
    ngay_ct: moment(),
    trang_thai: 1,
    ma_nt: "VND",
    ty_gia: 1,
    ghi_chu: "",
    tl_ck: 0,
    ts_gtgt: 0,
    t_thue: 0,
    t_ck: 0,
    t_tt: 0,
    t_tt_nt: 0,
    loadingExchange: false,
    Master: {},
    listSO1: false,
    loading: false,
    code_type: "",
  };

  componentDidMount = () => {
    this.props.getSoCt({ ma_cty: this.props.ma_cty });
  };

  handleOk = (e) => {
    if (this.state.loading === false) {
      const PackingSlip = {
        modelM: this.state,
        arrayCt: this.props.lstCtChooseFromSO,
        arrayAddress: this.props.lstAddressChooseFromSO,
      };
      this.setState({ loading: true });
      this.props.addNewPackingSlip(PackingSlip);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { newItem, soCt } = this.props;
    if (!_.isEqual(newItem, prevProps.newItem)) {
      if (newItem === "-1") {
        openNotificationWithIcon("error", "Trùng số chứng từ !");
        this.setState({ loading: false });
        this.props.clearSTT();
      } else if (_.isObject(newItem)) {
        openNotificationWithIcon("success", "Thêm phiếu xuất thành công !");
        this.props.checkVisible(false);
        this.props.history.push("/soVch02Edit", {
          Master: { ...newItem, CODE_TYPE: this.state.code_type },
        });
      }
    }
    if (!_.isEqual(soCt, prevProps.soCt)) {
      this.setState({ so_ct: soCt });
    }
  };

  handleCancel = (e) => {
    this.props.checkVisible(false);
    this.props.clearCache();
  };

  onChangeCreateTime = (date, dateString, name) => {
    this.setState({ [name]: moment(dateString).format("L") });
  };

  handleChangeCurrency = (value) => {
    this.setState({ ma_nt: value, loadingExchange: true });
    this.props.getExchangeRate({
      ma_cty: this.props.ma_cty,
      ma_nt: value,
      ngay_ct: this.state.ngay_lct,
    });
    setTimeout(() => {
      value === "VND"
        ? this.setState({ ty_gia: 1, loadingExchange: false })
        : this.setState({
            ty_gia: this.props.exchangeRate == 0 ? 1 : this.props.exchangeRate,
            loadingExchange: false,
          });
    }, 500);
  };

  handleChangeText = (params) => {
    this.setState({
      [params.name]: params.value,
    });
  };

  disabledDate(current) {
    return current - 1 && current - 1 < moment().startOf("day");
  }

  handleKHChange = (params) => {
    this.props.listCustomer.filter(async (item) => {
      if (item.idcustomer === params) {
        await this.setState({
          IDcustomer: params,
          dia_chi: item.dia_chi,
          email: item.email,
          tel: item.tel,
          ma_kh: item.ma_kh,
          nguoi_gd: item.nguoi_gd,
          so_ct: this.props.soCt,
          code_type: item.code_type,
        });
      }
    });
  };
  reloadSoCt = () => {
    this.props.getSoCt({ ma_cty: this.props.ma_cty });
  };
  render() {
    const {
      isVisible,
      listCustomer,
      soCt,
      listStatus,
      listCurrency,
    } = this.props;
    const {
      dia_chi,
      email,
      tel,
      nguoi_gd,
      ty_gia,
      loadingExchange,
      ma_nt,
      ghi_chu,
      loading,
      chooseSO1,
      listSO1,
      resize,
      IDcustomer,
      ngay_lct,
      ngay_ct,
    } = this.state;
    return (
      <Drawer
        title="Thêm mới phiếu xuất"
        width={resize}
        onClose={this.handleCancel}
        visible={isVisible}
        maskClosable={false}
        footer={
          <div
            style={{
              textAlign: "right",
              marginRight: 10,
            }}
          >
            <Button
              type="primary"
              form="submit-form"
              key="submit"
              htmlType="submit"
              style={{ marginRight: 8 }}
            >
              Lưu
            </Button>
            <Button onClick={this.handleCancel}>Hủy</Button>
          </div>
        }
      >
        <Spin spinning={loading}>
          <Collapse
            defaultActiveKey={["0", "1", "2"]}
            ghost
            className="so2-create"
            expandIconPosition="right"
          >
            <Panel header="Thông tin tham chiếu" key="0">
              <Button
                type="primary"
                onClick={() => this.setState({ chooseSO1: true })}
              >
                Chọn đơn hàng
              </Button>
              {chooseSO1 ? (
                <ChooseSO1
                  isVisible={chooseSO1}
                  checkVisible={this.checkVisible}
                />
              ) : (
                <></>
              )}
              {listSO1 ? (
                <ListSO1
                  isVisible={listSO1}
                  checkVisible={this.checkVisibleListSO1}
                />
              ) : (
                <></>
              )}
            </Panel>
            <Panel header="Thông tin khách hàng" key="1">
              <Form
                id="submit-form"
                onFinish={this.handleOk}
                layout="vertical"
                validateMessages={validateMessages}
              >
                <DHSSelectOpt
                  label="Khách hàng"
                  name="IDcustomer"
                  array={listCustomer}
                  valueOpt={"idcustomer"}
                  valueRender={"ten_kh"}
                  rules={true}
                  defaultValue={IDcustomer === "" ? undefined : IDcustomer}
                  onChange={this.handleKHChange}
                />
                <DHSInput
                  label="Địa chỉ"
                  name="dia_chi"
                  value={dia_chi}
                  getValueChange={this.handleChangeText}
                />
                <DHSInput
                  label="Email"
                  name="email"
                  value={email}
                  getValueChange={this.handleChangeText}
                />
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSInput
                      label="Người liên hệ"
                      name="nguoi_gd"
                      value={nguoi_gd}
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                  <Col span={12}>
                    <DHSInput
                      label="Số điện thoại"
                      name="tel"
                      value={tel}
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                </Row>
              </Form>
            </Panel>
            <Panel header="Thông tin phiếu xuất" key="2">
              <Form layout="vertical">
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSInput
                      label="Số đơn hàng"
                      name="so_ct"
                      disable={true}
                      value={soCt}
                      addonAfter={<ReloadOutlined onClick={this.reloadSoCt} />}
                    />
                  </Col>
                  <Col span={12}>
                    <DHSDatePicker
                      label="Ngày lập"
                      value={ngay_lct}
                      nameState="ngay_lct"
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSSelectOpt
                      label="Trạng thái"
                      array={listStatus}
                      valueOpt={"trang_thai"}
                      valueRender={"mo_ta"}
                      defaultValue={"Chờ duyệt"}
                      disable={true}
                    />
                  </Col>
                  <Col span={12}>
                    <DHSDatePicker
                      label="Ngày chứng từ"
                      value={ngay_ct}
                      nameState="ngay_ct"
                      getValueChange={this.handleChangeText}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <DHSSelectOpt
                      label="Loại tiền tệ"
                      array={listCurrency}
                      valueOpt={"ma_nt"}
                      valueRender={"ma_nt"}
                      defaultValue={ma_nt}
                      onChange={this.handleChangeCurrency}
                    />
                  </Col>
                  <Col span={12}>
                    <Spin spinning={loadingExchange}>
                      <DHSInput
                        label="Tỷ giá"
                        name="ty_gia"
                        disable={ma_nt === "VND" ? true : false}
                        value={ty_gia}
                        getValueChange={this.handleChangeText}
                      />
                    </Spin>
                  </Col>
                </Row>
                <DHSInputTextArea
                  label="Ghi Chú"
                  name="ghi_chu"
                  value={ghi_chu}
                  getValueChange={this.handleChangeText}
                />
              </Form>
            </Panel>
          </Collapse>
        </Spin>
      </Drawer>
    );
  }
  checkVisible = (params) => {
    this.setState({ chooseSO1: params.isVisible });
    params.dsPhSO
      ? this.setState({ listSO1: true, resize: widthDrawer + 400 })
      : this.setState({ listSO1: false, resize: widthDrawer });
  };
  checkVisibleListSO1 = (params) => {
    this.setState({ listSO1: params.isVisible, resize: widthDrawer });
    if (!_.isNil(params.phChoose)) {
      this.setState({
        ts_gtgt: params.phChoose.ts_gtgt,
        tl_ck: params.phChoose.tl_ck,
        t_thue: params.phChoose.t_thue,
        t_ck: params.phChoose.t_ck,
        t_tt: params.phChoose.t_tt,
        t_tt_nt: params.phChoose.t_tt_nt,
      });
      this.handleKHChange(params.phChoose.IDCustomer);
    }
  };
}

const mapStateToProps = (state) => {
  return {
    ma_cty: state.LoginReducer.ma_cty,
    userName: state.LoginReducer.userName,
    listCustomer: state.SO2Reducer.listCustomer,
    listStatus: state.SO2Reducer.listStatus,
    listCurrency: state.SO2Reducer.listCurrency,
    soCt: state.SO2Reducer.soCt,
    lstAddressChooseFromSO: state.SO2Reducer.lstAddressChooseFromSO,
    lstCtChooseFromSO: state.SO2Reducer.lstCtChooseFromSO,
    exchangeRate: state.SO2Reducer.exchangeRate,
    newItem: state.SO2Reducer.newItem,
    customer: state.SO2Reducer.customer,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getExchangeRate: (ty_gia) => {
      dispatch(actions.getExchangeRate(ty_gia));
    },
    addNewPackingSlip: (query) => {
      dispatch(actions.addNewPackingSlip(query));
    },
    getCustomer: (query) => {
      dispatch(actions.getCustomer(query));
    },
    getSoCt: (ma_cty) => {
      dispatch(actions.getSoCt(ma_cty));
    },
    clearCache: () => {
      dispatch(actions.clearCache());
    },
    clearSTT: () => {
      dispatch(actions.clearSTT());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SO2Create);
