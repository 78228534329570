import React, { useState, useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import Lines from "./Lines/Lines";
import * as actions from "../../../../redux/actions/Category/customer/index";
import * as actionSellPrice from "../../../../redux/actions/Sales/sellPriceBase/sellPriceBase";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./index.css";
const { TabPane } = Tabs;
export default function Transaction() {
  const dispatch = useDispatch();
  const [tabsActive, setTabsActive] = useState("1");
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  useEffect(() => {
    dispatch(
      actionSellPrice.getLookupSelectedItem({
        ma_cty,
        idcustomer: "",
        ma_nt: "VND",
        ngay_ct: moment().add(7, "hours"),
      })
    );
  }, []);
  return (
    <div className="Transaction">
      {/* <Tabs
        defaultActiveKey="1"
        activeKey={tabsActive}
        onTabClick={(key) => setTabsActive(key)}
      >
        <TabPane tab="Thanh toán" key="1" size="large"> */}
          <Lines setTabsActive={setTabsActive} />
        {/* </TabPane>
      </Tabs> */}
    </div>
  );
}
