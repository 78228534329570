import React from "react";
import MixBar from "./Types/mix";
export default function DHSMixBar(props) {
  const { title, data } = props;
  return (
    <div className="DHSMixBar">
      <div className="h4" style={{ marginBottom: 10 }}>
        {title}
      </div>
      <div className="salesByDays">
        <MixBar
          height={30}
          width={100}
          data={data.value}
          label={data.label}
          name="Doanh số"
          color="#2291C4"
        />
      </div>
    </div>
  );
}
