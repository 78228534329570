import React, { Component } from "react";
import moment from "moment";
import { Bar } from "react-chartjs-2";
export default class Bars extends Component {
  render() {
    const { width, height } = this.props;

    const data = {
      datasets: [
        {
          label: "Line",
          type: "line",
          data: this.props.data,
          fill: false,
          borderColor: "#EC932F",
          backgroundColor: "#EC932F",
          pointBorderColor: "#EC932F",
          pointBackgroundColor: "#EC932F",
          pointHoverBackgroundColor: "#EC932F",
          pointHoverBorderColor: "#EC932F",
          yAxisID: "y-axis-2",
        },
        {
          type: "bar",
          label: "Doanh Số",
          data: this.props.data,
          fill: false,
          backgroundColor: "#71B37C",
          borderColor: "#71B37C",
          hoverBackgroundColor: "#71B37C",
          hoverBorderColor: "#71B37C",
          yAxisID: "y-axis-1",
        },
      ],
    };

    const options = {
      legend: {
        display: false,
      },
      responsive: true,
      labels: this.props.label,
      tooltips: {
        // mode: 'label',
      },
      elements: {
        line: {
          fill: false,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
            },
            labels: this.props.label,
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: true,
            },
            labels: {
              show: true,
            },
            ticks: {
              callback(value) {
                return Number(value).toLocaleString("en");
              },
            },
          },
          {
            type: "linear",
            display: false,
            position: "right",
            id: "y-axis-2",
            gridLines: {
              display: false,
            },
            labels: {
              show: false,
            },
          },
        ],
      },
    };
    return (
      <div>
        <Bar
          height={height}
          width={width}
          data={data}
          options={options}
          // plugins={plugins}
        />
      </div>
    );
  }
}
