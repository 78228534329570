import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListPackingSlip = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch02/ListPackingSlip", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHPackingSlip = (detail) => (dispatch, getState) => {
  callApi("api/soVch02/GetPHPackingSlip", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTPackingSlip = (detail) => (dispatch, getState) => {
  callApi("api/soVch02/GetCTPackingSlip", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCustomer = (customer) => (dispatch, getState) => {
  callApi("api/soVch02/GetCustomer", "POST", customer)
    .then((res) =>
      dispatch({
        type: types.GET_CUSTOMER_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListCustomer = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch02/ListCustomer", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CUSTOMER_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch02/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/soVch02/GetListDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_DM_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getListCurrency = () => (dispatch, getState) => {
  callApi("api/soVch02/ListCurrency", "GET")
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CURRENCY_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/soVch02/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewPackingSlip = (query) => (dispatch, getState) => {
  callApi("api/soVch02/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteAPackingSlip = (master) => (dispatch, getState) => {
  callApi("api/soVch02/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_SO2,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updatePackingSlip = (so1) => (dispatch, getState) => {
  callApi("api/soVch02/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/soVch02/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const lookupSoSalesOrder = (query) => (dispatch, getState) => {
  callApi("api/soVch02/LookupSoSalesOrder", "POST", query)
    .then((res) =>
      dispatch({
        type: types.LOOKUP_SO_SO1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getContactPackingSlip = (query) => (dispatch, getState) => {
  callApi("api/soVch02/GetContactPackingSlip", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_CONTACT_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCtDriver = (query) => (dispatch, getState) => {
  callApi("api/soVch02/GetCtDriver", "POST", query)
    .then((res) =>
      dispatch({
        type: types.GET_DRIVER_SO2,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const voucherNumberUpdate = (query) => (dispatch, getState) => {
  callApi("api/soVch02/VoucherNumberUpdate", "POST", query)
    .then((res) =>
      dispatch({
        type: types.UPDATE_VOUCHER_NUMBER,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const listCtChooseFromSO = (params) => {
  return {
    type: types.LIST_CT_CHOOSE_FROM_SO,
    payload: params,
  };
};

export const clearCache = () => {
  return {
    type: types.CLEAR_SO2,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_SO2,
  };
};
