import * as types from "../../../constants/ActionTypes";

var initialState = {
  listApprovalQuotations: [],
  approvalQuotations: false,
  rejectQuotations: false,
};

var ApprovalQuotationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_APPROVAL_SO0: {
      return {
        ...state,
        listApprovalQuotations: action.payload,
      };
    }
    case types.APPROVAL_QUOTATION: {
      return {
        ...state,
        approvalQuotations: action.payload,
      };
    }
    case types.REJECT_QUOTATION: {
      return {
        ...state,
        rejectQuotations: action.payload,
      };
    }
    case types.CLEAR_APPROVAL_SO0: {
      return {
        ...state,
        approvalQuotations: false,
        rejectQuotations: false,
      };
    }
    default:
      return state;
  }
};

export default ApprovalQuotationsReducer;
