import System from "../../../components/layout/System/system";
import RoleInfo from "../../../components/layout/System/roleInfo/roleInfo";
import UserInfo from "../../../components/layout/System/userInfo/userInfo";
const systemRoutes = [
  {
    path: "/System",
    exact: false,
    main: ({ history }) => <System history={history} />,
  },
  {
    path: "/ChangePassword",
    exact: false,
    main: ({ history }) => <System history={history} isVisible={true} />,
  },
  {
    path: "/RolesInfo",
    exact: false,
    main: ({ history }) => <RoleInfo history={history} />,
  },
  {
    path: "/UsersInfo",
    exact: false,
    main: ({ history }) => <UserInfo history={history} />,
  },
];

export default systemRoutes;
