const moment = require("moment");
const _ = require("lodash");
const antd = require("antd");
const { Avatar, Checkbox } = antd;
var result = [];
// var getDaysArray = function (year = 2021, month = 1) {
//   var monthIndex = month - 1;
//   var names = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   var date = new Date(year, monthIndex, 1);
//   let result = [];
//   while (date.getMonth() == monthIndex) {
//     result.push({
//       value: moment(
//         `${date.getDate()}/${month}/${year}`,
//         "DD/MM/yyyy"
//       ).format("DD/MM/yyyy HH:mm"),
//       title: names[date.getDay()] + ", " + date.getDate(),
//     });
//     date.setDate(date.getDate() + 1);
//   }
//   console.log(result);
//   return result;
// };
const setStateForPeriod = (periodName, value1, value2) => {
  const result =
    periodName === "week"
      ? {
          period: periodName,
          formDate: moment(`${moment().year()}`)
            .add(value1, "weeks")
            .subtract(7, "days"),
          toDate: moment(`${moment().year()}`)
            .add(value1, "weeks")
            .endOf("days"),
        }
      : {
          period: periodName,
          formDate: moment(`${moment().year()}-${value1}-01`).startOf("month"),
          toDate: moment(`${moment().year()}-${value2}-01`).endOf("month"),
        };
  return result;
};

const FormatMoney = (money) => {
  return parseInt(money)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    .toString();
};

const FormatDate = (date) => {
  if (typeof date === "undefined") {
    return " ";
  }
  return moment(date).format("DD/MM/YYYY");
};
const FormatDateTime = (date) => {
  if (_.isUndefined(date) || _.isEmpty(date)) {
    return moment().format("DD/MM/YYYY HH:mm:ss");
  }
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
};
const toLowerPropertyName = (object) => {
  var key,
    keys = Object.keys(object);
  var n = keys.length;
  var newobj = {};
  while (n--) {
    key = keys[n];
    newobj[key.toLowerCase()] = object[key];
  }
  return newobj;
};
const toUpperPropertyName = (object) => {
  for (var key in object) {
    var upper = key.toUpperCase();
    if (upper !== key) {
      object[upper] = object[key];
      delete object[key];
    }
  }
  return object;
};
const toUpperPropertyNameByArray = (array) => {
  if (!_.isEmpty(array)) {
    _.filter(array, (item) => {
      for (var key in item) {
        var upper = key.toUpperCase();
        if (upper !== key) {
          item[upper] = item[key];
          delete item[key];
        }
      }
    });
  }
  return array;
};
const toLowerPropertyNameByArray = (array) => {
  if (!_.isEmpty(array)) {
    _.filter(array, (item) => {
      for (var key in item) {
        var upper = key.toLowerCase();
        if (upper !== key) {
          item[upper] = item[key];
          delete item[key];
        }
      }
    });
  }
  return array;
};
const onPeriodChange = (value, inputNumber = 0) => {
  switch (value) {
    case "q1": {
      return setStateForPeriod(value, "01", "03");
    }
    case "q2": {
      return setStateForPeriod(value, "04", "06");
    }
    case "q3": {
      return setStateForPeriod(value, "07", "09");
    }
    case "q4": {
      return setStateForPeriod(value, "10", "12");
    }
    case "d6t": {
      return setStateForPeriod(value, "01", "06");
    }
    case "c6t": {
      return setStateForPeriod(value, "07", "12");
    }
    case "cn": {
      return setStateForPeriod(value, "01", "12");
    }
    case "formTo": {
      return setStateForPeriod(value, "01", "12");
    }
    case "week": {
      return setStateForPeriod(value, inputNumber);
    }
    default: {
      return setStateForPeriod(value, value, value);
    }
  }
};
const FormatColumnDHSTable = (objectColumn) => {
  result.length = 0;
  Object.keys(objectColumn).map((itemColumn) => {
    result.push({
      title: objectColumn[itemColumn].name,
      dataIndex: itemColumn,
      key: itemColumn,
      width: objectColumn[itemColumn].width,
      render: (value, row, index) => {
        return objectColumn[itemColumn].formatDate ? (
          `${FormatDate(value)}`
        ) : objectColumn[itemColumn].formatMoney ? (
          `${FormatMoney(value)}`
        ) : objectColumn[itemColumn].formatImage ? (
          <Avatar shape="square" src={"data:image/png;base64," + value} />
        ) : objectColumn[itemColumn].checkBox ? (
          <Checkbox checked={value} disabled={true} />
        ) : (
          value
        );
      },
    });
  });
};

const toFormatObjectReport = (
  arr = [],
  propertyLabel = "",
  propertyValue = "",
  propertyValue2 = ""
) => {
  const obj = {
    label: arr.map((item) => item[propertyLabel]),
    value: arr.map((item) => item[propertyValue]),
    value2: arr.map((item) => item[propertyValue2]),
  };
  return obj;
};

const Upload = () => {
  const fileUpload = document.getElementById("fileUpload");
  const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
  let data = [];
  if (regex.test(fileUpload.value.toLowerCase())) {
    let fileName = fileUpload.files[0].name;
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = async (e) => {
          var promise = new Promise((res, rej) => {
            let dataExcel = processExcel(reader.result);
          });
        };
        reader.readAsBinaryString(fileUpload.files[0]);
      }
    } else {
      return 1;
    }
  } else {
    return 2;
  }
};

function processExcel(data) {
  const workbook = XLSX.read(data, { type: "binary" });
  const firstSheet = workbook.SheetNames[0];
  var worksheet = workbook.Sheets[firstSheet];
  var headers = {};
  var data = [];
  for (var z in worksheet) {
    if (z[0] === "!") continue;
    //parse out the column, row, and value
    var tt = 0;
    for (var i = 0; i < z.length; i++) {
      if (!isNaN(z[i])) {
        tt = i;
        break;
      }
    }
    var col = z.substring(0, tt);
    var row = parseInt(z.substring(tt));
    var value = worksheet[z].v;

    //store header names
    if (row == 1 && value) {
      headers[col] = value;
      continue;
    }

    if (!data[row]) data[row] = {};
    data[row][headers[col]] = value;
  }
  data.shift();
  data.shift();
  return data;
}
export {
  FormatMoney,
  FormatDate,
  toLowerPropertyName,
  toUpperPropertyName,
  toUpperPropertyNameByArray,
  onPeriodChange,
  FormatColumnDHSTable,
  toLowerPropertyNameByArray,
  FormatDateTime,
  result,
  Upload,
  toFormatObjectReport,
};
