import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListCustomerGroup = (ma_cty) => (dispatch, getState) => {
  callApi("api/CustomerGroup/lstCustomerGroup", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_CUS_GROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteCustomerGroup = (query) => (dispatch, getState) => {
  callApi("api/CustomerGroup/DeleteCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.DELETE_CUS_GROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewCustomerGroup = (model) => (dispatch, getState) => {
  callApi("api/CustomerGroup/InsertCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.INSERT_CUS_GROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const updateCustomerGroup = (model) => (dispatch, getState) => {
  callApi("api/CustomerGroup/UpdateCommand", "POST", model)
    .then((res) =>
      dispatch({
        type: types.UPDATE_CUS_GROUP,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const clearCache = () => {
  return {
    type: types.CLEAR_CUS_GROUP,
  };
};
