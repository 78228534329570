import * as types from "../../constants/ActionTypes";
import callApi from "../../../routes/config/configApi";

export const GetListMachines = (data) => (dispatch, getState) => {
  callApi("api/PDMachineSetup/GetListMachines", "POST", data)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_MACHINE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const GetMachinesActionState = (data) => (dispatch, getState) => {
  callApi("api/MachineRpt/GetLstActionState", "POST", data)
    .then((res) =>
      dispatch({
        type: types.GET_MACHINE_ACTION_STATE,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
