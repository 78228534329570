import * as types from "../../../constants/ActionTypes";

var initialState = {
  listRoleInfo: [],
  checkInsert: false,
  checkEdit: false,
  checkDelete: false,
};

var RoleInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_ROLE_INFO: {
      return {
        ...state,
        listRoleInfo: action.payload,
      };
    }
    case types.ADD_NEW_ROLE_INFO: {
      return {
        ...state,
        checkInsert: action.payload,
      };
    }
    case types.UPDATE_ROLE_INFO: {
      return {
        ...state,
        checkEdit: action.payload,
      };
    }
    case types.DELETE_ROLE_INFO: {
      return {
        ...state,
        checkDelete: action.payload,
      };
    }
    case types.CLEAR_CACHE_ROLE_INFO: {
      return {
        ...state,
        checkDelete: false,
        checkEdit: null,
        checkInsert: null,
      };
    }
    default:
      return state;
  }
};

export default RoleInfoReducer;
