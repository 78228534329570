import React, { Component } from "react";
import { Collapse, Row, Col, Form, Input, Select } from "antd";
import { FormatDate } from "../../../../../controller/Format";
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

export default class General extends Component {
  state = {
    loadingExchange: false,
  };
  render() {
    const { loadingExchange } = this.state;
    const { Master, customer } = this.props;
    return (
      <div className="so2-detail-general">
        <Collapse defaultActiveKey={["1"]} ghost expandIconPosition="right">
          <Panel header="Thông tin chung" key="1">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <Form.Item label="Tên khách hàng">
                    <h4>{customer.ten_kh}</h4>
                  </Form.Item>
                  <Form.Item label="Email">
                    <h4>{customer.email}</h4>
                  </Form.Item>
                  <Form.Item label="Số điện thoại">
                    <h4>{customer.tel}</h4>
                  </Form.Item>
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <Form.Item label="Người liên hệ">
                    <h4>{Master.NGUOI_GD}</h4>
                  </Form.Item>
                  <Form.Item label="Địa chỉ">
                    <h4>{customer.dia_chi}</h4>
                  </Form.Item>
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <Form.Item label="Ngày lập">
                    <h4>{FormatDate(Master.NGAY_CT)}</h4>
                  </Form.Item>
                  <Form.Item label="Trạng thái">
                    <Select defaultValue={Master.TRANG_THAI} disabled={true}>
                      <Option value="1">Chưa hóa đơn</Option>
                      <Option value="2">Đã duyệt</Option>
                      <Option value="3">Đã xuất hóa đơn</Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
              <Col className="gutter-row" span={6} xs={12} xl={6}>
                <Form layout="vertical">
                  <Form.Item label="Loại tiền tệ">
                    <h4>{Master.MA_NT}</h4>
                  </Form.Item>
                  <Form.Item label="Tỷ giá">
                    <h4>{Master.TY_GIA}</h4>
                  </Form.Item>
                  <Form.Item label="Ghi chú">
                    <h4>{Master.DIEN_GIAI}</h4>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
