import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListDiscount = (ma_cty) => (dispatch, getState) => {
  callApi("api/soDiscount/ListDiscount", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHDiscount = (detail) => (dispatch, getState) => {
  callApi("api/soDiscount/GetPHDiscount", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTDiscount = (detail) => (dispatch, getState) => {
  callApi("api/soDiscount/GetCTDiscount", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/soDiscount/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewDiscount = (quotation) => (dispatch, getState) => {
  callApi("api/soDiscount/InsertCommand", "POST", quotation)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

// export const getCustomer = (customer) => (dispatch, getState) => {
//   callApi("api/soDiscount/GetCustomer", "POST", customer)
//     .then((res) =>
//       dispatch({
//         type: types.GET_CUSTOMER_DS1,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };

// export const getListCustomer = (ma_cty) => (dispatch, getState) => {
//   callApi("api/soDiscount/ListCustomer", "POST", ma_cty)
//     .then((res) =>
//       dispatch({
//         type: types.GET_LIST_CUSTOMER_DS1,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };

// export const getListStatus = () => (dispatch, getState) => {
//   callApi("api/soDiscount/ListStatus", "GET")
//     .then((res) =>
//       dispatch({
//         type: types.GET_LIST_STATUS_DS1,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };

export const getLists = (ma_cty) => (dispatch, getState) => {
  callApi("api/soDiscount/Lists", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

// export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
//   callApi("api/soDiscount/GetExchangeRate", "POST", ty_gia)
//     .then((res) =>
//       dispatch({
//         type: types.GET_EXCHANGE_RATE_DS1,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };

export const deleteDiscount = (master) => (dispatch, getState) => {
  callApi("api/soDiscount/DeleteCommand", "POST", master)
    .then((res) =>
      dispatch({
        type: types.DELETE_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const updateDiscount = (so1) => (dispatch, getState) => {
  callApi("api/soDiscount/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_DS1,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

// export const lookupSoQuotation = (query) => (dispatch, getState) => {
//   callApi("api/soDiscount/LookupSoQuotation", "POST", query)
//     .then((res) =>
//       dispatch({
//         type: types.LOOKUP_SO_QUOTATION,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };
export const clearCache = () => {
  return {
    type: types.CLEAR_DS1,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_DS1,
  };
};
// export const clearSTTEdit = () => {
//   return {
//     type: types.CLEAR_STT_EDIT_DS1,
//   };
// };
// export const listCtChooseFromQU = (dsCtQU) => {
//   return {
//     type: types.LIST_CT_CHOOSE_FROM_QU,
//     payload: dsCtQU,
//   };
// };
// export const getContactDiscount = (query) => (dispatch, getState) => {
//   callApi("api/soDiscount/GetContactDiscount", "POST", query)
//     .then((res) =>
//       dispatch({
//         type: types.GET_CONTACT_DS1,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };
// export const filt = (query) => (dispatch, getState) => {
//   callApi("api/soDiscount/Filt", "POST", query)
//     .then((res) =>
//       dispatch({
//         type: types.FILT_DS1,
//         payload: res.data,
//       })
//     )
//     .catch((error) => console.log(error));
// };
