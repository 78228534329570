import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Spin, PageHeader } from "antd";
import moment from "moment";
import "./index.css";
import _ from "lodash";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toFormatObjectReport } from "../../../controller/Format";
import * as action from "../../../../redux/actions/Dashboard/SalesDB/SalesDB";
import DHSDatePicker from "../../../../common/DHSControl/DHSDashBoardComponent/DHSDatePicker";
import DHSMixBar from "../../../../common/DHSControl/DHSDashBoardComponent/DHSMixBar";
import DHSBar from "../../../../common/DHSControl/DHSDashBoardComponent/DHSBar";
import DHSCardsCountUp from "../../../../common/DHSControl/DHSDashBoardComponent/DHSCardsCountUp";
import DHSIcons from "../../../../common/DHSControl/DHSIcons/DHSIcons";
import DHSCompareBars from "../../../../common/DHSControl/DHSDashBoardComponent/DHSCompareBars";
SalesDB.propTypes = {
  _objReport: PropTypes.object,
  _filterDate: PropTypes.object,
};
SalesDB.defaultProps = {
  _objReport: {
    valSalesbyDay: [],
    labelSalesbyDay: [],
    valSalesByMonth: [],
    labelSalesbyMonth: [],
    valSalesbyQuater: [],
    labelSalesbyQuater: [],
    valSalesByCn: [],
    labelSalesbyCn: [],
    valSalesbyEmp: [],
    labelSalesbyEmp: [],
    valTop10: [],
    labelTop10: [],
    valCompare01: [],
    valCompare02: [],
    labelCompare: [],
  },
  _filterDate: {
    year: moment().year(),
    month: moment().month() + 1,
  },
};
export default function SalesDB(props) {
  const { _objReport, _filterDate } = props;
  const [loading, setLoading] = useState(false);
  const [objReport, setObjReport] = useState(_objReport);
  const [filterDate, setFilterDate] = useState(_filterDate);
  const ma_cty = useSelector((state) => state.LoginReducer.ma_cty);
  const SalesDB = useSelector((state) => state.SalesDBReducer);

  //Lấy year,month từ component DHSDatePicker
  const getDatePickerChange = async (date) => {
    setFilterDate(date);
  };

  const dispatch = useDispatch();
  const loadingDataSales = async () => {
    dispatch(
      action.fetchSalesByMonth({
        ma_cty,
        year: filterDate.year,
      })
    );
    dispatch(
      action.fetchSalesByDay({
        ...filterDate,
        ma_cty,
      })
    );
  };
  useEffect(() => {
    setLoading(true);
    loadingDataSales();
    dispatch(action.soGetSalesToday({ ma_cty }));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [filterDate]);

  useEffect(() => {
    setObjReport({
      valSalesByMonth: SalesDB.dsSalesbyMonth.map(({ value }) => value),
      valSalesbyQuater: SalesDB.dsSalesbyQuater.map(({ value }) => value),
      valSalesByCn: SalesDB.dsSalesbyCn.map(({ value }) => value),
      valSalesbyEmp: SalesDB.dsSalesbyEmployee.map(({ value }) => value),
      valTop10: SalesDB.dsTop10.map(({ value }) => value),
      valCompare01: SalesDB.dsCompare.map(({ value }) => value),
      valCompare02: SalesDB.dsCompare.map(({ value1 }) => value1),
      labelSalesbyMonth: SalesDB.dsSalesbyMonth.map(({ Description }) =>
        Description.toString()
      ),
      labelSalesbyQuater: SalesDB.dsSalesbyQuater.map(({ Description }) =>
        Description.toString()
      ),
      labelSalesbyCn: SalesDB.dsSalesbyCn.map(({ Description }) =>
        Description.toString()
      ),
      labelSalesbyEmp: SalesDB.dsSalesbyEmployee.map(({ Description }) =>
        Description.toString()
      ),
      labelTop10: SalesDB.dsTop10.map(({ Description }) =>
        Description.toString()
      ),
      labelCompare: SalesDB.dsCompare.map(({ Description }) =>
        Description.toString()
      ),
      valSalesbyDay: SalesDB.dataSalesByDay.map(({ value }) => value),
      labelSalesbyDay: SalesDB.dataSalesByDay.map(({ Description }) =>
        moment(Description).date().toString()
      ),
    });
  }, [SalesDB]);
  return (
    <div className="salesDB">
      <Spin spinning={loading}>
        <PageHeader className="site-page-header" title="DashBoard" />
        <Row gutter={[8, 8]}>
          {SalesDB.dsSalesToday.map((item, index) => {
            return (
              <Col className="gutter-row" xs={24} md={12} xl={6} key={index}>
                <DHSCardsCountUp
                  valSalesByMonth={item.so_luong_HD}
                  content1={item.DescriptionHD || "Hóa đơn"}
                  valSalesbyQuater={item.doanh_so}
                  content2={item.Description}
                  color={item.color || "#00aeff"}
                  icon={<DHSIcons name={item.icon || ""} />}
                />
              </Col>
            );
          })}
        </Row>
        <DHSDatePicker getDatePickerChange={getDatePickerChange} />
        <div className="flex" style={{ marginBottom: 10, background: "white" }}>
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" xs={24} md={24} xl={24}>
              <DHSMixBar
                title="Doanh số bán hàng theo ngày"
                data={toFormatObjectReport(
                  SalesDB.dataSalesByDay,
                  "Description",
                  "value"
                )}
              />
            </Col>
          </Row>
        </div>
        <div className="flex" style={{ marginBottom: 10 }}>
          <Row>
            <Col className="gutter-row" xs={24} md={12} xl={8}>
              <DHSBar
                title="Doanh số bán hàng theo tháng"
                data={toFormatObjectReport(
                  SalesDB.dsSalesbyMonth,
                  "Description",
                  "value"
                )}
              />
            </Col>
            <Col className="gutter-row" xs={24} md={12} xl={8}>
              <DHSBar
                title="Doanh số bán hàng theo quý"
                data={toFormatObjectReport(
                  SalesDB.dsSalesbyQuater,
                  "Description",
                  "value"
                )}
              />
            </Col>
            <Col className="gutter-row" xs={24} md={12} xl={8}>
              <DHSBar
                title="Top 10 sản phẩm"
                data={toFormatObjectReport(
                  SalesDB.dsTop10,
                  "Description",
                  "value"
                )}
              />
            </Col>
            <Col className="gutter-row" xs={24} md={12} xl={8}>
              <DHSBar
                title="Doanh số bán hàng theo nhân viên"
                data={toFormatObjectReport(
                  SalesDB.dsSalesbyEmployee,
                  "Description",
                  "value"
                )}
              />
            </Col>
            <Col className="gutter-row" xs={24} md={12} xl={8}>
              <DHSBar
                title="Doanh số bán hàng theo chi nhánh"
                data={toFormatObjectReport(
                  SalesDB.dsSalesbyCn,
                  "Description",
                  "value"
                )}
              />
            </Col>
            <Col className="gutter-row" xs={24} md={12} xl={8}>
              <DHSCompareBars
                title="So sánh kế hoạch và thực tế theo Quý"
                data={toFormatObjectReport(
                  SalesDB.dsCompare,
                  "Description",
                  "value",
                  "value1"
                )}
                name="Kế hoạch"
                nameCompare="Thực tế"
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}
