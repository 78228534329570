import * as types from "../../../constants/ActionTypes";
import callApi from "../../../../routes/config/configApi";

export const getListMovementTransfer = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch03/ListMovementTransfer", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LIST_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getPHMovementTransfer = (detail) => (dispatch, getState) => {
  callApi("api/inVch03/GetPHMovementTransfer", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_PH_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getCTMovementTransfer = (detail) => (dispatch, getState) => {
  callApi("api/inVch03/GetCTMovementTransfer", "POST", detail)
    .then((res) =>
      dispatch({
        type: types.GET_CT_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getSoCt = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch03/GetSoCt", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_SO_CT_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const getExchangeRate = (ty_gia) => (dispatch, getState) => {
  callApi("api/inVch03/GetExchangeRate", "POST", ty_gia)
    .then((res) =>
      dispatch({
        type: types.GET_EXCHANGE_RATE_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const addNewMovementTransfer = (query) => (dispatch, getState) => {
  callApi("api/inVch03/InsertCommand", "POST", query)
    .then((res) =>
      dispatch({
        type: types.ADD_NEW_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};

export const deleteAMovementTransfer = (master) => (dispatch, getState) => {
  callApi("api/inVch03/DeleteCommand", "POST", master)
    .then((res) => {
      dispatch({
        type: types.DELETE_IN3,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};
export const updateMovementTransfer = (so1) => (dispatch, getState) => {
  callApi("api/inVch03/UpdateCommand", "POST", so1)
    .then((res) =>
      dispatch({
        type: types.UPDATE_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const filt = (query) => (dispatch, getState) => {
  callApi("api/inVch03/Filt", "POST", query)
    .then((res) =>
      dispatch({
        type: types.FILT_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const getListsDm = (ma_cty) => (dispatch, getState) => {
  callApi("api/inVch03/GetListsDm", "POST", ma_cty)
    .then((res) =>
      dispatch({
        type: types.GET_LISTS_DM_IN3,
        payload: res.data,
      })
    )
    .catch((error) => console.log(error));
};
export const clearCache = () => {
  return {
    type: types.CLEAR_IN3,
  };
};
export const clearSTT = () => {
  return {
    type: types.CLEAR_HANDLE_STT_IN3,
  };
};
