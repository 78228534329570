import React, { Component } from "react";
import { List, Checkbox, InputNumber, Avatar } from "antd";
import "./index.css";
import _ from "lodash";
import { FormatMoney } from "../../../components/controller/Format";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
export default class DHSListItem extends Component {
  state = { listItemSelected: [], chooseRow: false };
  componentDidMount = () => {
    const { listItem, checkSelectedItem } = this.props;
    if (checkSelectedItem) {
      listItem.map((item) => {
        if (item.CHON) {
          item.STT_REC0 = Math.random().toString(36).substr(2, 9).toUpperCase();
          item.SO_LUONG = 1;
          this.state.listItemSelected.push(item);
        }
      });
    }
  };
  onSelectItem = async (e, item) => {
    item.CHON = e.target.checked;
    this.setState({ chooseRow: !this.state.chooseRow });
    const { listItemSelected } = this.state;
    if (_.includes(listItemSelected, item)) {
      _.remove(listItemSelected, item);
    } else {
      item.SO_LUONG = 1;
      await listItemSelected.push({
        ...item,
        STT_REC0: Math.random().toString(36).substr(2, 9).toUpperCase(),
      });
    }
    await this.props.getItemSelected(listItemSelected);
  };
  onChangeValue = (e, item, name) => {
    item[name] = e;
    this.state.listItemSelected.map((x) => {
      if (x.IDITEM === item.IDITEM) {
        x[name] = item[name];
      }
    });
  };
  Row1 = ({ data, index, style }) => {
    const {
      keyItem,
      title,
      description,
      inputName1,
      inputDisplay,
      avatar,
      checkSelectedItem,
    } = this.props;
    return (
      <div style={style}>
        <List.Item key={data[index][keyItem]}>
          <List.Item.Meta
            avatar={
              <Avatar
                shape="square"
                src={"data:image/png;base64," + data[index][avatar]}
              />
            }
            title={data[index][title]}
            description={data[index][description]}
          />
          {inputDisplay ? (
            <>
              <InputNumber
                size="small"
                defaultValue={data[index][inputName1] || 0}
                formatter={(value) => `${FormatMoney(value || 0)}`}
                min={0}
                onChange={(e) => this.onChangeValue(e, data[index], inputName1)}
              />
              <InputNumber
                defaultValue={1}
                size="small"
                min={1}
                onChange={(e) => this.onChangeValue(e, data[index], "SO_LUONG")}
              />
            </>
          ) : (
            <></>
          )}
          <Checkbox
            onChange={(e) => this.onSelectItem(e, data[index])}
            defaultChecked={checkSelectedItem ? data[index].CHON : false}
          ></Checkbox>
        </List.Item>
      </div>
    );
  };
  render() {
    const { listItem, header } = this.props;

    return (
      <div className="DHSListItem">
        {header}
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemCount={listItem.length}
              itemData={listItem}
              itemSize={50}
            >
              {this.Row1}
            </FixedSizeList>
          )}
        </AutoSizer>
        {/* <List
          header={header}
          dataSource={listItem}
          renderItem={(item) => (
            <List.Item key={item[keyItem]}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    src={"data:image/png;base64," + item[avatar]}
                  />
                }
                title={item[title]}
                description={item[description]}
              />
              {inputDisplay ? (
                <>
                  <InputNumber
                    size="small"
                    defaultValue={item[inputName1] || 0}
                    formatter={(value) => `${FormatMoney(value || 0)}`}
                    min={0}
                    onChange={(e) => this.onChangeValue(e, item, inputName1)}
                  />
                  <InputNumber
                    defaultValue={1}
                    size="small"
                    min={1}
                    onChange={(e) => this.onChangeValue(e, item, "SO_LUONG")}
                  />
                </>
              ) : (
                <></>
              )}
              <Checkbox
                onChange={(e) => this.onSelectItem(e, item)}
                defaultChecked={checkSelectedItem ? item.CHON : false}
              ></Checkbox>
            </List.Item>
          )}
        ></List> */}
      </div>
    );
  }
}
